import { Icon } from "@iconify/react";
import React, { useEffect } from "react";
import CameraWebcam from "../../../common/CameraWebcam";
import Count from "../../../common/Count";
import { DateCheckin } from "../../../common/DateCheckin";
import { HeaderName } from "../../../common/HeaderName";
import InputPhone from "../../../common/InputPhone";
import SelectOpt from "../../../common/SelectOpt";
import { iconUrl } from "../../../config";
import { generateBookingCode, priceSplit } from "../../../utils";
import { inputContainer } from "../bookingHelper";

export const FormWalkin = ({
  walkin,
  setWalkin,
  typeRoom,
  formError,
  setFormError,
  roomSelected,
  setRoomSelected,
  isOpening,
  purpose,
  purposeSelected,
  setPurposeSelected,
  guesttype,
  guestTypeSelected,
  setGuestTypeSelected,
  availability,
  errorAvail,
  roomPrice,
  earlyCheckin,
  deposit,
  totalCharge,
  setfileUpload,
  fileUpload,
  setShowImg,
  showImg,
  guestName,
  openModal,
  setMode,
  setError,
  setImgPopup,
  roomList,
  roomNumber,
  setGuestName,
  setDeposit,
  setRoomNumber,
  isValidGuest,
  errorVoucher,
  discount,
  loadingAvailability,
  setStopFetch,
}) => {
  const validEmailInput = (val) => {
    const validEmail = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (val?.length < 1) {
      setFormError(null);
    } else if (!validEmail.test(val)) {
      setFormError("Format email salah");
    } else {
      return setFormError(null);
    }
  };

  const handleBookedBy = (val) => {
    if (val.target.value) {
      let d = val?.target?.value.replace(
        /(^\w{1})|(\s+\w{1})|(,+\w{1})/g,
        (letter) => letter.toUpperCase()
      );
      let text = d.replace(", ", ",");
      let guest = text?.split(",");
      setWalkin({ ...walkin, guestName: guest });
    } else {
      setWalkin({ ...walkin, guestName: [] });
    }
  };

  const handleVoucherCode = (val) => {
    setWalkin({
      ...walkin,
      voucherCode: generateBookingCode(val.target.value),
    });
  };

  const handleEmail = (val) => {
    setWalkin({ ...walkin, email: val.target.value });
    validEmailInput(val.target.value);
  };
  const handleDeposit = (e) => {
    setDeposit(Number(e.target.value.replace(/\D/g, "")));
  };

  const handleGuestName = (val) => {
    if (val.target.value) {
      let d = val?.target?.value.replace(
        /(^\w{1})|(\s+\w{1})|(,+\w{1})/g,
        (letter) => letter.toUpperCase()
      );
      let text = d.replace(", ", ",");
      let guest = text?.split(",");
      setGuestName(guest);
    } else {
      setGuestName([]);
    }
  };

  const handleSelect = (value) => {
    setWalkin({
      ...walkin,
      roomId: value.value,
      roomType: value.label,
      roomNumber: [],
    });
    setRoomNumber([]);
    setRoomSelected(value);
  };

  const handleGuest = (val) => {
    setWalkin({ ...walkin, guest: val });
  };

  const handleRoom = (val) => {
    setWalkin({ ...walkin, room: val });
  };

  const handleCheckOut = (value) => {
    setWalkin({ ...walkin, checkout: value });
  };

  const detailPayment = (title, value, color, onClick) => {
    return (
      <div className="d-flex flex-row f10-vw">
        <div className="col px-0">
          <span className={` ${color ? color : "text-black"}`}>{title}</span>
        </div>
        <div className="col px-0">
          <div className="d-flex flex-row justify-content-between">
            <div className={` ${color ? color : "text-black"}`}>Rp</div>
            {onClick ? (
              <div
                className="col ml-1 px-0 text-right text-black "
                style={{
                  cursor: "pointer",
                  backgroundColor: "#EFE5F4",
                  borderRadius: "5px",
                  fontWeight: "bold",
                }}
                onClick={onClick}
              >
                <div className="d-flex justify-content-between">
                  <span className="px-2">
                    <Icon icon={iconUrl?.infoCircle} />
                  </span>
                  <span className="pr-2">{priceSplit(value)}</span>
                </div>
              </div>
            ) : (
              <>
                <div
                  className={`col px-0 text-right  ${
                    color ? "f10-vw " + color : "text-black"
                  }`}
                >
                  <span className="pr-2">{value}</span>
                </div>
              </>
            )}
          </div>
          {color && value > 0 && (
            <div className="px-2" style={{ position: "relative" }}>
              <small
                className="f4-vw grey"
                style={{ position: "absolute", top: 0, right: 5 }}
              >
                Sudah termasuk pajak
              </small>
            </div>
          )}
        </div>
      </div>
    );
  };

  const depositInput = (title, value, onChange) => {
    return (
      <div className="d-flex flex-row f10-vw align-items-center">
        <div className="col px-0">{title}</div>
        <div className="col px-0">
          <div className="d-flex flex-row align-items-center">
            <span className="pr-2">Rp</span>
            <input
              type="text"
              className="form-control f10-vw form-control-sm rounded-input text-right"
              style={{ paddingRight: 7 }}
              onChange={onChange}
              value={priceSplit(value)}
              onFocus={
                value > 0
                  ? (e) => (e.target.value = priceSplit(value))
                  : (e) => (e.target.value = "")
              }
              onBlur={(e) => (e.target.value = priceSplit(value))}
              maxLength={12}
            />
          </div>
        </div>
      </div>
    );
  };

  const handlePurpose = (val) => {
    setPurposeSelected(val);
  };

  const validVoucher = () => {
    let validation = false;
    if (
      errorVoucher ||
      (walkin.voucherCode?.length > 0 && walkin.voucherCode?.length < 16)
    ) {
      validation = true;
    }
    return validation;
  };

  const handleGuestType = (val) => {
    setGuestTypeSelected(val);
  };

  const handlePhone = (value) => {
    setWalkin({ ...walkin, phoneNumber: value });
  };

  useEffect(() => {
    setPurposeSelected(purpose[1]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    setGuestTypeSelected(guesttype[0]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="checkin-page d-flex ">
      <div className="col-4 px-0">
        <div className="">
          <HeaderName text="Informasi Tamu" fontSize="f12-vw fw-700" />
        </div>
        <div className="d-flex flex-column">
          <div className="col pl-0 pr-4">
            {inputContainer(
              "Nama Pemesan",
              "Nama Pemesan",
              walkin?.guestName,
              (e) => handleBookedBy(e),
              !isOpening ? true : false
            )}
          </div>
          <div className="col pl-0 pr-4">
            <div className="form-group mb-2">
              <label htmlFor="pemesan" className="f8-vw m-0 fw-700">
                <span className="red">* </span>Nomor Ponsel
              </label>
              <div className="group-input f8-vw grey">
                <InputPhone
                  value={walkin?.phoneNumber}
                  setValue={handlePhone}
                />
              </div>
            </div>
          </div>
          <div className="col pl-0 pr-4">
            {inputContainer(
              "Email",
              "Masukkan email",
              walkin?.email,
              (e) => handleEmail(e),
              false,
              "text",
              formError,
              false
            )}
            <div className="invalid-feedback f6-vw">{formError}</div>
          </div>
          <div className="col pl-0 pr-4">
            <div className="">
              {inputContainer(
                "Nama Tamu",
                "Nama Tamu di setiap kamar",
                guestName,
                (e) => handleGuestName(e),
                false,
                "text",
                !isValidGuest
              )}
              <div className="invalid-feedback f6-vw">
                Nama tamu harus sesuai jumlah kamar
              </div>
            </div>
          </div>
          <div className="col pl-0 pr-4">
            <div className="form-group">
              <div className="d-flex flex-row">
                <div className="col pl-0 pr-1">
                  <label className="f8-vw my-1 fw-700">Purpose</label>
                  <SelectOpt
                    optionsValue={purpose}
                    onChange={handlePurpose}
                    placeholder={"Pilih Tujuan Menginap"}
                    value={purposeSelected}
                    placement={true}
                  />
                </div>
                <div className="col pl-1 pr-0">
                  <label className="f8-vw my-1 fw-700">Guest Type</label>
                  <SelectOpt
                    optionsValue={guesttype}
                    onChange={handleGuestType}
                    placeholder={"Pilih Guest Type"}
                    value={guestTypeSelected}
                    placement={true}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="col-4 px-2">
        <div className="">
          <HeaderName text="Informasi Pemesanan" fontSize="f12-vw fw-700" />
        </div>
        <div className="d-flex flex-column">
          <div className="col pl-0" style={{ marginTop: 5.5 }}>
            <div>
              <div className="f8-vw fw-700 d-flex flex-row">
                <label className="col-6 pl-0 pr-2 m-0">Check in</label>
                <label className="col-6 pr-0 pl-2 m-0">Check out</label>
              </div>
              <DateCheckin
                start={walkin?.checkin}
                end={walkin?.checkout}
                handleEnd={handleCheckOut}
                minDate={true}
              />
            </div>
          </div>
          <div className="col pl-0">
            <div className="form-group mb-2" style={{ marginTop: "10px" }}>
              {inputContainer(
                "Kode Voucher",
                "RNA-XXX-XXX-XXX",
                walkin.voucherCode,
                (e) => handleVoucherCode(e),
                false,
                "text",
                validVoucher(),
                false,
                16
              )}
              <div className="invalid-feedback f6-vw">
                Kode Voucher Tidak tersedia
              </div>
            </div>
          </div>
          <div className="col pl-0">
            <div className="form-group mb-2">
              <label htmlFor="pemesan" className="f8-vw my-0 fw-700">
                <span className="red">* </span>Tamu dan Kamar
              </label>
              <div className="d-flex">
                <div className="col px-0 dropdown">
                  <div
                    className="form-control form-control-sm dropdown-toggle rounded-input py-0 d-flex align-items-center"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                    // style={{ height: "1.8rem" }}
                  >
                    <span className="f8-vw fw-500 ml-1">
                      {walkin?.guest} Tamu,
                    </span>
                    <span className="f8-vw fw-500 mx-1">
                      {walkin?.room} Kamar
                    </span>
                    <span style={{ position: "absolute", right: 10 }}>
                      <Icon
                        icon={iconUrl.arrowDownAlt2}
                        width={18}
                        color="#62148f"
                      />
                    </span>
                  </div>
                  <div
                    className="dropdown-menu py-0"
                    aria-labelledby="dropdownMenuButton"
                    style={{ width: "100%", zIndex: 5 }}
                  >
                    <div className="d-flex flex-column">
                      <div className="col d-flex justify-content-between align-items-center">
                        <div className="f8-vw fw-600">Tamu</div>
                        <div className="d-flex align-items-center">
                          <Count setValue={handleGuest} count={walkin?.guest} />
                        </div>
                      </div>
                      <div className="col py-0 d-flex justify-content-between align-items-center">
                        <div className="f8-vw fw-600">Kamar</div>
                        <div className="d-flex align-items-center">
                          <Count setValue={handleRoom} count={walkin?.room} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col pl-0">
            <div className="form-group mb-2" style={{ marginTop: 1 }}>
              <label htmlFor="type-room" className="f8-vw m-0 fw-700">
                <span className="red">* </span>Tipe Kamar
              </label>
              <SelectOpt
                optionsValue={typeRoom}
                onChange={handleSelect}
                placeholder={"Pilih Tipe Kamar"}
                value={roomSelected}
                height={"calc(1.5em + 0.75rem + 2px)"}
                loading={loadingAvailability}
              />
              {!availability && (
                <small className="f6-vw red">{errorAvail}</small>
              )}
            </div>
          </div>

          <div className="col pl-0 ">
            <div className="form-group" style={{ marginTop: 1 }}>
              <label className="f8-vw my-0 fw-700">Nomor Kamar</label>
              <div
                className="f8-vw px-2 form-control d-flex align-items-center"
                style={{
                  cursor: "pointer",
                  backgroundColor: "rgb(95,5,147,0.2)",
                  borderRadius: "5px",
                  padding: "0",
                }}
                onClick={
                  roomList?.length > 0
                    ? () => {
                        openModal();
                        setMode("room");
                        setStopFetch(true);
                      }
                    : () => {
                        openModal();
                        setMode("error");
                        setError("Tidak ada Nomor Kamar yang tersedia");
                        setImgPopup("/img/error-icon/room-not-available.png");
                      }
                }
              >
                <span className="purple">
                  {roomNumber?.length > 0
                    ? roomNumber.join(", ")
                    : "Pilih Nomor Kamar"}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="col-4 px-0">
        <div className="d-flex flex-column">
          <div className="col pr-0">
            <div className="my-1">
              <div className="">
                <HeaderName text="Informasi Tagihan" fontSize="f12-vw fw-700" />
              </div>
              <div className="my-1">
                {detailPayment(
                  "Total Harga Kamar",
                  loadingAvailability ? (
                    <span
                      className="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                  ) : !availability ? (
                    <span>-</span>
                  ) : (
                    priceSplit(roomPrice)
                  )
                )}
                {detailPayment(
                  "Early Check In",
                  loadingAvailability ? (
                    <span
                      className="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                  ) : !availability ? (
                    <span>-</span>
                  ) : (
                    priceSplit(parseInt(earlyCheckin))
                  )
                )}
                {depositInput("Deposit", deposit, (e) => handleDeposit(e))}
                {discount > 0 &&
                  availability &&
                  detailPayment(
                    "Diskon",
                    discount > 0
                      ? "-" + priceSplit(parseInt(discount))
                      : priceSplit(parseInt(discount))
                  )}
                <div className="">
                  <hr className="separator-grey my-2" />
                </div>
                {detailPayment(
                  "Total Tagihan",
                  !availability ? (
                    <span>-</span>
                  ) : (
                    priceSplit(parseInt(totalCharge))
                  ),
                  "fw-800"
                )}
              </div>
            </div>
          </div>

          <div className="col pr-0">
            <div className="">
              <HeaderName
                text="Foto KTP"
                fontSize="f8-vw fw-700"
                color1="black"
                color2="black"
              />
            </div>
            <div className="">
              <CameraWebcam
                setfileUpload={setfileUpload}
                title={"Upload foto KTP"}
                height="6.5rem"
                fileUpload={fileUpload}
                setShowImg={setShowImg}
                showImg={showImg}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
