import ReactTooltip from "react-tooltip";
import { HeaderName } from "../../../common/HeaderName";
import { IS_MOBILE } from "../../../common/Responsive";
import {
  getDayDuration,
  getServerTimeWithoutDays,
  parsePhone,
  priceSplit,
  showMoreGuest,
  splitText,
  showMore,
} from "../../../utils";
import {
  priceContainer,
  getDataContainer,
  mobileGetDataContainer,
  mobilePriceContainer,
} from "../helper";

export const CheckoutDetail = ({
  detailCheckout,
  compensation,
  lateCheckout,
  totalPaid,
  timezone,
  compensationClicked,
  backClicked,
  confirmClicked,
  penaltyCharge = 0,
  penaltyClicked = () => {},
}) => {
  return (
    <>
      {IS_MOBILE ? (
        <div className="d-flex flex-column mb-5">
          <div className="col px-2">
            <div className="">
              <span className="f12-vw grey">Kode Booking</span>
              <span className="ml-2 f12-vw fw-700">
                : {detailCheckout?.book_id || "-"}
              </span>
            </div>
          </div>
          <div className="col px-2 mt-1">
            <span className="grey f12-vw">Remaining Check Out</span>
            <span className="ml-2 f12-vw fw-700">
              : {detailCheckout?.checkout_remaining || "-"}
            </span>
          </div>
          <div className="col px-2 mt-2">
            <HeaderName
              text="Informasi Booking"
              fontSize="f14-vw fw-600"
              color2="purple"
            />
            <>
              {mobileGetDataContainer(
                "Nama Pemesan",
                showMore(detailCheckout?.booked_by, 25) || "-"
              )}
              {mobileGetDataContainer(
                "Email",
                showMore(detailCheckout?.email, 25) || "-"
              )}
              {mobileGetDataContainer(
                "Nomor Ponsel",
                detailCheckout?.phone ? parsePhone(detailCheckout?.phone) : "-"
              )}
              {mobileGetDataContainer(
                "Check In",
                detailCheckout?.checkin_date
                  ? getServerTimeWithoutDays(
                      detailCheckout?.checkin_date,
                      timezone
                    )
                  : "-"
              )}
              {mobileGetDataContainer(
                "Check Out",
                detailCheckout?.checkout_date
                  ? getServerTimeWithoutDays(
                      detailCheckout?.checkout_date,
                      timezone
                    )
                  : "-"
              )}
              {mobileGetDataContainer(
                "Durasi",
                detailCheckout?.checkout_date && detailCheckout?.checkin_date
                  ? getDayDuration(
                      detailCheckout?.checkout_date,
                      detailCheckout?.checkin_date
                    ) + " Malam"
                  : "-"
              )}
              {mobileGetDataContainer(
                "Tipe Kamar",
                detailCheckout?.room_type_name || "-"
              )}
            </>
          </div>
          <div className="col px-2 mt-3">
            <HeaderName
              text="Informasi Check In"
              fontSize="f14-vw fw-600"
              color2="purple"
            />
            <>
              {mobileGetDataContainer(
                "Nomor Kamar",
                detailCheckout?.room_number ? detailCheckout?.room_number : "-"
              )}
              {mobileGetDataContainer(
                "Nama Tamu",
                detailCheckout?.guest_name
                  ? showMore(detailCheckout?.guest_name, 25)
                  : "-"
              )}
            </>
          </div>
          <div className="col px-2 mt-3">
            <HeaderName
              text="Informasi Tagihan"
              fontSize="f14-vw fw-600"
              color2="purple"
            />
            <div className="mt-1">
              <div className="row no-gutters">
                <div className="col-4">
                  <div className="f12-vw text-black">Late Checkout</div>
                </div>
                <div className="col text-black">
                  {mobilePriceContainer(lateCheckout)}
                </div>
              </div>
              <>
                <div
                  className="d-flex py-1 align-items-center"
                  data-tip="Click for detail"
                >
                  <div className="col-4 px-0">
                    <div className="f12-vw text-black">Ganti Rugi</div>
                  </div>
                  <div
                    className="col px-0 f12-vw py-1"
                    onClick={compensationClicked}
                  >
                    <div className="d-flex justify-content-between align-items-center my-auto">
                      <div className="mr-auto">Rp.</div>
                      <div
                        className="text-right"
                        style={{
                          cursor: "pointer",
                          backgroundColor: "rgb(95,5,147,0.2)",
                          borderRadius: "5px",
                          width: "80%",
                          padding: 4,
                        }}
                      >
                        <span className="px-2">{priceSplit(compensation)}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <ReactTooltip
                  backgroundColor="white"
                  textColor="black"
                  className="data-class"
                  border
                />
              </>
              <>
                <div
                  className="d-flex py-1 align-items-center"
                  data-tip="Click for detail"
                >
                  <div className="col-4 px-0">
                    <div className="f12-vw text-black">Denda</div>
                  </div>
                  <div
                    className="col px-0 f12-vw py-1"
                    onClick={penaltyClicked}
                  >
                    <div className="d-flex justify-content-between align-items-center my-auto">
                      <div className="mr-auto">Rp.</div>
                      <div
                        className="text-right"
                        style={{
                          cursor: "pointer",
                          backgroundColor: "rgb(95,5,147,0.2)",
                          borderRadius: "5px",
                          width: "80%",
                          padding: 4,
                        }}
                      >
                        <span className="px-2">
                          {priceSplit(penaltyCharge)}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <ReactTooltip
                  backgroundColor="white"
                  textColor="black"
                  className="data-class"
                  border
                />
              </>
              <hr className="separator-blue mt-2 mb-3" />
              <div className="detail-information d-flex flex-row">
                <div className="col-4 px-0">
                  <div className="f12-vw font-weight-bold">Total Tagihan</div>
                </div>
                <div className="col px-0 f12-vw fw-700">
                  {mobilePriceContainer(totalPaid)}
                </div>
              </div>
            </div>
          </div>
          <div className="col px-2">
            <hr className="separator-blue" />
            <div className="d-flex justify-content-between mt-3">
              <div className="col-5 px-0">
                <button
                  className="btn btn-outline-purple btn-block f12-vw fw-700"
                  onClick={backClicked}
                >
                  Back
                </button>
              </div>
              <div className="col-5 px-0">
                <button
                  className={`btn text-white btn-block f12-vw fw-700 ${
                    detailCheckout?.room_number
                      ? "btn-gradient"
                      : "btn-secondary"
                  }`}
                  disabled={detailCheckout?.room_number ? false : true}
                  onClick={confirmClicked}
                >
                  Next
                </button>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="d-flex flex-column">
          <div className="col">
            <div className="">
              <div className="col px-0 f8-vw grey">Kode Booking</div>
              <div className="col px-0 f8-vw fw-700">
                {detailCheckout?.book_id || "-"}
              </div>
            </div>
          </div>
          <div className="col mt-2">
            <div className="col grey f8-vw px-0">Remaining Check Out</div>
            <div className="col px-0 f8-vw fw-700">
              {detailCheckout?.checkout_remaining || "-"}
            </div>
          </div>

          <div className="col mt-2">
            <div className="d-flex flex-row" style={{ height: "19rem" }}>
              <div className="col-4 pl-0">
                <HeaderName
                  text="Informasi Booking"
                  fontSize="f12-vw fw-600"
                  color2="purple"
                />
                <>
                  {getDataContainer(
                    "Nama Pemesan",
                    detailCheckout?.booked_by || "-"
                  )}
                  {getDataContainer(
                    "Email",
                    showMoreGuest(detailCheckout?.email, 25) || "-"
                  )}
                  {getDataContainer(
                    "Nomor Ponsel",
                    detailCheckout?.phone
                      ? parsePhone(detailCheckout?.phone)
                      : "-"
                  )}
                  {getDataContainer(
                    "Check In",
                    detailCheckout?.checkin_date
                      ? getServerTimeWithoutDays(
                          detailCheckout?.checkin_date,
                          timezone
                        )
                      : "-"
                  )}
                  {getDataContainer(
                    "Check Out",
                    detailCheckout?.checkout_date
                      ? getServerTimeWithoutDays(
                          detailCheckout?.checkout_date,
                          timezone
                        )
                      : "-"
                  )}
                  {getDataContainer(
                    "Durasi",
                    detailCheckout?.checkout_date &&
                      detailCheckout?.checkin_date
                      ? getDayDuration(
                          detailCheckout?.checkout_date,
                          detailCheckout?.checkin_date
                        ) + " Malam"
                      : "-"
                  )}
                  {getDataContainer(
                    "Tipe Kamar",
                    detailCheckout?.room_type_name || "-"
                  )}
                </>
              </div>
              <div className="col-4">
                <HeaderName
                  text="Informasi Check In"
                  fontSize="f12-vw fw-600"
                  color2="purple"
                />
                <>
                  {getDataContainer(
                    "Nomor Kamar",
                    detailCheckout?.room_number
                      ? detailCheckout?.room_number
                      : "-"
                  )}
                  {getDataContainer(
                    "Nama Tamu",
                    detailCheckout?.guest_name
                      ? showMoreGuest(splitText(detailCheckout?.guest_name))
                      : "-"
                  )}
                </>
              </div>
              <div className="col-4 pr-0">
                <HeaderName
                  text="Informasi Tagihan"
                  fontSize="f12-vw fw-600"
                  color2="purple"
                />
                <div className="mt-1">
                  <div className="row no-gutters">
                    <div className="col">
                      <div className="f8-vw text-black">Late Checkout</div>
                    </div>
                    <div className="col text-black">
                      {priceContainer(lateCheckout)}
                    </div>
                  </div>

                  <div
                    className="d-flex py-1 align-items-center"
                    data-tip="Click for detail"
                  >
                    <div className="col px-0">
                      <div className="f8-vw text-black">Ganti Rugi</div>
                    </div>
                    <div
                      className="col px-0 f8-vw py-1"
                      onClick={compensationClicked}
                    >
                      <div className="d-flex justify-content-between align-items-center my-auto">
                        <div className="mr-auto">Rp.</div>
                        <div
                          className="text-right"
                          style={{
                            cursor: "pointer",
                            backgroundColor: "rgb(95,5,147,0.2)",
                            borderRadius: "5px",
                            width: "80%",
                            padding: 4,
                          }}
                        >
                          <span className="px-2">
                            {priceSplit(compensation)}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <ReactTooltip
                    backgroundColor="white"
                    textColor="black"
                    className="data-class"
                    border
                  />
                  <div
                    className="d-flex py-1 align-items-center"
                    data-tip="Click for detail"
                  >
                    <div className="col px-0">
                      <div className="f8-vw text-black">Denda</div>
                    </div>
                    <div
                      className="col px-0 f8-vw py-1"
                      onClick={penaltyClicked}
                    >
                      <div className="d-flex justify-content-between align-items-center my-auto">
                        <div className="mr-auto">Rp.</div>
                        <div
                          className="text-right"
                          style={{
                            cursor: "pointer",
                            backgroundColor: "rgb(95,5,147,0.2)",
                            borderRadius: "5px",
                            width: "80%",
                            padding: 4,
                          }}
                        >
                          <span className="px-2">
                            {priceSplit(penaltyCharge)}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <ReactTooltip
                    backgroundColor="white"
                    textColor="black"
                    className="data-class"
                    border
                  />

                  <hr className="separator-blue mt-2 mb-3" />
                  <div className="detail-information d-flex flex-row">
                    <div className="col px-0">
                      <div className="f8-vw font-weight-bold">
                        Total Tagihan
                      </div>
                    </div>
                    <div className="col px-0 f8-vw fw-700">
                      {priceContainer(totalPaid)}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col mt-auto">
            <hr className="separator-blue" />
            <div className="d-flex justify-content-between mt-3">
              <div className="col-2 px-0">
                <button
                  className="btn btn-outline-purple btn-block f8-vw fw-700"
                  onClick={backClicked}
                >
                  Back
                </button>
              </div>
              <div className="col-2 px-0">
                <button
                  className={`btn text-white btn-block f8-vw fw-700 ${
                    detailCheckout?.room_number
                      ? "btn-gradient"
                      : "btn-secondary"
                  }`}
                  disabled={detailCheckout?.room_number ? false : true}
                  onClick={confirmClicked}
                >
                  Next
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
