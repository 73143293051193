import { Icon } from "@iconify/react";
import React, {
  useCallback,
  useContext,
  useEffect,
  useState,
  Fragment,
  useRef,
} from "react";
import { BreadcrumbContext } from "../../common/BreadcrumbContext";
import { MobileCardList } from "../../common/CardList";
import { t } from "../../common/ErrorMessage";
import NotifMessage, { ButtonClose } from "../../common/Notif";
import PopupModal from "../../common/PopupModal";
import { IS_MOBILE } from "../../common/Responsive";
import { iconUrl } from "../../config";
import {
  getClientTimeWithoutSecond,
  getServerTimeWithoutDays,
  getTimezoneOffsetClient,
  priceSplit,
  tableCol,
} from "../../utils";
import { addData, getData, getErr, safeErrCode } from "../../utils/api-request";
import { is_sub_menu_action } from "../../utils/uac";
import { EmptyData, MobileEmptyData } from "../Empty/EmptyData";

const FONT_SIZE = IS_MOBILE ? "f12-vw" : "f8-vw";
const FONT_SIZE_HEADER = IS_MOBILE ? "f16-vw" : "f12-vw";
const TIMEZONE =
  localStorage?.getItem("timezone") && localStorage?.getItem("timezone");

const TableWeeklyTransfers = ({
  list,
  loading,
  errorMessage,
  height,
  isOpen,
  setMode,
  totalCash,
  realCash,
  totalTransfer,
  loadingSubmit,
}) => {
  const timezone =
    localStorage?.getItem("timezone") && localStorage?.getItem("timezone");

  const renderItem = () => {
    return (
      <Fragment>
        {list.map((item, key) => {
          return (
            <tr key={key} className={tableCol(key)}>
              <td className="text-center align-middle font-weight-bold">
                {item?.real_shiftid}
              </td>
              <td className="text-center align-middle">
                {item?.opening_time ? (
                  <>
                    <div>
                      {getServerTimeWithoutDays(item?.opening_time, timezone)}
                    </div>
                    <div className="font-weight-bold">
                      {getClientTimeWithoutSecond(
                        item?.opening_time,
                        timezone
                      ) +
                        " " +
                        getTimezoneOffsetClient(timezone)}
                    </div>
                  </>
                ) : (
                  "-"
                )}
              </td>
              <td className="text-center align-middle">
                {item?.closing_time ? (
                  <>
                    <div>
                      {getServerTimeWithoutDays(item?.closing_time, timezone)}
                    </div>
                    <div className="font-weight-bold">
                      {getClientTimeWithoutSecond(
                        item?.closing_time,
                        timezone
                      ) +
                        " " +
                        getTimezoneOffsetClient(timezone)}
                    </div>
                  </>
                ) : (
                  "-"
                )}
              </td>
              <td className="text-center align-middle">{item?.full_name}</td>
              <td className="text-right align-middle">
                {priceSplit(item?.total_rev_trf)}
              </td>
              <td className="text-right align-middle">
                {priceSplit(item?.total_rev_cash)}
              </td>
              <td className="text-right align-middle">
                {priceSplit(item?.real_cash_on_hand)}
              </td>
            </tr>
          );
        })}
      </Fragment>
    );
  };

  return (
    <div className="d-flex flex-column">
      <div
        className="table-list table-responsive-sm scroll-table"
        style={{
          height: height ? height : "350px",
        }}
      >
        <table
          className="table table-sm m-0 "
          style={{
            borderCollapse: "separate",
            borderSpacing: 0,
          }}
        >
          <thead className="f8-vw sticky-table">
            <tr
              className="text-center text-white"
              style={{
                backgroundColor: "#0071A4",
              }}
            >
              <th rowSpan={2} className="align-middle">
                Shift ID
              </th>
              <th rowSpan={2} className="align-middle">
                Opening Time
              </th>
              <th rowSpan={2} className="align-middle">
                Closing Time
              </th>
              <th rowSpan={2} className="align-middle">
                Nama Kasir
              </th>
              <th colSpan={2} className="align-middle">
                Total Pendapatan
              </th>
              <th rowSpan={2} className="align-middle">
                <div>Real Cash</div>
                <div>on Hand</div>
              </th>
            </tr>
            <tr
              className="text-center text-white"
              style={{ backgroundColor: "#0071A4" }}
            >
              <th className="align-middle">Transfer Bank</th>
              <th className="align-middle">Cash</th>
            </tr>
          </thead>

          <tbody className="f8-vw">
            {list?.length > 0 && !loading ? (
              renderItem()
            ) : (
              <tr>
                <td colSpan={10} className="p-0">
                  <EmptyData
                    loading={loading}
                    errMessage={errorMessage}
                    sourceImg={"/img/setoran/process-setoran.svg"}
                    text="Semua setoran sudah diproses"
                    height={"21rem"}
                  />
                </td>
              </tr>
            )}
          </tbody>

          {list?.length > 0 && !loading && (
            <tfoot
              className="f8-vw fw-700 p-0"
              style={{
                position: "sticky",
                backgroundColor: "white",
                insetBlockEnd: 0,
                boxShadow: "0 3px 0 #0071A4 inset, 0 -3px 0 #0071A4 inset",
              }}
            >
              <tr
                className="p-0"
                style={{
                  backgroundColor: "#7FC3FF40",
                }}
              >
                <td colSpan={4} className="text-right">
                  Total (Rp)
                </td>
                <td className="text-right">{priceSplit(totalTransfer)}</td>
                <td className="text-right">{priceSplit(totalCash)}</td>
                <td className="text-right">{priceSplit(realCash)}</td>
              </tr>
              <tr
                className="p-0"
                style={{
                  backgroundColor: "#7FC3FF80",
                }}
              >
                <td colSpan={4} className="text-right">
                  Total Setoran (Rp)
                </td>
                <td colSpan={3} className="text-right">
                  {priceSplit(realCash)}
                </td>
              </tr>
            </tfoot>
          )}
        </table>
      </div>
      <div className="">
        <hr className="separator-blue my-2" />
      </div>
      {is_sub_menu_action("sf_setoran_process_setoran") && (
        <button
          className={`btn f8-vw fw-700 text-white px-4 ml-auto mt-3 ${
            list?.length > 0 && !loading ? "btn-gradient" : "bg-grey"
          }`}
          onClick={() => {
            isOpen();
            setMode("confirmation");
          }}
          disabled={list?.length > 0 && !loading ? false : true}
        >
          {loadingSubmit && (
            <span className="spinner-border spinner-border-sm mr-1"></span>
          )}
          Process
        </button>
      )}
    </div>
  );
};

const PopupSetoran = ({ setNote, note, totalCash, close, confirm, mode }) => {
  return (
    <div className="d-flex flex-column p-3" style={{ width: "21rem" }}>
      <div className="col">
        <div className="text-center">
          <img
            src={`/img/popup/${
              mode === "confirmation" ? "setoran.svg" : "setoran-success.svg"
            }`}
            alt="Setoran"
            loading="lazy"
          />
        </div>
      </div>
      <div className="col">
        <div className={"text-center fw-700 " + FONT_SIZE_HEADER}>
          {mode === "success-confirm"
            ? "Proses Setoran Berhasil"
            : "Proses Setoran"}
        </div>
        {mode === "success-confirm" && (
          <div className="text-center">
            <small className={FONT_SIZE}>
              Data berhasil disimpan, silahkan melanjutkan pembayaran di menu
              "Konfirmasi Setoran"
            </small>
          </div>
        )}
      </div>
      <div className="col">
        <div
          className="d-flex flex-column text-center p-3 my-2"
          style={{ backgroundColor: "#E8FEEA", borderRadius: 3 }}
        >
          <span className={"text-black fw-600 " + FONT_SIZE}>
            Total Setoran Cash
          </span>
          <span className={`${FONT_SIZE_HEADER} fw-700`}>
            Rp {priceSplit(totalCash)}
          </span>
        </div>
      </div>
      <div className="col">
        {mode === "confirmation" ? (
          <>
            <div
              className="py-1 mt-1"
              style={{
                borderRadius: "5px",
              }}
            >
              <div className="">
                <textarea
                  name="note"
                  id="note"
                  rows="5"
                  className={"form-control form-control-sm " + FONT_SIZE}
                  style={{
                    resize: "none",
                    backgroundColor: "white",
                  }}
                  onChange={(e) => setNote(e.target.value)}
                  value={note}
                  placeholder="Catatan..."
                />
              </div>
            </div>

            <hr className="separator-blue my-2" />

            <div className="text-center">
              <small className="f8-vw">
                Apakah anda yakin ingin melanjutkan?
              </small>
            </div>
          </>
        ) : (
          <>
            {note && (
              <div
                className="p-2 scroll-popup mt-2"
                style={{
                  backgroundColor: "white",
                  borderRadius: 5,
                  height: "7rem",
                }}
              >
                <span className={"text-justify " + FONT_SIZE}>
                  {note ? note : "-"}
                </span>
              </div>
            )}
          </>
        )}
      </div>
      <div className="col">
        {mode === "confirmation" ? (
          <div className="d-flex justify-content-between mt-2">
            <div className="col-6 pl-0">
              <button
                className={
                  "btn btn-sm btn-block btn-outline-purple " + FONT_SIZE
                }
                onClick={() => close()}
              >
                Close
              </button>
            </div>
            <div className="col-6 pr-0">
              <button
                className={
                  "btn btn-sm btn-block btn-gradient text-white " + FONT_SIZE
                }
                onClick={() => confirm()}
              >
                Confirm
              </button>
            </div>
          </div>
        ) : (
          <div className="d-flex justify-content-center mt-2">
            <div className="col-6 px-0">
              <button
                className={
                  "btn btn-sm btn-block bg-green text-white " + FONT_SIZE
                }
                onClick={() => close()}
              >
                OK
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

const SetoranMingguan = () => {
  const mounted = useRef(false);
  const { setpathLocation } = useContext(BreadcrumbContext);
  const [weeklyTransfers, setWeeklyTransfer] = useState([]);
  const [totalCash, setTotalCash] = useState(0);
  const [totalTransfer, setTotalTransfer] = useState(0);
  const [realCash, setRealCash] = useState(0);
  const [openModal, setOpenModal] = useState(false);
  const [mode, setMode] = useState("");
  const [loadingList, setLoadingList] = useState(false);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [errorList, setErrorList] = useState("");
  const [errorSubmit, setErrorSubmit] = useState("");
  const [headerPopup, setHeaderPopup] = useState("");
  const [note, setNote] = useState("");

  const isOpen = () => {
    setOpenModal(!openModal);
  };

  const submitWeeklyTransfers = () => {
    setLoadingSubmit(true);
    const payload = {
      note: note,
    };

    const formData = new FormData();
    for (const key in payload) {
      formData.append(key, payload[key]);
    }

    // for (const pair of formData.entries()) {
    //   console.log(pair[0] + " - " + pair[1]);
    // }

    addData("/api/v1/setoran/create", formData)
      .then((resp) => {
        setLoadingSubmit(false);
        if (resp.message === "Success") {
          setMode("success-confirm");
        } else {
          resp.json().then((err) => {
            setErrorSubmit(t(safeErrCode(err)));
          });
          setMode("error");
          setHeaderPopup("Proses Setoran Gagal");
        }
      })
      .catch((error) => {
        console.error(error);
        setLoadingSubmit(false);
        setErrorSubmit(t(getErr(error)));
        setMode("error");
        setHeaderPopup("Proses Setoran Gagal");
      });
  };

  const getWeeklyTransfers = useCallback(() => {
    setLoadingList(true);
    getData("/api/v1/setoran/list")
      .then((resp) => {
        setLoadingList(false);
        if (resp.data) {
          if (mounted.current) {
            // const group = resp.data.reduce((r, a) => {
            //   const date = getServerTimeWithoutDays(a.closing_time);
            //   r[date] = [...(r[date] || []), a];
            //   return r;
            // }, {});

            // const realCashOnHand = resp.data.reduce((acc, item) => {
            //   return acc + item.real_cash_on_hand;
            // }, 0);
            // const totalTransfer = resp.data.reduce((acc, item) => {
            //   return acc + item.transfer;
            // }, 0);

            // const shiftIdsTemp = [];

            // resp.data.forEach((item) => {
            //   shiftIdsTemp.push(item.real_shiftid);
            // });

            setTotalCash(resp.total_cash);
            setTotalTransfer(resp.total_trf);
            setRealCash(resp.total_coh);
            setWeeklyTransfer(resp.data);
          }
        } else {
          resp.json().then((err) => {
            setErrorList(t(safeErrCode(err)));
          });
          setTotalCash(0);
          setTotalTransfer(0);
          setWeeklyTransfer([]);
        }
      })
      .catch((error) => {
        console.log(error);
        setErrorList(t(getErr(error)));
        setLoadingList(false);
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mounted]);

  const reset = () => {
    setWeeklyTransfer([]);
    setTotalCash(0);
    setTotalTransfer(0);
    setOpenModal(false);
    setMode("");
    setLoadingList(false);
    setLoadingSubmit(false);
    setErrorList("");
    setErrorSubmit("");
    setHeaderPopup("");
    setNote("");
  };

  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);

  useEffect(() => {
    getWeeklyTransfers();

    return () => {
      setWeeklyTransfer([]);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getWeeklyTransfers, openModal]);

  useEffect(() => {
    setpathLocation("/shift/setoran-cash/proses-setoran");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (openModal) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
    return () => {
      document.body.style.overflow = "unset";
    };
  }, [openModal]);

  return (
    <>
      <PopupModal modalIsOpen={openModal}>
        {mode === "confirmation" ? (
          <PopupSetoran
            setNote={setNote}
            note={note}
            totalCash={realCash}
            close={reset}
            confirm={submitWeeklyTransfers}
            mode={mode}
          />
        ) : mode === "success-confirm" ? (
          <PopupSetoran
            note={note}
            totalCash={realCash}
            close={reset}
            mode={mode}
          />
        ) : (
          <>
            <NotifMessage
              closeModal={openModal}
              img={"/img/error-icon/setoran-mingguan-gagal.png"}
              messageHeader={headerPopup}
              color="red"
              messageBody={errorSubmit}
            />
            <ButtonClose close={reset} text="OK" />
          </>
        )}
      </PopupModal>
      {IS_MOBILE ? (
        <div className="d-flex flex-column px-3 py-1 mt-1 mb-5">
          <div className="col px-1">
            {weeklyTransfers?.length > 0 ? (
              weeklyTransfers?.map((item, index) => (
                <MobileCardList
                  key={item?.real_shiftid}
                  title={`Shift ID : ${item?.real_shiftid}`}
                >
                  <div className="card-hms-list">
                    <div className="col-5 px-0 grey-text">Nama Kasir</div>
                    <div className="col pr-0 f12-vw black-value">
                      {item?.full_name}
                    </div>
                  </div>
                  <div className="card-hms-list">
                    <div className="col-5 px-0 grey-text">Opening Time</div>
                    <div className="col pr-0 f12-vw black-value">
                      <span>
                        {getServerTimeWithoutDays(item?.opening_time, TIMEZONE)}
                        ,
                      </span>
                      <span className="fw-700">
                        {` ${getClientTimeWithoutSecond(
                          item?.opening_time,
                          TIMEZONE
                        )}
                          ${getTimezoneOffsetClient(TIMEZONE)}`}
                      </span>
                    </div>
                  </div>
                  <div className="card-hms-list">
                    <div className="col-5 px-0 grey-text">Closing Time</div>
                    <div className="col pr-0 f12-vw black-value">
                      <span>
                        {getServerTimeWithoutDays(item?.closing_time, TIMEZONE)}
                        ,
                      </span>
                      <span className="fw-700">
                        {` ${getClientTimeWithoutSecond(
                          item?.closing_time,
                          TIMEZONE
                        )}
                          ${getTimezoneOffsetClient(TIMEZONE)}`}
                      </span>
                    </div>
                  </div>
                  <div className="card-hms-list">
                    <div className="col-5 px-0 purple fw-700">
                      Total Pendapatan
                    </div>
                  </div>
                  <div className="card-hms-list">
                    <div className="col-5 px-0 grey-text">Transfer Bank</div>
                    <div className="col pr-0">
                      <div className="d-flex justify-content-between">
                        <span className="f12-vw black-value">Rp</span>
                        <span className="f12-vw black-value">
                          {priceSplit(item?.total_rev_trf)}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="card-hms-list">
                    <div className="col-5 px-0 grey-text">Cash</div>
                    <div className="col pr-0">
                      <div className="d-flex justify-content-between">
                        <span className="f12-vw black-value">Rp</span>
                        <span className="f12-vw black-value">
                          {priceSplit(item?.total_rev_cash)}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="card-hms-list">
                    <div className="col-5 px-0 grey-text fw-700">
                      Real Cash On Hand
                    </div>
                    <div className="col pr-0">
                      <div className="d-flex justify-content-between">
                        <span className="f12-vw black-value fw-700">Rp</span>
                        <span className="f12-vw black-value fw-700">
                          {priceSplit(item?.real_cash_on_hand)}
                        </span>
                      </div>
                    </div>
                  </div>
                </MobileCardList>
              ))
            ) : (
              <div style={{ position: "relative" }}>
                <MobileEmptyData
                  errMessage={errorList}
                  loading={loadingList}
                  height="40vh"
                />
              </div>
            )}
            {weeklyTransfers?.length > 0 && (
              <>
                <hr className="separator-blue" />
                <div className="my-3 d-flex align-items-center">
                  <span className="mr-1">
                    <Icon
                      icon={iconUrl.walletCreditCard20Filled}
                      color="orange"
                      width={25}
                    />
                  </span>
                  <span className="f14-vw fw-700 purple">Total</span>
                </div>
                <div
                  className="d-flex align-items-center p-2"
                  style={{ backgroundColor: "#7FC3FF40" }}
                >
                  <div className="col-5 px-0 f12-vw">Total Transfer Bank</div>
                  <div className="col pr-1">
                    <div className="d-flex justify-content-between">
                      <span className="f12-vw">Rp</span>
                      <span className="f12-vw fw-700">
                        {priceSplit(totalTransfer)}
                      </span>
                    </div>
                  </div>
                </div>
                <div
                  className="d-flex align-items-center p-2"
                  style={{ backgroundColor: "#7FC3FF40", marginTop: 2 }}
                >
                  <div className="col-5 px-0 f12-vw">Total Cash</div>
                  <div className="col pr-1">
                    <div className="d-flex justify-content-between">
                      <span className="f12-vw">Rp</span>
                      <span className="f12-vw fw-700">
                        {priceSplit(totalCash)}
                      </span>
                    </div>
                  </div>
                </div>
                <div
                  className="d-flex align-items-center p-2"
                  style={{ backgroundColor: "#7FC3FF40", marginTop: 2 }}
                >
                  <div className="col-5 px-0 f12-vw">Real Cash on Hand</div>
                  <div className="col pr-1">
                    <div className="d-flex justify-content-between">
                      <span className="f12-vw">Rp</span>
                      <span className="f12-vw fw-700">
                        {priceSplit(realCash)}
                      </span>
                    </div>
                  </div>
                </div>
                <div
                  className="d-flex align-items-center p-2"
                  style={{ backgroundColor: "#7FC3FF80", marginTop: 2 }}
                >
                  <div className="col-5 px-0 f12-vw fw-700">Total Setoran</div>
                  <div className="col pr-1">
                    <div className="d-flex justify-content-between">
                      <span className="f12-vw fw-700">Rp</span>
                      <span className="f12-vw fw-700">
                        {priceSplit(realCash)}
                      </span>
                    </div>
                  </div>
                </div>
              </>
            )}

            {is_sub_menu_action("sf_setoran_process_setoran") &&
              weeklyTransfers?.length > 0 && (
                <div className="d-flex justify-content-end mt-3">
                  <div className="col-6 px-0">
                    <button
                      className={`btn btn-block f12-vw fw-700 text-white px-4 ${
                        weeklyTransfers?.length > 0 && !loadingList
                          ? "btn-gradient"
                          : "bg-grey"
                      }`}
                      onClick={() => {
                        isOpen();
                        setMode("confirmation");
                      }}
                      disabled={
                        weeklyTransfers?.length > 0 && !loadingList
                          ? false
                          : true
                      }
                    >
                      {loadingSubmit && (
                        <span className="spinner-border spinner-border-sm mr-1"></span>
                      )}
                      Process
                    </button>
                  </div>
                </div>
              )}
          </div>
        </div>
      ) : (
        <div className="px-3 py-2">
          <div className="my-2 px-3">
            <TableWeeklyTransfers
              list={weeklyTransfers}
              loading={loadingList}
              errorMessage={errorList}
              isOpen={isOpen}
              setMode={setMode}
              totalCash={totalCash}
              totalTransfer={totalTransfer}
              loadingSubmit={loadingSubmit}
              realCash={realCash}
              height="25rem"
            />
          </div>
        </div>
      )}
    </>
  );
};
export default SetoranMingguan;
