import React, { useCallback, useRef } from "react";
import Resizer from "react-image-file-resizer";

export const MobileUploadFile = ({
  setfileUpload,
  title,
  height,
  showImg,
  setShowImg,
  fontSize = "f8-vw",
}) => {
  const cameraRef = useRef(null);
  const inputRef = useRef(null);

  const handleOpenFileInput = useCallback(() => {
    // setfileUpload([]);
    // setShowImg(null);
    setTimeout(() => {
      inputRef.current.click();
    }, 500);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputRef]);
  const handleOpenCamera = useCallback(() => {
    // setfileUpload([]);
    // setShowImg(null);
    setTimeout(() => {
      cameraRef.current.click();
    }, 500);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputRef]);

  const resizeFile = (file) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        640,
        480,
        "png",
        50,
        0,
        (uri) => {
          resolve(uri);
        },
        "file"
      );
    });

  const handleFile = async (val) => {
    let file = val.target.files;
    if (file.length > 0) {
      let resize = await resizeFile(file[0]);
      setfileUpload([resize]);
      setShowImg(URL.createObjectURL(resize));
    }
  };

  const CustomUpload = ({ fileUpload, onChange, title, ...inputProps }) => {
    return (
      <div>
        <div
          className=""
          style={{
            padding: 0,
          }}
          onClick={() => handleOpenFileInput()}
        >
          {fileUpload !== undefined &&
          fileUpload !== null &&
          fileUpload !== "" ? (
            <img
              src={fileUpload}
              alt="KTP"
              style={{ objectFit: "contain", height: height }}
              width="100%"
            />
          ) : (
            <div
              className="d-flex flex-column justify-content-center align-items-center px-0"
              style={{
                border: "2px dashed lightgray",
                height: height,
                borderRadius: 5,
                width: "100%",
                backgroundColor: "#ececec",
              }}
            >
              <div className="">
                <img src="/img/icon/icon-upload.svg" alt="" width={30} />
              </div>
              <div className={"blue fw-600 " + fontSize}>
                {title ? title : "Upload Foto"}
              </div>
            </div>
          )}
        </div>
        <input
          type="file"
          ref={inputRef}
          accept="image/*"
          onChange={(e) => onChange(e)}
          style={{
            display: "none",
          }}
          {...inputProps}
        />
      </div>
    );
  };
  const CustomTakePhoto = ({ onChange }) => {
    return (
      <div>
        <input
          type="file"
          ref={cameraRef}
          accept="image/*"
          onChange={(e) => onChange(e)}
          style={{
            display: "none",
          }}
          capture="camera"
        />
      </div>
    );
  };
  return (
    <div>
      <CustomUpload fileUpload={showImg} onChange={handleFile} title={title} />
      <CustomTakePhoto onChange={handleFile} />
      <div className="d-flex flex-row justify-content-between py-2">
        <div className="col pl-0">
          <button
            className={"btn btn-block purple " + fontSize}
            style={{ backgroundColor: "rgb(95,5,147,0.2)", border: "none" }}
            onClick={handleOpenCamera}
          >
            Take Photo
          </button>
        </div>
        <div className="col pr-0">
          <button
            className={"btn btn-block purple " + fontSize}
            style={{ backgroundColor: "rgb(95,5,147,0.2)", border: "none" }}
            onClick={handleOpenFileInput}
          >
            Browse File
          </button>
        </div>
      </div>
    </div>
  );
};
