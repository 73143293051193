import { Icon } from "@iconify/react";
import CurrencyInput from "react-currency-input-field";
import ReactTooltip from "react-tooltip";
import {
  filterStatusActivity,
  filterStatusColor,
  filterStatusRoom,
  priceSplit,
  splitDate,
  tableCol,
} from ".";
import { iconUrl } from "../config";
import { EmptyData } from "../pages/Empty/EmptyData";
import { IS_MOBILE } from "../common/Responsive";

export const appendInputBox = (
  title,
  value,
  onChange,
  bgcolor,
  textAlign,
  color
) => {
  return (
    <div
      className="deposit f8-vw rounded-input"
      style={{ border: "1px solid #ececec", borderRadius: "5px" }}
    >
      <div className="row no-gutter">
        <div className="col-6 pr-0">
          <div
            className="deposit-title input-right-radius py-1"
            style={{
              backgroundColor: bgcolor,
              textAlign: textAlign,
              fontWeight: 400,
              borderRadius: "5px",
              color: color ? color : "black",
            }}
          >
            {title}
          </div>
        </div>
        <div className="col-6 my-auto px-0">
          <span>Rp.</span>
          <CurrencyInput
            className="border-0 pr-0 text-right"
            value={parseInt(value)}
            onValueChange={onChange}
            style={{ backgroundColor: "transparent", width: "75%" }}
            min={0}
          />
        </div>
      </div>
    </div>
  );
};

export const checkBoxDeposit = (
  title,
  value,
  isChecked,
  isCheckedBox,
  disabled
) => {
  return (
    <>
      <div className="col-6 d-flex align-items-center">
        <input
          type="checkbox"
          readOnly
          checked={isChecked}
          onClick={isCheckedBox}
          data-tip="Include Deposit"
          disabled={disabled ? disabled : false}
        />
        <ReactTooltip
          backgroundColor="grey"
          textColor="white"
          className="data-class"
          border
        />
        <label
          className={`form-check-label f8-vw mx-1 ${
            isChecked ? "" : "disable-color"
          }`}
          htmlFor="defaultCheck1"
        >
          {title}
        </label>
      </div>
      <div
        className={`col-6 px-0 f8-vw d-flex align-items-center justify-content-between ${
          isChecked ? "" : "disable-color"
        }`}
      >
        <div className="mr-auto">Rp.</div>
        <div className="mr-2">{priceSplit(value)}</div>
      </div>
    </>
  );
};

export const searchInput = (
  onChange,
  value,
  placeholder,
  onClick,
  onKeyPress
) => {
  return (
    <div className="search-master">
      <div className="input-group">
        <input
          type="text"
          className="form-control form-control-sm f7-vw"
          placeholder={placeholder}
          aria-label="Example text with button addon"
          aria-describedby="button-addon1"
          onChange={onChange}
          value={value}
          onKeyPress={onKeyPress ? (e) => onKeyPress(e) : null}
        />
        <div className="input-group-append">
          <div
            className="input-group bg-primaryCol px-2"
            id="basic-addon2"
            style={{
              position: "relative",
              width: "2rem",
              borderRadius: "0 5px 5px 0",
              cursor: "pointer",
            }}
          >
            <div className="bg-search" onClick={onClick}>
              <Icon icon={iconUrl.searchIcon} color="white" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export const addNewButton = (onClick) => {
  return (
    <div className="add-new">
      <button className="btn btn-sm" onClick={onClick}>
        <span className="mr-2">
          <Icon icon="fa-solid:plus-circle" color="#ef9403" />
        </span>
        <span className="f8-vw">Add New</span>
      </button>
    </div>
  );
};

export const listTable = (
  tableHeader,
  list,
  trTable,
  loading,
  errorMessage,
  sourceImg,
  height
) => {
  return (
    <div
      className="table-list scroll-page table-border-line"
      style={{
        height: height ? height : "350px",
      }}
    >
      <table
        className="table table-sm"
        style={{
          borderCollapse: "separate",
          borderSpacing: 0,
        }}
      >
        <thead className="f8-vw sticky-table">
          <tr
            className="text-center text-white"
            style={{ backgroundColor: "#0071A4" }}
          >
            {tableHeader?.length > 0 &&
              tableHeader.map((item, i) => {
                return (
                  <th className={"align-middle py-2"} key={i}>
                    {item}
                  </th>
                );
              })}
          </tr>
        </thead>

        {list?.length > 0 && !loading ? (
          list?.map((item, i) => {
            return trTable(item, i);
          })
        ) : (
          <tbody>
            <tr>
              <td colSpan={tableHeader?.length}>
                <EmptyData
                  loading={loading}
                  errMessage={errorMessage}
                  sourceImg={sourceImg}
                  height="18rem"
                />
              </td>
            </tr>
          </tbody>
        )}
      </table>
    </div>
  );
};

export const Placeholder = ({ labelFontSize = "f7-vw" }) => {
  return (
    <div className="d-flex flex-row align-items-center">
      <div className="mx-2 ">
        <Icon icon={iconUrl.folderPlus} width={20} />
      </div>
      <div className={labelFontSize} style={{ position: "relative", top: 1 }}>
        ADD AN ATTACHMENT
      </div>
    </div>
  );
};

export const tableScroll = (
  tableHeader,
  list,
  trTable,
  loading,
  errorMessage,
  height
) => {
  return (
    <div
      className="table-list table-responsive-sm scroll-table table-report"
      style={{
        height: height ? height : "300px",
      }}
    >
      <table className="table table-borderless table-sm">
        <thead className="f7-vw sticky-table">
          <tr
            className="text-black text-center text-white"
            style={{ backgroundColor: "#51b7ab" }}
          >
            {tableHeader?.length > 0 &&
              tableHeader.map((item, i) => {
                return (
                  <th
                    className={
                      i === 0
                        ? "top-left-rounded align-middle"
                        : i === tableHeader?.length - 1
                        ? "top-right-rounded align-middle"
                        : "align-middle"
                    }
                    key={i}
                  >
                    {item}
                  </th>
                );
              })}
          </tr>
        </thead>

        {list?.length > 0 && !loading ? (
          list?.map((item, i) => {
            return trTable(item, i);
          })
        ) : (
          <tbody>
            <tr>
              <td colSpan={tableHeader?.length}>
                <EmptyData loading={loading} errMessage={errorMessage} />
              </td>
            </tr>
          </tbody>
        )}
      </table>
    </div>
  );
};

export const comboBoxDownloadReport = (link, onClickCSV, onClickXLS) => {
  return (
    <div className="combobox-download">
      <div className="dropdown">
        <button
          className={`btn dropdown-toggle text-white ${
            IS_MOBILE ? "f12-vw" : "f8-vw"
          }`}
          // style={{ backgroundColor: "#1F6E43" }}
          type="button"
          id="dropdownMenu2"
          data-toggle="dropdown"
          aria-expanded="false"
        >
          <span className="mr-2">
            <img
              src="/img/icon/export.svg"
              alt="export"
              style={{ width: 20 }}
            />
          </span>
          <span className="fw-700" style={{ color: "#1F6E43" }}>
            Export
          </span>
        </button>
        <div
          className={`dropdown-menu dropdown-menu-${
            IS_MOBILE ? "left" : "right"
          }`}
          aria-labelledby="dropdownMenu2"
        >
          <div className="d-flex flex-column">
            {onClickCSV && (
              <a
                className={`menu border-0 purple text-center font-weight-bold ${
                  IS_MOBILE ? "f12-vw" : "f8-vw"
                }`}
                onClick={(e) => {
                  onClickCSV(e);
                }}
                ref={link}
                href="no_use"
              >
                CSV
              </a>
            )}
            {onClickXLS && (
              <a
                className={`menu border-0 purple text-center font-weight-bold ${
                  IS_MOBILE ? "f12-vw" : "f8-vw"
                }`}
                onClick={(e) => {
                  onClickXLS(e);
                }}
                ref={link}
                href="no_use"
              >
                XLSX
              </a>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export const roomActivityTable = (
  tableHeader,
  list,
  dateRange,
  loading,
  errorMessage,
  height
) => {
  return (
    <div
      className="room-table scroll-table top-left-rounded"
      style={{
        height: height ? height : "350px",
        scrollBehavior: "smooth",
      }}
    >
      <table className="table table-borderless table-sm top-left-rounded">
        <thead
          className="f7-vw top-left-rounded"
          style={{ position: "sticky", top: 0, zIndex: 99 }}
        >
          <tr
            className="text-black text-center text-white top-left-rounded"
            style={{
              backgroundColor: "#949494",
              zIndex: 99,
            }}
          >
            <th
              className="align-middle top-left-rounded sticky-th px-3"
              style={{ backgroundColor: "#949494", right: 0 }}
              rowSpan={2}
            >
              No Kamar
            </th>
            <th
              className="col align-middle"
              rowSpan={2}
              style={{
                position: "sticky",
                zIndex: 99,
                left: "99.5px",
                backgroundColor: "#949494",
              }}
            >
              Tipe Kamar
            </th>
            {tableHeader?.length > 0 && (
              <th
                className="col align-middle top-right-rounded"
                rowSpan={1}
                colSpan={21}
                style={{
                  position: "sticky",
                }}
              >
                Date
                <div className="border"></div>
              </th>
            )}
          </tr>
          <tr
            className="text-black text-center text-white top-left-rounded "
            style={{
              backgroundColor: "#949494",
              zIndex: 99,
            }}
          >
            {tableHeader?.length > 0 &&
              tableHeader?.map((item, i) => {
                return (
                  <th
                    className="align-middle"
                    key={i}
                    style={{
                      width: 60,
                      overflow: "hidden",
                      borderCollapse: "separate",
                    }}
                  >
                    {splitDate(item)}
                  </th>
                );
              })}
          </tr>
        </thead>
        <tbody className="f7-vw" style={{ height: 100 }}>
          {list?.data?.length > 0 && !loading ? (
            list?.rooms?.map((item, i) => {
              // console.log(list);
              return (
                <tr className="set-overflow" style={{ zIndex: 0 }} key={i}>
                  <td className={"align-middle sticky-td " + tableCol(i)}>
                    <div className="text-center" style={{ width: "90px" }}>
                      {item?.room_number}
                    </div>
                  </td>
                  <td
                    className={"align-middle " + tableCol(i)}
                    style={{
                      position: "sticky",
                      zIndex: 0,
                      left: "99.5px",
                    }}
                  >
                    {item?.room_type_name}
                  </td>
                  {dateRange.map((data, index) => {
                    return (
                      <td
                        className={`align-middle text-center f7-vw`}
                        key={index}
                        style={{ border: "1px solid rgb(191,191,191)" }}
                      >
                        <div
                          className={`align-middle text-center py-0 px-1 ${filterStatusColor(
                            filterStatusActivity(
                              list.data,
                              item.room_number,
                              data
                            )
                          )}`}
                          style={{
                            borderRadius: 5,
                            width: 35,
                            fontWeight: "bold",
                          }}
                        >
                          {filterStatusRoom(
                            filterStatusActivity(
                              list.data,
                              item.room_number,
                              data
                            )
                          )}
                        </div>
                      </td>
                    );
                  })}
                </tr>
              );
            })
          ) : (
            <tr>
              <td colSpan={tableHeader?.length}>
                <EmptyData loading={loading} errMessage={errorMessage} />
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export const changeMonth = () => {
  return (
    <div
      className="py-1"
      style={{ border: "1px solid lightgrey", borderRadius: 5 }}
    >
      <div className="d-flex flex-row justify-content-around align-items-center">
        <div
          className="d-flex align-items-center"
          style={{ borderRight: "1px solid black" }}
        >
          <button className="btn-transparent border-0">
            <Icon icon="ep:arrow-left-bold" width={15} />
          </button>
        </div>
        <div className="f10-vw d-flex ">
          <span>March 2022</span>
        </div>
        <div
          className="d-flex align-items-center"
          style={{ borderLeft: "1px solid black" }}
        >
          <button className="btn-transparent border-0">
            <Icon icon="ep:arrow-right-bold" width={15} />
          </button>
        </div>
      </div>
    </div>
  );
};

export const comboApproveReject = (title1, title2, approveDepo, rejectDepo) => {
  return (
    <div className="combo-box">
      <div className="dropdown">
        <button
          className="bg-transparent dropdown-toggle border-0 purple"
          type="button"
          id="dropdownMenu2"
          data-toggle="dropdown"
          aria-expanded="false"
        >
          Pilih
        </button>
        <div className="dropdown-menu" aria-labelledby="dropdownMenu2">
          <div className="d-flex flex-column">
            <button
              className="menu border-0 purple bg-transparent f8-vw font-weight-bold "
              onClick={approveDepo}
            >
              {title1}
            </button>
            <button
              className="menu border-0 purple bg-transparent f8-vw font-weight-bold "
              onClick={rejectDepo}
            >
              {title2}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
