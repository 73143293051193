import React from "react";
import { Icon } from "@iconify/react";
import { HeaderName } from "../../../common/HeaderName";
// import { IS_MOBILE } from "../../../common/Responsive";
import { iconUrl } from "../../../config";
import {
  bucketRnaWeb,
  dateFormat,
  filterGuestType,
  filterPurpose,
  getDayDuration,
  getServerTimeWithoutDays,
  oss,
  parsePhone,
  priceSplit,
  showMoreGuest,
} from "../../../utils";
import { img_fallback } from "../../../utils/ui";
import { IS_MOBILE } from "../../../common/Responsive";

const FONT_SIZE = IS_MOBILE ? "f12-vw" : "f8-vw";
const FONT_SIZE_TITLE = IS_MOBILE ? "f14-vw" : "f10-vw";

export const ConfirmReschedule = ({
  item,
  formEdit,
  close,
  confirm,
  charge,
  errorCharge,
}) => {
  const {
    ota_booking_id,
    ota_name,
    ota_booking_date,
    booked_by,
    phone,
    email,
    checkin_date,
    checkout_date,
    room_type_name,
    room_booked,
    guest_qty,
    guest_type_id,
    purpose_id,
    ota_booking_evidence,
  } = item;

  const detailInformation = (title, value) => {
    return (
      <div className={`d-flex align-items-center ${FONT_SIZE} fw-500 mt-2`}>
        <div className="col-6 px-0 grey">{title}</div>
        <div className="col-6 px-0">: {value || "-"}</div>
      </div>
    );
  };

  return (
    <div
      className={`d-flex flex-column ${FONT_SIZE} p-3`}
      style={{ width: IS_MOBILE ? "21rem" : "35rem" }}
    >
      <div className="col">
        <div className="d-flex align-items-center">
          <span>
            <Icon
              icon={iconUrl.formMultiple20Filled}
              width={25}
              color="orange"
            />
          </span>
          <span className={`purple font-weight-bold ${FONT_SIZE_TITLE}`}>
            Konfirmasi Reschedule
          </span>
        </div>
        <hr className="separator-grey my-2" />
      </div>
      <div className="col">
        <div
          className={`d-flex flex-column flex-md-row ${IS_MOBILE &&
            "scroll-popup hide-scrollbar"}`}
          style={{ maxHeight: IS_MOBILE ? "21rem" : "29rem" }}
        >
          <div className="col px-0">
            <div className="">
              <HeaderName
                text="Kode Booking"
                fontSize={`${FONT_SIZE_TITLE} fw-600`}
                color2="purple"
              />
              <div className={FONT_SIZE}>{ota_booking_id || "-"}</div>
            </div>
            <div className="mt-2">
              <HeaderName
                text="Informasi Tamu"
                fontSize={`${FONT_SIZE_TITLE} fw-600`}
                color2="purple"
              />
            </div>
            <div className="my-1">
              {detailInformation("Nama OTA", ota_name)}
              {detailInformation(
                "Tanggal Booking",
                getServerTimeWithoutDays(ota_booking_date)
              )}
              {detailInformation("Nama Pemesan", showMoreGuest(booked_by, 10))}
              {detailInformation(
                "Nomor Ponsel",
                showMoreGuest(parsePhone(phone), 10)
              )}
              {detailInformation("Email", showMoreGuest(email))}
            </div>
            <div className="mt-2">
              <HeaderName
                text="Informasi Pemesanan"
                fontSize={`${FONT_SIZE_TITLE} fw-600`}
                color2="purple"
              />
            </div>
            <div className="my-1">
              {detailInformation("Check In", dateFormat(formEdit?.checkin))}
              {detailInformation("Check Out", dateFormat(formEdit?.checkout))}
              {detailInformation(
                "Durasi",
                getDayDuration(checkin_date, checkout_date) + " Malam"
              )}
              {detailInformation("Tipe Kamar", room_type_name)}
              {detailInformation(
                "Tamu & Kamar",
                guest_qty + " Tamu, " + room_booked + " Kamar"
              )}
              {detailInformation("Purpose", filterPurpose(purpose_id)?.label)}
              {detailInformation(
                "Guest Type",
                filterGuestType(guest_type_id)?.label
              )}
            </div>
          </div>
          <div className="col px-0">
            <div className="my-1">
              <HeaderName
                text="Bukti Pemesanan"
                fontSize={`${FONT_SIZE_TITLE} fw-600`}
                color2="purple"
              />
              <div className="mt-2">
                <img
                  src={`${bucketRnaWeb + ota_booking_evidence}?${oss}`}
                  alt=""
                  className="img-fluid"
                  style={{
                    objectFit: "cover",
                    maxHeight: "12rem",
                    width: "100%",
                  }}
                  onError={({ currentTarget }) => {
                    currentTarget.onerror = null;
                    currentTarget.src = img_fallback;
                  }}
                  loading="lazy"
                />
              </div>
            </div>
            <div className="mt-4">
              <HeaderName
                text="Total Charge"
                fontSize={`${FONT_SIZE_TITLE} fw-600`}
                color2="purple"
              />
              <div
                className={`d-flex justify-content-between align-items-center border px-3 py-2 rounded ${FONT_SIZE} fw-700`}
                style={{ backgroundColor: "#ececec" }}
              >
                <span>Rp</span>
                <span>{priceSplit(charge?.total_charge)}</span>
              </div>
              <div className="mt-1 text-danger f8-vw">{errorCharge}</div>
            </div>
          </div>
        </div>
        <div className="">
          <hr className="separator-grey" />
        </div>

        <div className="my-3">
          <div className={`text-center ${FONT_SIZE} my-2`}>
            Apakah anda yakin ingin melanjutkan?
          </div>
        </div>
      </div>
      <div className="col">
        <div className="d-flex justify-content-between ">
          <div className="col-md-3 col-4 px-0">
            <button
              className={`btn btn-sm btn-block btn-outline-purple ${FONT_SIZE}`}
              onClick={() => close()}
            >
              Back
            </button>
          </div>
          <div className="col-md-3 col-4 px-0">
            <button
              className={`btn btn-sm btn-block text-white ${FONT_SIZE} ${
                errorCharge ? "btn-secondary" : "btn-gradient"
              }`}
              onClick={() => confirm()}
              disabled={errorCharge}
            >
              Confirm
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
