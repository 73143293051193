import { priceSplit } from "../../utils";

export const grouping_compensation = (data) => {
  const object = data.reduce(
    (r, { category_id: category, category_name, ...rest }) => {
      if (!r[category])
        r[category] = { category, category_name, activities: [rest] };
      else r[category].activities.push(rest);
      return r;
    },
    {}
  );
  const result = Object.values(object);
  return result;
};

export const searchInput = (
  placeholder,
  value,
  onchange,
  fontSize = "f7-vw",
  autoFocus = false,
  onKeyPress
) => {
  return (
    <div className="">
      <input
        type="text"
        className={`form-control form-control-sm rounded-input ${fontSize}`}
        placeholder={placeholder}
        value={value}
        onChange={onchange}
        autoFocus={autoFocus}
        onKeyPress={onKeyPress ? (e) => onKeyPress(e) : null}
      />
    </div>
  );
};

export const priceContainer = (value, fallback = 0) => {
  return (
    <div className="d-flex justify-content-between my-auto f8-vw">
      <div className="mr-auto">Rp.</div>
      <div className="mr-2">{value ? priceSplit(value) : fallback}</div>
    </div>
  );
};
export const mobilePriceContainer = (value, fallback = 0) => {
  return (
    <div className="d-flex justify-content-between my-auto f12-vw">
      <div className="mr-auto">Rp.</div>
      <div className="mr-2">{value ? priceSplit(value) : fallback}</div>
    </div>
  );
};

export const getDataContainer = (title, value) => {
  return (
    <div className="detail-information mt-2 f8-vw">
      <div className="d-flex flex-row">
        <div className="col-5 px-0 grey">{title}</div>
        <div className="col px-0 text-black fw-600">: {value}</div>
      </div>
    </div>
  );
};
export const mobileGetDataContainer = (title, value) => {
  return (
    <div className="detail-information mt-2 f12-vw">
      <div className="d-flex flex-row">
        <div className="col-4 px-0 grey">{title}</div>
        <div className="col px-0 text-black fw-600">: {value}</div>
      </div>
    </div>
  );
};

export const HeaderBold = ({
  title1 = "",
  title2 = "",
  color1 = "purple",
  color2 = "orange",
  fontSize = "f12-vw",
  fontWeight = "600",
  margin = "",
}) => {
  return (
    <div className={`${fontSize} ${margin}`} style={{ fontWeight }}>
      <span className={color1}>{title1}</span>
      <span className={color2}> {title2}</span>
    </div>
  );
};
