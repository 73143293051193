import React, {
  Fragment,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { BreadcrumbContext } from "../../common/BreadcrumbContext";
import { Loading } from "../../common/Loading";
import SelectOpt from "../../common/SelectOpt";
import {
  addDays,
  bucketRnaWeb,
  checkLength,
  formatDate,
  getServerTimeWithoutDays,
  getTimezoneOffsetClient,
  isEmpty,
  oss,
  priceSplit,
  showMoreGuest,
  tableCol,
} from "../../utils";
import {
  getCsvFile,
  getData,
  getErr,
  safeErrCode,
} from "../../utils/api-request";
import { comboBoxDownloadReport } from "../../utils/helper";
import { EmptyData, MobileEmptyData } from "../Empty/EmptyData";
import { getClientTimeWithoutSecond } from "../../utils/index";
import {
  DateRangeFilterMobile,
  DateRangeFilterNew,
} from "../../common/DateRange";
import { mobileSearchInput, searchInput } from "../booking/bookingHelper";
import { is_sub_menu_action } from "../../utils/uac";
import PopupModal from "../../common/PopupModal";
import ReactImageGallery from "react-image-gallery";
import { img_fallback } from "../../utils/ui";
import { Icon } from "@iconify/react";
import { iconUrl } from "../../config";
import { IS_MOBILE } from "../../common/Responsive";
import { MobileCardList } from "../../common/CardList";
import { BottomSheet } from "react-spring-bottom-sheet";
import { t } from "../../common/ErrorMessage";

const TableShiftReport = ({ list, openImage, setDataSelected }) => {
  const timezone =
    localStorage?.getItem("timezone") && localStorage?.getItem("timezone");
  return (
    <div className="table-list table-responsive-sm ">
      <table
        className="table table-sm scroll-table"
        style={{ borderCollapse: "separate", borderSpacing: 0 }}
      >
        <thead className="f8-vw sticky-table">
          <tr
            className="text-center text-white"
            style={{ backgroundColor: "#0071A4" }}
          >
            <th className="align-middle" rowSpan={2}>
              Nama
            </th>
            <th className="align-middle" rowSpan={2}>
              Shift ID
            </th>
            <th className="align-middle" rowSpan={2}>
              Nama Shift
            </th>
            <th className="align-middle" rowSpan={1} colSpan={2}>
              Shift
            </th>
            <th
              className="align-middle"
              rowSpan={1}
              colSpan={3}
              style={{ borderBottom: "1px solid white" }}
            >
              Cash
            </th>
            <th className="align-middle" rowSpan={2}>
              Note
            </th>
            <th className="align-middle" rowSpan={2}>
              Bukti Foto
            </th>
            <th className="align-middle" rowSpan={2}>
              Shift Status
            </th>
            <th className="align-middle" rowSpan={2}>
              Status Setor
            </th>
          </tr>
          <tr
            className="text-black text-center text-white"
            style={{ backgroundColor: "#0071A4" }}
          >
            <th>Opening</th>
            <th>Closing</th>
            <th>Total Revenue</th>
            <th>Terhutang</th>
            <th>Setoran</th>
          </tr>
        </thead>

        <tbody className="f8-vw">
          {list?.map((item, i) => {
            return (
              <tr className={tableCol(i)} key={i}>
                <td className="align-middle pl-3">{item?.full_name}</td>
                <td className="align-middle text-center font-weight-bold">
                  {item?.shift_id}
                </td>
                <td className="align-middle ">{item?.shift_name}</td>
                <td rowSpan={1} className="align-middle text-center">
                  {item?.opening_time ? (
                    <>
                      <div>
                        {getServerTimeWithoutDays(item?.opening_time, timezone)}
                      </div>
                      <div className="font-weight-bold">
                        {getClientTimeWithoutSecond(
                          item?.opening_time,
                          timezone
                        )}{" "}
                        {getTimezoneOffsetClient(timezone)}
                      </div>
                    </>
                  ) : (
                    "-"
                  )}
                </td>
                <td rowSpan={1} className=" align-middle text-center">
                  {item?.closing_time ? (
                    <>
                      <div>
                        {getServerTimeWithoutDays(item?.closing_time, timezone)}
                      </div>
                      <div className="font-weight-bold">
                        {getClientTimeWithoutSecond(
                          item?.closing_time,
                          timezone
                        )}{" "}
                        {getTimezoneOffsetClient(timezone)}
                      </div>
                    </>
                  ) : (
                    "-"
                  )}
                </td>
                <td className=" text-right align-middle">
                  {priceSplit(item?.total_rev_cash)}
                </td>
                <td className="text-right  align-middle ">
                  {priceSplit(item?.terhutang)}
                </td>
                <td className="text-right  align-middle ">
                  {priceSplit(item?.setoran)}
                </td>
                <td
                  className={`align-middle ${
                    item?.note_closing ? "text-left" : "text-center"
                  }`}
                >
                  {item?.note_closing ? showMoreGuest(item?.note_closing) : "-"}
                </td>
                <td className={`align-middle text-center`}>
                  {checkLength(item?.file_path)?.length > 0 ? (
                    <button
                      className="btn btn-sm blue f8-vw"
                      onClick={() => {
                        openImage();
                        setDataSelected(checkLength(item?.file_path));
                      }}
                    >
                      Lihat Bukti
                    </button>
                  ) : (
                    "-"
                  )}
                </td>
                <td
                  className={`align-middle text-center ${
                    item.status < 2 ? "green" : "blue"
                  }`}
                >
                  {item?.status < 2 ? "Open" : "Close"}
                </td>
                <td
                  className={`align-middle text-center ${
                    item.status_setoran === "Not Done" ? "yellow" : "green"
                  }`}
                >
                  {item?.status_setoran}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

const ShowImage = ({ dataSelected, close }) => {
  const images = dataSelected.map((item) => {
    return {
      original: bucketRnaWeb + item + oss,
      thumbnail: bucketRnaWeb + item + oss,
    };
  });
  return (
    <div
      className="p-3 relative"
      style={{ width: IS_MOBILE ? "21rem" : "25rem" }}
    >
      <div className="d-flex align-items-center">
        <span className="mr-2">
          <Icon
            icon={iconUrl.informationIcon}
            color="orange"
            width={"1.5rem"}
          />
        </span>
        <span className={`purple fw-700 ${IS_MOBILE ? "f14-vw" : "f10-vw"}`}>
          Bukti Foto
        </span>
      </div>
      <div className="mt-2">
        <label
          htmlFor=""
          className={`fw-600 ${IS_MOBILE ? "f12-vw" : "f8-vw"}`}
        >
          Bukti Pembelian/Pembayaran
        </label>
        <div className="hotel-gallery-image-mobile">
          <ReactImageGallery
            items={images}
            showFullscreenButton={false}
            showPlayButton={false}
            showBullets={false}
            showIndex={true}
            autoPlay={false}
            infinite={true}
            lazyLoad={true}
            slideInterval={10000}
            thumbnailPosition="bottom"
            onImageError={(e) => {
              e.target.src = img_fallback;
            }}
            onThumbnailError={(e) => {
              e.target.src = img_fallback;
            }}
          />
        </div>
      </div>
      <hr className="separator-blue" />
      <div className="d-flex justify-content-center mt-3">
        <div className="col-5">
          <button
            className={`btn btn-sm btn-block btn-gradient text-white ${
              IS_MOBILE ? "f12-vw" : "f8-vw"
            }`}
            onClick={() => close()}
          >
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

const ShiftReport = () => {
  const {
    setpathLocation,
    openButtonSheet,
    setOpenButtonSheet,
    setShowFilter,
  } = useContext(BreadcrumbContext);
  const [shiftInfo, setShiftInfo] = useState([]);
  const [shiftSelected, setShiftSelected] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [userName, setUserName] = useState("");
  const [loadingList, setLoadingList] = useState(false);
  const [shiftList, setShiftList] = useState([]);
  const [error, setError] = useState("");
  const [hasMore, sethasMore] = useState(false);
  const [dataSelected, setDataSelected] = useState({});
  const [statusSelected, setStatusSelected] = useState([]);

  const [range, setRange] = useState({
    start: addDays(new Date(), -7),
    end: new Date(),
    shiftid: "",
    status_setoran: "",
    page: 1,
  });
  const link = useRef(null);

  const checkNextPage = () => {
    if (hasMore) {
      let page = range.page;
      let pagi = page + 1;
      setRange({ ...range, page: pagi });
      getShiftListData(pagi, false, range.start, range.end);
    }
  };

  const getShiftInfo = () => {
    getData("/api/v1/shift_management")
      .then((resp) => {
        if (resp.data) {
          let d = resp.data.map((item) => {
            return {
              label: item.shift_name,
              value: item.shift_id,
            };
          });
          setShiftInfo(d);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getShiftListData = (pagi, is_init, start, end) => {
    let datas = [];
    const params = {
      ...(!!start && { start_date: formatDate(start) }),
      ...(!!end && { end_date: formatDate(end) }),
      ...(!!userName && { nama: userName }),
      ...(!!range?.shiftid && { shift_id: range?.shiftid }),
      ...(!!range?.status_setoran && { status_setoran: range?.status_setoran }),
    };
    let paramsFormatted = null;
    if (!isEmpty(params)) {
      paramsFormatted = new URLSearchParams(params);
    }
    let url = `/api/v1/report/shift_report?page=${pagi}&${
      paramsFormatted !== null ? `&${paramsFormatted}` : ""
    }`;
    setLoadingList(true);
    getData(url)
      .then((resp) => {
        setLoadingList(false);
        if (resp.data) {
          setLoadingList(false);
          if (is_init) {
            datas = resp.data.data;
          } else {
            let x = [];
            x = shiftList;
            datas = [...x, ...resp.data.data];
          }
          sethasMore(resp.data.next_page);
          setShiftList(datas);
        } else {
          resp.json().then((err) => {
            setError(t(safeErrCode(err)));
          });
        }
      })
      .catch((error) => {
        let err = getErr(error);
        setLoadingList(false);
        setError(t(err));
        console.log(err);
      });
  };

  const handleEnter = (e) => {
    if (e.key === "Enter") {
      setShiftList([]);
      setRange({ ...range, page: 1 });
      getShiftListData(1, true, range.start, range.end);
    }
  };

  const openImage = () => {
    setOpenModal(!openModal);
  };

  const handleShiftSelected = (val) => {
    if (!val) {
      setShiftSelected([]);
      setRange({ ...range, shiftid: "" });
    } else {
      setShiftSelected(val);
      setRange({ ...range, shiftid: val.value });
    }
  };
  const handleStatusSelected = (val) => {
    if (!val) {
      setStatusSelected([]);
      setRange({ ...range, status_setoran: "" });
    } else {
      setStatusSelected(val);
      setRange({ ...range, status_setoran: val.value });
    }
  };
  const handleStart = (value) => {
    setRange({ ...range, start: value, end: addDays(value, 7) });
  };
  const handleEnd = (value) => {
    setRange({ ...range, end: value });
  };

  const handleUserName = (val) => {
    setUserName(val.target.value);
  };

  const handleDownloadCSV = (e) => {
    if (
      link.current.href &&
      link.current.href.substr(link.current.href.length - 6) !== "no_use"
    ) {
      return;
    }
    if (link.current.href.substr(link.current.href.length - 6) === "no_use") {
      e.preventDefault();
    }

    const params = {
      ...(!!range?.start && { start_date: formatDate(range?.start) }),
      ...(!!range?.end && { end_date: formatDate(range?.end) }),
      ...(!!userName && { nama: userName }),
      ...(!!range?.shiftid && { shift_id: range?.shiftid }),
      ...(!!range?.status_setoran && { status_setoran: range?.status_setoran }),
    };
    let paramsFormatted = null;
    if (!isEmpty(params)) {
      paramsFormatted = new URLSearchParams(params);
    }
    let url = `/api/v1/report/shift_report?csvoutput=1${
      paramsFormatted !== null ? `&${paramsFormatted}` : ""
    }`;
    getCsvFile(url)
      .then((resp) => {
        let now = new Date();
        let filenameServ = "shiftreport-" + formatDate(now) + ".csv";
        if (
          resp.headers !== undefined &&
          resp.headers !== null &&
          resp.headers.length > 1
        ) {
          filenameServ = resp.headers
            .get("Content-Disposition")
            .split("filename=")[1];
        }
        let filename = filenameServ;
        // console.log("filename-serv-download", filenameServ);
        resp.blob().then((blob) => {
          let dx = new Blob([blob]);
          const href = window.URL.createObjectURL(dx);
          link.current.download = filename;
          link.current.href = href;
          link.current.click();
          link.current.download = "no_use";
          link.current.href = "no_use";
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const resetFilter = () => {
    setRange({
      ...range,
      start: addDays(new Date(), -7),
      end: new Date(),
      shiftid: "",
    });
  };

  useEffect(() => {
    getShiftInfo();
    return () => {
      setShiftInfo([]);
    };
  }, []);

  useEffect(() => {
    setShowFilter(true);
    return () => {
      setShowFilter(false);
    };
  }, [setShowFilter]);

  useEffect(() => {
    if (
      userName === "" &&
      range.shiftid === "" &&
      range.status_setoran === ""
    ) {
      getShiftListData(1, true, range.start, range.end);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [range.shiftid, userName, range.status_setoranv]);

  useEffect(() => {
    setpathLocation("/report/shift");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (openModal) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
    return () => {
      document.body.style.overflow = "unset";
    };
  }, [openModal]);

  return (
    <>
      {IS_MOBILE ? (
        <div className="d-flex flex-column px-3 py-1 mb-5">
          <div className="col px-1">
            {is_sub_menu_action("rp_the_shift_export") && (
              <div className="">
                <div className="d-flex">
                  {comboBoxDownloadReport(link, handleDownloadCSV)}
                </div>
              </div>
            )}
          </div>
          <div className="col px-1">
            <InfiniteScroll
              dataLength={shiftList?.length}
              next={checkNextPage}
              hasMore={hasMore}
              loader={<Loading />}
              endMessage={
                shiftList?.length < 1 && (
                  <MobileEmptyData
                    loading={loadingList}
                    errMessage={error}
                    height="40vh"
                  />
                )
              }
            >
              {shiftList.map((item, i) => {
                const timezone = localStorage?.getItem("timezone");
                return (
                  <MobileCardList
                    title={
                      <>
                        <div
                          className="f12-vw fw-500"
                          style={{ color: "#959595" }}
                        >
                          Shift ID
                        </div>
                        <div>{item?.real_shiftid}</div>
                      </>
                    }
                    fontSizeHeader="f14-vw"
                    key={item?.real_shiftid}
                  >
                    <div className="card-hms-list">
                      <div className="col-4 px-0 grey-text">Nama Kasir</div>
                      <div className="col pr-0 f12-vw black-value">
                        {item?.full_name}
                      </div>
                    </div>
                    <div className="card-hms-list">
                      <div className="col-4 px-0 grey-text">Nama Shift</div>
                      <div className="col pr-0 f12-vw black-value">
                        {item?.shift_name}
                      </div>
                    </div>
                    <div className="card-hms-list">
                      <div className="col-4 px-0 grey-text">Opening Shift</div>
                      <div className="col pr-0 f12-vw black-value">
                        {`${getServerTimeWithoutDays(
                          item?.opening_time,
                          timezone
                        )}, ${getClientTimeWithoutSecond(
                          item?.opening_time
                        )} ${getTimezoneOffsetClient(timezone)}`}
                      </div>
                    </div>
                    <div className="card-hms-list">
                      <div className="col-4 px-0 grey-text">Closing Shift</div>
                      <div className="col pr-0 f12-vw black-value">
                        {item?.closing_time
                          ? `${getServerTimeWithoutDays(
                              item?.closing_time,
                              timezone
                            )}, ${getClientTimeWithoutSecond(
                              item?.closing_time,
                              timezone
                            )} ${getTimezoneOffsetClient(timezone)}`
                          : "-"}
                      </div>
                    </div>
                    <div className="card-hms-list">
                      <div className="col-4 px-0 purple fw-700">Cash</div>
                    </div>
                    <div className="card-hms-list">
                      <div className="col-4 px-0 grey-text">Total Revenue</div>
                      <div className="col pr-0">
                        <div className="d-flex justify-content-between">
                          <span className="f12-vw black-value">Rp</span>
                          <span className="f12-vw black-value">
                            {priceSplit(item?.total_rev_cash)}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="card-hms-list">
                      <div className="col-4 px-0 grey-text">Terhutang</div>
                      <div className="col pr-0">
                        <div className="d-flex justify-content-between">
                          <span className="f12-vw black-value">Rp</span>
                          <span className="f12-vw black-value">
                            {priceSplit(item?.terhutang)}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="card-hms-list">
                      <div className="col-4 px-0 grey-text">Setoran</div>
                      <div className="col pr-0">
                        <div className="d-flex justify-content-between">
                          <span className="f12-vw black-value">Rp</span>
                          <span className="f12-vw black-value">
                            {priceSplit(item?.setoran)}
                          </span>
                        </div>
                      </div>
                    </div>

                    <div className="card-hms-list">
                      <div className="col-4 px-0 grey-text">Note</div>
                      <div className="col pr-0 f12-vw black-value">
                        {item?.note_closing
                          ? showMoreGuest(item?.note_closing, 30)
                          : "-"}
                      </div>
                    </div>

                    <div className="card-hms-list">
                      <div className="col-4 px-0 grey-text">Bukti Setoran</div>
                      <div className="col pr-0 f12-vw">
                        {checkLength(item?.file_path) ? (
                          <div
                            className="f12-vw fw-700 blue"
                            onClick={() => {
                              openImage();
                              setDataSelected(checkLength(item?.file_path));
                            }}
                          >
                            <span className="mr-1">
                              <Icon
                                icon={iconUrl.formMultiple20Filled}
                                width={20}
                              />
                            </span>
                            Lihat bukti
                          </div>
                        ) : (
                          "-"
                        )}
                      </div>
                    </div>
                    <div className="card-hms-list">
                      <div className="col-4 px-0 grey-text">Shift Status</div>
                      <div
                        className={`col pr-0 f12-vw fw-700 ${
                          item.status < 2 ? "green" : "blue"
                        }`}
                      >
                        {item?.status < 2 ? "Open" : "Close"}
                      </div>
                    </div>
                    <div className="card-hms-list">
                      <div className="col-4 px-0 grey-text">Status Setor</div>
                      <div
                        className={`col pr-0 f12-vw fw-700 ${
                          item.status_setoran === "Not Done"
                            ? "yellow"
                            : "green"
                        }`}
                      >
                        {item?.status_setoran}
                      </div>
                    </div>
                  </MobileCardList>
                );
              })}
            </InfiniteScroll>
          </div>
        </div>
      ) : (
        <div className="d-flex flex-column px-4 py-1">
          <div className="col px-2">
            <div className="d-flex flex-row justify-content-between">
              <div className="col-md-8 px-0">
                <div className="d-flex flex-row">
                  <div className="col px-0 mr-2">
                    <label htmlFor="" className="f8-vw m-0 p-0">
                      Periode Shift
                    </label>
                    <DateRangeFilterNew
                      start={range.start}
                      end={range.end}
                      handleStart={handleStart}
                      handleEnd={handleEnd}
                      minDate={true}
                    />
                  </div>
                  <div className="col px-0 mr-2">
                    <label htmlFor="" className="f8-vw m-0 p-0">
                      Shift
                    </label>
                    <SelectOpt
                      optionsValue={shiftInfo}
                      onChange={handleShiftSelected}
                      placeholder={"Pilih Shift"}
                      value={shiftSelected}
                      clear={true}
                    />
                  </div>
                  <div className="col px-0 mr-2">
                    <label htmlFor="" className="f8-vw m-0 p-0">
                      Status Setoran
                    </label>
                    <SelectOpt
                      optionsValue={[
                        { value: "Done", label: "Done" },
                        { value: "Not Done", label: "Not Done" },
                      ]}
                      onChange={handleStatusSelected}
                      placeholder={"Pilih Status Setoran"}
                      value={statusSelected}
                      clear={true}
                    />
                  </div>
                  <div className="col px-0 mr-2">
                    <label htmlFor="" className="f8-vw m-0 p-0">
                      Nama
                    </label>
                    {searchInput(
                      "Cari Nama ",
                      userName,
                      (e) => handleUserName(e),
                      handleEnter
                    )}
                  </div>
                </div>
              </div>

              <div className="col-md-2 pr-0 pl-5 mt-auto">
                <div className="d-flex flex-column">
                  <button
                    className="btn btn-sm search-new rounded-input btn-block mt-auto f8-vw fw-500 text-white"
                    onClick={() => {
                      setShiftList([]);
                      getShiftListData(1, true, range.start, range.end);
                    }}
                    style={{
                      paddingTop: 7,
                      paddingBottom: 7,
                      backgroundColor: "#742B9E",
                    }}
                  >
                    Search
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="col px-0">
            {is_sub_menu_action("rp_the_shift_export") && (
              <div className="mt-2">
                <div className="d-flex">
                  {comboBoxDownloadReport(link, handleDownloadCSV)}
                </div>
              </div>
            )}
          </div>

          <div className="col px-2 mt-0">
            <InfiniteScroll
              dataLength={shiftList?.length}
              next={checkNextPage}
              hasMore={hasMore}
              loader={<Loading />}
              endMessage={
                shiftList?.length < 1 && (
                  <EmptyData
                    loading={loadingList}
                    errMessage={error}
                    height="25vh"
                  />
                )
              }
              height={"50vh"}
              className="scroll-table px-0 mt-2 table-border-line"
            >
              <TableShiftReport
                list={shiftList}
                loading={loadingList}
                errorMessage={error}
                openImage={openImage}
                setDataSelected={setDataSelected}
              />
            </InfiniteScroll>
          </div>
        </div>
      )}

      {IS_MOBILE && (
        <div>
          <BottomSheet
            open={openButtonSheet}
            onDismiss={() => setOpenButtonSheet(false)}
            snapPoints={({ maxHeight }) => maxHeight}
            header={
              <div className="d-flex justify-content-between">
                <span className="purple f16-vw fw-600 px-2">Filter</span>
                <span
                  className="px-2"
                  onClick={() => setOpenButtonSheet(false)}
                >
                  <Icon icon={iconUrl.closeBold} color={"grey"} width={20} />
                </span>
              </div>
            }
            footer={
              <div className="d-flex justify-content-between">
                <div className="col-6 pl-2">
                  <button
                    className="btn btn-block btn-outline-purple f12-vw"
                    onClick={() => {
                      resetFilter();
                      setOpenButtonSheet(false);
                      getShiftListData(
                        1,
                        true,
                        addDays(new Date(), -7),
                        new Date()
                      );
                    }}
                  >
                    Reset
                  </button>
                </div>
                <div className="col-6 pr-2">
                  <button
                    className="btn btn-block btn-gradient f12-vw text-white"
                    onClick={() => {
                      setOpenButtonSheet(false);
                      getShiftListData(1, true, range.start, range.end);
                    }}
                  >
                    Search
                  </button>
                </div>
              </div>
            }
          >
            <div className="mt-3" style={{ height: "30rem" }}>
              <div className="px-4 mt-2 date-wrapper">
                <label htmlFor="" className="f12-vw m-0 p-0">
                  Periode Shift
                </label>
                <DateRangeFilterMobile
                  start={range.start}
                  end={range.end}
                  handleStart={handleStart}
                  handleEnd={handleEnd}
                  minDate={true}
                  fontSize="f12-vw"
                />
              </div>
              <div className="px-4 mt-3">
                <label htmlFor="" className="f12-vw m-0 p-0">
                  Shift
                </label>
                <SelectOpt
                  optionsValue={shiftInfo}
                  onChange={handleShiftSelected}
                  placeholder={"Pilih Shift"}
                  value={shiftSelected}
                  clear={true}
                  fontSize="calc(8pt + 0.472vw)"
                  menuHeight="12rem"
                />
              </div>
              <div className="px-4 mt-3">
                <label htmlFor="" className="f12-vw m-0 p-0">
                  Status Setoran
                </label>
                <SelectOpt
                  optionsValue={[
                    { value: "Done", label: "Done" },
                    { value: "Not Done", label: "Not Done" },
                  ]}
                  onChange={handleStatusSelected}
                  placeholder={"Pilih Status Setoran"}
                  value={statusSelected}
                  clear={true}
                  fontSize="calc(8pt + 0.472vw)"
                  menuHeight="12rem"
                />
              </div>
              <div className="px-4 mt-3">
                <label htmlFor="" className="f12-vw m-0 p-0">
                  Nama
                </label>
                {mobileSearchInput("Cari Nama ", userName, (e) =>
                  handleUserName(e)
                )}
              </div>
            </div>
          </BottomSheet>
        </div>
      )}

      <PopupModal modalIsOpen={openModal} width={"40rem"}>
        <ShowImage dataSelected={dataSelected} close={openImage} />
      </PopupModal>
    </>
  );
};
export default ShiftReport;
