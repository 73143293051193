import i18n from "i18next";
import XHR from "i18next-xhr-backend";
import { initReactI18next } from "react-i18next";

const fallbackLng = ["id"];
const availableLanguages = ["en", "id"];

i18n
  .use(XHR)
  .use(initReactI18next)
  .init({
    backend: {
      loadPath: "/locales/{{lng}}/{{ns}}_001.json",
    },
    debug: false,
    fallbackLng,
    lng: fallbackLng[0],
    whitelist: availableLanguages,
    keySeparator: false,
    interpolation: {
      escapeValue: false,
    },
    react: {
      // wait: true,
      useSuspense: false,
      transEmptyNodeValue: "",
      transSupportBasicHtmlNodes: true,
      transKeepBasicHtmlNodesFor: ["b", "ul", "ol", "li", "i", "br", "u"],
    },
  });

export default i18n;
