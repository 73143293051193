import { Icon } from "@iconify/react";
import React, {
  Fragment,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { BreadcrumbContext } from "../../common/BreadcrumbContext";
import { HeaderName } from "../../common/HeaderName";
import NotifMessage, { ButtonClose } from "../../common/Notif";
import PopupModal from "../../common/PopupModal";
import {
  bucketRnaWeb,
  getClientTimeWithoutSecond,
  getServerTimeWithoutDays,
  getTimezoneOffsetClient,
  oss,
  priceSplit,
  tableCol,
} from "../../utils";
import { addData, getData, getErr, safeErrCode } from "../../utils/api-request";
import { EmptyData, MobileEmptyData } from "../Empty/EmptyData";
import { filterBankList, filterPaymentMethod, showMore } from "./common/utils";
import "react-medium-image-zoom/dist/styles.css";
import { is_sub_menu_action } from "../../utils/uac";
import { img_fallback } from "../../utils/ui";
import { iconUrl } from "../../config";
import { IS_MOBILE } from "../../common/Responsive";
import { MobileCardList } from "../../common/CardList";
import { t } from "../../common/ErrorMessage";

const FONT_SIZE = IS_MOBILE ? "f12-vw" : "f8-vw";
const FONT_SIZE_HEADER = IS_MOBILE ? "f16-vw" : "f12-vw";
const TIMEZONE =
  localStorage?.getItem("timezone") && localStorage?.getItem("timezone");
const timezone =
  localStorage?.getItem("timezone") && localStorage?.getItem("timezone");

const TableApprove = ({
  height,
  list,
  loading,
  errorMessage,
  isOpen,
  setMode,
  setDetailSelected,
  setSourceImage,
}) => {
  const timezone =
    localStorage?.getItem("timezone") && localStorage?.getItem("timezone");

  const renderItem = () => {
    return (
      <Fragment>
        {list.map((item, key) => {
          return (
            <tr key={key} className={tableCol(key)}>
              <td className="text-center align-middle font-weight-bold">
                {item?.wsetoran_id}
              </td>
              <td className="text-center align-middle">
                {item?.created_at ? (
                  <>
                    <div>
                      {getServerTimeWithoutDays(item?.created_at, timezone)}
                    </div>
                    <div className="font-weight-bold">
                      {getClientTimeWithoutSecond(item?.created_at, timezone) +
                        " " +
                        getTimezoneOffsetClient(timezone)}
                    </div>
                  </>
                ) : (
                  "-"
                )}
              </td>
              <td className="text-center align-middle">{item?.full_name}</td>
              <td className="text-right align-middle">
                {priceSplit(item?.total_setoran)}
              </td>
              <td className={"text-center align-middle "}>
                {filterPaymentMethod(item?.payment_type)?.label}
              </td>
              <td className={"text-center align-middle "}>
                {getServerTimeWithoutDays(item?.transfer_date)}
              </td>
              <td className={"text-center align-middle "}>
                {filterBankList(item?.to_bank_id)?.label}
              </td>
              <td className={"text-center align-middle purple"}>
                <span
                  className="purple"
                  onClick={() => {
                    setSourceImage(item?.bukti_setoran);
                    isOpen();
                    setMode("show-picture");
                  }}
                  style={{ cursor: "pointer" }}
                >
                  Lihat bukti
                </span>
              </td>
              <td
                className={`${
                  item?.note ? "text-left" : "text-center"
                } align-middle text-wrap`}
              >
                {item?.note ? (
                  <div style={{ width: "fit-content" }}>
                    {showMore(item?.note)}
                  </div>
                ) : (
                  "-"
                )}
              </td>
              <td
                className="text-center align-middle"
                style={{ backgroundColor: "white" }}
              >
                {item?.status !== 2 &&
                  is_sub_menu_action("sf_setoran_approve_setoran") && (
                    <button
                      className="btn btn-sm py-1 f8-vw blue fw-600"
                      onClick={() => {
                        setMode("approval-popup");
                        isOpen();
                        setDetailSelected(item);
                      }}
                    >
                      Approve Setoran
                    </button>
                  )}
              </td>
            </tr>
          );
        })}
      </Fragment>
    );
  };

  return (
    <div className="d-flex flex-column">
      <div
        className="table-list table-responsive-sm scroll-table"
        style={{
          height: height ? height : "350px",
        }}
      >
        <table
          className="table table-sm m-0"
          style={{ borderCollapse: "collapse", borderSpacing: 0 }}
        >
          <thead className="f8-vw sticky-table">
            <tr
              className="text-center text-white"
              style={{
                backgroundColor: "#0071A4",
              }}
            >
              <th className="py-2 align-middle">ID Setoran</th>
              <th className="py-2 align-middle">Tanggal Setoran</th>
              <th className="py-2 align-middle">
                <div>Disetorkan</div>
                <div>oleh</div>
              </th>
              <th className="py-2 align-middle">Total Setoran</th>
              <th className="py-2 align-middle">
                <div>Metode</div>
                <div>Pembayaran</div>
              </th>
              <th className="py-2 align-middle">
                <div>Tanggal Transfer</div>
                <div>/Diserahkan</div>
              </th>
              <th className="py-2 align-middle">Nama Bank</th>
              <th className="py-2 align-middle">Bukti Setoran</th>
              <th className="py-2 align-middle" style={{ width: "14rem" }}>
                Note
              </th>
              <th className="py-2 align-middle">Action</th>
            </tr>
          </thead>

          <tbody className="f8-vw">
            {list?.length > 0 && !loading ? (
              renderItem()
            ) : (
              <>
                <tr>
                  <td colSpan={12} className="p-0">
                    <EmptyData
                      loading={loading}
                      errMessage={errorMessage}
                      text="Semua setoran sudah di-Approve"
                      sourceImg={"/img/setoran/approve-setoran.svg"}
                      height={"21rem"}
                    />
                  </td>
                </tr>
              </>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

const PopupApproval = ({
  close,
  detail,
  reject,
  approve,
  mode,
  note,
  setNote,
  confirm,
  loading,
  back,
}) => {
  const detailInfo = (title, value) => {
    return (
      <div className={"d-flex flex-row " + FONT_SIZE}>
        <div className="col-5 px-0 grey">{title}</div>
        <div className="col px-0">: {value}</div>
      </div>
    );
  };
  return (
    <>
      {mode === "approval-popup" && (
        <div className="p-2 my-2" style={{ width: "21rem" }}>
          <div className="px-3">
            <div className="d-flex justify-content-between align-items-center">
              <div className={"col px-0 " + FONT_SIZE_HEADER}>
                <span className="mr-1">
                  <Icon
                    icon={iconUrl.walletCreditCard20Filled}
                    color="orange"
                    width={25}
                  />
                </span>
                <span className="dark-blue fw-700">Detail Setoran</span>
              </div>
              <div className="col-2 px-0 text-right">
                <button
                  className="btn btn-sm bg-transparent"
                  onClick={() => close()}
                >
                  <Icon
                    icon={iconUrl.timesIcon}
                    width="20"
                    height="20"
                    color="#177dac"
                  />
                </button>
              </div>
            </div>
          </div>
          <div className="px-3">
            <hr className="separator-blue my-2" />
          </div>

          <div className="px-3">
            <div className="form-group">
              <HeaderName
                text="Informasi Setoran"
                fontSize={`fw-700 ${IS_MOBILE ? "f14-vw" : "f10-vw"}`}
                color2="purple"
              />
              <div className="my-1">
                {detailInfo(
                  "ID Setoran",
                  <span className="font-weight-bold">
                    {detail?.wsetoran_id}
                  </span>
                )}
                {detailInfo(
                  "Tanggal Setoran",
                  <>
                    <span>
                      {getServerTimeWithoutDays(detail?.created_at, timezone)}
                    </span>
                    <span className="font-weight-bold">
                      {`, ${getClientTimeWithoutSecond(
                        detail?.created_at,
                        timezone
                      )} ${getTimezoneOffsetClient(timezone)}`}
                    </span>
                  </>
                )}
                {detailInfo("Disetorkan oleh", detail?.full_name)}
                {detailInfo(
                  "Total Setoran",
                  <span className="font-weight-bold">
                    Rp {priceSplit(detail?.total_setoran)}
                  </span>
                )}
                {detailInfo(
                  "Metode Pembayaran",
                  filterPaymentMethod(detail?.payment_type)?.label
                )}
                {detailInfo(
                  "Tanggal Transfer",
                  getServerTimeWithoutDays(detail?.transfer_date, timezone)
                )}
                {detail?.payment_type === 2 &&
                  detailInfo(
                    "Nama Bank",
                    filterBankList(detail?.to_bank_id)?.label
                  )}
                {detailInfo(
                  detail?.payment_type === 2 ? "Account Name" : "Penerima",
                  detail?.acc_name
                )}
              </div>
            </div>
            <div className="form-group">
              <label
                htmlFor=""
                className={"fw-700 " + FONT_SIZE}
                style={{ color: "#5e5e5e" }}
              >
                Note
              </label>
              <div className={FONT_SIZE}>
                {detail?.note ? detail?.note : "-"}
              </div>
            </div>
            <div className="form-group">
              <div
                className={"fw-700 " + FONT_SIZE}
                style={{ color: "#5e5e5e" }}
              >
                Bukti Transfer/Tanda Terima
              </div>
              <div className="my-1">
                <img
                  src={bucketRnaWeb + detail?.bukti_setoran + oss}
                  alt="bukti-setoran"
                  style={{
                    width: "100%",
                    maxHeight: 150,
                    objectFit: "contain",
                  }}
                  onError={({ currentTarget }) => {
                    currentTarget.onerror = null;
                    currentTarget.src = img_fallback;
                  }}
                  loading="lazy"
                />
              </div>
            </div>
          </div>
          <div className="col">
            <hr className={`separator-blue ${IS_MOBILE ? "my-2" : ""}`} />
            <div className={`text-center  ${IS_MOBILE ? "mb-2" : "mb-3"}`}>
              <small className="f8-vw">
                Apakah anda yakin ingin melanjutkan?
              </small>
            </div>
          </div>
          <div className="col">
            <div className="d-flex justify-content-between">
              <div className="col-6 pl-0">
                <button
                  className={
                    "btn btn-sm btn-block bg-red text-white fw-600 " + FONT_SIZE
                  }
                  onClick={() => reject()}
                >
                  Reject
                </button>
              </div>
              <div className="col-6 pr-0">
                <button
                  className={
                    "btn btn-sm btn-block bg-green text-white fw-600 " +
                    FONT_SIZE
                  }
                  onClick={() => approve()}
                >
                  Approve
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {mode === "approve-confirmation" && (
        <div
          className="p-2 my-2"
          style={{ width: IS_MOBILE ? "21rem" : "45rem" }}
        >
          <div className="px-3">
            <div className="d-flex justify-content-center">
              <img src="/img/popup/approve-new.svg" alt="" loading="lazy" />
            </div>
            <div className="text-center">
              <div className={`fw-700 ${IS_MOBILE ? "f14-vw" : "f10-vw"}`}>
                Apakah anda yakin ingin "APPROVE" Setoran?
              </div>
            </div>
          </div>
          <div className="px-3">
            <hr className="separator-blue my-2" />
          </div>

          <div className="px-3">
            {IS_MOBILE ? (
              <div
                className="scroll-popup hide-scrollbar"
                style={{ height: "17rem" }}
              >
                <div className="form-group">
                  <HeaderName
                    text="Informasi Setoran"
                    fontSize={`fw-700 ${IS_MOBILE ? "f14-vw" : "f10-vw"}`}
                    color2="purple"
                  />
                  <div className="my-1">
                    {detailInfo(
                      "ID Setoran",
                      <span className="font-weight-bold">
                        {detail?.wsetoran_id}
                      </span>
                    )}
                    {detailInfo(
                      "Tanggal Setoran",
                      <>
                        <span>
                          {getServerTimeWithoutDays(
                            detail?.created_at,
                            timezone
                          )}
                        </span>
                        <span className="font-weight-bold">
                          {` ,${getClientTimeWithoutSecond(
                            detail?.created_at,
                            timezone
                          )} ${getTimezoneOffsetClient(timezone)}`}
                        </span>
                      </>
                    )}
                    {detailInfo("Disetorkan oleh", detail?.full_name)}
                    {detailInfo(
                      "Total Setoran",
                      <span className="font-weight-bold">
                        Rp {priceSplit(detail?.total_setoran)}
                      </span>
                    )}
                    {detailInfo(
                      "Metode Pembayaran",
                      filterPaymentMethod(detail?.payment_type)?.label
                    )}
                    {detailInfo(
                      "Tanggal Transfer",
                      getServerTimeWithoutDays(detail?.transfer_date, timezone)
                    )}
                    {detail?.payment_type === 2 &&
                      detailInfo(
                        "Nama Bank",
                        filterBankList(detail?.to_bank_id)?.label
                      )}
                    {detailInfo(
                      detail?.payment_type === 2 ? "Account Name" : "Penerima",
                      detail?.acc_name
                    )}
                  </div>
                </div>
                <div className="form-group">
                  <label
                    htmlFor=""
                    className={`fw-700 ${IS_MOBILE ? "f14-vw" : "f10-vw"}`}
                    style={{ color: "#5e5e5e" }}
                  >
                    Note
                  </label>
                  <div className={FONT_SIZE}>
                    {detail?.note ? detail?.note : "-"}
                  </div>
                </div>
                <div className="form-group">
                  <div
                    className={`fw-700 ${IS_MOBILE ? "f14-vw" : "f10-vw"}`}
                    style={{ color: "#5e5e5e" }}
                  >
                    Bukti Transfer/Tanda Terima
                  </div>
                  <div className="my-1">
                    <img
                      src={bucketRnaWeb + detail?.bukti_setoran + oss}
                      alt="bukti-setoran"
                      style={{
                        width: "100%",
                        maxHeight: 150,
                        objectFit: "contain",
                      }}
                      onError={({ currentTarget }) => {
                        currentTarget.onerror = null;
                        currentTarget.src = img_fallback;
                      }}
                      loading="lazy"
                    />
                  </div>
                </div>
              </div>
            ) : (
              <>
                <div className="d-flex flex-row">
                  <div className="col pl-0">
                    <div className="form-group">
                      <HeaderName
                        text="Informasi Setoran"
                        fontSize={`fw-700 ${IS_MOBILE ? "f14-vw" : "f10-vw"}`}
                        color2="purple"
                      />
                      <div className="my-1">
                        {detailInfo(
                          "ID Setoran",
                          <span className="font-weight-bold">
                            {detail?.wsetoran_id}
                          </span>
                        )}
                        {detailInfo(
                          "Tanggal Setoran",
                          <>
                            <span>
                              {getServerTimeWithoutDays(
                                detail?.created_at,
                                timezone
                              )}
                            </span>
                            <span className="font-weight-bold">
                              {` ,${getClientTimeWithoutSecond(
                                detail?.created_at,
                                timezone
                              )} ${getTimezoneOffsetClient(timezone)}`}
                            </span>
                          </>
                        )}
                        {detailInfo("Disetorkan oleh", detail?.full_name)}
                        {detailInfo(
                          "Total Setoran",
                          <span className="font-weight-bold">
                            Rp {priceSplit(detail?.total_setoran)}
                          </span>
                        )}
                        {detailInfo(
                          "Metode Pembayaran",
                          filterPaymentMethod(detail?.payment_type)?.label
                        )}
                        {detailInfo(
                          "Tanggal Transfer",
                          getServerTimeWithoutDays(
                            detail?.transfer_date,
                            timezone
                          )
                        )}
                        {detail?.payment_type === 2 &&
                          detailInfo(
                            "Nama Bank",
                            filterBankList(detail?.to_bank_id)?.label
                          )}
                        {detailInfo(
                          detail?.payment_type === 2
                            ? "Account Name"
                            : "Penerima",
                          detail?.acc_name
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col pr-0">
                    <div className="form-group">
                      <div
                        className={`fw-700 ${IS_MOBILE ? "f14-vw" : "f10-vw"}`}
                        style={{ color: "#5e5e5e" }}
                      >
                        Bukti Transfer/Tanda Terima
                      </div>
                      <div className="my-1">
                        <img
                          src={bucketRnaWeb + detail?.bukti_setoran + oss}
                          alt="bukti-setoran"
                          style={{
                            width: "100%",
                            maxHeight: 150,
                            objectFit: "contain",
                          }}
                          className="img-fluid"
                          onError={({ currentTarget }) => {
                            currentTarget.onerror = null;
                            currentTarget.src = img_fallback;
                          }}
                          loading="lazy"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="">
                  <div className="form-group">
                    <label
                      htmlFor=""
                      className={`fw-700 ${IS_MOBILE ? "f14-vw" : "f10-vw"}`}
                      style={{ color: "#5e5e5e" }}
                    >
                      Note
                    </label>
                    <div className={FONT_SIZE}>
                      {detail?.note ? detail?.note : "-"}
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>

          <div className="col">
            <hr className={`separator-blue ${IS_MOBILE ? "my-2" : ""}`} />
            <div className={`text-center  ${IS_MOBILE ? "mb-2" : "mb-3"}`}>
              <small className="f8-vw">
                Apakah anda yakin ingin melanjutkan?
              </small>
            </div>
          </div>
          <div className="col">
            <div className="d-flex justify-content-between">
              <div className={`${IS_MOBILE ? "col-5 px-0" : "col-3 px-0"}`}>
                <button
                  className={
                    "btn btn-sm btn-block btn-outline-purple " + FONT_SIZE
                  }
                  onClick={() => back()}
                >
                  Back
                </button>
              </div>
              <div className={`${IS_MOBILE ? "col-5 px-0" : "col-3 px-0"}`}>
                <button
                  className={
                    "btn btn-sm btn-block btn-gradient text-white " + FONT_SIZE
                  }
                  onClick={() => confirm()}
                >
                  Confirm
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {mode === "approve-success" && (
        <div
          className="p-2 my-2"
          style={{ width: IS_MOBILE ? "21rem" : "45rem" }}
        >
          <div className="px-3">
            <div className="d-flex justify-content-center">
              <img src="/img/popup/approve.svg" alt="" loading="lazy" />
            </div>
            <div className="text-center">
              <div className={`fw-700 ${IS_MOBILE ? "f14-vw" : "f10-vw"}`}>
                Setoran Berhasil di Approve
              </div>
            </div>
          </div>
          <div className="px-3">
            <hr className="separator-blue my-2" />
          </div>

          <div className="px-3">
            {IS_MOBILE ? (
              <div
                className="scroll-popup hide-scrollbar"
                style={{ height: "17rem" }}
              >
                <div className="form-group">
                  <HeaderName
                    text="Informasi Setoran"
                    fontSize={`fw-700 ${IS_MOBILE ? "f14-vw" : "f10-vw"}`}
                    color2="purple"
                  />
                  <div className="my-1">
                    {detailInfo(
                      "ID Setoran",
                      <span className="font-weight-bold">
                        {detail?.wsetoran_id}
                      </span>
                    )}
                    {detailInfo(
                      "Tanggal Setoran",
                      <>
                        <span>
                          {getServerTimeWithoutDays(
                            detail?.created_at,
                            timezone
                          )}
                        </span>
                        <span className="font-weight-bold">
                          {` ,${getClientTimeWithoutSecond(
                            detail?.created_at,
                            timezone
                          )} ${getTimezoneOffsetClient(timezone)}`}
                        </span>
                      </>
                    )}
                    {detailInfo("Disetorkan oleh", detail?.full_name)}
                    {detailInfo(
                      "Total Setoran",
                      <span className="font-weight-bold">
                        Rp {priceSplit(detail?.total_setoran)}
                      </span>
                    )}
                    {detailInfo(
                      "Metode Pembayaran",
                      filterPaymentMethod(detail?.payment_type)?.label
                    )}
                    {detailInfo(
                      "Tanggal Transfer",
                      getServerTimeWithoutDays(detail?.transfer_date, timezone)
                    )}
                    {detail?.payment_type === 2 &&
                      detailInfo(
                        "Nama Bank",
                        filterBankList(detail?.to_bank_id)?.label
                      )}
                    {detailInfo(
                      detail?.payment_type === 2 ? "Account Name" : "Penerima",
                      detail?.acc_name
                    )}
                  </div>
                </div>
                <div className="form-group">
                  <label
                    htmlFor=""
                    className={`fw-700 ${IS_MOBILE ? "f14-vw" : "f10-vw"}`}
                    style={{ color: "#5e5e5e" }}
                  >
                    Note
                  </label>
                  <div className={FONT_SIZE}>
                    {detail?.note ? detail?.note : "-"}
                  </div>
                </div>
                <div className="form-group">
                  <div
                    className={`fw-700 ${IS_MOBILE ? "f14-vw" : "f10-vw"}`}
                    style={{ color: "#5e5e5e" }}
                  >
                    Bukti Transfer/Tanda Terima
                  </div>
                  <div className="my-1">
                    <img
                      src={bucketRnaWeb + detail?.bukti_setoran + oss}
                      alt="bukti-setoran"
                      style={{
                        width: "100%",
                        maxHeight: 150,
                        objectFit: "contain",
                      }}
                      onError={({ currentTarget }) => {
                        currentTarget.onerror = null;
                        currentTarget.src = img_fallback;
                      }}
                      loading="lazy"
                    />
                  </div>
                </div>
              </div>
            ) : (
              <>
                <div className="d-flex flex-row">
                  <div className="col pl-0">
                    <div className="form-group">
                      <HeaderName
                        text="Informasi Setoran"
                        fontSize={`fw-700 ${IS_MOBILE ? "f14-vw" : "f10-vw"}`}
                        color2="purple"
                      />
                      <div className="my-1">
                        {detailInfo(
                          "ID Setoran",
                          <span className="font-weight-bold">
                            {detail?.wsetoran_id}
                          </span>
                        )}
                        {detailInfo(
                          "Tanggal Setoran",
                          <>
                            <span>
                              {getServerTimeWithoutDays(
                                detail?.created_at,
                                timezone
                              )}
                            </span>
                            <span className="font-weight-bold">
                              {` ,${getClientTimeWithoutSecond(
                                detail?.created_at,
                                timezone
                              )} ${getTimezoneOffsetClient(timezone)}`}
                            </span>
                          </>
                        )}
                        {detailInfo("Disetorkan oleh", detail?.full_name)}
                        {detailInfo(
                          "Total Setoran",
                          <span className="font-weight-bold">
                            Rp {priceSplit(detail?.total_setoran)}
                          </span>
                        )}
                        {detailInfo(
                          "Metode Pembayaran",
                          filterPaymentMethod(detail?.payment_type)?.label
                        )}
                        {detailInfo(
                          "Tanggal Transfer",
                          getServerTimeWithoutDays(
                            detail?.transfer_date,
                            timezone
                          )
                        )}
                        {detail?.payment_type === 2 &&
                          detailInfo(
                            "Nama Bank",
                            filterBankList(detail?.to_bank_id)?.label
                          )}
                        {detailInfo(
                          detail?.payment_type === 2
                            ? "Account Name"
                            : "Penerima",
                          detail?.acc_name
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col pr-0">
                    <div className="form-group">
                      <div
                        className={`fw-700 ${IS_MOBILE ? "f14-vw" : "f10-vw"}`}
                        style={{ color: "#5e5e5e" }}
                      >
                        Bukti Transfer/Tanda Terima
                      </div>
                      <div className="my-1">
                        <img
                          src={bucketRnaWeb + detail?.bukti_setoran + oss}
                          alt="bukti-setoran"
                          style={{
                            width: "100%",
                            maxHeight: 150,
                            objectFit: "contain",
                          }}
                          onError={({ currentTarget }) => {
                            currentTarget.onerror = null;
                            currentTarget.src = img_fallback;
                          }}
                          loading="lazy"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="px-3">
                  <div className="form-group">
                    <label
                      htmlFor=""
                      className={`fw-700 ${IS_MOBILE ? "f14-vw" : "f10-vw"}`}
                      style={{ color: "#5e5e5e" }}
                    >
                      Note
                    </label>
                    <div className={FONT_SIZE}>
                      {detail?.note ? detail?.note : "-"}
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
          <div className="col">
            <hr className="separator-blue" />
          </div>
          <div className="col">
            <div className="d-flex justify-content-center">
              <div className={IS_MOBILE ? "col-5 px-0" : "col-2 px-0"}>
                <button
                  className={`btn btn-sm btn-block bg-green text-white ${FONT_SIZE}`}
                  onClick={() => close()}
                >
                  OK
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {mode === "reject-confirmation" && (
        <div
          className="p-2 my-2"
          style={{ width: IS_MOBILE ? "21rem" : "45rem" }}
        >
          <div className="px-3">
            <div className="d-flex justify-content-center">
              <img src="/img/popup/reject-new.svg" alt="" loading="lazy" />
            </div>
            <div className="text-center">
              <div className={`fw-700 ${IS_MOBILE ? "f14-vw" : "f10-vw"}`}>
                Apakah anda yakin ingin "Reject" Setoran?
              </div>
            </div>
          </div>
          <div className="px-3">
            <hr className="separator-blue my-2" />
          </div>

          <div className="px-3">
            {IS_MOBILE ? (
              <div
                className="scroll-popup hide-scrollbar"
                style={{ height: "17rem" }}
              >
                <div className="form-group">
                  <HeaderName
                    text="Informasi Setoran"
                    fontSize={`fw-700 ${IS_MOBILE ? "f14-vw" : "f10-vw"}`}
                    color2="purple"
                  />
                  <div className="my-1">
                    {detailInfo(
                      "ID Setoran",
                      <span className="font-weight-bold">
                        {detail?.wsetoran_id}
                      </span>
                    )}
                    {detailInfo(
                      "Tanggal Setoran",
                      <>
                        <span>
                          {getServerTimeWithoutDays(
                            detail?.created_at,
                            timezone
                          )}
                        </span>
                        <span className="font-weight-bold">
                          {` ,${getClientTimeWithoutSecond(
                            detail?.created_at,
                            timezone
                          )} ${getTimezoneOffsetClient(timezone)}`}
                        </span>
                      </>
                    )}
                    {detailInfo("Disetorkan oleh", detail?.full_name)}
                    {detailInfo(
                      "Total Setoran",
                      <span className="font-weight-bold">
                        Rp {priceSplit(detail?.total_setoran)}
                      </span>
                    )}
                    {detailInfo(
                      "Metode Pembayaran",
                      filterPaymentMethod(detail?.payment_type)?.label
                    )}
                    {detailInfo(
                      "Tanggal Transfer",
                      getServerTimeWithoutDays(detail?.transfer_date, timezone)
                    )}
                    {detail?.payment_type === 2 &&
                      detailInfo(
                        "Nama Bank",
                        filterBankList(detail?.to_bank_id)?.label
                      )}
                    {detailInfo(
                      detail?.payment_type === 2 ? "Account Name" : "Penerima",
                      detail?.acc_name
                    )}
                  </div>
                </div>
                <div className="form-group">
                  <label
                    htmlFor=""
                    className={`fw-700 ${IS_MOBILE ? "f14-vw" : "f10-vw"}`}
                    style={{ color: "#5e5e5e" }}
                  >
                    Note
                  </label>
                  <div className={FONT_SIZE}>
                    {detail?.note ? detail?.note : "-"}
                  </div>
                </div>
                <div className="form-group">
                  <div
                    className={`fw-700 ${IS_MOBILE ? "f14-vw" : "f10-vw"}`}
                    style={{ color: "#5e5e5e" }}
                  >
                    Bukti Transfer/Tanda Terima
                  </div>
                  <div className="my-1">
                    <img
                      src={bucketRnaWeb + detail?.bukti_setoran + oss}
                      alt="bukti-setoran"
                      style={{
                        width: "100%",
                        maxHeight: 150,
                        objectFit: "contain",
                      }}
                      onError={({ currentTarget }) => {
                        currentTarget.onerror = null;
                        currentTarget.src = img_fallback;
                      }}
                      loading="lazy"
                    />
                  </div>
                </div>
                <div className="form-group">
                  <label
                    htmlFor=""
                    className={`fw-700 ${IS_MOBILE ? "f14-vw" : "f10-vw"}`}
                  >
                    Alasan Reject
                  </label>
                  <div className="">
                    <textarea
                      name="note"
                      id="note"
                      rows="5"
                      className={"form-control form-control-sm " + FONT_SIZE}
                      style={{
                        borderColor: "grey",
                        resize: "none",
                        backgroundColor: "white",
                      }}
                      onChange={(e) => setNote(e.target.value)}
                      value={note}
                      placeholder="Note..."
                      autoFocus
                    />
                  </div>
                </div>
              </div>
            ) : (
              <>
                <div className="d-flex flex-row">
                  <div className="col pl-0">
                    <div className="form-group">
                      <HeaderName
                        text="Informasi Setoran"
                        fontSize={`fw-700 ${IS_MOBILE ? "f14-vw" : "f10-vw"}`}
                        color2="purple"
                      />
                      <div className="my-1">
                        {detailInfo(
                          "ID Setoran",
                          <span className="font-weight-bold">
                            {detail?.wsetoran_id}
                          </span>
                        )}
                        {detailInfo(
                          "Tanggal Setoran",
                          <>
                            <span>
                              {getServerTimeWithoutDays(
                                detail?.created_at,
                                timezone
                              )}
                            </span>
                            <span className="font-weight-bold">
                              {` ,${getClientTimeWithoutSecond(
                                detail?.created_at,
                                timezone
                              )} ${getTimezoneOffsetClient(timezone)}`}
                            </span>
                          </>
                        )}
                        {detailInfo("Disetorkan oleh", detail?.full_name)}
                        {detailInfo(
                          "Total Setoran",
                          <span className="font-weight-bold">
                            Rp {priceSplit(detail?.total_setoran)}
                          </span>
                        )}
                        {detailInfo(
                          "Metode Pembayaran",
                          filterPaymentMethod(detail?.payment_type)?.label
                        )}
                        {detailInfo(
                          "Tanggal Transfer",
                          getServerTimeWithoutDays(
                            detail?.transfer_date,
                            timezone
                          )
                        )}
                        {detail?.payment_type === 2 &&
                          detailInfo(
                            "Nama Bank",
                            filterBankList(detail?.to_bank_id)?.label
                          )}
                        {detailInfo(
                          detail?.payment_type === 2
                            ? "Account Name"
                            : "Penerima",
                          detail?.acc_name
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col pr-0">
                    <div className="form-group">
                      <div
                        className={`fw-700 ${IS_MOBILE ? "f14-vw" : "f10-vw"}`}
                        style={{ color: "#5e5e5e" }}
                      >
                        Bukti Transfer/Tanda Terima
                      </div>
                      <div className="my-1">
                        <img
                          src={bucketRnaWeb + detail?.bukti_setoran + oss}
                          alt="bukti-setoran"
                          style={{
                            width: "100%",
                            maxHeight: 150,
                            objectFit: "contain",
                          }}
                          onError={({ currentTarget }) => {
                            currentTarget.onerror = null;
                            currentTarget.src = img_fallback;
                          }}
                          loading="lazy"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="d-flex flex-row">
                  <div className="col pl-0">
                    <div className="form-group">
                      <label
                        htmlFor=""
                        className={`fw-700 ${IS_MOBILE ? "f14-vw" : "f10-vw"}`}
                        style={{ color: "#5e5e5e" }}
                      >
                        Note
                      </label>
                      <div className={FONT_SIZE}>
                        {detail?.note ? detail?.note : "-"}
                      </div>
                    </div>
                  </div>
                  <div className="col pr-0">
                    <div className="form-group">
                      <label
                        htmlFor=""
                        className={`fw-700 ${IS_MOBILE ? "f14-vw" : "f10-vw"}`}
                      >
                        Alasan Reject
                      </label>
                      <div className="">
                        <textarea
                          name="note"
                          id="note"
                          rows="5"
                          className={
                            "form-control form-control-sm " + FONT_SIZE
                          }
                          style={{
                            borderColor: "grey",
                            resize: "none",
                            backgroundColor: "white",
                          }}
                          onChange={(e) => setNote(e.target.value)}
                          value={note}
                          placeholder="Note..."
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
          <div className="col">
            <hr className="separator-blue" />
            <div className="text-center mb-3">
              <small className="f8-vw">
                Apakah anda yakin ingin melanjutkan?
              </small>
            </div>
          </div>
          <div className="col">
            <div className="d-flex justify-content-between">
              <div className={`${IS_MOBILE ? "col-5 px-0" : "col-3 px-0"}`}>
                <button
                  className={
                    "btn btn-sm btn-block btn-outline-purple " + FONT_SIZE
                  }
                  onClick={() => back()}
                >
                  Back
                </button>
              </div>
              <div className={`${IS_MOBILE ? "col-5 px-0" : "col-3 px-0"}`}>
                <button
                  className={`btn btn-sm btn-block ${
                    note === "" ? "btn-secondary" : "btn-gradient"
                  } text-white ${FONT_SIZE}`}
                  onClick={() => confirm()}
                  disabled={note === ""}
                >
                  Confirm
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {mode === "reject-success" && (
        <div
          className="p-2 my-2"
          style={{ width: IS_MOBILE ? "21rem" : "45rem" }}
        >
          <div className="px-3">
            <div className="d-flex justify-content-center">
              <img src="/img/popup/reject-success.svg" alt="" loading="lazy" />
            </div>
            <div className="text-center">
              <div className="fw-700 f10-vw">Setoran Berhasil di Reject</div>
            </div>
          </div>
          <div className="px-3">
            <hr className="separator-blue my-2" />
          </div>

          <div className="px-3">
            {IS_MOBILE ? (
              <div
                className="scroll-popup hide-scrollbar"
                style={{ height: "17rem" }}
              >
                <div className="form-group">
                  <HeaderName
                    text="Informasi Setoran"
                    fontSize={`fw-700 ${IS_MOBILE ? "f14-vw" : "f10-vw"}`}
                    color2="purple"
                  />
                  <div className="my-1">
                    {detailInfo(
                      "ID Setoran",
                      <span className="font-weight-bold">
                        {detail?.wsetoran_id}
                      </span>
                    )}
                    {detailInfo(
                      "Tanggal Setoran",
                      <>
                        <span>
                          {getServerTimeWithoutDays(
                            detail?.created_at,
                            timezone
                          )}
                        </span>
                        <span className="font-weight-bold">
                          {` ,${getClientTimeWithoutSecond(
                            detail?.created_at,
                            timezone
                          )} ${getTimezoneOffsetClient(timezone)}`}
                        </span>
                      </>
                    )}
                    {detailInfo("Disetorkan oleh", detail?.full_name)}
                    {detailInfo(
                      "Total Setoran",
                      <span className="font-weight-bold">
                        Rp {priceSplit(detail?.total_setoran)}
                      </span>
                    )}
                    {detailInfo(
                      "Metode Pembayaran",
                      filterPaymentMethod(detail?.payment_type)?.label
                    )}
                    {detailInfo(
                      "Tanggal Transfer",
                      getServerTimeWithoutDays(detail?.transfer_date, timezone)
                    )}
                    {detail?.payment_type === 2 &&
                      detailInfo(
                        "Nama Bank",
                        filterBankList(detail?.to_bank_id)?.label
                      )}
                    {detailInfo(
                      detail?.payment_type === 2 ? "Account Name" : "Penerima",
                      detail?.acc_name
                    )}
                  </div>
                </div>
                <div className="form-group">
                  <label
                    htmlFor=""
                    className={`fw-700 ${IS_MOBILE ? "f14-vw" : "f10-vw"}`}
                    style={{ color: "#5e5e5e" }}
                  >
                    Note
                  </label>
                  <div className={FONT_SIZE}>
                    {detail?.note ? detail?.note : "-"}
                  </div>
                </div>
                <div className="form-group">
                  <div
                    className={`fw-700 ${IS_MOBILE ? "f14-vw" : "f10-vw"}`}
                    style={{ color: "#5e5e5e" }}
                  >
                    Bukti Transfer/Tanda Terima
                  </div>
                  <div className="my-1">
                    <img
                      src={bucketRnaWeb + detail?.bukti_setoran + oss}
                      alt="bukti-setoran"
                      style={{
                        width: "100%",
                        maxHeight: 150,
                        objectFit: "contain",
                      }}
                      onError={({ currentTarget }) => {
                        currentTarget.onerror = null;
                        currentTarget.src = img_fallback;
                      }}
                      loading="lazy"
                    />
                  </div>
                </div>
                <div className="form-group">
                  <label
                    htmlFor=""
                    className={`fw-700 ${IS_MOBILE ? "f14-vw" : "f10-vw"}`}
                  >
                    Alasan Reject
                  </label>
                  <div className={FONT_SIZE}>{note ? note : "-"}</div>
                </div>
              </div>
            ) : (
              <>
                <div className="d-flex flex-row">
                  <div className="col pl-0">
                    <div className="form-group">
                      <HeaderName
                        text="Informasi Setoran"
                        fontSize={`fw-700 ${IS_MOBILE ? "f14-vw" : "f10-vw"}`}
                        color2="purple"
                      />
                      <div className="my-1">
                        {detailInfo(
                          "ID Setoran",
                          <span className="font-weight-bold">
                            {detail?.wsetoran_id}
                          </span>
                        )}
                        {detailInfo(
                          "Tanggal Setoran",
                          <>
                            <span>
                              {getServerTimeWithoutDays(
                                detail?.created_at,
                                timezone
                              )}
                            </span>
                            <span className="font-weight-bold">
                              {` ,${getClientTimeWithoutSecond(
                                detail?.created_at,
                                timezone
                              )} ${getTimezoneOffsetClient(timezone)}`}
                            </span>
                          </>
                        )}
                        {detailInfo("Disetorkan oleh", detail?.full_name)}
                        {detailInfo(
                          "Total Setoran",
                          <span className="font-weight-bold">
                            Rp {priceSplit(detail?.total_setoran)}
                          </span>
                        )}
                        {detailInfo(
                          "Metode Pembayaran",
                          filterPaymentMethod(detail?.payment_type)?.label
                        )}
                        {detailInfo(
                          "Tanggal Transfer",
                          getServerTimeWithoutDays(
                            detail?.transfer_date,
                            timezone
                          )
                        )}
                        {detail?.payment_type === 2 &&
                          detailInfo(
                            "Nama Bank",
                            filterBankList(detail?.to_bank_id)?.label
                          )}
                        {detailInfo(
                          detail?.payment_type === 2
                            ? "Account Name"
                            : "Penerima",
                          detail?.acc_name
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col pr-0">
                    <div className="form-group">
                      <div
                        className={`fw-700 ${IS_MOBILE ? "f14-vw" : "f10-vw"}`}
                        style={{ color: "#5e5e5e" }}
                      >
                        Bukti Transfer/Tanda Terima
                      </div>
                      <div className="my-1">
                        <img
                          src={bucketRnaWeb + detail?.bukti_setoran + oss}
                          alt="bukti-setoran"
                          style={{
                            width: "100%",
                            maxHeight: 150,
                            objectFit: "contain",
                          }}
                          onError={({ currentTarget }) => {
                            currentTarget.onerror = null;
                            currentTarget.src = img_fallback;
                          }}
                          loading="lazy"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="d-flex flex-row">
                  <div className="col pl-0">
                    <div className="form-group">
                      <label
                        htmlFor=""
                        className={`fw-700 ${IS_MOBILE ? "f14-vw" : "f10-vw"}`}
                        style={{ color: "#5e5e5e" }}
                      >
                        Note
                      </label>
                      <div className={FONT_SIZE}>
                        {detail?.note ? detail?.note : "-"}
                      </div>
                    </div>
                  </div>
                  <div className="col pr-0">
                    <div className="form-group">
                      <label
                        htmlFor=""
                        className={`fw-700 ${IS_MOBILE ? "f14-vw" : "f10-vw"}`}
                      >
                        Alasan Reject
                      </label>
                      <div className={FONT_SIZE}>{note ? note : "-"}</div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>

          <div className="col">
            <hr className="separator-blue" />
          </div>
          <div className="col">
            <div className="d-flex justify-content-center">
              <div className={IS_MOBILE ? "col-5 px-0" : "col-2 px-0"}>
                <button
                  className={`btn btn-sm btn-block bg-green text-white ${FONT_SIZE}`}
                  onClick={() => close()}
                >
                  OK
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

const ShowImage = ({ sourceImg, close }) => {
  return (
    <div
      className="d-flex flex-column p-2"
      onClick={() => close()}
      style={{
        cursor: "pointer",
        width: IS_MOBILE ? "21rem" : "auto",
      }}
    >
      <div className="col px-0">
        <div className="d-flex justify-content-between">
          <div className="text-left px-2">
            <HeaderName
              text="Bukti Setoran"
              fontSize={FONT_SIZE_HEADER}
              color1="dark-blue fw-700"
              color2="dark-blue fw-700"
            />
          </div>
          <div className="" onClick={close}>
            <Icon
              icon={iconUrl.timesIcon}
              width="25"
              height="25"
              color="#742b9e"
            />
          </div>
        </div>
        <hr className="separator-grey my-1 mx-1" />
      </div>
      <div className="text-center mt-2 px-1">
        <img
          src={bucketRnaWeb + sourceImg + oss}
          alt="bukti setoran"
          style={{
            borderRadius: 5,
            width: "100%",
            objectFit: "contain",
            maxHeight: IS_MOBILE ? "26rem" : "30rem",
          }}
          loading="lazy"
        />
      </div>
    </div>
  );
};

const SetoranApproval = () => {
  const mounted = useRef(false);
  const { setpathLocation } = useContext(BreadcrumbContext);
  const [listApproval, setListApproval] = useState([]);
  const [loadingList, setLoadingList] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [mode, setMode] = useState("");
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [errorList, setErrorList] = useState("");
  const [errorSubmit, setErrorSubmit] = useState("");
  const [headerPopup, setHeaderPopup] = useState("");
  const [detailSelected, setDetailSelected] = useState({});
  const [note, setNote] = useState("");
  const [sourceImage, setSourceImage] = useState("");

  const isOpen = () => {
    setOpenModal(!openModal);
  };
  const reset = () => {
    setNote("");
    setErrorSubmit("");
  };

  const getListApproval = useCallback(() => {
    setLoadingList(true);
    getData("/api/v1/setoran/listapvsetoran")
      .then((resp) => {
        setLoadingList(false);
        if (mounted.current) {
          if (resp.data) {
            setListApproval(resp.data);
          } else {
            resp.json().then((err) => {
              setErrorList(t(safeErrCode(err)));
            });
          }
        }
      })
      .catch((error) => {
        console.error(error);
        setLoadingList(false);
        setErrorList(t(getErr(error)));
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openModal]);

  const submitApproval = (action) => {
    setLoadingSubmit(true);
    const payload = {
      wsetoran_id: detailSelected?.wsetoran_id,
      real_shiftids: detailSelected?.real_shiftids,
      process_type: action,
      note_rejected: note,
    };
    const formData = new FormData();
    for (const key in payload) {
      formData.append(key, payload[key]);
    }
    addData("/api/v1/setoran/apvsetoran", formData)
      .then((resp) => {
        setLoadingSubmit(false);
        if (resp.message === "Success") {
          if (action === 1) {
            setMode("approve-success");
          } else {
            setMode("reject-success");
          }
        } else {
          resp.json().then((err) => {
            setErrorSubmit(t(safeErrCode(err)));
          });
          setMode("error");
          if (action === 1) {
            setHeaderPopup("Approve Setoran Gagal");
          } else {
            setHeaderPopup("Reject Setoran Gagal");
          }
        }
      })
      .catch((error) => {
        console.log(error);
        setLoadingSubmit(false);
        setErrorSubmit(t(getErr(error)));
        setMode("error");
        if (action === 1) {
          setHeaderPopup("Approve Setoran Gagal");
        } else {
          setHeaderPopup("Reject Setoran Gagal");
        }
      });
  };

  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);

  useEffect(() => {
    getListApproval();
  }, [getListApproval]);

  useEffect(() => {
    setpathLocation("/shift/setoran-cash/approve-setoran");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {IS_MOBILE ? (
        <div className="d-flex flex-column px-3 py-1 mt-1 mb-5">
          <div className="col px-1">
            {listApproval?.length > 0 ? (
              listApproval?.map((item, index) => (
                <MobileCardList
                  key={item?.wsetoran_id}
                  title={
                    <>
                      <div
                        className="f10-vw fw-500"
                        style={{ color: "#959595" }}
                      >
                        ID Setoran
                      </div>
                      <div>{item?.wsetoran_id}</div>
                    </>
                  }
                  action_button={
                    item?.status !== 2 &&
                    is_sub_menu_action("sf_setoran_approve_setoran") && (
                      <button
                        className="btn btn-block py-1 f8-vw btn-gradient text-white fw-700"
                        onClick={() => {
                          setMode("approval-popup");
                          isOpen();
                          setDetailSelected(item);
                        }}
                      >
                        <span className="mr-1">
                          <Icon icon={iconUrl.libraryAddCheck} width={18} />
                        </span>
                        <span className="f12-vw">Approve</span>
                      </button>
                    )
                  }
                >
                  <div className="card-hms-list">
                    <div className="col-5 px-0 grey-text">Disetorkan oleh</div>
                    <div className="col pr-0 f12-vw black-value">
                      {item?.full_name}
                    </div>
                  </div>

                  <div className="card-hms-list">
                    <div className="col-5 px-0 grey-text">Tanggal setoran</div>
                    <div className="col pr-0 f12-vw black-value">
                      <span>
                        {getServerTimeWithoutDays(item?.created_at, TIMEZONE)},
                      </span>
                      <span className="fw-700">
                        {` ${getClientTimeWithoutSecond(
                          item?.created_at,
                          TIMEZONE
                        )}
                          ${getTimezoneOffsetClient(TIMEZONE)}`}
                      </span>
                    </div>
                  </div>
                  <div className="card-hms-list">
                    <div className="col-5 px-0 grey-text">Total setoran</div>
                    <div className="col pr-0 f12-vw black-value">
                      Rp {priceSplit(item?.total_setoran)}
                    </div>
                  </div>
                  <div className="card-hms-list">
                    <div className="col-5 px-0 grey-text">
                      Metode Pembayaran
                    </div>
                    <div className="col pr-0 f12-vw black-value">
                      {filterPaymentMethod(item?.payment_type)?.label}
                    </div>
                  </div>
                  <div className="card-hms-list">
                    <div className="col-5 px-0 grey-text">
                      Tanggal Transfer /Diserahkan
                    </div>
                    <div className="col pr-0 f12-vw black-value">
                      {getServerTimeWithoutDays(item?.transfer_date, TIMEZONE)}
                    </div>
                  </div>
                  <div className="card-hms-list">
                    <div className="col-5 px-0 grey-text">Nama Bank</div>
                    <div className="col pr-0 f12-vw black-value">
                      {filterBankList(item?.to_bank_id)?.bank_name}
                      <div className="">
                        {filterBankList(item?.to_bank_id)?.bank_accno}
                      </div>
                    </div>
                  </div>
                  <div className="card-hms-list">
                    <div className="col-5 px-0 grey-text">Bukti Setoran</div>
                    <div className="col pr-0 f12-vw blue">
                      <div
                        className="f12-vw fw-700"
                        onClick={() => {
                          setSourceImage(item?.bukti_setoran);
                          isOpen();
                          setMode("show-picture");
                        }}
                        style={{ cursor: "pointer" }}
                      >
                        <span className="mr-1">
                          <Icon
                            icon={iconUrl.formMultiple20Filled}
                            width={20}
                          />
                        </span>
                        Lihat bukti
                      </div>
                    </div>
                  </div>
                  <div className="card-hms-list">
                    <div className="col-5 px-0 grey-text">Catatan</div>
                    <div className="col pr-0 f12-vw black-value">
                      {item?.note ? showMore(item?.note, 25) : "-"}
                    </div>
                  </div>
                </MobileCardList>
              ))
            ) : (
              <div style={{ position: "relative" }}>
                <MobileEmptyData
                  errMessage={errorList}
                  loading={loadingList}
                  height="40vh"
                />
              </div>
            )}
          </div>
        </div>
      ) : (
        <div className="px-3 py-2">
          <div className="my-2 px-3">
            <TableApprove
              errorMessage={errorList}
              loadingList={loadingList}
              list={listApproval}
              setMode={setMode}
              isOpen={isOpen}
              setDetailSelected={setDetailSelected}
              setSourceImage={setSourceImage}
              height="25rem"
            />
          </div>
        </div>
      )}
      <PopupModal modalIsOpen={openModal}>
        {mode === "approval-popup" ? (
          <PopupApproval
            detail={detailSelected}
            close={() => {
              isOpen();
              reset();
            }}
            reject={() => setMode("reject-confirmation")}
            approve={() => setMode("approve-confirmation")}
            mode={mode}
            back={() => setMode("approval-popup")}
          />
        ) : mode === "approve-confirmation" ? (
          <PopupApproval
            detail={detailSelected}
            close={() => {
              isOpen();
              reset();
            }}
            confirm={() => submitApproval(1)}
            mode={mode}
            loading={loadingSubmit}
            back={() => setMode("approval-popup")}
          />
        ) : mode === "reject-confirmation" ? (
          <PopupApproval
            detail={detailSelected}
            close={() => {
              isOpen();
              reset();
            }}
            confirm={() => submitApproval(2)}
            mode={mode}
            note={note}
            setNote={setNote}
            loading={loadingSubmit}
            back={() => setMode("approval-popup")}
          />
        ) : mode === "reject-success" || mode === "approve-success" ? (
          <PopupApproval
            detail={detailSelected}
            close={() => {
              isOpen();
              reset();
            }}
            mode={mode}
            note={note}
            loading={loadingSubmit}
            back={() => setMode("approval-popup")}
          />
        ) : mode === "show-picture" ? (
          <ShowImage
            sourceImg={sourceImage}
            close={() => {
              isOpen();
              setSourceImage("");
            }}
          />
        ) : (
          <>
            <NotifMessage
              closeModal={openModal}
              img={"/img/error-icon/setoran-mingguan-gagal.png"}
              messageHeader={headerPopup}
              color="red"
              messageBody={errorSubmit}
            />
            <ButtonClose
              close={() => {
                reset();
                isOpen();
              }}
              text="OK"
            />
          </>
        )}
      </PopupModal>
    </>
  );
};

export default SetoranApproval;
