import React from "react";

import { HotelBanner } from "./HotelBanner";

function Banner() {
  const hotelInfo =
    localStorage?.getItem("info_hotel") &&
    JSON.parse(localStorage.getItem("info_hotel"));
  return <>{hotelInfo && <HotelBanner />}</>;
}

export default Banner;
