import { sha256 } from "js-sha256";
import { isIOS, isMacOs } from "react-device-detect";
import jwt from "jsonwebtoken";
import history from "./history";
import ReactTooltip from "react-tooltip";
import { Fragment } from "react";
import { IS_MOBILE } from "../common/Responsive";

export const isEmpty = (obj) => {
  return Object.keys(obj)?.length === 0;
};
export const bucketRnaWeb = "https://img.ruangnyaman.id/";
// export const bucketRnaStorage = "https://imgstorage.ruangnyaman.id/";

export const priceSplit = (price, emptyResult = "") => {
  let result = 0;
  if (price === "") {
    result = emptyResult;
  } else if (price > 999) {
    result = price.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
  } else {
    result = price;
  }
  return result;
};

const hexStringToByte = (str) => {
  if (!str) {
    return new Uint8Array();
  }

  var a = [];
  for (var i = 0, len = str.length; i < len; i += 2) {
    a.push(parseInt(str.substr(i, 2), 16));
  }
  return new Uint8Array(a);
};

const longToByteArray = (long) => {
  // we want to represent the input as a 8-bytes array
  var byteArray = [0, 0, 0, 0, 0, 0, 0, 0];

  for (var index = 0; index < byteArray.length; index++) {
    var byte = long & 0xff;
    byteArray[index] = byte;
    long = (long - byte) / 256;
  }

  return byteArray;
};

export const combineSHA256 = (password) => {
  const tsD = Math.floor(Date.now() / 1000);
  const passSha256 = sha256(password);
  const passByte = Buffer.from(hexStringToByte(passSha256), "utf8");
  const tsDBuffer = longToByteArray(tsD);
  const combined = new Uint8Array([...passByte, ...tsDBuffer]);
  return sha256(combined);
};

export const checkLoggedIn = () => {
  return sessionStorage?.getItem("token") ? true : false;
};

export const isLogout = () => {
  let remember_me = localStorage.getItem("remember_me");

  sessionStorage.clear();
  localStorage.clear();
  localStorage.setItem("remember_me", remember_me);
  history.push("/login");
};

export const tableCol = (param) => {
  return (param + 1) % 2 === 0 ? "table-bg" : "bg-white";
};

export const zeroNumber = (param) => {
  return param.replace(/^0+/, "");
};

export const disableButton = (param) => {
  return param ? true : false;
};
export const disableColor = (param) => {
  return param
    ? "btn btn-gradient text-white f8-vw rounded-input btn-block"
    : "btn bg-grey text-white f8-vw rounded-input btn-block font-weight-bold";
};

export const oss = `?x-oss-process=style/${
  isIOS || isMacOs ? "q80" : "webp80"
}`;

export const review = (param) => {
  if (param > 0 && param <= 2) {
    return "Kurang";
  } else if (param > 2 && param <= 3) {
    return "Cukup";
  } else if (param > 3 && param <= 4) {
    return "Bagus";
  } else if (param > 4 && param <= 5) {
    return "Sangat Bagus";
  }
};

export const msgConfirm = (param, msg) => {
  return (
    <>
      {msg}
      <span className="msg">{param}</span>
    </>
  );
};
export const msgSuccess = (param, msg) => {
  return (
    <>
      <span className="msg">{param}</span>
      {msg}
    </>
  );
};

export const formatWithoutAMPM = (date) => {
  let hours = date.getHours();
  let minutes = date.getMinutes();
  hours = hours < 10 ? "0" + hours : hours;
  minutes = minutes < 10 ? "0" + minutes : minutes;
  return hours + ":" + minutes;
};

export const combineDateWithTime2 = (dt, timeString) => {
  var startDateTime;
  var parts = /^(\d+).(\d+)$/.exec(timeString);
  if (parts) {
    let hours = parseInt(parts[1], 10);
    let minutes = parseInt(parts[2], 10);

    if (!isNaN(hours) && !isNaN(minutes)) {
      startDateTime = new Date(dt.getTime());
      startDateTime.setHours(hours);
      startDateTime.setMinutes(minutes);
    }
  }
  return startDateTime;
};

export const validateEmail = (param, setError, test) => {
  const validEmail = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  if (param.length <= 0) {
    setError({ ...test, email: "Email tidak boleh kosong" });
  } else if (!validEmail.test(param)) {
    setError({ ...test, email: "Format email salah" });
  } else {
    return setError({ ...test, email: null });
  }
};

export const validatePassword = (param, setError, test) => {
  if (param.length <= 0) {
    setError({ ...test, pass: "Password tidak boleh kosong" });
  } else {
    return setError({ ...test, pass: null });
  }
};

export const dateFormat = (date) => {
  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  let day = String(date.getDate(date)).padStart(2, "0");
  let month = monthNames[date.getMonth(date)];
  let year = date.getFullYear(date);
  return day + " " + month + " " + year;
};

export const getDayDuration = (start, end) => {
  let date_start = new Date(start);
  let date_end = new Date(end);
  let checkstartdate = Date.UTC(
    date_start.getFullYear(),
    date_start.getMonth(),
    date_start.getDate()
  );
  let checkenddate = Date.UTC(
    date_end.getFullYear(),
    date_end.getMonth(),
    date_end.getDate()
  );
  return Math.floor(
    Math.abs(checkenddate - checkstartdate) / (1000 * 60 * 60 * 24)
  );
};

export const addDays = (date, days) => {
  var result = new Date(date);
  result.setDate(result.getDate() + days);
  return result;
};

export const validateToken = () => {
  var isValid = false;
  var decoded = jwt.decode(sessionStorage.getItem("token"), {
    complete: true,
  });
  if (decoded) {
    var dateNow = new Date();
    if (decoded.payload.exp * 1000 > dateNow.getTime()) {
      isValid = true;
    } else {
      isLogout(history);
    }
  }
  // console.log(isValid);
  return isValid;
};

export const formatDate = (date) => {
  let day = String(date.getDate(date)).padStart(2, "0");
  let month = String(date.getMonth(date) + 1).padStart(2, "0");
  let year = date.getFullYear(date);
  return year + "-" + month + "-" + day;
};

export const getServerTime = (timeUtc, timeZone) => {
  const convertedTime = new Date(timeUtc);

  const weekday = convertedTime.toLocaleString("en-US", {
    weekday: "short",
    timeZone,
  });

  const day = convertedTime.toLocaleString("en-US", {
    day: "2-digit",
    timeZone,
  });
  const month = convertedTime.toLocaleString("en-US", {
    month: "short",
    timeZone,
  });
  const year = convertedTime.toLocaleString("en-US", {
    year: "numeric",
    timeZone,
  });

  return weekday + ", " + day + " " + month + " " + year;
};

export const paymentStatus = [
  { value: 0, label: "Not Paid", color: "orange" },
  { value: 1, label: "Paid", color: "green" },
  { value: 2, label: "Not Paid", color: "orange" },
  { value: 3, label: "Cancel", color: "red" },
  { value: 5, label: "Expired", color: "red" },
  { value: 9, label: "Not Paid", color: "orange" },
];

export const paymentLabel = (payment_label) => {
  let label = paymentStatus.find((item) => payment_label === item?.value);
  return label?.label;
};
export const changeCol = (payment_status) => {
  let color = paymentStatus.find(
    (item) => parseInt(payment_status) === item?.value
  );
  return color?.color;
};

export const getServerTimeWithoutDays = (timeUtc, timeZone) => {
  const convertedTime = new Date(timeUtc);

  const day = convertedTime?.toLocaleString("en-US", {
    day: "2-digit",
    timeZone,
  });
  const month = convertedTime?.toLocaleString("en-US", {
    month: "short",
    timeZone,
  });
  const year = convertedTime?.toLocaleString("en-US", {
    year: "numeric",
    timeZone,
  });

  return day + " " + month + " " + year;
};

export const slicePhoneNumber = (val) => {
  let d = val.substring(0, 2) + "." + val.substring(2, val.length);
  return d;
};

export const roomLabel = (room_id) => {
  let hotel_room = JSON.parse(localStorage.getItem("room_type"));
  let d = hotel_room?.find((item) => item.value === room_id);
  let room_label = d?.label;
  return room_label;
};

export const showMoreGuest = (guest, length = 15) => {
  let d = guest;
  let text = d?.length > length ? d.substring(0, length) + "..." : d;
  if (d?.length > length) {
    return (
      <>
        <button
          type="button"
          className={`text-black border-0 text-left p-0 ${
            IS_MOBILE ? "f12-vw" : "f8-vw"
          }`}
          style={{ background: "transparent" }}
          data-tip={d}
        >
          {text}
        </button>
        <ReactTooltip
          backgroundColor="white"
          textColor="black"
          className="data-class"
          border
        />
      </>
    );
  } else {
    return d;
  }
};

export const parsePhone = (val) => {
  let d = String(val);
  let data = d?.replace(".", " ");
  return "+" + data;
};

export const paymentMethods = [
  { value: 1, label: "Virtual Account" },
  { value: 2, label: "Transfer Bank" },
  { value: 3, label: "E-Money" },
  { value: 4, label: "Cash" },
  { value: 5, label: "Point" },
];

export const getPaymentMethods = (val) => {
  return paymentMethods.find((i) => i.value.toString() === val.charAt(0))
    ?.label;
};

export const generateBookingCode = (param) => {
  let d = param.toUpperCase();
  let text = d?.replace(/\s/g, "");
  return text;
};

export const originChannels = [
  { value: 1, label: "Web" },
  { value: 2, label: "APK" },
  { value: 3, label: "HMS" },
];

export const getOriginChannels = (val) => {
  return originChannels.find((item) => item.value === val)?.label;
};

export const getTimeStamp = (date) => {
  let checkin = addDays(date, 1);
  let newDate = checkin.getTime() / 1000;
  let today = new Date().getTime() / 1000;
  let d = newDate < today ? true : false;
  return d;
};

export const formatTimezone = () => {
  const tzOffset = new Date().getTimezoneOffset() / -60;
  if (tzOffset === 7) {
    return "WIB";
  } else if (tzOffset === 8) {
    return "WITA";
  } else if (tzOffset === 9) {
    return "WIT";
  } else {
    return "Timezone Not Supported";
  }
};

export const timeShift = (param) => {
  let d = new Date(param);
  let strTime = d.toISOString();
  let dx = new Date(strTime);
  //let result = dx.getHours() + ":" + dx.getMinutes();

  let hours = dx.getHours() < 10 ? "0" + dx.getHours() : dx.getHours();
  let minutes = dx.getMinutes() < 10 ? "0" + dx.getMinutes() : dx.getMinutes();
  return hours + ":" + minutes;
};

export const filterCompensation = (param, val) => {
  let dx = param.filter((item) => item.category_id === val);
  return dx;
};

export const filterCountCompensation = (param, val) => {
  return param
    .filter((item) => item.category_id === val)
    .map((data) => data.qty);
};

export const getLocalRoomCompensation = () => {
  try {
    let arr = JSON.parse(localStorage?.getItem("room"));
    return arr;
  } catch (errCatch) {
    console.log("failed");
  }
};
export const getLocalPenaltySubmit = () => {
  try {
    let arr = JSON.parse(localStorage?.getItem("penalty_submit"));
    return arr;
  } catch (errCatch) {
    console.log("failed");
  }
};

export const saveLocalRoomCompensation = (data_local) => {
  try {
    let arr = JSON.stringify(data_local);
    localStorage.setItem("room", arr);
  } catch (errCatch) {
    console.log("failed");
  }
};
export const saveLocalQtyRoom = (data_local) => {
  try {
    let arr = JSON.stringify(data_local);
    localStorage.setItem("data_equipment", arr);
  } catch (errCatch) {
    console.log("failed");
  }
};
export const saveLocalPenaltySubmit = (data_local) => {
  try {
    let arr = JSON.stringify(data_local);
    localStorage.setItem("penalty_submit", arr);
  } catch (errCatch) {
    console.log("failed");
  }
};
export const saveLocalPenaltyData = (data_local) => {
  try {
    let arr = JSON.stringify(data_local);
    localStorage.setItem("penalty_data", arr);
  } catch (errCatch) {
    console.log("failed");
  }
};

export const editLocalRoomCompensation = (list, id, edits) => {
  Object.assign(
    list.find((b) => b.room === id),
    edits
  );
  return list;
};

export const editLocalQty = (list, room_number, edits) => {
  Object.assign(
    list.find((b) => b.room === room_number),
    edits
  );
  return list;
};

export const getTotalPriceCompensation = (list, id, edits) => {
  Object.assign(
    list.find((b) => b.room === id),
    edits
  );
  return list;
};

export const getPriceCompensation = () => {
  let rooms = getLocalRoomCompensation(),
    result = rooms
      .map((room) => room.price)
      .reduce((acc, price) => price + acc);
  return result;
};
export const getPricePenalty = () => {
  let rooms = getLocalPenaltySubmit(),
    result = rooms
      .map((room) => room.price)
      .reduce((acc, price) => price + acc);
  return result;
};

export const getChecked = (id_target, id_arr) => {
  return id_arr.includes(id_target) ? true : false;
};

export const getCheckedItemIDS = (room, id_target) => {
  let d = getLocalRoomCompensation();
  let dx = !!d && d.length > 0 && d.filter((item) => item.room === room);
  let result = false;
  if (dx.length > 0) {
    let ids = dx[0].itemids;
    result = ids.includes(id_target) ? true : false;
  }
  return result;
};
export const getCheckedItemPenalty = (room, id_target) => {
  let d = getLocalPenaltySubmit();
  let dx = !!d && d.length > 0 && d.filter((item) => item.room === room);
  let result = false;
  if (dx.length > 0) {
    let ids = dx[0].itemids;
    result = ids?.includes(id_target) ? true : false;
  }
  return result;
};

export const getRoomEquipment = (room, item_id) => {
  let d = JSON.parse(localStorage?.getItem("data_equipment"));
  let dx = d.filter((item) => item.room === room);
  let dxx = dx[0]?.room_equipment;
  return dxx?.find((data) => data.item_id === item_id)?.qty;
};
export const getLinenEquipment = (room, item_id) => {
  let d = JSON.parse(localStorage?.getItem("data_equipment"));
  let dx = d.filter((item) => item.room === room);
  let dxx = dx[0]?.linen_equipment;
  return dxx?.find((data) => data.item_id === item_id)?.qty;
};

export const getCompensationQty = (room, item_id) => {
  let d = JSON.parse(localStorage?.getItem("data_equipment"));
  let dx = !!d && d.length > 0 && d.filter((item) => item.room === room);
  let dxx = dx[0]?.compensation;
  return dxx?.find((data) => data.item_id === item_id)?.qty;
};
export const getPenaltyQty = (room, item_id) => {
  let d = JSON.parse(localStorage?.getItem("penalty_data"));
  let dx = !!d && d.length > 0 && d.filter((item) => item.room === room);
  let dxx = dx[0]?.penalty;
  return dxx?.find((data) => data.item_id === item_id)?.qty;
};

export const isFileUploadInvalid = (files) => {
  let d = false;
  if (files.length > 0) {
    for (const key of Object.keys(files)) {
      if (!files[key]) {
        d = true;
      }
    }
  }
  return d;
};

export const getOptionSelectValue = (select, id) => {
  let d = select?.filter((item) => String(item.value) === String(id));
  return d;
};

export const getLabel = (select, id) => {
  return select?.find((item) => parseInt(item.value) === parseInt(id))?.label;
};

export const filterChannelOther = (data) => {
  return data?.filter((item) => item.channel_id > 3 && item.active);
};

export const findChannelOther = (data, id) => {
  return data?.find((item) => item.channel_id === id)?.ota_name;
};

export const findLabel = (data, id) => {
  return data?.find((item) => String(item.value) === String(id))?.label;
};

export const getClientTime = (timeUtc, timeZone) => {
  const convertedTime = new Date(timeUtc);

  const hoursTime = convertedTime.toLocaleString("en-US", {
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
    hour12: false,
    timeZone,
  });

  const time = hoursTime.split(":");
  let hours = time[0];
  let minutes = time[1];
  let seconds = time[2];

  if (parseInt(hours) === 24) {
    return `00:${minutes}:${seconds}`;
  } else {
    return hoursTime;
  }
};

export const getClientTimeWithoutSecond = (timeUtc, timeZone) => {
  const convertedTime = new Date(timeUtc);

  const hoursTime = convertedTime.toLocaleString("en-US", {
    hour: "2-digit",
    minute: "2-digit",
    hour12: false,
    timeZone,
  });

  const time = hoursTime.split(":");
  let hours = time[0];
  let minutes = time[1];

  if (parseInt(hours) === 24) {
    return `00:${minutes}`;
  } else {
    return hoursTime;
  }
};

export const getTimezoneOffsetClient = (time) => {
  let tzOffset = Intl?.DateTimeFormat([], {
    timeZone: time,
    timeZoneName: "shortOffset",
  })
    .formatToParts()
    .find((o) => o.type === "timeZoneName")
    .value.match(/-?\d+/)[0];

  if (tzOffset === "7") {
    return "WIB";
  } else if (tzOffset === "8") {
    return "WITA";
  } else if (tzOffset === "9") {
    return "WIT";
  } else {
    return "Timezone Not Supported";
  }
};

export const splitGuestName = (guest) => {
  let name = guest.split(",");
  return showMoreGuest(name);
};

export const checkAclList = (acl, param) => {
  return acl.includes(param);
};

export const counterFormat = (value) => {
  const sec = parseInt(value, 10); // convert value to number if it's string
  let hours = Math.floor(sec / 3600); // get hours
  let minutes = Math.floor((sec - hours * 3600) / 60); // get minutes
  let seconds = sec - hours * 3600 - minutes * 60; //  get seconds
  // add 0 if value < 10; Example: 2 => 02
  if (hours < 10) {
    hours = "0" + hours;
  }
  if (minutes < 10) {
    minutes = "0" + minutes;
  }
  if (seconds < 10) {
    seconds = "0" + seconds;
  }
  return hours + ":" + minutes + ":" + seconds;
};

export const timerString = (hours, minutes, seconds) => {
  let time = new Date();
  let newTime = time.setHours(
    time.getHours() + parseInt(hours),
    time.getMinutes() + parseInt(minutes),
    time.getSeconds() + parseInt(seconds)
  );
  let tsRemain = new Date(newTime);
  let ts = Math.floor(tsRemain / 1000);
  let d = Math.floor(Date.now() / 1000);
  let dx = ts - d;
  return dx;
};

export const getEndHourShift = (end) => {
  let timezone =
    localStorage.getItem("timezone") && localStorage.getItem("timezone");
  let dx = end && end.split(":");
  let x = new Date();
  let xTime = x.setHours(dx[0], dx[1], 59);
  let localTime = getClientTime(Date.now(), timezone);
  let splitTime = localTime.split(":");
  let clientTime = x.setHours(splitTime[0], splitTime[1], splitTime[2]);
  let convertTz = new Date(xTime);
  let tz = Math.floor(convertTz / 1000);
  let tzNow = Math.floor(clientTime / 1000);
  let calculateTime = Math.abs(tzNow - tz);

  return calculateTime;
};

export const getStatusApprovalRejection = (status, value) => {
  return status.find((item) => item.value === value)?.label;
};

export const splitDate = (date) => {
  let d = date && date.split("-");
  return d[2];
};

export const filterStatusActivity = (list, room_number, date) => {
  let d = list.filter(
    (item) =>
      item.room_number === room_number &&
      formatDate(new Date(item.date_range)) === date
  );
  return d.length > 0
    ? d.map((item, i) => {
        return item.statusroom;
      })
    : 1;
};

export const getBedSize = (item, i) => {
  let d = item.bed_size.split(/ x | /);
  let x = d.filter((item) => item !== "cm");
  return x[i];
};

const roomStatus = [
  {
    value: 1,
    label: "VR",
  },
  {
    value: 2,
    label: "OC",
  },
  {
    value: 3,
    label: "OD",
  },
  {
    value: 4,
    label: "VD",
  },
  {
    value: 5,
    label: "VC",
  },
  {
    value: 6,
    label: "OS",
  },
  {
    value: 7,
    label: "OO",
  },
];
const statusColor = [
  {
    value: 1,
    label: "status-vr",
  },
  {
    value: 2,
    label: "status-oc",
  },
  {
    value: 3,
    label: "status-od",
  },
  {
    value: 4,
    label: "status-vd",
  },
  {
    value: 5,
    label: "status-vc",
  },
  {
    value: 6,
    label: "status-os",
  },
  {
    value: 7,
    label: "status-oo",
  },
];

export const filterStatusRoom = (status) => {
  return roomStatus?.find((item) => parseInt(item.value) === parseInt(status))
    ?.label;
};

export const filterStatusColor = (status) => {
  return statusColor.find((item) => parseInt(item.value) === parseInt(status))
    ?.label;
};

export const statusRoomCustom = [
  { value: 1, title: "Vacant Ready", label: "VR", className: "status-vr" },
  { value: 2, title: "Occupied Clean", label: "OC", className: "status-oc" },
  { value: 3, title: "Occupied Dirty", label: "OD", className: "status-od" },
  { value: 4, title: "Vacant Dirty", label: "VD", className: "status-vd" },
  { value: 5, title: "Vacant Clean", label: "VC", className: "status-vc" },
  { value: 6, title: "Out of Service", label: "OS", className: "status-os" },
  { value: 7, title: "Out of Order", label: "OO", className: "status-oo" },
];

export const filterStatusRoomCustom = (status) => {
  return statusRoomCustom?.find(
    (item) => parseInt(item.value) === parseInt(status)
  );
};

export const splitText = (text) => {
  let reg = /[{}]/g;
  let d = text?.replace(reg, "");
  let textTruncate = d?.split(",");

  return textTruncate?.join(",");
};

export const guesttype = [
  { value: 1, label: "Personal" },
  { value: 2, label: "Group" },
];
export const purpose = [
  { value: 1, label: "Business Trip" },
  { value: 2, label: "Leisure/Vacation" },
  { value: 3, label: "Family Trip" },
];

export const filterPurpose = (id) => {
  return purpose?.find((item) => item.value === id);
};
export const filterGuestType = (id) => {
  return guesttype?.find((item) => item.value === id);
};

export const filterStringValue = (data, id) => {
  return data?.length > 0
    ? data?.find((item) => String(item.value) === String(id))
    : null;
};
export const filterStringLabel = (data, label) => {
  return data?.length > 0
    ? data?.find((item) => String(item.label) === String(label))
    : null;
};

export const getTimezoneOffsetString = (time) => {
  const clientDate = new Date();
  let getItem = function(format) {
    format.timeZone = time;
    return parseInt(clientDate.toLocaleString("id-ID", format));
  };

  let adjDate = new Date(
    getItem({ year: "numeric" }),
    getItem({ month: "numeric" }) - 1, // months are zero based
    getItem({ day: "numeric" }),
    getItem({ hour: "numeric", hour12: false }),
    getItem({ minute: "numeric" })
  );
  let noSecs = new Date(clientDate.getTime());
  noSecs.setSeconds(0, 0);
  let diff = Math.round((adjDate.getTime() - noSecs.getTime()) / 60000);

  let clientTimezone = clientDate.getTimezoneOffset() - diff;
  const tzOffset = clientTimezone / -60;

  if (tzOffset === 7) {
    return "+07:00";
  } else if (tzOffset === 8) {
    return "+08:00";
  } else if (tzOffset === 9) {
    return "09:00";
  } else {
    return "Timezone Not Supported";
  }
};

export const showMore = (text, length = 15) => {
  return text?.length > length ? text.substring(0, length) + "..." : text;
};

export const checkNullArr = (arr) => {
  return arr.some((element) => element === "");
};

export const checkTime = () => {
  var date = new Date(); // current time
  var hours = date.getHours();

  if (hours < 5) {
    return true;
  } else {
    return false;
  }
};

export const grandTotalBreakage = (arr) => {
  return arr.reduce((sum, i) => {
    return sum + i.item_value * i.com_qty;
  }, 0);
};

export const splitDateForecast = (date) => {
  let date_split = date.split("-");
  return date_split[2] + "/" + date_split[1];
};

export const filterForecast = (list, room_type, date) => {
  let d = list.filter(
    (item) =>
      item.room_type_id === room_type &&
      formatDate(new Date(item.booked_date)) === date
  );
  return d.length > 0
    ? d.map((item, i) => {
        return item.total_booked;
      })
    : 0;
};

export const totalBook = (date, data) => {
  let temp = {};

  let filter_data = data.filter(
    (item) => formatDate(new Date(item?.booked_date)) === date
  );
  const total = filter_data.reduce((acc, item) => {
    return acc + item.total_booked;
  }, 0);
  if (filter_data.length > 0) {
    temp.booked_date = date;
    temp.total_booked = total;
  } else {
    temp.booked_date = date;
    temp.total_booked = 0;
  }
  return temp;
};

export const filterRoomingList = (list, room_number, date, index, length) => {
  let d = list.filter(
    (item) =>
      item.room_number === room_number &&
      formatDate(new Date(item.max_occupied_date)) === date
  );

  return d.length > 0
    ? d.map((item, i) => {
        return (
          <Fragment key={i}>
            <div
              style={{
                marginBottom: 5,
                marginTop: 5,
                backgroundColor: "#0088FF",
                // opacity: "0.7",
                cursor: "pointer",
                width:
                  length === index + 1 &&
                  getDayDuration(
                    item.min_occupied_date,
                    item.max_occupied_date
                  ) === 0
                    ? 21
                    : 42 *
                      getDayDuration(
                        item.min_occupied_date,
                        item.max_occupied_date
                      ),
                padding: 4,
                borderRadius: "5px",
                position: "relative",
                // left: 20,
                right:
                  42 *
                    getDayDuration(
                      item.min_occupied_date,
                      item.max_occupied_date
                    ) -
                  21,
                zIndex: 5,
                textAlign: "center",
              }}
              data-tip={item.guest_name}
            >
              <span
                style={{
                  color: "white",
                  textShadow: "2px 2px 4px #000000",
                }}
              >
                {showMore(
                  item.guest_name,
                  getDayDuration(
                    item.min_occupied_date,
                    item.max_occupied_date
                  ) +
                    1 <
                    4
                    ? 4
                    : 20
                )}
              </span>
            </div>
            <ReactTooltip
              backgroundColor="white"
              textColor="black"
              className="data-class"
              border
            />
          </Fragment>
        );
      })
    : null;
};

export const checkLength = (arr) => {
  if (arr !== "") {
    const splitArr = arr.split(",");
    return splitArr;
  }
};

export const getLocalStorageItem = (item) => {
  try {
    let arr = JSON.parse(localStorage?.getItem(item));
    return arr;
  } catch (errCatch) {
    console.log("failed");
  }
};

export const isEmptyLocalStorage = (localArr) => {
  try {
    let arr = JSON.parse(localArr);
    return arr;
  } catch (errCatch) {
    console.log("failed");
  }
};

export const convertISOtoString = (date) => {
  const convert = new Date(date);
  const timestamp = convert.getTime();

  return timestamp;
};

export const checkInvalidTime = (ts_date) => {
  let isInvalid = false;
  const date_now = new Date();
  const ts_now = date_now.getTime();

  if (ts_now > ts_date) {
    isInvalid = true;
  }
  return isInvalid;
};

export const capitalizeLetter = (word) => {
  let capital = word.replace(/(^\w{1})|(\s+\w{1})|(,+\w{1})/g, (letter) =>
    letter.toUpperCase()
  );
  return capital;
};

export const convertTimeDuration = (hour_resp) => {
  let x = parseFloat(hour_resp);
  if (x > 0) {
    let hours = Math.round(Math.floor(x / 1));
    let minutes = Math.round((x - hours) * 60);
    if (minutes < 1) {
      return hours + " Hours";
    } else {
      return hours + " Hours, " + minutes + " Minutes";
    }
  } else {
    return "-";
  }
};

export const splitID = (arr, n = 3) => {
  const splitArr = arr.split(",");
  const arrLength = splitArr?.length;

  if (arrLength > 3) {
    const result = new Array(Math.ceil(arrLength / n))
      .fill()
      .map((items) => splitArr.splice(0, n));
    return result.map((item, i) => {
      return <div key={i}>{item.join(", ")}</div>;
    });
  } else {
    return splitArr.join(", ");
  }
};

export const checkMenuPath = (url) => {
  const location = history.location.pathname;
  if (location === url) {
    return "active-path";
  } else {
    return "";
  }
};

export const totalCompensation = (arr) => {
  let result = 0;
  if (arr.length > 0) {
    result = arr.reduce((a, v) => (a = a + v.value * v.qty), 0);
  }
  return result;
};

export const checkExtendDate = (date) => {
  const date_now = new Date();
  const ts_now = date_now.getTime();
  const ts_date = convertISOtoString(date);

  if (ts_date >= ts_now) {
    return addDays(date, 1);
  } else {
    return addDays(date_now, 1);
  }
};
