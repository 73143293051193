import { useState, useContext, useCallback, useEffect, useRef } from "react";
import { Icon } from "@iconify/react";
import { BreadcrumbContext } from "../../common/BreadcrumbContext";
import { EmptyData, MobileEmptyData } from "../Empty/EmptyData";
import { addData, getData, getErr, safeErrCode } from "../../utils/api-request";
import history from "../../utils/history";
import {
  filterStatusRoomCustom,
  getClientTimeWithoutSecond,
  tableCol,
} from "../../utils/index";
import PopupModal from "../../common/PopupModal";
import NotifMessage, { ButtonClose } from "../../common/Notif";
import {
  getClientTime,
  getServerTimeWithoutDays,
  getTimezoneOffsetClient,
} from "../../utils";
import { filterHousekeepingStatus } from "./common/utils";
import { config, iconUrl } from "../../config";
import { is_sub_menu_action } from "../../utils/uac";
import { mobileSearchInput, searchInput } from "../booking/bookingHelper";
import { IS_MOBILE } from "../../common/Responsive";
import { MobileCardList } from "../../common/CardList";
import { BottomSheet } from "react-spring-bottom-sheet";
import { t } from "../../common/ErrorMessage";

const FONT_SIZE = IS_MOBILE ? "f12-vw" : "f8-vw";
const FONT_SIZE_HEADER = IS_MOBILE ? "f14-vw" : "f10-vw";

const TableRoomInspection = ({
  list,
  loading,
  errorMessage,
  height,
  setRoomInspectionSelected,
  isOpen,
  setMode,
}) => {
  const timezone =
    localStorage?.getItem("timezone") && localStorage?.getItem("timezone");
  return (
    <>
      <div
        className="table-list table-responsive-sm scroll-table"
        style={{
          height: height,
        }}
      >
        <table
          className="table table-sm"
          style={{ borderCollapse: "separate", borderSpacing: 0 }}
        >
          <thead className="f8-vw sticky-table">
            <tr
              className="text-center text-white"
              style={{
                backgroundColor: "#0071A4",
              }}
            >
              <th rowSpan={2} className="align-middle">
                ID
              </th>
              <th rowSpan={2} className="align-middle">
                Tanggal
              </th>
              <th rowSpan={2} className="align-middle">
                Room No
              </th>
              <th rowSpan={2} className="align-middle">
                Lt
              </th>
              <th rowSpan={2} className="align-middle">
                Room Type
              </th>
              <th rowSpan={2} className="align-middle">
                Bed Type
              </th>
              <th rowSpan={1} colSpan={2} className="align-middle">
                Start
              </th>
              <th rowSpan={1} colSpan={2} className="align-middle">
                End
              </th>
              <th rowSpan={1} colSpan={2} className="align-middle">
                Status
              </th>
              <th rowSpan={2} className="align-middle">
                Last Update
              </th>
            </tr>
            <tr
              className="text-center text-white"
              style={{ backgroundColor: "#0071A4" }}
            >
              <th>Time</th>
              <th>Cleaning by</th>
              <th>Time</th>
              <th>Cleaning by</th>
              <th>Room</th>
              <th>Housekeeping</th>
            </tr>
          </thead>

          <tbody className="f8-vw">
            {list?.length > 0 && !loading ? (
              <>
                {list?.map((item, index) => (
                  <tr
                    className={`click-table ${tableCol(index)}`}
                    key={index}
                    style={{
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      setRoomInspectionSelected(item);
                      isOpen();
                      setMode("inspection-form");
                    }}
                  >
                    <td className="align-middle text-center font-weight-bold">
                      {item?.id}
                    </td>
                    <td className="align-middle text-center">
                      {getServerTimeWithoutDays(item?.ts, timezone)}
                    </td>
                    <td className="align-middle text-center font-weight-bold">
                      {item?.room_number}
                    </td>
                    <td className="align-middle text-center">{item?.floor}</td>
                    <td className="align-middle text-center">
                      {item?.room_type_name}
                    </td>
                    <td className="align-middle text-center py-0">
                      <div className="font-weight-bold">
                        {item?.bed_type_name}
                      </div>
                      <div className="text-center">{item?.bed_size}</div>
                    </td>
                    <td className="align-middle text-center py-0">
                      {item?.start_cleaning ? (
                        <>
                          <div>
                            {getServerTimeWithoutDays(
                              item?.start_cleaning,
                              timezone
                            )}
                          </div>
                          <div className="font-weight-bold">
                            {getClientTimeWithoutSecond(
                              item?.start_cleaning,
                              timezone
                            ) +
                              " " +
                              getTimezoneOffsetClient(timezone)}
                          </div>
                        </>
                      ) : (
                        "-"
                      )}
                    </td>
                    <td className="align-middle text-center">
                      {item?.start_cleaning_by_name}
                    </td>
                    <td className="align-middle text-center py-0">
                      {item?.end_cleaning ? (
                        <>
                          <div>
                            {getServerTimeWithoutDays(
                              item?.end_cleaning,
                              timezone
                            )}
                          </div>
                          <div className="font-weight-bold">
                            {getClientTimeWithoutSecond(
                              item?.end_cleaning,
                              timezone
                            ) +
                              " " +
                              getTimezoneOffsetClient(timezone)}
                          </div>
                        </>
                      ) : (
                        "-"
                      )}
                    </td>
                    <td className="align-middle text-center">
                      {item?.end_cleaning_by_name}
                    </td>
                    <td className="align-middle text-center">
                      <div
                        className={`mx-auto ${
                          filterStatusRoomCustom(item?.room_status)?.className
                        }`}
                        style={{
                          borderRadius: 3,
                          width: "3rem",
                          fontWeight: "bold",
                        }}
                      >
                        {filterStatusRoomCustom(item?.room_status)?.label}
                      </div>
                    </td>

                    <td className={"align-middle text-center "}>
                      <div
                        className={`mx-auto ${
                          filterHousekeepingStatus(item?.hk_status)?.className
                        }`}
                        style={{
                          borderRadius: 3,
                          width: "3rem",
                          fontWeight: "bold",
                        }}
                      >
                        {filterHousekeepingStatus(item?.hk_status)?.label}
                      </div>
                    </td>
                    <td className="align-middle text-center py-0">
                      <div className="text-center">
                        {getServerTimeWithoutDays(item?.last_update, timezone)}
                      </div>
                      <div className="text-center font-weight-bold">
                        {getClientTimeWithoutSecond(
                          item?.last_update,
                          timezone
                        ) +
                          " " +
                          getTimezoneOffsetClient(timezone)}
                      </div>
                    </td>
                  </tr>
                ))}
              </>
            ) : (
              <tr>
                <td colSpan={20}>
                  <EmptyData
                    loading={loading}
                    errMessage={errorMessage}
                    text="Semua kamar sudah diinspeksi"
                    sourceImg={"/img/housekeeping/room-inspection.svg"}
                    height="36vh"
                  />
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </>
  );
};

const PopupTemplate = ({
  image,
  title,
  reset,
  withCrossIcon = false,
  room_number,
  text,
  color,
}) => {
  return (
    <div style={{ width: "20rem" }}>
      <div className="d-flex flex-column px-3 pt-3 pb-0">
        {!!withCrossIcon && (
          <div className="pointer popup-close-icon" onClick={reset}>
            <Icon
              icon={iconUrl.timesIcon}
              width="20"
              height="20"
              color="#742b9e"
            />
          </div>
        )}

        <div className="d-flex flex-column align-items-center justify-content-center">
          <div className="mb-3">
            <img src={image} alt="" width={100} loading="lazy" />
          </div>
          <div className={"fw-700 text-center " + FONT_SIZE_HEADER}>
            {title}
          </div>
          <div className={"text-center " + FONT_SIZE}>
            <span className="font-weight-bold">{room_number}</span> {text}
          </div>
        </div>
      </div>
      <ButtonClose
        close={() => {
          reset();
        }}
        text="OK"
        color={color}
      />
    </div>
  );
};

const PopupAddNote = ({
  mode,
  setNote,
  note,
  room_number,
  confirm,
  close,
  loading,
}) => {
  return (
    <div className="d-flex flex-column pt-2" style={{ width: "19rem" }}>
      <div className="col">
        <div className="text-center">
          <img
            src={
              mode === "approve-confirmation"
                ? "/img/popup/approve-bed.svg"
                : "/img/popup/reject-bed.svg"
            }
            alt=""
            width={100}
            loading="lazy"
          />
        </div>
      </div>
      <div className="col">
        <div className="d-flex justify-content-center my-2">
          <div className={"text-center px-5 " + FONT_SIZE_HEADER}>
            Apakah anda yakin ingin{" "}
            <span className={FONT_SIZE_HEADER}>
              {mode === "approve-confirmation" ? "Approve " : "Reject "}
            </span>
            <span className="fw-700"> Kamar {room_number} ?</span>
          </div>
        </div>
      </div>
      {mode === "reject-confirmation" && (
        <div className="col">
          <label
            htmlFor="note-inspection"
            className={"my-0 grey fw-700 " + FONT_SIZE}
          >
            Inspection Note
          </label>
          <div
            className="py-1 mt-1"
            style={{
              borderRadius: "5px",
            }}
          >
            <div className="">
              <textarea
                name="note"
                id="note"
                rows="5"
                className={"form-control form-control-sm " + FONT_SIZE}
                style={{
                  borderColor: "lightgray",
                  resize: "none",
                  backgroundColor: "white",
                }}
                onChange={(e) => setNote(e.target.value)}
                value={note}
                placeholder="Tulis Catatan..."
              />
            </div>
          </div>
        </div>
      )}

      <div className="col">
        <div className="d-flex justify-content-between my-3">
          <div className="col-5 px-0">
            <button
              className={"btn btn-block btn-outline-purple " + FONT_SIZE}
              onClick={() => close()}
            >
              Batal
            </button>
          </div>
          <div className="col-5 px-0">
            <button
              className={`btn btn-block text-white ${FONT_SIZE} ${
                (note !== "" && !loading) ||
                (mode === "approve-confirmation" && !loading)
                  ? "btn-gradient"
                  : "btn-secondary"
              }`}
              onClick={() => confirm()}
              disabled={
                (note !== "" && !loading) ||
                (mode === "approve-confirmation" && !loading)
                  ? false
                  : true
              }
            >
              {loading && (
                <span className="spinner-border spinner-border-sm mr-1"></span>
              )}
              Simpan
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

const PopupInspection = ({
  close,
  roomInspectionSelected,
  approve,
  reject,
  setShowFullscreen,
  openFullscreen,
  loading,
}) => {
  const [imgBedroom, setImgBedroom] = useState([]);
  // const [imgBathroom, setImgBathroom] = useState([]);
  const detailView = (title, value, flex = 4) => {
    return (
      <div className={`d-flex flex-row mt-1 ${FONT_SIZE}`}>
        <div className={`col-${flex} px-0`} style={{ color: "#707070" }}>
          {title}
        </div>
        <div className="col px-0 fw-600">: {value ? value : "-"}</div>
      </div>
    );
  };
  const detailInspection = (title, value) => {
    return (
      <div className={"d-flex my-2 " + FONT_SIZE}>
        <div className="col-5 px-0" style={{ color: "#707070" }}>
          {title}
        </div>
        <div className="col px-0 fw-600">: {value ? value : "-"}</div>
      </div>
    );
  };
  const previewImg = (title, value) => {
    return (
      <div className="my-2">
        <div className={"fw-700 " + FONT_SIZE}>{title}</div>
        <div onClick={handleFullscreen}>
          <img
            src={config.img_host + value}
            alt={title}
            style={{
              width: "100%",
              height: "12rem",
              objectFit: "contain",
              borderRadius: 5,
            }}
            loading="lazy"
          />
        </div>
      </div>
    );
  };

  const handleFullscreen = (val) => {
    setShowFullscreen(val.target.src);
    openFullscreen();
  };

  const DetailCleanMobile = ({
    title,
    action_time,
    action_by,
    note,
    reasons,
    action_clean,
    type,
    bedroom = [],
  }) => {
    return (
      <div className={"d-flex flex-column " + FONT_SIZE}>
        <div className="col px-0">
          <div
            className={"fw-700 py-1 px-2 " + FONT_SIZE_HEADER}
            style={{ backgroundColor: "#DDF8ED" }}
          >
            {title}
          </div>
          <div className="col px-0">
            {detailInspection(action_clean, action_time)}
            {detailInspection("Cleaning by", action_by)}
            <div className={FONT_SIZE}>
              <label htmlFor="" className={"fw-700 purple " + FONT_SIZE}>
                {title} Note
              </label>
              <div className="">{note ? note : "-"}</div>
            </div>
            {reasons && (
              <div className={FONT_SIZE}>
                <label htmlFor="" className={"fw-700 purple " + FONT_SIZE}>
                  Alasan Housekeeping Berbeda
                </label>
                <div className="">{reasons ? reasons : "-"}</div>
              </div>
            )}
          </div>
        </div>
        <div className="col px-0 ">
          <div className="d-flex flex-column">
            {type === "start" ? (
              <>
                <div className="col px-0">
                  {previewImg(
                    "Foto kamar sebelum",
                    roomInspectionSelected?.start_room_file_path
                  )}
                </div>
                <div className="col px-0">
                  {previewImg(
                    "Foto kamar mandi sebelum",
                    roomInspectionSelected?.start_bathroom_file_path
                  )}
                </div>
              </>
            ) : (
              <>
                <div className="col px-0">
                  <div className="d-flex flex-column">
                    {bedroom?.length > 0 &&
                      bedroom.map((item, i) => (
                        <div
                          key={i}
                          className={`col px-0 ${
                            (i + 1) % 2 === 0 ? "pr-0" : "pl-0"
                          }`}
                        >
                          {previewImg(item.description, item.original)}
                        </div>
                      ))}
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    );
  };

  const DetailClean = ({
    title,
    action_time,
    action_by,
    note,
    reasons,
    action_clean,
    type,
    bedroom = [],
  }) => {
    return (
      <div className="d-flex flex-row">
        <div className="col-4 pl-0">
          <div className="f10-vw purple font-weight-bold">{title}</div>
          <div className="col px-0">
            {detailInspection(action_clean, action_time)}
            {detailInspection("Cleaning by", action_by)}
            <div className={FONT_SIZE}>
              <label htmlFor="" className={"fw-700 " + FONT_SIZE}>
                Catatan
              </label>
              <div className="">{note ? note : "-"}</div>
            </div>
            {reasons && (
              <div className={FONT_SIZE}>
                <label htmlFor="" className={"fw-700 " + FONT_SIZE}>
                  Alasan Housekeeping Berbeda
                </label>
                <div className="">{reasons ? reasons : "-"}</div>
              </div>
            )}
          </div>
        </div>
        <div className="col-8 px-2">
          <div className="d-flex flex-row">
            {type === "start" ? (
              <>
                <div className="col-6 pl-0">
                  {previewImg(
                    "Foto kamar sebelum",
                    roomInspectionSelected?.start_room_file_path
                  )}
                </div>
                <div className="col-6 pr-0">
                  {previewImg(
                    "Foto kamar mandi sebelum",
                    roomInspectionSelected?.start_bathroom_file_path
                  )}
                </div>
              </>
            ) : (
              <>
                <div className="col px-0">
                  <div className="d-flex flex-wrap justify-content-between">
                    {bedroom?.length > 0 &&
                      bedroom.map((item, i) => (
                        <div
                          key={i}
                          className={`col-6 ${
                            (i + 1) % 2 === 0 ? "pr-0" : "pl-0"
                          }`}
                        >
                          {previewImg(item.description, item.original)}
                        </div>
                      ))}
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    );
  };

  useEffect(() => {
    const getRoomGallery = () => {
      const img_rooms_galery = [
        {
          original: roomInspectionSelected?.end_room_file_path,
          thumbnail: roomInspectionSelected?.end_room_file_path,
          description: "Foto Kamar Sesudah",
        },
        {
          original: roomInspectionSelected?.hasil_making_bed,
          thumbnail: roomInspectionSelected?.hasil_making_bed,
          description: "Making Bed",
        },
        {
          original: roomInspectionSelected?.end_bathroom_file_path,
          thumbnail: roomInspectionSelected?.end_bathroom_file_path,
          description: "Foto Kamar Mandi Sesudah",
        },
      ];
      return img_rooms_galery;
    };

    setImgBedroom(getRoomGallery);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const timezone =
    localStorage?.getItem("timezone") && localStorage?.getItem("timezone");
  return (
    <>
      {IS_MOBILE ? (
        <div
          className="d-flex flex-column p-2 f12-vw"
          style={{ width: "21rem" }}
        >
          <div className="col f16-vw font-weight-bold">
            <div className="d-flex justify-content-between align-items-center">
              <div className="">
                <span className="mr-2">
                  <Icon
                    icon={iconUrl.informationIcon}
                    color="orange"
                    width={"1.5rem"}
                  />
                </span>
                <span className="dark-blue">Inspection Detail</span>
              </div>
              <div>
                <button
                  className="btn btn-sm bg-transparent"
                  onClick={() => close()}
                >
                  <Icon
                    icon={iconUrl.timesIcon}
                    width="25"
                    height="25"
                    color="#742b9e"
                  />
                </button>
              </div>
            </div>
            <hr className="separator-blue" />
          </div>
          <div
            className="scroll-popup hide-scrollbar"
            style={{ maxHeight: "25rem" }}
          >
            <div className="col px-0">
              <div className="d-flex flex-column">
                <div className="col">
                  {detailView("ID", roomInspectionSelected?.id, 5)}
                  {detailView(
                    "Tanggal",
                    getServerTimeWithoutDays(
                      roomInspectionSelected?.ts,
                      timezone
                    ),
                    5
                  )}
                  {detailView(
                    "Room No",
                    roomInspectionSelected?.room_number,
                    5
                  )}
                </div>
                <div className="col">
                  {detailView("Floor", roomInspectionSelected?.floor, 5)}
                  {detailView(
                    "Room Type",
                    roomInspectionSelected?.room_type_name,
                    5
                  )}
                  {detailView(
                    "Bed Type",
                    roomInspectionSelected?.bed_type_name
                      ? `${roomInspectionSelected?.bed_type_name}`
                      : "-",
                    5
                  )}
                  {detailView(
                    "Bed Size",
                    roomInspectionSelected?.bed_type_name
                      ? `${roomInspectionSelected?.bed_size}`
                      : "-",
                    5
                  )}

                  {detailView(
                    "Room Status",
                    <span
                      className={`px-2 ${
                        filterStatusRoomCustom(
                          roomInspectionSelected?.room_status
                        )?.className
                      }`}
                      style={{
                        borderRadius: 3,
                        width: "3rem",
                        fontWeight: "bold",
                      }}
                    >
                      {
                        filterStatusRoomCustom(
                          roomInspectionSelected?.room_status
                        )?.label
                      }
                    </span>,
                    5
                  )}
                  {detailView(
                    "Housekeeping Status",
                    <span
                      className={`px-2 ${
                        filterHousekeepingStatus(
                          roomInspectionSelected?.hk_status
                        )?.className
                      }`}
                      style={{
                        borderRadius: 3,
                        width: "3rem",
                        fontWeight: "bold",
                      }}
                    >
                      {
                        filterHousekeepingStatus(
                          roomInspectionSelected?.hk_status
                        )?.label
                      }
                    </span>,
                    5
                  )}
                  {detailView(
                    "Last Update",
                    <>
                      {roomInspectionSelected?.last_update ? (
                        <>
                          <span>
                            {getServerTimeWithoutDays(
                              roomInspectionSelected?.last_update,
                              timezone
                            )}
                          </span>
                          <span className="font-weight-bold">
                            {` ${getClientTimeWithoutSecond(
                              roomInspectionSelected?.last_update,
                              timezone
                            )} ${getTimezoneOffsetClient(timezone)}`}
                          </span>
                        </>
                      ) : (
                        "-"
                      )}
                    </>,
                    5
                  )}
                </div>
              </div>
              <hr className="separator-blue mx-3" />
            </div>
            <div className="col">
              <div className="d-flex flex-column">
                <div className="col px-0">
                  <DetailCleanMobile
                    title={"Start Cleaning"}
                    action_clean={"Action Start"}
                    type="start"
                    action_by={roomInspectionSelected?.start_cleaning_by_name}
                    note={roomInspectionSelected?.start_note}
                    action_time={
                      <>
                        {roomInspectionSelected?.start_cleaning ? (
                          <>
                            <span>
                              {getServerTimeWithoutDays(
                                roomInspectionSelected?.start_cleaning,
                                timezone
                              )}
                            </span>
                            <span className="font-weight-bold">
                              {` ${getClientTime(
                                roomInspectionSelected?.start_cleaning,
                                timezone
                              )} ${getTimezoneOffsetClient(timezone)}`}
                            </span>
                          </>
                        ) : (
                          "-"
                        )}
                      </>
                    }
                  />
                </div>
                <div>
                  <hr className="separator-blue" />
                </div>
                <div className="col px-0">
                  <DetailCleanMobile
                    title={"End Cleaning"}
                    action_clean={"Action End"}
                    type="end"
                    action_by={roomInspectionSelected?.end_cleaning_by_name}
                    reasons={roomInspectionSelected?.reasons}
                    note={roomInspectionSelected?.end_note}
                    action_time={
                      <>
                        {roomInspectionSelected?.start_cleaning ? (
                          <>
                            <span>
                              {getServerTimeWithoutDays(
                                roomInspectionSelected?.start_cleaning,
                                timezone
                              )}
                            </span>
                            <span className="font-weight-bold">
                              {` ${getClientTime(
                                roomInspectionSelected?.start_cleaning,
                                timezone
                              )} ${getTimezoneOffsetClient(timezone)}`}
                            </span>
                          </>
                        ) : (
                          "-"
                        )}
                      </>
                    }
                    bedroom={imgBedroom}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="col">
            <hr className="separator-blue" />
            {is_sub_menu_action("hk_inspection_approve") ? (
              <div className="d-flex justify-content-between my-2">
                <div className="col-5 px-0">
                  <button
                    className="btn btn-sm btn-block bg-red text-white"
                    onClick={() => reject()}
                  >
                    Reject
                  </button>
                </div>
                <div className="col-5 px-0">
                  <button
                    className="btn btn-sm btn-block bg-green text-white"
                    onClick={() => approve()}
                    disabled={loading ? true : false}
                  >
                    Approve
                  </button>
                </div>
              </div>
            ) : (
              <div className="d-flex justify-content-center my-2">
                <div className="col-3 px-0">
                  <button
                    className="btn btn-sm btn-block bg-green rounded-input text-white"
                    onClick={() => close()}
                  >
                    OK
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      ) : (
        <div
          className="d-flex flex-column p-2 f12-vw"
          style={{ width: "55rem" }}
        >
          <div className="col f14-vw font-weight-bold">
            <div className="d-flex justify-content-between align-items-center">
              <div className="">
                <span className="mr-2">
                  <Icon
                    icon={iconUrl.informationIcon}
                    color="orange"
                    width={"1.5rem"}
                  />
                </span>
                <span className="dark-blue">Inspection Detail</span>
              </div>
              <div>
                <button
                  className="btn btn-sm bg-transparent"
                  onClick={() => close()}
                >
                  <Icon
                    icon={iconUrl.timesIcon}
                    width="25"
                    height="25"
                    color="#742b9e"
                  />
                </button>
              </div>
            </div>
            <hr className="separator-blue" />
          </div>
          <div className="scroll-popup" style={{ maxHeight: "20rem" }}>
            <div className="col">
              <div className="d-flex flex-row">
                <div className="col pl-0">
                  {detailView("ID", roomInspectionSelected?.id, 5)}
                  {detailView(
                    "Tanggal",
                    getServerTimeWithoutDays(
                      roomInspectionSelected?.ts,
                      timezone
                    ),
                    5
                  )}
                  {detailView(
                    "Room No",
                    roomInspectionSelected?.room_number,
                    5
                  )}
                </div>
                <div className="col">
                  {detailView("Floor", roomInspectionSelected?.floor, 5)}
                  {detailView(
                    "Room Type",
                    roomInspectionSelected?.room_type_name,
                    5
                  )}
                  {detailView(
                    "Bed Type",
                    roomInspectionSelected?.bed_type_name
                      ? `${roomInspectionSelected?.bed_type_name}`
                      : "-",
                    5
                  )}
                  {detailView(
                    "Bed Size",
                    roomInspectionSelected?.bed_type_name
                      ? `${roomInspectionSelected?.bed_size}`
                      : "-",
                    5
                  )}
                </div>
                <div className="col pr-0">
                  {detailView(
                    "Room Status",
                    <span
                      className={`px-2 ${
                        filterStatusRoomCustom(
                          roomInspectionSelected?.room_status
                        )?.className
                      }`}
                      style={{
                        borderRadius: 3,
                        width: "3rem",
                        fontWeight: "bold",
                      }}
                    >
                      {
                        filterStatusRoomCustom(
                          roomInspectionSelected?.room_status
                        )?.label
                      }
                    </span>,
                    5
                  )}
                  {detailView(
                    "Housekeeping Status",
                    <span
                      className={`px-2 ${
                        filterHousekeepingStatus(
                          roomInspectionSelected?.hk_status
                        )?.className
                      }`}
                      style={{
                        borderRadius: 3,
                        width: "3rem",
                        fontWeight: "bold",
                      }}
                    >
                      {
                        filterHousekeepingStatus(
                          roomInspectionSelected?.hk_status
                        )?.label
                      }
                    </span>,
                    5
                  )}
                  {detailView(
                    "Last Update",
                    <>
                      {roomInspectionSelected?.last_update ? (
                        <>
                          <span>
                            {getServerTimeWithoutDays(
                              roomInspectionSelected?.last_update,
                              timezone
                            )}
                          </span>
                          <span className="font-weight-bold">
                            {` ${getClientTimeWithoutSecond(
                              roomInspectionSelected?.last_update,
                              timezone
                            )} ${getTimezoneOffsetClient(timezone)}`}
                          </span>
                        </>
                      ) : (
                        "-"
                      )}
                    </>,
                    5
                  )}
                </div>
              </div>
              <hr className="separator-blue" />
            </div>
            <div className="col">
              <div className="d-flex flex-column">
                <div className="col px-0">
                  <DetailClean
                    title={"Start Cleaning"}
                    action_clean={"Action Start"}
                    type="start"
                    action_by={roomInspectionSelected?.start_cleaning_by_name}
                    note={roomInspectionSelected?.start_note}
                    action_time={
                      <>
                        {roomInspectionSelected?.start_cleaning ? (
                          <>
                            <span>
                              {getServerTimeWithoutDays(
                                roomInspectionSelected?.start_cleaning,
                                timezone
                              )}
                            </span>
                            <span className="font-weight-bold">
                              {` ${getClientTimeWithoutSecond(
                                roomInspectionSelected?.start_cleaning,
                                timezone
                              )} ${getTimezoneOffsetClient(timezone)}`}
                            </span>
                          </>
                        ) : (
                          "-"
                        )}
                      </>
                    }
                  />
                </div>
                <div>
                  <hr className="separator-blue" />
                </div>
                <div className="col px-0">
                  <DetailClean
                    title={"End Cleaning"}
                    action_clean={"Action End"}
                    type="end"
                    action_by={roomInspectionSelected?.end_cleaning_by_name}
                    reasons={roomInspectionSelected?.reasons}
                    note={roomInspectionSelected?.end_note}
                    action_time={
                      <>
                        {roomInspectionSelected?.start_cleaning ? (
                          <>
                            <span>
                              {getServerTimeWithoutDays(
                                roomInspectionSelected?.start_cleaning,
                                timezone
                              )}
                            </span>
                            <span className="font-weight-bold">
                              {` ${getClientTimeWithoutSecond(
                                roomInspectionSelected?.start_cleaning,
                                timezone
                              )} ${getTimezoneOffsetClient(timezone)}`}
                            </span>
                          </>
                        ) : (
                          "-"
                        )}
                      </>
                    }
                    bedroom={imgBedroom}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="col">
            <hr className="separator-blue" />
            {is_sub_menu_action("hk_inspection_approve") ? (
              <div className="d-flex justify-content-between my-2">
                <div className="col-2 px-0">
                  <button
                    className="btn btn-sm btn-block bg-red text-white"
                    onClick={() => reject()}
                  >
                    Reject
                  </button>
                </div>
                <div className="col-2 px-0">
                  <button
                    className="btn btn-sm btn-block bg-green text-white"
                    onClick={() => approve()}
                    disabled={loading ? true : false}
                  >
                    Approve
                  </button>
                </div>
              </div>
            ) : (
              <div className="d-flex justify-content-center my-2">
                <div className="col-3 px-0">
                  <button
                    className="btn btn-sm btn-block bg-green rounded-input text-white"
                    onClick={() => close()}
                  >
                    OK
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};

const RoomInspection = () => {
  const {
    setpathLocation,
    openButtonSheet,
    setOpenButtonSheet,
    setShowFilter,
  } = useContext(BreadcrumbContext);
  const [roomInspections, setRoomInspections] = useState([]);
  const [roomInspectionSelected, setRoomInspectionSelected] = useState({});
  const [openModal, setOpenModal] = useState(false);
  const [mode, setMode] = useState("");
  const [loadingList, setLoadingList] = useState(false);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [errorList, setErrorList] = useState("");
  const [errorSubmit, setErrorSubmit] = useState("");
  const [headerPopup, setHeaderPopup] = useState("");
  const mounted = useRef(false);
  const [imgPopup, setimgPopup] = useState("");
  const [text, setText] = useState("");
  const [color, setColor] = useState("");
  const [roomNumber, setRoomNumber] = useState("");
  const [inspectionNote, setInspectionNote] = useState("");
  const [showFullscreen, setShowFullscreen] = useState("");
  const [openModalFullscreen, setOpenModalFullscreen] = useState(false);

  const handleRoomNumber = (val) => {
    setRoomNumber(val.target.value);
  };

  const isOpen = () => {
    setOpenModal(!openModal);
  };

  const getRoomInspections = useCallback(() => {
    setLoadingList(true);
    getData(`/api/v1/housekeeping/roominspectlist?room_number=${roomNumber}`)
      .then((resp) => {
        if (mounted.current) {
          setLoadingList(false);
          if (resp.data) {
            // console.log("resp : ", resp.data);
            setRoomInspections(resp.data);
          } else {
            resp.json().then((err) => {
              setErrorList(t(safeErrCode(err)));
            });
            setRoomInspections([]);
          }
        }
      })
      .catch((error) => {
        console.log(error);
        setErrorList(t(getErr(error)));
        setLoadingList(false);
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [roomNumber]);

  const approveSubmit = () => {
    setMode("inspection-success");
    setHeaderPopup("Approval berhasil");
    setText("berhasil di-approve");
    setimgPopup("/img/housekeeping/approve.svg");
    setColor("bg-green");
  };

  const rejectSubmit = () => {
    setMode("inspection-success");
    setHeaderPopup("Reject berhasil");
    setText("di-reject");
    setimgPopup("/img/housekeeping/reject.svg");
    setColor("bg-red");
  };

  const submitRoomAction = (action_id) => {
    const payload = {
      action_id: action_id,
      room_number: roomInspectionSelected?.room_number,
      list_id: roomInspectionSelected.id,
      inspection_note: inspectionNote,
    };

    const formData = new FormData();
    for (const key in payload) {
      formData.append(key, payload[key]);
    }
    setLoadingSubmit(true);
    addData("/api/v1/housekeeping/roominspectlist", formData)
      .then((resp) => {
        setLoadingSubmit(false);
        if (resp.status === "OK") {
          if (action_id === 1) {
            approveSubmit();
          } else if (action_id === 2) {
            rejectSubmit();
          }
        } else {
          resp.json().then((err) => {
            setErrorSubmit(t(safeErrCode(err)));
          });
          setMode("error");
          if (action_id === 1) {
            setHeaderPopup("Approve Gagal");
          } else {
            setHeaderPopup("Reject Gagal");
          }
          setimgPopup("/img/error-icon/save-bedtype-failed.svg");
        }
      })
      .catch((error) => {
        console.error(error);
        setLoadingSubmit(false);
        setErrorSubmit(t(getErr(error)));
        setMode("error");
        if (action_id === 1) {
          setHeaderPopup("Approve Gagal");
        } else {
          setHeaderPopup("Reject Gagal");
        }
        setimgPopup("/img/error-icon/save-bedtype-failed.svg");
      });
  };

  const handleEnter = (e) => {
    if (e.key === "Enter") {
      getRoomInspections();
    }
  };
  const reset = () => {
    setRoomInspections([]);
    setRoomInspectionSelected({});
    setOpenModal(false);
    setMode("");
    setLoadingList(false);
    setLoadingSubmit(false);
    setErrorList("");
    setErrorSubmit("");
    setHeaderPopup("");
    setText("");
    setColor("");
    getRoomInspections();
    setInspectionNote("");
  };

  const resetFilter = () => {
    setRoomNumber("");
    getRoomInspections();
  };

  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);

  useEffect(() => {
    if (roomNumber === "") {
      getRoomInspections();
    }
  }, [getRoomInspections, roomNumber]);

  useEffect(() => {
    setpathLocation(history.location.pathname);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history.location.pathname]);

  useEffect(() => {
    if (openModal) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
    return () => {
      document.body.style.overflow = "unset";
    };
  }, [openModal]);

  useEffect(() => {
    setShowFilter(true);
    return () => {
      setShowFilter(false);
    };
  }, [setShowFilter]);

  return (
    <>
      <PopupModal modalIsOpen={openModal}>
        {mode === "inspection-form" ? (
          <>
            <PopupInspection
              close={isOpen}
              roomInspectionSelected={roomInspectionSelected}
              approve={() => setMode("approve-confirmation")}
              reject={() => setMode("reject-confirmation")}
              loadingSubmit={loadingSubmit}
              setShowFullscreen={setShowFullscreen}
              openFullscreen={() => setOpenModalFullscreen(true)}
            />
          </>
        ) : mode === "approve-confirmation" ? (
          <PopupAddNote
            setNote={setInspectionNote}
            note={inspectionNote}
            mode={mode}
            confirm={() => submitRoomAction(1)}
            room_number={roomInspectionSelected?.room_number}
            close={() => {
              isOpen();
              reset();
            }}
            loading={loadingSubmit}
          />
        ) : mode === "reject-confirmation" ? (
          <PopupAddNote
            setNote={setInspectionNote}
            note={inspectionNote}
            mode={mode}
            confirm={() => submitRoomAction(2)}
            room_number={roomInspectionSelected?.room_number}
            close={() => {
              isOpen();
              reset();
            }}
            loading={loadingSubmit}
          />
        ) : mode === "inspection-success" ? (
          <>
            <PopupTemplate
              image={imgPopup}
              title={headerPopup}
              room_number={`Kamar ${roomInspectionSelected?.room_number}`}
              reset={reset}
              text={text}
              color={color}
            />
          </>
        ) : (
          <>
            <NotifMessage
              closeModal={openModal}
              img={imgPopup}
              messageHeader={headerPopup}
              color="red"
              messageBody={errorSubmit}
            />
            <ButtonClose close={reset} text="OK" />
          </>
        )}
      </PopupModal>
      <PopupModal modalIsOpen={openModalFullscreen}>
        <div
          className="d-flex flex-column p-4"
          style={{ position: "relative" }}
        >
          <div style={{ position: "absolute", top: 0, right: 0, zIndex: 9999 }}>
            <button
              className="btn btn-sm bg-transparent"
              onClick={() => setOpenModalFullscreen(false)}
            >
              <Icon
                icon={iconUrl.timesIcon}
                width="20"
                height="20"
                color="#742b9e"
              />
            </button>
          </div>

          <img
            src={showFullscreen}
            alt=""
            style={{
              width: "100%",
              height: IS_MOBILE ? "15rem" : "25rem",
              objectFit: "contain",
            }}
            loading="lazy"
          />
        </div>
      </PopupModal>
      {IS_MOBILE ? (
        <div className="d-flex flex-column p-1 mb-5">
          <div className="col">
            {roomInspections?.length > 0 ? (
              roomInspections.map((item) => {
                const timezone = localStorage.getItem("timezone");
                return (
                  <MobileCardList
                    title={`Kamar ${item.room_number}`}
                    key={item?.id}
                    action_button={
                      <button
                        className="btn btn-sm btn-gradient text-white f12-vw fw-700 btn-block"
                        onClick={() => {
                          setRoomInspectionSelected(item);
                          isOpen();
                          setMode("inspection-form");
                        }}
                      >
                        <span className="mr-1">
                          <Icon icon={iconUrl.slideSearch24Filled} width={18} />
                        </span>
                        <span>Inspection</span>
                      </button>
                    }
                  >
                    <div className="card-hms-list">
                      <div className="col-5 px-0 grey-text">ID</div>
                      <div className="col pr-0 f12-vw black-value">
                        {item?.id}
                      </div>
                    </div>
                    <div className="card-hms-list">
                      <div className="col-5 px-0 grey-text">Tanggal</div>
                      <div className="col pr-0 f12-vw black-value">
                        {getServerTimeWithoutDays(item?.ts, timezone)}
                      </div>
                    </div>
                    <div className="card-hms-list">
                      <div className="col-5 px-0 grey-text">Lantai</div>
                      <div className="col pr-0 f12-vw black-value">
                        {item?.floor}
                      </div>
                    </div>
                    <div className="card-hms-list">
                      <div className="col-5 px-0 grey-text">Room Type</div>
                      <div className="col pr-0 f12-vw black-value">
                        {item?.room_type_name}
                      </div>
                    </div>
                    <div className="card-hms-list">
                      <div className="col-5 px-0 grey-text">Bed Type</div>
                      <div className="col pr-0 f12-vw black-value">
                        {item?.bed_type_name}
                      </div>
                    </div>
                    <div className="card-hms-list">
                      <div className="col-5 px-0 grey-text">Bed Size</div>
                      <div className="col pr-0 f12-vw black-value">
                        {item?.bed_size}
                      </div>
                    </div>
                    <div className="card-hms-list">
                      <div className="col-5 px-0 grey-text">Start Cleaning</div>
                      <div className="col pr-0 f12-vw black-value">
                        {item?.start_cleaning ? (
                          <>
                            <span>
                              {getServerTimeWithoutDays(
                                item?.start_cleaning,
                                timezone
                              )}
                            </span>
                            <span className="fw-700">
                              {`, ${getClientTimeWithoutSecond(
                                item?.start_cleaning,
                                timezone
                              )}
                                ${getTimezoneOffsetClient(timezone)}`}
                            </span>
                          </>
                        ) : (
                          "-"
                        )}
                      </div>
                    </div>
                    <div className="card-hms-list">
                      <div className="col-5 px-0 grey-text">
                        Start Cleaning By
                      </div>
                      <div className="col pr-0 f12-vw black-value">
                        {item?.start_cleaning_by_name}
                      </div>
                    </div>

                    <div className="card-hms-list">
                      <div className="col-5 px-0 grey-text">End Cleaning</div>
                      <div className="col pr-0 f12-vw black-value">
                        {item?.end_cleaning ? (
                          <>
                            <span>
                              {getServerTimeWithoutDays(
                                item?.end_cleaning,
                                timezone
                              )}
                            </span>

                            <span className="fw-700">
                              {`, ${getClientTimeWithoutSecond(
                                item?.end_cleaning,
                                timezone
                              )}
                                ${getTimezoneOffsetClient(timezone)}`}
                            </span>
                          </>
                        ) : (
                          "-"
                        )}
                      </div>
                    </div>
                    <div className="card-hms-list">
                      <div className="col-5 px-0 grey-text">
                        End Cleaning By
                      </div>
                      <div className="col pr-0 f12-vw black-value">
                        {item?.end_cleaning_by_name}
                      </div>
                    </div>

                    <div className="card-hms-list">
                      <div className="col-5 px-0 grey-text">Room Status</div>
                      <div className="col pr-0 f12-vw black-value">
                        <div
                          className={`text-center ${
                            filterStatusRoomCustom(item?.room_status)?.className
                          }`}
                          style={{
                            borderRadius: 3,
                            width: "3rem",
                            fontWeight: "bold",
                          }}
                        >
                          {filterStatusRoomCustom(item?.room_status)?.label}
                        </div>
                      </div>
                    </div>
                    <div className="card-hms-list">
                      <div className="col-5 px-0 grey-text">
                        Housekeeping Status
                      </div>
                      <div className="col pr-0 f12-vw black-value">
                        <div
                          className={`text-center ${
                            filterHousekeepingStatus(item?.hk_status)?.className
                          }`}
                          style={{
                            borderRadius: 3,
                            width: "3rem",
                            fontWeight: "bold",
                          }}
                        >
                          {filterHousekeepingStatus(item?.hk_status)?.label}
                        </div>
                      </div>
                    </div>
                    <div className="card-hms-list">
                      <div className="col-5 px-0 grey-text">Last Update</div>
                      <div className="col pr-0 f12-vw black-value">
                        {item?.last_update ? (
                          <>
                            <span>
                              {getServerTimeWithoutDays(
                                item?.last_update,
                                timezone
                              )}
                            </span>
                            <span className="fw-700">
                              {`, ${getClientTimeWithoutSecond(
                                item?.last_update,
                                timezone
                              )}
                                ${getTimezoneOffsetClient(timezone)}`}
                            </span>
                          </>
                        ) : (
                          "-"
                        )}
                      </div>
                    </div>
                  </MobileCardList>
                );
              })
            ) : (
              <div style={{ position: "relative" }}>
                <MobileEmptyData
                  errMessage={errorList}
                  loading={loadingList}
                  height="40vh"
                  text="Semua kamar sudah diinspeksi"
                  sourceImg={"/img/housekeeping/room-inspection.svg"}
                />
              </div>
            )}
          </div>
        </div>
      ) : (
        <div className="d-flex flex-column px-3 py-1">
          <div className="col">
            <div className="d-flex flex-row justify-content-between">
              <div className="col-md-5 px-0">
                <div className="d-flex flex-row">
                  <div className="col-4 px-0 mr-2">
                    <label htmlFor="" className="f8-vw m-0 p-0 fw-600">
                      Nomor Kamar
                    </label>
                    {searchInput(
                      "Cari Nomor Kamar",
                      roomNumber,
                      (e) => handleRoomNumber(e),
                      handleEnter
                    )}
                  </div>
                </div>
              </div>
              <div className="col-md-2 pr-0 pl-5 mt-auto">
                <div className="d-flex flex-column">
                  <button
                    className="btn btn-sm search-new rounded-input btn-block mt-auto f8-vw fw-500 text-white"
                    onClick={() => getRoomInspections()}
                    style={{
                      paddingTop: 7,
                      paddingBottom: 7,
                      backgroundColor: "#742B9E",
                    }}
                  >
                    Search
                  </button>
                </div>
              </div>
            </div>
            <div className="d-flex flex-column my-2">
              <TableRoomInspection
                list={roomInspections}
                loading={loadingList}
                errorMessage={errorList}
                isOpen={isOpen}
                setMode={setMode}
                setRoomInspectionSelected={setRoomInspectionSelected}
                height="55vh"
              />
            </div>
          </div>
        </div>
      )}

      {IS_MOBILE && (
        <div>
          <BottomSheet
            open={openButtonSheet}
            onDismiss={() => setOpenButtonSheet(false)}
            snapPoints={({ minHeight }) => minHeight}
            header={
              <div className="d-flex justify-content-between">
                <span className="purple f16-vw fw-600 px-2">Filter</span>
                <span
                  className="px-2"
                  onClick={() => setOpenButtonSheet(false)}
                >
                  <Icon icon={iconUrl.closeBold} color={"grey"} width={20} />
                </span>
              </div>
            }
            footer={
              <div className="d-flex justify-content-between">
                <div className="col-6 pl-2">
                  <button
                    className="btn btn-block btn-outline-purple f12-vw"
                    onClick={() => {
                      resetFilter();
                      setOpenButtonSheet(false);
                    }}
                  >
                    Reset
                  </button>
                </div>
                <div className="col-6 pr-2">
                  <button
                    className="btn btn-block btn-gradient f12-vw text-white"
                    onClick={() => {
                      getRoomInspections();
                      setOpenButtonSheet(false);
                    }}
                  >
                    Search
                  </button>
                </div>
              </div>
            }
          >
            <div className="mt-3" style={{ height: "17rem" }}>
              <div className="px-4 mt-3">
                <label htmlFor="" className="f12-vw m-0 p-0 fw-600">
                  Room Number
                </label>
                {mobileSearchInput("Cari Nomor Kamar", roomNumber, (e) =>
                  handleRoomNumber(e)
                )}
              </div>
            </div>
          </BottomSheet>
        </div>
      )}
    </>
  );
};

export default RoomInspection;
