import { createContext, useCallback, useEffect, useState } from "react";
import { useLocation } from "react-router";
import { isLogout } from "../utils";
import jwt from "jsonwebtoken";
import { getData, getErr, safeErrCode } from "../utils/api-request";

import { StickyButton } from "./StickyButton";
import { IS_MOBILE } from "./Responsive";
import { LoadingOverlay } from "./Loading";
import { t } from "./ErrorMessage";

export const BreadcrumbContext = createContext();

const BreadcrumbProvider = ({ children }) => {
  const location = useLocation();
  const [pathLocation, setpathLocation] = useState(location.pathname);
  const [userProfile, setUserProfile] = useState({});
  const [roomType, setRoomType] = useState([]);
  const [shiftInfo, setshiftInfo] = useState({});
  const [errorShift, setErrorShift] = useState("");
  const [isOpeningShift, setIsOpeningShift] = useState(true);
  const [openButtonSheet, setOpenButtonSheet] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
  const [hotelDetail, sethotelDetail] = useState({});
  const timezone =
    localStorage?.getItem("timezone") && localStorage.getItem("timezone");
  const [loadingOverlay, setLoadingOverlay] = useState(false);
  const [roomPrice, setRoomPrice] = useState([]);

  let token = sessionStorage?.getItem("token");

  const getPaymentMethod = useCallback(() => {
    if (token) {
      getData("/api/v1/listpaymentmethod")
        .then((resp) => {
          if (resp.data) {
            let d = resp.data.map((item) => {
              return {
                value: item.payment_type,
                label: item.payment_name,
              };
            });
            localStorage.setItem("payment_method", JSON.stringify(d));
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [token]);

  const getRoomPrice = useCallback(() => {
    if (token) {
      const url = "/api/v1/get_list_price_platform_today";
      getData(url)
        .then((resp) => {
          if (resp?.charge) {
            setRoomPrice(resp.charge);
          } else {
            setRoomPrice([]);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [token]);

  const getPaymentType = useCallback(() => {
    if (token) {
      getData(`/api/v1/paymentype?payment_type=2`)
        .then((resp) => {
          if (resp.data) {
            let d = resp.data.map((item) => {
              return {
                value: item.bank_id,
                label: `${item.bank_name} ( ${item.bank_accno} )`,
                bank_name: item.bank_name,
                bank_accno: item.bank_accno,
                bank_accholder: item.bank_accholder,
              };
            });
            localStorage.setItem("bank_list", JSON.stringify(d));
          } else {
            localStorage.setItem("bank_list", []);
            console.log(resp.data);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [token]);

  const getUserProfile = useCallback(() => {
    if (token) {
      getData("/api/v1/userprofile")
        .then((resp) => {
          if (resp.data) {
            setUserProfile(resp.data);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [token]);

  const getShiftName = useCallback(() => {
    if (!isOpeningShift && token) {
      getData("/api/v1/shift/current")
        .then((resp) => {
          if (resp) {
            let shift_info = {
              end_hour: resp?.shift?.end_hour,
              shift_id: resp?.shift?.shift_id,
              shift_name: resp?.shift?.shift_name,
              start_hour: resp?.shift?.start_hour,
              full_name: resp?.opening_shift?.full_name,
              open_shift_start: resp?.opening_shift?.start_hour,
              open_shift_end: resp?.opening_shift?.end_hour,
              open_shift_name: resp?.opening_shift?.shift_name,
            };
            setshiftInfo(shift_info);
            localStorage.setItem("shift_info", JSON.stringify(shift_info));
            setErrorShift("");
          } else {
            resp.json().then((err) => {
              setErrorShift(t(safeErrCode(err)));
            });
          }
        })
        .catch((error) => {
          let err = getErr(error);
          setErrorShift(t(err));
        });
    }
  }, [isOpeningShift, token]);

  useEffect(() => {
    if (localStorage?.getItem("info_hotel")) {
      const hotelInfo =
        localStorage?.getItem("info_hotel") &&
        JSON.parse(localStorage.getItem("info_hotel"));
      sethotelDetail(hotelInfo);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localStorage.getItem("info_hotel")]);

  const getRoomType = useCallback(() => {
    if (token) {
      getData("/api/v1/roomtypelist")
        .then((resp) => {
          if (resp.data) {
            let room_types =
              resp.data &&
              resp.data.map((item) => {
                return {
                  value: item.room_type_id,
                  label: item.room_type_name,
                };
              });
            localStorage.setItem("room_type", JSON.stringify(room_types));
            setRoomType(room_types);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [token]);

  useEffect(() => {
    if (token) {
      getShiftName();
    }
  }, [getShiftName, token]);

  useEffect(() => {
    getPaymentMethod();
  }, [getPaymentMethod]);

  useEffect(() => {
    getPaymentType();
  }, [getPaymentType]);

  useEffect(() => {
    getRoomType();
  }, [getRoomType]);

  useEffect(() => {
    getRoomPrice();
  }, [getRoomPrice]);

  useEffect(() => {
    if (sessionStorage?.getItem("token")) {
      getUserProfile();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sessionStorage.getItem("token")]);

  const validateToken = useCallback(() => {
    var isValid = false;
    var decoded = jwt.decode(token, {
      complete: true,
    });

    if (decoded) {
      var dateNow = new Date();
      if (decoded.payload.exp * 1000 > dateNow.getTime()) {
        isValid = true;
      }
    }
    return isValid;
  }, [token]);

  useEffect(() => {
    if (localStorage.getItem("shift_open")) {
      let d = JSON.parse(localStorage?.getItem("shift_open"));
      setIsOpeningShift(d);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localStorage.getItem("shift_open")]);

  useEffect(() => {
    if (!validateToken()) {
      isLogout();
    }
  }, [validateToken]);

  return (
    <BreadcrumbContext.Provider
      value={{
        pathLocation,
        setpathLocation,
        userProfile,
        errorShift,
        shiftInfo,
        getShiftName,
        isOpeningShift,
        setshiftInfo,
        roomType,
        hotelDetail,
        timezone,
        token,
        openButtonSheet,
        setOpenButtonSheet,
        setShowFilter,
        loadingOverlay,
        setLoadingOverlay,
        roomPrice,
      }}
    >
      {loadingOverlay && <LoadingOverlay />}
      {children}
      {IS_MOBILE && showFilter && (
        <>
          <div className="mt-5" />
          <StickyButton
            openButtonSheet={openButtonSheet}
            setOpenButtonSheet={setOpenButtonSheet}
          />
        </>
      )}
    </BreadcrumbContext.Provider>
  );
};

export default BreadcrumbProvider;
