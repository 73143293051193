import { Icon } from "@iconify/react";
import React, { useContext, useEffect, useRef } from "react";
import ReactToPrint, { useReactToPrint } from "react-to-print";
import {
  getDayDuration,
  getServerTime,
  getServerTimeWithoutDays,
  getTimezoneOffsetClient,
  priceSplit,
} from "../utils";
import { getClientTimeWithoutSecond } from "../utils/index";
import { BreadcrumbContext } from "../common/BreadcrumbContext";
import { genBookingId, genPaymentID } from "./common/printer-helper";
import { iconUrl } from "../config";

export const PrintConfirm = ({ data, launch_print, dateTransfer }) => {
  const { userProfile } = useContext(BreadcrumbContext);
  let componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  const date_now = dateTransfer;
  const timezone = localStorage.getItem("timezone");
  const hotel_info = JSON.parse(localStorage.getItem("info_hotel"));

  const createLineDash = () => {
    return (
      <p className="p-print txt-print-h2">
        {"- - - - - - - - - - - - - - - - - - - - - - - - - -"}
      </p>
    );
  };

  const createLineSolid = () => {
    return (
      <div className="p-print txt-print-h2">
        <hr
          style={{
            width: "100%",
            color: "#000",
            border: "0.8px solid #000",
            backgroundColor: "#000",
          }}
        />
      </div>
    );
  };

  const genContent1 = (label, value) => {
    return (
      <tr className="rmp txt-print-section">
        <td width={120}>{label}</td>
        <td>: {value}</td>
      </tr>
    );
  };

  const genContent2 = (label, value) => {
    return (
      <tr className="txt-print-section">
        <td width={100}>{label}</td>
        <td width={90} style={{ textAlign: "center" }}>
          {"IDR"}
        </td>
        <td className={"txt-price"} width={60}>
          {priceSplit(value)}
        </td>
      </tr>
    );
  };

  useEffect(() => {
    if (launch_print) {
      handlePrint();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [launch_print]);

  return (
    <div>
      <ReactToPrint
        content={() => componentRef.current}
        pageStyle={{ margin: 0, padding: 0 }}
      />
      <div ref={componentRef} className="receipt-print">
        <div
          className="d-flex justify-content-center"
          style={{ padding: "4px 0" }}
        >
          <img
            src={"/img/print-logo/logo.jpg"}
            alt={"logo"}
            className="img-print"
          />
        </div>
        <p className="p-print txt-print-h1">SLIP Pembayaran</p>
        {genPaymentID(data?.payment_id)}
        {createLineDash()}
        <p className="p-print txt-print-h3">{`${getServerTime(
          date_now,
          timezone
        )} - ${getClientTimeWithoutSecond(
          date_now,
          timezone
        )} ${getTimezoneOffsetClient(timezone)}`}</p>
        <p className="p-print txt-print-h3">{hotel_info?.hotel_name}</p>
        {createLineDash()}
        <table className="table-print">
          <tbody>{genContent1("Kasir", userProfile?.nama)}</tbody>
        </table>
        {genBookingId("Booking ID", data?.book_id)}
        {createLineDash()}
        <p className="p-print txt-print-section-h2">Informasi Booking</p>
        <table className="table-print">
          <tbody>
            {genContent1(
              "Check In",
              getServerTimeWithoutDays(data?.checkin_date, timezone)
            )}
            {genContent1(
              "Check Out",
              getServerTimeWithoutDays(data?.checkout_date, timezone)
            )}
            {genContent1(
              "Durasi",
              getDayDuration(data?.checkin_date, data?.checkout_date) + " Malam"
            )}
            {data?.process_type !== 12 &&
              genContent1("Nomor Kamar", data?.room_number)}
          </tbody>
        </table>
        {createLineDash()}
        <table className="table-print">
          <tbody>
            {genContent1("Metode Pembayaran", "Transfer Bank")}
            {genContent1(
              "Nama Bank",
              `${data?.to_bank_name} (${data?.bank_accno})`
            )}
          </tbody>
        </table>
        <p className="p-print txt-print-section-h2">Informasi Pembayaran</p>
        {data?.voucher_no !== ""
          ? genBookingId("Kode Voucher", data?.voucher_no)
          : null}
        <table className="table-print">
          <tbody>
            {data?.process_type !== 12
              ? genContent2(
                  "Harga Kamar",
                  data?.voucher_type === 1 || data?.voucher_type === 0
                    ? data?.room_charge
                    : data?.real_room_charge
                )
              : genContent2("Total Charge", data?.total_charge)}
            {data?.process_type !== 12 &&
              genContent2("Early Checkin", data?.early_checkin)}
            {data?.diskon > 0 && (
              <tr className="txt-print-section">
                <td width={100}>Diskon</td>
                <td width={90} style={{ textAlign: "center" }}>
                  {"IDR"}
                </td>
                <td className={"txt-price"} width={60}>
                  -{priceSplit(data?.diskon)}
                </td>
              </tr>
            )}
          </tbody>
        </table>
        {createLineSolid()}
        <table className="table-print">
          <tbody>
            {genContent2("Total Tagihan", data?.total_charge)}
            {data?.process_type !== 12 &&
              genContent2("Deposit (Cash)", data?.deposit)}
          </tbody>
        </table>
        {createLineDash()}
        <p className="p-print txt-print-footer">
          Terima kasih atas kepercayaannya
        </p>
        <p className="p-print txt-print-footer">Memilih ruangnyaman.id</p>
        <p className="p-print txt-print-tagline">#BeneranNyaman</p>
        {createLineDash()}
        <table className="table-print-contact">
          <tbody>
            <tr className="rmp txt-print-contact">
              <td>
                <span>
                  <Icon
                    icon={iconUrl.whatsappIcon}
                    fontSize={"8pt"}
                    style={{
                      top: "1px",
                      position: "relative",
                    }}
                  />{" "}
                  0818 22 88 28
                </span>

                <span style={{ marginLeft: "10px" }}>
                  <Icon
                    icon={iconUrl.envelopeIcon}
                    fontSize={"8pt"}
                    style={{ top: "1px", position: "relative" }}
                  />{" "}
                  info@ruangnyaman.id
                </span>
              </td>
            </tr>
          </tbody>
        </table>
        <p className="p-print txt-print-h3">ruangnyaman.id</p>
      </div>
    </div>
  );
};
