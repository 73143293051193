import { Icon } from "@iconify/react";
import React from "react";
import DatePicker from "react-datepicker";
import { iconUrl } from "../config";
import { addDays } from "../utils";
import { IS_MOBILE } from "./Responsive";

export default function DateRange(props) {
  return (
    <div className="date-border">
      <div className="date-view">
        <Icon
          icon={iconUrl.calendarIcon}
          color="#ef9403"
          width={18}
          className="mx-1"
        />
        <div className="px-0">
          <DatePicker
            selected={props.start}
            onChange={props.handleStart}
            minDate={props.minDate ? null : new Date()}
            selectsStart
            startDate={props.start}
            endDate={props.end}
            className={"date-range"}
            dateFormat="dd MMM yyyy"
          />
        </div>
        <div className="px-0">
          <Icon icon={iconUrl.arrowRightAlt2} color="grey" />
        </div>
        <div className="px-0">
          <DatePicker
            selected={props.end}
            onChange={props.handleEnd}
            selectsEnd
            startDate={props.start}
            endDate={props.end}
            minDate={props.start}
            className={"date-range"}
            dateFormat="dd MMM yyyy"
          />
        </div>
      </div>
    </div>
  );
}

export const DateRangeFilter = ({
  start,
  end,
  handleStart,
  handleEnd,
  minDate,
  isClearable = false,
  maxDate,
}) => {
  return (
    <div
      className=""
      style={{
        border: "1px solid #ced4da",
        borderRadius: 5,
        padding: "4px 0",
      }}
    >
      <div className="d-flex px-1 f8-vw align-items-center justify-content-around">
        <Icon icon={iconUrl.calendarIcon} color="#ef9403" width={15} />
        <div className="">
          <DatePicker
            selected={start}
            selectsStart
            onChange={handleStart}
            startDate={start}
            endDate={end}
            className={
              IS_MOBILE
                ? "date-checkin-new f12-vw border-0 text-center form-input form-control"
                : "date-checkin-new f7-vw"
            }
            dateFormat="dd MMM yyyy"
            placeholderText="Tanggal Start"
            // open={openDate}
            maxDate={maxDate && new Date()}
          />
        </div>
        <div className="px-0">
          <Icon icon={iconUrl.arrowRightAlt2} color="grey" />
        </div>
        <div className="">
          <DatePicker
            selected={end}
            selectsEnd
            onChange={handleEnd}
            startDate={start}
            endDate={end}
            minDate={minDate && start}
            className={
              IS_MOBILE
                ? "date-checkin-new f12-vw border-0 text-center form-input form-control"
                : "date-checkin-new f7-vw"
            }
            dateFormat="dd MMM yyyy"
            placeholderText="Tanggal End"
            maxDate={maxDate && new Date()}
          />
        </div>
      </div>
    </div>
  );
};
export const DateRangeFilterNew = ({
  start,
  end,
  handleStart,
  handleEnd,
  minDate,
  maxDate,
  fontSize = "f8-vw",
  isClear = false,
  handleClear,
}) => {
  const handleCalendarOpen = () => {
    document.addEventListener(
      "touchstart",
      (event) => {
        event.stopPropagation();
      },
      true
    );
  };
  return (
    <div
      className="form-control form-control-sm d-flex align-items-center justify-content-around"
      style={{
        border: "1px solid #ced4da",
        borderRadius: 5,
        padding: "4px 0",
        height: "2.08rem",
      }}
    >
      <Icon
        icon={iconUrl.calendarIcon}
        color="#ef9403"
        width={IS_MOBILE ? 25 : 18}
        className={IS_MOBILE ? "" : "mx-2"}
      />
      <div className={`px-0 ${IS_MOBILE ? "w-25" : ""}`}>
        <DatePicker
          selected={start}
          selectsStart
          onChange={(e) => handleStart(e)}
          startDate={start}
          endDate={end}
          className={"date-checkin-new fw-600 text-left " + fontSize}
          dateFormat="dd MMM yyyy"
          placeholderText="Tanggal Start"
          maxDate={maxDate && new Date()}
          withPortal={IS_MOBILE ? true : false}
          onCalendarOpen={() => handleCalendarOpen()}
          onChangeRaw={(e) => {
            e.preventDefault();
          }}
          onKeyDown={(e) => {
            e.preventDefault();
          }}
          onFocus={(e) => e.target.blur()}
          preventOpenOnFocus={IS_MOBILE ? true : false}
        />
      </div>
      <div className={IS_MOBILE ? "" : "mr-2"}>
        <Icon icon={iconUrl.arrowRightAlt2} color="grey" />
      </div>
      <div className={`px-0 ${IS_MOBILE ? "w-25" : ""}`}>
        <DatePicker
          selected={end}
          selectsEnd
          onChange={(e) => handleEnd(e)}
          startDate={start}
          endDate={end}
          minDate={minDate && addDays(start, 1)}
          className={"date-checkin-new fw-600 text-left " + fontSize}
          dateFormat="dd MMM yyyy"
          placeholderText="Tanggal End"
          maxDate={maxDate && new Date()}
          withPortal={IS_MOBILE ? true : false}
          onCalendarOpen={() => handleCalendarOpen()}
          onChangeRaw={(e) => {
            e.preventDefault();
          }}
          onKeyDown={(e) => {
            e.preventDefault();
          }}
          onFocus={(e) => e.target.blur()}
          preventOpenOnFocus={IS_MOBILE ? true : false}
        />
      </div>
      {isClear && start !== null && end !== null && (
        <div
          className="mx-1"
          style={{ cursor: "pointer" }}
          onClick={() => handleClear()}
        >
          <Icon icon={iconUrl.timesIcon} width={IS_MOBILE ? 25 : 18} />
        </div>
      )}
    </div>
  );
};
export const DateRangeFilterMobile = ({
  start,
  end,
  handleStart,
  handleEnd,
  minDate,
  maxDate,
  fontSize = "f8-vw",
}) => {
  const handleCalendarOpen = () => {
    document.addEventListener(
      "touchstart",
      (event) => {
        event.stopPropagation();
      },
      true
    );
  };
  return (
    <div
      className="form-control form-control-sm d-flex align-items-center justify-content-center"
      style={{
        border: "1px solid #ced4da",
        borderRadius: 5,
        padding: "4px 0",
        height: "2.08rem",
      }}
    >
      <Icon
        icon={iconUrl.calendarIcon}
        color="#ef9403"
        width={IS_MOBILE ? 25 : 18}
        className={"mx-2"}
      />
      <div className={`px-0 mx-3 ${IS_MOBILE ? "w-25" : ""}`}>
        <DatePicker
          selected={start}
          selectsStart
          onChange={(e) => handleStart(e)}
          startDate={start}
          endDate={end}
          className={"date-checkin-new fw-600 text-left " + fontSize}
          dateFormat="dd MMM yyyy"
          placeholderText="Tanggal Start"
          maxDate={maxDate && new Date()}
          withPortal={IS_MOBILE ? true : false}
          onCalendarOpen={() => handleCalendarOpen()}
          onChangeRaw={(e) => {
            e.preventDefault();
          }}
          onKeyDown={(e) => {
            e.preventDefault();
          }}
          onFocus={(e) => e.target.blur()}
          preventOpenOnFocus={IS_MOBILE ? true : false}
        />
      </div>
      <div className={"mx-2"}>
        <Icon icon={iconUrl.arrowRightAlt2} color="purple" />
      </div>
      <div className={`px-0 mx-3 ${IS_MOBILE ? "w-25" : ""}`}>
        <DatePicker
          selected={end}
          selectsEnd
          onChange={(e) => handleEnd(e)}
          startDate={start}
          endDate={end}
          minDate={minDate && addDays(start, 1)}
          className={"date-checkin-new fw-600 text-left " + fontSize}
          dateFormat="dd MMM yyyy"
          placeholderText="Tanggal End"
          maxDate={maxDate && new Date()}
          withPortal={IS_MOBILE ? true : false}
          onCalendarOpen={() => handleCalendarOpen()}
          onChangeRaw={(e) => {
            e.preventDefault();
          }}
          onKeyDown={(e) => {
            e.preventDefault();
          }}
          onFocus={(e) => e.target.blur()}
          preventOpenOnFocus={IS_MOBILE ? true : false}
        />
      </div>
    </div>
  );
};
