import React from "react";
import { dateFormat, getTimezoneOffsetClient, priceSplit } from "../utils";
import { IS_MOBILE } from "./Responsive";

const popupHeader = (url) => {
  return (
    <div className="popup-header">
      <div className="px-2 pt-4">
        <div className="d-flex justify-content-center">
          <img src={url} alt="" width="100px" />
        </div>
      </div>
    </div>
  );
};

const popupBody = (messageHeader, messageBody, color) => {
  return (
    <div className="notif-body mx-4 my-2">
      <div
        className={`text-center ${
          IS_MOBILE ? "f16-vw" : "f12-vw"
        } font-weight-bold ${color}`}
      >
        {messageHeader}
      </div>
      {messageBody && (
        <div className={`text-center my-2 ${IS_MOBILE ? "f12-vw" : "f8-vw"}`}>
          {messageBody}
        </div>
      )}
    </div>
  );
};

const popupSuccessBody = (messageHeader, color, description) => {
  return (
    <div className="notif-body mx-4 my-3">
      <div className={"text-center f16-vw mt-2 " + color}>{messageHeader}</div>
      <div className="my-1">
        {description &&
          description?.map((item, i) => {
            return (
              <div className="d-flex flex-row" key={i}>
                <div className="col-5 pl-1 pr-0">
                  <div className="d-flex justify-content-between align-items-center">
                    <span className="f8-vw " style={{ color: "grey" }}>
                      {item.label}
                    </span>
                    <span>:</span>
                  </div>
                </div>
                <div className="col pl-1 pr-0">
                  <span
                    className="f8-vw text-black"
                    style={{ fontWeight: "bold" }}
                  >
                    {item.value}
                  </span>
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default function NotifMessage({
  img,
  messageHeader,
  messageBody,
  color,
  width = "20rem",
}) {
  return (
    <div style={{ width: width }}>
      {popupHeader(img)}
      {popupBody(messageHeader, messageBody, color)}
    </div>
  );
}

export const SuccessNotif = (props) => {
  return <>{popupSuccessBody(props.messageHeader, props.color, props.list)}</>;
};

export const ButtonClose = (props) => {
  return (
    <div className="d-flex m-3 justify-content-center">
      <div className="col-6">
        <button
          className={`btn btn-block text-white ${
            IS_MOBILE ? "f12-vw" : "f8-vw"
          } ${props.color ? props.color : "btn-gradient"}`}
          onClick={props.close}
        >
          {props.text}
        </button>
      </div>
    </div>
  );
};

export const ButtonConfirm = ({
  close,
  margin = "m-3",
  disabled = false,
  secondButton = "Cancel",
  loading = false,
  color = "btn-gradient",
  confirm,
  text,
}) => {
  return (
    <div className={`popup-footer ${margin} d-flex flex-row`}>
      <div className="col-6 px-2 pr-1">
        <button
          className={`btn btn-block btn-outline-purple ${
            IS_MOBILE ? "f12-vw" : "f8-vw"
          }`}
          onClick={() => close()}
        >
          {secondButton}
        </button>
      </div>
      <div className="col-6 px-2 pl-1">
        <button
          className={
            loading || disabled
              ? `btn btn-block bg-grey text-white ${
                  IS_MOBILE ? "f12-vw" : "f8-vw"
                }`
              : `btn btn-block text-white ${
                  IS_MOBILE ? "f12-vw" : "f8-vw"
                } ${color}`
          }
          onClick={() => confirm()}
          disabled={disabled}
        >
          {loading ? (
            <span
              className="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            ></span>
          ) : (
            text
          )}
        </button>
      </div>
    </div>
  );
};

export const ButtonCloseSuccess = (props) => {
  return (
    <div className="popup-footer mx-4 my-2 d-flex justify-content-end">
      <div className="col-6 px-0">
        <button
          className="btn btn-gradient text-white f8-vw rounded-input btn-block"
          onClick={props.close}
        >
          {props.text}
        </button>
      </div>
    </div>
  );
};

export const DailyClosingNotif = (props) => {
  return (
    <div className="notif-body mx-4 my-3">
      <div className="d-flex justify-content-center px-3">
        <span className="green f12-vw text-center text-wrap">
          {props?.header}
        </span>
      </div>
      <div
        className="p-2 mt-2"
        style={{ backgroundColor: "#F2F4FF", borderRadius: "5px" }}
      >
        <div className="d-flex flex-column px-1">
          <div className="col px-0">
            <span className="f8-vw font-weight-bold">{props?.date}</span>{" "}
            <span className="purple f8-vw">{props?.time}</span>
          </div>
          <div className="col px-0">
            <span className="f8-vw">Total Pendapatan : </span>
            <span className="f8-vw font-weight-bold">
              Rp. {priceSplit(props?.income)}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export const DepositCashAction = (props) => {
  const timezone = localStorage.getItem("timezone");
  const detailInfo = (title, value) => {
    return (
      <div className="d-flex flex-row f8-vw">
        <div className="col-3 px-0 grey">{title}</div>
        <div className="col px-0 text-black">: {value}</div>
      </div>
    );
  };
  const revenueInfo = (title, value, color) => {
    return (
      <div className="d-flex flex-row f8-vw">
        <div className={`col px-0 ${color ? color : "grey"}`}>{title}</div>
        <div className="col px-0 text-black">
          <div className="d-flex flex-row">
            <span>: Rp</span>
            <span className="ml-auto">{priceSplit(value)}</span>
          </div>
        </div>
      </div>
    );
  };
  return (
    <div className="notif-body mx-4 my-3">
      <div className="d-flex justify-content-center px-3">
        <span className={`f12-vw text-center text-wrap ${props.headerColor}`}>
          {props?.header}
        </span>
      </div>
      <div className="p-2 mt-2">
        <div className="d-flex flex-column px-1">
          {detailInfo("Nama", props?.detail?.full_name)}
          {detailInfo("Shift", props?.detail?.shift_name)}
          {detailInfo(
            "Start",
            dateFormat(new Date(props?.detail?.created_at)) +
              " " +
              props?.detail?.start_hour +
              " " +
              getTimezoneOffsetClient(timezone)
          )}
          {detailInfo(
            "End",
            dateFormat(new Date(props?.detail?.created_at)) +
              " " +
              props?.detail?.end_hour +
              " " +
              getTimezoneOffsetClient(timezone)
          )}
        </div>
      </div>
      <div
        className="p-2 mt-2"
        style={{ backgroundColor: "#F2F4FF", borderRadius: "5px" }}
      >
        <div className="d-flex flex-column px-1">
          {revenueInfo("Total Cash", props?.detail?.real_cash)}
          {revenueInfo("Total Setoran", props?.payload?.totalsetor)}
          <div className="">
            <hr className="separator-grey my-1" />
          </div>
          {revenueInfo("Total", props?.payload?.cashsisa)}
        </div>
      </div>
    </div>
  );
};

export const DepositCashApprovalRejectionDetail = (props) => {
  const timezone = localStorage.getItem("timezone");
  const detailInfo = (title, value) => {
    return (
      <div className="d-flex flex-row f8-vw">
        <div className="col-3 px-0 grey">{title}</div>
        <div className="col px-0 text-black">: {value}</div>
      </div>
    );
  };
  const revenueInfo = (title, value, color) => {
    return (
      <div className="d-flex flex-row f8-vw">
        <div className={`col px-0 ${color ? color : "grey"}`}>{title}</div>
        <div className="col px-0 text-black">
          <div className="d-flex flex-row">
            <span>: Rp</span>
            <span className="ml-auto">{priceSplit(value)}</span>
          </div>
        </div>
      </div>
    );
  };
  return (
    <div className="notif-body mx-4 my-3">
      <div className="d-flex justify-content-center px-3">
        <span className={`f12-vw text-center text-wrap ${props.headerColor}`}>
          {props?.header}
        </span>
      </div>
      <div className="p-2 mt-2">
        <div className="d-flex flex-column px-1">
          {detailInfo("Nama", props?.detail?.full_name)}
          {detailInfo("Shift", props?.detail?.shift_name)}
          {detailInfo(
            "Start",
            dateFormat(new Date(props?.detail?.created_at)) +
              " " +
              props?.shiftDetail?.start_hour +
              " " +
              getTimezoneOffsetClient(timezone)
          )}
          {detailInfo(
            "End",
            dateFormat(new Date(props?.detail?.created_at)) +
              " " +
              props?.shiftDetail?.end_hour +
              " " +
              getTimezoneOffsetClient(timezone)
          )}
        </div>
      </div>
      <div
        className="p-2 mt-2"
        style={{ backgroundColor: "#F2F4FF", borderRadius: "5px" }}
      >
        <div className="d-flex flex-column px-1">
          {revenueInfo("Total Cash", props?.detail?.pendapatan)}
          {revenueInfo("Total Setoran", props?.detail?.setoran)}
          {/* <div className="">
            <hr className="separator-grey my-1" />
          </div>
          {revenueInfo("Total", props?.detail?.sisacash)} */}
        </div>
      </div>
    </div>
  );
};
