import React from "react";
import { useContext } from "react";
import { bucketRnaWeb, oss, showMore } from "../utils";
import { BreadcrumbContext } from "./BreadcrumbContext";
import { IS_MOBILE } from "./Responsive";

const UserInfo = () => {
  const { userProfile } = useContext(BreadcrumbContext);
  const onErrorLoadImg = (e) => {
    return (e.target.src = IS_MOBILE
      ? "/img/user-mobile.svg"
      : "/img/user.svg");
  };

  return (
    <>
      {IS_MOBILE ? (
        <div className="d-flex align-items-center">
          <div className="ml-3">
            <div className="user-picture">
              <img
                src={
                  userProfile?.profile_photo_path
                    ? bucketRnaWeb + userProfile?.profile_photo_path + oss
                    : "/img/ersu - mobile.svg"
                }
                alt="user-profile"
                onError={(e) => onErrorLoadImg(e)}
                className="img-fluid"
                loading="lazy"
              />
            </div>
          </div>
          <div className="col px-1">
            <div className="d-flex flex-column">
              <div className="">
                <img src={process.env.REACT_APP_IMG_BANNER} alt="" width={60} />
              </div>
              <div className="f14-vw user-name mt-1">
                {showMore(userProfile?.nama, 10)}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="d-flex flex-row align-items-center justify-content-between">
          <div className="col px-0 mx-2">
            <div className="d-flex flex-column">
              <div className="f10-vw text-right user-name">
                {showMore(userProfile?.nama, 5)}
              </div>
            </div>
          </div>
          <div className="">
            <span className="user-picture">
              <img
                src={
                  userProfile?.profile_photo_path
                    ? bucketRnaWeb + userProfile?.profile_photo_path + oss
                    : "/img/user.svg"
                }
                loading="lazy"
                alt="user-profile"
                onError={(e) => onErrorLoadImg(e)}
                className="img-fluid"
              />
            </span>
          </div>
        </div>
      )}
    </>
  );
};

export default UserInfo;
