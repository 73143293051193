import React, { Fragment } from "react";

export const FootnoteHousekeeping = ({ data }) => {
  return (
    <div
      className="d-flex justify-content-between align-items-center px-2 py-3"
      style={{
        borderTop: "2px solid #ced4da",
        borderBottom: "2px solid #ced4da",
      }}
    >
      {data.map(({ value, label, title, className }) => {
        return (
          <Fragment key={value}>
            <div className="d-flex align-items-center">
              <div
                className={"f8-vw px-2 mr-2 text-center " + className}
                style={{
                  borderRadius: 3,
                  width: "3rem",
                  paddingTop: 2,
                  paddingBottom: 2,
                }}
              >
                {label}
              </div>
              <div className="f8-vw">{title}</div>
            </div>
          </Fragment>
        );
      })}
    </div>
  );
};
