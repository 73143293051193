import { Icon } from "@iconify/react";
import React, { useEffect } from "react";
import { HeaderName } from "../../../common/HeaderName";
import { IS_MOBILE } from "../../../common/Responsive";
import SelectOpt from "../../../common/SelectOpt";
import { iconUrl } from "../../../config";
import { getServerTimeWithoutDays } from "../../../utils";

const FONT_SIZE = IS_MOBILE ? "f12-vw" : "f8-vw";
const FONT_SIZE_HEADER = IS_MOBILE ? "f14-vw fw-700" : "f10-vw fw-700";

export const RoomChangeNumber = ({
  detail,
  typeRoom,
  roomSelected,
  setRoomSelected,
  reason,
  setReason,
  roomList,
  openModal,
  setModeModal,
  roomNumber,
  setError,
  setImgPopup,
  confirm,
  close,
  setRoomNumber,
  setRequestSelected,
  requestSelected,
  loading,
}) => {
  const detailInfo = (title, value) => {
    return (
      <div className={"d-flex flex-row mt-1 " + FONT_SIZE}>
        <div className="col-5 px-0 grey">{title}</div>
        <div className="col px-0 fw-600">: {value ? value : "-"}</div>
      </div>
    );
  };
  const inputContainer = (inpValue, inpPlaceholder, value, onChange) => {
    return (
      <div className="form-group my-1">
        <label htmlFor="pemesan" className={"m-0 " + FONT_SIZE}>
          {inpValue}
        </label>
        <textarea
          name="note"
          id="note"
          rows="5"
          className={"form-control form-control-sm rounded-input " + FONT_SIZE}
          style={{
            borderColor: "lightgray",
            resize: "none",
            backgroundColor: "white",
          }}
          onChange={onChange}
          value={value}
          placeholder={inpPlaceholder}
        />
      </div>
    );
  };
  const handleSelectRoom = (val) => {
    setRoomSelected(val);
    setRoomNumber([]);
  };

  const requestBy = [
    { value: 1, label: "RNA" },
    { value: 2, label: "Tamu" },
  ];

  const handleRequestBy = (val) => {
    setRequestSelected(val);
  };

  useEffect(() => {
    let d = requestBy?.find((item) => item.value === 2);
    setRequestSelected(d);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (detail?.room_type) {
      let d = typeRoom?.find((item) => item.value === detail?.room_type);
      setRoomSelected(d);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {IS_MOBILE ? (
        <div
          className="d-flex flex-column py-2 px-4"
          style={{ width: "21rem" }}
        >
          <div className="col px-0 py-1">
            <div className="d-flex align-items-center justify-content-start">
              <Icon icon={iconUrl.exchangeFour} color="orange" width={25} />
              <div className="fw-600 mx-2">
                <HeaderName
                  text="Change Room"
                  fontSize="f16-vw"
                  color1="blue-title"
                  color2="blue-title"
                />
              </div>
            </div>
          </div>
          <div className="col px-0">
            <hr className="separator-blue my-2" />
          </div>
          <div className="col px-0">
            <div className={FONT_SIZE}>
              <div className="fw-500 grey">Kode Booking</div>
              <div className="fw-600">{detail?.book_id}</div>
            </div>
          </div>
          <div
            className="px-0 mt-2 scroll-popup hide-scrollbar"
            style={{ height: "22rem" }}
          >
            <div className="fw-600">
              <HeaderName
                text="Informasi Booking"
                fontSize={FONT_SIZE_HEADER}
                color2="purple"
              />
            </div>
            {detailInfo("Nama Tamu", detail?.guest_name)}
            {detailInfo(
              "Check In",
              getServerTimeWithoutDays(detail?.checkin_date)
            )}
            {detailInfo(
              "Check Out",
              getServerTimeWithoutDays(detail?.checkout_date)
            )}
            {detailInfo("Tipe Kamar", detail?.room_type_name)}
            {detailInfo("Nomor Kamar", detail?.room_number)}

            <div className="d-flex flex-column mt-2">
              <HeaderName
                text="New Room Info"
                fontSize={FONT_SIZE_HEADER}
                color2="purple"
              />
              <div className="col px-0">
                <label htmlFor="type-room" className={"mb-0 mt-1 " + FONT_SIZE}>
                  Request By
                </label>
                <SelectOpt
                  optionsValue={requestBy}
                  onChange={handleRequestBy}
                  placeholder={"Pilih Request"}
                  value={requestSelected}
                  fontSize={IS_MOBILE ? "calc(8pt + 0.472vw)" : ""}
                />
              </div>
              <div className="col px-0">
                <label htmlFor="type-room" className={"mb-0 mt-2 " + FONT_SIZE}>
                  Tipe Kamar
                </label>
                <SelectOpt
                  optionsValue={typeRoom}
                  onChange={handleSelectRoom}
                  placeholder={"Pilih Tipe Kamar"}
                  value={roomSelected}
                  fontSize={IS_MOBILE ? "calc(8pt + 0.472vw)" : ""}
                />
              </div>
              <div className="col px-0">
                <div className="mt-1">
                  <label className={"my-0 " + FONT_SIZE}>Nomor Kamar</label>
                  <div
                    className={"px-2 " + FONT_SIZE}
                    style={{
                      cursor: "pointer",
                      backgroundColor: "rgb(95,5,147,0.2)",
                      borderRadius: "5px",
                      padding: "6px 0",
                    }}
                    onClick={
                      roomList?.length > 0
                        ? () => {
                            openModal();
                            setModeModal("room");
                          }
                        : () => {
                            openModal();
                            setModeModal("error");
                            setError("Tidak ada Nomor Kamar yang tersedia");
                            setImgPopup(
                              "/img/error-icon/room-not-available.svg"
                            );
                          }
                    }
                  >
                    <span className="purple">
                      {roomNumber?.length > 0
                        ? roomNumber.join(", ")
                        : "Pilih Nomor Kamar"}
                    </span>
                  </div>
                </div>
              </div>

              <div className="col px-0">
                {inputContainer("Reason", "Alasan pindah kamar", reason, (e) =>
                  setReason(e.target.value)
                )}
              </div>
            </div>
          </div>

          <div className="col px-0">
            <hr className="separator-blue my-2" />
          </div>
          <div className="col px-0">
            <div className="text-center">
              <small className="f10-vw">
                Apakah Anda yakin ingin melanjutkan?
              </small>
            </div>
          </div>

          <div className="col px-0">
            <div className="d-flex justify-content-between my-2">
              <div className="col-5 px-0">
                <button
                  className={
                    "btn btn-sm btn-block btn-outline-purple " + FONT_SIZE
                  }
                  onClick={() => {
                    close();
                  }}
                >
                  Back
                </button>
              </div>
              <div className="col-5 px-0">
                <button
                  className={`btn btn-sm btn-block text-white ${FONT_SIZE} ${
                    reason !== "" && roomNumber?.length > 0 && !loading
                      ? "bg-green"
                      : "btn-secondary"
                  }`}
                  disabled={
                    reason !== "" && roomNumber?.length > 0 && !loading
                      ? false
                      : true
                  }
                  onClick={() => confirm()}
                >
                  Confirm
                </button>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div
          className="d-flex flex-column py-2 px-4"
          style={{ width: "35rem" }}
        >
          <div className="col px-0 py-1">
            <div className="d-flex align-items-center justify-content-start">
              <Icon icon={iconUrl.exchangeFour} color="orange" width={25} />
              <div className="fw-600 mx-2">
                <HeaderName
                  text="Change Room"
                  fontSize="f14-vw"
                  color1="blue-title"
                  color2="blue-title"
                />
              </div>
            </div>
          </div>
          <div className="col px-0">
            <hr className="separator-blue my-2" />
          </div>

          <div className="d-flex flex-row">
            <div className="col pl-0 pr-2">
              <div className={FONT_SIZE}>
                <div className="fw-500 grey">Kode Booking</div>
                <div className="fw-600">{detail?.book_id}</div>
              </div>

              <div className="fw-600 mt-2">
                <HeaderName
                  text="Informasi Booking"
                  fontSize={FONT_SIZE_HEADER}
                  color2="purple"
                />
              </div>
              {detailInfo("Nama Tamu", detail?.guest_name)}
              {detailInfo(
                "Check In",
                getServerTimeWithoutDays(detail?.checkin_date)
              )}
              {detailInfo(
                "Check Out",
                getServerTimeWithoutDays(detail?.checkout_date)
              )}
              {detailInfo("Tipe Kamar", detail?.room_type_name)}
              {detailInfo("Nomor Kamar", detail?.room_number)}
            </div>
            <div className="col pr-0 pl-2">
              <div className="d-flex flex-column">
                <HeaderName
                  text="New Room Info"
                  fontSize={FONT_SIZE_HEADER}
                  color2="purple"
                />
                <div className="col px-0">
                  <label
                    htmlFor="type-room"
                    className={"mb-0 mt-1 " + FONT_SIZE}
                  >
                    Request By
                  </label>
                  <SelectOpt
                    optionsValue={requestBy}
                    onChange={handleRequestBy}
                    placeholder={"Pilih Request"}
                    value={requestSelected}
                    fontSize={IS_MOBILE ? "calc(8pt + 0.472vw)" : ""}
                  />
                </div>
                <div className="col px-0">
                  <label
                    htmlFor="type-room"
                    className={"mb-0 mt-2 " + FONT_SIZE}
                  >
                    Tipe Kamar
                  </label>
                  <SelectOpt
                    optionsValue={typeRoom}
                    onChange={handleSelectRoom}
                    placeholder={"Pilih Tipe Kamar"}
                    value={roomSelected}
                    fontSize={IS_MOBILE ? "calc(8pt + 0.472vw)" : ""}
                  />
                </div>
                <div className="col px-0">
                  <div className="mt-1">
                    <label className={"my-0 " + FONT_SIZE}>Nomor Kamar</label>
                    <div
                      className={"px-2 " + FONT_SIZE}
                      style={{
                        cursor: "pointer",
                        backgroundColor: "rgb(95,5,147,0.2)",
                        borderRadius: "5px",
                        padding: "6px 0",
                      }}
                      onClick={
                        roomList?.length > 0
                          ? () => {
                              openModal();
                              setModeModal("room");
                            }
                          : () => {
                              openModal();
                              setModeModal("error");
                              setError("Tidak ada Nomor Kamar yang tersedia");
                              setImgPopup(
                                "/img/error-icon/room-not-available.svg"
                              );
                            }
                      }
                    >
                      <span className="purple">
                        {roomNumber?.length > 0
                          ? roomNumber.join(", ")
                          : "Pilih Nomor Kamar"}
                      </span>
                    </div>
                  </div>
                </div>

                <div className="col px-0">
                  {inputContainer(
                    "Reason",
                    "Alasan pindah kamar",
                    reason,
                    (e) => setReason(e.target.value)
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="col px-0">
            <hr className="separator-blue my-2" />
          </div>
          <div className="col px-0">
            <div className="text-center">
              <small className={IS_MOBILE ? "f10-vw" : "f8-vw"}>
                Apakah Anda yakin ingin melanjutkan?
              </small>
            </div>
          </div>

          <div className="col px-0">
            <div className="d-flex justify-content-between my-2">
              <div className="col-4 px-0">
                <button
                  className={
                    "btn btn-sm btn-block btn-outline-purple " + FONT_SIZE
                  }
                  onClick={() => {
                    close();
                  }}
                >
                  Back
                </button>
              </div>
              <div className="col-4 px-0">
                <button
                  className={`btn btn-sm btn-block text-white ${FONT_SIZE} ${
                    reason !== "" && roomNumber?.length > 0 && !loading
                      ? "bg-green"
                      : "btn-secondary"
                  }`}
                  disabled={
                    reason !== "" && roomNumber?.length > 0 && !loading
                      ? false
                      : true
                  }
                  onClick={() => confirm()}
                >
                  Confirm
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
