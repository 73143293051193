import ReactToPrint, { useReactToPrint } from "react-to-print";
import { useContext, useEffect, useRef } from "react";
import { Icon } from "@iconify/react";
import {
  getClientTimeWithoutSecond,
  getDayDuration,
  getServerTime,
  getServerTimeWithoutDays,
  getTimezoneOffsetClient,
  priceSplit,
} from "../utils/index";
import { BreadcrumbContext } from "../common/BreadcrumbContext";
import { genBookingId, genPaymentID } from "./common/printer-helper";
import { iconUrl } from "../config";

function PrintReceipt({
  data,
  launch_print,
  roomCharge,
  earlyCheckin,
  deposit,
  totalCharge,
  paymentSelected,
  bankSelected,
  payment,
  charge,
  bookingSelected,
  paymentId,
  due_date,
  discount,
  realCharge,
  additionalCharge,
  processType,
}) {
  const { userProfile } = useContext(BreadcrumbContext);
  let componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  const date_now = new Date();
  const timezone = localStorage.getItem("timezone");
  const hotel_info = JSON.parse(localStorage.getItem("info_hotel"));

  const createLineDash = () => {
    return (
      <p className="p-print txt-print-h2">
        {"- - - - - - - - - - - - - - - - - - - - - - - - - -"}
      </p>
    );
  };

  const createLineSolid = () => {
    return (
      <div className="p-print txt-print-h2">
        <hr
          style={{
            width: "100%",
            color: "#000",
            border: "0.8px solid #000",
            backgroundColor: "#000",
          }}
        />
      </div>
    );
  };

  const genContent1 = (label, value) => {
    return (
      <tr className="rmp txt-print-section">
        <td width={120}>{label}</td>
        <td>: {value}</td>
      </tr>
    );
  };

  const genContent2 = (label, value) => {
    return (
      <tr className="txt-print-section">
        <td width={120}>{label}</td>
        <td width={80} style={{ textAlign: "center" }}>
          {"IDR"}
        </td>
        <td className={"txt-price"} width={60}>
          {priceSplit(value)}
        </td>
      </tr>
    );
  };

  useEffect(() => {
    if (launch_print) {
      handlePrint();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [launch_print]);

  return (
    <div>
      <ReactToPrint
        content={() => componentRef.current}
        pageStyle={{ margin: 0, padding: 0 }}
      />
      <div ref={componentRef} className="receipt-print">
        <div
          className="d-flex justify-content-center"
          style={{ padding: "4px 0" }}
        >
          <img
            src={"/img/print-logo/logo.jpg"}
            alt={"logo"}
            className="img-print"
          />
        </div>
        {paymentSelected?.value === 3 ? (
          <p className="p-print txt-print-h1">SLIP Pembayaran</p>
        ) : (
          <p className="p-print txt-print-h1">Billing</p>
        )}

        {/* <p className="p-print txt-print-h2">
          Payment ID {"\t"}: {limitText(paymentId)}
        </p> */}
        {genPaymentID(paymentId)}
        {createLineDash()}
        <p className="p-print txt-print-h3">{`${getServerTime(
          date_now,
          timezone
        )} - ${getClientTimeWithoutSecond(
          date_now,
          timezone
        )} ${getTimezoneOffsetClient(timezone)}`}</p>
        <p className="p-print txt-print-h3">{hotel_info?.hotel_name}</p>
        {createLineDash()}
        <table className="table-print">
          <tbody>
            {genContent1("Kasir", userProfile?.nama)}
            {/* {genContent1("Kode Booking", limitText(data?.booking_id))} */}
          </tbody>
        </table>
        {genBookingId("Booking ID", data?.book_id)}
        {createLineDash()}
        <p className="p-print txt-print-section-h2">Informasi Booking</p>
        <table className="table-print">
          <tbody>
            {genContent1(
              "Check In",
              getServerTimeWithoutDays(data?.checkin_date, timezone)
            )}
            {genContent1(
              "Check Out",
              getServerTimeWithoutDays(data?.checkout_date, timezone)
            )}
            {genContent1(
              "Durasi",
              getDayDuration(data?.checkin_date, data?.checkout_date) + " Malam"
            )}
            {processType !== 12 &&
              genContent1(
                "Nomor Kamar",
                bookingSelected?.room_number?.join(", ")
              )}
          </tbody>
        </table>
        {createLineDash()}
        <table className="table-print">
          <tbody>
            {genContent1("Metode Pembayaran", paymentSelected?.label)}
            {paymentSelected?.value === 2 && (
              <>
                {genContent1("Nama Bank", bankSelected?.label)}
                {genContent1(
                  "Batas Pembayaran",
                  `${getClientTimeWithoutSecond(
                    due_date
                  )} ${getTimezoneOffsetClient(timezone)}`
                )}
              </>
            )}
          </tbody>
        </table>
        {createLineDash()}
        {paymentSelected?.value === 3 ? (
          <p className="p-print txt-print-section-h2">Informasi Pembayaran</p>
        ) : (
          <p className="p-print txt-print-section-h2">Informasi Tagihan</p>
        )}
        {bookingSelected?.voucher_no?.length > 0
          ? genBookingId("Kode Voucher", bookingSelected.voucher_no)
          : null}
        <table className="table-print mt-2">
          <tbody>
            {genContent2(
              "Harga Kamar",
              (bookingSelected?.voucher_type?.length > 0 &&
                bookingSelected?.voucher_type[0] === 1) ||
                processType === 12
                ? roomCharge
                : realCharge
            )}

            {processType === 12 &&
              genContent2("Additional Charge", additionalCharge)}
            {processType !== 12 && genContent2("Early Checkin", earlyCheckin)}
            {paymentSelected?.value === 3 &&
              processType !== 12 &&
              genContent2("Deposit", deposit)}
            {discount > 0 && (
              <tr className="txt-print-section">
                <td width={120}>Diskon</td>
                <td width={80} style={{ textAlign: "center" }}>
                  {"IDR"}
                </td>
                <td className={"txt-price"} width={60}>
                  -{priceSplit(discount)}
                </td>
              </tr>
            )}
          </tbody>
        </table>
        {createLineSolid()}
        <table className="table-print">
          <tbody>
            {genContent2(
              "Total Tagihan",
              paymentSelected?.value === 3 ? totalCharge + deposit : totalCharge
            )}
            {paymentSelected?.value === 2 &&
              processType !== 12 &&
              genContent2("Deposit (Cash)", deposit)}
            {paymentSelected?.value === 3 &&
              genContent2("Pembayaran Tunai", payment)}
          </tbody>
        </table>
        {paymentSelected?.value === 3 && (
          <>
            {createLineSolid()}
            <table className="table-print">
              <tbody>{genContent2("Kembalian", charge)}</tbody>
            </table>
          </>
        )}

        {createLineDash()}
        <p className="p-print txt-print-footer">
          Terima kasih atas kepercayaannya
        </p>
        <p className="p-print txt-print-footer">Memilih ruangnyaman.id</p>
        <p className="p-print txt-print-tagline">#BeneranNyaman</p>
        {createLineDash()}
        <table className="table-print-contact">
          <tbody>
            <tr className="rmp txt-print-contact">
              <td>
                <span>
                  <Icon
                    icon={iconUrl.whatsappIcon}
                    fontSize={"8pt"}
                    style={{
                      top: "1px",
                      position: "relative",
                    }}
                  />{" "}
                  0818 22 88 28
                </span>

                <span style={{ marginLeft: "10px" }}>
                  <Icon
                    icon={iconUrl.envelopeIcon}
                    fontSize={"8pt"}
                    style={{ top: "1px", position: "relative" }}
                  />{" "}
                  info@ruangnyaman.id
                </span>
              </td>
            </tr>
          </tbody>
        </table>
        <p className="p-print txt-print-h3">ruangnyaman.id</p>
        <p className="p-print txt-print-h3"></p>
      </div>
    </div>
  );
}

export default PrintReceipt;
