import { Icon } from "@iconify/react";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { BottomSheet } from "react-spring-bottom-sheet";
import { BreadcrumbContext } from "../../common/BreadcrumbContext";
import { MobileCardList } from "../../common/CardList";
import { t } from "../../common/ErrorMessage";
import { IS_MOBILE } from "../../common/Responsive";
import { iconUrl } from "../../config";
import { tableCol } from "../../utils";
import { getData, getErr, safeErrCode } from "../../utils/api-request";
import { listTable } from "../../utils/helper";
import history from "../../utils/history";
import { mobileSearchInput, searchInput } from "../booking/bookingHelper";
import { MobileEmptyData } from "../Empty/EmptyData";

const BedType = () => {
  const {
    setpathLocation,
    openButtonSheet,
    setOpenButtonSheet,
    setShowFilter,
  } = useContext(BreadcrumbContext);
  const [search, setSearch] = useState("");
  const [bedType, setBedType] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const tableHeader = ["No", "Bed Type", "Size"];

  const handleSearch = (val) => {
    setSearch(val.target.value);
  };

  const getBedTypeList = useCallback((search = "") => {
    setLoading(true);
    getData(`/api/v1/bedtypelist?bedtypename=${search}`)
      .then((resp) => {
        setLoading(false);
        if (resp.data) {
          setBedType(resp.data);
        } else {
          resp.json().then((err) => {
            setError(t(safeErrCode(err)));
          });
        }
      })
      .catch((error) => {
        let err = getErr(error);
        setLoading(false);
        setError(t(err));
      });
  }, []);

  const trTable = (item, i) => {
    return (
      <tbody className="f8-vw" key={i}>
        <tr className={tableCol(i)}>
          <td className="align-middle text-center">{i + 1}</td>
          <td className="align-middle ">{item.bed_type_name}</td>
          <td className="align-middle text-center">{item.bed_size}</td>
        </tr>
      </tbody>
    );
  };

  const handleEnter = (e) => {
    if (e.key === "Enter") {
      getBedTypeList(search);
    }
  };

  const resetFilter = () => {
    setSearch("");
  };

  useEffect(() => {
    if (search === "") {
      getBedTypeList(search);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search, getBedTypeList]);

  useEffect(() => {
    setpathLocation(history.location.pathname);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setShowFilter(true);
    return () => {
      setShowFilter(false);
    };
  }, [setShowFilter]);
  return (
    <>
      {IS_MOBILE ? (
        <div className="d-flex flex-column px-3 py-1 mb-5">
          <div className="col px-1">
            {bedType?.length > 0 ? (
              bedType.map((item, i) => {
                return (
                  <MobileCardList title={item?.bed_type_name} key={i}>
                    <div className="card-hms-list">
                      <div className="col-4 px-0 grey-text">No</div>
                      <div className="col pr-0 f12-vw black-value">{i + 1}</div>
                    </div>
                    <div className="card-hms-list">
                      <div className="col-4 px-0 grey-text">Size</div>
                      <div className="col pr-0 f12-vw black-value">
                        {item.bed_size}
                      </div>
                    </div>
                  </MobileCardList>
                );
              })
            ) : (
              <div style={{ position: "relative" }}>
                <MobileEmptyData
                  errMessage={error}
                  loading={loading}
                  height="40vh"
                  sourceImg={"/img/master-data/bed-type.svg"}
                />
              </div>
            )}
          </div>
        </div>
      ) : (
        <div className="d-flex flex-column px-4 py-1">
          <div className="col px-2">
            <div className="d-flex flex-row justify-content-between">
              <div className="col-md-9 px-0">
                <div className="d-flex flex-row">
                  <div className="col-3 px-0 mr-2">
                    <label htmlFor="" className="f8-vw m-0 p-0">
                      Bed Type
                    </label>
                    {searchInput(
                      "Cari Bed Type",
                      search,
                      (e) => handleSearch(e),
                      handleEnter
                    )}
                  </div>
                </div>
              </div>
              <div className="col-md-2 pr-0 pl-5 mt-auto">
                <div className="d-flex flex-column">
                  <button
                    className="btn btn-sm search-new rounded-input btn-block mt-auto f8-vw fw-500 text-white"
                    onClick={() => {
                      getBedTypeList(search);
                    }}
                    style={{
                      paddingTop: 7,
                      paddingBottom: 7,
                      backgroundColor: "#742B9E",
                    }}
                  >
                    Search
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="col px-2 mt-0">
            <div className="my-3">
              {listTable(
                tableHeader,
                bedType,
                trTable,
                loading,
                error,
                "/img/master-data/bed-type.svg"
              )}
            </div>
          </div>
        </div>
      )}

      {IS_MOBILE && (
        <div>
          <BottomSheet
            open={openButtonSheet}
            onDismiss={() => setOpenButtonSheet(false)}
            snapPoints={({ minHeight }) => minHeight}
            header={
              <div className="d-flex justify-content-between">
                <span className="purple f16-vw fw-600 px-2">Filter</span>
                <span
                  className="px-2"
                  onClick={() => setOpenButtonSheet(false)}
                >
                  <Icon icon={iconUrl.closeBold} color={"grey"} width={20} />
                </span>
              </div>
            }
            footer={
              <div className="d-flex justify-content-between">
                <div className="col-6 pl-2">
                  <button
                    className="btn btn-block btn-outline-purple f12-vw"
                    onClick={() => {
                      resetFilter();
                      setOpenButtonSheet(false);
                      setBedType([]);
                      getBedTypeList();
                    }}
                  >
                    Reset
                  </button>
                </div>
                <div className="col-6 pr-2">
                  <button
                    className="btn btn-block btn-gradient f12-vw text-white"
                    onClick={() => {
                      setOpenButtonSheet(false);
                      getBedTypeList(search);
                    }}
                  >
                    Search
                  </button>
                </div>
              </div>
            }
          >
            <div className="mt-3" style={{ height: "10rem" }}>
              <div className="px-4 mt-2">
                <label htmlFor="" className="f12-vw m-0 p-0 fw-600">
                  Bed Type
                </label>
                {mobileSearchInput("Cari Bed Type", search, (e) =>
                  handleSearch(e)
                )}
              </div>
            </div>
          </BottomSheet>
        </div>
      )}
    </>
  );
};

export default BedType;
