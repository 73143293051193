import React from "react";
import { priceSplit, showMoreGuest, tableCol } from "../../utils";
import { getServerTimeWithoutDays } from "../../utils/index";

export const TableBreakage = ({
  list,
  setIdDetail,
  errMessage,
  loadingList,
}) => {
  return (
    <>
      <div className="table-list table-responsive-sm">
        <table
          className="table table-sm scroll-table"
          style={{ borderCollapse: "separate", borderSpacing: 0 }}
        >
          <thead className="f8-vw sticky-table">
            <tr
              className="text-center text-white"
              style={{ backgroundColor: "#0071A4" }}
            >
              <th className="align-middle">Booking ID</th>
              <th className="align-middle">
                <div>Tanggal</div>
                <div>Breakage</div>
              </th>
              <th className="align-middle">Nama Tamu</th>
              <th className="align-middle">Room Type</th>
              <th className="align-middle">
                <div>Room</div>
                <div>No</div>
              </th>
              <th className="align-middle">Breakage Item</th>
              <th className="align-middle">Total Charge</th>
            </tr>
          </thead>

          <tbody className="f8-vw">
            {list?.length > 0 &&
              list?.map((item, i) => {
                return (
                  <tr className={tableCol(i)} key={i}>
                    <td className="text-left align-middle font-weight-bold">
                      {item?.book_id}
                    </td>
                    <td className="text-center align-middle">
                      {getServerTimeWithoutDays(item?.ts)}
                    </td>
                    <td className="text-left align-middle">
                      {showMoreGuest(item?.nama_tamu)}
                    </td>
                    <td className="text-center align-middle">
                      {item?.room_type_name}
                    </td>
                    <td className="text-center align-middle">
                      {item?.room_no}
                    </td>
                    <td className="text-center align-middle ">
                      <div
                        className="blue f8-vw pointer"
                        onClick={() => {
                          setIdDetail(item?.id);
                        }}
                        style={{ cursor: "pointer" }}
                      >
                        Lihat Detail
                      </div>
                    </td>
                    <td className="text-right font-weight-bold">
                      {priceSplit(item?.total_compen)}
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
    </>
  );
};
