import { Icon } from "@iconify/react";
import React, { useContext, useEffect, useState } from "react";
import { BreadcrumbContext } from "../../common/BreadcrumbContext";
import PopupModal from "../../common/PopupModal";
import { TabLink } from "../../common/TabLink";
import { iconUrl } from "../../config";
import { OtherCheckin } from "../../pages/booking/otherota/OtherCheckin";
import { Walkin } from "../../pages/booking/walkin/Walkin";
import { PopupRoomPrice } from "./PopupRoomPrice";
import NotifMessage, { ButtonClose } from "../../common/Notif";
import { is_sub_menu_action } from "../../utils/uac";

function TabsMenu() {
  const {
    setpathLocation,
    roomType,
    loadingOverlay,
    roomPrice,
    userProfile,
  } = useContext(BreadcrumbContext);
  const [currentTab, setcurrentTab] = useState(1);
  const [isOpen, setIsOpen] = useState(false);
  const [isOpeningShift, setisOpeningShift] = useState(true);
  const today = new Date();
  const [mode, setMode] = useState("");
  const hotelInfo = JSON?.parse(localStorage?.getItem("info_hotel"));

  const openModal = () => {
    setIsOpen(!isOpen);
  };
  useEffect(() => {
    if (localStorage.getItem("shift_open")) {
      let d = JSON.parse(localStorage?.getItem("shift_open"));
      setisOpeningShift(d);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localStorage.getItem("shift_open")]);

  useEffect(() => {
    if (currentTab === 1) {
      setpathLocation("/reservasi/booking/walkin");
    } else {
      setpathLocation("/reservasi/booking/other OTA");
    }
  });

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
    return () => {
      document.body.style.overflow = "unset";
    };
  }, [isOpen]);

  useEffect(() => {
    if (
      userProfile?.level_id === 3 &&
      is_sub_menu_action("mg_change_hotel_process")
    ) {
      setMode("warning-popup");
      setIsOpen(true);
    }
    return () => {
      setMode("");
      setIsOpen(false);
    };
  }, [userProfile?.level_id]);

  return (
    <div className="d-flex flex-column mt-1">
      {!loadingOverlay && (
        <>
          <div className="mt-0 tab-link">
            <div className="d-flex justify-content-between">
              <ul className="nav">
                <TabLink
                  tabName="Walk-in"
                  url=""
                  tabClass="py-2 tab-container-walk-in active"
                  tabMenus="walk-in"
                  isOpen={isOpeningShift}
                  onClick={() => {
                    setcurrentTab(1);
                  }}
                  icon={iconUrl.personWalkingLuggage}
                />

                <TabLink
                  tabName="Other OTA"
                  url=""
                  tabClass="py-2 tab-container-other-ota"
                  tabMenus="other-ota"
                  isOpen={isOpeningShift}
                  onClick={() => {
                    setcurrentTab(3);
                  }}
                  icon={iconUrl.touch1Filled}
                />
              </ul>
              <div className="btn-position">
                <button
                  className="btn btn-block room-btn py-2"
                  onClick={() => {
                    openModal();
                    setMode("room-price");
                  }}
                  aria-label="Room Price"
                >
                  <Icon
                    icon={iconUrl.receiptMoney20Filled}
                    color="#16912F"
                    width={20}
                  />
                  <span className="room-text">Room Price</span>
                </button>
              </div>
            </div>
          </div>

          <div className="col px-0 tab-content tab-menu" id="myTabContent">
            <Walkin typeRoom={roomType} isOpening={isOpeningShift} />
            <OtherCheckin typeRoom={roomType} currentTab={currentTab} />
          </div>
        </>
      )}

      <PopupModal modalIsOpen={isOpen}>
        {mode === "room-price" ? (
          <PopupRoomPrice
            today={today}
            list={roomPrice}
            closeModal={openModal}
          />
        ) : (
          <>
            <NotifMessage
              img={"/img/popup/warning-popup.png"}
              messageHeader={"Attention!"}
              messageBody={
                <>
                  <div>Anda sedang melakukan input data di Properti </div>
                  <div
                    style={{ backgroundColor: "#FFCC0060" }}
                    className="fw-700 py-2"
                  >
                    {hotelInfo?.hotel_name}
                  </div>
                </>
              }
            />
            <ButtonClose
              close={() => {
                setIsOpen(false);
              }}
              text="OK"
            />
          </>
        )}
      </PopupModal>
    </div>
  );
}

export default TabsMenu;
