import React from "react";
import {
  getServerTimeWithoutDays,
  getTimezoneOffsetClient,
  priceSplit,
  splitID,
  tableCol,
} from "../../utils";
import { filterStatusSetoran } from "../setoran/common/utils";
import { getClientTimeWithoutSecond } from "../../utils/index";

export const TableHistoryTransfer = ({ list, isOpen, getDetail }) => {
  const timezone =
    localStorage?.getItem("timezone") && localStorage?.getItem("timezone");
  return (
    <div className="table-list table-responsive-sm">
      <table className="table table-sm">
        <thead className="f8-vw sticky-table">
          <tr
            className="text-center text-white"
            style={{ backgroundColor: "#0071A4" }}
          >
            <th className="align-middle py-2">Setoran ID</th>
            <th className="align-middle py-2">Created at</th>
            <th className="align-middle py-2">Disetorkan oleh</th>
            <th className="align-middle py-2">
              <div>Total</div>
              <div>Setoran</div>
            </th>
            <th className="align-middle py-2">
              <div>Metode</div>
              <div>Pembayaran</div>
            </th>
            <th className="align-middle py-2">Transfer Date</th>
            <th className="align-middle py-2">Nama Bank</th>
            <th className="align-middle py-2">Penerima</th>
            <th className="align-middle py-2">Bukti Setoran</th>
            <th className="align-middle py-2">Note</th>
            <th className="align-middle py-2">Approved by</th>
            <th className="align-middle py-2">Approved at</th>
            <th className="align-middle py-2">Status</th>
            <th className="align-middle py-2">Shift ID</th>
            <th className="align-middle py-2">Note Rejected</th>
          </tr>
        </thead>

        <tbody className="f8-vw">
          {list?.length > 0 && (
            <>
              {list?.map((item, index) => (
                <tr key={item?.wsetoran_id} className={tableCol(index)}>
                  <td className="align-middle text-center font-weight-bold">
                    {item?.wsetoran_id}
                  </td>
                  <td className="align-middle text-center">
                    <div>
                      {getServerTimeWithoutDays(item?.created_at, timezone)}
                    </div>
                    <div className="font-weight-bold">
                      {getClientTimeWithoutSecond(item?.created_at, timezone)}{" "}
                      {getTimezoneOffsetClient(timezone)}
                    </div>
                  </td>
                  <td className="align-middle text-center">
                    {item?.disetorkan_oleh}
                  </td>
                  <td className="align-middle text-right font-weight-bold">
                    {priceSplit(item?.total_setoran)}
                  </td>
                  <td className="align-middle text-center">
                    {item?.payment_name}
                  </td>

                  <td className={`align-middle text-center`}>
                    {item?.transfer_date ? (
                      <>
                        <div>
                          {getServerTimeWithoutDays(
                            item?.transfer_date,
                            timezone
                          )}
                        </div>
                        <div className="font-weight-bold">
                          {getClientTimeWithoutSecond(
                            item?.transfer_date,
                            timezone
                          )}{" "}
                          {getTimezoneOffsetClient(timezone)}
                        </div>
                      </>
                    ) : (
                      "-"
                    )}
                  </td>
                  <td
                    className={`align-middle  ${
                      item?.bank_name ? "text-left" : "text-center"
                    }`}
                  >
                    {item?.bank_name
                      ? `${item?.bank_name} (${item?.to_account_no})`
                      : "-"}
                  </td>
                  <td className="align-middle text-left">{item?.acc_name}</td>
                  <td className="align-middle text-center">
                    <div
                      className="btn btn-sm blue f8-vw"
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        getDetail(item?.bukti_setoran);
                        isOpen();
                      }}
                    >
                      Lihat bukti
                    </div>
                  </td>
                  <td
                    className={`align-middle  ${
                      item?.note ? "text-left" : "text-center"
                    }`}
                  >
                    {item?.note ? item?.note : "-"}
                  </td>
                  <td className="align-middle text-left">
                    {item?.approved_by_name}
                  </td>
                  <td className="align-middle text-center">
                    <div>
                      {getServerTimeWithoutDays(item?.ts_approve, timezone)}
                    </div>
                    <div className="font-weight-bold">
                      {getClientTimeWithoutSecond(item?.ts_approve, timezone)}{" "}
                      {getTimezoneOffsetClient(timezone)}
                    </div>
                  </td>
                  <td
                    className={
                      "align-middle text-center " +
                      filterStatusSetoran(item?.status)?.className
                    }
                  >
                    {filterStatusSetoran(item?.status)?.label}
                  </td>
                  <td className="align-middle text-center">
                    {splitID(item?.real_shiftids)}
                  </td>
                  <td
                    className={`align-middle  ${
                      item?.note_rejected ? "text-left" : "text-center"
                    }`}
                  >
                    {item?.note_rejected ? item?.note_rejected : "-"}
                  </td>
                </tr>
              ))}
            </>
          )}
        </tbody>
      </table>
    </div>
  );
};
