import React from "react";
import { IS_MOBILE } from "./Responsive";
import history from "../utils/history";

const EmptyResponse = ({
  text,
  src,
  backToHome = false,
  fontSize = "f10-vw",
  text2 = "",
  fontSize2 = "f8-vw",
  buttonFontSize = "f8-vw",
  maxWidth = "auto",
  opacity = "",
  padding = "py-5",
}) => {
  return (
    <div
      className={`d-flex flex-column justify-content-center align-items-center ${padding}`}
    >
      {!!src && (
        <img
          src={src}
          alt="..."
          className="img-fluid"
          style={{
            maxWidth: IS_MOBILE ? "40%" : maxWidth,
            opacity: opacity,
          }}
        />
      )}

      <div className={`${fontSize} fw-bold mt-2 text-center`}>{text}</div>

      <div
        className={`${fontSize2} fw-bold text-center text-secondary my-1`}
        style={{ whiteSpace: "pre-line" }}
      >
        {text2}
      </div>

      {!!backToHome && (
        <button
          className={`btn btn-sm btn-primary ${buttonFontSize} mt-2`}
          onClick={() => history.push("/")}
        >
          Back to home
        </button>
      )}
    </div>
  );
};

export default EmptyResponse;
