import { Icon } from "@iconify/react";
import React, {
  Fragment,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { BreadcrumbContext } from "../../../common/BreadcrumbContext";
import NotifMessage, { ButtonClose } from "../../../common/Notif";
import PopupModal from "../../../common/PopupModal";
import {
  changeCol,
  formatDate,
  generateBookingCode,
  getClientTime,
  getServerTimeWithoutDays,
  getTimezoneOffsetClient,
  paymentLabel,
  priceSplit,
  tableCol,
} from "../../../utils";
import {
  addData,
  addDataPict,
  getData,
  getErr,
  safeErrCode,
} from "../../../utils/api-request";
import history from "../../../utils/history";
import { isValidAccess, is_sub_menu_action } from "../../../utils/uac";
import { EmptyData, MobileEmptyData } from "../../Empty/EmptyData";
import { mobileSearchInput, searchInput } from "../bookingHelper";
import { PaymentConfirmPopup } from "./PaymentConfirmPopup";
import { PaymentConfirmSuccess } from "./PaymentConfirmSuccess";
import { PrintConfirm } from "../../../print/PrintConfirm";
import { CancelPayment } from "./CancelPayment";
import { iconUrl } from "../../../config";
import { IS_MOBILE } from "../../../common/Responsive";
import { MobileCardList } from "../../../common/CardList";
import { BottomSheet } from "react-spring-bottom-sheet";
import { t } from "../../../common/ErrorMessage";

const PaymentConfirm = () => {
  const {
    setpathLocation,
    isOpeningShift,
    openButtonSheet,
    setOpenButtonSheet,
    setShowFilter,
  } = useContext(BreadcrumbContext);
  const [listPayment, setListPayment] = useState([]);
  const [bookingDetail, setbookingDetail] = useState({});
  const timezone =
    localStorage?.getItem("timezone") && localStorage?.getItem("timezone");
  const mounted = useRef(false);
  const [isOpen, setIsOpen] = useState(false);
  const [mode, setMode] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [loadingSubmit, setloadingSubmit] = useState(false);
  const [imgPopup, setImgPopup] = useState("");
  const [headerPopup, setHeaderPopup] = useState("");
  const [fileUpload, setfileUpload] = useState([]);
  const [isPrint, setIsPrint] = useState(false);
  const [showImg, setShowImg] = useState("");
  const [dateTransfer, setDateTransfer] = useState(new Date());
  const [search, setSearch] = useState({
    booking_id: "",
    room_number: "",
    payment_id: "",
  });

  const openModal = () => {
    setIsOpen(!isOpen);
  };

  const getListPaymentConfirm = useCallback(() => {
    setLoading(true);
    getData(
      `/api/v1/listpayment?booking_id=${search?.booking_id}&room_number=${search?.room_number}&payment_id=${search?.payment_id}`
    )
      .then((resp) => {
        setLoading(false);
        if (resp.data) {
          if (resp.data.length > 0) {
            setError("");
            setListPayment(resp.data);
          } else {
            setListPayment([]);
          }
        } else {
          resp.json().then((err) => {
            setError(t(safeErrCode(err)));
          });
        }
      })
      .catch((error) => {
        let err = getErr(error);
        setLoading(false);
        setError(t(err));
      });
  }, [search?.booking_id, search?.payment_id, search?.room_number]);

  const submitConfirmPayment = () => {
    setloadingSubmit(true);
    const payload = {
      billing_ids: bookingDetail.billing_id,
      payment_id: bookingDetail.payment_id,
      process_type: bookingDetail?.process_type,
      tgl_transfer: `${formatDate(dateTransfer)} ${getClientTime(
        dateTransfer
      )}`,
      idem_id:
        Math.random()
          .toString()
          .slice(2, 15) +
        Math.random()
          .toString()
          .slice(2, 15),
    };
    const formData = new FormData();
    for (const key in payload) {
      formData.append(key, payload[key]);
    }
    if (fileUpload.length > 0) {
      for (const key of Object.keys(fileUpload)) {
        if (fileUpload[key]) {
          formData.append("file_bukti", fileUpload[key]);
        }
      }
    }
    addDataPict("/api/v1/confirmpayment", formData)
      .then((resp) => {
        setloadingSubmit(false);
        if (resp.message) {
          setMode("confirm-success");
          setIsPrint(true);
        } else {
          resp.json().then((err) => {
            setError(t(safeErrCode(err)));
            setMode("error");
            setHeaderPopup("Konfirmasi Gagal");
            setImgPopup("/img/error-icon/booking-failed.svg");
          });
        }
      })
      .catch((error) => {
        console.log(error);
        setloadingSubmit(false);
        let err = getErr(error);
        setError(t(err));
        setMode("error");
        setHeaderPopup("Konfirmasi Gagal");
        setImgPopup("/img/error-icon/booking-failed.svg");
      });
  };

  const cancelPayment = (payment_id) => {
    setloadingSubmit(true);
    const url = "/api/v1/listpayment/checkin/cancel";
    const payload = {
      payment_id,
      process_type: bookingDetail?.process_type,
      booking_id_uuid: bookingDetail?.booking_id,
    };
    addData(url, payload)
      .then((resp) => {
        setloadingSubmit(false);
        if (resp.message) {
          setMode("success-cancel");
          setHeaderPopup("Cancel Pembayaran Berhasil!");
          setImgPopup("/img/error-icon/booking-failed.svg");
        } else {
          resp.json().then((err) => {
            setError(t(safeErrCode(err)));
            setMode("error");
            setHeaderPopup("Cancel Pembayaran Gagal");
            setImgPopup("/img/error-icon/booking-failed.svg");
          });
        }
      })
      .catch((error) => {
        setloadingSubmit(false);
        console.log(error);
        let err = getErr(error);
        setError(t(err));
        setMode("error");
        setHeaderPopup("Cancel Pembayaran Gagal");
        setImgPopup("/img/error-icon/booking-failed.svg");
      });
  };

  const reset = () => {
    setbookingDetail([]);
    setError("");
    setLoading(false);
    setIsOpen(false);
    setIsPrint(false);
    setShowImg("");
    setfileUpload([]);
    getListPaymentConfirm();
  };

  const handleEnter = (e) => {
    if (e.key === "Enter") {
      setListPayment([]);
      getListPaymentConfirm();
    }
  };

  const resetFilter = () => {
    setListPayment([]);
    setSearch({ ...search, booking_id: "", room_number: "", payment_id: "" });
    getListPaymentConfirm();
  };

  const redirectCancel = (ota_name) => {
    if (ota_name === "HMS") {
      history.push("/reservasi/pending-booking");
    } else {
      history.push("/reservasi/payment-process");
    }
  };

  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);
  useEffect(() => {
    setpathLocation("/reservasi/konfirmasi-pembayaran");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (
      search?.booking_id === "" &&
      search?.room_number === "" &&
      search?.payment_id === "" &&
      !isOpeningShift
    ) {
      getListPaymentConfirm();
    }
    return () => {
      mounted.current = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search, isOpeningShift]);

  useEffect(() => {
    if (isPrint) {
      setTimeout(() => {
        setIsPrint(false);
      }, 6000);
    }
  }, [isPrint]);

  useEffect(() => {
    setShowFilter(true);
    return () => {
      setShowFilter(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
    return () => {
      document.body.style.overflow = "unset";
    };
  }, [isOpen]);

  return (
    <>
      {IS_MOBILE ? (
        <div className={` my-1 ${!isOpeningShift ? "" : "not-opening"}`}>
          <div className="d-flex flex-column px-3 py-1 mb-5">
            <div className="col px-1">
              {listPayment?.length > 0 ? (
                listPayment.map((item) => {
                  const timezone = localStorage.getItem("timezone");
                  return (
                    <MobileCardList
                      title={
                        <>
                          <div
                            className="f10-vw fw-500"
                            style={{ color: "#959595" }}
                          >
                            Kode Booking
                          </div>
                          <div>{item?.book_id}</div>
                        </>
                      }
                      key={item?.payment_id}
                      fontSizeHeader="f10-vw"
                      action_button={
                        isValidAccess([
                          "rv_payment_confirm_process",
                          "rv_payment_confirm_cancel",
                        ]) &&
                        (item?.payment_status === 0 ||
                          item?.payment_status === 2) && (
                          <div className="dropdown combo-box-mobile col px-0">
                            <button
                              className="btn btn-sm btn-gradient text-white dropdown-toggle border-0 btn-block f12-vw fw-600"
                              type="button"
                              data-toggle="dropdown"
                              aria-expanded="false"
                            >
                              Action
                              <span className="ml-2">
                                <Icon icon={iconUrl.arrowDownAlt2} width={15} />
                              </span>
                            </button>
                            <div
                              className="dropdown-menu dropdown-menu-center"
                              aria-labelledby="dropdownMenu2"
                            >
                              {is_sub_menu_action(
                                "rv_payment_confirm_process"
                              ) && (
                                <button
                                  className="menu border-0 purple bg-transparent f12-vw font-weight-bold btn-block m-0"
                                  onClick={() => {
                                    openModal();
                                    setMode("confirm-payment");
                                    setbookingDetail(item);
                                  }}
                                >
                                  Confirm Payment
                                </button>
                              )}
                              {is_sub_menu_action(
                                "rv_payment_confirm_cancel"
                              ) && (
                                <button
                                  className="menu border-0 purple bg-transparent f12-vw font-weight-bold btn-block m-0"
                                  onClick={() => {
                                    openModal();
                                    setMode("cancel-payment");
                                    setbookingDetail(item);
                                  }}
                                >
                                  Cancel Payment
                                </button>
                              )}
                            </div>
                          </div>
                        )
                      }
                    >
                      <div className="card-hms-list">
                        <div className="col-5 px-0 grey-text">Nomor Kamar</div>
                        <div className="col pr-0 f12-vw black-value">
                          {item?.room_number}
                        </div>
                      </div>
                      <div className="card-hms-list">
                        <div className="col-5 px-0 grey-text">Payment ID</div>
                        <div className="col pr-0 f12-vw black-value">
                          {item?.payment_id}
                        </div>
                      </div>
                      <div className="card-hms-list">
                        <div className="col-5 px-0 grey-text">Total Bayar</div>
                        <div className="col pr-0 f12-vw black-value">
                          Rp {priceSplit(item?.total_charge)}
                        </div>
                      </div>
                      <div className="card-hms-list">
                        <div className="col-5 px-0 grey-text">
                          Nomor Rekening
                        </div>
                        <div className="col pr-0 f12-vw black-value">
                          {item?.bank_accno ? (
                            <>
                              <div>{item?.to_bank_name}</div>
                              <div>{item?.bank_accno}</div>
                            </>
                          ) : (
                            "-"
                          )}
                        </div>
                      </div>
                      <div className="card-hms-list">
                        <div className="col-5 px-0 grey-text">
                          Batas Pembayaran
                        </div>
                        <div className="col pr-0 f12-vw black-value">
                          {item?.due_date ? (
                            <>
                              <div>
                                {getServerTimeWithoutDays(
                                  item?.due_date,
                                  timezone
                                )}
                              </div>
                              <div style={{ fontWeight: "bold" }}>
                                {getClientTime(item?.due_date, timezone) +
                                  " " +
                                  getTimezoneOffsetClient(timezone)}
                              </div>
                            </>
                          ) : (
                            "-"
                          )}
                        </div>
                      </div>
                      <div className="card-hms-list">
                        <div className="col-5 px-0 grey-text">Status</div>
                        <div className="col pr-0 f12-vw black-value">
                          <span
                            className={`text-center align-middle fw-600 ${changeCol(
                              item?.payment_status
                            )}`}
                          >
                            {paymentLabel(item?.payment_status)}
                          </span>
                        </div>
                      </div>
                    </MobileCardList>
                  );
                })
              ) : (
                <div style={{ position: "relative" }}>
                  <MobileEmptyData
                    errMessage={error}
                    loading={loading}
                    height="40vh"
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      ) : (
        <div
          className={`check-out-page mt-1 px-3 ${
            !isOpeningShift ? "" : "not-opening"
          }`}
        >
          <div className="d-flex flex-column px-3 py-1">
            <div className="col px-0">
              <div className="d-flex flex-row justify-content-between align-items-center">
                <div className="col-md-8 px-0">
                  <div className="d-flex flex-row">
                    <div className="col px-0 mr-1">
                      <label htmlFor="" className="f8-vw m-0 p-0">
                        Kode Booking
                      </label>
                      {searchInput(
                        "Cari Kode Booking",
                        search.booking_id,
                        (e) =>
                          setSearch({
                            ...search,
                            booking_id: generateBookingCode(e.target.value),
                          }),
                        handleEnter
                      )}
                    </div>
                    <div className="col px-0 mr-1">
                      <label htmlFor="" className="f8-vw m-0 p-0">
                        Payment ID
                      </label>
                      {searchInput(
                        "Cari Payment ID",
                        search.payment_id,
                        (e) =>
                          setSearch({
                            ...search,
                            payment_id: generateBookingCode(e.target.value),
                          }),
                        handleEnter
                      )}
                    </div>
                    <div className="col px-0 mr-1">
                      <label htmlFor="" className="f8-vw m-0 p-0">
                        Nomor Kamar
                      </label>
                      {searchInput(
                        "Cari Nomor Kamar",
                        search.room_number,
                        (e) =>
                          setSearch({
                            ...search,
                            room_number: e.target.value,
                          }),
                        handleEnter
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-md-2 pr-0 pl-5 mt-auto">
                  <div className="d-flex flex-column">
                    <button
                      className="btn btn-sm search-new rounded-input btn-block mt-auto f8-vw fw-500 text-white"
                      onClick={() => {
                        setListPayment([]);
                        getListPaymentConfirm();
                      }}
                      style={{
                        paddingTop: 7,
                        paddingBottom: 7,
                        backgroundColor: "#742B9E",
                      }}
                    >
                      Search
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="col px-0 mt-3">
              <div
                className="table-list table-responsive-sm scroll-table table-report"
                style={{
                  height: "25rem",
                }}
              >
                <table className="table table-sm scroll-table">
                  <thead className="f8-vw sticky-table">
                    <tr
                      className="text-black text-center text-white"
                      style={{ backgroundColor: "#0071A4" }}
                    >
                      <th className="align-middle py-2">Booking ID</th>
                      <th className="align-middle py-2">Payment ID</th>
                      <th className="align-middle py-2">
                        <div>Room</div>
                        <div>Number</div>
                      </th>
                      <th className="align-middle py-2">Total Bayar</th>
                      <th className="align-middle py-2">Nomor Rekening</th>
                      <th className="align-middle py-2">
                        <div>Batas</div>
                        <div>Pembayaran</div>
                      </th>
                      <th className="align-middle py-3">Status</th>
                      <th className="align-middle py-3">Action</th>
                    </tr>
                  </thead>
                  <tbody className="f8-vw">
                    {listPayment?.length > 0 ? (
                      <>
                        {listPayment?.map((item, index) => (
                          <Fragment key={index}>
                            <tr className={`f8-vw ${tableCol(index)}`}>
                              <td className="align-middle text-left font-weight-bold">
                                {item?.book_id}
                              </td>
                              <td className="align-middle text-center font-weight-bold">
                                {item?.payment_id}
                              </td>
                              <td className="align-middle text-center font-weight-bold">
                                {item?.room_number}
                              </td>
                              <td className="align-middle text-right">
                                {priceSplit(item?.total_charge)}
                              </td>
                              <td className="align-middle text-center">
                                <div>{item?.to_bank_name}</div>
                                <div className="fw-700">{item?.bank_accno}</div>
                              </td>
                              <td className="align-middle text-center">
                                {item?.due_date ? (
                                  <>
                                    <div>
                                      {getServerTimeWithoutDays(
                                        item?.due_date,
                                        timezone
                                      )}
                                    </div>
                                    <div
                                      className=""
                                      style={{ fontWeight: "bold" }}
                                    >
                                      {getClientTime(item?.due_date, timezone) +
                                        " " +
                                        getTimezoneOffsetClient(timezone)}
                                    </div>
                                  </>
                                ) : (
                                  "-"
                                )}
                              </td>
                              <td
                                className={`text-center align-middle ${changeCol(
                                  item?.payment_status
                                )}`}
                              >
                                {paymentLabel(item?.payment_status)}
                              </td>
                              <td className="align-middle text-center">
                                {isValidAccess([
                                  "rv_payment_confirm_process",
                                  "rv_payment_confirm_cancel",
                                ]) &&
                                  (item?.payment_status === 0 ||
                                    item?.payment_status === 2) && (
                                    <div className="combo-box">
                                      <div
                                        className="dropdown"
                                        style={{
                                          color: "#79319F",
                                        }}
                                      >
                                        <button
                                          className="bg-transparent dropdown-toggle border-0 blue btn-block f8-vw fw-700"
                                          type="button"
                                          id="dropdownMenu2"
                                          data-toggle="dropdown"
                                          aria-expanded="false"
                                        >
                                          Action
                                        </button>
                                        <div
                                          className="dropdown-menu dropdown-menu-right"
                                          aria-labelledby="dropdownMenu2"
                                        >
                                          <div className="d-flex flex-column">
                                            {is_sub_menu_action(
                                              "rv_payment_confirm_process"
                                            ) && (
                                              <button
                                                className="menu btn border-0 btn-sm purple f8-vw"
                                                onClick={() => {
                                                  openModal();
                                                  setMode("confirm-payment");
                                                  setbookingDetail(item);
                                                }}
                                              >
                                                Confirm Payment
                                              </button>
                                            )}
                                            {is_sub_menu_action(
                                              "rv_payment_confirm_cancel"
                                            ) && (
                                              <button
                                                className="menu btn border-0 btn-sm purple f8-vw"
                                                onClick={() => {
                                                  openModal();
                                                  setMode("cancel-payment");
                                                  setbookingDetail(item);
                                                }}
                                              >
                                                Cancel Payment
                                              </button>
                                            )}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  )}
                              </td>
                            </tr>
                          </Fragment>
                        ))}
                      </>
                    ) : (
                      <tr>
                        <td colSpan={10} style={{ padding: 0 }}>
                          <EmptyData
                            loading={loading}
                            errMessage={error}
                            text="Semua booking sudah dibayar"
                            sourceImg={"/img/booking/payment-confirmation.svg"}
                            height="20rem"
                          />
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      )}
      <div style={{ display: "none" }}>
        <PrintConfirm
          data={bookingDetail}
          launch_print={isPrint}
          dateTransfer={dateTransfer}
        />
      </div>
      {IS_MOBILE && (
        <div>
          <BottomSheet
            open={openButtonSheet}
            onDismiss={() => setOpenButtonSheet(false)}
            snapPoints={({ minHeight }) => minHeight}
            header={
              <div className="d-flex justify-content-between">
                <span className="purple f16-vw fw-600 px-2">Filter</span>
                <span
                  className="px-2"
                  onClick={() => setOpenButtonSheet(false)}
                >
                  <Icon icon={iconUrl.closeBold} color={"grey"} width={20} />
                </span>
              </div>
            }
            footer={
              <div className="d-flex justify-content-between">
                <div className="col-6 pl-2">
                  <button
                    className="btn btn-block btn-outline-purple f12-vw"
                    onClick={() => {
                      resetFilter();
                      setOpenButtonSheet(false);
                    }}
                  >
                    Reset
                  </button>
                </div>

                <div className="col-6 pr-2">
                  <button
                    className="btn btn-block btn-gradient f12-vw text-white"
                    onClick={() => {
                      setListPayment([]);
                      getListPaymentConfirm();
                      setOpenButtonSheet(false);
                    }}
                  >
                    Search
                  </button>
                </div>
              </div>
            }
          >
            <div className="mt-3" style={{ height: "17rem" }}>
              <div className="px-4 mt-3">
                <label htmlFor="" className="f12-vw m-0 p-0 fw-600">
                  Kode Booking
                </label>
                {mobileSearchInput(
                  "Masukkan Kode Booking",
                  search.booking_id,
                  (e) =>
                    setSearch({
                      ...search,
                      booking_id: generateBookingCode(e.target.value),
                    }),
                  handleEnter
                )}
              </div>
              <div className="px-4 mt-3">
                <label htmlFor="" className="f12-vw m-0 p-0 fw-600">
                  Payment ID
                </label>
                {mobileSearchInput(
                  "Cari Payment ID",
                  search.payment_id,
                  (e) =>
                    setSearch({
                      ...search,
                      payment_id: e.target.value,
                    }),
                  handleEnter
                )}
              </div>
              <div className="px-4 mt-3">
                <label htmlFor="" className="f12-vw m-0 p-0 fw-600">
                  Nomor Kamar
                </label>
                {mobileSearchInput(
                  "Cari Nomor Kamar",
                  search.room_number,
                  (e) =>
                    setSearch({
                      ...search,
                      room_number: e.target.value,
                    }),
                  handleEnter
                )}
              </div>
            </div>
          </BottomSheet>
        </div>
      )}
      <PopupModal modalIsOpen={isOpen}>
        {mode === "confirm-payment" ? (
          <PaymentConfirmPopup
            setfileUpload={setfileUpload}
            close={openModal}
            confirm={() => submitConfirmPayment()}
            fileUpload={fileUpload}
            setDateTransfer={setDateTransfer}
            dateTransfer={dateTransfer}
            showImg={showImg}
            setShowImg={setShowImg}
            loading={loadingSubmit}
          />
        ) : mode === "confirm-success" ? (
          <PaymentConfirmSuccess
            close={() => {
              openModal();
              getListPaymentConfirm();
              setfileUpload([]);
            }}
            detail={bookingDetail}
            fileUpload={fileUpload}
            isPrint={() => setIsPrint(true)}
          />
        ) : mode === "cancel-payment" || mode === "success-cancel" ? (
          <CancelPayment
            items={bookingDetail}
            back={reset}
            confirm={cancelPayment}
            mode={mode}
            loading={loadingSubmit}
            redirect={() => redirectCancel(bookingDetail?.ota_name)}
          />
        ) : (
          <>
            <NotifMessage
              closeModal={openModal}
              img={imgPopup}
              messageHeader={headerPopup}
              color="red"
              messageBody={error}
            />
            <ButtonClose
              close={() => {
                openModal();
              }}
              text="OK"
            />
          </>
        )}
      </PopupModal>
    </>
  );
};

export default PaymentConfirm;
