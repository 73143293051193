import React from "react";
import { HeaderName } from "../../../common/HeaderName";
import { IS_MOBILE } from "../../../common/Responsive";
import { priceSplit } from "../../../utils";

export const PaymentPopupSuccess = ({
  totalCharge,
  payment,
  charge,
  deposit,
  earlyCheckin,
  roomCharge,
  paymentSelected,
  bankSelected,
  confirm,
  close,
  realCharge,
  voucher_no,
  voucher_type,
  discount,
  additionalCharge,
  processType,
}) => {
  const detailPaymentMethod = (title, value) => {
    return (
      <div className={`d-flex flex-row mt-1 ${IS_MOBILE ? "f12-vw" : "f8-vw"}`}>
        <div className="col px-0">
          <span className="grey">{title}</span>
        </div>
        <div className="col px-0 fw-600">: {value}</div>
      </div>
    );
  };

  const detailPaymentCharge = (title, value, color = "text-black") => {
    return (
      <div className={`d-flex flex-row mt-1 ${IS_MOBILE ? "f12-vw" : "f8-vw"}`}>
        <div className="col px-0">
          <span className="text-black">{title}</span>
        </div>
        <div className="col px-0">
          <div className="d-flex justify-content-between">
            <span className="text-black">Rp</span>
            <span className={color}>{priceSplit(value)}</span>
          </div>
        </div>
      </div>
    );
  };

  const detaiTotalPaid = (title, value, tax) => {
    return (
      <div className={`d-flex flex-row mt-1 ${IS_MOBILE ? "f12-vw" : "f8-vw"}`}>
        <div className="col px-0">
          <span className="text-black fw-700" style={{ fontWeight: 500 }}>
            {title}
          </span>
        </div>
        <div className="col px-0">
          <div className="d-flex justify-content-between">
            <div className="fw-700">Rp</div>
            <div className="">
              <div className="text-right fw-700" style={{ fontWeight: 500 }}>
                {priceSplit(value)}
              </div>
              {tax && (
                <div
                  className={`${IS_MOBILE ? "f8-vw" : "f6-vw"} grey`}
                  style={{
                    position: "relative",
                    right: 0,
                    top: 0,
                  }}
                >
                  Sudah termasuk pajak
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  };

  const redirectValidation = () => {
    return paymentSelected?.value === 2 ? confirm() : close();
  };

  return (
    <>
      {IS_MOBILE ? (
        <div className="d-flex flex-column p-2" style={{ width: "21rem" }}>
          <div className="col">
            <div className="d-flex justify-content-center">
              <img
                src="/img/success-payment/success-icon.svg"
                alt=""
                loading="lazy"
              />
            </div>
          </div>
          <div className="col">
            <div className="f16-vw font-weight-bold text-center">
              {paymentSelected?.value === 2
                ? "Pemesanan Berhasil"
                : "Pembayaran Berhasil"}
            </div>
            {paymentSelected?.value === 2 && (
              <>
                <div className="f12-vw text-center">
                  Lakukan Konfirmasi setelah melakukan pembayaran pada menu
                  "Konfirmasi Pembayaran"
                </div>
              </>
            )}
            <hr className="separator-blue my-2" />
          </div>
          <div className="col">
            <div className="d-flex flex-column">
              <div className="col px-0">
                <div className="">
                  <HeaderName text="Pembayaran" fontSize="f14-vw fw-700" />
                </div>
                {detailPaymentMethod(
                  "Metode Pembayaran",
                  paymentSelected?.label
                )}
                {paymentSelected?.value === 2 && (
                  <>
                    {detailPaymentMethod("Nama Bank", bankSelected?.bank_name)}
                    {detailPaymentMethod(
                      "Nomor Rekening",
                      bankSelected?.bank_accno
                    )}
                  </>
                )}
              </div>
              <div className="col px-0">
                <div className="mt-2">
                  <HeaderName
                    text="Informasi Tagihan"
                    fontSize="f14-vw fw-700"
                    color2="purple"
                  />
                  <div className="my-1">
                    {!!voucher_no && (
                      <div className="mb-2">
                        <div className="f12-vw">Kode Voucher</div>
                        <div className="f12-vw blue">{voucher_no}</div>
                      </div>
                    )}
                    {detailPaymentCharge(
                      "Total Harga Kamar",
                      voucher_type === 1 || processType === 12
                        ? roomCharge
                        : realCharge
                    )}
                    {processType !== 12 &&
                      detailPaymentCharge("Early Check In", earlyCheckin)}
                    {paymentSelected?.value === 3 &&
                      processType !== 12 &&
                      detailPaymentCharge("Deposit", deposit)}
                    {discount > 0 &&
                      detailPaymentCharge("Diskon", "-" + priceSplit(discount))}
                    {processType === 12 &&
                      detailPaymentCharge(
                        "Additional Charge",
                        priceSplit(additionalCharge)
                      )}
                    <hr className="separator-blue my-2" />
                    {detaiTotalPaid("Total Tagihan", totalCharge, true)}
                    {paymentSelected?.value === 2 &&
                      processType !== 12 &&
                      detaiTotalPaid("Deposit (Cash)", deposit, false)}
                  </div>
                  {paymentSelected?.value === 3 && (
                    <>
                      <hr className="separator-blue my-2" />
                      <div className="mt-2">
                        {detailPaymentCharge("Pembayaran Tunai", payment)}
                        {detailPaymentCharge("Kembalian", charge)}
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
            <hr className="separator-blue" />
          </div>
          <div className="col">
            <div className="d-flex justify-content-center">
              <div className="col-6">
                <button
                  className="btn f12-vw bg-green rounded-input text-white btn-block"
                  onClick={() => redirectValidation()}
                >
                  OK
                </button>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="d-flex flex-column p-2" style={{ width: "23rem" }}>
          <div className="col">
            <div className="d-flex justify-content-center">
              <img
                src="/img/success-payment/success-icon.svg"
                alt=""
                loading="lazy"
              />
            </div>
          </div>
          <div className="col">
            <div className="f12-vw font-weight-bold text-center">
              {paymentSelected?.value === 2
                ? "Pemesanan Berhasil"
                : "Pembayaran Berhasil"}
            </div>
            {paymentSelected?.value === 2 && (
              <>
                <div className="f8-vw text-center">
                  Lakukan Konfirmasi setelah melakukan pembayaran
                </div>
                <div className="f8-vw text-center">
                  pada menu "Konfirmasi Pembayaran"
                </div>
              </>
            )}

            <hr className="separator-blue" />
          </div>
          <div className="col">
            <div className="d-flex flex-column">
              <div className="col pl-0">
                <div className="">
                  <HeaderName text="Pembayaran" fontSize="f10-vw fw-700" />
                </div>
                {detailPaymentMethod(
                  "Metode Pembayaran",
                  paymentSelected?.label
                )}
                {paymentSelected?.value === 2 && (
                  <>
                    {detailPaymentMethod("Nama Bank", bankSelected?.bank_name)}
                    {detailPaymentMethod(
                      "Nomor Rekening",
                      bankSelected?.bank_accno
                    )}
                  </>
                )}
              </div>
              <div className="col px-0">
                <div className="mt-2">
                  <HeaderName
                    text="Informasi Tagihan"
                    fontSize="f10-vw fw-700"
                    color2="purple"
                  />
                  <div className="">
                    {!!voucher_no && (
                      <div className="mb-2">
                        <div className="f8-vw">Kode Voucher</div>
                        <div className="f8-vw blue">{voucher_no}</div>
                      </div>
                    )}
                    {detailPaymentCharge(
                      "Total Harga Kamar",
                      voucher_type === 1 || processType === 12
                        ? roomCharge
                        : realCharge
                    )}
                    {processType !== 12 &&
                      detailPaymentCharge("Early Check In", earlyCheckin)}
                    {paymentSelected?.value === 3 &&
                      processType !== 12 &&
                      detailPaymentCharge("Deposit", deposit)}
                    {discount > 0 &&
                      detailPaymentCharge("Diskon", "-" + priceSplit(discount))}
                    {processType === 12 &&
                      detailPaymentCharge(
                        "Additional Charge",
                        priceSplit(additionalCharge)
                      )}
                    <hr className="separator-blue my-2" />
                    {detaiTotalPaid("Total Tagihan", totalCharge, true)}
                    {paymentSelected?.value === 2 &&
                      processType !== 12 &&
                      detaiTotalPaid("Deposit (Cash)", deposit, false)}
                  </div>
                  {paymentSelected?.value === 3 && (
                    <>
                      <hr className="separator-blue my-2" />
                      <div className="mt-2">
                        {detailPaymentCharge("Pembayaran Tunai", payment)}
                        {detailPaymentCharge("Kembalian", charge)}
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
            <hr className="separator-blue" />
          </div>
          <div className="col">
            <div className="d-flex justify-content-center">
              <div className="col-6">
                <button
                  className="btn btn-sm f8-vw bg-green rounded-input text-white btn-block"
                  onClick={() => redirectValidation()}
                >
                  OK
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
