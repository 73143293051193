import React from "react";
import Select from "react-select";

function SelectOpt(props) {
  const customStyles = {
    valueContainer: (base) => ({
      ...base,
      position: "relative",
      bottom: 5,
      alignItems: "center",
    }),
    dropdownIndicator: (base) => ({
      ...base,
      color: "#742b9e",
      position: "relative",
      bottom: 4,
      ":hover": {
        color: "#742b9e",
      },
    }),
    loadingIndicator: (base) => ({
      ...base,
      color: "#742b9e",
      position: "relative",
      bottom: 4,
    }),
    clearIndicator: (base) => ({
      ...base,
      position: "relative",
      bottom: 4,
      left: 5,
      padding: 0,
      color: "#742b9e",
      ":hover": {
        color: "#742b9e",
      },
    }),
    indicatorSeparator: (base) => ({
      ...base,
      display: "none",
    }),
    control: (base) => ({
      ...base,
      borderRadius: "5px",
      boxShadow: "none",
      fontSize: props.fontSize ? props.fontSize : "calc(6pt + 0.312vw)",
      height: props.height ? props.height : "calc(1.5em + 0.75rem + 2px)",
      minHeight: props.height ? props.height : "calc(1.5em + 0.75rem + 2px)",
      borderColor: props.borderColor ? props.borderColor : "rgb(211,211,211)",
      alignItems: "center",
    }),
    placeholder: (base) => ({
      ...base,
      position: "relative",
      top: 3,
      bottom: props.position ? props.position : 0,
      color: "#b7b7b7",
      whiteSpace: "nowrap",
    }),
    input: (base) => ({
      ...base,
      position: "relative",
      bottom: 0,
      display: "flex",
      alignItems: "center",
      fontSize: props.fontSize ? props.fontSize : "calc(6pt + 0.312vw)",
      color: "grey",
    }),
    singleValue: (base) => ({
      ...base,
      position: "relative",
      top: 3,
      bottom: 0,
      display: "flex",
      alignItems: "center",
      fontSize: props.fontSize ? props.fontSize : "calc(6pt + 0.312vw)",
      color: "black",
      fontWeight: 400,
    }),
    menu: (base) => ({
      ...base,
      fontSize: props.fontSize ? props.fontSize : "calc(6pt + 0.312vw)",
      color: "grey",
      zIndex: 10,
    }),
    menuList: (base) => ({
      ...base,
      maxHeight: props.menuHeight ? props.menuHeight : "auto",
    }),
    option: (base, { isSelected }) => ({
      ...base,
      backgroundColor: isSelected ? "#62148f" : "ffffff",
      color: isSelected ? "#ffffff" : "#000000",
      fontWeight: isSelected ? "bold" : "normal",
      fontSize: props.fontSize ? props.fontSize : "calc(6pt + 0.312vw)",
      "&:hover": {
        color: isSelected ? "#ffffff" : "#000000",
        backgroundColor: isSelected ? "#62148f" : "#EEE4F3",
      },
    }),
  };

  return (
    <>
      <Select
        options={props.optionsValue}
        menuPlacement={props.placement ? "top" : "bottom"}
        value={props.value && props.value}
        defaultValue={props.defaultValue && props.defaultValue}
        isSearchable={props.isSearchable ? props.isSearchable : false}
        styles={customStyles}
        isOptionSelected={true}
        classNamePrefix={"react-select"}
        placeholder={props.placeholder}
        onChange={props.onChange}
        autoFocus={props.focus ? props.focus : false}
        isClearable={props.clear && props.clear}
        isDisabled={props.disabled ? props.disabled : false}
        isLoading={props.loading ? props.loading : false}
        formatOptionLabel={
          !!props?.formatOptionLabel ? props.formatOptionLabel : null
        }
        openMenuOnFocus={false}
      />
    </>
  );
}

export default SelectOpt;
