import { Icon } from "@iconify/react";
import React from "react";

export const TabLink = (props) => {
  return (
    <li className="col-6 tab-li">
      {!props.isOpen ? (
        <a
          className={props.tabClass}
          id={props.tabMenus + "-tab"}
          data-toggle="tab"
          href={"#" + props.tabMenus}
          role="tab"
          aria-controls={props.tabMenus}
          aria-selected="true"
          onClick={props.onClick}
        >
          <Icon icon={props.icon} className="mx-2" width={18} />
          {props.tabName}
        </a>
      ) : (
        <div
          className={props.tabClass}
          id={props.tabMenus + "-tab"}
          data-toggle="tab"
          role="tab"
          aria-controls={props.tabMenus}
          aria-selected="true"
          style={{ border: "none" }}
        >
          <Icon icon={props.icon} className="mx-2" width={18} />
          {props.tabName}
        </div>
      )}
    </li>
  );
};
