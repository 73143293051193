import "./styles/HMS_styles.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import { Redirect, Route } from "react-router-dom";
import Banner from "./pages/Data/Banner";
import Info from "./pages/Data/Info";
import BreadcrumbProvider from "./common/BreadcrumbContext";
import ProtectedPage from "./components/auth/ProtectedPage";
import { HMSContent } from "./common/routerPage";
import { IS_MOBILE } from "./common/Responsive";
import ScrollToTop from "./utils/ScrollToTop";
import { hideBanner } from "./utils/hide-banner";
import Sidebar from "./components/Sidebar/Sidebar";
import { MobileSideBar } from "./components/Sidebar/MobileSideBar";
import { MobileBreadcrumb } from "./common/MobileBreadcrumb";
// import { useCheckBrowser } from "./hook/useCheckBrowser";

function App() {
  // const valid = useCheckBrowser();
  // console.log(valid);
  return (
    <>
      {IS_MOBILE ? (
        <>
          <ProtectedPage>
            <BreadcrumbProvider>
              <ScrollToTop>
                <MobileSideBar />
                <div className="my-2">
                  <MobileBreadcrumb />
                </div>
                <div>
                  <HMSContent />
                </div>
              </ScrollToTop>
            </BreadcrumbProvider>
          </ProtectedPage>
        </>
      ) : (
        <ProtectedPage>
          <BreadcrumbProvider>
            <div className="row no-gutters bg-white">
              <div className="col-md-2half">
                <Sidebar />
              </div>
              <div className="col-md-9half">
                <div className="d-flex flex-column">
                  <Info />
                  {!hideBanner() && (
                    <>
                      <Banner />

                      <div className="px-5">
                        <hr className="separator-grey my-2" />
                      </div>
                    </>
                  )}

                  <div className="col">
                    <HMSContent />
                  </div>
                </div>
              </div>
            </div>
          </BreadcrumbProvider>
        </ProtectedPage>
      )}

      <Route exact path="/">
        <Redirect to="/login" />
      </Route>
    </>
  );
}

export default App;
