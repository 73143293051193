import { Icon } from "@iconify/react";
import React, { useEffect, useRef } from "react";
import ReactToPrint, { useReactToPrint } from "react-to-print";
import { iconUrl } from "../config";
import { createLineDash } from "./common/printer-helper";

export const Print = ({ data, launch_print, children }) => {
  // const { userProfile } = useContext(BreadcrumbContext);
  let componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  useEffect(() => {
    if (launch_print) {
      handlePrint();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [launch_print]);
  return (
    <div>
      <ReactToPrint
        content={() => componentRef.current}
        pageStyle={{ margin: 0, padding: 0 }}
      />
      <div ref={componentRef} className="receipt-print">
        <div
          className="d-flex justify-content-center"
          style={{ padding: "4px 0" }}
        >
          <img
            src={"/img/print-logo/logo.jpg"}
            alt={"logo"}
            className="img-print"
          />
        </div>
        {children}
        {createLineDash()}
        <p className="p-print txt-print-footer">
          Terima kasih atas kepercayaannya
        </p>
        <p className="p-print txt-print-footer">Memilih ruangnyaman.id</p>
        <p className="p-print txt-print-tagline">#BeneranNyaman</p>
        {createLineDash()}
        <table className="table-print-contact">
          <tbody>
            <tr className="rmp txt-print-contact">
              <td>
                <span>
                  <Icon
                    icon={iconUrl.whatsappIcon}
                    fontSize={"8pt"}
                    style={{
                      top: "1px",
                      position: "relative",
                    }}
                  />{" "}
                  0818 22 88 28
                </span>

                <span style={{ marginLeft: "10px" }}>
                  <Icon
                    icon={iconUrl.envelopeIcon}
                    fontSize={"8pt"}
                    style={{ top: "1px", position: "relative" }}
                  />{" "}
                  info@ruangnyaman.id
                </span>
              </td>
            </tr>
          </tbody>
        </table>
        <p className="p-print txt-print-h3">ruangnyaman.id</p>
      </div>
    </div>
  );
};
