import React from "react";
import { Fragment } from "react";
import { NavLink } from "react-router-dom";
import { checkMenuPath } from "../../utils";
import { is_sub_menu_action } from "../../utils/uac";

export const Menu = ({
  collapseClass,
  menuData,
  id,
  dataParent = "#accordionMenu",
  children,
  close,
}) => {
  return (
    <div
      id={id}
      className={collapseClass}
      aria-labelledby={id}
      data-parent={dataParent}
    >
      <div className="py-1 ml-3 menu">
        {menuData?.map((item, i) => {
          return (
            <Fragment key={i}>
              {is_sub_menu_action(item?.acl) && (
                <li
                  className={`item-list pl-3 ml-3 py-1 w-100 menu-hover ${checkMenuPath(
                    item?.linkName
                  )}`}
                >
                  <NavLink
                    to={item?.linkName}
                    className={item?.cName}
                    onClick={close}
                  >
                    <div className="text-menu fw-500 w-100">
                      {item?.menuName}
                    </div>
                  </NavLink>
                </li>
              )}
            </Fragment>
          );
        })}
        {children}
      </div>
    </div>
  );
};
