import React from "react";
import { HeaderName } from "../../../common/HeaderName";
import { dateFormat, getDayDuration, showMoreGuest } from "../../../utils";

export const WalkinDetailPopup = ({
  detail,
  purposeSelected,
  guestTypeSelected,
  close,
  confirm,
  guestName,
  roomNumber,
}) => {
  const detailInformation = (title, value) => {
    return (
      <div className="d-flex flex-row f7-vw mt-1">
        <div className="col-5 px-0">
          <span className="grey">{title}</span>
        </div>
        <div className="col px-0">
          <span className="text-black ">: {value ? value : "-"}</span>
        </div>
      </div>
    );
  };
  return (
    <div className="d-flex flex-column f7-vw p-3">
      <div className="col">
        <div className="purple font-weight-bold f10-vw">
          Konfirmasi Pemesanan
        </div>
        <hr className="separator-grey my-2" />
      </div>
      <div className="col px-0">
        <div className="d-flex flex-row">
          <div className="col">
            <div className="">
              <HeaderName text="Informasi Tamu" fontSize="f9-vw" />
            </div>
            <div className="my-1">
              {detailInformation("Nama OTA", "HMS")}
              {detailInformation("Nama Pemesan", detail?.guestName.join(", "))}
              {detailInformation("Nomor Ponsel", "+" + detail?.phoneNumber)}
              {detailInformation("Email", showMoreGuest(detail?.email))}
              {detailInformation("Nama Tamu", showMoreGuest(guestName))}
            </div>
          </div>
          <div className="col">
            <div className="">
              <HeaderName text="Informasi Pemesanan" fontSize="f9-vw" />
            </div>
            <div className="my-1">
              {detailInformation("Check In", dateFormat(detail?.checkin))}
              {detailInformation("Check Out", dateFormat(detail?.checkout))}
              {detailInformation(
                "Durasi",
                getDayDuration(detail?.checkin, detail?.checkout) + " Malam"
              )}
              {detailInformation("Tipe Kamar", detail?.roomType)}
              {detailInformation(
                "Tamu & Kamar",
                detail?.guest + " Tamu, " + detail?.room + " Kamar"
              )}
              {detailInformation("No Kamar", roomNumber)}
              {detailInformation("Purpose", purposeSelected?.label)}
              {detailInformation("Guest Type", guestTypeSelected?.label)}
            </div>
          </div>
        </div>
        <div className="px-3">
          <hr className="separator-grey my-2" />
          <div className="text-center my-2">
            Periksa kembali detail Pemesanan anda , klik confirm untuk
            melanjutkan
          </div>
        </div>
      </div>
      <div className="col">
        <div className="d-flex justify-content-between ">
          <div className="col-2 px-0">
            <button
              className="btn btn-sm btn-block bg-secondCol text-white rounded-input f8-vw"
              onClick={() => close()}
            >
              Back
            </button>
          </div>
          <div className="col-2 px-0">
            <button
              className="btn btn-sm btn-block bg-green text-white rounded-input f8-vw"
              onClick={() => confirm()}
            >
              Confirm
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
