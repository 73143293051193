import React from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

function InputPhone({ value, setValue, classInput, disabled = false }) {
  return (
    <>
      <PhoneInput
        country={"id"}
        countryCodeEditable={false}
        value={value}
        onChange={setValue}
        inputClass={classInput ? classInput : ""}
        disabled={disabled}
      />
    </>
  );
}

export default InputPhone;
