import React, { useContext, useEffect, useRef, useState } from "react";
import SelectOpt from "../../common/SelectOpt";
import InputPhone from "../../common/InputPhone";
import { InputCounter } from "./common/InputCounter";
import { Icon } from "@iconify/react";
import ReactDatePicker from "react-datepicker";
import {
  addDays,
  capitalizeLetter,
  formatDate,
  slicePhoneNumber,
} from "../../utils";
import {
  addDataPict,
  getData,
  getErr,
  safeErrCode,
} from "../../utils/api-request";
import PopupModal from "../../common/PopupModal";
import { ConfirmFormLossFound } from "./ConfirmFormLossFound";
import NotifMessage, { ButtonClose } from "../../common/Notif";
import { isEmpty } from "../../utils/index";
import { filterSelect, reportType } from "./common/utils";
import { BreadcrumbContext } from "../../common/BreadcrumbContext";
import { is_sub_menu_action } from "../../utils/uac";
import { iconUrl } from "../../config";
import { CameraWebcam } from "../../common/CameraWebcam";
import { IS_MOBILE } from "../../common/Responsive";
import { MobileUploadFile } from "../../common/MobileUploadFile";
import { t } from "../../common/ErrorMessage";

const FONT_SIZE = IS_MOBILE ? "f12-vw" : "f8-vw";
const FONT_SIZE_TITLE = IS_MOBILE ? "f12-vw" : "f10-vw";
const FONT_SIZE_HEADER = IS_MOBILE ? "f14-vw" : "f12-vw";

const SuccessPopup = ({ close }) => {
  return (
    <div className="d-flex flex-column py-3" style={{ width: "20rem" }}>
      <div className="col">
        <div className="text-center">
          <img
            src="/img/lost-found/lost-found-success.svg"
            alt="success-icon"
            loading="lazy"
          />
        </div>
      </div>
      <div className="col">
        <div className="text-center">
          <div className={"fw-700 " + FONT_SIZE_HEADER}>
            Data Berhasil Disimpan
          </div>
          <div className={"mt-2 " + FONT_SIZE_TITLE}>
            Data akan disimpan di menu
          </div>
          <div className={FONT_SIZE_TITLE}>Lost & Found List</div>
        </div>
      </div>
      <div className="col">
        <div className="d-flex justify-content-center mt-3">
          <div className="col-6">
            <button
              className={
                "btn btn-sm btn-block bg-green text-white " + FONT_SIZE
              }
              onClick={() => close()}
            >
              OK
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

const LossFoundForm = () => {
  const [formLostFound, setFormLostFound] = useState({
    itemName: "",
    itemDetail: "",
    founderName: "",
    phoneNumber: "+62",
    dateLostFound: new Date(),
    dateCheckin: new Date(),
    dateCheckout: addDays(new Date(), 1),
  });
  const [isOpen, setIsOpen] = useState(false);
  const [mode, setMode] = useState("");
  const [imgPopup, setImgPopup] = useState("");
  const mounted = useRef(false);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [errorSubmit, setErrorSubmit] = useState("");
  const [headerPopup, setHeaderPopup] = useState("");
  const [reportTypeSelected, setReportTypeSelected] = useState([]);
  const [reportBySelected, setReportBySelected] = useState([]);
  const [itemLocationSelected, setItemLocationSelected] = useState([]);
  const [totalItem, setTotalItem] = useState(1);
  const [fileUpload, setFileUpload] = useState([]);
  const [showImage, setShowImage] = useState("");
  const [roomNumberList, setRoomNumberList] = useState([]);
  const [employeeList, setEmployeeList] = useState([]);
  const [employeeSelected, setEmployeeSelected] = useState([]);
  const [roomNumberSelected, setRoomNumberSelected] = useState([]);
  const [validateDisable, setValidateDisable] = useState(false);
  const { setpathLocation } = useContext(BreadcrumbContext);
  const [openDate, setOpenDate] = useState(false);

  const handleOpenDate = () => {
    setOpenDate(!openDate);
  };

  const reportBy = [
    { value: 1, label: "EMPLOYEE" },
    { value: 2, label: "GUEST" },
    { value: 3, label: "OTHERS" },
  ];
  const itemLocation = [
    { value: 1, label: "KAMAR" },
    { value: 2, label: "LOBBY" },
    { value: 3, label: "PARKIRAN" },
    { value: 4, label: "RESTORAN" },
    { value: 5, label: "OTHERS" },
  ];

  const handleDateLostFound = (value) => {
    setFormLostFound({ ...formLostFound, dateLostFound: value });
  };
  const handleDateCheckin = (value) => {
    setFormLostFound({
      ...formLostFound,
      dateCheckin: value,
      dateCheckout: addDays(value, 1),
    });
  };
  const handleDateCheckout = (value) => {
    setFormLostFound({ ...formLostFound, dateCheckout: value });
  };

  const getEmployee = () => {
    let url = "/api/v1/list_employee";
    getData(url)
      .then((resp) => {
        if (mounted.current) {
          if (resp.data) {
            let selection = resp.data.map((item) => {
              return {
                value: item.user_id,
                label: item.full_name,
              };
            });
            setEmployeeList(selection);
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getRoomNumberList = () => {
    let url = "/api/v1/list_room";
    getData(url)
      .then((resp) => {
        if (mounted.current) {
          if (resp.data) {
            let roomList = resp.data.map((item) => {
              return {
                value: item.room_number,
                label: item.room_number,
              };
            });
            setRoomNumberList(roomList);
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const submitLostFound = () => {
    setLoadingSubmit(true);
    let url = "/api/v1/lossnfound";
    let payload = {
      nama_barang: formLostFound?.itemName,
      qty: totalItem,
      detail_barang: formLostFound?.itemDetail,
      report_type: reportTypeSelected?.value,
      photo_barang: fileUpload[0],
      reported_by_type: reportBySelected?.value,
      reported_by: formLostFound?.founderName,
      reported_by_contact: slicePhoneNumber(formLostFound?.phoneNumber),
      ts_lost_found: formatDate(formLostFound?.dateLostFound),
      lost_found_location: itemLocationSelected?.value,
      room_number: !isEmpty(roomNumberSelected)
        ? roomNumberSelected?.value
        : "",
      hand_over_to: employeeSelected?.value,
      check_in_date:
        reportTypeSelected?.value === 1
          ? formatDate(formLostFound?.dateCheckin)
          : "",
      check_out_date:
        reportTypeSelected?.value === 1
          ? formatDate(formLostFound?.dateCheckout)
          : "",
    };
    const formData = new FormData();
    for (const key in payload) {
      formData.append(key, payload[key]);
    }
    addDataPict(url, formData)
      .then((resp) => {
        setLoadingSubmit(false);
        if (resp.message === "Success") {
          setMode("success-add");
        } else {
          resp.json().then((err) => {
            setErrorSubmit(t(safeErrCode(err)));
          });
          setMode("error");
          setHeaderPopup("Data Gagal Tersimpan");
          setImgPopup("/img/lost-found/lost-found-error.svg");
        }
      })
      .catch((error) => {
        console.log(error);
        setLoadingSubmit(false);
        setErrorSubmit(t(getErr(error)));
        setMode("error");
        setHeaderPopup("Data Gagal Tersimpan");
        setImgPopup("/img/lost-found/lost-found-error.svg");
      });
  };

  const handleReportType = (val) => {
    setReportTypeSelected(val);
    setFormLostFound({
      ...formLostFound,
      itemName: "",
      itemDetail: "",
      founderName: "",
      phoneNumber: "+62",
      dateLostFound: new Date(),
      dateCheckin: new Date(),
      dateCheckout: addDays(new Date(), 1),
    });
    setShowImage("");
    setTotalItem(1);
    setFileUpload([]);
    setItemLocationSelected([]);
    setReportBySelected([]);
    setRoomNumberSelected([]);
    setEmployeeSelected([]);
  };
  const handleReportBy = (val) => {
    setReportBySelected(val);
  };
  const handleItemLocation = (val) => {
    if (val.value !== 1) {
      setRoomNumberSelected([]);
    }
    setItemLocationSelected(val);
  };

  const handleEmployee = (val) => {
    setEmployeeSelected(val);
  };
  const handleRoomNumber = (val) => {
    setRoomNumberSelected(val);
  };

  const handlePhone = (val) => {
    setFormLostFound({ ...formLostFound, phoneNumber: val });
  };
  const inputContainer = (
    inpValue,
    inpPlaceholder,
    value,
    onChange,
    type,
    required = true
  ) => {
    return (
      <div className="form-group">
        <label htmlFor="pemesan" className={"fw-600 " + FONT_SIZE}>
          {required && <span className="red">* </span>}
          {inpValue}
        </label>
        <input
          type={type ? type : "text"}
          placeholder={inpPlaceholder}
          className={`form-control ${FONT_SIZE}`}
          value={value}
          onChange={onChange}
        />
      </div>
    );
  };
  const handleTotalItem = (val) => {
    setTotalItem(Number(val.target.value.replace(/\D/g, "")));
  };

  const openModal = () => {
    setIsOpen(!isOpen);
  };

  const reset = () => {
    setFormLostFound({
      ...formLostFound,
      itemName: "",
      itemDetail: "",
      founderName: "",
      phoneNumber: "+62",
      dateLostFound: new Date(),
      dateCheckin: new Date(),
      dateCheckout: addDays(new Date(), 1),
    });
    setFileUpload([]);
    setItemLocationSelected([]);
    setReportBySelected(filterSelect(reportBy, 2));
    setReportTypeSelected(filterSelect(reportType, 1));
    setRoomNumberSelected([]);
    setEmployeeSelected([]);
    setTotalItem(1);
    setIsOpen(false);
    setErrorSubmit("");
    setHeaderPopup("");
    setImgPopup("");
    setMode("");
    setValidateDisable(false);
    setShowImage("");
  };

  const validateButton = (param) => {
    switch (param) {
      case 1:
        return (formLostFound?.itemName !== "" &&
          formLostFound?.founderName !== "" &&
          formLostFound?.itemDetail !== "" &&
          formLostFound?.phoneNumber?.length > 9 &&
          formLostFound?.phoneNumber.length < 17 &&
          itemLocationSelected?.value === 1 &&
          !isEmpty(roomNumberSelected) &&
          !isEmpty(reportBySelected)) ||
          (formLostFound?.itemName !== "" &&
            formLostFound?.founderName !== "" &&
            formLostFound?.itemDetail !== "" &&
            formLostFound?.phoneNumber?.length > 9 &&
            formLostFound?.phoneNumber.length < 17 &&
            itemLocationSelected?.value > 1 &&
            !isEmpty(reportBySelected))
          ? true
          : false;
      case 2:
        return (reportTypeSelected?.value === 2 &&
          formLostFound?.itemName !== "" &&
          formLostFound?.founderName !== "" &&
          formLostFound?.itemDetail !== "" &&
          formLostFound?.phoneNumber?.length > 9 &&
          formLostFound?.phoneNumber.length < 17 &&
          reportBySelected?.value !== 2 &&
          itemLocationSelected?.value !== 1 &&
          !isEmpty(employeeSelected) &&
          !isEmpty(reportBySelected) &&
          fileUpload?.length > 0) ||
          (reportTypeSelected?.value === 2 &&
            formLostFound?.itemName !== "" &&
            formLostFound?.founderName !== "" &&
            formLostFound?.itemDetail !== "" &&
            formLostFound?.phoneNumber?.length > 9 &&
            formLostFound?.phoneNumber.length < 17 &&
            reportBySelected?.value !== 2 &&
            itemLocationSelected?.value === 1 &&
            !isEmpty(roomNumberSelected) &&
            !isEmpty(employeeSelected) &&
            !isEmpty(reportBySelected) &&
            fileUpload?.length > 0) ||
          (reportTypeSelected?.value === 2 &&
            formLostFound?.itemName !== "" &&
            formLostFound?.founderName !== "" &&
            formLostFound?.itemDetail !== "" &&
            formLostFound?.phoneNumber?.length > 9 &&
            formLostFound?.phoneNumber.length < 17 &&
            reportBySelected?.value === 2 &&
            !isEmpty(itemLocationSelected) &&
            !isEmpty(roomNumberSelected) &&
            !isEmpty(employeeSelected) &&
            !isEmpty(reportBySelected) &&
            fileUpload?.length > 0)
          ? true
          : false;
      default:
        break;
    }
  };

  const validateDisableForm = () => {
    return reportTypeSelected?.value === 2 && reportBySelected?.value !== 2
      ? setValidateDisable(true)
      : setValidateDisable(false);
  };

  const handleCalendarOpen = () => {
    document.addEventListener(
      "touchstart",
      (event) => {
        event.stopPropagation();
      },
      true
    );
  };

  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);

  useEffect(() => {
    getEmployee();
  }, []);

  useEffect(() => {
    getRoomNumberList();
  }, []);

  useEffect(() => {
    if (reportTypeSelected?.value || reportBySelected?.value) {
      validateDisableForm();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reportTypeSelected, reportBySelected]);

  useEffect(() => {
    if (mounted.current) {
      setReportTypeSelected(filterSelect(reportType, 1));
    }
  }, []);

  useEffect(() => {
    if (reportTypeSelected?.value === 1) {
      setReportBySelected(filterSelect(reportBy, 2));
    } else {
      setReportBySelected([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reportTypeSelected]);

  useEffect(() => {
    setpathLocation("/lost-& found/lost-& found form");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {IS_MOBILE ? (
        <div className="px-2 mb-5">
          <div className="d-flex flex-column">
            <div className="col">
              <div className="form-group">
                <label htmlFor="type-room" className="f12-vw fw-600">
                  Tipe Laporan
                </label>
                <SelectOpt
                  optionsValue={reportType}
                  onChange={handleReportType}
                  placeholder={"Pilih Tipe Laporan"}
                  value={reportTypeSelected}
                  fontSize={"calc(8pt + 0.472vw)"}
                />
              </div>
            </div>
            <div className="col">
              <div className="form-group">
                <label htmlFor="type-room" className="f12-vw fw-600">
                  Dilaporkan oleh
                </label>
                <SelectOpt
                  optionsValue={
                    reportTypeSelected?.value === 1
                      ? [filterSelect(reportBy, 2)]
                      : reportBy
                  }
                  onChange={handleReportBy}
                  placeholder={"Dilaporkan oleh..."}
                  value={reportBySelected}
                  fontSize={"calc(8pt + 0.472vw)"}
                />
              </div>
            </div>
            <div className="col">
              {inputContainer(
                "Nama Barang",
                "Nama Barang",
                formLostFound.itemName,
                (e) =>
                  setFormLostFound({
                    ...formLostFound,
                    itemName: e.target.value,
                  }),
                "text",
                false
              )}
            </div>
            <div className="col">
              <div className="form-group">
                <label htmlFor="pemesan" className="f12-vw fw-600">
                  Jumlah
                </label>
                <InputCounter
                  counter={totalItem}
                  setCount={setTotalItem}
                  onChange={handleTotalItem}
                />
              </div>
            </div>
            <div className="col">
              <div className="form-group">
                <label htmlFor="note-inspection" className="f12-vw fw-600">
                  Detail Barang
                </label>
                <div
                  style={{
                    borderRadius: "3px",
                  }}
                >
                  <div>
                    <textarea
                      name="note"
                      id="note"
                      className="form-control f12-vw rounded-input"
                      style={{
                        borderColor: "lightgray",
                        resize: "none",
                        backgroundColor: "white",
                        height: "7.1rem",
                      }}
                      onChange={(e) =>
                        setFormLostFound({
                          ...formLostFound,
                          itemDetail: e.target.value,
                        })
                      }
                      value={formLostFound.itemDetail}
                      placeholder="Catatan..."
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="col">
              {inputContainer(
                "Nama Pelapor",
                "Nama Pelapor",
                formLostFound.founderName,
                (e) =>
                  setFormLostFound({
                    ...formLostFound,
                    founderName: e?.target?.value,
                  }),
                "text",
                false
              )}
            </div>
            <div className="col">
              <div className="form-group">
                <label htmlFor="pemesan" className="f12-vw fw-600">
                  Nomor Ponsel
                </label>
                <div className="group-input f12-vw grey">
                  <InputPhone
                    value={formLostFound?.phoneNumber}
                    setValue={handlePhone}
                  />
                </div>
              </div>
            </div>
            <div className="col">
              <div className="form-group">
                <label htmlFor="pemesan" className="f12-vw fw-600">
                  Tanggal Hilang/Ditemukan
                </label>
                <div
                  className=""
                  style={{
                    border: "1px solid #ced4da",
                    borderRadius: 4,
                    // padding: "4px 0",
                  }}
                  onClick={() => {
                    handleOpenDate();
                  }}
                >
                  <div className="d-flex px-2 f12-vw align-items-center">
                    <Icon
                      icon={iconUrl.calendarIcon}
                      color="#ef9403"
                      width={15}
                    />
                    <div className="">
                      <ReactDatePicker
                        selected={formLostFound.dateLostFound}
                        onChange={handleDateLostFound}
                        startDate={formLostFound.dateLostFound}
                        className={
                          "date-checkin-new f12-vw form-control text-left"
                        }
                        dateFormat="dd MMM yyyy"
                        maxDate={new Date()}
                        withPortal={true}
                        onCalendarOpen={() => handleCalendarOpen()}
                        onChangeRaw={(e) => {
                          e.preventDefault();
                        }}
                        onKeyDown={(e) => {
                          e.preventDefault();
                        }}
                        onFocus={(e) => e.target.blur()}
                        preventOpenOnFocus={true}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="form-group">
                <label htmlFor="type-room" className="f12-vw fw-600">
                  Lokasi Hilang/Ditemukan
                </label>
                <SelectOpt
                  optionsValue={itemLocation}
                  onChange={handleItemLocation}
                  placeholder={"Lokasi Hilang/Ditemukan"}
                  value={itemLocationSelected}
                  fontSize={"calc(8pt + 0.472vw)"}
                />
              </div>
            </div>
            <div className="col">
              <div className="form-group">
                <label htmlFor="type-room" className="f12-vw fw-600">
                  Diserahkan Kepada
                </label>
                <SelectOpt
                  optionsValue={employeeList}
                  onChange={handleEmployee}
                  placeholder={"Diserahkan Kepada"}
                  value={employeeSelected}
                  disabled={reportTypeSelected?.value === 1 ? true : false}
                  fontSize={"calc(8pt + 0.472vw)"}
                  menuHeight="10rem"
                />
              </div>
            </div>
            <div className="col">
              <div className="form-group">
                <div className="d-flex flex-row">
                  <div className="col-6 pl-0">
                    <label htmlFor="pemesan" className="f12-vw fw-600">
                      Tanggal Checkin
                    </label>
                    <div
                      className=""
                      style={{
                        border: "1px solid #ced4da",
                        borderRadius: 5,
                        backgroundColor: validateDisable ? "#ededed" : "white",
                      }}
                    >
                      <div className="d-flex px-2 f12-vw align-items-center justify-content-center">
                        <Icon
                          icon={iconUrl.calendarIcon}
                          color="#ef9403"
                          width={25}
                        />
                        <div className="">
                          {validateDisable ? (
                            <div className="px-3">-</div>
                          ) : (
                            <ReactDatePicker
                              selected={formLostFound.dateCheckin}
                              onChange={handleDateCheckin}
                              startDate={formLostFound.dateCheckin}
                              className={
                                "date-checkin-new f12-vw form-control text-left"
                              }
                              dateFormat="dd MMM yyyy"
                              withPortal={true}
                              onCalendarOpen={() => handleCalendarOpen()}
                              onChangeRaw={(e) => {
                                e.preventDefault();
                              }}
                              onKeyDown={(e) => {
                                e.preventDefault();
                              }}
                              onFocus={(e) => e.target.blur()}
                              preventOpenOnFocus={true}
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-6 pr-0">
                    <label htmlFor="pemesan" className="f12-vw fw-600">
                      Tanggal Checkout
                    </label>
                    <div
                      className=""
                      style={{
                        border: "1px solid #ced4da",
                        borderRadius: 5,
                        backgroundColor: validateDisable ? "#ededed" : "white",
                      }}
                    >
                      <div className="d-flex px-2 f12-vw align-items-center justify-content-center">
                        <Icon
                          icon={iconUrl.calendarIcon}
                          color="#ef9403"
                          width={25}
                        />
                        <div className="">
                          {validateDisable ? (
                            <div className="px-3">-</div>
                          ) : (
                            <ReactDatePicker
                              selected={formLostFound.dateCheckout}
                              onChange={handleDateCheckout}
                              endDate={formLostFound.dateCheckout}
                              className={
                                "date-checkin-new f12-vw form-control text-left"
                              }
                              dateFormat="dd MMM yyyy"
                              withPortal={true}
                              minDate={addDays(formLostFound.dateCheckin, 1)}
                              onCalendarOpen={() => handleCalendarOpen()}
                              onChangeRaw={(e) => {
                                e.preventDefault();
                              }}
                              onKeyDown={(e) => {
                                e.preventDefault();
                              }}
                              onFocus={(e) => e.target.blur()}
                              preventOpenOnFocus={true}
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col">
              <div className="form-group">
                <label htmlFor="type-room" className="f12-vw fw-600">
                  Room Number
                </label>
                <SelectOpt
                  optionsValue={roomNumberList}
                  onChange={handleRoomNumber}
                  placeholder={"Room Number"}
                  value={roomNumberSelected}
                  isSearchable={true}
                  fontSize={"calc(8pt + 0.472vw)"}
                  menuHeight="10rem"
                />
              </div>
            </div>
            <div className="col">
              <label htmlFor="upload-foto" className="f12-vw fw-600">
                Photo Barang
              </label>
              <MobileUploadFile
                setfileUpload={setFileUpload}
                title={"Add attachment"}
                height="9.5rem"
                fileUpload={fileUpload}
                setShowImg={setShowImage}
                showImg={showImage}
                fontSize="f12-vw"
              />
            </div>
          </div>

          <hr className="separator-blue mx-3" />
          <div className="d-flex justify-content-end">
            {is_sub_menu_action("lf_lost_found_process") && (
              <div className="col-6">
                <button
                  className={`btn btn-block py-2 f12-vw fw-700 text-white ${
                    validateButton(reportTypeSelected?.value)
                      ? "btn-gradient"
                      : "btn-secondary"
                  }`}
                  onClick={() => {
                    openModal();
                    setMode("confirm");
                  }}
                  disabled={
                    validateButton(reportTypeSelected?.value) ? false : true
                  }
                >
                  Next
                </button>
              </div>
            )}
          </div>
        </div>
      ) : (
        <div className="my-1 px-4">
          <div className="px-1">
            <div className="d-flex flex-row">
              <div className="col-4 pl-1">
                <div className="d-flex flex-column">
                  <div className="col px-0">
                    <div className="form-group d-flex flex-row">
                      <div className="col-6 pl-0 pr-2">
                        <label htmlFor="type-room" className="f8-vw fw-600">
                          Tipe Laporan
                        </label>
                        <SelectOpt
                          optionsValue={reportType}
                          onChange={handleReportType}
                          placeholder={"Pilih Tipe Laporan"}
                          value={reportTypeSelected}
                        />
                      </div>
                      <div className="col-6 pr-0 pl-2">
                        <label htmlFor="type-room" className="f8-vw fw-600">
                          Dilaporkan oleh
                        </label>
                        <SelectOpt
                          optionsValue={
                            reportTypeSelected?.value === 1
                              ? [filterSelect(reportBy, 2)]
                              : reportBy
                          }
                          onChange={handleReportBy}
                          placeholder={"Dilaporkan oleh..."}
                          value={reportBySelected}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col px-0">
                    {inputContainer(
                      "Nama Barang",
                      "Nama Barang",
                      formLostFound.itemName,
                      (e) =>
                        setFormLostFound({
                          ...formLostFound,
                          itemName: capitalizeLetter(e.target.value),
                        }),
                      "text",
                      false
                    )}
                  </div>
                  <div className="col px-0">
                    <div className="form-group">
                      <label htmlFor="pemesan" className="f8-vw fw-600">
                        Jumlah
                      </label>
                      <InputCounter
                        counter={totalItem}
                        setCount={setTotalItem}
                        onChange={handleTotalItem}
                      />
                    </div>
                  </div>
                  <div className="col px-0">
                    <label htmlFor="note-inspection" className="f8-vw fw-600">
                      Detail Barang
                    </label>
                    <div
                      style={{
                        borderRadius: "3px",
                      }}
                    >
                      <div>
                        <textarea
                          name="note"
                          id="note"
                          className="form-control f8-vw rounded-input"
                          style={{
                            borderColor: "lightgray",
                            resize: "none",
                            backgroundColor: "white",
                            height: "7.1rem",
                          }}
                          onChange={(e) =>
                            setFormLostFound({
                              ...formLostFound,
                              itemDetail: e.target.value,
                            })
                          }
                          value={formLostFound.itemDetail}
                          placeholder="Catatan..."
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-4 px-3">
                <div className="d-flex flex-column">
                  <div className="col px-0">
                    {inputContainer(
                      "Nama Pelapor",
                      "Nama Pelapor",
                      formLostFound.founderName,
                      (e) =>
                        setFormLostFound({
                          ...formLostFound,
                          founderName: capitalizeLetter(e.target.value),
                        }),
                      "text",
                      false
                    )}
                  </div>
                  <div className="col px-0">
                    <div className="form-group">
                      <label htmlFor="pemesan" className="f8-vw fw-600">
                        Nomor Ponsel
                      </label>
                      <div className="group-input f8-vw grey">
                        <InputPhone
                          value={formLostFound?.phoneNumber}
                          setValue={handlePhone}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col px-0">
                    <div className="form-group">
                      <label htmlFor="pemesan" className="f8-vw fw-600">
                        Tanggal Hilang/Ditemukan
                      </label>
                      <div
                        className=""
                        style={{
                          border: "1px solid #ced4da",
                          borderRadius: 4,
                          // padding: "4px 0",
                        }}
                        onClick={() => {
                          handleOpenDate();
                        }}
                      >
                        <div className="d-flex px-2 f8-vw align-items-center">
                          <Icon
                            icon={iconUrl.calendarIcon}
                            color="#ef9403"
                            width={15}
                          />
                          <div className="">
                            <ReactDatePicker
                              selected={formLostFound.dateLostFound}
                              onChange={handleDateLostFound}
                              startDate={formLostFound.dateLostFound}
                              className={"date-checkin-new f8-vw form-control "}
                              dateFormat="dd MMM yyyy"
                              open={openDate}
                              maxDate={new Date()}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col px-0">
                    <div className="form-group">
                      <label htmlFor="type-room" className="f8-vw fw-600">
                        Lokasi Hilang/Ditemukan
                      </label>
                      <SelectOpt
                        optionsValue={itemLocation}
                        onChange={handleItemLocation}
                        placeholder={"Lokasi Hilang/Ditemukan"}
                        value={itemLocationSelected}
                        placement={itemLocation?.length > 3 ? "top" : "auto"}
                      />
                    </div>
                  </div>
                  <div className="col px-0">
                    <div className="form-group">
                      <label htmlFor="type-room" className="f8-vw fw-600">
                        Diserahkan Kepada
                      </label>
                      <SelectOpt
                        optionsValue={employeeList}
                        onChange={handleEmployee}
                        placeholder={"Diserahkan Kepada"}
                        menuHeight="12rem"
                        value={employeeSelected}
                        placement={employeeList?.length > 7 ? "top" : "auto"}
                        disabled={
                          reportTypeSelected?.value === 1 ? true : false
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-4 pr-1">
                <div className="d-flex flex-column">
                  <div className="col px-0">
                    <div className="form-group">
                      <div className="d-flex flex-row">
                        <div className="col-6 pl-0">
                          <label htmlFor="pemesan" className="f8-vw fw-600">
                            Tanggal Checkin
                          </label>
                          <div
                            className=""
                            style={{
                              border: "1px solid #ced4da",
                              borderRadius: 5,
                              padding: "1px 0",
                              backgroundColor: validateDisable
                                ? "#ededed"
                                : "white",
                            }}
                          >
                            <div className="d-flex px-2 f8-vw align-items-center justify-content-center">
                              <Icon
                                icon={iconUrl.calendarIcon}
                                color="#ef9403"
                                width={15}
                              />
                              <div className="">
                                {validateDisable ? (
                                  <div style={{ padding: "5px 8px" }}>-</div>
                                ) : (
                                  <ReactDatePicker
                                    selected={formLostFound.dateCheckin}
                                    onChange={handleDateCheckin}
                                    startDate={formLostFound.dateCheckin}
                                    className={
                                      "date-checkin-new f8-vw form-control form-control-sm"
                                    }
                                    dateFormat="dd MMM yyyy"
                                  />
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-6 pr-0">
                          <label htmlFor="pemesan" className="f8-vw fw-600">
                            Tanggal Checkout
                          </label>
                          <div
                            className=""
                            style={{
                              border: "1px solid #ced4da",
                              borderRadius: 5,
                              padding: "1px 0",
                              backgroundColor: validateDisable
                                ? "#ededed"
                                : "white",
                            }}
                          >
                            <div className="d-flex px-2 f8-vw align-items-center justify-content-center">
                              <Icon
                                icon={iconUrl.calendarIcon}
                                color="#ef9403"
                                width={15}
                              />
                              <div className="">
                                {validateDisable ? (
                                  <div style={{ padding: "5px 8px" }}>-</div>
                                ) : (
                                  <ReactDatePicker
                                    selected={formLostFound.dateCheckout}
                                    onChange={handleDateCheckout}
                                    endDate={formLostFound.dateCheckout}
                                    minDate={addDays(
                                      formLostFound.dateCheckin,
                                      1
                                    )}
                                    className={
                                      "date-checkin-new f8-vw form-control form-control-sm"
                                    }
                                    dateFormat="dd MMM yyyy"
                                  />
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col px-0">
                    <div className="form-group">
                      <label htmlFor="type-room" className="f8-vw fw-600">
                        Room Number
                      </label>
                      <SelectOpt
                        optionsValue={roomNumberList}
                        onChange={handleRoomNumber}
                        placeholder={"Room Number"}
                        value={roomNumberSelected}
                        isSearchable={true}
                        menuHeight="15rem"
                      />
                    </div>
                  </div>
                  <div className="col px-0">
                    <label htmlFor="upload-foto" className="f8-vw fw-600">
                      Photo Barang
                    </label>
                    <CameraWebcam
                      setfileUpload={setFileUpload}
                      title={"Add attachment"}
                      height="9.5rem"
                      fileUpload={fileUpload}
                      setShowImg={setShowImage}
                      showImg={showImage}
                    />
                  </div>
                </div>
              </div>
            </div>
            <hr className="separator-grey my-3 mx-1" />
            <div className="d-flex justify-content-end">
              {is_sub_menu_action("lf_lost_found_process") && (
                <div className="col-2 pr-2 pl-3">
                  <button
                    className={`btn btn-block py-2 ml-1 f8-vw fw-700 text-white ${
                      validateButton(reportTypeSelected?.value)
                        ? "btn-gradient"
                        : "btn-secondary"
                    }`}
                    onClick={() => {
                      openModal();
                      setMode("confirm");
                    }}
                    disabled={
                      validateButton(reportTypeSelected?.value) ? false : true
                    }
                  >
                    Next
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      )}

      <PopupModal
        modalIsOpen={isOpen}
        width={mode === "confirm" ? "40rem" : "20rem"}
      >
        {mode === "confirm" ? (
          <ConfirmFormLossFound
            detail={formLostFound}
            reportType={reportTypeSelected}
            reportBy={reportBySelected}
            qty={totalItem}
            roomNumber={roomNumberSelected}
            founderName={employeeSelected}
            location={itemLocationSelected}
            fileSrc={fileUpload}
            submit={submitLostFound}
            close={openModal}
            loadingSubmit={loadingSubmit}
          />
        ) : mode === "success-add" ? (
          <SuccessPopup close={reset} />
        ) : (
          <>
            <NotifMessage
              closeModal={openModal}
              img={imgPopup}
              messageHeader={headerPopup}
              color="black"
              messageBody={errorSubmit}
            />
            <ButtonClose close={openModal} text="OK" />
          </>
        )}
      </PopupModal>
    </>
  );
};
export default LossFoundForm;
