import React from "react";
import { IS_MOBILE } from "../../common/Responsive";
import { priceSplit, showMoreGuest } from "../../utils";

const FONT_SIZE = IS_MOBILE ? "f12-vw" : "f8-vw";
const FONT_SIZE_HEADER = IS_MOBILE ? "f16-vw" : "f12-vw";

export const OpenShiftSuccess = ({
  shiftInfo,
  userProfile,
  lastBalance,
  realCash,
  noteBalance,
  close,
}) => {
  const detailInfo = (title, value) => {
    return (
      <div className="d-flex justify-content-between">
        <div
          className={"col-7 px-0 fw-500 " + FONT_SIZE}
          style={{ color: "#707070" }}
        >
          {title}
        </div>
        <div className={"col px-0 " + FONT_SIZE}>{value}</div>
      </div>
    );
  };
  return (
    <div className="px-2 py-3" style={{ width: "21rem" }}>
      <div className="text-center">
        <img
          src="/img/icon-shift/open-shift-success.svg"
          alt=""
          loading="lazy"
        />
      </div>
      <div className="text-center">
        <span className={"fw-700 " + FONT_SIZE_HEADER}>
          Opening Shift Berhasil
        </span>
      </div>
      <div className={"text-center " + FONT_SIZE}>
        <span>Data </span>
        <span className="fw-700">Opening {shiftInfo?.shift_name} </span>
        <span>berhasil disimpan</span>
      </div>
      <hr className="separator-blue mx-3" />
      <div className="d-flex flex-column">
        <div className="col">
          <span className={`purple fw-700 ${IS_MOBILE ? "f14-vw" : "f10-vw"}`}>
            Shift Detail
          </span>
        </div>
        <div className="col">
          {detailInfo("Nama", ": " + showMoreGuest(userProfile.nama))}
          {detailInfo(
            "Shift",
            shiftInfo?.shift_name ? ": " + shiftInfo?.shift_name : ": -"
          )}
          {detailInfo(
            "Saldo Awal",
            <span className="fw-700">: Rp {priceSplit(lastBalance)}</span>
          )}
          {detailInfo(
            "Real Cash From Last Shift",
            <span className="fw-700">: Rp {priceSplit(realCash)}</span>
          )}
          {noteBalance && (
            <div className="mt-2">
              <div
                className={"fw-500 " + FONT_SIZE}
                style={{ color: "#707070" }}
              >
                Catatan
              </div>
              <div className={"fw-500 " + FONT_SIZE}>{noteBalance}</div>
            </div>
          )}
        </div>
        <hr className="separator-blue mx-3" />
        <div className="col">
          <div className="d-flex justify-content-center">
            <div className="col-6">
              <button
                className={
                  "btn btn-sm btn-block bg-green text-white " + FONT_SIZE
                }
                onClick={() => close()}
              >
                OK
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
