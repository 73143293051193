import React from "react";
import { HeaderName } from "../../../common/HeaderName";
import { IS_MOBILE } from "../../../common/Responsive";
import { priceSplit } from "../../../utils";

export const PaymentConfirmSuccess = ({ detail, close, fileUpload }) => {
  const fontSize = IS_MOBILE ? "f12-vw" : "f8-vw";
  return (
    <div className="px-3 py-2" style={{ width: IS_MOBILE ? "21rem" : "24rem" }}>
      <div className="d-flex flex-column align-items-center justify-content-center">
        <div className="">
          <img
            src="/img/success-payment/success-icon.svg"
            alt=""
            width={100}
            loading="lazy"
          />
        </div>
        <div className="font-weight-bold text-center">
          <HeaderName
            text="Konfirmasi Pembayaran Berhasil"
            fontSize={IS_MOBILE ? "f14-vw" : "f12-vw"}
            color1="black"
            color2="black"
          />
        </div>
      </div>
      <div
        className="d-flex flex-column scroll-popup hide-scrollbar"
        style={{ maxHeight: "24rem" }}
      >
        <div className="col">
          <hr className="separator-blue my-2" />
          <div className="">
            <span className={"grey fw-600 " + fontSize}>Kode Booking</span>
          </div>
          <div className="">
            <span className={"fw-700 blue " + fontSize}>
              {detail?.book_id ? detail?.book_id : "-"}
            </span>
          </div>
        </div>
        <div className="px-3">
          <hr className="separator-blue my-1" />
        </div>
        <div className="col">
          <div className="d-flex align-items-center">
            <div className="col-5 px-0">
              <span className={"fw-700 " + fontSize}>Total Tagihan</span>
            </div>
            <div className="col-7 px-0">
              <div className="d-flex justify-content-between align-items-center">
                <div className={"col px-0 fw-700 " + fontSize}>Rp</div>
                <div className={"col  px-0 fw-700 text-right " + fontSize}>
                  {priceSplit(detail?.total_charge)}
                </div>
              </div>
            </div>
          </div>
        </div>
        {detail?.process_type !== 12 && (
          <div className="col">
            <div className="d-flex align-items-center">
              <div className="col-5 px-0">
                <span className={"fw-700 " + fontSize}>Deposit (Cash)</span>
              </div>
              <div className="col-7 px-0">
                <div className="d-flex justify-content-between align-items-center">
                  <div className={"col px-0 fw-700 " + fontSize}>Rp</div>
                  <div className={"col  px-0 fw-700 text-right " + fontSize}>
                    {priceSplit(detail?.deposit)}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        <div className="px-3">
          <hr className="separator-blue my-1" />
        </div>

        <div className="col mt-2">
          <label htmlFor="bukti" className={"my-0 " + fontSize}>
            Bukti Transfer
          </label>
          {fileUpload?.length > 0 && (
            <div>
              <img
                src={URL.createObjectURL(fileUpload[0])}
                alt=""
                style={{
                  width: "100%",
                  height: "auto",
                  objectFit: "contain",
                  borderRadius: 10,
                }}
                loading="lazy"
              />
            </div>
          )}
        </div>
      </div>
      <div className="col mt-3">
        <div className="d-flex justify-content-center">
          <div className="col-6">
            <button
              className={
                "btn bg-green btn-block font-weight-bold text-white " + fontSize
              }
              onClick={close}
            >
              OK
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
