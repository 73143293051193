import React, { useEffect, useRef, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { mobileSearchInput, searchInput } from "../booking/bookingHelper";
import { EmptyData, MobileEmptyData } from "../Empty/EmptyData";
import { TableBreakage } from "./TableBreakage";
import SelectOpt from "../../common/SelectOpt";
import { useContext } from "react";
import { BreadcrumbContext } from "../../common/BreadcrumbContext";
import { comboBoxDownloadReport } from "../../utils/helper";
import PopupModal from "../../common/PopupModal";
import { ModalDetail } from "./ModalDetail";
import history from "../../utils/history";
import {
  getCsvFile,
  getData,
  getErr,
  safeErrCode,
} from "../../utils/api-request";
import {
  addDays,
  formatDate,
  getServerTimeWithoutDays,
  grandTotalBreakage,
  isEmpty,
  priceSplit,
  showMoreGuest,
} from "../../utils/index";
import { Loading } from "../../common/Loading";
import { is_sub_menu_action } from "../../utils/uac";
import {
  DateRangeFilterMobile,
  DateRangeFilterNew,
} from "../../common/DateRange";
import { IS_MOBILE } from "../../common/Responsive";
import { MobileCardList } from "../../common/CardList";
import { Icon } from "@iconify/react";
import { iconUrl } from "../../config";
import { BottomSheet } from "react-spring-bottom-sheet";
import { t } from "../../common/ErrorMessage";

const BreakageReport = () => {
  const {
    roomType,
    setpathLocation,
    openButtonSheet,
    setOpenButtonSheet,
    setShowFilter,
  } = useContext(BreadcrumbContext);
  const [loadingList, setLoadingList] = useState(false);
  const [loadingListDetail, setLoadingListDetail] = useState(false);
  const [error, setError] = useState("");
  const [errorDetail, setErrorDetail] = useState("");
  const [breakageList, setBreakageList] = useState([]);
  const [breakageDetail, setBreakageDetail] = useState([]);
  const [hasMore, setHasMore] = useState(false);
  const [hasMoreDetail, setHasMoreDetail] = useState(false);
  const [bookingId, setBookingId] = useState("");
  const [guestname, setGuestname] = useState("");
  const [roomTypeSelected, setRoomTypeSelected] = useState([]);
  const [breakageDate, setBreakageDate] = useState(null);
  const [endBreakageDate, setEndBreakageDate] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [page, setPage] = useState(1);
  const [pageDetail, setPageDetail] = useState(1);
  const [IdDetail, setIdDetail] = useState("");
  const [totalBreakage, setTotalBreakage] = useState(0);
  const link = useRef(null);
  const mounted = useRef(false);

  const checkNextPage = () => {
    if (hasMore) {
      let pagi = page + 1;
      setPage(pagi);
      getBreakageReport(pagi, false, breakageDate, endBreakageDate);
    }
  };

  const checkDetailNextPage = () => {
    if (hasMoreDetail) {
      let pagi = pageDetail + 1;
      setPageDetail(pagi);
      getDetailBreakageReport(pagi, false);
    }
  };

  const handleBookingId = (val) => {
    setBookingId(val.target.value);
  };

  const handleGuestname = (val) => {
    setGuestname(val.target.value);
  };

  const reset = () => {
    setLoadingList(false);
    setLoadingListDetail(false);
    setError("");
    setErrorDetail("");
    setTotalBreakage(0);
    setIsOpen(false);
    setIdDetail("");
    setHasMoreDetail(false);
    setBreakageDetail([]);
  };

  const handleRoomTypeSelected = (val) => {
    if (!val) {
      setRoomTypeSelected([]);
    } else {
      setRoomTypeSelected(val);
    }
  };

  const getBreakageReport = (pagi, is_init, start, end) => {
    setLoadingList(true);
    const params = {
      ...(!!bookingId && { booking_id: bookingId.replace(" ", "") }),
      ...(!!start && { breakage_date: formatDate(start) }),
      ...(!!end && {
        endbreakage_date: formatDate(end),
      }),
      ...(!!guestname && { nama_tamu: guestname }),
      ...(!isEmpty(roomTypeSelected) && {
        room_type_id: roomTypeSelected.value,
      }),
      ...(!!pagi && { page: pagi }),
    };

    let paramsFormatted = null;

    if (!isEmpty(params)) {
      paramsFormatted = new URLSearchParams(params);
    }
    const endpoint = `/api/v1/report/breakage?${
      paramsFormatted !== null ? `&${paramsFormatted}` : ""
    }`;
    let datas = [];
    getData(endpoint)
      .then((resp) => {
        if (mounted.current) {
          if (resp.data) {
            setLoadingList(false);
            if (is_init) {
              datas = resp.data.data;
            } else {
              let x = [];
              x = breakageList;
              datas = [...x, ...resp.data.data];
            }
            setHasMore(resp.data.next_page);
            setBreakageList(datas);
          } else {
            setLoadingList(false);
            resp.json().then((err) => {
              setError(t(safeErrCode(err)));
              console.error(err);
            });
            setBreakageList([]);
          }
        }
      })
      .catch((error) => {
        let err = getErr(error);
        setLoadingList(false);
        setError(t(err));
        console.error(err);
        setBreakageList([]);
      });
  };

  const getDetailBreakageReport = (pagi, is_init) => {
    const url_endpoint = `/api/v1/report/breakage/${IdDetail}?page=${pagi}`;
    let datas = [];
    setLoadingListDetail(true);
    getData(url_endpoint)
      .then((resp) => {
        setLoadingListDetail(false);
        if (resp.data) {
          setLoadingListDetail(false);
          setIsOpen(true);
          if (is_init) {
            datas = resp.data.data;
          } else {
            let x = [];
            x = breakageDetail;
            datas = [...x, ...resp.data.data];
          }
          setHasMoreDetail(resp.data.next_page);
          setBreakageDetail(datas);
        } else {
          resp.json().then((err) => {
            setErrorDetail(t(safeErrCode(err)));
          });
        }
      })
      .catch((error) => {
        let err = getErr(error);
        setLoadingListDetail(false);
        setErrorDetail(t(err));
        console.log(err);
      });
  };

  const exportCSVFile = (e) => {
    if (
      link.current.href &&
      link.current.href.substr(link.current.href.length - 6) !== "no_use"
    ) {
      return;
    }
    if (link.current.href.substr(link.current.href.length - 6) === "no_use") {
      e.preventDefault();
    }
    const params = {
      ...(!!bookingId && { booking_id: bookingId.replace(" ", "") }),
      ...(!!breakageDate &&
        breakageDate !== null && { breakage_date: formatDate(breakageDate) }),
      ...(!!endBreakageDate &&
        endBreakageDate !== null && {
          endbreakage_date: formatDate(endBreakageDate),
        }),
      ...(!!guestname && { nama_tamu: guestname }),
      ...(!isEmpty(roomTypeSelected) && {
        room_type_id: roomTypeSelected.value,
      }),
    };

    let paramsFormatted = null;

    if (!isEmpty(params)) {
      paramsFormatted = new URLSearchParams(params);
    }
    const endpoint = `/api/v1/report/breakage?&csvoutput=1${
      paramsFormatted !== null ? `&${paramsFormatted}` : ""
    }`;
    getCsvFile(endpoint)
      .then((resp) => {
        let now = new Date();
        let filenameServ = "breakage-report-" + formatDate(now) + ".csv";
        if (
          resp.headers !== undefined &&
          resp.headers !== null &&
          resp.headers.length > 1
        ) {
          filenameServ = resp.headers
            .get("Content-Disposition")
            .split("filename=")[1];
        }
        let filename = filenameServ;
        // console.log("filename-serv-download", filenameServ);
        resp.blob().then((blob) => {
          let dx = new Blob([blob]);
          const href = window.URL.createObjectURL(dx);
          link.current.download = filename;
          link.current.href = href;
          link.current.click();
          link.current.download = "no_use";
          link.current.href = "no_use";
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleClear = () => {
    setBreakageDate(null);
    setEndBreakageDate(null);
  };

  const handleEnter = (e) => {
    if (e.key === "Enter") {
      setBreakageList([]);
      setPage(1);
      getBreakageReport(1, true, breakageDate, endBreakageDate);
    }
  };

  const resetFilter = () => {
    setBreakageDate(null);
    setEndBreakageDate(null);
    setBookingId("");
    setRoomTypeSelected([]);
    setPage(1);
  };

  useEffect(() => {
    setShowFilter(true);
    return () => {
      setShowFilter(false);
    };
  }, [setShowFilter]);

  useEffect(() => {
    setpathLocation(history.location.pathname);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);

  useEffect(() => {
    if (
      bookingId === "" &&
      isEmpty(roomTypeSelected) &&
      guestname === "" &&
      breakageDate === null &&
      endBreakageDate === null
    ) {
      getBreakageReport(1, true, breakageDate, endBreakageDate);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bookingId, breakageDate, guestname, roomTypeSelected]);

  useEffect(() => {
    if (!!IdDetail) {
      getDetailBreakageReport(1, true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [IdDetail]);

  useEffect(() => {
    let total = grandTotalBreakage(breakageDetail);
    setTotalBreakage(total);
  }, [breakageDetail]);
  return (
    <>
      {IS_MOBILE ? (
        <div className="d-flex flex-column px-3 py-1 mb-5">
          <div className="col px-0">
            {is_sub_menu_action("rp_breakage_report_export") && (
              <div className="d-flex">
                {comboBoxDownloadReport(link, exportCSVFile)}
              </div>
            )}
          </div>
          <div className="col px-1">
            <InfiniteScroll
              dataLength={breakageList.length}
              next={checkNextPage}
              hasMore={hasMore}
              loader={<Loading />}
              endMessage={
                breakageList?.length < 1 && (
                  <MobileEmptyData
                    loading={loadingList}
                    errMessage={error}
                    height="40vh"
                  />
                )
              }
            >
              {breakageList.map((item, i) => {
                return (
                  <MobileCardList
                    title={
                      <>
                        <div
                          className="f12-vw fw-500"
                          style={{ color: "#959595" }}
                        >
                          Kode Booking
                        </div>
                        <div>{item?.book_id}</div>
                      </>
                    }
                    fontSizeHeader="f12-vw"
                    key={i}
                  >
                    <div className="card-hms-list">
                      <div className="col-4 px-0 grey-text">
                        Tanggal Breakage
                      </div>
                      <div className="col pr-0 f12-vw black-value">
                        {getServerTimeWithoutDays(item?.ts)}
                      </div>
                    </div>
                    <div className="card-hms-list">
                      <div className="col-4 px-0 grey-text">Nama Tamu</div>
                      <div className="col pr-0 f12-vw black-value">
                        {showMoreGuest(item?.nama_tamu)}
                      </div>
                    </div>
                    <div className="card-hms-list">
                      <div className="col-4 px-0 grey-text">Nomor Kamar</div>
                      <div className="col pr-0 f12-vw black-value">
                        {item?.room_no}
                      </div>
                    </div>
                    <div className="card-hms-list">
                      <div className="col-4 px-0 grey-text">Tipe Kamar</div>
                      <div className="col pr-0 f12-vw black-value">
                        {item?.room_type_name}
                      </div>
                    </div>
                    <div className="card-hms-list">
                      <div className="col-4 px-0 grey-text">Bukti Setoran</div>
                      <div className="col pr-0 f12-vw">
                        <div
                          className="f12-vw fw-700 blue"
                          onClick={() => {
                            setIdDetail(item?.id);
                          }}
                        >
                          <span className="mr-1">
                            <Icon
                              icon={iconUrl.formMultiple20Filled}
                              width={20}
                            />
                          </span>
                          Lihat Detail
                        </div>
                      </div>
                    </div>
                    <div className="card-hms-list">
                      <div className="col-4 px-0 grey-text">Total Charge</div>
                      <div className="col pr-0 f12-vw black-value fw-700">
                        Rp {priceSplit(item?.total_compen)}
                      </div>
                    </div>
                  </MobileCardList>
                );
              })}
            </InfiniteScroll>
          </div>
        </div>
      ) : (
        <div className="d-flex flex-column px-4 py-1">
          <div className="col px-2">
            <div className="d-flex flex-row justify-content-between">
              <div className="col-md-9 px-0">
                <div className="d-flex flex-row">
                  <div className="col px-0 mr-2">
                    <label htmlFor="" className="f8-vw m-0 p-0">
                      Tanggal Breakage
                    </label>
                    <DateRangeFilterNew
                      start={breakageDate}
                      end={endBreakageDate}
                      handleStart={(e) => {
                        setBreakageDate(e);
                        setEndBreakageDate(addDays(e, 1));
                      }}
                      handleEnd={(e) => setEndBreakageDate(e)}
                      minDate={true}
                      isClear={true}
                      handleClear={handleClear}
                    />
                  </div>
                  <div className="col px-0 mr-2">
                    <label htmlFor="" className="f8-vw m-0 p-0">
                      Booking ID
                    </label>
                    {searchInput(
                      "Cari Booking ID ",
                      bookingId,
                      (e) => handleBookingId(e),
                      handleEnter
                    )}
                  </div>
                  <div className="col px-0 mr-2">
                    <label htmlFor="" className="f8-vw m-0 p-0">
                      Nama Tamu
                    </label>
                    {searchInput(
                      "Cari Nama Tamu",
                      guestname,
                      (e) => handleGuestname(e),
                      handleEnter
                    )}
                  </div>
                  <div className="col px-0 mr-2">
                    <label htmlFor="" className="f8-vw m-0 p-0">
                      Room Type
                    </label>
                    <SelectOpt
                      optionsValue={roomType}
                      onChange={handleRoomTypeSelected}
                      placeholder={"Pilih Room Type"}
                      value={roomTypeSelected}
                      clear={true}
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-2 pr-0 pl-5 mt-auto">
                <div className="d-flex flex-column">
                  <button
                    className="btn btn-sm search-new rounded-input btn-block mt-auto f8-vw fw-500 text-white"
                    onClick={() => {
                      setBreakageList([]);
                      setPage(1);
                      getBreakageReport(1, true, breakageDate, endBreakageDate);
                    }}
                    style={{
                      paddingTop: 7,
                      paddingBottom: 7,
                      backgroundColor: "#742B9E",
                    }}
                  >
                    Search
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="col px-0">
            {is_sub_menu_action("rp_breakage_report_export") && (
              <div className="mt-2">
                <div className="d-flex">
                  {comboBoxDownloadReport(link, exportCSVFile)}
                </div>
              </div>
            )}
          </div>

          <div className="col px-2 mt-0">
            <InfiniteScroll
              dataLength={breakageList.length}
              next={checkNextPage}
              hasMore={hasMore}
              loader={<Loading />}
              endMessage={
                breakageList.length < 1 && (
                  <EmptyData
                    loading={loadingList}
                    errMessage={error}
                    height="18rem"
                  />
                )
              }
              height={"50vh"}
              className="scroll-table px-0 mt-2 table-border-line"
            >
              <TableBreakage
                list={breakageList}
                setIdDetail={setIdDetail}
                errMessage={error}
                loadingList={loadingList}
              />
            </InfiniteScroll>
          </div>
        </div>
      )}

      {IS_MOBILE && (
        <div>
          <BottomSheet
            open={openButtonSheet}
            onDismiss={() => setOpenButtonSheet(false)}
            snapPoints={({ maxHeight }) => maxHeight}
            header={
              <div className="d-flex justify-content-between">
                <span className="purple f16-vw fw-600 px-2">Filter</span>
                <span
                  className="px-2"
                  onClick={() => setOpenButtonSheet(false)}
                >
                  <Icon icon={iconUrl.closeBold} color={"grey"} width={20} />
                </span>
              </div>
            }
            footer={
              <div className="d-flex justify-content-between">
                <div className="col-6 pl-2">
                  <button
                    className="btn btn-block btn-outline-purple f12-vw"
                    onClick={() => {
                      resetFilter();
                      setOpenButtonSheet(false);
                      getBreakageReport(1, true, null, null);
                    }}
                  >
                    Reset
                  </button>
                </div>
                <div className="col-6 pr-2">
                  <button
                    className="btn btn-block btn-gradient f12-vw text-white"
                    onClick={() => {
                      setOpenButtonSheet(false);
                      getBreakageReport(1, true, breakageDate, endBreakageDate);
                    }}
                  >
                    Search
                  </button>
                </div>
              </div>
            }
          >
            <div className="mt-3" style={{ height: "30rem" }}>
              <div className="px-4 mt-2 date-wrapper">
                <label htmlFor="" className="f12-vw m-0 p-0">
                  Periode Shift
                </label>
                <DateRangeFilterMobile
                  start={breakageDate}
                  end={endBreakageDate}
                  handleStart={(e) => setBreakageDate(e)}
                  handleEnd={(e) => setEndBreakageDate(e)}
                  minDate={true}
                  fontSize="f12-vw"
                />
              </div>
              <div className="px-4 mt-3">
                <label htmlFor="" className="f12-vw m-0 p-0">
                  Booking ID
                </label>
                {mobileSearchInput("Cari Booking ID ", bookingId, (e) =>
                  handleBookingId(e)
                )}
              </div>
              <div className="px-4 mt-3">
                <label htmlFor="" className="f12-vw m-0 p-0">
                  Cari Tamu
                </label>
                {mobileSearchInput("Cari Nama Tamu", guestname, (e) =>
                  handleGuestname(e)
                )}
              </div>
              <div className="px-4 mt-3">
                <label htmlFor="" className="f12-vw m-0 p-0">
                  Shift
                </label>
                <SelectOpt
                  optionsValue={roomType}
                  onChange={handleRoomTypeSelected}
                  placeholder={"Pilih Room Type"}
                  value={roomTypeSelected}
                  clear={true}
                  fontSize="calc(8pt + 0.472vw)"
                  menuHeight="12rem"
                />
              </div>
            </div>
          </BottomSheet>
        </div>
      )}

      <PopupModal modalIsOpen={isOpen} width="34rem" top="60%">
        <ModalDetail
          close={reset}
          checkNextPage={checkDetailNextPage}
          itemList={breakageDetail}
          total={totalBreakage}
          loadingList={loadingListDetail}
          error={errorDetail}
        />
      </PopupModal>
    </>
  );
};

export default BreakageReport;
