import React, { useCallback, useContext, useEffect, useState } from "react";
import { BreadcrumbContext } from "../../../common/BreadcrumbContext";
import NotifMessage, {
  ButtonClose,
  ButtonConfirm,
} from "../../../common/Notif";
import PopupModal from "../../../common/PopupModal";
import { PopupRoomNumber } from "../../../components/Popup/PopupRoomNumber";
import {
  createLineDash,
  createLineSolid,
  genBookingId,
  genContent1,
  genContent2,
  genPaymentID,
} from "../../../print/common/printer-helper";
import { Print } from "../../../print/Print";
import {
  addDays,
  checkNullArr,
  checkTime,
  formatDate,
  getClientTimeWithoutSecond,
  getDayDuration,
  getServerTime,
  getServerTimeWithoutDays,
  getTimezoneOffsetClient,
  isEmpty,
  priceSplit,
  slicePhoneNumber,
} from "../../../utils";
import {
  addData,
  addDataPict,
  fetchWithRetry,
  getData,
  getErr,
  safeErrCode,
} from "../../../utils/api-request";
import history from "../../../utils/history";
import { FormWalkin } from "./FormWalkin";
import {
  MobilePaymentMethodWalkin,
  PaymentMethodWalkin,
} from "./PaymentMethodWalkin";
import { WalkinDetailPopup } from "./WalkinDetailPopup";
import { IS_MOBILE } from "../../../common/Responsive";
import { MobileFormWalkin } from "./MobileFormWalkin";
import { useDebounce } from "../../../hook/useDebounce";
import { t } from "../../../common/ErrorMessage";

export const Walkin = (props) => {
  const { userProfile } = useContext(BreadcrumbContext);
  const [error, setError] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [headerPopup, setHeaderPopup] = useState("");
  const [roomNumber, setRoomNumber] = useState([]);
  const [roomSelected, setRoomSelected] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [formError, setFormError] = useState("");
  const [mode, setMode] = useState("");
  const [imgPopup, setImgPopup] = useState("");
  const [walkin, setWalkin] = useState({
    guestName: [],
    phoneNumber: "",
    checkin: checkTime() ? addDays(new Date(), -1) : new Date(),
    checkout: checkTime() ? new Date() : addDays(new Date(), 1),
    guest: 2,
    room: 1,
    roomId: "",
    roomNumber: [],
    email: "",
    payment: 0,
    earlycheckin: 0,
    roomType: "",
    voucherCode: "",
  });
  const [purposeSelected, setPurposeSelected] = useState([]);
  const [guestTypeSelected, setGuestTypeSelected] = useState([]);
  const [availability, setAvailability] = useState(false);
  const [errorAvail, setErrorAvail] = useState("");
  const [deposit, setDeposit] = useState(0);
  const [earlyCheckin, setEarlyCheckin] = useState(0);
  const [guestName, setGuestName] = useState([]);
  const [roomList, setRoomList] = useState([]);
  const [totalCharge, setTotalCharge] = useState(0);
  const [roomCharge, setRoomCharge] = useState(0);
  const [chargePerRoom, setChargePerRoom] = useState(0);
  const [fileUpload, setfileUpload] = useState([]);
  const [showImage, setShowImage] = useState("");
  const [detailSuccess, setDetailSuccess] = useState({});
  const [paymentMethod, setPaymentMethod] = useState([]);
  const [paymentSelected, setpaymentSelected] = useState([]);
  const [bankList, setBankList] = useState([]);
  const [bankSelected, setBankSelected] = useState([]);
  const [payment, setPayment] = useState(0);
  const [change, setChange] = useState(0);
  const [totalPaid, setTotalPaid] = useState(0);
  const [isValidGuest, setisValidGuest] = useState(true);
  const [isPrint, setIsPrint] = useState(false);
  const [paymentId, setPaymentId] = useState("");
  const [dueDate, setDueDate] = useState("");
  const [discount, setDiscount] = useState(0);
  const [errorVoucher, setErrorVoucher] = useState("");
  const [isValidVoucher, setIsValidVoucher] = useState(false);
  const debouncedSearchTerm = useDebounce(walkin.voucherCode, 500);
  const debouncedCountRoom = useDebounce(walkin.room, 500);
  const [loadingAvailability, setLoadingAvailability] = useState(false);
  const [stopFetch, setStopFetch] = useState(false);
  const [newPrice, setnewPrice] = useState(0);
  const [rateKey, setRateKey] = useState("");

  const isPicked = roomNumber?.length >= walkin?.room ? true : false;

  const createOrder = () => {
    setLoading(true);
    const formData = new FormData();
    const payload = {
      checkin_date: formatDate(walkin.checkin),
      checkout_date: formatDate(walkin.checkout),
      room_booked: walkin.room,
      room_type: walkin.roomId,
      booked_by: walkin?.guestName?.join(","),
      guest_qty: walkin.guest,
      phone: slicePhoneNumber(walkin.phoneNumber),
      email: walkin.email,
      purpose_id: purposeSelected?.value,
      guest_type_id: guestTypeSelected?.value,
      room_number: roomNumber.join(","),
      guest: guestName.join(","),
      earlycheckin: earlyCheckin,
      deposit: deposit,
      single_room_charge: chargePerRoom,
      user_total_charge: totalCharge,
      file_ktp: fileUpload[0],
      ...(!!rateKey && { rate_key: rateKey }),
      ...(!!walkin.voucherCode && { voucher_no: walkin.voucherCode }),
    };

    for (const key in payload) {
      formData.append(key, payload[key]);
    }

    addDataPict("/api/v1/order/bookthencheckin", formData)
      .then((resp) => {
        setLoading(false);
        openModal();
        if (resp.message === "Ok") {
          setMode("payment-process");
          setDetailSuccess(resp);
          setStopFetch(true);
        } else {
          resp.json().then((err) => {
            setError(t(safeErrCode(err)));
            setMode("error");
            setHeaderPopup("Booking Gagal");
            setImgPopup("/img/error-icon/booking-failed.svg");
            setStopFetch(false);
          });
        }
      })
      .catch((error) => {
        let err = getErr(error);
        openModal();
        setStopFetch(false);
        setLoading(false);
        setError(t(err));
        console.log(error);
        setMode("error");
        setHeaderPopup("Booking Gagal");
        setImgPopup("/img/error-icon/booking-failed.svg");
      });
  };

  const getAllotmentPrice = useCallback(
    (voucher) => {
      const params = {
        ...(!!roomSelected?.value && {
          room_type_id: roomSelected?.value,
        }),
        ...(!!debouncedCountRoom && { room_qty: debouncedCountRoom }),
        ...(!!voucher && { voucherno: voucher }),
        ...(!!walkin?.checkin && { date_start: formatDate(walkin.checkin) }),
        ...(!!walkin?.checkout && { date_end: formatDate(walkin.checkout) }),
      };
      let paramsFormatted = null;
      if (!isEmpty(params)) {
        paramsFormatted = new URLSearchParams(params);
      }
      const endpoint = `/api/v1/alotmentprice?${
        paramsFormatted !== null ? `&${paramsFormatted}` : ""
      }`;
      setStopFetch(false);
      if (roomSelected?.value) {
        setLoadingAvailability(true);
        setAvailability(false);
        fetchWithRetry(endpoint, 15000)
          .then((resp) => {
            setLoadingAvailability(false);
            if (resp?.available) {
              setAvailability(resp?.available);
              setRoomCharge(
                resp.vouchertype === 1 ? resp.new_price : resp.charge
              );
              setRateKey(resp?.rate_key);
              setnewPrice(resp?.new_price);
              setChargePerRoom(resp.single_room_charge);
              setDiscount(parseInt(resp.diskon));
              if (resp.voucher_err_msg !== "" && voucher.length === 16) {
                setMode("error-voucher");
                setIsOpen(true);
                setIsValidVoucher(true);
                setHeaderPopup("Kode Voucher Salah");
              } else {
                setIsValidVoucher(false);
              }
              setEarlyCheckin(resp.charge_early);
              setErrorVoucher(resp.voucher_err_msg);
            } else {
              resp.json().then((err) => {
                setErrorAvail(t(safeErrCode(err)));
              });
              setEarlyCheckin(0);
              setIsOpen(true);
              setMode("error-avail");
              setImgPopup("/img/error-icon/room-not-available.png");
              setAvailability(false);
            }
          })
          .catch((error) => {
            let err = getErr(error);
            setLoadingAvailability(false);
            setAvailability(false);
            setIsOpen(true);
            setMode("error-avail");
            setErrorAvail(t(err));
            console.error(t(err));
            setImgPopup("/img/error-icon/room-not-available.png");
          });
      }
    },
    [debouncedCountRoom, roomSelected?.value, walkin.checkin, walkin.checkout]
  );

  const getRoomNumber = useCallback(() => {
    if (walkin.roomId !== "") {
      getData(`/api/v1/detailroom?room_type=${walkin.roomId}`)
        .then((resp) => {
          if (resp.data) {
            if (resp.data.length > 0) {
              let d = resp.data.map((item) => {
                return {
                  room_number: item.room_number,
                  floor: item.floor,
                  bed_type_name: item.bed_type_name,
                  room_type: item.room_type,
                  bed_size: item.bed_size,
                  status: item.status,
                  checked: false,
                };
              });
              setRoomList(d);
            } else {
              setHeaderPopup("");
              setRoomList([]);
              setError(
                "Nomor Kamar tidak tersedia, silahkan pilih tipe kamar lain"
              );
              setImgPopup("/img/error-icon/room-not-available.png");
            }
          } else {
            resp.json().then((err) => {
              setError(t(safeErrCode(err)));
            });
            setImgPopup("/img/error-icon/room-not-available.png");
          }
        })
        .catch((error) => {
          let err = getErr(error);
          setError(t(err));
          setImgPopup("/img/error-icon/room-not-available.png");
        });
    }
  }, [walkin.roomId]);

  const getPaymentMethod = useCallback(() => {
    if (isOpen && mode === "payment-process") {
      getData("/api/v1/listpaymentmethod")
        .then((resp) => {
          if (resp.data) {
            let d = resp.data.map((item) => {
              return {
                value: item.payment_type,
                label: item.payment_name,
              };
            });
            setPaymentMethod(d);
          } else {
            resp.json().then((err) => {
              setError(t(safeErrCode(err)));
            });
            setPaymentMethod([]);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [isOpen, mode]);

  const getPaymentType = useCallback(() => {
    if (paymentSelected.value === 2) {
      getData(`/api/v1/paymentype?payment_type=${paymentSelected.value}`)
        .then((resp) => {
          if (resp.data) {
            let d = resp.data.map((item) => {
              return {
                value: item.bank_id,
                label: `${item.bank_name} ( ${item.bank_accno} )`,
                bank_name: item.bank_name,
                bank_accno: item.bank_accno,
                bank_accholder: item.bank_accholder,
              };
            });
            setBankList(d);
          } else {
            resp.json().then((err) => {
              setError(t(safeErrCode(err)));
            });
            setBankList([]);
          }
        })
        .catch((error) => {
          let err = getErr(error);
          setError(t(err));
        });
    }
  }, [paymentSelected]);

  const makePayment = () => {
    const formData = new FormData();
    const payload = {
      billing_ids: detailSuccess.billing_ids,
      booking_id: detailSuccess.booking_id,
      paymenttype: paymentSelected?.value,
      payment_receive:
        paymentSelected?.value === 2 ? totalCharge - deposit : payment,
      real_room_charge: detailSuccess?.real_room_charge,
      ...(!!bankSelected?.bank_name && { bank_name: bankSelected.bank_name }),
      ...(!!bankSelected?.bank_accno && {
        bankaccno: bankSelected.bank_accno,
      }),
      ...(!!walkin?.voucherCode && {
        voucher_no: walkin?.voucherCode,
      }),
    };
    for (const key in payload) {
      formData.append(key, payload[key]);
    }
    // for (const key in payload) {
    //   console.log(key, payload[key]);
    // }
    addData("/api/v1/makepayment_pending", formData)
      .then((resp) => {
        if (resp.message === "Success" && resp.payment_id) {
          setMode("confirm-success");
          setHeaderPopup("Pembayaran Berhasil");
          setImgPopup("/img/icon-shift/confirm-close.svg");
          setPaymentId(resp?.payment_id);
          setDueDate(resp.due_date);
          setIsPrint(true);
        } else {
          resp.json().then((err) => {
            setError(t(safeErrCode(err)));
          });
          setHeaderPopup("Pembayaran Gagal");
          setImgPopup("/img/error-icon/booking-failed.svg");
          setMode("error-payment");
        }
      })
      .catch((error) => {
        let err = getErr(error);
        setLoading(false);
        setError(t(err));
        console.log(error);
        setMode("error-payment");
        setHeaderPopup("Pembayaran Gagal");
        setImgPopup("/img/error-icon/booking-failed.svg");
      });
  };

  const cancelBooking = (id) => {
    const payload = {
      booking_uid: id,
    };
    setLoadingSubmit(true);
    addData("/api/v1/order/cancel_bookthencheckin", payload)
      .then((resp) => {
        setLoadingSubmit(false);
        if (resp.message) {
          setMode("cancel-success");
          setHeaderPopup("Cancel Booking Berhasil");
          setImgPopup("/img/icon-shift/confirm-close.svg");
        } else {
          resp.json().then((err) => {
            setError(t(safeErrCode(err)));
            setMode("error");
            setHeaderPopup("Cancel Gagal");
            setImgPopup("/img/error-icon/booking-failed.svg");
          });
        }
      })
      .catch((error) => {
        setLoadingSubmit(false);
        let err = getErr(error);
        setError(t(err));
        console.log(error);
        setMode("error");
        setHeaderPopup("Cancel Gagal");
        setImgPopup("/img/error-icon/booking-failed.svg");
      });
  };

  // <== API request

  // UTILS ==>

  const openModal = () => {
    setIsOpen(!isOpen);
  };
  const timezone = localStorage.getItem("timezone");
  const hotel_info = JSON.parse(localStorage.getItem("info_hotel"));
  const guesttype = [
    { value: 1, label: "Personal" },
    { value: 2, label: "Group" },
  ];
  const purpose = [
    { value: 1, label: "Business Trip" },
    { value: 2, label: "Leisure/Vacation" },
    { value: 3, label: "Family Trip" },
  ];

  const reset = () => {
    setWalkin({
      ...walkin,
      guestName: [],
      phoneNumber: "62",
      checkin: checkTime() ? addDays(new Date(), -1) : new Date(),
      checkout: checkTime() ? new Date() : addDays(new Date(), 1),
      guest: 2,
      room: 1,
      roomId: "",
      roomNumber: [],
      email: "",
      payment: 0,
      earlycheckin: 0,
      roomType: "",
      voucherCode: "",
    });
    setError("");
    setIsOpen(false);
    setHeaderPopup("");
    setRoomNumber([]);
    setRoomSelected([]);
    setLoading(false);
    setFormError("");
    setMode("");
    setImgPopup("");
    setPurposeSelected(purpose[1]);
    setGuestTypeSelected(guesttype[0]);
    setAvailability(false);
    setErrorAvail("");
    setDeposit(0);
    setEarlyCheckin(0);
    setGuestName([]);
    setRoomList([]);
    setTotalCharge(0);
    setRoomCharge(0);
    setChargePerRoom(0);
    setfileUpload([]);
    setShowImage("");
    setDetailSuccess({});
    setpaymentSelected([]);
    setBankSelected([]);
    setPayment(0);
    setChange(0);
    setTotalPaid(0);
    setisValidGuest(true);
    setIsPrint(false);
    setPaymentId("");
    setDueDate("");
    setDiscount(0);
    setErrorVoucher("");
    setStopFetch(false);
    setnewPrice(0);
    setRateKey("");
  };
  // <== UTILS

  useEffect(() => {
    getAllotmentPrice(debouncedSearchTerm);
  }, [debouncedSearchTerm, getAllotmentPrice]);

  useEffect(() => {
    getRoomNumber();
  }, [getRoomNumber]);

  useEffect(() => {
    getPaymentMethod();
  }, [getPaymentMethod]);

  useEffect(() => {
    getPaymentType();
  }, [getPaymentType]);

  useEffect(() => {
    let total = earlyCheckin + newPrice + deposit;
    setTotalCharge(total);
  }, [deposit, earlyCheckin, setTotalCharge, newPrice]);

  useEffect(() => {
    if (checkNullArr(guestName)) {
      setisValidGuest(false);
    } else {
      setisValidGuest(true);
    }
  }, [guestName]);

  useEffect(() => {
    let interval;
    if (walkin.roomId !== "" && availability && !stopFetch) {
      interval = setInterval(() => {
        getAllotmentPrice(debouncedSearchTerm);
      }, 60000);
    }

    return () => {
      clearInterval(interval);
    };
  }, [
    availability,
    getAllotmentPrice,
    debouncedSearchTerm,
    stopFetch,
    walkin.roomId,
  ]);

  useEffect(() => {
    if (isPrint) {
      setTimeout(() => {
        setIsPrint(false);
      }, 6000);
    }
  }, [isPrint]);

  useEffect(() => {
    let timeout;
    if (isOpen && mode === "payment-process") {
      timeout = setTimeout(() => {
        setMode("expired-payment");
        setHeaderPopup("Pending Payment");
        setImgPopup("/img/error-icon/booking-failed.svg");
      }, 300000);
    }

    return () => {
      clearTimeout(timeout);
    };
  }, [isOpen, mode]);

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
    return () => {
      document.body.style.overflow = "unset";
    };
  }, [isOpen]);

  return (
    <div
      className={`tab-pane show active p-3 ${
        !props.isOpening ? "" : "not-opening"
      }`}
      id="walk-in"
      role="tabpanel"
      aria-labelledby="walk-in-tab"
    >
      <>
        {IS_MOBILE ? (
          <div className="d-flex flex-column pb-4">
            <div className="col">
              <MobileFormWalkin
                typeRoom={props.typeRoom}
                errMessage={error}
                walkin={walkin}
                setWalkin={setWalkin}
                roomNumber={roomNumber}
                setRoomNumber={setRoomNumber}
                formError={formError}
                setFormError={setFormError}
                setMode={setMode}
                mode={mode}
                headerPopup={headerPopup}
                roomSelected={roomSelected}
                setRoomSelected={setRoomSelected}
                isOpening={props.isOpening}
                imgPopup={imgPopup}
                setimgPopup={setImgPopup}
                purpose={purpose}
                purposeSelected={purposeSelected}
                setPurposeSelected={setPurposeSelected}
                guesttype={guesttype}
                guestTypeSelected={guestTypeSelected}
                setGuestTypeSelected={setGuestTypeSelected}
                availability={availability}
                errorAvail={errorAvail}
                earlyCheckin={earlyCheckin}
                deposit={deposit}
                setDeposit={setDeposit}
                roomPrice={roomCharge}
                totalCharge={totalCharge}
                setTotalCharge={setTotalCharge}
                roomList={roomList}
                guestName={guestName}
                openModal={openModal}
                setError={setError}
                setfileUpload={setfileUpload}
                fileUpload={fileUpload}
                setGuestName={setGuestName}
                setImgPopup={setImgPopup}
                setShowImg={setShowImage}
                showImg={showImage}
                isValidGuest={isValidGuest}
                discount={discount}
                errorVoucher={isValidVoucher}
                loadingPrice={loadingAvailability}
                loadingAvailability={loadingAvailability}
                loadingEarlyCheckin={loadingAvailability}
                setAvailability={setAvailability}
                setStopFetch={setStopFetch}
              />
            </div>
            <div className="col">
              <hr className="separator-grey my-2" />
            </div>
            <div className="col mt-3">
              <div className="d-flex flex-row">
                <div className="col-5 px-0 ml-auto" style={{ width: 200 }}>
                  <button
                    className={`btn text-white f12-vw btn-block font-weight-bold ${
                      walkin?.guestName?.length > 0 &&
                      walkin?.phoneNumber?.length >= 10 &&
                      walkin?.phoneNumber?.length <= 20 &&
                      walkin?.roomType !== "" &&
                      availability &&
                      !loading &&
                      guestName.length > 0 &&
                      roomNumber.length === walkin.room &&
                      fileUpload.length > 0 &&
                      isValidGuest &&
                      errorVoucher === ""
                        ? "btn-gradient"
                        : "btn-secondary"
                    }`}
                    disabled={
                      walkin?.guestName?.length > 0 &&
                      walkin?.phoneNumber?.length >= 10 &&
                      walkin?.phoneNumber?.length <= 20 &&
                      walkin?.roomType !== "" &&
                      availability &&
                      !loading &&
                      guestName.length > 0 &&
                      roomNumber.length === walkin.room &&
                      fileUpload.length > 0 &&
                      isValidGuest &&
                      errorVoucher === ""
                        ? false
                        : true
                    }
                    onClick={() => {
                      createOrder();
                    }}
                  >
                    {loading ? (
                      <span
                        className="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>
                    ) : (
                      "Next"
                    )}
                  </button>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="d-flex flex-column">
            <div className="col">
              <FormWalkin
                typeRoom={props.typeRoom}
                errMessage={error}
                walkin={walkin}
                setWalkin={setWalkin}
                roomNumber={roomNumber}
                setRoomNumber={setRoomNumber}
                formError={formError}
                setFormError={setFormError}
                setMode={setMode}
                mode={mode}
                headerPopup={headerPopup}
                roomSelected={roomSelected}
                setRoomSelected={setRoomSelected}
                isOpening={props.isOpening}
                imgPopup={imgPopup}
                setimgPopup={setImgPopup}
                purpose={purpose}
                purposeSelected={purposeSelected}
                setPurposeSelected={setPurposeSelected}
                guesttype={guesttype}
                guestTypeSelected={guestTypeSelected}
                setGuestTypeSelected={setGuestTypeSelected}
                availability={availability}
                errorAvail={errorAvail}
                earlyCheckin={earlyCheckin}
                deposit={deposit}
                setDeposit={setDeposit}
                roomPrice={roomCharge}
                totalCharge={totalCharge}
                setTotalCharge={setTotalCharge}
                roomList={roomList}
                guestName={guestName}
                openModal={openModal}
                setError={setError}
                setfileUpload={setfileUpload}
                fileUpload={fileUpload}
                setGuestName={setGuestName}
                setImgPopup={setImgPopup}
                setShowImg={setShowImage}
                showImg={showImage}
                isValidGuest={isValidGuest}
                discount={discount}
                errorVoucher={isValidVoucher}
                loadingPrice={loadingAvailability}
                loadingAvailability={loadingAvailability}
                loadingEarlyCheckin={loadingAvailability}
                setAvailability={setAvailability}
                setStopFetch={setStopFetch}
              />
            </div>
            <div className="col">
              <hr className="separator-grey my-2" />
            </div>
            <div className="col mt-3">
              <div className="d-flex flex-row">
                <div className="col-2 px-0 ml-auto" style={{ width: 200 }}>
                  <button
                    className={`btn text-white f8-vw btn-block font-weight-bold ${
                      walkin?.guestName?.length > 0 &&
                      walkin?.phoneNumber?.length >= 10 &&
                      walkin?.phoneNumber?.length <= 20 &&
                      walkin?.roomType !== "" &&
                      availability &&
                      !loading &&
                      guestName.length > 0 &&
                      roomNumber.length === walkin.room &&
                      fileUpload.length > 0 &&
                      isValidGuest &&
                      errorVoucher === ""
                        ? "btn-gradient"
                        : "btn-secondary"
                    }`}
                    disabled={
                      walkin?.guestName?.length > 0 &&
                      walkin?.phoneNumber?.length >= 10 &&
                      walkin?.phoneNumber?.length <= 20 &&
                      walkin?.roomType !== "" &&
                      availability &&
                      !loading &&
                      guestName.length > 0 &&
                      roomNumber.length === walkin.room &&
                      fileUpload.length > 0 &&
                      isValidGuest &&
                      errorVoucher === ""
                        ? false
                        : true
                    }
                    onClick={() => {
                      createOrder();
                    }}
                  >
                    {loading ? (
                      <span
                        className="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>
                    ) : (
                      "Next"
                    )}
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
      </>
      <div style={{ display: "none" }}>
        <Print launch_print={isPrint}>
          <>
            {paymentSelected?.value === 3 ? (
              <p className="p-print txt-print-h1">SLIP Pembayaran</p>
            ) : (
              <p className="p-print txt-print-h1">Billing</p>
            )}

            {genPaymentID(paymentId)}
            {createLineDash()}
            <p className="p-print txt-print-h3">{`${getServerTime(
              new Date(),
              timezone
            )} - ${getClientTimeWithoutSecond(
              new Date(),
              timezone
            )} ${getTimezoneOffsetClient(timezone)}`}</p>
            <p className="p-print txt-print-h3">{hotel_info?.hotel_name}</p>
            {createLineDash()}
            <table className="table-print">
              <tbody>{genContent1("Kasir", userProfile?.nama)}</tbody>
            </table>
            {genBookingId("Booking ID", detailSuccess?.book_id)}
            {createLineDash()}
            <p className="p-print txt-print-section-h2">Informasi Booking</p>
            <table className="table-print">
              <tbody>
                {genContent1(
                  "Check In",
                  getServerTimeWithoutDays(walkin?.checkin, timezone)
                )}
                {genContent1(
                  "Check Out",
                  getServerTimeWithoutDays(walkin?.checkout, timezone)
                )}
                {genContent1(
                  "Durasi",
                  getDayDuration(walkin?.checkin, walkin?.checkout) + " Malam"
                )}
                {genContent1("Nomor Kamar", roomNumber?.join(", "))}
              </tbody>
            </table>
            {createLineDash()}
            <table className="table-print">
              <tbody>
                {genContent1("Metode Pembayaran", paymentSelected?.label)}
                {paymentSelected?.value === 2 && (
                  <>
                    {genContent1("Nama Bank", bankSelected?.label)}
                    {genContent1(
                      "Batas Pembayaran",
                      `${getClientTimeWithoutSecond(
                        dueDate,
                        timezone
                      )} ${getTimezoneOffsetClient(timezone)}`
                    )}
                  </>
                )}
              </tbody>
            </table>
            {createLineDash()}
            {paymentSelected?.value === 3 ? (
              <p className="p-print txt-print-section-h2">
                Informasi Pembayaran
              </p>
            ) : (
              <p className="p-print txt-print-section-h2">Informasi Tagihan</p>
            )}
            {walkin?.voucherCode !== ""
              ? genBookingId("Kode Voucher", walkin.voucherCode)
              : null}
            <table className="table-print mt-2">
              <tbody>
                {genContent2("Harga Kamar", roomCharge)}
                {genContent2("Early Checkin", earlyCheckin)}
                {paymentSelected?.value === 3 &&
                  genContent2("Deposit", deposit)}
                {discount > 0 && (
                  <tr className="txt-print-section">
                    <td width={100}>Diskon</td>
                    <td width={90} style={{ textAlign: "center" }}>
                      {"IDR"}
                    </td>
                    <td className={"txt-price"} width={60}>
                      -{priceSplit(discount)}
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
            {createLineSolid()}
            <table className="table-print">
              <tbody>
                {genContent2(
                  "Total Tagihan",
                  paymentSelected?.value === 3
                    ? totalCharge
                    : totalCharge - deposit
                )}
                {paymentSelected?.value === 2 &&
                  genContent2("Deposit (Cash)", deposit)}
                {paymentSelected?.value === 3 &&
                  genContent2("Pembayaran Tunai", payment)}
              </tbody>
            </table>
            {paymentSelected?.value === 3 && (
              <>
                {createLineSolid()}
                <table className="table-print">
                  <tbody>{genContent2("Kembalian", change)}</tbody>
                </table>
              </>
            )}
          </>
        </Print>
      </div>

      {IS_MOBILE ? (
        <PopupModal modalIsOpen={isOpen}>
          {mode === "confirm-detail" ? (
            <WalkinDetailPopup
              detail={walkin}
              purposeSelected={purposeSelected}
              guestTypeSelected={guestTypeSelected}
              guestName={guestName.length > 0 && guestName.join(",")}
              roomNumber={roomNumber.length > 0 && roomNumber.join(",")}
              showImg={showImage}
              roomPrice={roomCharge}
              totalCharge={totalCharge}
              earlyCheckin={earlyCheckin}
              deposit={deposit}
              close={() => {
                openModal();
              }}
              confirm={() => {
                createOrder();
              }}
            />
          ) : mode === "confirm-success" ? (
            <>
              <NotifMessage
                closeModal={openModal}
                img={imgPopup}
                messageHeader={
                  paymentSelected.value === 3
                    ? "Checkin & Pembayaran Berhasil"
                    : "Silahkan Konfirmasi Pembayaran"
                }
                color="black"
                messageBody={
                  paymentSelected.value === 3
                    ? "Pembayaran Berhasil"
                    : "Klik OK untuk menuju ke menu Konfirmasi"
                }
              />
              <ButtonClose
                close={
                  paymentSelected.value === 2
                    ? () => {
                        openModal();
                        history.push("/reservasi/payment-confirm");
                      }
                    : () => {
                        reset();
                      }
                }
                color="bg-green"
                text="OK"
              />
            </>
          ) : mode === "room" ? (
            <>
              <PopupRoomNumber
                close={() => {
                  openModal();
                  setStopFetch(false);
                }}
                reset={() => {
                  setRoomNumber([]);
                  getRoomNumber();
                }}
                title="Room Number"
                roomNumber={roomList}
                onChange={(val) => setRoomNumber(val)}
                setRoomNumber={setRoomList}
                isPicked={isPicked}
              />
            </>
          ) : mode === "payment-process" ? (
            <MobilePaymentMethodWalkin
              close={() => {
                setMode("cancel-confirm");
                setHeaderPopup("Cancel Booking");
                setImgPopup("/img/error-icon/booking-failed.svg");
              }}
              paymentMethod={paymentMethod}
              paymentSelected={paymentSelected}
              bankList={bankList}
              bankSelected={bankSelected}
              roomCharge={roomCharge}
              earlyCheckin={earlyCheckin}
              deposit={deposit}
              totalCharge={totalCharge - deposit}
              setBankSelected={setBankSelected}
              setpaymentSelected={setpaymentSelected}
              payment={payment}
              charge={change}
              onClick={() => {
                makePayment();
              }}
              setPayment={setPayment}
              setCharge={setChange}
              totalPaid={totalPaid}
              setTotalPaid={setTotalPaid}
              voucherCode={walkin.voucherCode}
              discount={discount}
              newPrice={newPrice}
            />
          ) : mode === "cancel-confirm" ? (
            <>
              <NotifMessage
                closeModal={openModal}
                img={imgPopup}
                messageHeader={headerPopup}
                color="red"
                messageBody={"Apakah anda yakin ingin Cancel Booking?"}
              />
              <ButtonConfirm
                text={"Confirm"}
                secondButton={"Back"}
                close={() => setMode("payment-process")}
                confirm={() => cancelBooking(detailSuccess?.booking_id)}
                disabled={loadingSubmit}
                loading={loadingSubmit}
              />
            </>
          ) : mode === "cancel-success" ? (
            <>
              <NotifMessage
                closeModal={openModal}
                img={imgPopup}
                messageHeader={headerPopup}
                color="black"
                // messageBody={"Cancel Booking Berhasil"}
              />
              <ButtonClose
                close={() => {
                  reset();
                }}
                text="OK"
              />
            </>
          ) : mode === "error-payment" ? (
            <>
              <NotifMessage
                closeModal={openModal}
                img={imgPopup}
                messageHeader={headerPopup}
                color="red"
                messageBody={error}
              />
              <ButtonClose
                close={() => {
                  setMode("payment-process");
                }}
                text="OK"
              />
            </>
          ) : mode === "error-voucher" ? (
            <>
              <NotifMessage
                closeModal={openModal}
                img={"/img/error-icon/voucher-error.svg"}
                messageHeader={headerPopup}
                color="red"
                messageBody={errorVoucher}
              />
              <ButtonClose
                close={() => {
                  openModal();
                }}
                text="OK"
              />
            </>
          ) : mode === "error-avail" ? (
            <>
              <NotifMessage
                closeModal={openModal}
                img={imgPopup}
                messageHeader={headerPopup}
                color="red"
                messageBody={errorAvail}
              />
              <ButtonClose
                close={() => {
                  openModal();
                }}
                text="OK"
              />
            </>
          ) : mode === "expired-payment" ? (
            <>
              <NotifMessage
                closeModal={openModal}
                img={imgPopup}
                messageHeader={headerPopup}
                color="orange"
                messageBody={
                  "Waktu Pembayaran telah habis, silahkan lanjutkan di Menu Pending Booking"
                }
              />
              <ButtonClose
                close={() => {
                  openModal();
                  history.push("/reservasi/pending-booking");
                }}
                text="OK"
              />
            </>
          ) : (
            <>
              <NotifMessage
                closeModal={openModal}
                img={imgPopup}
                messageHeader={headerPopup}
                color="red"
                messageBody={error}
              />
              <ButtonClose
                close={() => {
                  openModal();
                }}
                text="OK"
              />
            </>
          )}
        </PopupModal>
      ) : (
        <PopupModal modalIsOpen={isOpen}>
          {mode === "confirm-detail" ? (
            <WalkinDetailPopup
              detail={walkin}
              purposeSelected={purposeSelected}
              guestTypeSelected={guestTypeSelected}
              guestName={guestName.length > 0 && guestName.join(",")}
              roomNumber={roomNumber.length > 0 && roomNumber.join(",")}
              showImg={showImage}
              roomPrice={roomCharge}
              totalCharge={totalCharge}
              earlyCheckin={earlyCheckin}
              deposit={deposit}
              close={() => {
                openModal();
              }}
              confirm={() => {
                createOrder();
              }}
            />
          ) : mode === "confirm-success" ? (
            <>
              <NotifMessage
                closeModal={openModal}
                img={imgPopup}
                messageHeader={
                  paymentSelected.value === 3
                    ? "Checkin & Pembayaran Berhasil"
                    : "Silahkan Konfirmasi Pembayaran"
                }
                color="black"
                messageBody={
                  paymentSelected.value === 3
                    ? "Pembayaran Berhasil"
                    : "Klik OK untuk menuju ke menu Konfirmasi"
                }
              />
              <ButtonClose
                close={
                  paymentSelected.value === 2
                    ? () => {
                        openModal();
                        history.push("/reservasi/payment-confirm");
                      }
                    : () => {
                        reset();
                      }
                }
                color="bg-green"
                text="OK"
              />
            </>
          ) : mode === "room" ? (
            <>
              <PopupRoomNumber
                close={() => {
                  openModal();
                  setStopFetch(false);
                }}
                reset={() => {
                  setRoomNumber([]);
                  getRoomNumber();
                }}
                title="Room Number"
                roomNumber={roomList}
                onChange={(val) => setRoomNumber(val)}
                setRoomNumber={setRoomList}
                isPicked={isPicked}
              />
            </>
          ) : mode === "payment-process" ? (
            <PaymentMethodWalkin
              close={() => {
                setMode("cancel-confirm");
                setHeaderPopup("Cancel Booking");
                setImgPopup("/img/error-icon/booking-failed.svg");
              }}
              paymentMethod={paymentMethod}
              paymentSelected={paymentSelected}
              bankList={bankList}
              bankSelected={bankSelected}
              roomCharge={roomCharge}
              earlyCheckin={earlyCheckin}
              deposit={deposit}
              totalCharge={totalCharge - deposit}
              setBankSelected={setBankSelected}
              setpaymentSelected={setpaymentSelected}
              payment={payment}
              charge={change}
              onClick={() => {
                makePayment();
              }}
              setPayment={setPayment}
              setCharge={setChange}
              totalPaid={totalPaid}
              setTotalPaid={setTotalPaid}
              voucherCode={walkin.voucherCode}
              discount={discount}
              newPrice={newPrice}
            />
          ) : mode === "cancel-confirm" ? (
            <>
              <NotifMessage
                closeModal={openModal}
                img={imgPopup}
                messageHeader={headerPopup}
                color="red"
                messageBody={"Apakah anda yakin ingin Cancel Booking?"}
              />
              <ButtonConfirm
                text={"Confirm"}
                secondButton={"Back"}
                close={() => setMode("payment-process")}
                confirm={() => cancelBooking(detailSuccess?.booking_id)}
                disabled={loadingSubmit}
                loading={loadingSubmit}
              />
            </>
          ) : mode === "cancel-success" ? (
            <>
              <NotifMessage
                closeModal={openModal}
                img={imgPopup}
                messageHeader={headerPopup}
                color="black"
                // messageBody={"Cancel Booking Berhasil"}
              />
              <ButtonClose
                close={() => {
                  reset();
                }}
                text="OK"
              />
            </>
          ) : mode === "error-payment" ? (
            <>
              <NotifMessage
                closeModal={openModal}
                img={imgPopup}
                messageHeader={headerPopup}
                color="red"
                messageBody={error}
              />
              <ButtonClose
                close={() => {
                  setMode("payment-process");
                }}
                text="OK"
              />
            </>
          ) : mode === "error-voucher" ? (
            <>
              <NotifMessage
                closeModal={openModal}
                img={"/img/error-icon/voucher-error.svg"}
                messageHeader={headerPopup}
                color="red"
                messageBody={errorVoucher}
              />
              <ButtonClose
                close={() => {
                  openModal();
                }}
                text="OK"
              />
            </>
          ) : mode === "error-avail" ? (
            <>
              <NotifMessage
                closeModal={openModal}
                img={imgPopup}
                messageHeader={headerPopup}
                color="red"
                messageBody={errorAvail}
              />
              <ButtonClose
                close={() => {
                  openModal();
                }}
                text="OK"
              />
            </>
          ) : mode === "expired-payment" ? (
            <>
              <NotifMessage
                closeModal={openModal}
                img={imgPopup}
                messageHeader={headerPopup}
                color="orange"
                messageBody={
                  "Waktu Pembayaran telah habis, silahkan lanjutkan di Menu Pending Booking"
                }
              />
              <ButtonClose
                close={() => {
                  openModal();
                  history.push("/reservasi/pending-booking");
                }}
                text="OK"
              />
            </>
          ) : (
            <>
              <NotifMessage
                closeModal={openModal}
                img={imgPopup}
                messageHeader={headerPopup}
                color="red"
                messageBody={error}
              />
              <ButtonClose
                close={() => {
                  openModal();
                }}
                text="OK"
              />
            </>
          )}
        </PopupModal>
      )}
    </div>
  );
};
