import PrivateComponent from "../components/auth/PrivateRoute";

import TabsMenu from "../components/TabMenu/TabsMenu";
import RoomAction from "../pages/housekeeping/RoomAction";
import RoomInspection from "../pages/housekeeping/RoomInspection";
import BedType from "../pages/master/BedType";
import Compensation from "../pages/master/Compensation";
import RoomList from "../pages/master/RoomList";
import RoomStock from "../pages/room-stock/RoomStock";
import RoomChangeStatus from "../pages/housekeeping/RoomChangeStatus";
import HousekeepingReport from "../pages/housekeeping/HousekeepingReport";
import HistoryTransfer from "../pages/weekly-transfer/HistoryTransfer";
import ShiftReport from "../pages/shift-report/ShiftReport";
import GuestCheckin from "../pages/report-booking/GuestCheckin";
import Checkin from "../pages/booking/checkin-process/Checkin";
import CheckinPaymentProcess from "../pages/booking/payment-process/PaymentReservasi";
import CheckinPaymentConfirm from "../pages/booking/payment-confirm/PaymentConfirm";
import Checkout from "../pages/checkout/checkout-process/Checkout";
import CheckoutPaymentConfirm from "../pages/checkout/payment-confirm/PaymentConfirm";
import CheckoutPaymentProcess from "../pages/checkout/payment-process/PaymentProcess";
import SetoranMingguan from "../pages/setoran/SetoranMingguan";
import SetoranConfirmation from "../pages/setoran/SetoranConfirmation";
import SetoranApproval from "../pages/setoran/SetoranApproval";
import LossFoundList from "../pages/loss-found/LossFoundList";
import LossFoundForm from "../pages/loss-found/LossFoundForm";
import RePrintCheckinBilling from "../pages/re-printing/RePrintCheckinBilling";
import RePrintCheckinPaymentSlip from "../pages/re-printing/RePrintCheckinPaymentSlip";
import RePrintCheckoutBilling from "../pages/re-printing/RePrintCheckoutBilling";
import RePrintCheckoutPaymentSlip from "../pages/re-printing/RePrintCheckoutPaymentSlip";
import BreakageReport from "../pages/report-breakage/BreakageReport";
import BookingForecast from "../pages/booking-forecast/BookingForecast";
import RoomingList from "../pages/rooming-list/RoomingList";
import PendingBooking from "../pages/booking/pending-booking/PendingBooking";
import ShiftManagement from "../pages/master/ShiftManagement";

export const routerComponent = [
  //RESERVASI
  {
    path: "/reservasi/booking",
    component: TabsMenu,
    acl: "rv_booking_show",
    execution_type: 2,
  },
  {
    path: "/reservasi/pending-booking",
    component: PendingBooking,
    acl: "rv_room_pb_show",
    execution_type: 3,
  },
  {
    path: "/reservasi/checkin-process",
    component: Checkin,
    acl: "rv_check_in_show",
    execution_type: 4,
  },
  {
    path: "/reservasi/payment-process",
    component: CheckinPaymentProcess,
    acl: "rv_process_payment_show",
    execution_type: 5,
  },
  {
    path: "/reservasi/payment-confirm",
    component: CheckinPaymentConfirm,
    acl: "rv_payment_confirm_show",
    execution_type: 6,
  },
  {
    path: "/reservasi/room-stock",
    component: RoomStock,
    acl: "rv_room_st_show",
    execution_type: 1,
  },
  {
    path: "/reservasi/rooming-list",
    component: RoomingList,
    acl: "rv_roominglist_show",
    execution_type: 30,
  },
  {
    path: "/reservasi/re-print-billing",
    component: RePrintCheckinBilling,
    acl: "rv_checkin_billing_show",
    execution_type: 7,
  },
  {
    path: "/reservasi/re-print-payment",
    component: RePrintCheckinPaymentSlip,
    acl: "rv_checkin_receipt_show",
    execution_type: 8,
  },

  //CHECKOUT
  {
    path: "/checkout/checkout-process",
    component: Checkout,
    acl: "ch_process_check_out_show",
    execution_type: 9,
  },
  {
    path: "/checkout/payment-process",
    component: CheckoutPaymentProcess,
    acl: "ch_process_payment_show",
    execution_type: 10,
  },
  {
    path: "/checkout/payment-confirm",
    component: CheckoutPaymentConfirm,
    acl: "ch_payment_confirm_show",
    execution_type: 11,
  },
  {
    path: "/checkout/re-print-billing",
    component: RePrintCheckoutBilling,
    acl: "ch_checkout_billing_show",
    execution_type: 12,
  },
  {
    path: "/checkout/re-print-payment",
    component: RePrintCheckoutPaymentSlip,
    acl: "ch_checkout_receipt_show",
    execution_type: 13,
  },

  //SHIFT
  {
    path: "/shift/proses-setoran",
    component: SetoranMingguan,
    acl: "sf_setoran_process_setoran_show",
    execution_type: 14,
  },
  {
    path: "/shift/konfirmasi-setoran",
    component: SetoranConfirmation,
    acl: "sf_setoran_confirm_setoran_show",
    execution_type: 15,
  },
  {
    path: "/shift/approve-setoran",
    component: SetoranApproval,
    acl: "sf_setoran_approve_setoran_show",
    execution_type: 16,
  },

  //HOUSEKEEPING
  {
    path: "/housekeeping/room-action",
    component: RoomAction,
    acl: "hk_room_act_show",
    execution_type: 17,
  },
  {
    path: "/housekeeping/room-inspection",
    component: RoomInspection,
    acl: "hk_inspection_show",
    execution_type: 18,
  },
  {
    path: "/housekeeping/room-management",
    component: RoomChangeStatus,
    acl: "hk_room_list_show",
    execution_type: 19,
  },

  //MASTERDATA
  {
    path: "/master-data/room-allocation",
    component: RoomList,
    acl: "mt_room_mgmt_show",
    execution_type: 20,
  },
  {
    path: "/master-data/bed-type",
    component: BedType,
    acl: "mt_bed_type_show",
    execution_type: 21,
  },
  {
    path: "/master-data/loss-and-breakage",
    component: Compensation,
    acl: "mt_loss_show",
    execution_type: 22,
  },
  {
    path: "/master-data/shift-management",
    component: ShiftManagement,
    acl: "mt_shift_mgmt_show",
    execution_type: 23,
  },

  //REPORT
  {
    path: "/report/guest-checkin",
    component: GuestCheckin,
    acl: "rp_guest_activity_show",
    execution_type: 24,
  },
  {
    path: "/report/shift-report",
    component: ShiftReport,
    acl: "rp_the_shift_show",
    execution_type: 25,
  },
  {
    path: "/report/housekeeping-report",
    component: HousekeepingReport,
    acl: "rp_house_keeping_show",
    execution_type: 26,
  },
  {
    path: "/report/history-setoran",
    component: HistoryTransfer,
    acl: "rp_setoran_cash_show",
    execution_type: 27,
  },
  {
    path: "/report/breakage-report",
    component: BreakageReport,
    acl: "rp_breakage_report_show",
    execution_type: 28,
  },
  {
    path: "/report/booking-forecast",
    component: BookingForecast,
    acl: "rp_booking_forecast_show",
    execution_type: 29,
  },
  // {
  //   path: "/report/rooming-list",
  //   component: RoomingList,
  //   acl: "rp_rooming_list_show",
  //   execution_type: 30,
  // },

  //LOSSFOUND
  {
    path: "/loss-n-found/loss-found-form",
    component: LossFoundForm,
    acl: "lf_lost_found_show",
    execution_type: 31,
  },
  {
    path: "/loss-n-found/loss-found-list",
    component: LossFoundList,
    acl: "lf_lost_found_show",
    execution_type: 32,
  },
];

export const HMSContent = () => {
  return (
    <>
      {routerComponent?.map((item, i) => {
        return (
          <PrivateComponent
            path={item.path}
            component={item.component}
            acl={item.acl}
            key={i}
          />
        );
      })}
    </>
  );
};
