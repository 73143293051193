import React, { useEffect, useRef, useState } from "react";
import {
  addDays,
  convertTimeDuration,
  filterStatusRoomCustom,
  formatDate,
  getClientTime,
  getClientTimeWithoutSecond,
  getServerTimeWithoutDays,
  getTimezoneOffsetClient,
  isEmpty,
  showMoreGuest,
  tableCol,
} from "../../utils";
import {
  getCsvFile,
  getData,
  getErr,
  safeErrCode,
} from "../../utils/api-request";
import { EmptyData, MobileEmptyData } from "../Empty/EmptyData";
import { Loading } from "../../common/Loading";
import InfiniteScroll from "react-infinite-scroll-component";
import { comboBoxDownloadReport } from "../../utils/helper";
import { mobileSearchInput, searchInput } from "../booking/bookingHelper";
import ReactDatePicker from "react-datepicker";
import { Icon } from "@iconify/react";
import SelectOpt from "../../common/SelectOpt";
import PopupModal from "../../common/PopupModal";
import { config, iconUrl } from "../../config";
import { filterHousekeepingStatus } from "./common/utils";
import NotifMessage, { ButtonClose } from "../../common/Notif";
import {
  DateRangeFilterMobile,
  DateRangeFilterNew,
} from "../../common/DateRange";
import { useContext } from "react";
import { BreadcrumbContext } from "../../common/BreadcrumbContext";
import { is_sub_menu_action } from "../../utils/uac";
import { img_fallback } from "../../utils/ui";
import { IS_MOBILE } from "../../common/Responsive";
import { MobileCardList } from "../../common/CardList";
import { BottomSheet } from "react-spring-bottom-sheet";
import { t } from "../../common/ErrorMessage";

const FONT_SIZE = IS_MOBILE ? "f12-vw" : "f8-vw";
const FONT_SIZE_HEADER = IS_MOBILE ? "f14-vw" : "f10-vw";

const TableReport = ({ list, getDetailList }) => {
  const timezone =
    localStorage?.getItem("timezone") && localStorage?.getItem("timezone");
  return (
    <>
      <div className="table-list table-responsive-sm">
        <table
          className="table table-sm "
          style={{
            borderCollapse: "separate",
            borderSpacing: 0,
          }}
        >
          <thead
            className="f8-vw "
            style={{ position: "sticky", top: 0, zIndex: 1 }}
          >
            <tr
              className="text-center text-white"
              style={{ backgroundColor: "#0071A4" }}
            >
              <th className=" align-middle" rowSpan={2}>
                ID
              </th>
              <th className="align-middle" rowSpan={2}>
                Created at
              </th>
              <th className="align-middle" rowSpan={2}>
                Room No
              </th>
              <th className="align-middle" rowSpan={2}>
                Room Type
              </th>
              <th className="align-middle" rowSpan={2}>
                Bed Type
              </th>
              <th className="align-middle" rowSpan={1} colSpan={4}>
                Cleaning
              </th>
              <th className="align-middle" rowSpan={2}>
                Reason
              </th>
              <th className="align-middle" rowSpan={2}>
                <div>Cleaning</div>
                <div>Duration (Jam)</div>
              </th>
              <th className="align-middle" rowSpan={1} colSpan={3}>
                Inspected by
              </th>
            </tr>
            <tr
              className="text-center text-white"
              style={{ backgroundColor: "#0071A4" }}
            >
              <th>Start</th>
              <th>Start by</th>
              <th>End</th>
              <th>End by</th>
              <th>Name</th>
              <th>Created at</th>
              <th>Note</th>
            </tr>
          </thead>

          <tbody className="f8-vw">
            {list?.map((item, index) => (
              <tr
                key={index}
                style={{
                  cursor: "pointer",
                }}
                className={`click-table ${tableCol(index)}`}
                onClick={() => getDetailList(item?.id)}
              >
                <td className="text-center font-weight-bold align-middle">
                  {item?.id}
                </td>
                <td className="text-center align-middle">
                  <div>{getServerTimeWithoutDays(item?.ts, timezone)}</div>
                  <div className="font-weight-bold">
                    {getClientTimeWithoutSecond(item?.ts, timezone) +
                      " " +
                      getTimezoneOffsetClient(timezone)}
                  </div>
                </td>
                <td className="text-center align-middle">
                  {item?.room_number}
                </td>
                <td className="text-left align-middle">{item?.room_type}</td>
                <td className="text-left align-middle">{item?.bed_size}</td>
                <td className="text-center">
                  <div>
                    {getServerTimeWithoutDays(item?.start_cleaning, timezone)}
                  </div>
                  <div className="font-weight-bold">
                    {getClientTime(item?.start_cleaning, timezone) +
                      " " +
                      getTimezoneOffsetClient(timezone)}
                  </div>
                </td>
                <td className="text-left align-middle">
                  {item?.start_cleaning_by_name}
                </td>
                <td className="text-center">
                  <div>
                    {getServerTimeWithoutDays(item?.end_cleaning, timezone)}
                  </div>
                  <div className="font-weight-bold">
                    {getClientTime(item?.end_cleaning, timezone) +
                      " " +
                      getTimezoneOffsetClient(timezone)}
                  </div>
                </td>
                <td className="text-left align-middle">
                  {item?.end_cleaning_by_name}
                </td>

                <td
                  className={`align-middle ${
                    item?.reasons ? "text-left" : "text-center"
                  }`}
                >
                  {item?.reasons ? showMoreGuest(item?.reasons, 25) : "-"}
                </td>
                <td className="text-center align-middle">
                  {item?.cleaning_duration}
                </td>
                <td className="text-left align-middle">
                  {item?.inspect_by_name}
                </td>
                <td className="text-center align-middle">
                  {item?.ts_inspect ? (
                    <>
                      <div>
                        {getServerTimeWithoutDays(item?.ts_inspect, timezone)}
                      </div>
                      <div className="font-weight-bold">
                        {getClientTime(item?.ts_inspect, timezone) +
                          " " +
                          getTimezoneOffsetClient(timezone)}
                      </div>
                    </>
                  ) : (
                    "-"
                  )}
                </td>
                <td className={"text-center align-middle"}>
                  {item?.inspection_note
                    ? showMoreGuest(item?.inspection_note, 25)
                    : "-"}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
};

const DetailPopup = ({ close, list, setShowFullscreen, openFullscreen }) => {
  const detailView = (title, value, flex = 4) => {
    return (
      <div className={`d-flex flex-row mt-1 ${FONT_SIZE}`}>
        <div className={`col-${flex} px-0`} style={{ color: "#707070" }}>
          {title}
        </div>
        <div className="col px-0 fw-600">: {value ? value : "-"}</div>
      </div>
    );
  };
  const detailInspection = (title, value) => {
    return (
      <div className={"d-flex my-2 " + FONT_SIZE}>
        <div className="col-5 px-0" style={{ color: "#707070" }}>
          {title}
        </div>
        <div className="col px-0 fw-600">: {value ? value : "-"}</div>
      </div>
    );
  };
  const previewImg = (title, value) => {
    return (
      <div className="my-2">
        <div className={"fw-700 " + FONT_SIZE}>{title}</div>
        <div onClick={handleFullscreen}>
          <img
            src={config.img_host + value}
            alt={title}
            style={{
              width: "100%",
              height: "12rem",
              objectFit: "contain",
              borderRadius: 5,
            }}
            loading="lazy"
            onError={({ currentTarget }) => {
              currentTarget.onerror = null; // prevents looping
              currentTarget.src = img_fallback;
            }}
          />
        </div>
      </div>
    );
  };

  const img_rooms_galery = [
    {
      original: list?.end_room_file_path,
      thumbnail: list?.end_room_file_path,
      description: "Foto Kamar Sesudah",
    },
    {
      original: list?.hasil_making_bed,
      thumbnail: list?.hasil_making_bed,
      description: "Making Bed",
    },
    {
      original: list?.end_bathroom_file_path,
      thumbnail: list?.end_bathroom_file_path,
      description: "Foto Kamar Mandi Sesudah",
    },
  ];

  const handleFullscreen = (val) => {
    setShowFullscreen(val.target.src);
    openFullscreen();
  };

  const DetailCleanMobile = ({
    title,
    action_time,
    action_by,
    note,
    reasons,
    action_clean,
    type,
    bedroom = [],
  }) => {
    return (
      <div className={"d-flex flex-column " + FONT_SIZE}>
        <div className="col px-0">
          <div
            className={"fw-700 py-1 px-2 " + FONT_SIZE_HEADER}
            style={{ backgroundColor: "#DDF8ED" }}
          >
            {title}
          </div>
          <div className="col px-0">
            {detailInspection(action_clean, action_time)}
            {detailInspection("Cleaning by", action_by)}
            <div className={FONT_SIZE}>
              <label htmlFor="" className={"fw-700 purple " + FONT_SIZE}>
                {title} Note
              </label>
              <div className="">{note ? note : "-"}</div>
            </div>
            {reasons && (
              <div className={FONT_SIZE}>
                <label htmlFor="" className={"fw-700 purple " + FONT_SIZE}>
                  Alasan Housekeeping Berbeda
                </label>
                <div className="">{reasons ? reasons : "-"}</div>
              </div>
            )}
          </div>
        </div>
        <div className="col px-0 ">
          <div className="d-flex flex-column">
            {type === "start" ? (
              <>
                <div className="col px-0">
                  {previewImg("Foto kamar sebelum", list?.start_room_file_path)}
                </div>
                <div className="col px-0">
                  {previewImg(
                    "Foto kamar mandi sebelum",
                    list?.start_bathroom_file_path
                  )}
                </div>
              </>
            ) : (
              <>
                <div className="col px-0">
                  <div className="d-flex flex-column">
                    {bedroom?.length > 0 &&
                      bedroom.map((item, i) => (
                        <div
                          key={i}
                          className={`col px-0 ${
                            (i + 1) % 2 === 0 ? "pr-0" : "pl-0"
                          }`}
                        >
                          {previewImg(item.description, item.original)}
                        </div>
                      ))}
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    );
  };

  const DetailClean = ({
    title,
    action_time,
    action_by,
    note,
    reasons,
    action_clean,
    type,
    bedroom = [],
  }) => {
    return (
      <div className="d-flex flex-row f7-vw">
        <div className="col-4 pl-0">
          <div className="f10-vw purple font-weight-bold">{title}</div>
          <div className="col px-0">
            {detailInspection(action_clean, action_time)}
            {detailInspection("Cleaning by", action_by)}
            <div className={FONT_SIZE}>
              <label htmlFor="" className={"fw-700 " + FONT_SIZE}>
                Catatan
              </label>
              <div className="">{note ? note : "-"}</div>
            </div>
            {reasons && (
              <div className={FONT_SIZE}>
                <label htmlFor="" className={"fw-700 " + FONT_SIZE}>
                  Alasan Housekeeping Berbeda
                </label>
                <div className="">{reasons ? reasons : "-"}</div>
              </div>
            )}
          </div>
        </div>
        <div className="col-8 px-2">
          <div className="d-flex flex-row">
            {type === "start" ? (
              <>
                <div className="col-6 pl-0">
                  {previewImg("Foto kamar sebelum", list?.start_room_file_path)}
                </div>
                <div className="col-6 pr-0">
                  {previewImg(
                    "Foto kamar mandi sebelum",
                    list?.start_bathroom_file_path
                  )}
                </div>
              </>
            ) : (
              <>
                <div className="col px-0">
                  <div className="d-flex flex-wrap justify-content-between">
                    {bedroom?.length > 0 &&
                      bedroom.map((item, i) => (
                        <div
                          key={i}
                          className={`col-6 ${
                            (i + 1) % 2 === 0 ? "pr-0" : "pl-0"
                          }`}
                        >
                          {previewImg(item.description, item.original)}
                        </div>
                      ))}
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    );
  };
  const timezone =
    localStorage?.getItem("timezone") && localStorage?.getItem("timezone");

  return (
    <>
      {IS_MOBILE ? (
        <div
          className="d-flex flex-column p-2 f12-vw"
          style={{ width: "21rem" }}
        >
          <div className="col f16-vw font-weight-bold">
            <div className="d-flex justify-content-between align-items-center">
              <div className="">
                <span className="mr-2">
                  <Icon
                    icon={iconUrl.informationIcon}
                    color="orange"
                    width={"1.5rem"}
                  />
                </span>
                <span className="dark-blue">Inspection Detail</span>
              </div>
              <div>
                <button
                  className="btn btn-sm bg-transparent"
                  onClick={() => close()}
                >
                  <Icon
                    icon={iconUrl.timesIcon}
                    width="25"
                    height="25"
                    color="#742b9e"
                  />
                </button>
              </div>
            </div>
            <hr className="separator-blue" />
          </div>
          <div
            className="scroll-popup hide-scrollbar"
            style={{ maxHeight: "25rem" }}
          >
            <div className="col px-0">
              <div className="d-flex flex-column">
                <div className="col">
                  {detailView("ID", list?.id, 5)}
                  {detailView(
                    "Tanggal",
                    getServerTimeWithoutDays(list?.ts, timezone),
                    5
                  )}
                  {detailView("Room No", list?.room_number, 5)}
                </div>
                <div className="col">
                  {detailView("Floor", list?.floor, 5)}
                  {detailView("Room Type", list?.room_type_name, 5)}
                  {detailView(
                    "Bed Type",
                    list?.bed_type_name ? `${list?.bed_type_name}` : "-",
                    5
                  )}
                  {detailView(
                    "Bed Size",
                    list?.bed_type_name ? `${list?.bed_size}` : "-",
                    5
                  )}

                  {detailView(
                    "Room Status",
                    <span
                      className={`px-2 ${
                        filterStatusRoomCustom(list?.room_status)?.className
                      }`}
                      style={{
                        borderRadius: 3,
                        width: "3rem",
                        fontWeight: "bold",
                      }}
                    >
                      {filterStatusRoomCustom(list?.room_status)?.label}
                    </span>,
                    5
                  )}
                  {detailView(
                    "Housekeeping Status",
                    <span
                      className={`px-2 ${
                        filterHousekeepingStatus(list?.hk_status)?.className
                      }`}
                      style={{
                        borderRadius: 3,
                        width: "3rem",
                        fontWeight: "bold",
                      }}
                    >
                      {filterHousekeepingStatus(list?.hk_status)?.label}
                    </span>,
                    5
                  )}
                  {detailView(
                    "Last Update",
                    <>
                      {list?.last_update ? (
                        <>
                          <span>
                            {getServerTimeWithoutDays(
                              list?.last_update,
                              timezone
                            )}
                          </span>
                          <span className="font-weight-bold">
                            {` ${getClientTimeWithoutSecond(
                              list?.last_update,
                              timezone
                            )} ${getTimezoneOffsetClient(timezone)}`}
                          </span>
                        </>
                      ) : (
                        "-"
                      )}
                    </>,
                    5
                  )}
                </div>
              </div>
              <hr className="separator-blue mx-3" />
            </div>
            <div className="col">
              <div className="d-flex flex-column">
                <div className="col px-0">
                  <DetailCleanMobile
                    title={"Start Cleaning"}
                    action_clean={"Action Start"}
                    type="start"
                    action_by={list?.start_cleaning_by_name}
                    note={list?.start_note}
                    action_time={
                      <>
                        {list?.start_cleaning ? (
                          <>
                            <span>
                              {getServerTimeWithoutDays(
                                list?.start_cleaning,
                                timezone
                              )}
                            </span>
                            <span className="font-weight-bold">
                              {` ${getClientTime(
                                list?.start_cleaning,
                                timezone
                              )} ${getTimezoneOffsetClient(timezone)}`}
                            </span>
                          </>
                        ) : (
                          "-"
                        )}
                      </>
                    }
                  />
                </div>
                <div>
                  <hr className="separator-blue" />
                </div>
                <div className="col px-0">
                  <DetailCleanMobile
                    title={"End Cleaning"}
                    action_clean={"Action End"}
                    type="end"
                    action_by={list?.end_cleaning_by_name}
                    reasons={list?.reasons}
                    note={list?.end_note}
                    action_time={
                      <>
                        {list?.start_cleaning ? (
                          <>
                            <span>
                              {getServerTimeWithoutDays(
                                list?.start_cleaning,
                                timezone
                              )}
                            </span>
                            <span className="font-weight-bold">
                              {` ${getClientTime(
                                list?.start_cleaning,
                                timezone
                              )} ${getTimezoneOffsetClient(timezone)}`}
                            </span>
                          </>
                        ) : (
                          "-"
                        )}
                      </>
                    }
                    bedroom={img_rooms_galery}
                  />
                </div>
                <div>
                  <hr className="separator-blue" />
                </div>
                <div className="col px-0">
                  <div className={"d-flex flex-column " + FONT_SIZE}>
                    <div className="col px-0">
                      <div
                        className={"fw-700 py-1 px-2 " + FONT_SIZE_HEADER}
                        style={{ backgroundColor: "#DDF8ED" }}
                      >
                        Inspection
                      </div>
                    </div>
                    <div className="col px-0">
                      {detailInspection(
                        "Created at",
                        list?.ts_inspect ? (
                          <>
                            <span>
                              {getServerTimeWithoutDays(
                                list?.ts_inspect,
                                timezone
                              )}
                            </span>
                            <span className="font-weight-bold">
                              {` ${getClientTimeWithoutSecond(
                                list?.ts_inspect,
                                timezone
                              )} ${getTimezoneOffsetClient(timezone)}`}
                            </span>
                          </>
                        ) : (
                          "-"
                        )
                      )}
                      {detailInspection("Inspect by", list?.inspect_by_name)}
                      <div className={FONT_SIZE}>
                        <label
                          htmlFor=""
                          className={"fw-700 purple " + FONT_SIZE}
                        >
                          Inspect Note
                        </label>
                        <div className="">
                          {list?.inspection_note ? list?.inspection_note : "-"}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col">
            <hr className="separator-blue" />
            <div className="d-flex justify-content-center my-2">
              <div className="col-3 px-0">
                <button
                  className="btn btn-sm btn-block bg-green rounded-input text-white"
                  onClick={() => close()}
                >
                  OK
                </button>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div
          className="d-flex flex-column p-2 f12-vw"
          style={{ width: "55rem" }}
        >
          <div className="col f14-vw font-weight-bold">
            <div className="d-flex justify-content-between align-items-center">
              <div className="">
                <span className="mr-2">
                  <Icon
                    icon={iconUrl.informationIcon}
                    color="orange"
                    width={"1.5rem"}
                  />
                </span>
                <span className="dark-blue">Inspection Detail</span>
              </div>
              <div>
                <button
                  className="btn btn-sm bg-transparent"
                  onClick={() => close()}
                >
                  <Icon
                    icon={iconUrl.timesIcon}
                    width="25"
                    height="25"
                    color="#742b9e"
                  />
                </button>
              </div>
            </div>
            <hr className="separator-blue" />
          </div>
          <div className="scroll-popup" style={{ maxHeight: "20rem" }}>
            <div className="col">
              <div className="d-flex flex-row">
                <div className="col pl-0">
                  {detailView("ID", list?.id, 5)}
                  {detailView(
                    "Tanggal",
                    getServerTimeWithoutDays(list?.ts, timezone),
                    5
                  )}
                  {detailView("Room No", list?.room_number, 5)}
                </div>
                <div className="col">
                  {detailView("Floor", list?.floor, 5)}
                  {detailView("Room Type", list?.room_type_name, 5)}
                  {detailView(
                    "Bed Type",
                    list?.bed_type_name ? `${list?.bed_type_name}` : "-",
                    5
                  )}
                  {detailView(
                    "Bed Size",
                    list?.bed_type_name ? `${list?.bed_size}` : "-",
                    5
                  )}
                </div>
                <div className="col pr-0">
                  {detailView(
                    "Room Status",
                    <span
                      className={`px-2 ${
                        filterStatusRoomCustom(list?.room_status)?.className
                      }`}
                      style={{
                        borderRadius: 3,
                        width: "3rem",
                        fontWeight: "bold",
                      }}
                    >
                      {filterStatusRoomCustom(list?.room_status)?.label}
                    </span>,
                    5
                  )}
                  {detailView(
                    "Housekeeping Status",
                    <span
                      className={`px-2 ${
                        filterHousekeepingStatus(list?.hk_status)?.className
                      }`}
                      style={{
                        borderRadius: 3,
                        width: "3rem",
                        fontWeight: "bold",
                      }}
                    >
                      {filterHousekeepingStatus(list?.hk_status)?.label}
                    </span>,
                    5
                  )}
                  {detailView(
                    "Last Update",
                    <>
                      {list?.last_update ? (
                        <>
                          <span>
                            {getServerTimeWithoutDays(
                              list?.last_update,
                              timezone
                            )}
                          </span>
                          <span className="font-weight-bold">
                            {` ${getClientTimeWithoutSecond(
                              list?.last_update,
                              timezone
                            )} ${getTimezoneOffsetClient(timezone)}`}
                          </span>
                        </>
                      ) : (
                        "-"
                      )}
                    </>,
                    5
                  )}
                </div>
              </div>
              <hr className="separator-blue" />
            </div>
            <div className="col">
              <div className="d-flex flex-column">
                <div className="col px-0">
                  <DetailClean
                    title={"Start Cleaning"}
                    action_clean={"Action Start"}
                    type="start"
                    action_by={list?.start_cleaning_by_name}
                    note={list?.start_note}
                    action_time={
                      <>
                        {list?.start_cleaning ? (
                          <>
                            <span>
                              {getServerTimeWithoutDays(
                                list?.start_cleaning,
                                timezone
                              )}
                            </span>
                            <span className="font-weight-bold">
                              {` ${getClientTimeWithoutSecond(
                                list?.start_cleaning,
                                timezone
                              )} ${getTimezoneOffsetClient(timezone)}`}
                            </span>
                          </>
                        ) : (
                          "-"
                        )}
                      </>
                    }
                  />
                </div>
                <div>
                  <hr className="separator-blue" />
                </div>
                <div className="col px-0">
                  <DetailClean
                    title={"End Cleaning"}
                    action_clean={"Action End"}
                    type="end"
                    action_by={list?.end_cleaning_by_name}
                    reasons={list?.reasons}
                    note={list?.end_note}
                    action_time={
                      <>
                        {list?.start_cleaning ? (
                          <>
                            <span>
                              {getServerTimeWithoutDays(
                                list?.start_cleaning,
                                timezone
                              )}
                            </span>
                            <span className="font-weight-bold">
                              {` ${getClientTimeWithoutSecond(
                                list?.start_cleaning,
                                timezone
                              )} ${getTimezoneOffsetClient(timezone)}`}
                            </span>
                          </>
                        ) : (
                          "-"
                        )}
                      </>
                    }
                    bedroom={img_rooms_galery}
                  />
                </div>

                <div>
                  <hr className="separator-blue" />
                </div>
                <div className="col px-0">
                  <div className="d-flex flex-row">
                    <div className="col-4 pl-0">
                      <div className="f10-vw purple font-weight-bold">
                        Inspection
                      </div>
                      <div className="col px-0">
                        {detailInspection(
                          "Created at",
                          list?.ts_inspect ? (
                            <>
                              <span>
                                {getServerTimeWithoutDays(
                                  list?.ts_inspect,
                                  timezone
                                )}
                              </span>
                              <span className="font-weight-bold">
                                {` ${getClientTimeWithoutSecond(
                                  list?.ts_inspect,
                                  timezone
                                )} ${getTimezoneOffsetClient(timezone)}`}
                              </span>
                            </>
                          ) : (
                            "-"
                          )
                        )}
                        {detailInspection("Inspect by", list?.inspect_by_name)}
                      </div>
                    </div>
                    <div className="col">
                      <div className={FONT_SIZE}>
                        <label
                          htmlFor=""
                          className={"fw-700 purple " + FONT_SIZE}
                        >
                          Catatan
                        </label>
                        <div className="">
                          {list?.inspection_note ? list?.inspection_note : "-"}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col">
            <hr className="separator-blue" />
            <div className="d-flex justify-content-center my-2">
              <div className="col-3 px-0">
                <button
                  className="btn btn-sm btn-block bg-green rounded-input text-white"
                  onClick={() => close()}
                >
                  OK
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

const HousekeepingReport = () => {
  const {
    setpathLocation,
    openButtonSheet,
    setOpenButtonSheet,
    setShowFilter,
  } = useContext(BreadcrumbContext);
  const [roomNumber, setRoomNumber] = useState("");
  const link = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const [reportList, setReportList] = useState([]);
  const [loadingList, setLoadingList] = useState(false);
  const [errorList, setErrorList] = useState("");
  const [errorDetail, setErrorDetail] = useState("");
  const [hasMore, sethasMore] = useState(false);
  const [mode, setMode] = useState("");
  const mounted = useRef(false);
  const [durationFilter, setDurationFilter] = useState([]);
  const [durationFromSelected, setDurationFromSelected] = useState([]);
  const [durationToSelected, setDurationToSelected] = useState([]);
  const [detailList, setDetailList] = useState({});
  const [cleaningBy, setCleaningBy] = useState("");
  const [inspectedBy, setInspectedBy] = useState("");
  const [openModalFullscreen, setOpenModalFullscreen] = useState(false);
  const [showFullscreen, setShowFullscreen] = useState("");
  const [createAt, setCreateAt] = useState({
    start: addDays(new Date(), -7),
    end: new Date(),
  });
  const handleRoomNumber = (val) => {
    setRoomNumber(val.target.value);
  };
  const [range, setRange] = useState({
    page: 1,
    start: addDays(new Date(), -7),
    end: new Date(),
  });

  const openModal = () => {
    setIsOpen(!isOpen);
  };

  const checkNextPage = () => {
    if (hasMore) {
      let page = range.page;
      let pagi = page + 1;
      setRange({ ...range, page: pagi });
      getReportList(
        pagi,
        false,
        range.start,
        range.end,
        createAt.start,
        createAt.end
      );
    }
  };

  const getReportList = (pagi, is_init, start, end, createStart, createEnd) => {
    let datas = [];
    const params = {
      ...(!!start && { start_date: formatDate(start) }),
      ...(!!end && { end_date: formatDate(end) }),
      ...(!!createStart && { created_start: formatDate(createStart) }),
      ...(!!createEnd && { created_end: formatDate(createEnd) }),
      ...(!!roomNumber && { room_number: roomNumber }),
      ...(!!inspectedBy && { inspectby: inspectedBy }),
      ...(!!cleaningBy && { cleaningby: cleaningBy }),
      ...(!!durationFromSelected?.value && {
        duration_start: durationFromSelected?.value,
      }),
      ...(!!durationToSelected?.value && {
        duration_end: durationToSelected?.value,
      }),
    };
    let paramsFormatted = null;
    if (!isEmpty(params)) {
      paramsFormatted = new URLSearchParams(params);
    }
    let url = `/api/v1/report/hk_report?page=${pagi}&${
      paramsFormatted !== null ? `&${paramsFormatted}` : ""
    }`;
    setLoadingList(true);
    getData(url)
      .then((resp) => {
        if (mounted.current) {
          if (resp.data) {
            setLoadingList(false);
            setErrorList("");
            if (is_init) {
              datas = resp.data.data;
            } else {
              let x = [];
              x = reportList;
              datas = [...x, ...resp.data.data];
            }
            sethasMore(resp.data.next_page);
            setReportList(datas);
          } else {
            setLoadingList(false);
            resp.json().then((err) => {
              setErrorList(t(safeErrCode(err)));
            });
            setReportList([]);
          }
        }
      })
      .catch((error) => {
        let err = getErr(error);
        setLoadingList(false);
        setErrorList(t(err));
        console.log(err);
      });
  };

  const getDetailList = (id) => {
    let url = "/api/v1/report/hk_report_detail?id=" + id;
    getData(url)
      .then((resp) => {
        if (resp.data) {
          setDetailList(resp.data[0]);
          openModal();
          setMode("detail");
        } else {
          resp.json().then((err) => {
            setErrorDetail(t(safeErrCode(err)));
          });
          openModal();
          setMode("error");
        }
      })
      .catch((error) => {
        console.log(error);
        openModal();
        setMode("detail");
      });
  };

  const handleFromDuration = (val) => {
    if (!val) {
      setDurationFromSelected([]);
    } else {
      setDurationFromSelected(val);
    }
  };
  const handleToDuration = (val) => {
    if (!val) {
      setDurationToSelected([]);
    } else {
      setDurationToSelected(val);
    }
  };
  const handleCleaningBy = (val) => {
    setCleaningBy(val.target.value);
  };
  const handleInspectedBy = (val) => {
    setInspectedBy(val.target.value);
  };
  const handleCreateStart = (val) => {
    setCreateAt({ ...createAt, start: val, end: addDays(val, 7) });
  };
  const handleCreateEnd = (val) => {
    setCreateAt({ ...createAt, end: val });
  };

  const handleEnter = (e) => {
    if (e.key === "Enter") {
      setReportList([]);
      setRange({ ...range, page: 1 });
      getReportList(
        1,
        true,
        range.start,
        range.end,
        createAt.start,
        createAt.end
      );
    }
  };

  const handleDownloadCSV = (e) => {
    if (
      link.current.href &&
      link.current.href.substr(link.current.href.length - 6) !== "no_use"
    ) {
      return;
    }
    if (link.current.href.substr(link.current.href.length - 6) === "no_use") {
      e.preventDefault();
    }
    getCsvFile(
      `/api/v1/report/hk_report?start_date=${formatDate(
        range?.start
      )}&end_date=${formatDate(
        range?.end
      )}&room_number=${roomNumber}&duration_start=${
        durationFromSelected?.value
      }&duration_end=${
        durationToSelected?.value
      }&cleaningby=${cleaningBy}&inspectby=${inspectedBy}&created_start=${formatDate(
        createAt.start
      )}&created_end=${formatDate(createAt.end)}&csvoutput=1`
    )
      .then((resp) => {
        let now = new Date();
        let filenameServ = "housekeeping-report-" + formatDate(now) + ".csv";
        if (
          resp.headers !== undefined &&
          resp.headers !== null &&
          resp.headers.length > 1
        ) {
          filenameServ = resp.headers
            .get("Content-Disposition")
            .split("filename=")[1];
        }
        let filename = filenameServ;
        // console.log("filename-serv-download", filenameServ);
        resp.blob().then((blob) => {
          let dx = new Blob([blob]);
          const href = window.URL.createObjectURL(dx);
          link.current.download = filename;
          link.current.href = href;
          link.current.click();
          link.current.download = "no_use";
          link.current.href = "no_use";
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const resetFilter = () => {
    setRange({
      ...range,
      start: addDays(new Date(), -7),
      end: new Date(),
      page: 1,
    });
    setCreateAt({
      ...createAt,
      start: addDays(new Date(), -7),
      end: new Date(),
    });
    setRoomNumber("");
    setInspectedBy("");
    setCleaningBy("");
    setDurationFromSelected([]);
    setDurationToSelected([]);
  };

  const handleCalendarOpen = () => {
    document.addEventListener(
      "touchstart",
      (event) => {
        event.stopPropagation();
      },
      true
    );
  };

  useEffect(() => {
    let d = [];
    for (let i = 0; i <= 23; i++) {
      d.push({ value: i, label: i });
    }
    setDurationFilter(d);
  }, []);

  useEffect(() => {
    if (
      roomNumber === "" &&
      durationFromSelected?.value === undefined &&
      durationToSelected?.value === undefined &&
      inspectedBy === "" &&
      cleaningBy === ""
    ) {
      getReportList(
        1,
        true,
        range.start,
        range.end,
        createAt.start,
        createAt.end
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    cleaningBy,
    durationFromSelected?.value,
    durationToSelected?.value,
    inspectedBy,
    roomNumber,
  ]);

  useEffect(() => {
    setpathLocation("/report/housekeeping");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setShowFilter(true);
    return () => {
      setShowFilter(false);
    };
  }, [setShowFilter]);

  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);

  useEffect(() => {
    if (isOpen || openModalFullscreen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
    return () => {
      document.body.style.overflow = "unset";
    };
  }, [isOpen, openModalFullscreen]);

  return (
    <>
      {IS_MOBILE ? (
        <div className="d-flex flex-column px-3 py-1 mb-5">
          <div className="col px-1">
            {is_sub_menu_action("rp_house_keeping_export") && (
              <div className="mt-2">
                <div className="d-flex">
                  {comboBoxDownloadReport(link, handleDownloadCSV)}
                </div>
              </div>
            )}
          </div>
          <div className="col px-1">
            <InfiniteScroll
              dataLength={reportList.length}
              next={checkNextPage}
              hasMore={hasMore}
              loader={<Loading />}
              endMessage={
                reportList?.length < 1 && (
                  <MobileEmptyData
                    loading={loadingList}
                    errMessage={errorList}
                    height="40vh"
                  />
                )
              }
            >
              {reportList.map((item, i) => {
                const timezone = localStorage?.getItem("timezone");
                return (
                  <div onClick={() => getDetailList(item?.id)} key={item?.id}>
                    <MobileCardList
                      title={
                        <>
                          <div
                            className="f12-vw fw-500"
                            style={{ color: "#959595" }}
                          >
                            Create At
                          </div>
                          <div className="">
                            <span className="font-weight-bold">
                              {`${getServerTimeWithoutDays(
                                item?.ts,
                                timezone
                              )}, ${getClientTimeWithoutSecond(
                                item?.ts,
                                timezone
                              )} ${getTimezoneOffsetClient(timezone)}`}
                            </span>
                          </div>
                        </>
                      }
                      fontSizeHeader="f12-vw"
                    >
                      <div className="card-hms-list">
                        <div className="col-4 px-0 grey-text">ID</div>
                        <div className="col pr-0 f12-vw black-value">
                          {item?.id}
                        </div>
                      </div>
                      <div className="card-hms-list">
                        <div className="col-4 px-0 grey-text">Room Number</div>
                        <div className="col pr-0 f12-vw black-value">
                          {item?.room_number}
                        </div>
                      </div>
                      <div className="card-hms-list">
                        <div className="col-4 px-0 grey-text">Room Type</div>
                        <div className="col pr-0 f12-vw black-value">
                          {item?.room_type}
                        </div>
                      </div>
                      <div className="card-hms-list">
                        <div className="col-4 px-0 grey-text">Bed Type</div>
                        <div className="col pr-0 f12-vw black-value">
                          {item?.bed_size}
                        </div>
                      </div>
                      <div className="card-hms-list">
                        <div className="col-4 px-0 purple fw-700">Cleaning</div>
                      </div>
                      <div className="card-hms-list">
                        <div className="col-4 px-0 grey-text">Start Time</div>
                        <div className="col pr-0 f12-vw black-value">
                          <span>
                            {`${getServerTimeWithoutDays(
                              item?.start_cleaning,
                              timezone
                            )}, `}
                          </span>
                          <span className="font-weight-bold">
                            {`${getClientTimeWithoutSecond(
                              item?.start_cleaning,
                              timezone
                            )} ${getTimezoneOffsetClient(timezone)}`}
                          </span>
                        </div>
                      </div>
                      <div className="card-hms-list">
                        <div className="col-4 px-0 grey-text">
                          Start Cleaning By
                        </div>
                        <div className="col pr-0 f12-vw black-value">
                          {item?.start_cleaning_by_name}
                        </div>
                      </div>
                      <div className="card-hms-list">
                        <div className="col-4 px-0 grey-text">End Time</div>
                        <div className="col pr-0 f12-vw black-value">
                          <span>
                            {`${getServerTimeWithoutDays(
                              item?.end_cleaning,
                              timezone
                            )}, `}
                          </span>
                          <span className="font-weight-bold">
                            {`${getClientTimeWithoutSecond(
                              item?.end_cleaning,
                              timezone
                            )} ${getTimezoneOffsetClient(timezone)}`}
                          </span>
                        </div>
                      </div>
                      <div className="card-hms-list">
                        <div className="col-4 px-0 grey-text">
                          End Cleaning By
                        </div>
                        <div className="col pr-0 f12-vw black-value">
                          {item?.end_cleaning_by_name}
                        </div>
                      </div>
                      {item?.reasons && (
                        <div className="card-hms-list">
                          <div className="col-4 px-0 grey-text">Reason</div>
                          <div className="col pr-0 f12-vw black-value">
                            {showMoreGuest(item?.reasons, 30)}
                          </div>
                        </div>
                      )}
                      <div className="card-hms-list">
                        <div className="col-4 px-0 grey-text">
                          Cleaning Duration
                        </div>
                        <div className="col pr-0 f12-vw black-value">
                          {convertTimeDuration(item?.cleaning_duration)}
                        </div>
                      </div>
                      <div className="card-hms-list">
                        <div className="col-4 px-0 purple fw-700">
                          Inspection
                        </div>
                      </div>
                      <div className="card-hms-list">
                        <div className="col-4 px-0 grey-text">Inspected By</div>
                        <div className="col pr-0 f12-vw black-value">
                          {item?.inspect_by_name}
                        </div>
                      </div>
                      <div className="card-hms-list">
                        <div className="col-4 px-0 grey-text">Created at</div>
                        <div className="col pr-0 f12-vw black-value">
                          <span>
                            {`${getServerTimeWithoutDays(
                              item?.ts_inspect,
                              timezone
                            )}, `}
                          </span>
                          <span className="font-weight-bold">
                            {`${getClientTimeWithoutSecond(
                              item?.ts_inspect,
                              timezone
                            )} ${getTimezoneOffsetClient(timezone)}`}
                          </span>
                        </div>
                      </div>
                      <div className="card-hms-list">
                        <div className="col-4 px-0 grey-text">Note</div>
                        <div className="col pr-0 f12-vw black-value">
                          {item?.inspection_note
                            ? showMoreGuest(item?.inspection_note, 25)
                            : "-"}
                        </div>
                      </div>
                    </MobileCardList>
                  </div>
                );
              })}
            </InfiniteScroll>
          </div>
        </div>
      ) : (
        <div className="d-flex flex-column px-4 py-1">
          <div className="col px-2">
            <div className="d-flex flex-row justify-content-between">
              <div className="col px-0">
                <div className="d-flex flex-row">
                  <div className="col px-0 mr-2">
                    <label htmlFor="" className="f8-vw m-0 p-0">
                      Created at
                    </label>
                    <DateRangeFilterNew
                      start={createAt.start}
                      end={createAt.end}
                      handleStart={handleCreateStart}
                      handleEnd={handleCreateEnd}
                      minDate={true}
                    />
                  </div>
                  <div className="col-2 px-0 mr-2">
                    <label htmlFor="" className="f8-vw m-0 p-0">
                      Room Number
                    </label>
                    {searchInput(
                      "Nomor Kamar",
                      roomNumber,
                      (e) => handleRoomNumber(e),
                      handleEnter
                    )}
                  </div>
                  <div className="col-2 px-0 mr-2">
                    <label htmlFor="" className="f8-vw m-0 p-0">
                      Cleaning by
                    </label>
                    {searchInput(
                      "Masukkan Nama",
                      cleaningBy,
                      (e) => handleCleaningBy(e),
                      handleEnter
                    )}
                  </div>
                  <div className="col-4 px-0">
                    <div className="d-flex">
                      <div className="col pl-0 pr-1">
                        <label htmlFor="pemesan" className="f8-vw m-0">
                          Start Clean
                        </label>
                        <div
                          className=""
                          style={{
                            border: "1px solid #ced4da",
                            borderRadius: 3,
                            padding: "5px 0",
                          }}
                        >
                          <div className="d-flex px-2 f8-vw align-items-center">
                            <Icon
                              icon={iconUrl.calendarIcon}
                              color="#ef9403"
                              width={15}
                            />
                            <div className="">
                              <ReactDatePicker
                                selected={range.start}
                                onChange={(e) =>
                                  setRange({
                                    ...range,
                                    start: e,
                                    end: addDays(e, 7),
                                  })
                                }
                                startDate={range.start}
                                className={"date-checkin-new f8-vw text-left"}
                                dateFormat="dd MMM yyyy"
                                placeholderText="Pilih Tanggal"
                                onChangeRaw={(e) => {
                                  e.preventDefault();
                                }}
                                onKeyDown={(e) => {
                                  e.preventDefault();
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col pr-0 pl-1">
                        <label htmlFor="pemesan" className="f8-vw m-0">
                          End Clean
                        </label>
                        <div
                          className=""
                          style={{
                            border: "1px solid #ced4da",
                            borderRadius: 3,
                            padding: "5px 0",
                          }}
                        >
                          <div className="d-flex px-2 f8-vw align-items-center">
                            <Icon
                              icon={iconUrl.calendarIcon}
                              color="#ef9403"
                              width={15}
                            />
                            <div className="">
                              <ReactDatePicker
                                selected={range.end}
                                onChange={(e) => setRange({ ...range, end: e })}
                                startDate={range.start}
                                endDate={range.end}
                                className={"date-checkin-new f8-vw text-left"}
                                dateFormat="dd MMM yyyy"
                                placeholderText="Pilih Tanggal"
                                minDate={range.start}
                                onChangeRaw={(e) => {
                                  e.preventDefault();
                                }}
                                onKeyDown={(e) => {
                                  e.preventDefault();
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="d-flex flex-row mt-2">
                  <div className="col-3 pl-0 pr-2">
                    <label htmlFor="" className="f8-vw m-0">
                      Cleaning Duration
                    </label>
                    <div className="d-flex">
                      <div className="col pr-1 pl-0">
                        <SelectOpt
                          optionsValue={durationFilter}
                          onChange={handleFromDuration}
                          placeholder={"From"}
                          value={durationFromSelected}
                          clear={true}
                          menuHeight="10rem"
                        />
                      </div>
                      <div className="col pl-1 pr-0">
                        <SelectOpt
                          optionsValue={durationFilter}
                          onChange={handleToDuration}
                          placeholder={"To"}
                          value={durationToSelected}
                          clear={true}
                          menuHeight="10rem"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-3 px-0 mr-2">
                    <label htmlFor="" className="f8-vw m-0 p-0">
                      Inspected by
                    </label>
                    {searchInput(
                      "Nama Staff Inspeksi",
                      inspectedBy,
                      (e) => handleInspectedBy(e),
                      handleEnter
                    )}
                  </div>

                  <div className="col-md-2 pr-0 pl-5 mt-auto ml-auto">
                    <div className="d-flex flex-column">
                      <button
                        className="btn btn-sm search-new rounded-input btn-block mt-auto f8-vw fw-500 text-white"
                        style={{
                          paddingTop: 7,
                          paddingBottom: 7,
                          backgroundColor: "#742B9E",
                        }}
                        onClick={() => {
                          setReportList([]);
                          setRange({ ...range, page: 1 });
                          getReportList(
                            1,
                            true,
                            range.start,
                            range.end,
                            createAt.start,
                            createAt.end
                          );
                        }}
                      >
                        Search
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col px-0">
            {is_sub_menu_action("rp_house_keeping_export") && (
              <div className="mt-2">
                <div className="d-flex">
                  {comboBoxDownloadReport(link, handleDownloadCSV)}
                </div>
              </div>
            )}
          </div>

          <div className="col px-2 mt-0">
            <InfiniteScroll
              dataLength={reportList.length}
              next={checkNextPage}
              hasMore={hasMore}
              loader={<Loading />}
              endMessage={
                reportList?.length < 1 && (
                  <EmptyData
                    loading={loadingList}
                    errMessage={errorList}
                    height="13rem"
                  />
                )
              }
              className="px-0 mt-2 scroll-table table-border-line"
              height={"40vh"}
            >
              <TableReport
                list={reportList}
                loading={loadingList}
                errorMessage={errorList}
                getDetailList={getDetailList}
                noDetail={() => {
                  openModal();
                  setMode("no-inspect");
                }}
              />
            </InfiniteScroll>
          </div>
        </div>
      )}

      {IS_MOBILE && (
        <div>
          <BottomSheet
            open={openButtonSheet}
            onDismiss={() => setOpenButtonSheet(false)}
            snapPoints={({ maxHeight }) => maxHeight}
            header={
              <div className="d-flex justify-content-between">
                <span className="purple f16-vw fw-600 px-2">Filter</span>
                <span
                  className="px-2"
                  onClick={() => setOpenButtonSheet(false)}
                >
                  <Icon icon={iconUrl.closeBold} color={"grey"} width={20} />
                </span>
              </div>
            }
            footer={
              <div className="d-flex justify-content-between">
                <div className="col-6 pl-2">
                  <button
                    className="btn btn-block btn-outline-purple f12-vw"
                    onClick={() => {
                      resetFilter();
                      setOpenButtonSheet(false);
                      getReportList(
                        1,
                        true,
                        addDays(new Date(), -7),
                        new Date(),
                        addDays(new Date(), -7),
                        new Date()
                      );
                    }}
                  >
                    Reset
                  </button>
                </div>
                <div className="col-6 pr-2">
                  <button
                    className="btn btn-block btn-gradient f12-vw text-white"
                    onClick={() => {
                      setOpenButtonSheet(false);
                      getReportList(
                        1,
                        true,
                        range.start,
                        range.end,
                        createAt.start,
                        createAt.end
                      );
                    }}
                  >
                    Search
                  </button>
                </div>
              </div>
            }
          >
            <div className="mt-3" style={{ height: "33rem" }}>
              <div className="px-4 mt-2 date-wrapper">
                <label htmlFor="" className="f12-vw m-0 p-0">
                  Created At
                </label>
                <DateRangeFilterMobile
                  start={createAt.start}
                  end={createAt.end}
                  handleStart={handleCreateStart}
                  handleEnd={handleCreateEnd}
                  minDate={true}
                  fontSize="f12-vw"
                />
              </div>
              <div className="px-4 mt-3">
                <label htmlFor="" className="f12-vw m-0 p-0">
                  Room Number
                </label>
                {mobileSearchInput("Cari Nomor Kamar ", roomNumber, (e) =>
                  setRoomNumber(e.target.value)
                )}
              </div>
              <div className="px-4 mt-3">
                <label htmlFor="" className="f12-vw m-0 p-0">
                  Cleaning By
                </label>
                {mobileSearchInput("Nama Housekeeper ", cleaningBy, (e) =>
                  setCleaningBy(e.target.value)
                )}
              </div>
              <div className="px-4 mt-3">
                <div className="d-flex">
                  <div className="col pl-0 pr-1">
                    <label htmlFor="pemesan" className="f12-vw m-0 p-0">
                      Start Clean
                    </label>
                    <div
                      className=""
                      style={{
                        border: "1px solid #ced4da",
                        borderRadius: 3,
                        padding: "5px 0",
                      }}
                    >
                      <div className="d-flex px-2 f8-vw align-items-center">
                        <Icon
                          icon={iconUrl.calendarIcon}
                          color="#ef9403"
                          width={17}
                        />
                        <div className="">
                          <ReactDatePicker
                            selected={range.start}
                            onChange={(e) =>
                              setRange({
                                ...range,
                                start: e,
                                end: addDays(e, 7),
                              })
                            }
                            startDate={range.start}
                            endDate={range.end}
                            className={"date-checkin-new f12-vw text-center"}
                            dateFormat="dd MMM yyyy"
                            placeholderText="Pilih Tanggal"
                            onCalendarOpen={() => handleCalendarOpen()}
                            withPortal={true}
                            onChangeRaw={(e) => {
                              e.preventDefault();
                            }}
                            onKeyDown={(e) => {
                              e.preventDefault();
                            }}
                            onFocus={(e) => e.target.blur()}
                            preventOpenOnFocus={true}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col pr-0 pl-1">
                    <label htmlFor="pemesan" className="f12-vw m-0 p-0">
                      End Clean
                    </label>
                    <div
                      className=""
                      style={{
                        border: "1px solid #ced4da",
                        borderRadius: 3,
                        padding: "5px 0",
                      }}
                    >
                      <div className="d-flex px-2 f8-vw align-items-center">
                        <Icon
                          icon={iconUrl.calendarIcon}
                          color="#ef9403"
                          width={17}
                        />
                        <div className="">
                          <ReactDatePicker
                            selected={range.end}
                            onChange={(e) => setRange({ ...range, end: e })}
                            startDate={range.start}
                            endDate={range.end}
                            className={"date-checkin-new f12-vw text-center"}
                            dateFormat="dd MMM yyyy"
                            placeholderText="Pilih Tanggal"
                            minDate={range.start}
                            withPortal={true}
                            onCalendarOpen={() => handleCalendarOpen()}
                            onChangeRaw={(e) => {
                              e.preventDefault();
                            }}
                            onKeyDown={(e) => {
                              e.preventDefault();
                            }}
                            onFocus={(e) => e.target.blur()}
                            preventOpenOnFocus={true}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="px-4 mt-3">
                <label htmlFor="" className="f8-vw m-0">
                  Cleaning Duration
                </label>
                <div className="d-flex">
                  <div className="col pr-1 pl-0">
                    <SelectOpt
                      optionsValue={durationFilter}
                      onChange={handleFromDuration}
                      placeholder={"From"}
                      value={durationFromSelected}
                      clear={true}
                      fontSize="calc(8pt + 0.472vw)"
                      menuHeight="10rem"
                    />
                  </div>
                  <div className="col pl-1 pr-0">
                    <SelectOpt
                      optionsValue={durationFilter}
                      onChange={handleToDuration}
                      placeholder={"To"}
                      value={durationToSelected}
                      clear={true}
                      fontSize="calc(8pt + 0.472vw)"
                      menuHeight="10rem"
                    />
                  </div>
                </div>
              </div>
              <div className="px-4 mt-3">
                <label htmlFor="" className="f12-vw m-0 p-0">
                  Inspected By
                </label>
                {mobileSearchInput("Nama Staff Inspeksi ", inspectedBy, (e) =>
                  setInspectedBy(e.target.value)
                )}
              </div>
            </div>
          </BottomSheet>
        </div>
      )}

      <PopupModal
        modalIsOpen={isOpen}
        width={mode === "detail" ? "50rem" : "22rem"}
      >
        {mode === "detail" ? (
          <DetailPopup
            list={detailList}
            close={openModal}
            setShowFullscreen={setShowFullscreen}
            openFullscreen={() => setOpenModalFullscreen(true)}
          />
        ) : mode === "no-inspect" ? (
          <>
            <NotifMessage
              closeModal={openModal}
              img={"/img/error-icon/save-room-failed.svg"}
              messageHeader={"Belum Di Inspeksi"}
              color="red"
              messageBody={"Silahkan melakukan inspeksi terlebih dahulu"}
            />
            <ButtonClose close={openModal} text="OK" />
          </>
        ) : (
          <>
            <NotifMessage
              closeModal={openModal}
              img={"/img/error-icon/save-room-failed.svg"}
              messageHeader={"Tidak bisa melihat detail"}
              color="red"
              messageBody={errorDetail}
            />
            <ButtonClose close={openModal} text="OK" />
          </>
        )}
      </PopupModal>
      <PopupModal
        modalIsOpen={openModalFullscreen}
        mode={false}
        top="60%"
        width="35rem"
      >
        <div
          className="d-flex flex-column p-4"
          style={{ position: "relative" }}
        >
          <div style={{ position: "absolute", top: 0, right: 0, zIndex: 9999 }}>
            <button
              className="btn btn-sm bg-transparent"
              onClick={() => setOpenModalFullscreen(false)}
            >
              <Icon
                icon={iconUrl.timesIcon}
                width="20"
                height="20"
                color="#742b9e"
              />
            </button>
          </div>

          <img
            src={showFullscreen}
            alt=""
            style={{
              width: "100%",
              height: IS_MOBILE ? "15rem" : "25rem",
              objectFit: "contain",
            }}
            loading="lazy"
          />
        </div>
      </PopupModal>
    </>
  );
};

export default HousekeepingReport;
