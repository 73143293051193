import React from "react";
import CameraWebcam from "../../../common/CameraWebcam";
import { HeaderName } from "../../../common/HeaderName";
import { MobileUploadFile } from "../../../common/MobileUploadFile";
import { IS_MOBILE } from "../../../common/Responsive";
import { getServerTimeWithoutDays, showMore } from "../../../utils";

const FONT_SIZE = IS_MOBILE ? "f12-vw" : "f8-vw";

export const CancelPopup = ({
  detail,
  cancel,
  close,
  img,
  title,
  mode,
  note,
  setNote,
  setfileUpload,
  setShowImg,
  fileUpload,
  showImg,
}) => {
  const detailInfo = (title, value) => {
    return (
      <div className={`d-flex flex-row mt-1 ${FONT_SIZE}`}>
        <div className="col-5 grey-text px-0">{title}</div>
        <div className="col px-0">: {value ? value : "-"}</div>
      </div>
    );
  };
  return (
    <div
      className="d-flex flex-column p-3"
      style={{ width: IS_MOBILE ? "21rem" : "35rem" }}
    >
      <div className="col">
        <div className="text-center">
          <img src={img} alt="" loading="lazy" />
        </div>
      </div>
      <div className="col">
        <div className="d-flex justify-content-center">
          <div
            className={`font-weight-bold text-center ${
              IS_MOBILE ? "f14-vw" : "f10-vw"
            }`}
            style={{ width: "80%" }}
          >
            {title}
          </div>
        </div>
      </div>
      <div className="col">
        <hr className="separator-blue" />
        {IS_MOBILE ? (
          <div
            className={
              IS_MOBILE ? "scroll-popup hide-scrollbar" : "scroll-popup"
            }
            style={{ height: "20rem" }}
          >
            <HeaderName
              text="Informasi Booking"
              fontSize={`fw-600 ${IS_MOBILE ? "f14-vw" : "f10-vw"}`}
              color2="purple"
            />
            {detailInfo("Kode Booking", detail?.ota_booking_id)}
            {detailInfo("Nama OTA", detail?.ota_name)}
            {detailInfo("Nama Pemesan", detail?.booked_by)}
            {detailInfo(
              "Check In",
              getServerTimeWithoutDays(detail?.checkin_date)
            )}
            {detailInfo(
              "Check Out",
              getServerTimeWithoutDays(detail?.checkout_date)
            )}
            {detailInfo("Tipe Kamar", detail?.room_type_name)}
            {mode === "cancel-booking" ? (
              <div>
                <div className="form-group mt-2">
                  <label
                    htmlFor=""
                    className={`fw-600 grey-text mb-0 ${
                      IS_MOBILE ? "f12-vw" : "f8-vw"
                    }`}
                  >
                    Reason
                  </label>
                  <div className="">
                    <textarea
                      name="note"
                      id="note"
                      rows="3"
                      className={"form-control form-control-sm " + FONT_SIZE}
                      style={{
                        borderColor: "grey",
                        resize: "none",
                        backgroundColor: "white",
                      }}
                      onChange={(e) => setNote(e.target.value)}
                      value={note}
                      placeholder="Note..."
                    />
                  </div>
                </div>
                <div className="">
                  <HeaderName
                    text="Bukti Cancel"
                    fontSize="f12-vw fw-700"
                    color1="black"
                    color2="black"
                  />
                </div>
                <div className="">
                  <MobileUploadFile
                    setfileUpload={setfileUpload}
                    title={"Upload Bukti Cancel"}
                    height="9rem"
                    fileUpload={fileUpload}
                    setShowImg={setShowImg}
                    showImg={showImg}
                    fontSize={"f12-vw"}
                  />
                </div>
              </div>
            ) : (
              <>
                <div className="form-group">
                  <label
                    htmlFor=""
                    className={`fw-600 grey-text ${
                      IS_MOBILE ? "f12-vw" : "f8-vw"
                    }`}
                  >
                    Reason
                  </label>
                  <div className={FONT_SIZE}>
                    {note ? showMore(note, 100) : "-"}
                  </div>
                </div>
                <div className="">
                  <HeaderName
                    text="Bukti Cancel"
                    fontSize="f12-vw fw-700"
                    color1="black"
                    color2="black"
                  />
                </div>
                <div className="">
                  <img
                    src={showImg}
                    alt=""
                    loading="lazy"
                    width={"100%"}
                    style={{ objectFit: "contain" }}
                  />
                </div>
              </>
            )}
          </div>
        ) : (
          <div className="d-flex flex-row">
            <div className="col-6 pl-0">
              <HeaderName
                text="Informasi Booking"
                fontSize={`fw-600 ${IS_MOBILE ? "f14-vw" : "f10-vw"}`}
                color2="purple"
              />
              {detailInfo("Kode Booking", detail?.ota_booking_id)}
              {detailInfo("Nama OTA", detail?.ota_name)}
              {detailInfo("Nama Pemesan", detail?.booked_by)}
              {detailInfo(
                "Check In",
                getServerTimeWithoutDays(detail?.checkin_date)
              )}
              {detailInfo(
                "Check Out",
                getServerTimeWithoutDays(detail?.checkout_date)
              )}
              {detailInfo("Tipe Kamar", detail?.room_type_name)}
              {mode === "cancel-booking" ? (
                <div className="form-group mt-2">
                  <label
                    htmlFor=""
                    className={`fw-600 grey-text mb-0 ${
                      IS_MOBILE ? "f12-vw" : "f8-vw"
                    }`}
                  >
                    Reason
                  </label>
                  <div className="">
                    <textarea
                      name="note"
                      id="note"
                      rows="3"
                      className={"form-control form-control-sm " + FONT_SIZE}
                      style={{
                        borderColor: "grey",
                        resize: "none",
                        backgroundColor: "white",
                      }}
                      onChange={(e) => setNote(e.target.value)}
                      value={note}
                      placeholder="Note..."
                    />
                  </div>
                </div>
              ) : (
                <div className="form-group">
                  <label
                    htmlFor=""
                    className={`fw-600 grey-text ${
                      IS_MOBILE ? "f12-vw" : "f8-vw"
                    }`}
                  >
                    Reason
                  </label>
                  <div className={FONT_SIZE}>
                    {note ? showMore(note, 100) : "-"}
                  </div>
                </div>
              )}
            </div>
            {mode === "cancel-booking" ? (
              <div className="col-6 pr-0">
                <div className="">
                  <HeaderName
                    text="Bukti Cancel"
                    fontSize="f8-vw fw-700"
                    color1="black"
                    color2="black"
                  />
                </div>
                <div className="">
                  <CameraWebcam
                    setfileUpload={setfileUpload}
                    title={"Upload Bukti Cancel"}
                    height="7.5rem"
                    fileUpload={fileUpload}
                    setShowImg={setShowImg}
                    showImg={showImg}
                  />
                </div>
              </div>
            ) : (
              <div className="col-6 pr-0">
                <div className="">
                  <HeaderName
                    text="Bukti Cancel"
                    fontSize="f8-vw fw-700"
                    color1="black"
                    color2="black"
                  />
                </div>
                <div className="">
                  <img
                    src={showImg}
                    alt=""
                    loading="lazy"
                    width={"100%"}
                    style={{ objectFit: "contain" }}
                  />
                </div>
              </div>
            )}
          </div>
        )}

        <hr className="separator-blue" />
      </div>
      <div className="col">
        {mode === "cancel-booking" ? (
          <div className="d-flex justify-content-between ">
            <div className="col-5 px-0">
              <button
                className={
                  "btn btn-sm btn-block btn-outline-purple " + FONT_SIZE
                }
                onClick={() => close()}
              >
                No
              </button>
            </div>
            <div className="col-5 px-0">
              <button
                className={`btn btn-sm btn-block text-white ${
                  note !== "" && fileUpload?.length > 0
                    ? "btn-gradient"
                    : "btn-secondary"
                } ${FONT_SIZE}`}
                onClick={() => cancel(detail?.ota_booking_id)}
                disabled={note !== "" && fileUpload?.length > 0 ? false : true}
              >
                Yes
              </button>
            </div>
          </div>
        ) : (
          <div className="d-flex justify-content-center">
            <div className="col-5 px-0">
              <button
                className={"btn btn-sm btn-block text-white " + FONT_SIZE}
                style={{ backgroundColor: "#36AB1F" }}
                onClick={() => close()}
              >
                OK
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
