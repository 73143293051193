const statusLostFound = [
  { value: 0, label: "New", className: "orange" },
  { value: 1, label: "Returned", className: "green" },
];

export const filterStatusLostFound = (id) => {
  return statusLostFound?.find((item) => item.value === id);
};

export const reportType = [
  { value: 1, label: "LOST" },
  { value: 2, label: "FOUND" },
];

export const filterSelect = (data, value) => {
  return data?.find((item) => item?.value === value);
};

export const lessThanToday = (bookingdate) => {
  let today = new Date().getTime();
  let checkin_date = new Date(bookingdate);
  let checkin = checkin_date.setHours(23, 59, 59);
  if (today <= checkin) {
    return true;
  }
  return false;
};

export const lessThanTime = (bookingdate) => {
  let today = new Date().getTime();
  let checkin_date = new Date(bookingdate).setHours(
    new Date(bookingdate).getHours() - 1
  );
  if (today <= checkin_date) {
    return true;
  }
  return false;
};

export const filterRoom = (data, value) => {
  return data?.find((item) => item?.room_number === value);
};
