import { useEffect, useState } from "react";

export const useScroll = () => {
  const [scrollPosition, setScrollPosition] = useState(false);
  const handleScroll = () => {
    const position = window.pageYOffset;
    if (position >= 200) {
      setScrollPosition(true);
    } else {
      setScrollPosition(false);
    }
  };
  useEffect(() => {
    const newLocal = true;
    window.addEventListener("scroll", handleScroll, { passive: newLocal });

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return scrollPosition;
};
