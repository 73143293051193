import { Icon } from "@iconify/react";
import React from "react";
import ReactDatePicker from "react-datepicker";
import Count from "../../../common/Count";
import { DateCheckin } from "../../../common/DateCheckin";
import { HeaderName } from "../../../common/HeaderName";
import InputPhone from "../../../common/InputPhone";
import SelectOpt from "../../../common/SelectOpt";
import { iconUrl } from "../../../config";
import { addDays } from "../../../utils";
import { IS_MOBILE } from "../../../common/Responsive";

export const PopupEditBooking = ({
  setFormEdit,
  formEdit,
  otherPlatform,
  setOtherSelected,
  otherSelected,
  setFormError,
  formError,
  typeRoom,
  roomTypeSelected,
  setRoomTypeSelected,
  purpose,
  guestTypeSelected,
  purposeSelected,
  guesttype,
  setPurposeSelected,
  setGuestTypeSelected,
  nextButton,
  backButton,
  detailCheckin,
}) => {
  const { ota_name } = detailCheckin;
  const validEmailInput = (val) => {
    const validEmail = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (val?.length < 1) {
      setFormError(null);
    } else if (!validEmail.test(val)) {
      setFormError("Format email salah");
    } else {
      return setFormError(null);
    }
  };
  const inputContainer = (
    inpValue,
    inpPlaceholder,
    value,
    onChange,
    autoFocus,
    type,
    isInvalid,
    required = false
  ) => {
    return (
      <div className="form-group my-0">
        <label htmlFor="pemesan" className="f8-vw m-0">
          {required && <span className="red">* </span>}
          {inpValue}
        </label>
        <input
          type={type ? type : "text"}
          placeholder={inpPlaceholder}
          className={`form-control f8-vw rounded-input ${
            isInvalid ? "is-invalid" : ""
          }`}
          value={value}
          onChange={onChange}
          autoFocus={
            autoFocus && localStorage.getItem("shift_open") ? true : false
          }
          disabled={ota_name === "RNA"}
        />
      </div>
    );
  };
  const mobileInputContainer = (
    inpValue,
    inpPlaceholder,
    value,
    onChange,
    autoFocus,
    type,
    isInvalid,
    required = false
  ) => {
    return (
      <div className="form-group my-0">
        <label htmlFor="pemesan" className="f12-vw m-0">
          {required && <span className="red">* </span>}
          {inpValue}
        </label>
        <input
          type={type ? type : "text"}
          placeholder={inpPlaceholder}
          className={`form-control f12-vw rounded-input ${
            isInvalid ? "is-invalid" : ""
          }`}
          value={value}
          onChange={onChange}
          autoFocus={
            autoFocus && localStorage.getItem("shift_open") ? true : false
          }
          disabled={ota_name === "RNA"}
        />
      </div>
    );
  };
  const handleOtherOTA = (val) => {
    setOtherSelected(val);
  };
  const handleGuestName = (val) => {
    if (val.target.value) {
      let d = val?.target?.value.replace(
        /(^\w{1})|(\s+\w{1})|(,+\w{1})/g,
        (letter) => letter.toUpperCase()
      );
      let text = d.replace(", ", ",");
      let guest = text?.split(",");
      setFormEdit({ ...formEdit, guestName: guest });
    } else {
      setFormEdit({ ...formEdit, guestName: [] });
    }
  };
  const handlePhone = (value) => {
    setFormEdit({ ...formEdit, phoneNumber: value });
  };
  const handleEmail = (val) => {
    setFormEdit({ ...formEdit, email: val.target.value });
    validEmailInput(val.target.value);
  };
  const handleCheckIn = (value) => {
    setFormEdit({ ...formEdit, checkin: value, checkout: addDays(value, 1) });
  };
  const handleCheckOut = (value) => {
    setFormEdit({ ...formEdit, checkout: value });
  };
  const handleSelect = (value) => {
    setRoomTypeSelected(value);
  };
  const handleGuest = (val) => {
    setFormEdit({ ...formEdit, guest: val });
  };
  const handleRoom = (val) => {
    setFormEdit({ ...formEdit, room: val });
  };
  const handlePurpose = (val) => {
    setPurposeSelected(val);
  };
  const handleGuestType = (val) => {
    setGuestTypeSelected(val);
  };
  const handleBookingDate = (value) => {
    setFormEdit({ ...formEdit, booking_date: value });
  };
  const handleCalendarOpen = () => {
    document.addEventListener(
      "touchstart",
      (event) => {
        event.stopPropagation();
      },
      true
    );
  };

  const validation = () => {
    return (
      formEdit.guestName.length > 0 &&
      formEdit.bookingCode !== "" &&
      !!roomTypeSelected &&
      !!otherSelected &&
      !formError
    );
  };

  console.log(formError);
  return (
    <>
      {IS_MOBILE ? (
        <div className="py-3">
          <div className="px-4">
            <div className="d-flex align-items-center">
              <span className="mr-2">
                <Icon icon={iconUrl.editIcon} color="orange" width={25} />
              </span>
              <span className="f14-vw blue-title fw-700">Edit Booking</span>
            </div>
            <hr className="separator-grey my-2" />
          </div>
          <div
            className="d-flex flex-column px-2 hide-scrollbar"
            style={{ height: "25rem", overflowY: "scroll" }}
          >
            <div className="col">
              <div className="">
                <div className="form-group my-0">
                  <label
                    htmlFor="type-room"
                    className="f12-vw m-0"
                    style={{ fontWeight: 500 }}
                  >
                    Kode Booking
                  </label>
                  <input
                    type="text"
                    placeholder={"Kode Booking"}
                    className="form-control f12-vw rounded-input"
                    value={formEdit.bookingCode}
                    onChange={(e) =>
                      setFormEdit({
                        ...formEdit,
                        bookingCode: e.target.value.toUpperCase(),
                      })
                    }
                    disabled={ota_name === "RNA"}
                  />
                </div>
              </div>
            </div>

            <div className="col">
              <div className="mt-2">
                <HeaderName
                  text={"Informasi Tamu"}
                  fontSize="f14-vw fw-600"
                  color2="purple"
                />
              </div>
              <div className="form-group mb-2">
                <label htmlFor="pemesan" className="f12-vw m-0">
                  Nama OTA
                </label>
                <SelectOpt
                  optionsValue={otherPlatform}
                  onChange={handleOtherOTA}
                  placeholder={"Pilih OTA"}
                  value={otherSelected}
                  fontSize="calc(8pt + 0.472vw)"
                  disabled={ota_name === "RNA"}
                />
              </div>
              <div className="form-group mb-2">
                <label htmlFor="" className="f12-vw mb-1">
                  Tanggal Booking
                </label>
                <div
                  className=""
                  style={{
                    border: "1px solid #ced4da",
                    borderRadius: 5,
                    padding: "4px 0",
                    backgroundColor: ota_name === "RNA" ? "#e9ecef" : "white",
                    ...(ota_name === "RNA" && { pointerEvents: "none" }),
                  }}
                >
                  <div className="d-flex px-2 f8-vw align-items-center">
                    <Icon
                      icon={iconUrl.calendarIcon}
                      color="#ef9403"
                      width={20}
                    />
                    <div className="w-100">
                      <ReactDatePicker
                        selected={formEdit?.booking_date}
                        onChange={(e) => handleBookingDate(e)}
                        className={"date-checkin-new f12-vw text-left"}
                        dateFormat="dd MMM yyyy"
                        placeholderText="Pilih Tanggal Booking"
                        maxDate={new Date()}
                        withPortal={IS_MOBILE ? true : false}
                        onFocus={(e) => e.target.blur()}
                        onCalendarOpen={handleCalendarOpen}
                        onKeyDown={(e) => {
                          e.preventDefault();
                        }}
                        onChangeRaw={(e) => {
                          e.preventDefault();
                        }}
                        disabled={ota_name === "RNA"}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="form-group mb-2">
                {mobileInputContainer(
                  "Nama Pemesan",
                  "Nama Pemesan",
                  formEdit?.guestName,
                  (e) => handleGuestName(e),
                  true
                )}
              </div>
              <div className="form-group mb-2">
                <label htmlFor="pemesan" className="f12-vw m-0">
                  Nomor Ponsel
                </label>
                <div className="group-input f12-vw grey">
                  <InputPhone
                    value={formEdit?.phoneNumber}
                    setValue={handlePhone}
                    disabled={ota_name === "RNA"}
                  />
                </div>
              </div>
              <div className="form-group mb-2">
                {mobileInputContainer(
                  "Email",
                  "Masukkan email",
                  formEdit?.email,
                  (e) => handleEmail(e),
                  false,
                  "email",
                  formError,
                  false
                )}
                <div className="invalid-feedback f6-vw">{formError}</div>
              </div>
            </div>
            <div className="col">
              <div className="">
                <HeaderName
                  text={"Informasi Pemesanan"}
                  fontSize="f14-vw fw-600"
                  color2="purple"
                />
              </div>
              <div
                className="form-group"
                style={{ marginTop: 6, marginBottom: 0 }}
              >
                <div className="mb-0 d-flex flex-row">
                  <div className="col-6 pl-0 pr-2 f12-vw">Check in</div>
                  <div className="col-6 pr-0 pl-2 f12-vw">Check out</div>
                </div>
                <DateCheckin
                  start={formEdit?.checkin}
                  handleStart={handleCheckIn}
                  disable={ota_name === "RNA"}
                  end={formEdit?.checkout}
                  handleEnd={handleCheckOut}
                  minDate={true}
                  fontSize={"f12-vw"}
                  disableEnd={ota_name === "RNA"}
                />
              </div>
              <div className="form-group d-flex flex-column mb-2">
                <label htmlFor="type-room" className="f12-vw mb-0 mt-2">
                  Tipe Kamar
                </label>
                <SelectOpt
                  optionsValue={typeRoom}
                  onChange={handleSelect}
                  placeholder={"Pilih Tipe Kamar"}
                  value={roomTypeSelected}
                  fontSize="calc(8pt + 0.472vw)"
                />
              </div>
              <div className="form-group mb-2">
                <label htmlFor="pemesan" className="f12-vw my-0">
                  Tamu dan Kamar
                </label>
                <div className="d-flex">
                  <div className="col px-0 dropdown">
                    <div
                      className="form-control form-control-sm dropdown-toggle rounded-input py-0 d-flex align-items-center"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                      style={{
                        pointerEvents: ota_name === "RNA" && "none",
                        backgroundColor:
                          ota_name === "RNA" ? "#e9ecef" : "white",
                      }}
                    >
                      <div className="d-flex justify-content-between">
                        <div className="">
                          <span className="f12-vw fw-500 ml-1">
                            {formEdit?.guest} Tamu,
                          </span>
                          <span className="f12-vw fw-500 mx-1">
                            {formEdit?.room} Kamar
                          </span>
                        </div>
                      </div>
                      <span style={{ position: "absolute", right: 10 }}>
                        <Icon
                          icon={iconUrl.arrowDownAlt2}
                          width={18}
                          color="#62148f"
                        />
                      </span>
                    </div>
                    <div
                      className="dropdown-menu py-0"
                      aria-labelledby="dropdownMenuButton"
                      style={{ width: "100%" }}
                    >
                      <div className="d-flex flex-column">
                        <div className="col d-flex justify-content-between align-items-center">
                          <div className="f12-vw fw-600">Tamu</div>
                          <div className="d-flex align-items-center">
                            <Count
                              setValue={handleGuest}
                              count={formEdit?.guest}
                              fontSize={"f12-vw"}
                            />
                          </div>
                        </div>
                        <div className="col py-0 d-flex justify-content-between align-items-center">
                          <div className="f12-vw fw-600">Kamar</div>
                          <div className="d-flex align-items-center">
                            <Count
                              setValue={handleRoom}
                              count={formEdit?.room}
                              fontSize={"f12-vw"}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="form-group mb-2">
                <label className="f12-vw m-0">Purpose</label>
                <SelectOpt
                  optionsValue={purpose}
                  onChange={handlePurpose}
                  placeholder={"Pilih Tujuan Menginap"}
                  value={purposeSelected}
                  placement={true}
                  fontSize="calc(8pt + 0.472vw)"
                  disabled={ota_name === "RNA"}
                />
              </div>
              <div className="form-group mb-2">
                <label className="f12-vw my-0">Guest Type</label>
                <SelectOpt
                  optionsValue={guesttype}
                  onChange={handleGuestType}
                  placeholder={"Pilih Guest Type"}
                  value={guestTypeSelected}
                  placement={true}
                  fontSize="calc(8pt + 0.472vw)"
                  disabled={ota_name === "RNA"}
                />
              </div>
            </div>
          </div>
          <div className="px-4">
            <hr className="separator-grey" />
          </div>
          <div className="px-4">
            <div className="text-center mb-3">
              <span className="f10-vw text-center">
                Apakah anda yakin ingin melanjutkan?
              </span>
            </div>
          </div>
          <div className="px-4">
            <div className="d-flex justify-content-between">
              <div className="col-4 px-0">
                <button
                  className="btn btn-block btn-sm f12-vw btn-outline-purple"
                  onClick={() => backButton()}
                >
                  Back
                </button>
              </div>
              <div className="col-4 px-0">
                <button
                  className={`btn btn-block btn-sm f12-vw text-white ${
                    validation() ? "btn-gradient" : "btn-secondary"
                  }`}
                  onClick={() => nextButton()}
                  disabled={validation() ? false : true}
                >
                  Next
                </button>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div
          className="d-flex flex-column px-2 py-3"
          style={{ width: "35rem" }}
        >
          <div className="col">
            <div className="d-flex align-items-center">
              <span className="mr-2">
                <Icon icon={iconUrl.editIcon} color="orange" width={25} />
              </span>
              <span className="f10-vw blue-title fw-700">Edit Booking</span>
            </div>
            <hr className="separator-grey my-2" />
          </div>
          <div className="col-6">
            <div className="">
              <div className="form-group my-0">
                <label
                  htmlFor="type-room"
                  className="f8-vw m-0"
                  style={{ fontWeight: 500 }}
                >
                  Kode Booking
                </label>
                <input
                  type="text"
                  placeholder={"Kode Booking"}
                  className="form-control f8-vw rounded-input"
                  value={formEdit.bookingCode}
                  onChange={(e) =>
                    setFormEdit({
                      ...formEdit,
                      bookingCode: e.target.value.toUpperCase(),
                    })
                  }
                  disabled={ota_name === "RNA"}
                />
              </div>
            </div>
          </div>
          <div className="col">
            <div className="d-flex flex-row my-3">
              <div className="col-6 pl-0">
                <div className="">
                  <HeaderName
                    text={"Informasi Tamu"}
                    fontSize="f10-vw fw-600"
                    color2="purple"
                  />
                </div>

                <div className="form-group mb-0">
                  <label htmlFor="pemesan" className="f8-vw m-0">
                    Nama OTA
                  </label>
                  <SelectOpt
                    optionsValue={otherPlatform}
                    onChange={handleOtherOTA}
                    placeholder={"Pilih OTA"}
                    value={otherSelected}
                    // fontSize="calc(5pt + 0.265vw)"
                    disabled={ota_name === "RNA"}
                  />
                </div>

                <div className="form-group my-0">
                  <label htmlFor="" className="f8-vw mb-1">
                    Tanggal Booking
                  </label>
                  <div
                    className=""
                    style={{
                      border: "1px solid #ced4da",
                      borderRadius: 5,
                      padding: "4px 0",
                      backgroundColor: ota_name === "RNA" ? "#e9ecef" : "white",
                      ...(ota_name === "RNA" && { pointerEvents: "none" }),
                    }}
                  >
                    <div className="d-flex px-2 f8-vw align-items-center ">
                      <Icon
                        icon={iconUrl.calendarIcon}
                        color="#ef9403"
                        width={15}
                      />
                      <div className="">
                        <ReactDatePicker
                          selected={formEdit?.booking_date}
                          onChange={(e) => handleBookingDate(e)}
                          className={"date-checkin-new f8-vw"}
                          dateFormat="dd MMM yyyy"
                          placeholderText="Pilih Tanggal Booking"
                          maxDate={new Date()}
                          disabled={ota_name === "RNA"}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="form-group my-0">
                  {inputContainer(
                    "Nama Pemesan",
                    "Nama Pemesan",
                    formEdit?.guestName,
                    (e) => handleGuestName(e),
                    true
                  )}
                </div>
                <div className="form-group my-0">
                  <label htmlFor="pemesan" className="f8-vw m-0">
                    Nomor Ponsel
                  </label>
                  <div className="group-input f8-vw grey">
                    <InputPhone
                      value={formEdit?.phoneNumber}
                      setValue={handlePhone}
                      disabled={ota_name === "RNA"}
                    />
                  </div>
                </div>
                <div className="form-group my-0">
                  {inputContainer(
                    "Email",
                    "Masukkan email",
                    formEdit?.email,
                    (e) => handleEmail(e),
                    false,
                    "email",
                    formError,
                    false
                  )}
                  <div className="invalid-feedback f6-vw">{formError}</div>
                </div>
              </div>
              <div className="col-6 pr-0">
                <div className="">
                  <HeaderName
                    text={"Informasi Pemesanan"}
                    fontSize="f10-vw fw-600"
                    color2="purple"
                  />
                </div>
                <div
                  className="form-group"
                  style={{ marginTop: 6, marginBottom: 0 }}
                >
                  <div className="mb-0 d-flex flex-row">
                    <div className="col-6 pl-0 pr-2 f8-vw">Check in</div>
                    <div className="col-6 pr-0 pl-2 f8-vw">Check out</div>
                  </div>
                  <DateCheckin
                    start={formEdit?.checkin}
                    handleStart={handleCheckIn}
                    disable={ota_name === "RNA"}
                    end={formEdit?.checkout}
                    handleEnd={handleCheckOut}
                    minDate={true}
                    fontSize="f8-vw"
                    disableEnd={ota_name === "RNA"}
                  />
                </div>
                <div className="form-group d-flex flex-column mb-0">
                  <label htmlFor="type-room" className="f8-vw mb-0 mt-2">
                    Tipe Kamar
                  </label>
                  <SelectOpt
                    optionsValue={typeRoom}
                    onChange={handleSelect}
                    placeholder={"Pilih Tipe Kamar"}
                    value={roomTypeSelected}
                    // fontSize="calc(5pt + 0.265vw)"
                  />
                </div>
                <div className="">
                  <label htmlFor="pemesan" className="f8-vw my-0">
                    Tamu dan Kamar
                  </label>
                  <div className="d-flex">
                    <div className="col px-0 dropdown">
                      <div
                        className="form-control form-control-sm dropdown-toggle py-0 d-flex align-items-center"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                        style={{
                          pointerEvents: ota_name === "RNA" && "none",
                          backgroundColor:
                            ota_name === "RNA" ? "#e9ecef" : "white",
                        }}
                      >
                        <span className="f8-vw">{formEdit?.guest} Tamu,</span>
                        <span className="f8-vw mx-1">
                          {formEdit?.room} Kamar
                        </span>
                      </div>
                      <div
                        className="dropdown-menu py-0"
                        aria-labelledby="dropdownMenuButton"
                        style={{ width: "100%" }}
                      >
                        <div className="d-flex flex-column">
                          <div className="col d-flex justify-content-between align-items-center">
                            <div className="f8-vw">Tamu</div>
                            <div className="d-flex align-items-center">
                              <Count
                                setValue={handleGuest}
                                count={formEdit?.guest}
                              />
                            </div>
                          </div>
                          <div className="col py-0 d-flex justify-content-between align-items-center">
                            <div className="f8-vw">Kamar</div>
                            <div className="d-flex align-items-center">
                              <Count
                                setValue={handleRoom}
                                count={formEdit?.room}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="">
                  <label className="f8-vw m-0">Purpose</label>
                  <SelectOpt
                    optionsValue={purpose}
                    onChange={handlePurpose}
                    placeholder={"Pilih Tujuan Menginap"}
                    value={purposeSelected}
                    placement={true}
                    disabled={ota_name === "RNA"}
                  />
                </div>
                <div className="">
                  <label className="f8-vw my-0">Guest Type</label>
                  <SelectOpt
                    optionsValue={guesttype}
                    onChange={handleGuestType}
                    placeholder={"Pilih Guest Type"}
                    value={guestTypeSelected}
                    placement={true}
                    disabled={ota_name === "RNA"}
                  />
                </div>
              </div>
            </div>
            <hr className="separator-grey" />
          </div>
          <div className="col">
            <div className="d-flex justify-content-between">
              <div className="col-3 px-0">
                <button
                  className="btn btn-block btn-sm f8-vw btn-outline-purple"
                  onClick={() => backButton()}
                >
                  Back
                </button>
              </div>
              <div className="col-3 px-0">
                <button
                  className={`btn btn-block btn-sm f8-vw text-white ${
                    validation() ? "btn-gradient" : "btn-secondary"
                  }`}
                  onClick={() => nextButton()}
                  disabled={validation() ? false : true}
                >
                  Next
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
