import { Icon } from "@iconify/react";
import React, { useEffect } from "react";
import ReactDatePicker from "react-datepicker";
import Count from "../../../common/Count";
import { DateCheckin } from "../../../common/DateCheckin";
import { HeaderName } from "../../../common/HeaderName";
import InputPhone from "../../../common/InputPhone";
import SelectOpt from "../../../common/SelectOpt";
import { iconUrl } from "../../../config";
import { addDays } from "../../../utils";
import { inputContainer } from "../bookingHelper";

export const OtherForm = ({
  otherForm,
  setotherForm,
  formError,
  setFormError,
  otherPlatform,
  otherSelected,
  setOtherSelected,
  typeRoom,
  roomSelected,
  setRoomSelected,
  errorBookingCode,
  purpose,
  purposeSelected,
  setPurposeSelected,
  guesttype,
  guestTypeSelected,
  setGuestTypeSelected,
}) => {
  // UTILS
  const handleOtherOTA = (val) => {
    setOtherSelected(val);
  };

  const handleSelect = (value) => {
    setotherForm({ ...otherForm, roomType: value.value, roomNumber: [] });
    setRoomSelected(value);
  };

  const handleGuestName = (val) => {
    if (val.target.value) {
      let d = val?.target?.value.replace(
        /(^\w{1})|(\s+\w{1})|(,+\w{1})/g,
        (letter) => letter.toUpperCase()
      );
      let text = d.replace(", ", ",");
      let guest = text?.split(",");
      setotherForm({ ...otherForm, guestName: guest });
    } else {
      setotherForm({ ...otherForm, guestName: [] });
    }
  };

  const handleCheckIn = (value) => {
    setotherForm({ ...otherForm, checkin: value, checkout: addDays(value, 1) });
  };
  const handleCheckOut = (value) => {
    setotherForm({ ...otherForm, checkout: value });
  };
  const handleBookingDate = (value) => {
    setotherForm({ ...otherForm, booking_date: value });
  };

  const handleGuest = (val) => {
    setotherForm({ ...otherForm, guest: val });
  };
  const handleRoom = (val) => {
    setotherForm({ ...otherForm, room: val });
  };

  const handlePurpose = (val) => {
    setPurposeSelected(val);
  };
  const handleGuestType = (val) => {
    setGuestTypeSelected(val);
  };

  const validEmailInput = (val) => {
    const validEmail = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (val?.length < 1) {
      setFormError(null);
    } else if (!validEmail.test(val)) {
      setFormError("Format email salah");
    } else {
      return setFormError(null);
    }
  };
  const handlePhone = (value) => {
    setotherForm({ ...otherForm, phoneNumber: value });
  };
  const handleEmail = (val) => {
    setotherForm({ ...otherForm, email: val.target.value });
    validEmailInput(val.target.value);
  };

  const handleCalendarOpen = () => {
    document.addEventListener(
      "touchstart",
      (event) => {
        event.stopPropagation();
      },
      true
    );
  };

  useEffect(() => {
    setPurposeSelected(purpose[1]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    setGuestTypeSelected(guesttype[0]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <div className="d-flex flex-row justify-content-between">
        <div className="col-4 px-0">
          <HeaderName text="Informasi Tamu" fontSize="f12-vw fw-700" />
        </div>
        <div className="col-4 pr-0">
          <HeaderName text="Informasi Pemesanan" fontSize="f12-vw fw-700" />
        </div>
      </div>
      <div className="d-flex flex-row">
        <div className="col-4 pl-0">
          <div className="d-flex flex-row">
            {!!errorBookingCode && (
              <div
                className="alert alert-danger alert-dismissible fade show f7-vw mr-2"
                role="alert"
                style={{
                  position: "absolute",
                  zIndex: 9,
                  right: 0,
                }}
              >
                <div className="d-flex align-items-center">
                  <Icon
                    icon={iconUrl.circleAlert}
                    width={13}
                    className="mr-2"
                  />
                  <span>{errorBookingCode}</span>
                </div>
              </div>
            )}
          </div>

          <div className="d-flex flex-column">
            <div className="col pl-0 pr-2">
              <div className="form-group mb-2">
                <label htmlFor="pemesan" className="f8-vw fw-700 m-0">
                  <span className="red">* </span>Nama OTA
                </label>
                <SelectOpt
                  optionsValue={otherPlatform}
                  onChange={handleOtherOTA}
                  placeholder={"Pilih OTA"}
                  value={otherSelected}
                />
              </div>
            </div>
            <div className="col pl-0 pr-2">
              <div className="form-group mb-2">
                <label htmlFor="type-room" className="f8-vw fw-700 m-0">
                  <span className="red">* </span>Kode Booking
                </label>
                <input
                  type="text"
                  placeholder={"Kode Booking"}
                  className="form-control f8-vw rounded-input "
                  value={otherForm.bookingCode}
                  onChange={(e) =>
                    setotherForm({
                      ...otherForm,
                      bookingCode: e.target.value.toUpperCase(),
                    })
                  }
                />
              </div>
            </div>
            <div className="col pl-0 pr-2">
              <label htmlFor="" className="f8-vw fw-700 m-0">
                <span className="red">* </span>Tanggal Booking
              </label>

              <div
                className="form-group mb-2"
                style={{
                  border: "1px solid #ced4da",
                  borderRadius: 5,
                  padding: "4px 0",
                }}
              >
                <div className="d-flex px-2 f8-vw align-items-center ">
                  <Icon
                    icon={iconUrl.calendarIcon}
                    color="#ef9403"
                    width={15}
                  />
                  <div className="w-100">
                    <ReactDatePicker
                      selected={otherForm?.booking_date}
                      onChange={(e) => handleBookingDate(e)}
                      className={"date-checkin-new f8-vw"}
                      dateFormat="dd MMM yyyy"
                      placeholderText="Pilih Tanggal Booking"
                      maxDate={new Date()}
                      onCalendarOpen={handleCalendarOpen}
                      onKeyDown={(e) => {
                        e.preventDefault();
                      }}
                      onChangeRaw={(e) => {
                        e.preventDefault();
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="col pl-0 pr-2">
              <div className="d-flex flex-row">
                <div className="col-6 pl-0 pr-2">
                  <label className="f8-vw fw-700 my-1">Purpose</label>
                  <SelectOpt
                    optionsValue={purpose}
                    onChange={handlePurpose}
                    placeholder={"Pilih Tujuan Menginap"}
                    value={purposeSelected}
                    placement={true}
                  />
                </div>
                <div className="col-6 pr-0 pl-2">
                  <label className="f8-vw fw-700 my-1">Guest Type</label>
                  <SelectOpt
                    optionsValue={guesttype}
                    onChange={handleGuestType}
                    placeholder={"Pilih Guest Type"}
                    value={guestTypeSelected}
                    placement={true}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-4 px-2">
          <div className="d-flex flex-column">
            <div className="col pl-1 pr-2">
              {inputContainer(
                "Nama Tamu",
                "Nama Lengkap",
                otherForm?.guestName,
                (e) => handleGuestName(e),
                true
              )}
            </div>
            <div className="col pl-1 pr-2">
              <div className="form-group mb-2">
                <label htmlFor="pemesan" className="f8-vw fw-700 m-0">
                  <span className="red">* </span>Nomor Ponsel
                </label>
                <div className="group-input f8-vw grey">
                  <InputPhone
                    value={otherForm?.phoneNumber}
                    setValue={handlePhone}
                  />
                </div>
              </div>
            </div>
            <div className="col pl-1 pr-2">
              {inputContainer(
                "Email",
                "Masukkan email",
                otherForm?.email,
                (e) => handleEmail(e),
                false,
                "email",
                formError,
                false
              )}
              <div className="invalid-feedback f6-vw">{formError}</div>
            </div>
          </div>
        </div>

        <div className="col-4 pr-0">
          <div className="d-flex flex-column">
            <div className="col pl-0">
              <div className="date-header form-group mb-2">
                <div className="f8-vw fw-700 mt-1 d-flex flex-row">
                  <span className="col-6 pl-0 pr-2">Check in</span>
                  <span className="col-6 pr-0 pl-2">Check out</span>
                </div>
                <DateCheckin
                  start={otherForm?.checkin}
                  handleStart={handleCheckIn}
                  disable={false}
                  end={otherForm?.checkout}
                  handleEnd={handleCheckOut}
                  minDate={true}
                />
              </div>
            </div>
            <div className="col pl-0" style={{ marginTop: 2 }}>
              <div className="form-group mb-2">
                <label htmlFor="type-room" className="f8-vw fw-700 m-0">
                  <span className="red">* </span>Tipe Kamar
                </label>
                <SelectOpt
                  optionsValue={typeRoom}
                  onChange={handleSelect}
                  placeholder={"Pilih Tipe Kamar"}
                  value={roomSelected}
                />
              </div>
            </div>
            <div className="col pl-0">
              <div className="form-group mb-2" style={{ marginTop: 2 }}>
                <label htmlFor="pemesan" className="f8-vw fw-700 m-0">
                  <span className="red">* </span>Tamu dan Kamar
                </label>
                <div className="d-flex">
                  <div className="col px-0 dropdown">
                    <div
                      className="form-control form-control-sm dropdown-toggle py-0 d-flex align-items-center"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                      // style={{ height: "1.8rem" }}
                    >
                      <span className="f8-vw">
                        {otherForm?.guest} Tamu, {otherForm?.room} Kamar
                      </span>
                    </div>
                    <div
                      className="dropdown-menu py-0"
                      aria-labelledby="dropdownMenuButton"
                      style={{ width: "100%" }}
                    >
                      <div className="d-flex flex-column">
                        <div className="col d-flex justify-content-between align-items-center">
                          <div className="f8-vw">Tamu</div>
                          <div className="d-flex align-items-center">
                            <Count
                              setValue={handleGuest}
                              count={otherForm?.guest}
                            />
                          </div>
                        </div>
                        <div className="col py-0 d-flex justify-content-between align-items-center">
                          <div className="f8-vw">Kamar</div>
                          <div className="d-flex align-items-center">
                            <Count
                              setValue={handleRoom}
                              count={otherForm?.room}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
