import React from "react";
import { HeaderName } from "../../../common/HeaderName";
import { IS_MOBILE } from "../../../common/Responsive";
import { priceSplit } from "../../../utils";

const FONT_SIZE = IS_MOBILE ? "f12-vw" : "f8-vw";

export const CancelPaymentCheckout = ({
  items,
  back,
  confirm,
  loading,
  mode,
  redirect,
}) => {
  const detailPaymentMethod = (title, value) => {
    return (
      <div className={"d-flex flex-row " + FONT_SIZE}>
        <div className="col px-0">
          <span className="grey">{title}</span>
        </div>
        <div className="col px-0">: {value}</div>
      </div>
    );
  };

  const detailPaymentCharge = (title, value, color = "text-black") => {
    return (
      <div className={"d-flex flex-row " + FONT_SIZE}>
        <div className="col px-0">
          <span className="text-black">{title}</span>
        </div>
        <div className="col px-0">
          <div className="d-flex justify-content-between">
            <span className="text-black">Rp</span>
            <span className={color}>{priceSplit(value)}</span>
          </div>
        </div>
      </div>
    );
  };

  const detaiTotalPaid = (title, value) => {
    return (
      <div className={"d-flex flex-row " + FONT_SIZE}>
        <div className="col px-0">
          <span className="fw-700">{title}</span>
        </div>
        <div className="col px-0">
          <div className="d-flex justify-content-between">
            <div className="fw-700">Rp</div>
            <div className="">
              <div className="text-right fw-700">{priceSplit(value)}</div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div
      className="d-flex flex-column p-3"
      style={{ width: IS_MOBILE ? "21rem" : "22rem" }}
    >
      <div className="text-center">
        <img
          src={
            mode === "cancel-payment"
              ? "/img/icon/cancel-payment.svg"
              : "/img/popup/cancel-success.svg"
          }
          alt=""
          style={{ width: 100 }}
          loading="lazy"
        />
      </div>
      <div className="col">
        <div className="d-flex flex-column">
          {mode === "cancel-payment" ? (
            <>
              <span
                className={
                  IS_MOBILE
                    ? "f14-vw font-weight-bold text-center"
                    : "f10-vw font-weight-bold text-center"
                }
              >
                Apakah anda yakin ingin
              </span>
              <span
                className={
                  IS_MOBILE
                    ? "f14-vw font-weight-bold text-center"
                    : "f10-vw font-weight-bold text-center"
                }
              >
                membatalkan pembayaran ini?
              </span>
            </>
          ) : (
            <span
              className={
                IS_MOBILE
                  ? "f14-vw font-weight-bold text-center"
                  : "f10-vw font-weight-bold text-center"
              }
            >
              Cancel Payment Success
            </span>
          )}
        </div>
        <hr className="separator-blue" />
      </div>
      <div className="col">
        <div className="d-flex flex-column">
          <div className="col px-0">
            <div className="">
              <HeaderName
                text="Informasi Pembayaran"
                fontSize={
                  IS_MOBILE
                    ? "f14-vw font-weight-bold"
                    : "f10-vw font-weight-bold"
                }
                color2="purple"
              />
            </div>
            {detailPaymentMethod("Metode Pembayaran", "Transfer Bank")}
            {detailPaymentMethod("Nama Bank", items?.to_bank_name)}
            {detailPaymentMethod("Nomor Rekening", items?.bank_accno)}
          </div>
          <div className="col px-0 mt-2">
            <div className="">
              <HeaderName
                text="Informasi Tagihan"
                fontSize={
                  IS_MOBILE
                    ? "f14-vw font-weight-bold"
                    : "f10-vw font-weight-bold"
                }
                color2="purple"
              />
              <div className="">
                {items?.process_type === 2 ? (
                  <>
                    {detailPaymentCharge(
                      "Total Harga Kamar",
                      items?.room_charge
                    )}
                    {detailPaymentCharge(
                      "Late Check Out",
                      items?.early_checkin
                    )}
                    {detailPaymentCharge("Ganti Rugi", items?.ganti_rugi)}
                    <hr className="separator-blue my-2" />
                    {detaiTotalPaid("Total", items?.total_charge)}
                    {detaiTotalPaid("Deposit", items?.deposit)}
                    <hr className="separator-blue my-2" />
                    {detaiTotalPaid("Total Tagihan", items?.total_charge)}
                    <hr className="separator-blue my-2" />
                  </>
                ) : (
                  <>
                    <hr className="separator-blue my-2" />
                    {detaiTotalPaid("Total Tagihan", items?.total_charge, true)}
                    <hr className="separator-blue my-2" />
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      {mode === "cancel-payment" && (
        <div className="text-center px-3">
          <span className={IS_MOBILE ? "f10-vw" : "f8-vw"}>
            Apakah anda yakin ingin melanjutkan?
          </span>
        </div>
      )}
      <div className="col mt-3">
        {mode === "cancel-payment" ? (
          <div className="d-flex justify-content-between">
            <div className="col-4 px-0" onClick={() => back()}>
              <button
                className={
                  "btn btn-sm btn-block btn-outline-purple " + FONT_SIZE
                }
              >
                Back
              </button>
            </div>
            <div
              className="col-4 px-0"
              onClick={() => {
                confirm(items?.payment_id, items?.process_type);
              }}
            >
              <button
                className={`btn btn-sm btn-block text-white ${FONT_SIZE} ${
                  loading ? "btn-secondary" : "btn-gradient"
                }`}
                disabled={loading ? true : false}
              >
                {loading && (
                  <span
                    className="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                )}
                Confirm
              </button>
            </div>
          </div>
        ) : (
          <div className="d-flex justify-content-center">
            <div
              className="col-6 px-1"
              onClick={() => {
                redirect();
              }}
            >
              <button
                className={
                  "btn btn-sm btn-block bg-green rounded-input text-white " +
                  FONT_SIZE
                }
              >
                OK
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
