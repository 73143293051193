import React from "react";

export const HeaderName = ({
  fontSize,
  color1 = "purple",
  color2 = "orange",
  text,
}) => {
  const splitText = text && text?.split(" ");
  const indexOftext = text && splitText?.length - 1;
  const titleHeader = text && splitText?.slice(0, indexOftext).join(" ");
  if (splitText?.length < 2) {
    return (
      <>
        <div
          className={color1 ? color1 + " " + fontSize : color1 + " " + fontSize}
        >
          {splitText[0]}
        </div>
      </>
    );
  } else {
    return (
      <div>
        <span
          className={color1 ? color1 + " " + fontSize : color1 + " " + fontSize}
        >
          {titleHeader}
        </span>
        <span
          className={color2 ? color2 + " " + fontSize : color2 + " " + fontSize}
        >
          {" "}
          {splitText[splitText?.length - 1]}
        </span>
      </div>
    );
  }
};
