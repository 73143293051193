import { Icon } from "@iconify/react";
import React, { useEffect, useState } from "react";
import { HeaderName } from "../../../common/HeaderName";
import { IS_MOBILE } from "../../../common/Responsive";
import SelectOpt from "../../../common/SelectOpt";
import { iconUrl } from "../../../config";
import { isEmpty, priceSplit } from "../../../utils";

const FONT_SIZE = IS_MOBILE ? "f12-vw" : "f8-vw";
const FONT_SIZE_HEADER = IS_MOBILE ? "f16-vw" : "f12-vw";
const FONT_SIZE_TITLE = IS_MOBILE ? "f14-vw" : "f10-vw";

export const PaymentPopup = ({
  close,
  paymentMethod,
  paymentSelected,
  bankList,
  bankSelected,
  setBankSelected,
  setpaymentSelected,
  payment,
  charge,
  onClick,
  setPayment,
  setCharge,
  totalPaid,
  setTotalPaid,
  detail,
  setTotalCharge,
  totalCharge,
}) => {
  const [tempMethod, setTempMethod] = useState([]);

  const handlePaymentMethod = (val) => {
    setpaymentSelected(val);
    setBankSelected([]);
    setPayment(0);
  };

  const handlePayment = (val) => {
    setPayment(Number(val.target.value.replace(/\D/g, "")));
  };

  const handleBankSelected = (val) => {
    setBankSelected(val);
  };

  const detailPayment = (title, value, color, tax) => {
    return (
      <div className="d-flex flex-row f8-vw my-1 py-1">
        <div className="col-6 px-0">
          <span className={` ${color ? color : "text-black"}`}>{title}</span>
        </div>
        <div className="col px-0">
          <div className="d-flex flex-row justify-content-between">
            <div className={`${color ? color : "text-black"} text-black`}>
              Rp
            </div>
            <div
              className={`col px-0 text-right  ${color ? color : "text-black"}`}
            >
              <span className="px-2">{value}</span>
            </div>
          </div>
          {tax && value > 0 && (
            <div className="px-2" style={{ position: "relative" }}>
              <small
                className="f4-vw grey"
                style={{ position: "absolute", top: -3, right: 5 }}
              >
                Sudah termasuk pajak
              </small>
            </div>
          )}
        </div>
      </div>
    );
  };

  const mobileDetailPayment = (title, value, color, tax) => {
    return (
      <div className="d-flex flex-row f12-vw my-1 py-1">
        <div className="col-6 px-0">
          <span className={` ${color ? color : "text-black"}`}>{title}</span>
        </div>
        <div className="col px-0">
          <div className="d-flex flex-row justify-content-between">
            <div className={`${color ? color : "text-black"} text-black`}>
              Rp
            </div>
            <div
              className={`col px-0 text-right  ${color ? color : "text-black"}`}
            >
              <span className="px-2">{value}</span>
            </div>
          </div>
          {tax && value > 0 && (
            <div className="px-2" style={{ position: "relative" }}>
              <small
                className="f7-vw grey"
                style={{ position: "absolute", top: -3, right: 5 }}
              >
                Sudah termasuk pajak
              </small>
            </div>
          )}
        </div>
      </div>
    );
  };

  const mobilePaymentInput = (
    title,
    value,
    onChange,
    autoFocus,
    disabled = false
  ) => {
    return (
      <div className="d-flex flex-row align-items-center f12-vw">
        <div className="col-6 px-0">
          <span className={`font-weight-bold text-black`}>{title}</span>
        </div>
        <div className="col px-0">
          <div className="d-flex flex-row align-items-center justify-content-between">
            <div className={`font-weight-bold text-black`}>Rp</div>
            <div
              className={`col px-0 font-weight-bold text-black ml-2`}
              style={{ border: "1px solid lightgray", borderRadius: 5 }}
            >
              <input
                type="text"
                className="form-control text-right border-0 form-control-sm f12-vw font-weight-bold"
                value={priceSplit(value)}
                style={{ textAlign: "right" }}
                autoFocus={autoFocus}
                onChange={onChange}
                onFocus={(e) => (e.target.value = "")}
                onBlur={(e) => (e.target.value = priceSplit(value))}
                maxLength={12}
                inputMode="numeric"
                disabled={disabled}
              />
            </div>
          </div>
        </div>
      </div>
    );
  };

  const paymentInput = (
    title,
    value,
    onChange,
    autoFocus,
    disabled = false
  ) => {
    return (
      <div className="d-flex flex-row align-items-center f8-vw">
        <div className="col-6 px-0">
          <span className={`font-weight-bold text-black`}>{title}</span>
        </div>
        <div className="col px-0">
          <div className="d-flex flex-row align-items-center justify-content-between">
            <div className={`font-weight-bold text-black`}>Rp</div>
            <div
              className={`col px-0 font-weight-bold text-black ml-2`}
              style={{ border: "1px solid lightgray", borderRadius: 5 }}
            >
              <input
                type="text"
                className="form-control text-right border-0 form-control-sm f8-vw font-weight-bold"
                value={priceSplit(value)}
                style={{ textAlign: "right" }}
                autoFocus={autoFocus}
                onChange={onChange}
                onFocus={
                  value > 0
                    ? (e) => (e.target.value = priceSplit(value))
                    : (e) => (e.target.value = "")
                }
                onBlur={(e) => (e.target.value = priceSplit(value))}
                maxLength={12}
                disabled={disabled}
              />
            </div>
          </div>
        </div>
      </div>
    );
  };

  useEffect(() => {
    let total = detail?.room_charge + detail?.early_checkin;
    setTotalCharge(total);
  }, [
    detail.deposit,
    detail?.early_checkin,
    detail?.room_charge,
    setTotalCharge,
  ]);

  useEffect(() => {
    if (
      detail.room_charge === 0 &&
      detail.early_checkin === 0 &&
      detail.deposit >= 0 &&
      !isEmpty(paymentMethod)
    ) {
      let d = paymentMethod.find((item) => item.value === 3);
      let x = paymentMethod.filter((item) => item.value === 3);
      setpaymentSelected(d);
      setTempMethod(x);
    } else {
      setTempMethod(paymentMethod);
    }
  }, [
    detail.deposit,
    detail.early_checkin,
    detail.room_charge,
    paymentMethod,
    setpaymentSelected,
  ]);

  useEffect(() => {
    if (isEmpty(paymentSelected) || paymentSelected?.value === 3) {
      let d = totalCharge + detail?.deposit;
      setTotalPaid(d);
    } else {
      setTotalPaid(totalCharge);
    }
  }, [
    detail.deposit,
    paymentSelected,
    paymentSelected?.value,
    setTotalPaid,
    totalCharge,
  ]);

  useEffect(() => {
    if (paymentSelected?.value === 3 && payment >= totalPaid) {
      setCharge(Math.abs(parseInt(totalPaid) - parseInt(payment)));
    } else {
      setCharge(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentSelected, totalPaid, payment]);

  return (
    <div className="d-flex flex-column px-4 py-4" style={{ width: "21rem" }}>
      <div className="col px-0 py-1">
        <div className="d-flex align-items-center justify-content-start">
          <Icon
            icon={iconUrl.walletCreditCard20Filled}
            color="orange"
            width={25}
          />
          <div className="fw-600 mx-2">
            <HeaderName
              text="Pembayaran"
              fontSize={FONT_SIZE_HEADER}
              color1="blue-title"
            />
          </div>
        </div>
      </div>
      <div
        className="col px-0"
        style={{
          backgroundColor: "white",
          borderRadius: "5px",
        }}
      >
        <div className="d-flex flex-column my-2">
          <div className="col px-1">
            <div className="">
              <label htmlFor="payment-method" className={"my-1 " + FONT_SIZE}>
                Metode Pembayaran
              </label>
              <SelectOpt
                placeholder="Pilih metode pembayaran"
                optionsValue={tempMethod}
                onChange={handlePaymentMethod}
                fontSize={IS_MOBILE ? "calc(8pt + 0.472vw)" : ""}
                value={paymentSelected}
              />
            </div>
          </div>
          {paymentSelected?.value === 2 && (
            <div className="col px-1 mt-2">
              <div className="">
                <label htmlFor="payment-method" className={"my-1 " + FONT_SIZE}>
                  Pilih Bank
                </label>
                <SelectOpt
                  placeholder="Pilih bank"
                  optionsValue={bankList}
                  onChange={handleBankSelected}
                  fontSize={IS_MOBILE ? "calc(8pt + 0.472vw)" : ""}
                  value={bankSelected}
                />
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="col px-0 my-1">
        <div className="d-flex flex-column my-1">
          <div className="col px-1">
            <HeaderName
              text="Informasi Pembayaran"
              fontSize={"fw-600 " + FONT_SIZE_TITLE}
              color2="purple"
            />
          </div>
          <div className="col px-1">
            {detail?.voucher_no !== "" && (
              <div>
                <div className={FONT_SIZE}>Kode Voucher</div>
                <div className={"blue " + FONT_SIZE}>{detail.voucher_no}</div>
              </div>
            )}
            {IS_MOBILE ? (
              <>
                {mobileDetailPayment(
                  "Total Harga Kamar",
                  priceSplit(
                    detail.voucher_type === 1
                      ? detail.room_charge
                      : detail.real_room_charge
                  )
                )}
                {mobileDetailPayment(
                  "Early Check In",
                  priceSplit(detail?.early_checkin)
                )}
                {isEmpty(paymentSelected) || paymentSelected?.value === 3
                  ? mobileDetailPayment("Deposit", priceSplit(detail.deposit))
                  : null}
                {detail?.diskon > 0 &&
                  detail.voucher_type !== 1 &&
                  mobileDetailPayment(
                    "Diskon",
                    detail?.diskon > 0
                      ? "-" + priceSplit(detail?.diskon)
                      : detail?.diskon
                  )}

                <hr className="separator-blue my-2" />
                {mobileDetailPayment(
                  "Total Tagihan",
                  priceSplit(totalPaid),
                  "fw-700",
                  true
                )}
                {paymentSelected?.value === 2 &&
                  mobileDetailPayment(
                    "Deposit (Cash)",
                    priceSplit(detail.deposit),
                    "fw-700"
                  )}
                {paymentSelected?.value === 3 && (
                  <>
                    <hr className="separator-blue my-2" />
                    {mobilePaymentInput(
                      "Pembayaran Tunai",
                      payment,
                      (e) => handlePayment(e),
                      false,
                      totalPaid === 0 ? true : false
                    )}
                    {mobileDetailPayment(
                      "Kembalian",
                      priceSplit(charge),
                      "text-black fw-700",
                      false
                    )}
                  </>
                )}
              </>
            ) : (
              <>
                {detailPayment(
                  "Total Harga Kamar",
                  priceSplit(
                    detail.voucher_type === 1
                      ? detail.room_charge
                      : detail.real_room_charge
                  )
                )}
                {detailPayment(
                  "Early Check In",
                  priceSplit(detail?.early_checkin)
                )}
                {isEmpty(paymentSelected) || paymentSelected?.value === 3
                  ? detailPayment("Deposit", priceSplit(detail.deposit))
                  : null}
                {detail?.diskon > 0 &&
                  detail.voucher_type !== 1 &&
                  detailPayment(
                    "Diskon",
                    detail?.diskon > 0
                      ? "-" + priceSplit(detail?.diskon)
                      : detail?.diskon
                  )}

                <hr className="separator-blue my-2" />
                {detailPayment(
                  "Total Tagihan",
                  priceSplit(totalPaid),
                  "fw-700",
                  true
                )}
                {paymentSelected?.value === 2 &&
                  detailPayment(
                    "Deposit (Cash)",
                    priceSplit(detail.deposit),
                    "fw-700"
                  )}
                {paymentSelected?.value === 3 && (
                  <>
                    <hr className="separator-blue my-2" />
                    {paymentInput(
                      "Pembayaran Tunai",
                      payment,
                      (e) => handlePayment(e),
                      false,
                      totalPaid === 0 ? true : false
                    )}
                    {detailPayment(
                      "Kembalian",
                      priceSplit(charge),
                      "text-black fw-700",
                      false
                    )}
                  </>
                )}
              </>
            )}
          </div>
          <div className="col px-1">
            <hr className="separator-blue my-1" />
          </div>
        </div>
      </div>
      <div className="col">
        <div className="text-center mb-3">
          <small className={`fw-500 ${IS_MOBILE ? "f10-vw" : "f8-vw"}`}>
            Apakah anda yakin ingin melanjutkan?
          </small>
        </div>
      </div>
      <div className="col px-0">
        <div className="d-flex justify-content-between">
          <div className="col pl-1">
            <button
              className={`btn btn-block ${FONT_SIZE} font-weight-bold btn-outline-purple`}
              onClick={close}
            >
              Cancel
            </button>
          </div>
          <div className="col pr-1">
            <button
              className={`btn btn-block ${FONT_SIZE} font-weight-bold text-white ${
                (paymentSelected?.value === 2 && bankSelected?.value) ||
                (paymentSelected?.value === 3 && payment >= totalPaid)
                  ? "btn-gradient"
                  : "btn-secondary"
              }`}
              onClick={onClick}
              disabled={
                (paymentSelected?.value === 2 && bankSelected?.value) ||
                (paymentSelected?.value === 3 && payment >= totalPaid)
                  ? false
                  : true
              }
            >
              Confirm
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
