import React from "react";
import { Loading } from "../../common/Loading";
import { img_fallback } from "../../utils/ui";

export const EmptyData = ({
  loading,
  text,
  sourceImg,
  errMessage,
  height = 0,
}) => {
  return loading ? (
    <div
      className="d-flex justify-content-center align-items-center"
      style={{ height: height }}
    >
      <Loading />
    </div>
  ) : (
    <div
      className="d-flex flex-column justify-content-center align-items-center"
      style={{ height: height }}
    >
      <div>
        <img
          src={sourceImg ? sourceImg : "/img/nodata/not-found.svg"}
          alt=""
          style={{ width: "5.5rem" }}
          onError={({ currentTarget }) => {
            currentTarget.onerror = null; // prevents looping
            currentTarget.src = img_fallback;
          }}
          loading="lazy"
        />
      </div>
      <div className="grey">
        <div className="f8-vw d-flex flex-column text-center">
          {errMessage ? (
            errMessage
          ) : (
            <>
              <span>{text ? text : "Tidak ada data"}</span>
            </>
          )}
        </div>
      </div>
    </div>
  );
};
export const MobileEmptyData = ({
  loading,
  text,
  sourceImg,
  errMessage,
  height = 0,
}) => {
  return loading ? (
    <div
      className="d-flex justify-content-center align-items-center"
      style={{ height: height }}
    >
      <Loading />
    </div>
  ) : (
    <div
      className="d-flex flex-column justify-content-center align-items-center"
      style={{ height: height }}
    >
      <span>
        <img
          src={sourceImg ? sourceImg : "/img/nodata/not-found.svg"}
          alt=""
          style={{ width: "5.5rem" }}
          onError={({ currentTarget }) => {
            currentTarget.onerror = null; // prevents looping
            currentTarget.src = img_fallback;
          }}
        />
      </span>
      <div className="grey">
        <div className="f14-vw d-flex flex-column text-center">
          {errMessage ? (
            errMessage
          ) : (
            <>
              <span>{text ? text : "Tidak ada data"}</span>
            </>
          )}
        </div>
      </div>
    </div>
  );
};
