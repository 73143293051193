import { Icon } from "@iconify/react";
import React from "react";
import { IS_MOBILE } from "./Responsive";

const HotelRules = ({
  iconName,
  title,
  info,
  iconColor,
  fontColor,
  infoColor = "text-black",
  children,
  iconSize = 25,
}) => {
  return (
    <>
      {IS_MOBILE ? (
        <div className="d-flex">
          {iconName && (
            <span>
              <Icon icon={iconName} color={iconColor} width={iconSize} />
            </span>
          )}
          {children ? (
            children
          ) : (
            <div className="ml-1">
              <div className="d-flex flex-column">
                <div className="px-0">
                  <span className={`f12-vw fw-800 ${fontColor}`}>{title}</span>
                </div>
                <span className={`f12-vw fw-700 ${infoColor}`}>{info}</span>
              </div>
            </div>
          )}
        </div>
      ) : (
        <div className="d-flex align-items-center">
          {iconName && (
            <div className="">
              <span>
                <Icon icon={iconName} color={iconColor} width={iconSize} />
              </span>
            </div>
          )}
          {children ? (
            children
          ) : (
            <div className="ml-3">
              <div className="d-flex flex-column">
                <div className="px-0">
                  <span className={`f8-vw fw-800 ${fontColor}`}>{title}</span>
                </div>
                <div className="px-0">
                  <span className={`f9-vw fw-700 ${infoColor}`}>{info}</span>
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default React.memo(HotelRules);
