import React from "react";
import { IS_MOBILE } from "./Responsive";
import { ButtonConfirm } from "./Notif";
import { getServerTimeWithoutDays } from "../utils";

export const WarningModal = ({
  width = "21rem",
  messageHeader,
  type = "Check-in",
  close,
  confirm,
  book_id,
  guestName,
  date,
}) => {
  const FONT_DEFAULT = IS_MOBILE ? "f12-vw" : "f8-vw";
  const popupHeader = (url) => {
    return (
      <div className="popup-header">
        <div className="px-2 pt-4">
          <div className="d-flex justify-content-center">
            <img src={url} alt="" width="100px" />
          </div>
        </div>
      </div>
    );
  };

  const bodyMessage = (title, value) => {
    return (
      <div className={`d-flex flex-column ${FONT_DEFAULT} mb-2`}>
        <div className="col px-0 grey">{title}</div>
        <div className="col px-0 fw-700">{value || "-"}</div>
      </div>
    );
  };

  return (
    <div style={{ width: width }}>
      {popupHeader("/img/popup/warning-popup.png")}
      <div className="notif-body mx-4 my-2">
        <div
          className={`text-center ${
            IS_MOBILE ? "f16-vw" : "f12-vw"
          } font-weight-bold`}
        >
          {messageHeader}
        </div>
        <div className="d-flex flex-column mt-2">
          {bodyMessage("Booking ID", book_id)}
          {bodyMessage("Nama Tamu", guestName)}
          {bodyMessage(`Tgl ${type}`, getServerTimeWithoutDays(date))}
        </div>
      </div>
      <ButtonConfirm
        close={close}
        secondButton="Kembali"
        confirm={confirm}
        text={"Ya"}
      />
    </div>
  );
};
