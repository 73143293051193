import { Icon } from "@iconify/react/dist/iconify.js";
import React, { useState } from "react";
import { IS_MOBILE } from "../../../common/Responsive";
import { iconUrl } from "../../../config";
import ReactDatePicker from "react-datepicker";
import {
  checkExtendDate,
  formatDate,
  getServerTimeWithoutDays,
  priceSplit,
} from "../../../utils";
import { addData, getErr, safeErrCode } from "../../../utils/api-request";
import { useTranslation } from "react-i18next";

const FONT_SIZE_HEADER = IS_MOBILE ? "16-vw" : "f12-vw";
const FONT_SIZE = IS_MOBILE ? "f12-vw" : "f8-vw";

const DatePickerExtends = ({
  border,
  fontSize = "f8-vw",
  setDate,
  date = new Date(),
  minDate = new Date(),
  disable = false,
}) => {
  const handleDate = (val) => {
    setDate(val);
  };
  const handleCalendarOpen = () => {
    document.addEventListener(
      "touchstart",
      (event) => {
        event.stopPropagation();
      },
      true
    );
  };
  // const openCalender = () => {
  //   startRef.current.setOpen(true);
  // };
  return (
    <div
      className="form-control form-control-sm d-flex align-items-center px-2 w-100"
      style={{
        border,
        borderRadius: 5,
        padding: "3px 0",
        backgroundColor: "white",
      }}
      // onClick={() => openCalender()}
    >
      <Icon icon={iconUrl.calendarIcon} color="#ef9403" width={18} />

      <div className={`px-0`}>
        <ReactDatePicker
          selected={date}
          onChange={handleDate}
          minDate={minDate}
          className={"fw-600 text-center p-0 date-checkin-new " + fontSize}
          dateFormat="dd MMM yyyy"
          withPortal={IS_MOBILE ? true : false}
          onCalendarOpen={handleCalendarOpen}
          onChangeRaw={(e) => {
            e.preventDefault();
          }}
          onKeyDown={(e) => {
            e.preventDefault();
          }}
          onFocus={(e) => e.target.blur()}
          disabled={disable}
        />
      </div>
    </div>
  );
};

const ExtendsModal = ({
  close,
  detail,
  loading,
  price,
  setMode,
  setBillingExtend,
  setError,
  timezone,
  setRedirected,
  setHeaderPopup,
  date,
  setDate,
  errorExtend,
}) => {
  const {
    book_id,
    booking_id,
    activity_id,
    checkout_date,
    guest_name,
    room_number,
  } = detail;
  const { total_charge, rate_key } = price || {};
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const { t } = useTranslation();

  const detailLabel = (title, value, col = 6) => {
    return (
      <div className={`col-${col} px-0 mb-3`}>
        <div className={`d-flex flex-column ${FONT_SIZE}`}>
          <div className="col px-0 grey">{title}</div>
          <div className="col px-0 fw-600">{value ? value : "-"}</div>
        </div>
      </div>
    );
  };

  // const checkPriceExtend = useCallback(() => {
  //   setLoading(true);
  //   const payload = {
  //     booking_id,
  //     activity_id,
  //     extend_todate: formatDate(date),
  //   };
  //   let paramsFormatted = null;

  //   if (!isEmpty(payload)) {
  //     paramsFormatted = new URLSearchParams(payload);
  //   }

  //   const endpoint = `/api/v1/get_extend_price?${
  //     paramsFormatted !== null ? `&${paramsFormatted}` : ""
  //   }`;
  //   getData(endpoint)
  //     .then((resp) => {
  //       if (resp?.message === "Success") {
  //         setPrice(resp?.data);
  //         setErrorGet("");
  //       } else {
  //         resp.json().then((err) => {
  //           setErrorGet(t(safeErrCode(err)));
  //         });
  //       }
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //       setErrorGet(t(getErr(err)));
  //     })
  //     .finally(() => setLoading(false));
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [date]);

  const handleSubmit = () => {
    setLoadingSubmit(true);
    const payload = {
      booking_id,
      activity_id,
      extend_todate: formatDate(date),
      total_charge,
      ...(!!rate_key && { rate_key }),
    };
    const url = "/api/v1/extend";
    addData(url, payload)
      .then((resp) => {
        if (resp.message === "Success") {
          setBillingExtend(resp?.billing_id);
          setMode("extends-payment");
        } else {
          resp.json().then((err) => {
            if (err.code === "E2011") {
              setRedirected("process");
            } else if (err.code === "E2012") {
              setRedirected("confirm");
            }
            setHeaderPopup("Extend Booking Gagal");
            setError(t(safeErrCode(err)));
            setMode("error");
          });
        }
      })
      .catch((err) => {
        console.log(err);
        setMode("error");
        setError(t(getErr(err)));
      })
      .finally(() => setLoadingSubmit(false));
  };

  // useEffect(() => {
  //   checkPriceExtend();
  // }, [checkPriceExtend]);

  return (
    <div
      className="px-4 py-3"
      style={{
        width: IS_MOBILE ? "21rem" : "25rem",
      }}
    >
      <div
        className={`d-flex align-items-center blue-title fw-700 ${FONT_SIZE_HEADER}`}
      >
        <Icon icon="mingcute:calendar-add-fill" color="orange" width={25} />
        <span className="ml-2">Booking Extends</span>
      </div>
      <div>
        <hr className="separator-blue my-2" />
      </div>
      <div className="d-flex flex-wrap">
        {detailLabel("Booking ID", book_id)}
        {detailLabel(
          "Checkout Date",
          getServerTimeWithoutDays(checkout_date, timezone)
        )}
        {detailLabel("Room Number", room_number)}
        {detailLabel(
          "Extend",
          <DatePickerExtends
            date={date}
            setDate={setDate}
            fontSize={IS_MOBILE ? "f12-vw" : "f8-vw"}
            disable={loading}
            minDate={checkExtendDate(checkout_date)}
          />
        )}
        {detailLabel("Nama Tamu", guest_name)}
        {detailLabel(
          "Total Charge",
          !!errorExtend ? (
            <span className="text-danger f8-vw">{errorExtend}</span>
          ) : loading ? (
            <span
              className="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            ></span>
          ) : (
            <span className="fw-700 f10-vw">Rp {priceSplit(total_charge)}</span>
          )
        )}
      </div>
      <div>
        <hr className="separator-blue my-2" />
      </div>
      <div className="d-flex flex-row justify-content-between">
        <div className="col-5 px-0">
          <button
            className={`btn btn-block text-white ${FONT_SIZE} btn-outline-purple purple`}
            onClick={() => close()}
          >
            Back
          </button>
        </div>
        <div className="col-5 px-0">
          <button
            className={`btn btn-block ${
              loading || loadingSubmit ? "btn-count-disable" : "bg-primaryCol"
            } text-white ${FONT_SIZE}`}
            disabled={loading || loadingSubmit}
            onClick={() => handleSubmit()}
          >
            {loadingSubmit && (
              <span
                className="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              ></span>
            )}
            Confirm
          </button>
        </div>
      </div>
    </div>
  );
};

export default ExtendsModal;
