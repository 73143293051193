import { isLogout } from "../utils";
import history from "../utils/history";
import { config } from "../config";

// CRUD API
export const publicPost = (param) => {
  const requestMethod = {
    method: "POST",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
    },
    body: new URLSearchParams(param),
  };
  return requestMethod;
};

export const postMethod = (param) => {
  const requestMethod = {
    method: "POST",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      Authorization: "Bearer " + sessionStorage.getItem("token"),
    },
    body: new URLSearchParams(param),
  };
  return requestMethod;
};
export const postPict = (param) => {
  const requestMethod = {
    method: "POST",
    headers: {
      // "Content-Type": "multipart/form-data",
      Authorization: "Bearer " + sessionStorage.getItem("token"),
    },
    body: param,
  };
  return requestMethod;
};

export const deleteMethod = () => {
  const requestMethod = {
    method: "DELETE",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      Authorization: "Bearer " + sessionStorage.getItem("token"),
    },
  };
  return requestMethod;
};

const updateMethod = (param) => {
  const requestMethod = {
    method: "PUT",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      Authorization: "Bearer " + sessionStorage.getItem("token"),
    },
    body: new URLSearchParams(param),
  };
  return requestMethod;
};

const updateMethodPict = (param) => {
  const requestMethod = {
    method: "PUT",
    headers: {
      // "Content-Type": "multipart/form-data",
      Authorization: "Bearer " + sessionStorage.getItem("token"),
    },
    body: param,
  };
  return requestMethod;
};

// API URL

export const getData = async (url, { ...propsHeader }) => {
  const token = sessionStorage.getItem("token");
  return fetch(config.base_url + url, {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      Authorization: "Bearer " + token,
    },
    ...propsHeader,
  })
    .then((resp) => {
      if (resp.status >= 200 && resp.status <= 299) {
        return resp.json();
      } else if (resp.status === 401 || resp.status === 403) {
        isLogout(history.push("/login"));
      } else {
        return resp;
      }
    })
    .catch((err) => {
      throw err;
    });
};

export const addData = async (url, payload) => {
  return await fetch(config.base_url + url, postMethod(payload))
    .then((resp) => {
      if (resp.status >= 200 && resp.status <= 299) {
        return resp.json();
      } else if (resp.status === 401 || resp.status === 403) {
        isLogout(history.push("/login"));
      } else {
        return resp;
      }
    })
    .catch((err) => {
      throw err;
    });
};

export const addDataPict = async (url, payload) => {
  return await fetch(config.base_url + url, postPict(payload))
    .then((resp) => {
      if (resp.status >= 200 && resp.status <= 299) {
        return resp.json();
      } else if (resp.status === 401 || resp.status === 403) {
        isLogout(history.push("/login"));
      } else {
        return resp;
      }
    })
    .catch((err) => {
      throw err;
    });
};

export const deleteData = async (url, payload) => {
  return await fetch(config.base_url + url, deleteMethod(payload))
    .then((resp) => {
      if (resp.status >= 200 && resp.status <= 299) {
        return resp.json();
      } else if (resp.status === 401 || resp.status === 403) {
        isLogout(history.push("/login"));
      } else {
        return resp;
      }
    })
    .catch((err) => {
      throw err;
    });
};

export const updateData = async (url, payload) => {
  return await fetch(config.base_url + url, updateMethod(payload))
    .then((resp) => {
      if (resp.status >= 200 && resp.status <= 299) {
        return resp.json();
      } else if (resp.status === 401 || resp.status === 403) {
        isLogout(history.push("/login"));
      } else {
        return resp;
      }
    })
    .catch((err) => {
      throw err;
    });
};
export const updateDataPict = async (url, payload) => {
  return await fetch(config.base_url + url, updateMethodPict(payload))
    .then((resp) => {
      if (resp.status >= 200 && resp.status <= 299) {
        return resp.json();
      } else if (resp.status === 401 || resp.status === 403) {
        isLogout(history.push("/login"));
      } else {
        return resp;
      }
    })
    .catch((err) => {
      throw err;
    });
};

export const getCsvFile = async (url) => {
  const token = sessionStorage.getItem("token");
  return fetch(config.base_url + url, {
    headers: {
      Accept: "application/vnd.ms-excel",
      Authorization: "Bearer " + token,
    },
  })
    .then((resp) => {
      if (resp.status >= 200 && resp.status <= 299) {
        return resp;
      } else if (resp.status === 401 || resp.status === 403) {
        isLogout(history.push("/login"));
      } else {
        return resp;
      }
    })
    .catch((err) => {
      throw err;
    });
};

export const getErr = (err) => {
  let hErr = new Error(err).message;
  hErr = hErr.split(":")[1].trim();
  let report = "Unknown Error";
  switch (hErr.toLowerCase()) {
    case "failed to fetch":
      report = "FE0002";
      break;
    case "can't access dead object":
      report = "Cant Access Format Data";
      break;
    default:
      report = "Unknown error";
      break;
  }
  return report;
};

export const safeErrCode = (code) => {
  return code.code ? code.code : code.message;
};

const wait = (delay) => {
  return new Promise((resolve) => setTimeout(resolve, delay));
};

const fetchWithTimeout = async (url, timeout) => {
  const abortController = new AbortController();
  setTimeout(() => {
    abortController.abort();
  }, timeout);
  return await getData(url, { signal: abortController.signal });
};

export const fetchWithRetry = async (promise, timeout, retries = 2) => {
  let count = retries;
  let increasingTimeout = 2;

  while (count > 0) {
    try {
      return await fetchWithTimeout(promise, timeout);
    } catch (error) {
      if (error.name !== "AbortError") throw error;
      count--;
      increasingTimeout = Math.pow(increasingTimeout, 2);
      await wait(increasingTimeout);
    }
  }
  return Promise.reject(new Error(`failed to fetch`));
};
