import { routerComponent } from "./routerPage";

export const aclFilter = (usr_acl) => {
  let d = JSON.parse(localStorage?.getItem("acl_list"));
  let x = usr_acl?.map((item) => {
    return d?.includes(item, 0);
  });
  let dx = x.filter((item) => item === true);
  return dx.length >= 2 ? true : false;
};

export const aclFilterSingle = (user_acl) => {
  let d = JSON.parse(localStorage?.getItem("acl_list"));
  let x = d?.find((item) => item === user_acl);
  return x ? true : false;
};

export const aclFilterMenu = (usr_acl) => {
  let d = JSON.parse(localStorage?.getItem("acl_list"));
  let x = usr_acl?.map((item) => {
    return d?.includes(item, 0);
  });
  let dx = x.filter((item) => item === true);
  return dx.length >= 1 ? true : false;
};

export const checkTrue = (usr_acl) => {
  let d = JSON.parse(localStorage?.getItem("acl_list"));
  let x = usr_acl?.map((item) => {
    return d?.includes(item, 0);
  });
  console.log(x);
};

export const getLocalStorageItem = (localStore) => {
  try {
    let data = JSON?.parse(localStorage?.getItem(localStore));
    return data;
  } catch (errCatch) {
    return [];
  }
};

export const priorityPage = (arr_data) => {
  let data = getLocalStorageItem("sub_menu_action_2");
  let arr_original = data;
  let x = [];
  if (arr_original?.length > 0) {
    arr_original?.map((value) => {
      let d = routerComponent.filter((v, i) => v.acl === value);
      return x.push(d[0]);
    });
    let z = x.sort((a, b) => a.execution_type - b.execution_type);
    return z[0];
  }
};

export const priorityUrlLogin = (arr_data) => {
  // let data = localStorage.getItem("sub_menu_action");
  // let arr_original = data.split(",");

  let x = [];
  arr_data.map((value) => {
    let d = routerComponent.filter((v, i) => v.acl === value);
    return x.push(d[0]);
  });
  let z = x.sort((a, b) => a.execution_type - b.execution_type);
  console.log(z);
  return z[0];
};
