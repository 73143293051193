export const grouping_print = (data) => {
  const object = data.reduce(
    (r, { book_id, checkin_date, checkout_date, ...rest }) => {
      if (!r[book_id])
        r[book_id] = {
          book_id,
          checkin_date,
          checkout_date,
          detail: [rest],
        };
      else r[book_id].detail.push(rest);
      return r;
    },
    {}
  );
  const result = Object.values(object);
  return result;
};
