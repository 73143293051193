import { priceSplit } from "../../utils";

export const createLineDash = () => {
  return (
    <p className="p-print txt-print-h2">
      {"- - - - - - - - - - - - - - - - - - - - - - - - - -"}
    </p>
  );
};

export const createLineSolid = () => {
  return (
    <div className="p-print txt-print-h2">
      <hr
        style={{
          width: "100%",
          color: "#000",
          border: "0.8px solid #000",
          backgroundColor: "#000",
        }}
      />
    </div>
  );
};

export const genContent1 = (label, value) => {
  return (
    <tr className="rmp txt-print-section">
      <td width={120}>{label}</td>
      <td>: {value}</td>
    </tr>
  );
};

export const genContent2 = (label, value) => {
  return (
    <tr className="txt-print-section">
      <td width={100}>{label}</td>
      <td width={90} style={{ textAlign: "center" }}>
        {"IDR"}
      </td>
      <td className={"txt-price"} width={60}>
        {priceSplit(value)}
      </td>
    </tr>
  );
};

export const genBookingId = (label, value) => {
  return (
    <>
      <p className="txt-print-section" style={{ margin: 0 }}>
        {label}
      </p>
      <p className="txt-print-section" style={{ margin: 0 }}>
        {value}
      </p>
    </>
  );
};
export const genPaymentID = (value) => {
  return (
    <>
      <p className="p-print txt-print-h2">Payment ID {"\t"}</p>
      <p className="p-print txt-print-h2">{value}</p>
    </>
  );
};
