import { Icon } from "@iconify/react";
import { MobileCardList } from "../../../common/CardList";
import { DateRangeFilterNew } from "../../../common/DateRange";
import { IS_MOBILE } from "../../../common/Responsive";
import { iconUrl } from "../../../config";
import {
  generateBookingCode,
  getServerTimeWithoutDays,
  tableCol,
  showMoreGuest,
  splitText,
  parsePhone,
  showMore,
  checkInvalidTime,
  convertISOtoString,
  addDays,
} from "../../../utils";
import { isValidAccess, is_sub_menu_action } from "../../../utils/uac";
import { searchInput } from "../../booking/bookingHelper";
import { EmptyData, MobileEmptyData } from "../../Empty/EmptyData";

export const CheckoutList = ({
  booking,
  bookingCode,
  setBookingCode,
  loading,
  errMessage,
  timezone,
  range,
  setRange,
  guestFullname,
  setguestFullname,
  filterClicked,
  checkoutClicked,
  breakageClicked,
  changeRoomClicked,
  onKeyPress,
  roomNumberFilter,
  setRoomNumberFilter,
  handleClear,
  extendClicked,
}) => {
  return (
    <>
      {IS_MOBILE ? (
        <div className="d-flex flex-column p-1 mb-5">
          <div className="col px-0">
            {booking?.length > 0 ? (
              booking.map((item) => {
                return (
                  <MobileCardList
                    title={
                      <>
                        <div
                          className="f10-vw fw-500"
                          style={{ color: "#959595" }}
                        >
                          Kode Booking
                        </div>
                        <div>{item?.book_id}</div>
                      </>
                    }
                    key={item?.room_number}
                    action_button={
                      isValidAccess([
                        "ch_process_check_out_breakage",
                        "ch_process_check_out_change_room",
                        "ch_process_check_out_process",
                      ]) && (
                        <div className="dropdown combo-box-mobile col px-0">
                          <button
                            className="btn btn-sm btn-gradient text-white dropdown-toggle border-0 btn-block f12-vw fw-600"
                            type="button"
                            data-toggle="dropdown"
                            aria-expanded="false"
                          >
                            Action
                            <span className="ml-2">
                              <Icon icon={iconUrl.arrowDownAlt2} width={15} />
                            </span>
                          </button>
                          <div
                            className="dropdown-menu dropdown-menu-center"
                            style={{ zIndex: 2 }}
                          >
                            {is_sub_menu_action(
                              "ch_process_check_out_process"
                            ) && (
                              <button
                                className="menu border-0 purple bg-transparent f12-vw font-weight-bold btn-block m-0"
                                onClick={() => {
                                  checkoutClicked(item);
                                }}
                              >
                                Check Out
                              </button>
                            )}
                            {is_sub_menu_action(
                              "ch_process_check_out_breakage"
                            ) && (
                              <button
                                className="menu border-0 purple bg-transparent f12-vw font-weight-bold btn-block m-0"
                                onClick={() => {
                                  breakageClicked(item);
                                }}
                              >
                                Breakage
                              </button>
                            )}
                            {is_sub_menu_action(
                              "ch_process_check_out_change_room"
                            ) &&
                              !checkInvalidTime(
                                convertISOtoString(item.checkout_date)
                              ) && (
                                <button
                                  className="menu border-0 purple bg-transparent f12-vw font-weight-bold btn-block m-0"
                                  onClick={() => {
                                    changeRoomClicked(item);
                                  }}
                                >
                                  Change Room
                                </button>
                              )}

                            {is_sub_menu_action(
                              "ch_process_check_out_extend"
                            ) && (
                              <button
                                className="menu border-0 purple bg-transparent f12-vw font-weight-bold btn-block m-0"
                                onClick={() => {
                                  extendClicked(item);
                                }}
                              >
                                Extends
                              </button>
                            )}
                          </div>
                        </div>
                      )
                    }
                    fontSizeHeader="f12-vw"
                  >
                    <div className="card-hms-list">
                      <div className="col-4 px-0 grey-text">Nomor Kamar</div>
                      <div className="col pr-0 f12-vw black-value">
                        {item?.room_number}
                      </div>
                    </div>
                    <div className="card-hms-list">
                      <div className="col-4 px-0 grey-text">Nama Tamu</div>
                      <div className="col pr-0 f12-vw black-value">
                        {showMore(item?.guest_name, 25)}
                      </div>
                    </div>
                    <div className="card-hms-list">
                      <div className="col-4 px-0 grey-text">Floor</div>
                      <div className="col pr-0 f12-vw black-value">
                        {item?.floor}
                      </div>
                    </div>
                    <div className="card-hms-list">
                      <div className="col-4 px-0 grey-text">Room Type</div>
                      <div className="col pr-0 f12-vw black-value">
                        {item?.room_type_name}
                      </div>
                    </div>
                    <div className="card-hms-list">
                      <div className="col-4 px-0 grey-text">Bed Type</div>
                      <div className="col pr-0 f12-vw black-value">
                        {item?.bed_type_name}
                      </div>
                    </div>
                    <div className="card-hms-list">
                      <div className="col-4 px-0 grey-text">Bed Size</div>
                      <div className="col pr-0 f12-vw black-value">
                        {item?.bed_size}
                      </div>
                    </div>
                    <div className="card-hms-list">
                      <div className="col-4 px-0 grey-text">Phone Number</div>
                      <div className="col pr-0 f12-vw black-value">
                        {parsePhone(item?.phone)}
                      </div>
                    </div>
                    <div className="card-hms-list">
                      <div className="col-4 px-0 grey-text">Check Out</div>
                      <div className="col pr-0 f12-vw black-value">
                        {getServerTimeWithoutDays(
                          item?.checkout_date,
                          timezone
                        )}
                      </div>
                    </div>
                  </MobileCardList>
                );
              })
            ) : (
              <div style={{ position: "relative" }}>
                <MobileEmptyData
                  errMessage={errMessage}
                  loading={loading}
                  height="40vh"
                />
              </div>
            )}
          </div>
        </div>
      ) : (
        <div className="d-flex flex-column px-3">
          <div className="col px-0">
            <div className="d-flex flex-row justify-content-between">
              <div className="col-md-9 px-0">
                <div className="d-flex flex-row">
                  <div className="col px-0 mr-1">
                    <label className="mb-0 f8-vw fw-600">
                      Pilih Tanggal Check Out
                    </label>
                    <DateRangeFilterNew
                      start={range?.start}
                      end={range?.end}
                      handleStart={(value) =>
                        setRange({
                          ...range,
                          start: value,
                          end: addDays(value, 2),
                        })
                      }
                      handleEnd={(value) => setRange({ ...range, end: value })}
                      minDate={true}
                      isClear
                      handleClear={handleClear}
                    />
                  </div>
                  <div className="col px-0 mr-1">
                    <label htmlFor="" className="f8-vw m-0 p-0 fw-600">
                      Kode Booking
                    </label>
                    {searchInput(
                      "Cari Kode Booking",
                      bookingCode,
                      (e) =>
                        setBookingCode(generateBookingCode(e.target.value)),
                      (e) => onKeyPress(e)
                    )}
                  </div>
                  <div className="col px-0 mr-1">
                    <label htmlFor="" className="f8-vw m-0 p-0 fw-600">
                      Nama Tamu
                    </label>
                    {searchInput(
                      "Cari Tamu",
                      guestFullname,
                      (e) => setguestFullname(e.target.value),
                      (e) => onKeyPress(e)
                    )}
                  </div>
                  <div className="col px-0 mr-1">
                    <label htmlFor="" className="f8-vw m-0 p-0 fw-600">
                      Nomor Kamar
                    </label>
                    {searchInput(
                      "Cari Nomor Kamar",
                      roomNumberFilter,
                      (e) => setRoomNumberFilter(e.target.value),
                      (e) => onKeyPress(e)
                    )}
                  </div>
                </div>
              </div>
              <div className="col-md-2 pr-0 pl-5 mt-auto">
                <div className="d-flex flex-column">
                  <button
                    className="btn btn-sm search-new rounded-input btn-block mt-auto f8-vw fw-500 text-white"
                    onClick={() => filterClicked()}
                    style={{
                      paddingTop: 7,
                      paddingBottom: 7,
                      backgroundColor: "#742B9E",
                    }}
                  >
                    Search
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="col px-0 mt-3">
            <div
              className="table-list table-responsive-sm scroll-table table-report"
              style={{
                height: "24rem",
              }}
            >
              <table className="table table-sm">
                <thead
                  className="f8-vw"
                  style={{ position: "sticky", top: 0, zIndex: 5 }}
                >
                  <tr
                    className="text-center text-white"
                    style={{ backgroundColor: "#0071A4" }}
                  >
                    <th className="py-2 align-middle">Booking ID</th>
                    <th className="py-2 align-middle">
                      <div>Room</div>
                      <div>Number</div>
                    </th>
                    <th className="py-2 align-middle">Lt</th>
                    <th className="py-2 align-middle">Room Type</th>
                    <th className="py-2 align-middle">Bed Type</th>
                    <th className="py-2 align-middle">Nama Tamu</th>
                    <th className="py-2 align-middle">Phone Number</th>
                    <th className="py-2 align-middle">Check Out</th>
                    <th className="py-2 align-middle">Action</th>
                  </tr>
                </thead>
                <tbody className="f8-vw text-center">
                  {booking?.length > 0 && !loading ? (
                    <>
                      {booking?.map((item, index) => (
                        <tr key={index} className={`${tableCol(index)}`}>
                          <td className="text-left align-middle font-weight-bold">
                            {item?.book_id}
                          </td>
                          <td className="align-middle font-weight-bold">
                            {item?.room_number}
                          </td>
                          <td className="align-middle px-3">{item?.floor}</td>
                          <td className="align-middle">
                            {item?.room_type_name}
                          </td>
                          <td className="align-middle">
                            <div className="d-flex flex-column">
                              <div className="font-weight-bold">
                                {item?.bed_type_name}
                              </div>
                              <div className="font-weight-normal">
                                {item?.bed_size}
                              </div>
                            </div>
                          </td>
                          <td className="align-middle text-left">
                            {showMoreGuest(splitText(item?.guest_name))}
                          </td>
                          <td className="align-middle">
                            {!!item?.phone ? parsePhone(item?.phone) : "-"}
                          </td>
                          <td className="align-middle">
                            <div className="d-flex flex-column">
                              <div className="font-weight-normal">
                                {!!item?.checkout_date
                                  ? getServerTimeWithoutDays(
                                      item?.checkout_date,
                                      timezone
                                    )
                                  : "-"}
                              </div>
                            </div>
                          </td>
                          <td
                            className="align-middle"
                            style={{ backgroundColor: "white" }}
                          >
                            {isValidAccess([
                              "ch_process_check_out_breakage",
                              "ch_process_check_out_change_room",
                              "ch_process_check_out_process",
                              "ch_process_check_out_extend",
                            ]) && (
                              <div className="combo-box">
                                <div className="dropdown">
                                  <button
                                    className="bg-transparent dropdown-toggle border-0 blue btn-block f8-vw fw-700"
                                    type="button"
                                    id="dropdownMenu2"
                                    data-toggle="dropdown"
                                    aria-expanded="false"
                                  >
                                    Action
                                  </button>
                                  <div
                                    className="dropdown-menu dropdown-menu-right"
                                    aria-labelledby="dropdownMenu2"
                                    style={{ zIndex: 4 }}
                                  >
                                    <div className="d-flex flex-column">
                                      {is_sub_menu_action(
                                        "ch_process_check_out_process"
                                      ) && (
                                        <button
                                          className="menu border-0 dark-blue bg-transparent f8-vw font-weight-bold "
                                          onClick={() => {
                                            checkoutClicked(item);
                                          }}
                                        >
                                          Check Out
                                        </button>
                                      )}
                                      {is_sub_menu_action(
                                        "ch_process_check_out_breakage"
                                      ) && (
                                        <button
                                          className="menu border-0 dark-blue bg-transparent f8-vw font-weight-bold "
                                          onClick={() => {
                                            breakageClicked(item);
                                          }}
                                        >
                                          Breakage
                                        </button>
                                      )}
                                      {is_sub_menu_action(
                                        "ch_process_check_out_change_room"
                                      ) &&
                                        !checkInvalidTime(
                                          convertISOtoString(item.checkout_date)
                                        ) && (
                                          <button
                                            className="menu border-0 dark-blue bg-transparent f8-vw font-weight-bold "
                                            onClick={() => {
                                              changeRoomClicked(item);
                                            }}
                                          >
                                            Change Room
                                          </button>
                                        )}
                                      {is_sub_menu_action(
                                        "ch_process_check_out_extend"
                                      ) && (
                                        <button
                                          className="menu border-0 dark-blue bg-transparent f8-vw font-weight-bold "
                                          onClick={() => {
                                            extendClicked(item);
                                          }}
                                        >
                                          Extends
                                        </button>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}
                          </td>
                        </tr>
                      ))}
                    </>
                  ) : (
                    <tr>
                      <td colSpan={10} style={{ padding: 0 }}>
                        <EmptyData
                          loading={loading}
                          errMessage={errMessage}
                          text="Data tidak ditemukan"
                          sourceImg={"/img/booking/checkout-process.svg"}
                          height="17rem"
                        />
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
