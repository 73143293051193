import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { BreadcrumbContext } from "../../common/BreadcrumbContext";
import {
  DateRangeFilterMobile,
  DateRangeFilterNew,
} from "../../common/DateRange";
import { Loading } from "../../common/Loading";
import SelectOpt from "../../common/SelectOpt";
import {
  addDays,
  formatDate,
  generateBookingCode,
  getServerTimeWithoutDays,
  isEmpty,
  showMoreGuest,
} from "../../utils";
import {
  getCsvFile,
  getData,
  getErr,
  safeErrCode,
} from "../../utils/api-request";
import { comboBoxDownloadReport } from "../../utils/helper";
import ReportTable from "../Data/Table/ReportTable";
import { EmptyData, MobileEmptyData } from "../Empty/EmptyData";
import { mobileSearchInput, searchInput } from "../booking/bookingHelper";
import { is_sub_menu_action } from "../../utils/uac";
import { IS_MOBILE } from "../../common/Responsive";
import { MobileCardList } from "../../common/CardList";
import { BottomSheet } from "react-spring-bottom-sheet";
import { Icon } from "@iconify/react";
import { iconUrl } from "../../config";
import { t } from "../../common/ErrorMessage";

const GuestCheckin = () => {
  const mounted = useRef(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const link = useRef(null);
  const [bookingFilter, setbookingFilter] = useState({
    page: 1,
    roomId: "",
    start: addDays(new Date(), -7),
    end: new Date(),
    bookingId: "",
    orderName: "",
    room_number: "",
  });
  const [hasMore, sethasMore] = useState(false);
  const [reportData, setreportData] = useState([]);
  const [channelList, setchannelList] = useState([]);
  const [channelSelected, setchannelSelected] = useState([]);
  const {
    setpathLocation,
    roomType,
    openButtonSheet,
    setOpenButtonSheet,
    setShowFilter,
  } = useContext(BreadcrumbContext);

  const handleStart = (value) => {
    setbookingFilter({
      ...bookingFilter,
      start: value,
      end: addDays(value, 7),
    });
  };
  const handleEnd = (value) => {
    setbookingFilter({ ...bookingFilter, end: value });
  };
  const handleRoomType = (value) => {
    if (!value) {
      setbookingFilter({ ...bookingFilter, roomId: "" });
    } else {
      setbookingFilter({ ...bookingFilter, roomId: value.value });
    }
  };
  const handleOrderName = (e) => {
    setbookingFilter({ ...bookingFilter, orderName: e.target.value });
  };

  const handleRoomNumber = (val) => {
    setbookingFilter({ ...bookingFilter, room_number: val.target.value });
  };

  const handleBookingId = (val) => {
    setbookingFilter({
      ...bookingFilter,
      bookingId: generateBookingCode(val.target.value),
    });
  };

  // next page for report data
  const checkNextPage = () => {
    if (hasMore) {
      let page = bookingFilter.page;
      let pagi = page + 1;
      setbookingFilter({ ...bookingFilter, page: pagi });
      getBookingData(pagi, false, bookingFilter.start, bookingFilter.end);
    }
  };

  const getChannel = useCallback(() => {
    let channel = [
      { value: "HMS", label: "HMS" },
      { value: "RNA", label: "RNA" },
    ];
    getData("/api/v1/listotherotaname")
      .then((resp) => {
        if (mounted.current) {
          if (resp.data) {
            resp.data.map((item) => {
              return channel.push({
                value: item.ota_id,
                label: item.ota_name,
              });
            });

            setchannelList(channel);
          } else {
            resp.json().then((err) => {
              setError(t(safeErrCode(err)));
            });
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getBookingData = (pagi, is_init, start, end) => {
    let datas = [];
    setLoading(true);
    const params = {
      ...(!!start && { start_date: formatDate(start) }),
      ...(!!end && { end_date: formatDate(end) }),
      ...(!!bookingFilter?.room_number && {
        room_number: bookingFilter?.room_number,
      }),
      ...(!!bookingFilter?.bookingId && {
        booking_id: bookingFilter?.bookingId,
      }),
      ...(!!channelSelected.label && { channel: channelSelected.label }),
      ...(!!bookingFilter.orderName && { nama_tamu: bookingFilter.orderName }),
      ...(!!bookingFilter.roomId && { room_type_id: bookingFilter.roomId }),
      ...{ page: pagi },
    };
    let paramsFormatted = null;
    if (!isEmpty(params)) {
      paramsFormatted = new URLSearchParams(params);
    }
    let url = `/api/v1/report/guest_activity?page=${pagi}&${
      paramsFormatted !== null ? `&${paramsFormatted}` : ""
    }`;
    getData(url)
      .then((resp) => {
        if (mounted.current) {
          setLoading(false);
          if (resp.data) {
            setLoading(false);
            if (is_init) {
              datas = resp.data.data;
            } else {
              let x = [];
              x = reportData;
              datas = [...x, ...resp.data.data];
            }
            sethasMore(resp.data.next_page);
            setreportData(datas);
          } else {
            resp.json().then((err) => {
              setError(t(safeErrCode(err)));
            });
          }
        }
      })
      .catch((error) => {
        let err = getErr(error);
        setLoading(false);
        setError(t(err));
        console.log(err);
      });
  };

  const handleDownloadCSV = (e) => {
    if (
      link.current.href &&
      link.current.href.substr(link.current.href.length - 6) !== "no_use"
    ) {
      return;
    }
    if (link.current.href.substr(link.current.href.length - 6) === "no_use") {
      e.preventDefault();
    }
    getCsvFile(
      `/api/v1/report/guest_activity?start_date=${formatDate(
        bookingFilter.start
      )}&end_date=${formatDate(bookingFilter.end)}&room_type_id=${
        bookingFilter.roomId
      }&channel=${channelSelected.label}&nama_tamu=${
        bookingFilter.orderName
      }&room_number=${bookingFilter?.room_number}&csvoutput=1`
    )
      .then((resp) => {
        let now = new Date();
        let filenameServ = "guest-activity-report-" + formatDate(now) + ".csv";
        if (
          resp.headers !== undefined &&
          resp.headers !== null &&
          resp.headers.length > 1
        ) {
          filenameServ = resp.headers
            .get("Content-Disposition")
            .split("filename=")[1];
        }
        let filename = filenameServ;
        // console.log("filename-serv-download", filenameServ);
        resp.blob().then((blob) => {
          let dx = new Blob([blob]);
          const href = window.URL.createObjectURL(dx);
          link.current.download = filename;
          link.current.href = href;
          link.current.click();
          link.current.download = "no_use";
          link.current.href = "no_use";
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleEnter = (e) => {
    if (e.key === "Enter") {
      setreportData([]);
      setbookingFilter({ ...bookingFilter, page: 1 });
      getBookingData(1, true, bookingFilter.start, bookingFilter.end);
    }
  };

  const handleChannel = (value) => {
    if (!value) {
      setchannelSelected([]);
    } else {
      setchannelSelected(value);
    }
  };

  const resetFilter = () => {
    setbookingFilter({
      page: 1,
      roomId: "",
      start: addDays(new Date(), -7),
      end: new Date(),
      bookingId: "",
      orderName: "",
      room_number: "",
    });
    setchannelSelected([]);
  };

  useEffect(() => {
    if (
      bookingFilter.roomId === "" &&
      channelSelected.label === undefined &&
      bookingFilter.orderName === "" &&
      bookingFilter?.room_number === "" &&
      bookingFilter.bookingId === ""
    ) {
      getBookingData(1, true, bookingFilter.start, bookingFilter.end);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    bookingFilter.orderName,
    bookingFilter.roomId,
    bookingFilter?.room_number,
    channelSelected.label,
    bookingFilter.bookingId,
  ]);

  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);

  useEffect(() => {
    setpathLocation("/report/guest-activity");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getChannel();
  }, [getChannel]);

  useEffect(() => {
    setShowFilter(true);
    return () => {
      setShowFilter(false);
    };
  }, [setShowFilter]);
  return (
    <>
      {IS_MOBILE ? (
        <div className="d-flex flex-column px-3 py-1 mb-5">
          <div className="col px-1">
            {is_sub_menu_action("rp_guest_activity_export") && (
              <div className="">
                <div className="d-flex">
                  {comboBoxDownloadReport(link, handleDownloadCSV)}
                </div>
              </div>
            )}
          </div>
          <div className="col px-1">
            <InfiniteScroll
              dataLength={reportData.length}
              next={checkNextPage}
              hasMore={hasMore}
              loader={<Loading />}
              endMessage={
                reportData?.length < 1 && (
                  <div style={{ position: "relative" }}>
                    <MobileEmptyData
                      errMessage={error}
                      loading={loading}
                      height="40vh"
                    />
                  </div>
                )
              }
            >
              {reportData.map((item, i) => {
                const timezone = localStorage?.getItem("timezone");
                return (
                  <MobileCardList
                    title={
                      <>
                        <div
                          className="f10-vw fw-500"
                          style={{ color: "#959595" }}
                        >
                          Kode Booking
                        </div>
                        <div>{item?.book_id}</div>
                      </>
                    }
                    fontSizeHeader="f10-vw"
                    key={i}
                  >
                    <div className="card-hms-list">
                      <div className="col-4 px-0 grey-text">
                        Tanggal Booking
                      </div>
                      <div className="col pr-0 f12-vw black-value">
                        {getServerTimeWithoutDays(item?.created_at, timezone)}
                      </div>
                    </div>
                    <div className="card-hms-list">
                      <div className="col-4 px-0 grey-text">Tipe Kamar</div>
                      <div className="col pr-0 f12-vw black-value">
                        {item?.room_type_name}
                      </div>
                    </div>
                    <div className="card-hms-list">
                      <div className="col-4 px-0 grey-text">Nomor Kamar</div>
                      <div className="col pr-0 f12-vw black-value">
                        {item?.room_number}
                      </div>
                    </div>
                    <div className="card-hms-list">
                      <div className="col-4 px-0 grey-text">Nama Tamu</div>
                      <div className="col pr-0 f12-vw black-value">
                        {showMoreGuest(item?.guest_name, 25)}
                      </div>
                    </div>
                    <div className="card-hms-list">
                      <div className="col-4 px-0 grey-text">
                        Channel Booking
                      </div>
                      <div className="col pr-0 f12-vw black-value">
                        {item?.channel_booking}
                      </div>
                    </div>
                    <div className="card-hms-list">
                      <div className="col-4 px-0 grey-text">Status</div>
                      <div className="col pr-0 f12-vw black-value">
                        {item?.guest_status}
                      </div>
                    </div>
                    <div className="card-hms-list">
                      <div className="col-4 px-0 purple fw-700">Booking</div>
                    </div>
                    <div className="card-hms-list">
                      <div className="col-4 px-0 grey-text">Check In</div>
                      <div className="col pr-0 f12-vw black-value">
                        {getServerTimeWithoutDays(item?.checkin_date, timezone)}
                      </div>
                    </div>
                    <div className="card-hms-list">
                      <div className="col-4 px-0 grey-text">Check Out</div>
                      <div className="col pr-0 f12-vw black-value">
                        {getServerTimeWithoutDays(
                          item?.checkout_date,
                          timezone
                        )}
                      </div>
                    </div>
                    <div className="card-hms-list">
                      <div className="col-4 px-0 purple fw-700">Actual</div>
                    </div>
                    <div className="card-hms-list">
                      <div className="col-4 px-0 grey-text">Check In</div>
                      <div className="col pr-0 f12-vw black-value">
                        {getServerTimeWithoutDays(
                          item?.actual_checkin_date,
                          timezone
                        )}
                      </div>
                    </div>
                    <div className="card-hms-list">
                      <div className="col-4 px-0 grey-text">Check In By</div>
                      <div className="col pr-0 f12-vw black-value">
                        {item?.check_in_by}
                      </div>
                    </div>
                    <div className="card-hms-list">
                      <div className="col-4 px-0 grey-text">Check Out</div>
                      <div className="col pr-0 f12-vw black-value">
                        {item?.actual_checkout_date
                          ? getServerTimeWithoutDays(
                              item?.actual_checkout_date,
                              timezone
                            )
                          : "-"}
                      </div>
                    </div>
                    <div className="card-hms-list">
                      <div className="col-4 px-0 grey-text">Check Out By</div>
                      <div className="col pr-0 f12-vw black-value">
                        {item?.check_out_by ? item?.check_out_by : "-"}
                      </div>
                    </div>
                  </MobileCardList>
                );
              })}
            </InfiniteScroll>
          </div>
        </div>
      ) : (
        <div className="d-flex flex-column px-4 py-1">
          <div className="col px-2">
            <div className="d-flex flex-row justify-content-between">
              <div className="col px-0">
                <div className="d-flex flex-row">
                  <div className="col-3 px-0 mr-2">
                    <label htmlFor="" className="f8-vw m-0 p-0">
                      Kode Booking
                    </label>
                    {searchInput(
                      "Cari Kode Booking",
                      bookingFilter.bookingId,
                      handleBookingId,
                      handleEnter
                    )}
                  </div>
                  <div className="col px-0 mr-2">
                    <label htmlFor="" className="f8-vw m-0 p-0">
                      Tanggal Check In
                    </label>
                    <DateRangeFilterNew
                      start={bookingFilter.start}
                      end={bookingFilter.end}
                      handleStart={handleStart}
                      handleEnd={handleEnd}
                      minDate="ada"
                    />
                  </div>
                  <div className="col px-0 mr-2">
                    <label htmlFor="" className="f8-vw m-0 p-0">
                      Tipe Kamar
                    </label>
                    <SelectOpt
                      optionsValue={roomType}
                      onChange={handleRoomType}
                      placeholder={"Tipe Kamar"}
                      clear={true}
                    />
                  </div>
                  <div className="col px-0 mr-2">
                    <label htmlFor="" className="f8-vw m-0 p-0">
                      Nomor Kamar
                    </label>
                    {searchInput(
                      "Nomor Kamar",
                      bookingFilter.room_number,
                      handleRoomNumber,
                      handleEnter
                    )}
                  </div>
                  <div className="col px-0">
                    <label htmlFor="" className="f8-vw m-0 p-0">
                      Nama Tamu
                    </label>
                    {searchInput(
                      "Cari Tamu",
                      bookingFilter.orderName,
                      handleOrderName,
                      handleEnter
                    )}
                  </div>
                </div>
                <div className="d-flex flex-row mt-2">
                  <div className="col-3 px-0">
                    <label htmlFor="" className="f8-vw m-0 p-0">
                      Channel OTA
                    </label>
                    <SelectOpt
                      optionsValue={channelList}
                      onChange={handleChannel}
                      placeholder={"Channel OTA"}
                      clear={true}
                      menuHeight="12rem"
                    />
                  </div>
                  <div className="col-md-2 pr-0 pl-5 mt-auto ml-auto">
                    <div className="d-flex flex-column">
                      <button
                        className="btn btn-sm search-new rounded-input btn-block mt-auto f8-vw fw-500 text-white"
                        onClick={() => {
                          setreportData([]);
                          setbookingFilter({ ...bookingFilter, page: 1 });
                          getBookingData(
                            1,
                            true,
                            bookingFilter.start,
                            bookingFilter.end
                          );
                        }}
                        style={{
                          paddingTop: 7,
                          paddingBottom: 7,
                          backgroundColor: "#742B9E",
                        }}
                      >
                        Search
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col px-0">
            {is_sub_menu_action("rp_guest_activity_export") && (
              <div className="mt-2">
                <div className="d-flex">
                  {comboBoxDownloadReport(link, handleDownloadCSV)}
                </div>
              </div>
            )}
          </div>
          <div className="col px-2 mt-0">
            <InfiniteScroll
              dataLength={reportData.length}
              next={checkNextPage}
              hasMore={hasMore}
              loader={<Loading />}
              endMessage={
                reportData.length < 1 && (
                  <EmptyData
                    loading={loading}
                    errMessage={error}
                    height="17rem"
                  />
                )
              }
              height={"50vh"}
              className="table-scroll px-0 mt-2 table-border-line"
            >
              <ReportTable reportData={reportData} />
            </InfiniteScroll>
          </div>
        </div>
      )}

      {IS_MOBILE && (
        <div>
          <BottomSheet
            open={openButtonSheet}
            onDismiss={() => setOpenButtonSheet(false)}
            snapPoints={({ maxHeight }) => maxHeight}
            header={
              <div className="d-flex justify-content-between">
                <span className="purple f16-vw fw-600 px-2">Filter</span>
                <span
                  className="px-2"
                  onClick={() => setOpenButtonSheet(false)}
                >
                  <Icon icon={iconUrl.closeBold} color={"grey"} width={20} />
                </span>
              </div>
            }
            footer={
              <div className="d-flex justify-content-between">
                <div className="col-6 pl-2">
                  <button
                    className="btn btn-block btn-outline-purple f12-vw"
                    onClick={() => {
                      resetFilter();
                      setOpenButtonSheet(false);
                      getBookingData(
                        1,
                        true,
                        addDays(new Date(), -7),
                        new Date()
                      );
                    }}
                  >
                    Reset
                  </button>
                </div>
                <div className="col-6 pr-2">
                  <button
                    className="btn btn-block btn-gradient f12-vw text-white"
                    onClick={() => {
                      setOpenButtonSheet(false);
                      setreportData([]);
                      setbookingFilter({ ...bookingFilter, page: 1 });
                      getBookingData(
                        1,
                        true,
                        bookingFilter.start,
                        bookingFilter.end
                      );
                    }}
                  >
                    Search
                  </button>
                </div>
              </div>
            }
          >
            <div className="mt-3" style={{ height: "33rem" }}>
              <div className="px-4 mt-2">
                <label htmlFor="" className="f12-vw m-0 p-0">
                  Kode Booking
                </label>
                {mobileSearchInput(
                  "Cari Kode Booking",
                  bookingFilter.bookingId,
                  handleBookingId
                )}
              </div>
              <div className="px-4 mt-3 date-wrapper">
                <label htmlFor="" className="f12-vw m-0 p-0">
                  Tanggal Check In
                </label>
                <DateRangeFilterMobile
                  start={bookingFilter.start}
                  end={bookingFilter.end}
                  handleStart={handleStart}
                  handleEnd={handleEnd}
                  minDate={true}
                  fontSize="f12-vw"
                />
              </div>
              <div className="px-4 mt-3">
                <label htmlFor="" className="f12-vw m-0 p-0">
                  Room Number
                </label>
                {mobileSearchInput(
                  "Cari Nomor Kamar",
                  bookingFilter.room_number,
                  handleRoomNumber
                )}
              </div>
              <div className="px-4 mt-3">
                <label htmlFor="" className="f12-vw m-0 p-0">
                  Nama Tamu
                </label>
                {mobileSearchInput(
                  "Cari Tamu",
                  bookingFilter.orderName,
                  handleOrderName
                )}
              </div>
              <div className="px-4 mt-3">
                <label htmlFor="" className="f12-vw m-0 p-0">
                  Channel
                </label>
                <SelectOpt
                  optionsValue={channelList}
                  onChange={handleChannel}
                  value={channelSelected}
                  placeholder={"Channel"}
                  clear={true}
                  fontSize="calc(8pt + 0.472vw)"
                  menuHeight="10rem"
                />
              </div>
            </div>
          </BottomSheet>
        </div>
      )}
    </>
  );
};
export default GuestCheckin;
