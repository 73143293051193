import React from "react";
import {
  getServerTimeWithoutDays,
  showMoreGuest,
  tableCol,
} from "../../../utils";
import { isValidAccess, is_sub_menu_action } from "../../../utils/uac";
import { EmptyData } from "../../Empty/EmptyData";
import { searchInput } from "../bookingHelper";

export const PendingBookingList = ({
  guestName,
  setGuestName,
  onKeyPress,
  searchFilter,
  list,
  tableHeader,
  loadingList,
  errorList,
  setMode,
  openModal,
  setDetail,
  height = "55vh",
}) => {
  return (
    <div className="d-flex flex-column px-4 py-1">
      <div className="col px-2">
        <div className="d-flex flex-row justify-content-between">
          <div className="col-md-9 px-0">
            <div className="d-flex flex-row">
              <div className="col-3 px-0 mr-2">
                <label htmlFor="" className="f8-vw m-0 p-0">
                  Nama Tamu
                </label>
                {searchInput(
                  "Cari Nama Tamu",
                  guestName,
                  (e) => setGuestName(e.target.value),
                  onKeyPress
                )}
              </div>
            </div>
          </div>
          <div className="col-md-2 pr-0 pl-5 mt-auto">
            <div className="d-flex flex-column">
              <button
                className="btn btn-sm search-new btn-block mt-auto f8-vw fw-500 text-white"
                onClick={() => searchFilter()}
                style={{
                  paddingTop: 7,
                  paddingBottom: 7,
                  backgroundColor: "#742B9E",
                }}
              >
                Search
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="col px-2 mt-2">
        <div
          className="table-list scroll-table table-border-line"
          style={{
            height: height,
          }}
        >
          <table
            className="table table-sm"
            style={{
              borderCollapse: "collapse",
              borderSpacing: 0,
            }}
          >
            <thead
              className="f8-vw"
              style={{ position: "sticky", top: 0, zIndex: 1 }}
            >
              <tr
                className="text-center text-white"
                style={{ backgroundColor: "#0071A4" }}
              >
                {tableHeader.map((item, i) => {
                  return (
                    <th className={"align-middle py-2"} key={i}>
                      {item}
                    </th>
                  );
                })}
              </tr>
            </thead>
            <tbody className="f8-vw">
              {list?.length > 0 ? (
                list.map((item, index) => {
                  const timezone = localStorage.getItem("timezone");
                  return (
                    <tr
                      className={` ${tableCol(index)}`}
                      style={{
                        zIndex: 0,
                      }}
                      key={index}
                    >
                      <td className="text-left align-middle fw-700">
                        {item?.book_id}
                      </td>
                      <td className=" text-left align-middle">
                        {showMoreGuest(item?.guest_name)}
                      </td>
                      <td className="text-center align-middle">
                        {getServerTimeWithoutDays(item?.checkin_date, timezone)}
                      </td>
                      <td className="text-center align-middle">
                        {getServerTimeWithoutDays(
                          item?.checkout_date,
                          timezone
                        )}
                      </td>
                      <td className="text-center align-middle">
                        {item?.room_type_name}
                      </td>
                      <td className="text-center align-middle">
                        {item?.room_number}
                      </td>
                      <td className="text-center align-middle">
                        {isValidAccess([
                          "rv_room_pb_process",
                          "rv_room_pb_cancel",
                        ]) && (
                          <div className="combo-box">
                            <div className="dropdown">
                              <button
                                className="bg-transparent dropdown-toggle border-0 blue btn-block f8-vw fw-700"
                                type="button"
                                id="dropdownMenu2"
                                data-toggle="dropdown"
                                aria-expanded="false"
                              >
                                Action
                              </button>
                              <div
                                className="dropdown-menu dropdown-menu-right"
                                aria-labelledby="dropdownMenu2"
                              >
                                <div className="d-flex flex-column">
                                  {is_sub_menu_action("rv_room_pb_process") && (
                                    <button
                                      className="menu border-0 dark-blue bg-transparent f8-vw fw-700"
                                      onClick={() => {
                                        setMode("payment-process");
                                        openModal();
                                        setDetail(item);
                                      }}
                                    >
                                      Process
                                    </button>
                                  )}

                                  {is_sub_menu_action("rv_room_pb_cancel") && (
                                    <button
                                      className="menu border-0 dark-blue bg-transparent f8-vw fw-700"
                                      onClick={() => {
                                        setMode("cancel-booking");
                                        openModal();
                                        setDetail(item);
                                      }}
                                    >
                                      Cancel
                                    </button>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      </td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td colSpan={10} className="p-0 border-0">
                    <EmptyData
                      text="Semua booking sudah diproses"
                      loading={loadingList}
                      errMessage={errorList}
                      sourceImg={"/img/booking/checkin-process.svg"}
                      height="18rem"
                    />
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};
