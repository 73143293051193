import React, { useContext } from "react";
import { useLocation } from "react-router-dom";
import Breadcrumb from "./Breadcrumb";
import { BreadcrumbContext } from "./BreadcrumbContext";

export const MobileBreadcrumb = () => {
  const location = useLocation();
  const { pathLocation } = useContext(BreadcrumbContext);
  const getBreadcrumbLocation = (param) => {
    if (param !== undefined) {
      let d = param?.replace("-", " ");
      const textBread = d.replace(/(^\w{1})|(\s+\w{1})/g, (letter) =>
        letter.toUpperCase()
      );
      return textBread;
    }
  };
  const logData = pathLocation?.split("/");
  return (
    <div className="info-status px-4">
      <Breadcrumb
        branchs={
          logData?.length <= 3
            ? [
                {
                  name: getBreadcrumbLocation(logData[1]),
                  path: location.pathname,
                },
                {
                  name: getBreadcrumbLocation(logData[2]),
                  path: location.pathname,
                },
              ]
            : [
                {
                  name: getBreadcrumbLocation(logData[1]),
                  path: location.pathname,
                },
                {
                  name: getBreadcrumbLocation(logData[2]),
                  path: location.pathname,
                },
                {
                  name: getBreadcrumbLocation(logData[3]),
                  path: location.pathname,
                },
              ]
        }
      />
    </div>
  );
};
