import { Icon } from "@iconify/react";
import React from "react";
import { iconUrl } from "../../config";
import { dateFormat, priceSplit, roomLabel, tableCol } from "../../utils";
import { IS_MOBILE } from "../../common/Responsive";

export const PopupRoomPrice = ({ today, list, closeModal }) => {
  const blankRow = [];
  for (let index = 0; index < 5 - list.length; index++) {
    blankRow.push(
      <tr
        className={` ${tableCol(
          (5 - list.length) % 2 === 0 ? index + 1 : index
        )}`}
        style={{
          zIndex: 0,
        }}
        key={index}
      >
        <td className="text-left align-middle"></td>
        <td className="text-right align-middle font-weight-bold"></td>
        <td className="text-right align-middle font-weight-bold"></td>
      </tr>
    );
  }

  return (
    <div
      className="d-flex flex-column py-3"
      style={{ width: IS_MOBILE ? "21rem" : "25rem" }}
    >
      <div className="col">
        <Icon
          icon={iconUrl.receiptMoney20Filled}
          color="orange"
          width={IS_MOBILE ? 24 : 25}
        />
        <span
          className={`blue-title fw-700 ml-1 ${
            IS_MOBILE ? "f14-vw" : "f12-vw"
          }`}
        >
          Harga Kamar hari ini
        </span>
        <span className={`mx-1 fw-500 ${IS_MOBILE ? "f14-vw" : "f12-vw"}`}>
          ({dateFormat(today)})
        </span>
      </div>
      <div className="col mt-2">
        <div
          className="table-list table-responsive scroll-page"
          style={{ maxHeight: "15rem" }}
        >
          <table
            className="table scroll-table table-bordered"
            style={{
              borderCollapse: "separate",
              borderSpacing: 0,
              margin: 0,
            }}
          >
            <thead
              className={IS_MOBILE ? "f14-vw fw-700" : "f9-vw fw-500"}
              style={{ position: "sticky", top: 0, zIndex: 1, fontWeight: 500 }}
            >
              <tr
                className="text-black text-center text-white"
                style={{ backgroundColor: "#0071A4" }}
              >
                <th className={"align-middle"} style={{ padding: 8 }}>
                  Tipe Kamar
                </th>
                <th className={"align-middle"} style={{ padding: 8 }}>
                  Allotment
                </th>
                <th className={"align-middle"} style={{ padding: 8 }}>
                  Harga
                </th>
              </tr>
            </thead>
            <tbody className={IS_MOBILE ? "f12-vw" : "f8-vw"}>
              {list?.length > 0 &&
                list.map((item, index) => {
                  return (
                    <tr
                      className={` ${tableCol(index)}`}
                      style={{
                        zIndex: 0,
                      }}
                      key={index}
                    >
                      <td className="text-left align-middle py-1">
                        {roomLabel(item.room_type)}
                      </td>
                      <td className="text-center align-middle py-1">
                        {item?.allotment}
                      </td>
                      <td className="text-right align-middle py-1 font-weight-bold">
                        {priceSplit(item.room_price)}
                      </td>
                    </tr>
                  );
                })}
              {5 - list.length < 5 && 5 - list.length > 0 && blankRow}
            </tbody>
          </table>
        </div>
      </div>
      <div className="col mt-2">
        <div className="d-flex justify-content-end">
          <div className="col-4 px-0">
            <button
              className={`btn btn-sm btn-block btn-gradient text-white ${
                IS_MOBILE ? "f12-vw" : "f8-vw"
              }`}
              onClick={() => closeModal()}
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
