import { Icon } from "@iconify/react";
import React, { useEffect, useState } from "react";
import { useGeolocated } from "react-geolocated";
import { iconUrl } from "../../config";
import { getData } from "../../utils/api-request";
import { dateCompilation } from "../../utils/mobile";
import { CustomCameraGeoTaging } from "./mobile-view/common/CustomCameraGeotaging";
import Resizer from "react-image-file-resizer";

export const ActionStartPopup = ({
  title,
  onSubmit,
  reset,
  loadingSubmit,
  note,
  setNote,
  setFileRoom,
  setFileBathroom,
  fileRoom,
  fileBathroom,
  roomActionSelected,
}) => {
  const [showRoom, setShowRoom] = useState("");
  const [showBathRoom, setShowBathRoom] = useState("");
  const [latitude, setLatitude] = useState("");
  const [longitude, setLongitude] = useState("");
  const [infoHotel, setInfoHotel] = useState("");
  const [isOnArea, setIsOnArea] = useState(false);
  const { coords, isGeolocationEnabled } = useGeolocated({
    positionOptions: {
      enableHighAccuracy: true,
    },
    userDecisionTimeout: 5000,
  });

  const validateButtonSubmit = () => {
    if (fileRoom.length > 0 && fileBathroom.length > 0 && !loadingSubmit) {
      return true;
    }
    return false;
  };

  useEffect(() => {
    let info_hotel = localStorage.getItem("info_hotel");
    if (info_hotel?.length > 0) {
      let infoHotelData = JSON.parse(info_hotel);
      setInfoHotel(infoHotelData?.hotel_name);
    }
  }, []);

  useEffect(() => {
    if (isGeolocationEnabled) {
      setLatitude(coords?.latitude);
      setLongitude(coords?.longitude);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [coords, isGeolocationEnabled]);

  useEffect(() => {
    if (isGeolocationEnabled) {
      let url = "/api/v1/is_inside_area?lat=" + latitude + "&lng=" + longitude;
      getData(url)
        .then((resp) => {
          if (resp?.on_area) {
            setIsOnArea(true);
          }
        })
        .catch((errX) => console.log(errX));
    }
  }, [latitude, longitude, isGeolocationEnabled]);

  const resizeFile = (file) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        640,
        480,
        "png",
        50,
        0,
        (uri) => {
          resolve(uri);
        },
        "file"
      );
    });

  const renderImageCanvas = async (elID, file) => {
    const blob = new Blob(
      [file],
      { type: file.type } // If the type is unknown, default is empty string.
    );
    let canvas = document.getElementById(elID);

    const ctx = canvas.getContext("2d");
    const img = new Image();
    let img_file = null;
    let dateNow = new Date().getTime();
    let filenamed = "file-" + dateNow + ".png";
    img.onload = (event) => {
      // canvas.width = document.getElementById("step-ctrl").clientWidth;
      // canvas.height = document.documentElement.clientHeight;
      canvas.width = document.getElementById(
        "text-input-start-cleaning"
      ).clientWidth;
      canvas.height = window.innerHeight;
      // canvas.height = document.documentElement.clientHeight - 430;
      canvas.height = 180;

      let infoHotelName =
        isOnArea && infoHotel?.length > 0 ? infoHotel : "Unknown Location";
      ctx.font = "10pt Open Sans";

      var textWidth = ctx.measureText(infoHotelName).width;

      ctx.drawImage(
        img,
        0,
        0,
        img.width,
        img.height, // source rectangle
        0,
        0,
        canvas.width,
        canvas.height
      ); // destination rectangle

      ctx.fillStyle = "rgba(255,255,255,0.5)";
      ctx.fillRect(0, 5, textWidth + 80, 50);

      let dateNow = new Date();
      let formatDate = dateCompilation(dateNow.toISOString().toString());
      ctx.fillStyle = "black";
      ctx.fillText(formatDate, 25, 25);
      ctx.fillText(infoHotelName, 25, 45);

      let imgSrc = canvas.toDataURL("image/png");

      canvas.toBlob((blobFile) => {
        img_file = new File([blobFile], filenamed, {
          lastModified: new Date().getTime(),
          type: blobFile.type,
        });
        let filex = {
          img_file: [img_file],
          img_src: URL.createObjectURL(blob),
        };
        switch (elID) {
          case "fotoKamar":
            setFileRoom(filex?.img_file);
            setShowRoom(imgSrc);
            break;
          case "fotoKamarMandi":
            setFileBathroom(filex?.img_file);
            setShowBathRoom(imgSrc);
            break;
          default:
            break;
        }
      });
      URL.revokeObjectURL(event.target.src); // 👈 This is important. If you are not using the blob, you should release it if you don't want to reuse it. It's good for memory.
    };
    img.src = URL.createObjectURL(blob);
  };

  const handleFile = async (val) => {
    let file = val.target.files;
    let file_resize = await resizeFile(file[0]);
    renderImageCanvas("fotoKamar", file_resize);
  };

  const handleFileToilet = async (val) => {
    let file = val.target.files;
    let file_resize = await resizeFile(file[0]);
    renderImageCanvas("fotoKamarMandi", file_resize);
  };

  return (
    <div className="d-flex flex-column p-3" style={{ width: "21rem" }}>
      <div className="dark-blue f12-vw px-3">
        <div className="align-middle">
          <span className="mr-1">
            <Icon
              icon={iconUrl.questionMarkCircle20Solid}
              color="orange"
              width={"2rem"}
            />
          </span>
          <span className="f12-vw">Yakin mulai bersihkan </span>
          <span className="font-weight-bold f12-vw">{title}</span>
        </div>
      </div>
      <div
        className="scroll-popup hide-scrollbar"
        style={{ maxHeight: "25rem" }}
      >
        <div
          className="d-flex px-3 py-1 mt-1"
          style={{
            borderRadius: "5px",
          }}
        >
          <div className="col px-0">
            <label htmlFor="upload room" className="f12-vw fw-700">
              Catatan
            </label>
            <textarea
              name="note"
              id={"text-input-start-cleaning"}
              rows="5"
              className="form-control f12-vw form-control-sm rounded-input"
              style={{
                borderColor: "lightgray",
                resize: "none",
                backgroundColor: "white",
              }}
              onChange={(e) => setNote(e.target.value)}
              value={note}
              placeholder="Write a note..."
            />
          </div>
        </div>

        <div
          className="px-3 py-1"
          style={{
            borderRadius: "5px",
          }}
        >
          <div className="d-flex flex-column">
            <div className="col px-0 my-1">
              <label htmlFor="upload room" className="f12-vw fw-700">
                Foto Kamar
              </label>
              <CustomCameraGeoTaging
                fileUpload={showRoom}
                onChange={handleFile}
                placeholder={"Take Photo"}
                iconUpload={true}
                canvas_component={
                  <>
                    <canvas
                      id={"fotoKamar"}
                      style={{
                        borderRadius: 3,
                        display: "none",
                      }}
                      height={"5px"}
                    />
                    <img
                      src={showRoom}
                      alt=""
                      style={{
                        objectFit: "contain",
                        width: "100%",
                        height: "12rem",
                        display: showRoom ? "block" : "none",
                      }}
                      loading="lazy"
                    />
                  </>
                }
                height={"12rem"}
              />
            </div>
            <div className="col px-0 my-1">
              <label htmlFor="upload room" className="f12-vw fw-700">
                Foto Kamar Mandi
              </label>
              <CustomCameraGeoTaging
                fileUpload={showBathRoom}
                onChange={handleFileToilet}
                placeholder={"Take Photo"}
                iconUpload={true}
                canvas_component={
                  <>
                    <canvas
                      id={"fotoKamarMandi"}
                      style={{
                        borderRadius: 3,
                        display: "none",
                      }}
                      height={"5px"}
                    />
                    <img
                      src={showBathRoom}
                      alt=""
                      style={{
                        objectFit: "contain",
                        width: "100%",
                        height: "12rem",
                        display: showBathRoom ? "block" : "none",
                      }}
                      loading="lazy"
                    />
                  </>
                }
                height={"12rem"}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="px-3">
        <hr className="separator-blue" />
        <div className="text-center">
          <small className="f10-vw">Apakah anda yakin ingin melanjutkan?</small>
        </div>
      </div>
      <div className="mt-3 d-flex flex-row justify-content-between">
        <div className="col-6">
          <button
            className={`btn btn-block f12-vw font-weight-bold text-black btn-outline-purple`}
            onClick={reset}
          >
            Back
          </button>
        </div>
        <div className="col-6">
          <button
            className={`btn btn-block f12-vw font-weight-bold text-white ${
              validateButtonSubmit() ? "btn-gradient" : "btn-secondary"
            }`}
            onClick={() => onSubmit("startclean", roomActionSelected.id)}
            disabled={validateButtonSubmit() ? false : true}
          >
            {loadingSubmit && (
              <span className="spinner-border spinner-border-sm mr-1"></span>
            )}
            Next
          </button>
        </div>
      </div>
    </div>
  );
};
