import { Icon } from "@iconify/react";
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { BreadcrumbContext } from "../../common/BreadcrumbContext";
import NotifMessage, { ButtonClose, ButtonConfirm } from "../../common/Notif";
import PopupModal from "../../common/PopupModal";
import SelectOpt from "../../common/SelectOpt";
import {
  filterStatusRoomCustom,
  getClientTimeWithoutSecond,
  getServerTimeWithoutDays,
  getTimezoneOffsetClient,
  isEmpty,
  statusRoomCustom,
  tableCol,
} from "../../utils";
import { addData, getData, getErr, safeErrCode } from "../../utils/api-request";
import history from "../../utils/history";
import { EmptyData, MobileEmptyData } from "../Empty/EmptyData";
import { CustomOptionLabel } from "./common";
import { filterHousekeepingStatus } from "./common/utils";
import { mobileSearchInput, searchInput } from "../booking/bookingHelper";
import { is_sub_menu_action } from "../../utils/uac";
import { iconUrl } from "../../config";
import { IS_MOBILE } from "../../common/Responsive";
import { MobileCardList } from "../../common/CardList";
import { BottomSheet } from "react-spring-bottom-sheet";
import { t } from "../../common/ErrorMessage";

const FONT_SIZE = IS_MOBILE ? "f12-vw" : "f8-vw";

const TableRoomChangeStatus = ({
  list,
  loading,
  errorMessage,
  height,
  setRoomInspectionSelected,
  isOpen,
  setMode,
}) => {
  const timezone =
    localStorage?.getItem("timezone") && localStorage?.getItem("timezone");
  return (
    <>
      <div
        className="table-list table-responsive-sm scroll-table"
        style={{
          height: height ? height : "300px",
          padding: 0,
        }}
      >
        <table
          className="table table-sm"
          style={{ borderCollapse: "separate", borderSpacing: 0 }}
        >
          <thead className="f8-vw sticky-table">
            <tr
              className="text-center text-white "
              style={{
                backgroundColor: "#0071A4",
              }}
            >
              <th rowSpan={2} className="align-middle">
                <div>Nomor</div>
                <div>Kamar</div>
              </th>
              <th rowSpan={2} className="align-middle">
                Lt
              </th>
              <th rowSpan={2} className="align-middle">
                Room Type
              </th>
              <th rowSpan={2} className="align-middle">
                Bed Type
              </th>
              <th
                colSpan={2}
                rowSpan={1}
                className="align-middle"
                style={{ borderBottomWidth: "1px" }}
              >
                Status
              </th>
              <th rowSpan={2} className="align-middle">
                <div>Last</div>
                <div>Update</div>
              </th>
              <th
                rowSpan={2}
                className="align-middle"
                style={{
                  width: "3rem",
                }}
              >
                Action
              </th>
            </tr>
            <tr
              className="text-center text-white"
              style={{
                backgroundColor: "#0071A4",
              }}
            >
              <th className="align-middle">Room</th>
              <th className="align-middle">Housekeeping</th>
            </tr>
          </thead>

          <tbody className="f8-vw">
            {list?.length > 0 && !loading ? (
              <>
                {list?.map((item, index) => (
                  <tr key={index} className={tableCol(index)}>
                    <td className="text-center align-middle fw-700">
                      {item?.room_number}
                    </td>
                    <td className="text-center align-middle">{item?.floor}</td>
                    <td className="text-center align-middle">
                      {item?.room_type_name}
                    </td>
                    <td className="text-center align-middle py-0">
                      <div className="fw-700">{item?.bed_type_name}</div>
                      <div className="">{item?.bed_size}</div>
                    </td>
                    <td className="text-center align-middle">
                      <div
                        className={`mx-auto ${
                          filterStatusRoomCustom(item?.status)?.className
                        }`}
                        style={{
                          borderRadius: 3,
                          width: "3rem",
                          fontWeight: "bold",
                          paddingTop: 2,
                          paddingBottom: 2,
                        }}
                      >
                        {filterStatusRoomCustom(item?.status)?.label}
                      </div>
                    </td>
                    <td className={"text-center align-middle "}>
                      <div
                        className={`mx-auto ${
                          filterHousekeepingStatus(item?.hk_status)?.className
                        }`}
                        style={{
                          borderRadius: 3,
                          width: "3rem",
                          fontWeight: "bold",
                          paddingTop: 2,
                          paddingBottom: 2,
                        }}
                      >
                        {filterHousekeepingStatus(item?.hk_status)?.label}
                      </div>
                    </td>
                    <td className="text-center align-middle py-0">
                      {item?.last_update?.length > 0 ? (
                        <>
                          <div className="text-center">
                            {getServerTimeWithoutDays(
                              item?.last_update,
                              timezone
                            )}
                          </div>
                          <div className="text-center fw-700">
                            {getClientTimeWithoutSecond(
                              item?.last_update,
                              timezone
                            ) +
                              " " +
                              getTimezoneOffsetClient(timezone)}
                          </div>
                        </>
                      ) : (
                        "-"
                      )}
                    </td>
                    <td className={`text-center align-middle`}>
                      {is_sub_menu_action("hk_room_list_update") && (
                        <>
                          {item?.status === 2 || item?.status === 3 ? null : (
                            <button
                              className="btn btn-sm py-0 f8-vw fw-700 dark-blue"
                              onClick={() => {
                                isOpen();
                                setMode("change-status");
                                setRoomInspectionSelected(item);
                              }}
                            >
                              Ubah Status
                            </button>
                          )}
                        </>
                      )}
                    </td>
                  </tr>
                ))}
              </>
            ) : (
              <>
                <tr>
                  <td className="p-0" colSpan={10}>
                    <EmptyData
                      loading={loading}
                      errMessage={errorMessage}
                      text="Tidak ada Status Kamar yang perlu dirubah"
                      sourceImg={"/img/housekeeping/room-management.svg"}
                      height="36vh"
                    />
                  </td>
                </tr>
              </>
            )}
          </tbody>
        </table>
      </div>
    </>
  );
};

const PopupForm = ({
  title,
  statusSelected,
  setStatusSelected,
  reset,
  setMode,
  setimgPopup,
  roomInspectionSelected,
}) => {
  const roomStatus = [
    { value: 4, title: "Vacant Dirty", label: "VD", className: "status-vd" },
    { value: 6, title: "Out of Service", label: "OS", className: "status-os" },
    { value: 7, title: "Out of Order", label: "OO", className: "status-oo" },
  ];
  const filterStatus = (id) => {
    return roomStatus?.filter((item) => item?.value !== id);
  };
  return (
    <div className="py-3 px-4" style={{ width: "19rem" }}>
      <div
        className={`d-flex align-items-center fw-700 ${
          IS_MOBILE ? "f14-vw" : "f10-vw"
        }`}
      >
        <span className="mr-2">
          <Icon
            icon={iconUrl.informationIcon}
            color="orange"
            width={"1.5rem"}
          />
        </span>
        <span className="dark-blue">{title}</span>
      </div>
      <div className="mt-4">
        <div className="d-flex flex-column">
          <div>
            <label htmlFor="room-status" className={"fw-600 " + FONT_SIZE}>
              Status Kamar
            </label>
            <SelectOpt
              placeholder="Pilih Status Kamar"
              optionsValue={filterStatus(roomInspectionSelected?.status)}
              value={statusSelected}
              fontSize={IS_MOBILE ? "calc(8pt + 0.472vw)" : ""}
              onChange={(val) => setStatusSelected(val)}
              formatOptionLabel={CustomOptionLabel}
            />
          </div>
        </div>
      </div>
      <hr className="separator-blue" />
      <div className="text-center">
        <small className={IS_MOBILE ? "f10-vw" : "f8-vw"}>
          Apakah anda yakin untuk melanjutkan?
        </small>
      </div>

      <div className="mt-3 d-flex justify-content-between">
        <div className="col-5 px-0">
          <button
            className={`btn btn-block ${FONT_SIZE} fw-700 btn-outline-purple`}
            onClick={() => {
              reset();
            }}
          >
            Back
          </button>
        </div>
        <div className="col-5 px-0">
          <button
            className={`btn btn-block ${FONT_SIZE} fw-700 text-white ${
              !isEmpty(statusSelected) ? "btn-gradient" : "btn-secondary"
            }`}
            onClick={() => {
              setMode("change-confirm");
              setimgPopup("/img/housekeeping/confirm-status.svg");
            }}
            disabled={!isEmpty(statusSelected) ? false : true}
          >
            Next
          </button>
        </div>
      </div>
    </div>
  );
};

const PopupConfirm = ({
  image,
  reset,
  withCrossIcon = false,
  color,
  mode,
  setMode,
  room_number,
  confirmStatus,
  loading,
  statusSelected,
}) => {
  return (
    <>
      <div
        className="d-flex flex-column px-3 pt-3 pb-0"
        style={{ width: "19rem" }}
      >
        {!!withCrossIcon && (
          <div className="pointer popup-close-icon" onClick={reset}>
            <Icon
              icon={iconUrl.timesIcon}
              width="20"
              height="20"
              color="#742b9e"
            />
          </div>
        )}

        <div className="d-flex flex-column align-items-center justify-content-center">
          <div className="mb-3">
            <img src={image} alt="" width={100} loading="lazy" />
          </div>
          {mode === "change-confirm" && (
            <>
              <div className={"text-center align-middle " + FONT_SIZE}>
                Yakin ingin mengubah
              </div>
              <div className={"text-center " + FONT_SIZE}>
                Status <span className="fw-700">{room_number}?</span>
              </div>
            </>
          )}
          {mode === "change-success" && (
            <>
              <div className={"text-center " + FONT_SIZE}>
                Status <span className="fw-700">{room_number}</span>
              </div>
              <div className={"text-center " + FONT_SIZE}>berhasil diubah</div>
            </>
          )}
        </div>
      </div>
      {mode === "change-confirm" ? (
        <ButtonConfirm
          text="Confirm"
          secondButton="Back"
          close={() => reset()}
          confirm={() => {
            setMode("change-success");
            confirmStatus(statusSelected?.value);
          }}
          loading={loading}
        />
      ) : (
        <ButtonClose
          close={() => {
            reset();
          }}
          text="OK"
          color={color}
        />
      )}
    </>
  );
};

const RoomChangeStatus = () => {
  const {
    setpathLocation,
    openButtonSheet,
    setOpenButtonSheet,
    setShowFilter,
  } = useContext(BreadcrumbContext);
  const mounted = useRef(false);
  const [roomNumber, setRoomNumber] = useState("");
  const [loadingList, setLoadingList] = useState(false);
  const [errorList, setErrorList] = useState("");
  const [roomList, setRoomList] = useState([]);
  const [roomInspectionSelected, setRoomInspectionSelected] = useState({});
  const [errorSubmit, setErrorSubmit] = useState("");
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [headerPopup, setHeaderPopup] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [mode, setMode] = useState("");
  const [imgPopup, setimgPopup] = useState("");
  const [color, setColor] = useState("");
  const [statusSelected, setStatusSelected] = useState([]);
  const [statusRoomSelected, setStatusRoomSelected] = useState([]);
  const [floorList, setFloorList] = useState([]);
  const [floorSelected, setFloorSelected] = useState([]);
  const [roomTypeSelected, setRoomTypeSelected] = useState([]);
  const roomType =
    localStorage?.getItem("room_type") &&
    JSON.parse(localStorage?.getItem("room_type"));

  const isOpen = () => {
    setOpenModal(!openModal);
  };

  const getRoomChangeStatusList = useCallback(() => {
    setLoadingList(true);
    getData(
      `/api/v1/roomstock?room_number=${roomNumber}&process_type=2&room_type=${roomTypeSelected?.value}&floor=${floorSelected?.value}&room_status=${statusRoomSelected?.value}`
    )
      .then((resp) => {
        setLoadingList(false);
        if (resp.data) {
          setRoomList(resp.data);
        } else {
          resp.json().then((err) => {
            setErrorList(t(safeErrCode(err)));
          });
        }
      })
      .catch((error) => {
        let err = getErr(error);
        setLoadingList(false);
        setErrorList(t(err));
      });
  }, [
    floorSelected?.value,
    roomNumber,
    roomTypeSelected?.value,
    statusRoomSelected?.value,
  ]);

  const getFloorStockList = useCallback(() => {
    getData("/api/v1/roomstock/floorlist")
      .then((resp) => {
        if (mounted.current) {
          if (resp.data) {
            let d = resp.data.map((item) => {
              return {
                value: item,
                label: `Lt. ${item}`,
              };
            });
            setFloorList(d);
          } else {
            resp.json().then((err) => {
              console.error(t(safeErrCode(err)));
            });
          }
        }
      })
      .catch((error) => {
        let err = getErr(error);
        console.error(t(err));
      });
  }, []);

  const handleEnter = (e) => {
    if (e.key === "Enter") {
      getRoomChangeStatusList();
    }
  };
  const submitRoomAction = (status) => {
    const payload = {
      room_number: roomInspectionSelected?.room_number,
      current_status: roomInspectionSelected?.status,
      next_status: status,
    };

    const formData = new FormData();
    for (const key in payload) {
      formData.append(key, payload[key]);
    }

    for (const pair of formData.entries()) {
      console.log(pair[0] + " - " + pair[1]);
    }
    addData("/api/v1/housekeeping/changeroomstatus", formData)
      .then((resp) => {
        setLoadingSubmit(false);
        if (resp.status === "OK") {
          setMode("change-success");
          setimgPopup("/img/housekeeping/approve.svg");
          setColor("bg-green");
        } else {
          resp.json().then((err) => {
            setErrorSubmit(t(safeErrCode(err)));
          });
          setMode("error");
          setHeaderPopup("Gagal ubah status kamar");
          setimgPopup("/img/error-icon/save-bedtype-failed.svg");
        }
      })
      .catch((error) => {
        console.error(error);
        setLoadingSubmit(false);
        setErrorSubmit(t(getErr(error)));
        setMode("error");
        setHeaderPopup("Gagal ubah status kamar");
        setimgPopup("/img/error-icon/save-bedtype-failed.svg");
      });
  };

  const handleRoomNumber = (val) => {
    setRoomNumber(val.target.value);
  };

  const reset = () => {
    setRoomList([]);
    setRoomInspectionSelected({});
    setOpenModal(false);
    setMode("");
    setLoadingList(false);
    setLoadingSubmit(false);
    setErrorList("");
    setErrorSubmit("");
    setHeaderPopup("");
    setStatusSelected([]);
    setColor("");
    getRoomChangeStatusList();
  };

  const handleStatusRoomSelected = (val) => {
    if (!val) {
      setStatusRoomSelected([]);
    } else {
      setStatusRoomSelected(val);
    }
  };
  const handleFloor = (val) => {
    if (!val) {
      setFloorSelected([]);
    } else {
      setFloorSelected(val);
    }
  };
  const handleRoomType = (val) => {
    if (!val) {
      setRoomTypeSelected([]);
    } else {
      setRoomTypeSelected(val);
    }
  };

  const resetFilter = () => {
    setStatusRoomSelected([]);
    setRoomNumber("");
    setFloorSelected([]);
    setRoomTypeSelected([]);
    getRoomChangeStatusList();
  };

  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);

  useEffect(() => {
    if (
      roomNumber === "" &&
      roomTypeSelected?.value === undefined &&
      statusRoomSelected?.value === undefined &&
      floorSelected?.value === undefined
    ) {
      getRoomChangeStatusList();
    }
  }, [
    floorSelected?.value,
    getRoomChangeStatusList,
    roomNumber,
    roomTypeSelected?.value,
    statusRoomSelected?.value,
  ]);

  useEffect(() => {
    setpathLocation(history.location.pathname);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history.location.pathname]);
  useEffect(() => {
    getFloorStockList();
  }, [getFloorStockList]);

  useEffect(() => {
    setShowFilter(true);
    return () => {
      setShowFilter(false);
    };
  }, [setShowFilter]);

  useEffect(() => {
    if (openModal) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
    return () => {
      document.body.style.overflow = "unset";
    };
  }, [openModal]);

  return (
    <>
      {IS_MOBILE ? (
        <div className="d-flex flex-column p-1 mb-5">
          <div className="col">
            {roomList?.length > 0 ? (
              roomList.map((item, i) => {
                const timezone = localStorage.getItem("timezone");
                return (
                  <MobileCardList
                    title={`Kamar ${item.room_number}`}
                    key={item?.room_number}
                    action_button={
                      is_sub_menu_action("hk_room_list_update") && (
                        <div className="col px-0">
                          {item?.status === 2 || item?.status === 3 ? null : (
                            <button
                              className="btn border-0 btn-sm btn-gradient btn-block text-white f12-vw fw-700"
                              onClick={() => {
                                isOpen();
                                setMode("change-status");
                                setRoomInspectionSelected(item);
                              }}
                            >
                              <span className="mr-1">
                                <Icon
                                  icon={
                                    iconUrl.textBulletListSquareEdit24Filled
                                  }
                                  width={18}
                                />
                              </span>
                              <span>Ubah Status</span>
                            </button>
                          )}
                        </div>
                      )
                    }
                  >
                    <div className="card-hms-list">
                      <div className="col-5 px-0 grey-text">Lantai</div>
                      <div className="col pr-0 f12-vw black-value">
                        {item?.floor}
                      </div>
                    </div>
                    <div className="card-hms-list">
                      <div className="col-5 px-0 grey-text">Room Type</div>
                      <div className="col pr-0 f12-vw black-value">
                        {item?.room_type_name}
                      </div>
                    </div>
                    <div className="card-hms-list">
                      <div className="col-5 px-0 grey-text">Bed Type</div>
                      <div className="col pr-0 f12-vw black-value">
                        {item?.bed_type_name}
                      </div>
                    </div>
                    <div className="card-hms-list">
                      <div className="col-5 px-0 grey-text">Bed Size</div>
                      <div className="col pr-0 f12-vw black-value">
                        {item?.bed_size}
                      </div>
                    </div>

                    <div className="card-hms-list">
                      <div className="col-5 px-0 grey-text fw-700">Status</div>
                    </div>
                    <div className="card-hms-list">
                      <div className="col-5 px-0 grey-text">Room Status</div>
                      <div className="col pr-0 f12-vw black-value">
                        <div
                          className={`text-center ${
                            filterStatusRoomCustom(item?.status)?.className
                          }`}
                          style={{
                            borderRadius: 3,
                            width: "3rem",
                            fontWeight: "bold",
                          }}
                        >
                          {filterStatusRoomCustom(item?.status)?.label}
                        </div>
                      </div>
                    </div>
                    <div className="card-hms-list">
                      <div className="col-5 px-0 grey-text">
                        Housekeeping Status
                      </div>
                      <div className="col pr-0 f12-vw black-value">
                        <div
                          className={`text-center ${
                            filterHousekeepingStatus(item?.hk_status)?.className
                          }`}
                          style={{
                            borderRadius: 3,
                            width: "3rem",
                            fontWeight: "bold",
                          }}
                        >
                          {filterHousekeepingStatus(item?.hk_status)?.label}
                        </div>
                      </div>
                    </div>
                    <div className="card-hms-list">
                      <div className="col-5 px-0 grey-text">Last Update</div>
                      <div className="col pr-0 f12-vw black-value">
                        {item?.last_update ? (
                          <>
                            <span>
                              {getServerTimeWithoutDays(
                                item?.last_update,
                                timezone
                              )}
                            </span>
                            <span className="fw-700">
                              {`, ${getClientTimeWithoutSecond(
                                item?.last_update,
                                timezone
                              )}
                                ${getTimezoneOffsetClient(timezone)}`}
                            </span>
                          </>
                        ) : (
                          "-"
                        )}
                      </div>
                    </div>
                  </MobileCardList>
                );
              })
            ) : (
              <div style={{ position: "relative" }}>
                <MobileEmptyData
                  errMessage={errorList}
                  loading={loadingList}
                  height="40vh"
                  text="Tidak ada Status Kamar yang perlu dirubah"
                  sourceImg={"/img/housekeeping/room-management.svg"}
                />
              </div>
            )}
          </div>
        </div>
      ) : (
        <div className="d-flex flex-column px-3 py-1">
          <div className="col">
            <div className="d-flex flex-row justify-content-between">
              <div className="col-md-9 px-0">
                <div className="d-flex flex-row">
                  <div className="col px-0 mr-2">
                    <label htmlFor="" className="f8-vw m-0 p-0 fw-600">
                      Room Status
                    </label>
                    <SelectOpt
                      placeholder="Pilih Status"
                      optionsValue={statusRoomCustom}
                      value={statusRoomSelected}
                      onChange={(val) => handleStatusRoomSelected(val)}
                      formatOptionLabel={CustomOptionLabel}
                      clear={true}
                    />
                  </div>
                  <div className="col px-0 mr-2">
                    <label htmlFor="" className="f8-vw m-0 p-0 fw-600">
                      Tipe Kamar
                    </label>
                    <SelectOpt
                      placeholder="Pilih Room"
                      optionsValue={roomType}
                      value={roomTypeSelected}
                      onChange={(val) => handleRoomType(val)}
                      clear={true}
                    />
                  </div>
                  <div className="col-2 px-0 mr-2">
                    <label htmlFor="" className="f8-vw m-0 p-0 fw-600">
                      Nomor Kamar
                    </label>
                    {searchInput(
                      "Cari Nomor Kamar",
                      roomNumber,
                      (e) => handleRoomNumber(e),
                      handleEnter
                    )}
                  </div>
                  <div className="col-2 px-0 mr-2">
                    <label htmlFor="" className="f8-vw m-0 p-0 fw-600">
                      Lantai
                    </label>
                    <SelectOpt
                      placeholder="Pilih Floor"
                      optionsValue={floorList}
                      value={floorSelected}
                      onChange={(val) => handleFloor(val)}
                      clear={true}
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-2 pr-0 pl-5 mt-auto">
                <div className="d-flex flex-column">
                  <button
                    className="btn btn-sm search-new rounded-input btn-block mt-auto f8-vw fw-500 text-white"
                    onClick={() => getRoomChangeStatusList()}
                    style={{
                      paddingTop: 7,
                      paddingBottom: 7,
                      backgroundColor: "#742B9E",
                    }}
                  >
                    Search
                  </button>
                </div>
              </div>
            </div>
            <div className="d-flex flex-column my-2">
              <TableRoomChangeStatus
                list={roomList}
                loading={loadingList}
                errorMessage={errorList}
                setRoomInspectionSelected={setRoomInspectionSelected}
                isOpen={isOpen}
                setMode={setMode}
                height="52vh"
              />
            </div>
          </div>
        </div>
      )}

      {IS_MOBILE && (
        <BottomSheet
          open={openButtonSheet}
          onDismiss={() => setOpenButtonSheet(false)}
          snapPoints={({ minHeight }) => minHeight}
          header={
            <div className="d-flex justify-content-between">
              <span className="purple f16-vw fw-600 px-2">Filter</span>
              <span className="px-2" onClick={() => setOpenButtonSheet(false)}>
                <Icon icon={iconUrl.closeBold} color={"grey"} width={20} />
              </span>
            </div>
          }
          footer={
            <div className="d-flex justify-content-between">
              <div className="col-6 pl-2">
                <button
                  className="btn btn-block btn-outline-purple f12-vw"
                  onClick={() => {
                    resetFilter();
                    setOpenButtonSheet(false);
                  }}
                >
                  Reset
                </button>
              </div>
              <div className="col-6 pr-2">
                <button
                  className="btn btn-block btn-gradient f12-vw text-white"
                  onClick={() => {
                    setRoomList([]);
                    getRoomChangeStatusList();
                    setOpenButtonSheet(false);
                  }}
                >
                  Search
                </button>
              </div>
            </div>
          }
        >
          <div className="mt-3" style={{ height: "18rem" }}>
            <div className="px-4 mt-3">
              <label htmlFor="" className="f12-vw m-0 p-0 fw-600">
                Room Status
              </label>
              <SelectOpt
                placeholder="Pilih Status"
                optionsValue={statusRoomCustom}
                value={statusRoomSelected}
                onChange={(val) => handleStatusRoomSelected(val)}
                formatOptionLabel={CustomOptionLabel}
                clear={true}
                fontSize="calc(8pt + 0.472vw)"
                menuHeight="10rem"
              />
            </div>
            <div className="px-4 mt-2">
              <label htmlFor="" className="f12-vw m-0 p-0 fw-600">
                Tipe Kamar
              </label>
              <SelectOpt
                placeholder="Pilih Room"
                optionsValue={roomType}
                value={roomTypeSelected}
                onChange={(val) => handleRoomType(val)}
                clear={true}
                fontSize="calc(8pt + 0.472vw)"
                menuHeight="10rem"
                isSearchable={true}
              />
            </div>
            <div className="px-4 mt-3">
              <label htmlFor="" className="f12-vw m-0 p-0 fw-600">
                Nomor Kamar
              </label>
              {mobileSearchInput("Cari Nomor Kamar", roomNumber, (e) =>
                handleRoomNumber(e)
              )}
            </div>
            <div className="px-4 mt-2">
              <label htmlFor="" className="f12-vw m-0 p-0 fw-600">
                Lantai
              </label>
              <SelectOpt
                placeholder="Pilih Floor"
                optionsValue={floorList}
                value={floorSelected}
                onChange={(val) => handleFloor(val)}
                clear={true}
                fontSize="calc(8pt + 0.472vw)"
                menuHeight="10rem"
                placement={"top"}
                isSearchable={true}
              />
            </div>
          </div>
        </BottomSheet>
      )}

      <PopupModal
        modalIsOpen={openModal}
        width={"10rem"}
        mode={mode === "success" ? true : false}
        top={mode === "inspection-form" ? "" : "70%"}
      >
        {mode === "change-status" ? (
          <PopupForm
            title={`Pilih Status Kamar ${roomInspectionSelected?.room_number}`}
            statusSelected={statusSelected}
            setStatusSelected={setStatusSelected}
            reset={reset}
            setMode={setMode}
            roomInspectionSelected={roomInspectionSelected}
            setimgPopup={setimgPopup}
          />
        ) : mode === "change-confirm" || mode === "change-success" ? (
          <PopupConfirm
            title={
              mode === "change-confirm" ? "Yakin ingin mengubah status" : ""
            }
            image={imgPopup}
            statusSelected={statusSelected}
            reset={reset}
            setMode={setMode}
            room_number={`Kamar ${roomInspectionSelected.room_number}`}
            mode={mode}
            confirmStatus={submitRoomAction}
            loading={loadingSubmit}
            color={color}
          />
        ) : (
          <>
            <NotifMessage
              closeModal={openModal}
              img={imgPopup}
              messageHeader={headerPopup}
              color="red"
              messageBody={errorSubmit}
            />
            <ButtonClose close={reset} text="OK" />
          </>
        )}
      </PopupModal>
    </>
  );
};

export default RoomChangeStatus;
