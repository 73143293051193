import { Icon } from "@iconify/react";
import React from "react";
import { iconUrl } from "../config";

export const StickyButton = ({ openButtonSheet, setOpenButtonSheet }) => {
  return (
    <div
      style={{
        position: "fixed",
        bottom: 0,
        right: 0,
        left: 0,
        height: 50,
        borderTopLeftRadius: 10,
        borderTopRightRadius: 10,
        backgroundColor: "#0071A4",
      }}
      onClick={() => setOpenButtonSheet(!openButtonSheet)}
    >
      <div className="d-flex align-items-center justify-content-center h-100">
        <span className="mr-2">
          <Icon icon={iconUrl.filter20Filled} width={22} color={"white"} />
        </span>
        <div className="text-white f14-vw align-middle">Filter</div>
      </div>
    </div>
  );
};
