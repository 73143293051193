import {
  useState,
  useContext,
  useCallback,
  useEffect,
  useRef,
  Fragment,
} from "react";
import { Icon } from "@iconify/react";
import { BreadcrumbContext } from "../../common/BreadcrumbContext";
import { EmptyData, MobileEmptyData } from "../../pages/Empty/EmptyData";
import {
  addDataPict,
  getData,
  getErr,
  safeErrCode,
} from "../../utils/api-request";
import history from "../../utils/history";
import PopupModal from "../../common/PopupModal";
import NotifMessage, { ButtonClose } from "../../common/Notif";
import {
  getClientTime,
  getServerTimeWithoutDays,
  getTimezoneOffsetClient,
  filterStatusRoomCustom,
  getClientTimeWithoutSecond,
  tableCol,
  isEmpty,
} from "../../utils";
import {
  filterHousekeepingStatus,
  housekeepingStatus,
  seeMore,
} from "./common/utils";
import { FootnoteHousekeeping } from "./common/FootnoteHousekeeping";
import SelectOpt from "../../common/SelectOpt";
import { CustomOptionLabel } from "./common";
import { mobileSearchInput, searchInput } from "../booking/bookingHelper";
import { is_sub_menu_action } from "../../utils/uac";
import { iconUrl } from "../../config";
import { IS_MOBILE } from "../../common/Responsive";
import { MobileCardList } from "../../common/CardList";
import { BottomSheet } from "react-spring-bottom-sheet";
import { CameraWebcam } from "../../common/CameraWebcam";
import { ActionStartPopup } from "./ActionStartPopup";
import { ActionEndPopup } from "./ActionEndPopup";
import { t } from "../../common/ErrorMessage";

const TableRoomAction = ({
  list,
  loading,
  errorMessage,
  isOpen,
  setMode,
  setActionEnd,
  setRoomActionSelected,
  setActionStart,
  height,
  endClean,
}) => {
  const timezone =
    localStorage?.getItem("timezone") && localStorage?.getItem("timezone");
  return (
    <>
      <div
        className="table-list table-responsive-sm scroll-table"
        style={{
          height: height ? height : "300px",
        }}
      >
        <table
          className="table table-sm"
          style={{ borderCollapse: "separate", borderSpacing: 0 }}
        >
          <thead className="f8-vw sticky-table">
            <tr
              className="text-black text-center text-white"
              style={{
                backgroundColor: "#0071A4",
              }}
            >
              <th rowSpan={2} className="align-middle">
                ID
              </th>
              <th rowSpan={2} className="align-middle">
                Tanggal
              </th>
              <th rowSpan={2} className="align-middle">
                Room No
              </th>
              <th rowSpan={2} className="align-middle">
                Lt
              </th>
              <th rowSpan={2} className="align-middle">
                Tipe Kamar
              </th>
              <th rowSpan={2} className="align-middle">
                Bed Type
              </th>
              <th rowSpan={1} colSpan={2}>
                Cleaning
              </th>
              <th rowSpan={1} colSpan={2} className="align-middle">
                Status
              </th>
              <th rowSpan={2} className="align-middle">
                Last Update
              </th>
              {/* <th rowSpan={2} className="align-middle">
                Action
              </th> */}
            </tr>
            <tr
              className="text-center text-white"
              style={{
                backgroundColor: "#0071A4",
              }}
            >
              <th>Start</th>
              <th>End</th>
              <th>Room</th>
              <th>Housekeeping</th>
            </tr>
          </thead>
          <tbody className="f8-vw">
            {list?.length > 0 && !loading ? (
              <>
                {list?.map((item, index) => (
                  <Fragment key={item?.id}>
                    <tr
                      style={{
                        cursor: "pointer",
                      }}
                      className={`click-table ${tableCol(index)}`}
                      onClick={() => {
                        isOpen();
                        setMode("detail-room");
                        setRoomActionSelected(item);
                      }}
                    >
                      <td className="align-middle text-center font-weight-bold">
                        {item?.id}
                      </td>
                      <td className="align-middle text-center">
                        {getServerTimeWithoutDays(item?.ts, timezone)}
                      </td>
                      <td className="align-middle text-center font-weight-bold">
                        {item?.room_number}
                      </td>
                      <td className="align-middle text-center">
                        {item?.floor}
                      </td>
                      <td className="align-middle text-center">
                        {item?.room_type_name}
                      </td>
                      <td className="align-middle text-center py-0">
                        <div className="font-weight-bold">
                          {item?.bed_type_name}
                        </div>
                        <div className="">{item?.bed_size}</div>
                      </td>
                      <td className="align-middle text-center py-0">
                        {item?.start_cleaning ? (
                          <>
                            <div>
                              {getServerTimeWithoutDays(
                                item?.start_cleaning,
                                timezone
                              )}
                            </div>
                            <div className="font-weight-bold">
                              {getClientTime(item?.start_cleaning, timezone) +
                                " " +
                                getTimezoneOffsetClient(timezone)}
                            </div>
                          </>
                        ) : (
                          "-"
                        )}
                      </td>
                      <td className="align-middle text-center py-0">
                        {item?.end_cleaning ? (
                          <>
                            <div>
                              {getServerTimeWithoutDays(
                                item?.end_cleaning,
                                timezone
                              )}
                            </div>
                            <div className="font-weight-bold">
                              {getClientTime(item?.end_cleaning, timezone) +
                                " " +
                                getTimezoneOffsetClient(timezone)}
                            </div>
                          </>
                        ) : (
                          "-"
                        )}
                      </td>
                      <td className="align-middle text-center">
                        <div
                          className={`mx-auto ${
                            filterStatusRoomCustom(item?.room_status)?.className
                          }`}
                          style={{
                            borderRadius: 5,
                            width: 35,
                            fontWeight: "bold",
                          }}
                        >
                          {filterStatusRoomCustom(item?.room_status)?.label}
                        </div>
                      </td>
                      <td className={"align-middle text-center "}>
                        <div
                          className={`mx-auto ${
                            filterHousekeepingStatus(item?.hk_status)?.className
                          }`}
                          style={{
                            borderRadius: 5,
                            width: 35,
                            fontWeight: "bold",
                          }}
                        >
                          {filterHousekeepingStatus(item?.hk_status)?.label}
                        </div>
                      </td>
                      <td className="align-middle text-center py-0">
                        <div className="text-center">
                          {getServerTimeWithoutDays(
                            item?.last_update,
                            timezone
                          )}
                        </div>
                        <div className="text-center font-weight-bold">
                          {getClientTime(item?.last_update, timezone) +
                            " " +
                            getTimezoneOffsetClient(timezone)}
                        </div>
                      </td>
                      {/* <td
                        className="align-middle text-center"
                        style={{ width: "100px", backgroundColor: "white" }}
                      >
                        {is_sub_menu_action("hk_room_act_process") && (
                          <div>
                            {item?.hk_status === 0 || item?.hk_status === 3 ? (
                              <button
                                className="border-0 btn green f8-vw font-weight-bold"
                                onClick={(e) => {
                                  isOpen();
                                  setMode("cleaning-confirm");
                                  setRoomActionSelected(item);
                                  e.preventDefault();
                                  e.stopPropagation();
                                }}
                              >
                                Start Clean
                              </button>
                            ) : (
                              item?.hk_status === 1 && (
                                <button
                                  className="border-0 btn blue f8-vw font-weight-bold"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();

                                    !!setActionEnd &&
                                      setActionEnd(
                                        `${getServerTimeWithoutDays(
                                          new Date(),
                                          timezone
                                        )}, ${getClientTime(
                                          new Date(),
                                          timezone
                                        ) +
                                          " " +
                                          getTimezoneOffsetClient(timezone)}`
                                      );
                                    setActionStart(
                                      `${getServerTimeWithoutDays(
                                        item.lastaction,
                                        timezone
                                      )}, ${getClientTime(
                                        item.lastaction,
                                        timezone
                                      ) +
                                        " " +
                                        getTimezoneOffsetClient(timezone)}`
                                    );
                                    setRoomActionSelected(item);
                                    endClean();
                                  }}
                                >
                                  End Clean
                                </button>
                              )
                            )}
                          </div>
                        )}
                      </td> */}
                    </tr>
                  </Fragment>
                ))}
              </>
            ) : (
              <tr>
                <td className="p-0" colSpan={12}>
                  <EmptyData
                    loading={loading}
                    errMessage={errorMessage}
                    text="Semua kamar sudah dibersihkan"
                    sourceImg={"/img/housekeeping/room-action.svg"}
                    height="36vh"
                  />
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </>
  );
};

const PopupTemplate = ({
  image,
  title,
  reset,
  mode,
  withCrossIcon = false,
}) => {
  const timezone =
    localStorage?.getItem("timezone") && localStorage?.getItem("timezone");
  return (
    <div className="p-3">
      <div className="d-flex flex-column " style={{ width: "19rem" }}>
        {!!withCrossIcon && (
          <div className="pointer popup-close-icon" onClick={reset}>
            <Icon
              icon={iconUrl.timesIcon}
              width="20"
              height="20"
              color="#742b9e"
            />
          </div>
        )}

        <div className="d-flex flex-column align-items-center justify-content-center">
          <div className="mb-2">
            <img src={image} alt="" width={100} loading="lazy" />
          </div>
          <div
            className={`font-weight-bold text-center ${
              IS_MOBILE ? "f14-vw" : "f10-vw"
            }`}
          >
            {title}
          </div>
          {mode === "cleaning-start" ? (
            <div
              className={`mt-2 w-100 py-3 ${IS_MOBILE ? "f12-vw" : "f8-vw"}`}
              style={{ backgroundColor: "#E8FEEA" }}
            >
              <div className="text-center">Action Start</div>
              <div className="text-center">
                {getServerTimeWithoutDays(new Date(), timezone)},{" "}
                <span className="font-weight-bold">{`${getClientTime(
                  new Date(),
                  timezone
                )} ${getTimezoneOffsetClient(timezone)}`}</span>
              </div>
            </div>
          ) : (
            <div className="f10-vw">selesai dibersihkan</div>
          )}
        </div>
      </div>
      <div className="d-flex justify-content-center mt-3">
        <div className="col-5">
          <button
            className={`btn btn-sm btn-block bg-green text-white ${
              IS_MOBILE ? "f12-vw" : "f8-vw"
            }`}
            onClick={() => reset()}
          >
            OK
          </button>
        </div>
      </div>
    </div>
  );
};

const PopupStart = ({
  title,
  onSubmit,
  reset,
  loadingSubmit,
  note,
  setNote,
  setFileRoom,
  setFileBathroom,
  fileRoom,
  fileBathroom,
  roomActionSelected,
}) => {
  const [showRoom, setShowRoom] = useState("");
  const [showBathRoom, setShowBathRoom] = useState("");
  const validateButtonSubmit = () => {
    if (fileRoom.length > 0 && fileBathroom.length > 0 && !loadingSubmit) {
      return true;
    }
    return false;
  };

  return (
    <div className="d-flex flex-column p-3" style={{ width: "35rem" }}>
      <div className="dark-blue f12-vw px-3">
        <div className="align-middle">
          <span className="mr-1">
            <Icon
              icon={iconUrl.questionMarkCircle20Solid}
              color="orange"
              width={"2rem"}
            />
          </span>
          <span className="f12-vw">Yakin mulai bersihkan </span>
          <span className="font-weight-bold f12-vw">{title}</span>
        </div>
      </div>
      <div
        className="d-flex px-3 py-1 mt-1"
        style={{
          borderRadius: "5px",
        }}
      >
        <div className="col-6 pl-0">
          <label htmlFor="upload room" className="f8-vw fw-700">
            Catatan
          </label>
          <textarea
            name="note"
            id="note"
            rows="5"
            className="form-control f8-vw form-control-sm rounded-input"
            style={{
              borderColor: "lightgray",
              resize: "none",
              backgroundColor: "white",
            }}
            onChange={(e) => setNote(e.target.value)}
            value={note}
            placeholder="Write a note..."
          />
        </div>
      </div>

      <div
        className="px-3 py-1"
        style={{
          borderRadius: "5px",
        }}
      >
        <div className="d-flex flex-row">
          <div className="col pl-0 my-1">
            <label htmlFor="upload room" className="f8-vw fw-700">
              Foto Kamar
            </label>
            <CameraWebcam
              setfileUpload={setFileRoom}
              title="Add Attachment"
              height="8rem"
              fileUpload={fileRoom}
              setShowImg={setShowRoom}
              showImg={showRoom}
            />
          </div>
          <div className="col pr-0 my-1">
            <label htmlFor="upload room" className="f8-vw fw-700">
              Foto Kamar Mandi
            </label>
            <CameraWebcam
              setfileUpload={setFileBathroom}
              title="Add Attachment"
              height="8rem"
              fileUpload={fileBathroom}
              setShowImg={setShowBathRoom}
              showImg={showBathRoom}
            />
          </div>
        </div>
      </div>
      <div className="px-3">
        <hr className="separator-blue" />
        <div className="text-center">
          <small className="f8-vw">Apakah anda yakin ingin melanjutkan?</small>
        </div>
      </div>
      <div className="mt-3 d-flex flex-row justify-content-between">
        <div className="col-3">
          <button
            className={`btn btn-block f8-vw font-weight-bold text-black btn-outline-purple`}
            onClick={reset}
          >
            Back
          </button>
        </div>
        <div className="col-3">
          <button
            className={`btn btn-block f8-vw font-weight-bold text-white ${
              validateButtonSubmit() ? "btn-gradient" : "btn-secondary"
            }`}
            onClick={() => onSubmit("startclean", roomActionSelected.id)}
            disabled={validateButtonSubmit() ? false : true}
          >
            {loadingSubmit && (
              <span className="spinner-border spinner-border-sm mr-1"></span>
            )}
            Next
          </button>
        </div>
      </div>
    </div>
  );
};

const PopupEnd = ({
  sameUser = false,
  roomActionSelected,
  reset,
  note,
  setNote,
  loadingSubmit,
  onSubmit,
  reasonNote,
  setReasonNote,
  listFile,
  validate,
  stepperForm,
  mode,
}) => {
  const timezone =
    localStorage?.getItem("timezone") && localStorage?.getItem("timezone");

  const listUploadFile = (title, setFile, show, setShow, i) => {
    return (
      <div className={`mt-2`} key={i}>
        <label htmlFor="" className="f8-vw fw-700 mb-0">
          {title}
        </label>
        <CameraWebcam
          setfileUpload={setFile}
          title={"Add attachment"}
          height="8rem"
          setShowImg={setShow}
          showImg={show}
        />
      </div>
    );
  };
  const listShowFile = (title, show, i) => {
    return (
      <div className={`mt-2`} key={i}>
        <label htmlFor="" className="f8-vw fw-700 mb-0">
          {title}
        </label>
        <div className="" style={{ height: "8rem" }}>
          <img
            src={show}
            alt=""
            style={{ objectFit: "contain", width: "100%", height: "8rem" }}
            loading="lazy"
          />
        </div>
      </div>
    );
  };

  return (
    <div className="d-flex flex-column py-3 px-1" style={{ width: "50rem" }}>
      <div className="col">
        <div className="d-flex justify-content-between">
          <div className="col px-0">
            <div className="d-flex align-items-center">
              <span className="mr-1">
                <Icon
                  icon={
                    mode === "end-confirm"
                      ? iconUrl.questionMarkCircle20Solid
                      : iconUrl.informationIcon
                  }
                  color="orange"
                  width={"1.7rem"}
                />
              </span>
              <span className="dark-blue f12-vw mr-1">
                {mode === "end-confirm"
                  ? "Yakin selesai bersihkan"
                  : "End Clean"}
              </span>
              <span className="dark-blue f12-vw font-weight-bold">
                {` Kamar ${roomActionSelected?.room_number}`}
              </span>
            </div>
          </div>
        </div>
        <hr className="my-2 separator-blue" />
      </div>
      <div className="col">
        <div className="d-flex flex-row">
          <div className="col pl-0">
            <label htmlFor="" className="f8-vw fw-700">
              Room Action Detail
            </label>
            <div className="f8-vw">
              <div className="d-flex flex-row">
                <div className="col-4 px-0 " style={{ color: "#5e5e5e" }}>
                  Action Start
                </div>
                <div className="">
                  :{" "}
                  {getServerTimeWithoutDays(
                    roomActionSelected?.start_cleaning,
                    timezone
                  )}
                  <span className="font-weight-bold">{`, ${getClientTimeWithoutSecond(
                    roomActionSelected?.start_cleaning,
                    timezone
                  )} ${getTimezoneOffsetClient(timezone)}`}</span>
                </div>
              </div>
            </div>
            <div className="f8-vw">
              <div className="d-flex flex-row">
                <div className="col-4 px-0 " style={{ color: "#5e5e5e" }}>
                  Action End
                </div>
                <div className="">
                  : {getServerTimeWithoutDays(new Date(), timezone)}
                  <span className="font-weight-bold">{`, ${getClientTimeWithoutSecond(
                    new Date(),
                    timezone
                  )} ${getTimezoneOffsetClient(timezone)}`}</span>
                </div>
              </div>
            </div>
          </div>
          <div className="col">
            <label htmlFor="" className="f8-vw fw-700">
              Catatan
            </label>
            {mode === "end-confirm" ? (
              <div className="f8-vw">{note ? note : "-"}</div>
            ) : (
              <textarea
                name="note"
                id="note"
                rows="5"
                className="form-control f8-vw form-control-sm rounded-input"
                style={{
                  borderColor: "lightgray",
                  resize: "none",
                  backgroundColor: stepperForm > 1 ? "#ededed" : "white",
                }}
                onChange={(e) => setNote(e.target.value)}
                value={note}
                placeholder="Tulis catatan..."
              />
            )}
          </div>

          <div className="col pr-0">
            <label htmlFor="" className="f8-vw fw-700">
              Alasan Housekeeper berbeda
            </label>
            {mode === "end-confirm" ? (
              <div className="f8-vw">{reasonNote ? reasonNote : "-"}</div>
            ) : (
              <div className="">
                <textarea
                  name="note"
                  id="note"
                  rows="5"
                  className="form-control f8-vw form-control-sm rounded-input"
                  style={{
                    borderColor: "lightgray",
                    resize: "none",
                    backgroundColor: sameUser ? "lightgray" : "white",
                    cursor: sameUser ? "not-allowed" : "text",
                  }}
                  onChange={(e) => setReasonNote(e.target.value)}
                  value={reasonNote}
                  placeholder="Alasan housekeeper berbeda..."
                  disabled={!sameUser ? false : true}
                />
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="col px-0">
        {mode === "end-confirm" ? (
          <div className="d-flex flex-row">
            {listFile?.length > 0 &&
              listFile?.map((item, i) => {
                return (
                  <div className="col" key={item?.id}>
                    {listShowFile(item?.title, item.show, item.id)}
                  </div>
                );
              })}
          </div>
        ) : (
          <div className="d-flex flex-row">
            {listFile?.length > 0 &&
              listFile?.map((item, i) => {
                return (
                  <div className="col" key={item?.id}>
                    {listUploadFile(
                      item?.title,
                      item.setFile,
                      item.show,
                      item.setShow,
                      item.id
                    )}
                  </div>
                );
              })}
          </div>
        )}
      </div>
      <div className="col">
        <hr className="separator-blue" />
        <div className="text-center">
          <small className="f8-vw">Apakah anda yakin untuk melanjutkan?</small>
        </div>
      </div>
      <div className="col">
        <div className={`d-flex mt-3 justify-content-between`}>
          <div className="col-2 pl-0 pr-4">
            <button
              className="btn btn-sm btn-block btn-outline-purple"
              onClick={() => reset()}
            >
              Back
            </button>
          </div>

          <div className="col-2 pr-0 pl-4">
            <button
              className={`btn btn-sm btn-block text-white ${
                validate() ? "btn-gradient" : "btn-secondary"
              }`}
              onClick={() =>
                onSubmit(`endcleanextra`, roomActionSelected?.id, stepperForm)
              }
              disabled={validate() ? false : true}
            >
              {loadingSubmit && (
                <span className="spinner-border spinner-border-sm mr-1"></span>
              )}
              Next
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

const PopupDetail = ({ roomActionSelected, reset }) => {
  const detailInfo = (title, value, flex = 4) => {
    return (
      <div className={`d-flex flex-row mt-1 ${IS_MOBILE ? "f12-vw" : "f8-vw"}`}>
        <div className={`col-${flex} px-0`} style={{ color: "#707070" }}>
          {title}
        </div>
        <div className="col px-0 fw-600">: {value}</div>
      </div>
    );
  };
  const timezone =
    localStorage?.getItem("timezone") && localStorage?.getItem("timezone");
  return (
    <>
      {IS_MOBILE ? (
        <div className="d-flex flex-column p-2" style={{ width: "21rem" }}>
          <div className="col">
            <div className="d-flex align-items-center">
              <span className="mr-2">
                <Icon
                  icon={iconUrl.informationIcon}
                  color="orange"
                  width={"1.5rem"}
                />
              </span>
              <span className="f16-vw dark-blue fw-700">
                Room Action Detail
              </span>
            </div>
          </div>
          <div className="">
            <hr className="separator-blue mx-3" />
          </div>
          <div className="col px-0 ">
            <div
              className="scroll-popup hide-scrollbar"
              style={{ maxHeight: "25rem" }}
            >
              <div className="d-flex flex-column">
                <div className="col">
                  {detailInfo("ID", roomActionSelected?.id, 5)}
                  {detailInfo(
                    "Tanggal",
                    getServerTimeWithoutDays(roomActionSelected?.ts, timezone),
                    5
                  )}
                  {detailInfo("Room No", roomActionSelected?.room_number, 5)}
                  {detailInfo("Floor", roomActionSelected?.floor, 5)}
                  {detailInfo(
                    "Room Type",
                    roomActionSelected?.room_type_name,
                    5
                  )}
                  {detailInfo(
                    "Bed Type",
                    `${roomActionSelected?.bed_type_name}`,
                    5
                  )}
                  {detailInfo("Bed Size", `${roomActionSelected?.bed_size}`, 5)}
                </div>
                <div className="col">
                  <div className="d-flex flex-column f12-vw mt-3">
                    <div className="purple font-weight-bold">
                      Start Cleaning
                    </div>
                    {detailInfo(
                      "Action Start",
                      <>
                        {roomActionSelected?.start_cleaning ? (
                          <>
                            <span>
                              {getServerTimeWithoutDays(
                                roomActionSelected?.start_cleaning,
                                timezone
                              )}
                            </span>
                            <span className="font-weight-bold">
                              {` ${getClientTimeWithoutSecond(
                                roomActionSelected?.start_cleaning,
                                timezone
                              )} ${getTimezoneOffsetClient(timezone)}`}
                            </span>
                          </>
                        ) : (
                          "-"
                        )}
                      </>,
                      5
                    )}
                    {detailInfo(
                      "Start cleaning by",
                      roomActionSelected?.start_cleaning_by_name
                        ? roomActionSelected?.start_cleaning_by_name
                        : "-",
                      5
                    )}
                    <div className="purple font-weight-bold mt-3">
                      End Cleaning
                    </div>
                    {detailInfo(
                      "Action End",
                      <>
                        {roomActionSelected?.end_cleaning ? (
                          <>
                            <span>
                              {getServerTimeWithoutDays(
                                roomActionSelected?.end_cleaning,
                                timezone
                              )}
                            </span>
                            <span className="font-weight-bold">
                              {` ${getClientTimeWithoutSecond(
                                roomActionSelected?.end_cleaning,
                                timezone
                              )} ${getTimezoneOffsetClient(timezone)}`}
                            </span>
                          </>
                        ) : (
                          "-"
                        )}
                      </>,
                      5
                    )}
                    {detailInfo(
                      "End cleaning by",
                      roomActionSelected?.end_cleaning_by_name
                        ? roomActionSelected?.end_cleaning_by_name
                        : "-",
                      5
                    )}
                  </div>
                </div>
                <div className="col">
                  {detailInfo(
                    "Room Status",
                    <span
                      className={`px-2 ${
                        filterStatusRoomCustom(roomActionSelected?.room_status)
                          ?.className
                      }`}
                      style={{
                        borderRadius: 3,
                        width: "3rem",
                        fontWeight: "bold",
                      }}
                    >
                      {
                        filterStatusRoomCustom(roomActionSelected?.room_status)
                          ?.label
                      }
                    </span>,
                    5
                  )}
                  {detailInfo(
                    "Housekeeping Status",
                    <span
                      className={`px-2
                      ${
                        filterHousekeepingStatus(roomActionSelected?.hk_status)
                          ?.className
                      }`}
                      style={{
                        borderRadius: 3,
                        width: "3rem",
                        fontWeight: "bold",
                      }}
                    >
                      {
                        filterHousekeepingStatus(roomActionSelected?.hk_status)
                          ?.label
                      }
                    </span>,
                    5
                  )}
                  {detailInfo(
                    "Last Update",
                    <>
                      {roomActionSelected?.last_update ? (
                        <>
                          <span>
                            {getServerTimeWithoutDays(
                              roomActionSelected?.last_update,
                              timezone
                            )}
                          </span>
                          <span className="font-weight-bold">
                            {` ${getClientTimeWithoutSecond(
                              roomActionSelected?.last_update,
                              timezone
                            )} ${getTimezoneOffsetClient(timezone)}`}
                          </span>
                        </>
                      ) : (
                        "-"
                      )}
                    </>,
                    5
                  )}
                </div>
              </div>
              <hr className="separator-blue mx-3" />
              <div className="d-flex flex-column f12-vw">
                <div className="col">
                  <div className="purple font-weight-bold">
                    Start Cleaning Note
                  </div>
                  <div className="text-justify">
                    {roomActionSelected?.start_note
                      ? seeMore(roomActionSelected?.start_note)
                      : "-"}
                  </div>
                </div>
                <div className="col">
                  <div className="purple font-weight-bold">
                    End Cleaning Note
                  </div>
                  {roomActionSelected?.end_note
                    ? seeMore(roomActionSelected?.end_note)
                    : "-"}
                </div>
                <div className="col">
                  <div className="purple font-weight-bold">Inspection Note</div>
                  {roomActionSelected?.inspection_note
                    ? seeMore(roomActionSelected?.inspection_note)
                    : "-"}
                </div>
              </div>
            </div>
            <hr className="separator-blue mx-3" />
          </div>

          <div className="col">
            <div className="d-flex justify-content-center">
              <div className="col-5 px-0">
                <button
                  className="btn btn-sm btn-block btn-gradient text-white"
                  onClick={() => reset()}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="d-flex flex-column p-3" style={{ width: "55rem" }}>
          <div className="col">
            <div className="d-flex align-items-center">
              <span className="mr-2">
                <Icon
                  icon={iconUrl.informationIcon}
                  color="orange"
                  width={"1.5rem"}
                />
              </span>
              <span className="f12-vw dark-blue fw-700">
                Room Action Detail
              </span>
            </div>
          </div>
          <div className="">
            <hr className="separator-blue mx-3" />
          </div>
          <div className="col">
            <div className="d-flex flex-row">
              <div className="col pl-0">
                {detailInfo("ID", roomActionSelected?.id)}
                {detailInfo(
                  "Tanggal",
                  getServerTimeWithoutDays(roomActionSelected?.ts, timezone)
                )}
                {detailInfo("Room No", roomActionSelected?.room_number)}
                {detailInfo("Floor", roomActionSelected?.floor)}
                {detailInfo("Room Type", roomActionSelected?.room_type_name)}
                {detailInfo("Bed Type", `${roomActionSelected?.bed_type_name}`)}
                {detailInfo("Bed Size", `${roomActionSelected?.bed_size}`)}
              </div>
              <div className="col">
                <div className="d-flex flex-column f8-vw">
                  <div className="purple font-weight-bold">Start Cleaning</div>
                  {detailInfo(
                    "Action Start",
                    <>
                      {roomActionSelected?.start_cleaning ? (
                        <>
                          <span>
                            {getServerTimeWithoutDays(
                              roomActionSelected?.start_cleaning,
                              timezone
                            )}
                          </span>
                          <span className="font-weight-bold">
                            {` ${getClientTimeWithoutSecond(
                              roomActionSelected?.start_cleaning,
                              timezone
                            )} ${getTimezoneOffsetClient(timezone)}`}
                          </span>
                        </>
                      ) : (
                        "-"
                      )}
                    </>,
                    5
                  )}
                  {detailInfo(
                    "Start cleaning by",
                    roomActionSelected?.start_cleaning_by_name
                      ? roomActionSelected?.start_cleaning_by_name
                      : "-",
                    5
                  )}
                  <div className="purple font-weight-bold mt-3">
                    End Cleaning
                  </div>
                  {detailInfo(
                    "Action End",
                    <>
                      {roomActionSelected?.end_cleaning ? (
                        <>
                          <span>
                            {getServerTimeWithoutDays(
                              roomActionSelected?.end_cleaning,
                              timezone
                            )}
                          </span>
                          <span className="font-weight-bold">
                            {` ${getClientTimeWithoutSecond(
                              roomActionSelected?.end_cleaning,
                              timezone
                            )} ${getTimezoneOffsetClient(timezone)}`}
                          </span>
                        </>
                      ) : (
                        "-"
                      )}
                    </>,
                    5
                  )}
                  {detailInfo(
                    "End cleaning by",
                    roomActionSelected?.end_cleaning_by_name
                      ? roomActionSelected?.end_cleaning_by_name
                      : "-",
                    5
                  )}
                </div>
              </div>
              <div className="col pr-0">
                {detailInfo(
                  "Room Status",
                  <span
                    className={`px-2 ${
                      filterStatusRoomCustom(roomActionSelected?.room_status)
                        ?.className
                    }`}
                    style={{
                      borderRadius: 5,
                      width: 35,
                      fontWeight: "bold",
                    }}
                  >
                    {
                      filterStatusRoomCustom(roomActionSelected?.room_status)
                        ?.label
                    }
                  </span>,
                  5
                )}
                {detailInfo(
                  "Housekeeping Status",
                  <span
                    className={`px-2
                      ${
                        filterHousekeepingStatus(roomActionSelected?.hk_status)
                          ?.className
                      }`}
                    style={{
                      borderRadius: 5,
                      width: 35,
                      fontWeight: "bold",
                    }}
                  >
                    {
                      filterHousekeepingStatus(roomActionSelected?.hk_status)
                        ?.label
                    }
                  </span>,
                  5
                )}
                {detailInfo(
                  "Last Update",
                  <>
                    {roomActionSelected?.last_update ? (
                      <>
                        <span>
                          {getServerTimeWithoutDays(
                            roomActionSelected?.last_update,
                            timezone
                          )}
                        </span>
                        <span className="font-weight-bold">
                          {` ${getClientTimeWithoutSecond(
                            roomActionSelected?.last_update,
                            timezone
                          )} ${getTimezoneOffsetClient(timezone)}`}
                        </span>
                      </>
                    ) : (
                      "-"
                    )}
                  </>,
                  5
                )}
              </div>
            </div>
            <hr className="separator-blue" />
          </div>
          <div className="col">
            <div className="d-flex flex-row f8-vw">
              <div className="col pl-0">
                <div className="purple font-weight-bold">
                  Start Cleaning Note
                </div>
                <div className="text-justify">
                  {roomActionSelected?.start_note
                    ? seeMore(roomActionSelected?.start_note)
                    : "-"}
                </div>
              </div>
              <div className="col">
                <div className="purple font-weight-bold">End Cleaning Note</div>
                {roomActionSelected?.end_note
                  ? seeMore(roomActionSelected?.end_note)
                  : "-"}
              </div>
              <div className="col pr-0">
                <div className="f8-vw">
                  <div className="purple font-weight-bold">Inspection Note</div>
                  {roomActionSelected?.inspection_note
                    ? seeMore(roomActionSelected?.inspection_note)
                    : "-"}
                </div>
              </div>
            </div>
            <hr className="separator-blue" />
          </div>
          <div className="col">
            <div className="d-flex justify-content-end">
              <div className="col-2">
                <button
                  className="btn btn-sm btn-block btn-gradient text-white"
                  onClick={() => reset()}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

const RoomAction = () => {
  const {
    setpathLocation,
    openButtonSheet,
    setOpenButtonSheet,
    setShowFilter,
  } = useContext(BreadcrumbContext);
  const [roomActions, setRoomActions] = useState([]);
  const [roomActionSelected, setRoomActionSelected] = useState({});
  const [openModal, setOpenModal] = useState(false);
  const [mode, setMode] = useState("");
  const [loadingList, setLoadingList] = useState(false);
  const [loadingActionList, setLoadingActionList] = useState(false);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [errorList, setErrorList] = useState("");
  const [errorActionList, setErrorActionList] = useState("");
  const [errorSubmit, setErrorSubmit] = useState("");
  const [headerPopup, setHeaderPopup] = useState("");
  const [fileRoom, setFileRoom] = useState([]);
  const [fileMakingRoom, setFileMakingRoom] = useState([]);
  const [fileBathroom, setFileBathroom] = useState([]);
  const [fileRoomShow, setFileRoomShow] = useState("");
  const [fileMakingRoomShow, setFileMakingRoomShow] = useState("");
  const [fileBathroomShow, setFileBathroomShow] = useState("");
  const [actionStart, setActionStart] = useState("");
  const [actionEnd, setActionEnd] = useState("");
  const mounted = useRef(false);
  const [note, setNote] = useState("");
  const [roomNumber, setRoomNumber] = useState("");
  const userInfo =
    localStorage.getItem("user_info") &&
    JSON.parse(localStorage.getItem("user_info"));
  const [checkUserId, setCheckUserId] = useState(false);
  const [reasonNote, setReasonNote] = useState("");
  const [hkStatusSelected, setHkStatusSelected] = useState([]);
  const [detailEndClean, setDetailEndClean] = useState({});

  const listFile = [
    {
      id: 1,
      setFile: setFileRoom,
      show: fileRoomShow,
      setShow: setFileRoomShow,
      title: "Foto Kamar Sesudah",
      label: "end_room_file_path",
    },
    {
      id: 2,
      setFile: setFileMakingRoom,
      show: fileMakingRoomShow,
      setShow: setFileMakingRoomShow,
      title: "Hasil Making Bed",
      label: "hasil_making_bed",
    },
    {
      id: 3,
      setFile: setFileBathroom,
      show: fileBathroomShow,
      setShow: setFileBathroomShow,
      title: "Foto Kamar Mandi Sesudah",
      label: "end_bathroom_file_path",
    },
  ];

  const validateButtonSubmit = () => {
    switch (checkUserId) {
      case true:
        return fileRoomShow?.length > 0 &&
          fileMakingRoomShow?.length > 0 &&
          fileBathroomShow?.length > 0 &&
          !loadingSubmit
          ? true
          : false;
      case false:
        return fileRoomShow?.length > 0 &&
          fileMakingRoomShow?.length > 0 &&
          fileBathroomShow?.length > 0 &&
          reasonNote.length > 0 &&
          !loadingSubmit
          ? true
          : false;

      default:
        break;
    }
  };

  const handleRoomNumber = (val) => {
    setRoomNumber(val.target.value);
  };

  const isOpen = () => {
    setOpenModal(!openModal);
  };

  const getRoomActions = useCallback(() => {
    setLoadingList(true);
    const params = {
      ...(!!roomNumber && { room_number: roomNumber }),
      ...(!!hkStatusSelected?.value && { hk_status: hkStatusSelected?.value }),
    };
    let paramsFormatted = null;

    if (!isEmpty(params)) {
      paramsFormatted = new URLSearchParams(params);
    }
    const endpoint = `/api/v1/listhousekeeping?${
      paramsFormatted !== null ? `&${paramsFormatted}` : ""
    }`;
    getData(endpoint)
      .then((resp) => {
        if (mounted.current) {
          setLoadingList(false);
          if (resp.data) {
            setRoomActions(resp.data);
          } else {
            resp.json().then((err) => {
              setErrorList(t(safeErrCode(err)));
            });
            setRoomActions([]);
          }
        }
      })
      .catch((error) => {
        console.log(error);
        setErrorList(t(getErr(error)));
        setLoadingList(false);
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [roomNumber, hkStatusSelected]);

  const submitRoomCleaning = (type, id, step) => {
    setLoadingSubmit(true);
    const payload = {
      id,
      roomnumber: roomActionSelected.room_number,
      ...(type === "startclean"
        ? {
            start_note: note,
            start_room_file_path: fileRoom[0],
            start_bathroom_file_path: fileBathroom[0],
          }
        : {
            end_room_file_path: fileRoom[0],
            hasil_making_bed: fileMakingRoom[0],
            end_bathroom_file_path: fileBathroom[0],
            end_note: note,
            reason: reasonNote,
          }),
    };
    const formData = new FormData();
    for (const key in payload) {
      formData.append(key, payload[key]);
    }
    // for (const pair of formData.entries()) {
    //   console.log(pair[0] + " - " + pair[1]);
    // }
    addDataPict(`/api/v1/${type}`, formData)
      .then((resp) => {
        setLoadingSubmit(false);
        if (resp.status === "OK") {
          if (type === "startclean") {
            setMode("cleaning-start");
          } else {
            setMode("cleaning-end");
          }
        } else {
          resp.json().then((err) => {
            setErrorSubmit(t(safeErrCode(err)));
          });
          setMode("error");
          if (type === "startclean") {
            setHeaderPopup("Gagal Start Clean!");
          } else {
            setHeaderPopup("Gagal End Clean!");
          }
        }
      })
      .catch((error) => {
        console.log(error);
        setLoadingSubmit(false);
        setErrorSubmit(t(getErr(error)));
        setMode("error");
        if (type === "startclean") {
          setHeaderPopup("Gagal Start Clean!");
        } else {
          setHeaderPopup("Gagal End Clean!");
        }
      });
  };

  const handleEnter = (e) => {
    if (e.key === "Enter") {
      getRoomActions();
    }
  };

  const reset = () => {
    setRoomActions([]);
    setRoomActionSelected({});
    setOpenModal(false);
    setMode("");
    setLoadingList(false);
    setLoadingActionList(false);
    setLoadingSubmit(false);
    setErrorList("");
    setErrorActionList("");
    setErrorSubmit("");
    setHeaderPopup("");
    setActionStart("");
    setActionEnd("");
    setNote("");
    setRoomNumber("");
    getRoomActions();
    setReasonNote("");
    setFileRoom([]);
    setFileBathroom([]);
    setFileMakingRoom([]);
    setFileRoomShow("");
    setFileBathroomShow("");
    setFileMakingRoomShow("");
    setDetailEndClean({});
  };

  const resetFilter = () => {
    setHkStatusSelected([]);
    setRoomNumber("");
    setRoomActions([]);
    getRoomActions();
  };

  const handleSelectHkStatus = (val) => {
    if (!val) {
      setHkStatusSelected([]);
    } else {
      setHkStatusSelected(val);
    }
  };

  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);

  useEffect(() => {
    if (roomNumber === "" && hkStatusSelected?.value === undefined) {
      getRoomActions();
    }
  }, [getRoomActions, roomNumber, hkStatusSelected]);

  useEffect(() => {
    setpathLocation(history.location.pathname);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history.location.pathname]);

  useEffect(() => {
    if (roomActionSelected?.start_cleaning_by) {
      if (roomActionSelected?.start_cleaning_by === userInfo?.user_id) {
        setCheckUserId(true);
      } else {
        setCheckUserId(false);
      }
    }
  }, [roomActionSelected, userInfo]);

  useEffect(() => {
    setShowFilter(true);
    return () => {
      setShowFilter(false);
    };
  }, [setShowFilter]);

  useEffect(() => {
    if (openModal) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
    return () => {
      document.body.style.overflow = "unset";
    };
  }, [openModal]);

  return (
    <>
      {IS_MOBILE ? (
        <PopupModal modalIsOpen={openModal}>
          {mode === "cleaning-confirm" ? (
            <>
              <ActionStartPopup
                title={`Kamar ${roomActionSelected?.room_number}?`}
                actionStart={actionStart}
                actionEnd={actionEnd}
                onSubmit={submitRoomCleaning}
                reset={reset}
                loadingSubmit={loadingSubmit}
                roomActionSelected={roomActionSelected}
                note={note}
                setNote={setNote}
                setFileRoom={setFileRoom}
                setFileBathroom={setFileBathroom}
                fileRoom={fileRoom}
                fileBathroom={fileBathroom}
              />
            </>
          ) : mode === "cleaning-start" ? (
            <>
              <PopupTemplate
                image="/img/housekeeping/start-clean.svg"
                title={`Mulai bersihkan kamar ${roomActionSelected?.room_number}`}
                reset={reset}
                mode={mode}
                setMode={setMode}
                setActionStart={setActionStart}
                roomActionSelected={roomActionSelected}
              />
            </>
          ) : mode === "cleaning-form" ? (
            <>
              <ActionEndPopup
                sameUser={checkUserId}
                roomActionSelected={roomActionSelected}
                reset={reset}
                setFileBathroom={setFileBathroom}
                setFileRoom={setFileRoom}
                setFileRoomShow={setFileRoomShow}
                setFileBathroomShow={setFileBathroomShow}
                setFileMakingRoom={setFileMakingRoom}
                setFileMakingRoomShow={setFileMakingRoomShow}
                note={note}
                setNote={setNote}
                loadingSubmit={loadingSubmit}
                onSubmit={() => setMode("end-confirm")}
                reasonNote={reasonNote}
                setReasonNote={setReasonNote}
                listFile={listFile}
                validate={validateButtonSubmit}
                detailEndClean={detailEndClean}
                mode={mode}
              />
            </>
          ) : mode === "end-confirm" ? (
            <>
              <ActionEndPopup
                sameUser={checkUserId}
                roomActionSelected={roomActionSelected}
                reset={() => setMode("cleaning-form")}
                setFileBathroom={setFileBathroom}
                setFileRoom={setFileRoom}
                fileBathroom={fileBathroom}
                fileRoom={fileRoom}
                note={note}
                setNote={setNote}
                loadingSubmit={loadingSubmit}
                onSubmit={submitRoomCleaning}
                reasonNote={reasonNote}
                setReasonNote={setReasonNote}
                listFile={listFile}
                validate={validateButtonSubmit}
                detailEndClean={detailEndClean}
                mode={mode}
              />
            </>
          ) : mode === "cleaning-end" ? (
            <>
              <PopupTemplate
                image="/img/housekeeping/end-clean.svg"
                title={`Kamar ${roomActionSelected?.room_number}`}
                reset={reset}
                mode={mode}
                setMode={setMode}
              />
            </>
          ) : mode === "detail-room" ? (
            <PopupDetail
              roomActionSelected={roomActionSelected}
              reset={reset}
            />
          ) : (
            <>
              <NotifMessage
                closeModal={openModal}
                img={"/img/error-icon/save-room-failed.svg"}
                messageHeader={headerPopup}
                color="red"
                messageBody={errorSubmit}
              />
              <ButtonClose close={reset} text="OK" />
            </>
          )}
        </PopupModal>
      ) : (
        <PopupModal modalIsOpen={openModal}>
          {mode === "cleaning-confirm" ? (
            <>
              <PopupStart
                title={`Kamar ${roomActionSelected?.room_number}?`}
                actionStart={actionStart}
                actionEnd={actionEnd}
                onSubmit={submitRoomCleaning}
                reset={reset}
                loadingSubmit={loadingSubmit}
                roomActionSelected={roomActionSelected}
                note={note}
                setNote={setNote}
                setFileRoom={setFileRoom}
                setFileBathroom={setFileBathroom}
                fileRoom={fileRoom}
                fileBathroom={fileBathroom}
              />
            </>
          ) : mode === "cleaning-start" ? (
            <>
              <PopupTemplate
                image="/img/housekeeping/start-clean.svg"
                title={`Mulai bersihkan kamar ${roomActionSelected?.room_number}`}
                reset={reset}
                mode={mode}
                setMode={setMode}
                setActionStart={setActionStart}
                roomActionSelected={roomActionSelected}
              />
            </>
          ) : mode === "cleaning-form" ? (
            <>
              <PopupEnd
                sameUser={checkUserId}
                roomActionSelected={roomActionSelected}
                reset={reset}
                setFileBathroom={setFileBathroom}
                setFileRoom={setFileRoom}
                fileBathroom={fileBathroom}
                fileRoom={fileRoom}
                note={note}
                setNote={setNote}
                loadingSubmit={loadingSubmit}
                onSubmit={() => setMode("end-confirm")}
                reasonNote={reasonNote}
                setReasonNote={setReasonNote}
                listFile={listFile}
                validate={validateButtonSubmit}
                detailEndClean={detailEndClean}
                mode={mode}
              />
            </>
          ) : mode === "end-confirm" ? (
            <>
              <PopupEnd
                sameUser={checkUserId}
                roomActionSelected={roomActionSelected}
                reset={() => setMode("cleaning-form")}
                setFileBathroom={setFileBathroom}
                setFileRoom={setFileRoom}
                fileBathroom={fileBathroom}
                fileRoom={fileRoom}
                note={note}
                setNote={setNote}
                loadingSubmit={loadingSubmit}
                onSubmit={submitRoomCleaning}
                reasonNote={reasonNote}
                setReasonNote={setReasonNote}
                listFile={listFile}
                validate={validateButtonSubmit}
                detailEndClean={detailEndClean}
                mode={mode}
              />
            </>
          ) : mode === "cleaning-end" ? (
            <>
              <PopupTemplate
                image="/img/housekeeping/end-clean.svg"
                title={`Kamar ${roomActionSelected?.room_number}`}
                reset={reset}
                mode={mode}
                setMode={setMode}
              />
            </>
          ) : mode === "detail-room" ? (
            <PopupDetail
              roomActionSelected={roomActionSelected}
              reset={reset}
            />
          ) : (
            <>
              <NotifMessage
                closeModal={openModal}
                img={"/img/error-icon/save-room-failed.svg"}
                messageHeader={headerPopup}
                color="red"
                messageBody={errorSubmit}
              />
              <ButtonClose close={reset} text="OK" />
            </>
          )}
        </PopupModal>
      )}

      {IS_MOBILE ? (
        <div className="d-flex flex-column p-1 mb-5">
          <div className="col">
            {roomActions?.length > 0 ? (
              roomActions.map((item) => {
                const timezone = localStorage.getItem("timezone");
                return (
                  <div
                    key={item?.id}
                    onClick={() => {
                      isOpen();
                      setMode("detail-room");
                      setRoomActionSelected(item);
                    }}
                  >
                    <MobileCardList
                      title={`Kamar ${item.room_number}`}
                      action_button={
                        is_sub_menu_action("hk_room_act_process") && (
                          <div>
                            {item?.hk_status === 0 || item?.hk_status === 3 ? (
                              <button
                                className="btn border-0 btn-sm btn-primary text-white btn-block f12-vw fw-700"
                                onClick={(e) => {
                                  isOpen();
                                  setMode("cleaning-confirm");
                                  setRoomActionSelected(item);
                                  e.preventDefault();
                                  e.stopPropagation();
                                }}
                              >
                                <span className="mr-1">
                                  <Icon
                                    icon={iconUrl.clockPlay}
                                    width={"1rem"}
                                  />
                                </span>
                                <span>Start Clean</span>
                              </button>
                            ) : (
                              item?.hk_status === 1 && (
                                <button
                                  className="btn border-0 btn-sm btn-success text-white btn-block f12-vw fw-700"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();

                                    !!setActionEnd &&
                                      setActionEnd(
                                        `${getServerTimeWithoutDays(
                                          new Date(),
                                          timezone
                                        )}, ${getClientTime(
                                          new Date(),
                                          timezone
                                        ) +
                                          " " +
                                          getTimezoneOffsetClient(timezone)}`
                                      );
                                    setActionStart(
                                      `${getServerTimeWithoutDays(
                                        item.lastaction,
                                        timezone
                                      )}, ${getClientTime(
                                        item.lastaction,
                                        timezone
                                      ) +
                                        " " +
                                        getTimezoneOffsetClient(timezone)}`
                                    );
                                    setRoomActionSelected(item);
                                    isOpen();
                                    setMode("cleaning-form");
                                  }}
                                >
                                  <span className="mr-1">
                                    <Icon
                                      icon={iconUrl.clockPlay}
                                      width={"1rem"}
                                    />
                                  </span>
                                  <span>End Clean</span>
                                </button>
                              )
                            )}
                          </div>
                        )
                      }
                    >
                      <div className="card-hms-list">
                        <div className="col-5 px-0 grey-text">ID</div>
                        <div className="col pr-0 f12-vw black-value">
                          {item?.id}
                        </div>
                      </div>
                      <div className="card-hms-list">
                        <div className="col-5 px-0 grey-text">Tanggal</div>
                        <div className="col pr-0 f12-vw black-value">
                          {getServerTimeWithoutDays(item?.ts, timezone)}
                        </div>
                      </div>
                      <div className="card-hms-list">
                        <div className="col-5 px-0 grey-text">Lantai</div>
                        <div className="col pr-0 f12-vw black-value">
                          {item?.floor}
                        </div>
                      </div>
                      <div className="card-hms-list">
                        <div className="col-5 px-0 grey-text">Room Type</div>
                        <div className="col pr-0 f12-vw black-value">
                          {item?.room_type_name}
                        </div>
                      </div>
                      <div className="card-hms-list">
                        <div className="col-5 px-0 grey-text">Bed Type</div>
                        <div className="col pr-0 f12-vw black-value">
                          {item?.bed_type_name}
                        </div>
                      </div>
                      <div className="card-hms-list">
                        <div className="col-5 px-0 grey-text">Bed Size</div>
                        <div className="col pr-0 f12-vw black-value">
                          {item?.bed_size}
                        </div>
                      </div>
                      <div className="card-hms-list">
                        <div className="col-5 px-0 grey-text">
                          Start Cleaning
                        </div>
                        <div className="col pr-0 f12-vw black-value">
                          {item?.start_cleaning ? (
                            <>
                              <span>
                                {getServerTimeWithoutDays(
                                  item?.start_cleaning,
                                  timezone
                                )}
                              </span>
                              <span className="fw-700">
                                {`, ${getClientTimeWithoutSecond(
                                  item?.start_cleaning,
                                  timezone
                                )}
                                ${getTimezoneOffsetClient(timezone)}`}
                              </span>
                            </>
                          ) : (
                            "-"
                          )}
                        </div>
                      </div>
                      <div className="card-hms-list">
                        <div className="col-5 px-0 grey-text">End Cleaning</div>
                        <div className="col pr-0 f12-vw black-value">
                          {item?.end_cleaning ? (
                            <>
                              <span>
                                {getServerTimeWithoutDays(
                                  item?.end_cleaning,
                                  timezone
                                )}
                              </span>

                              <span className="fw-700">
                                {`, ${getClientTimeWithoutSecond(
                                  item?.end_cleaning,
                                  timezone
                                )}
                                ${getTimezoneOffsetClient(timezone)}`}
                              </span>
                            </>
                          ) : (
                            "-"
                          )}
                        </div>
                      </div>
                      <div className="card-hms-list">
                        <div className="col-5 px-0 grey-text">Room Status</div>
                        <div className="col pr-0 f12-vw black-value">
                          <div
                            className={`text-center ${
                              filterStatusRoomCustom(item?.room_status)
                                ?.className
                            }`}
                            style={{
                              borderRadius: 3,
                              width: "3rem",
                              fontWeight: "bold",
                            }}
                          >
                            {filterStatusRoomCustom(item?.room_status)?.label}
                          </div>
                        </div>
                      </div>
                      <div className="card-hms-list">
                        <div className="col-5 px-0 grey-text">
                          Housekeeping Status
                        </div>
                        <div className="col pr-0 f12-vw black-value">
                          <div
                            className={`text-center ${
                              filterHousekeepingStatus(item?.hk_status)
                                ?.className
                            }`}
                            style={{
                              borderRadius: 3,
                              width: "3rem",
                              fontWeight: "bold",
                            }}
                          >
                            {filterHousekeepingStatus(item?.hk_status)?.label}
                          </div>
                        </div>
                      </div>
                      <div className="card-hms-list">
                        <div className="col-5 px-0 grey-text">Last Update</div>
                        <div className="col pr-0 f12-vw black-value">
                          {item?.last_update ? (
                            <>
                              <span>
                                {getServerTimeWithoutDays(
                                  item?.last_update,
                                  timezone
                                )}
                              </span>
                              <span className="fw-700">
                                {`, ${getClientTimeWithoutSecond(
                                  item?.last_update,
                                  timezone
                                )}
                                ${getTimezoneOffsetClient(timezone)}`}
                              </span>
                            </>
                          ) : (
                            "-"
                          )}
                        </div>
                      </div>
                    </MobileCardList>
                  </div>
                );
              })
            ) : (
              <div style={{ position: "relative" }}>
                <MobileEmptyData
                  errMessage={errorList}
                  loading={loadingList}
                  height="40vh"
                  text="Semua kamar sudah dibersihkan"
                  sourceImg={"/img/housekeeping/room-action.svg"}
                />
              </div>
            )}
          </div>
        </div>
      ) : (
        <div className="d-flex flex-column px-3 py-1">
          <div className="col">
            <div className="d-flex flex-row justify-content-between">
              <div className="col-md-5 px-0">
                <div className="d-flex flex-row">
                  <div className="col px-0 mr-2">
                    <label htmlFor="" className="f8-vw m-0 p-0 fw-600">
                      Status Housekeeping
                    </label>
                    <SelectOpt
                      placeholder="Pilih Status Housekeeping"
                      optionsValue={housekeepingStatus}
                      value={hkStatusSelected}
                      onChange={(val) => handleSelectHkStatus(val)}
                      formatOptionLabel={CustomOptionLabel}
                      clear={true}
                    />
                  </div>
                  <div className="col-4 px-0 mr-2">
                    <label htmlFor="" className="f8-vw m-0 p-0 fw-600">
                      Nomor Kamar
                    </label>
                    {searchInput(
                      "Cari Nomor Kamar",
                      roomNumber,
                      (e) => handleRoomNumber(e),
                      handleEnter
                    )}
                  </div>
                </div>
              </div>
              <div className="col-md-2 pr-0 pl-5 mt-auto">
                <div className="d-flex flex-column">
                  <button
                    className="btn btn-sm search-new rounded-input btn-block mt-auto f8-vw fw-500 text-white"
                    onClick={() => getRoomActions()}
                    style={{
                      paddingTop: 7,
                      paddingBottom: 7,
                      backgroundColor: "#742B9E",
                    }}
                  >
                    Search
                  </button>
                </div>
              </div>
            </div>
            <div className="d-flex flex-column my-2">
              <TableRoomAction
                list={roomActions}
                loading={loadingList}
                errorMessage={errorList}
                isOpen={isOpen}
                setMode={setMode}
                setActionEnd={setActionEnd}
                setRoomActionSelected={setRoomActionSelected}
                loadingActionList={loadingActionList}
                errorActionList={errorActionList}
                setActionStart={setActionStart}
                height="47vh"
                endClean={() => {
                  isOpen();
                  setMode("cleaning-form");
                }}
              />
              <div className="mt-4">
                <FootnoteHousekeeping data={housekeepingStatus} />
              </div>
            </div>
          </div>
        </div>
      )}

      {IS_MOBILE && (
        <div>
          <BottomSheet
            open={openButtonSheet}
            onDismiss={() => setOpenButtonSheet(false)}
            snapPoints={({ minHeight }) => minHeight}
            header={
              <div className="d-flex justify-content-between">
                <span className="purple f16-vw fw-600 px-2">Filter</span>
                <span
                  className="px-2"
                  onClick={() => setOpenButtonSheet(false)}
                >
                  <Icon icon={iconUrl.closeBold} color={"grey"} width={20} />
                </span>
              </div>
            }
            footer={
              <div className="d-flex justify-content-between">
                <div className="col-6 pl-2">
                  <button
                    className="btn btn-block btn-outline-purple f12-vw"
                    onClick={() => {
                      resetFilter();
                      setOpenButtonSheet(false);
                    }}
                  >
                    Reset
                  </button>
                </div>
                <div className="col-6 pr-2">
                  <button
                    className="btn btn-block btn-gradient f12-vw text-white"
                    onClick={() => {
                      setRoomActions([]);
                      getRoomActions();
                      setOpenButtonSheet(false);
                    }}
                  >
                    Search
                  </button>
                </div>
              </div>
            }
          >
            <div className="mt-3" style={{ height: "18rem" }}>
              <div className="px-4 mt-3">
                <label htmlFor="" className="f12-vw m-0 p-0 fw-600">
                  Status Housekeeping
                </label>
                <SelectOpt
                  placeholder="Pilih Status Housekeeping"
                  optionsValue={housekeepingStatus}
                  value={hkStatusSelected}
                  onChange={(val) => handleSelectHkStatus(val)}
                  formatOptionLabel={CustomOptionLabel}
                  clear={true}
                  fontSize="calc(8pt + 0.472vw)"
                  menuHeight="10rem"
                />
              </div>
              <div className="px-4 mt-3">
                <label htmlFor="" className="f12-vw m-0 p-0 fw-600">
                  Nomor Kamar
                </label>
                {mobileSearchInput("Cari Nomor Kamar", roomNumber, (e) =>
                  handleRoomNumber(e)
                )}
              </div>
            </div>
          </BottomSheet>
        </div>
      )}
    </>
  );
};

export default RoomAction;
