import { Icon } from "@iconify/react";
import React from "react";
import { HeaderName } from "../../../common/HeaderName";
import { IS_MOBILE } from "../../../common/Responsive";
import { iconUrl } from "../../../config";
import { getServerTimeWithoutDays, showMoreGuest } from "../../../utils";

const FONT_SIZE = IS_MOBILE ? "f12-vw" : "f8-vw";
const FONT_SIZE_HEADER = IS_MOBILE ? "f14-vw fw-700" : "f10-vw fw-700";
export const ConfirmChangeRoom = ({
  detail,
  roomSelected,
  reason,
  roomNumber,
  confirm,
  close,
  requestSelected,
  loading,
}) => {
  const detailInfo = (title, value) => {
    return (
      <div className={"d-flex flex-row mt-1 " + FONT_SIZE}>
        <div className="col-5 px-0 grey">{title}</div>
        <div className="col px-0 fw-600">: {value ? value : "-"}</div>
      </div>
    );
  };
  return (
    <>
      {IS_MOBILE ? (
        <div
          className="d-flex flex-column py-2 px-4"
          style={{ width: "21rem" }}
        >
          <div className="col px-0 py-1">
            <div className="d-flex align-items-center justify-content-start">
              <Icon icon={iconUrl.exchangeFour} color="orange" width={25} />
              <div className="fw-600 mx-2">
                <HeaderName
                  text="Change Room"
                  fontSize="f16-vw"
                  color1="blue-title"
                  color2="blue-title"
                />
              </div>
            </div>
          </div>
          <div className="col px-0">
            <hr className="separator-blue my-2" />
          </div>
          <div className="col px-0">
            <div className={FONT_SIZE}>
              <div className="fw-500 grey">Kode Booking</div>
              <div className="fw-600">{detail?.book_id}</div>
            </div>
          </div>
          <div
            className="px-0 mt-2 scroll-popup hide-scrollbar"
            style={{ height: "22rem" }}
          >
            <div className="fw-600">
              <HeaderName
                text="Informasi Booking"
                fontSize={FONT_SIZE_HEADER}
                color2="purple"
              />
            </div>
            {detailInfo("Nama Tamu", detail?.guest_name)}
            {detailInfo(
              "Check In",
              getServerTimeWithoutDays(detail?.checkin_date)
            )}
            {detailInfo(
              "Check Out",
              getServerTimeWithoutDays(detail?.checkout_date)
            )}
            {detailInfo("Tipe Kamar", detail?.room_type_name)}
            {detailInfo("Nomor Kamar", detail?.room_number)}

            <div className="d-flex flex-column mt-2">
              <HeaderName
                text="New Room Info"
                fontSize={FONT_SIZE_HEADER}
                color2="purple"
              />
              <div className="col px-0">
                {detailInfo("Request by", requestSelected?.label)}
                {detailInfo("Tipe Kamar", roomSelected?.label)}
                {detailInfo("Nomor Kamar", roomNumber?.join(","))}
                <div className={"d-flex flex-column mt-1 " + FONT_SIZE}>
                  <div className="col px-0 grey">Reason</div>
                  <div className="col px-0 fw-600 mt-2">
                    <div
                      style={{
                        flexWrap: "wrap",
                        width: "18rem",
                        overflowWrap: "break-word",
                      }}
                    >
                      {reason ? reason : "-"}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col px-0">
            <hr className="separator-blue my-2" />
          </div>
          <div className="col px-0">
            <div className="text-center">
              <small className="f10-vw">
                Apakah Anda yakin ingin melanjutkan?
              </small>
            </div>
          </div>

          <div className="col px-0">
            <div className="d-flex justify-content-between my-2">
              <div className="col-5 px-0">
                <button
                  className={
                    "btn btn-sm btn-block btn-outline-purple " + FONT_SIZE
                  }
                  onClick={() => {
                    close();
                  }}
                >
                  Back
                </button>
              </div>
              <div className="col-5 px-0">
                <button
                  className={`btn btn-sm btn-block text-white ${FONT_SIZE} ${
                    reason !== "" && roomNumber?.length > 0 && !loading
                      ? "bg-green"
                      : "btn-secondary"
                  }`}
                  disabled={
                    reason !== "" && roomNumber?.length > 0 && !loading
                      ? false
                      : true
                  }
                  onClick={() => confirm()}
                >
                  Confirm
                </button>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div
          className="d-flex flex-column py-3 px-4"
          style={{ width: "35rem" }}
        >
          <div className="col px-0 py-1">
            <div className="d-flex align-items-center justify-content-start">
              <Icon icon={iconUrl.exchangeFour} color="orange" width={25} />
              <div className="fw-600 mx-2">
                <HeaderName
                  text="Change Room Confirmation"
                  fontSize="f14-vw"
                  color1="blue-title"
                  color2="blue-title"
                />
              </div>
            </div>
          </div>
          <div className="col px-0">
            <hr className="separator-blue my-2" />
          </div>

          <div className={FONT_SIZE}>
            <div className="fw-500 grey">Kode Booking</div>
            <div className="fw-600">{detail?.book_id}</div>
          </div>
          <div className="d-flex flex-row mt-2">
            <div className="col pl-0 pr-2">
              <div className="fw-600">
                <HeaderName
                  text="Informasi Booking"
                  fontSize={FONT_SIZE_HEADER}
                  color2="purple"
                />
              </div>
              {detailInfo("Nama Tamu", detail?.guest_name)}
              {detailInfo(
                "Check In",
                getServerTimeWithoutDays(detail?.checkin_date)
              )}
              {detailInfo(
                "Check Out",
                getServerTimeWithoutDays(detail?.checkout_date)
              )}
              {detailInfo("Tipe Kamar", detail?.room_type_name)}
              {detailInfo("Nomor Kamar", detail?.room_number)}
            </div>
            <div className="col pr-0 pl-2">
              <HeaderName
                text="New Room Info"
                fontSize={FONT_SIZE_HEADER}
                color2="purple"
              />

              {detailInfo("Request by", requestSelected?.label)}
              {detailInfo("Tipe Kamar", roomSelected?.label)}
              {detailInfo("Nomor Kamar", roomNumber?.join(","))}
              <div className={"d-flex flex-column mt-1 " + FONT_SIZE}>
                <div className="col-5 px-0 grey">Reason</div>
                <div className="col px-0 fw-600 mt-2">
                  {reason ? showMoreGuest(reason, 60) : "-"}
                </div>
              </div>
            </div>
          </div>

          <div className="col px-0">
            <hr className="separator-blue my-2" />
          </div>
          <div className="col px-0">
            <div className="text-center">
              <small className={IS_MOBILE ? "f10-vw" : "f8-vw"}>
                Apakah Anda yakin ingin melanjutkan?
              </small>
            </div>
          </div>

          <div className="col px-0">
            <div className="d-flex justify-content-between my-2">
              <div className="col-3 px-0">
                <button
                  className={
                    "btn btn-sm btn-block btn-outline-purple " + FONT_SIZE
                  }
                  onClick={() => {
                    close();
                  }}
                >
                  Back
                </button>
              </div>
              <div className="col-3 px-0">
                <button
                  className={`btn btn-sm btn-block text-white ${FONT_SIZE} ${
                    reason !== "" && roomNumber?.length > 0 && !loading
                      ? "bg-green"
                      : "btn-secondary"
                  }`}
                  disabled={
                    reason !== "" && roomNumber?.length > 0 && !loading
                      ? false
                      : true
                  }
                  onClick={() => confirm()}
                >
                  Confirm
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
