import { Icon } from "@iconify/react";
import React from "react";
import { DateCheckin } from "../../../common/DateCheckin";
import { HeaderName } from "../../../common/HeaderName";
import { iconUrl } from "../../../config";
import {
  addDays,
  filterGuestType,
  filterPurpose,
  getDayDuration,
  getServerTimeWithoutDays,
  parsePhone,
  showMoreGuest,
} from "../../../utils";
import { IS_MOBILE } from "../../../common/Responsive";

const FONT_SIZE = IS_MOBILE ? "f12-vw" : "f8-vw";
const FONT_SIZE_TITLE = IS_MOBILE ? "f14-vw" : "f10-vw";

export const Reschedule = ({
  item,
  backButton,
  formEdit,
  setFormEdit,
  nextButton,
}) => {
  const {
    ota_booking_id,
    ota_name,
    ota_booking_date,
    booked_by,
    phone,
    email,
    checkin_date,
    checkout_date,
    room_type_name,
    room_booked,
    guest_qty,
    guest_type_id,
    purpose_id,
  } = item;

  const { checkin, checkout } = formEdit;

  const renderLabel = (title, value) => {
    return (
      <div className={`d-flex align-items-center ${FONT_SIZE} fw-500 mt-2`}>
        <div className="col-6 px-0 grey">{title}</div>
        <div className="col-6 px-0">: {value || "-"}</div>
      </div>
    );
  };

  const handleCheckIn = (val) => {
    setFormEdit({
      ...formEdit,
      checkin: val,
      checkout: addDays(val, getDayDuration(checkin_date, checkout_date)),
    });
  };
  return (
    <div
      className="d-flex flex-column px-2 py-3"
      style={{ width: IS_MOBILE ? "21rem" : "40rem" }}
    >
      <div className="col">
        <div className="d-flex align-items-center">
          <span className="mr-2">
            <Icon icon={iconUrl.editIcon} color="orange" width={25} />
          </span>
          <span className={`${FONT_SIZE_TITLE} purple fw-700`}>Reschedule</span>
        </div>
        <hr className="separator-grey my-2" />
      </div>
      <div className="col-6">
        <div className="">
          <div className="form-group my-0">
            <label
              htmlFor="type-room"
              className={`${FONT_SIZE} m-0 purple fw-700`}
            >
              Kode Booking
            </label>
            <div className={FONT_SIZE}>{ota_booking_id || "-"}</div>
          </div>
        </div>
      </div>
      <div className="col">
        <div
          className={`d-flex flex-column flex-md-row my-2 my-md-3 ${IS_MOBILE &&
            "scroll-popup hide-scrollbar"}`}
          style={{ maxHeight: "21rem" }}
        >
          <div className="col-md-6 col-12 pl-0 pr-0 pl-md-0 pr-md-3">
            <div className="">
              <HeaderName
                text={"Informasi Tamu"}
                fontSize={`${FONT_SIZE_TITLE} fw-600`}
                color2="purple"
              />
            </div>
            {renderLabel("Nama OTA", ota_name)}
            {renderLabel(
              "Tanggal Booking",
              getServerTimeWithoutDays(ota_booking_date)
            )}
            {renderLabel("Nama Pemesan", showMoreGuest(booked_by))}
            {renderLabel("Nomor Ponsel", parsePhone(phone))}
            {renderLabel("Email", showMoreGuest(email))}
          </div>
          <div className="col-md-6 col-12 pl-0 pr-0 pl-md-3 pr-md-0">
            <div className="">
              <HeaderName
                text={"Informasi Pemesanan"}
                fontSize={`${FONT_SIZE_TITLE} fw-600`}
                color2="purple"
              />
            </div>
            <div
              className="form-group"
              style={{ marginTop: 6, marginBottom: 0 }}
            >
              <div className="mb-0 d-flex flex-row">
                <div className={`col-6 pl-0 pr-2 ${FONT_SIZE}`}>Check in</div>
                <div className={`col-6 pr-0 pl-2 ${FONT_SIZE}`}>Check out</div>
              </div>
              <DateCheckin
                start={checkin}
                handleStart={handleCheckIn}
                disable={false}
                end={checkout}
                minDate={new Date()}
                fontSize={FONT_SIZE}
                border="2px solid #5f0593"
                disableEnd
              />
            </div>
            {renderLabel(
              "Durasi",
              getDayDuration(checkin_date, checkout_date) + " Malam"
            )}
            {renderLabel("Tipe Kamar", room_type_name)}
            {renderLabel(
              "Tamu & Kamar",
              !!guest_qty && room_booked
                ? guest_qty + " Tamu, " + room_booked + " Kamar"
                : "-"
            )}
            {renderLabel("Purpose", filterPurpose(purpose_id)?.label)}
            {renderLabel("Guest Type", filterGuestType(guest_type_id)?.label)}
          </div>
        </div>
        <hr className="separator-grey" />
        <div className={`text-center mb-3 ${FONT_SIZE}`}>
          Apakah anda yakin ingin melanjutkan?
        </div>
      </div>
      <div className="col">
        <div className="d-flex justify-content-between">
          <div className="col-md-3 col-4 px-0">
            <button
              className={`btn btn-block btn-sm ${FONT_SIZE} btn-outline-purple`}
              onClick={() => backButton()}
            >
              Back
            </button>
          </div>
          <div className="col-md-3 col-4 px-0">
            <button
              className={`btn btn-block btn-sm ${FONT_SIZE} text-white  ${
                getServerTimeWithoutDays(formEdit?.checkin) !==
                getServerTimeWithoutDays(checkin_date)
                  ? "btn-gradient"
                  : "btn-secondary"
              }`}
              onClick={() => nextButton()}
              disabled={
                getServerTimeWithoutDays(formEdit?.checkin) !==
                getServerTimeWithoutDays(checkin_date)
                  ? false
                  : true
              }
            >
              Next
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
