import { Icon } from "@iconify/react";
import React from "react";
import ReactDatePicker from "react-datepicker";
import { iconUrl } from "../config";
import { addDays } from "../utils";
import { IS_MOBILE } from "./Responsive";

export const DateCheckin = ({
  start,
  end,
  handleEnd,
  handleStart,
  fontSize = "f8-vw",
  disable = true,
  border = "1px solid #ced4da",
  disableEnd = false,
  minDate = null,
}) => {
  const handleCalendarOpen = () => {
    document.addEventListener(
      "touchstart",
      (event) => {
        event.stopPropagation();
      },
      true
    );
  };
  return (
    <div className="d-flex justify-content-between">
      <div className="col pl-0 pr-1">
        <div
          className="form-control form-control-sm d-flex align-items-center justify-content-center"
          style={{
            padding: disable ? "4px 0" : "3px 0",
            borderRadius: 5,
            backgroundColor: !disable ? "white" : "#ececec",
            border,
            ...(disable && { pointerEvents: "none" }),
          }}
        >
          <Icon icon={iconUrl.calendarIcon} color="#ef9403" width={18} />
          {/* {disable ? (
            <span className={"mx-2 fw-600 p-0 w-100 " + fontSize}>
              {getServerTimeWithoutDays(start)}
            </span>
          ) : ( */}
          <div className={`px-0 ${IS_MOBILE ? "w-75" : ""}`}>
            <ReactDatePicker
              selected={start}
              onChange={handleStart}
              selectsStart
              startDate={start}
              endDate={end}
              className={"date-checkin-new fw-600 text-center p-0 " + fontSize}
              dateFormat="dd MMM yyyy"
              withPortal={IS_MOBILE ? true : false}
              onCalendarOpen={handleCalendarOpen}
              onChangeRaw={(e) => {
                e.preventDefault();
              }}
              onKeyDown={(e) => {
                e.preventDefault();
              }}
              onFocus={(e) => e.target.blur()}
              minDate={minDate}
              disabled={disable}
            />
          </div>
          {/* )} */}
        </div>
      </div>
      <div className="col pl-1 pr-0">
        <div
          className="form-control form-control-sm d-flex justify-content-center align-items-center"
          style={{
            border,
            borderRadius: 5,
            padding: disableEnd ? "4px 0" : "3px 0",
            backgroundColor: !disableEnd ? "white" : "#ececec",
            ...(disableEnd && { pointerEvents: "none" }),
          }}
        >
          <Icon icon={iconUrl.calendarIcon} color="#ef9403" width={18} />
          {/* {disableEnd ? (
            <span className={"mx-2 fw-600 " + fontSize}>
              {getServerTimeWithoutDays(end)}
            </span>
          ) : ( */}
          <div className={`px-0 ${IS_MOBILE ? "w-75" : ""}`}>
            <ReactDatePicker
              selected={end}
              onChange={handleEnd}
              selectsEnd
              startDate={start}
              endDate={end}
              minDate={addDays(start, 1)}
              className={"date-checkin-new fw-600 text-center p-0 " + fontSize}
              dateFormat="dd MMM yyyy"
              withPortal={IS_MOBILE ? true : false}
              onCalendarOpen={handleCalendarOpen}
              onChangeRaw={(e) => {
                e.preventDefault();
              }}
              onKeyDown={(e) => {
                e.preventDefault();
              }}
              onFocus={(e) => e.target.blur()}
              disabled={disableEnd}
            />
          </div>
          {/* )} */}
        </div>
      </div>
    </div>
  );
};
