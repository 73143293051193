import { Icon } from "@iconify/react";
import React, { useEffect } from "react";
import { HeaderName } from "../../../common/HeaderName";
import { iconUrl } from "../../../config";
import {
  filterGuestType,
  filterPurpose,
  getDayDuration,
  getServerTimeWithoutDays,
  parsePhone,
  priceSplit,
  showMore,
  showMoreGuest,
} from "../../../utils";

export const DetailCheckinPopup = ({
  detail,
  close,
  confirm,
  roomNumber,
  guestName,
  deposit,
  earlyCheckin,
  totalCharge,
  roomPrice,
  loading,
  setIsOpen,
  isOpen,
}) => {
  const detailInformation = (title, value) => {
    return (
      <div className="d-flex flex-row mt-1">
        <div className="col px-0">
          <span className="grey f8-vw">{title}</span>
        </div>
        <div className="col px-0">
          <span className="text-black f8-vw fw-500">: {value}</span>
        </div>
      </div>
    );
  };
  const detailPayment = (title, value, color, tax, onClick) => {
    return (
      <div className="d-flex flex-row f8-vw">
        <div className="col px-0">
          <span className={` ${color ? color : "text-black"}`}>{title}</span>
        </div>
        <div className="col px-0">
          <div className="d-flex flex-row justify-content-between">
            <div className={` ${color ? color : "text-black"}`}>Rp</div>
            {onClick ? (
              <div
                className="col ml-1 px-0 text-right text-black "
                style={{
                  cursor: "pointer",
                  backgroundColor: "#EFE5F4",
                  borderRadius: "5px",
                  fontWeight: "bold",
                }}
                onClick={onClick}
              >
                <div className="d-flex justify-content-between">
                  <span className="px-2">
                    <Icon icon={iconUrl?.infoCircle} />
                  </span>
                  <span className="pr-2">{priceSplit(value)}</span>
                </div>
              </div>
            ) : (
              <>
                <div
                  className={`col px-0 text-right  ${
                    color ? "f8-vw font-weight-bold " + color : "text-black"
                  }`}
                >
                  <span className="pr-2">{priceSplit(value)}</span>
                </div>
              </>
            )}
          </div>
          {color && tax && value > 0 && (
            <div className="px-2" style={{ position: "relative" }}>
              <small
                className="f4-vw grey"
                style={{ position: "absolute", top: 0, right: 5 }}
              >
                Sudah termasuk pajak
              </small>
            </div>
          )}
        </div>
      </div>
    );
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      setIsOpen(false);
    }, 60000);
    return () => {
      clearTimeout(timeout);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);
  return (
    <div className="d-flex flex-column p-3" style={{ width: "40rem" }}>
      <div className="col">
        <div className="blue-title fw-600 f12-vw">
          <span className="mr-2">
            <Icon
              icon={iconUrl.formMultiple20Filled}
              color="orange"
              width={25}
            />
          </span>
          Konfirmasi Check In
        </div>
        <hr className="separator-blue my-2" />
      </div>
      <div className="col px-0">
        <div className="d-flex flex-row">
          <div className="col">
            <div className="">
              <HeaderName
                text="Informasi Booking"
                fontSize="f10-vw fw-600"
                color2="purple"
              />
            </div>
            <div className="my-1">
              {detailInformation("Nama OTA", detail?.ota_name)}
              {detailInformation(
                "Nama Pemesan",
                showMoreGuest(detail?.booked_by)
              )}
              {detailInformation("Nomor Ponsel", parsePhone(detail?.phone))}
              {detailInformation(
                "Email",
                detail?.email ? showMoreGuest(detail?.email) : "-"
              )}
              {detailInformation("Tipe Kamar", detail?.room_type_name)}
              {detailInformation(
                "Tamu & Kamar",
                detail?.guest_qty + " Tamu, " + detail?.room_booked + " Kamar"
              )}
              {detailInformation(
                "Check In",
                getServerTimeWithoutDays(detail?.checkin_date)
              )}
              {detailInformation(
                "Check Out",
                getServerTimeWithoutDays(detail?.checkout_date)
              )}
              {detailInformation(
                "Durasi",
                getDayDuration(detail?.checkin_date, detail?.checkout_date) +
                  " Malam"
              )}
              {detailInformation(
                "Purpose",
                filterPurpose(detail?.purpose_id)?.label
              )}
              {detailInformation(
                "Guest Type",
                filterGuestType(detail?.guest_type_id)?.label
              )}
            </div>
          </div>
          <div className="col">
            <div className="">
              <HeaderName
                text="Informasi Checkin"
                fontSize="f10-vw fw-600"
                color2="purple"
              />
            </div>
            <div className="my-1">
              {detailInformation("Nomor Kamar", roomNumber?.join(","))}
              {detailInformation("Nama Tamu", showMoreGuest(guestName))}
            </div>
            <div className="my-2">
              <div className="my-2">
                <HeaderName
                  text="Informasi Pembayaran"
                  fontSize="f10-vw fw-600"
                  color2="purple"
                />
              </div>
              <div className="my-1">
                {detailPayment("Total Harga Kamar", roomPrice)}
                {detailPayment("Early Check In", parseInt(earlyCheckin))}

                <div className="">
                  <hr className="separator-purple my-2" />
                </div>
                {detailPayment(
                  "Total Tagihan",
                  parseInt(totalCharge),
                  "fw-700",
                  true
                )}
                <div className="mt-3">
                  {detailPayment(
                    "Deposit (Cash)",
                    parseInt(deposit),
                    "fw-700",
                    false
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="px-3">
          <hr className="separator-grey my-2" />
          <div className="text-center my-3 f8-vw">
            Periksa kembali detail Pemesanan anda, klik confirm untuk
            melanjutkan
          </div>
        </div>
      </div>
      <div className="col">
        <div className="d-flex justify-content-between ">
          <div className="col-2 px-0">
            <button
              className="btn btn-sm btn-block btn-outline-purple f8-vw"
              onClick={() => close()}
            >
              Back
            </button>
          </div>
          <div className="col-2 px-0">
            <button
              className={`btn btn-sm btn-block text-white f8-vw ${
                loading ? "btn-secondary" : "btn-gradient"
              }`}
              onClick={() => confirm()}
              disabled={loading ? true : false}
            >
              Confirm
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export const MobileDetailCheckinPopup = ({
  detail,
  close,
  confirm,
  roomNumber,
  guestName,
  deposit,
  earlyCheckin,
  totalCharge,
  roomPrice,
  loading,
  setIsOpen,
  isOpen,
}) => {
  const detailInformation = (title, value) => {
    return (
      <div className="d-flex flex-row mt-1">
        <div className="col px-0">
          <span className="grey f12-vw">{title}</span>
        </div>
        <div className="col px-0">
          <span className="text-black f12-vw fw-500">: {value}</span>
        </div>
      </div>
    );
  };
  const detailPayment = (title, value, color, tax, onClick) => {
    return (
      <div className="d-flex flex-row f12-vw">
        <div className="col px-0">
          <span className={` ${color ? color : "text-black"}`}>{title}</span>
        </div>
        <div className="col px-0">
          <div className="d-flex flex-row justify-content-between">
            <div className={` ${color ? color : "text-black"}`}>Rp</div>
            {onClick ? (
              <div
                className="col ml-1 px-0 text-right text-black "
                style={{
                  cursor: "pointer",
                  backgroundColor: "#EFE5F4",
                  borderRadius: "5px",
                  fontWeight: "bold",
                }}
                onClick={onClick}
              >
                <div className="d-flex justify-content-between">
                  <span className="px-2">
                    <Icon icon={iconUrl?.infoCircle} />
                  </span>
                  <span className="pr-2">{priceSplit(value)}</span>
                </div>
              </div>
            ) : (
              <>
                <div
                  className={`col px-0 text-right  ${
                    color ? "f12-vw font-weight-bold " + color : "text-black"
                  }`}
                >
                  <span className="pr-2">{priceSplit(value)}</span>
                </div>
              </>
            )}
          </div>
          {color && tax && value > 0 && (
            <div className="px-2" style={{ position: "relative" }}>
              <small
                className="f4-vw grey"
                style={{ position: "absolute", top: 0, right: 5 }}
              >
                Sudah termasuk pajak
              </small>
            </div>
          )}
        </div>
      </div>
    );
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      setIsOpen(false);
    }, 60000);
    return () => {
      clearTimeout(timeout);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);
  return (
    <div className="py-3" style={{ width: "21rem" }}>
      <div className="px-4">
        <div className="blue-title fw-600 f14-vw">
          <span className="mr-2">
            <Icon
              icon={iconUrl.formMultiple20Filled}
              color="orange"
              width={25}
            />
          </span>
          Konfirmasi Check In
        </div>

        <hr className="separator-blue my-2 mx-1" />
      </div>
      <div
        className="d-flex flex-column scroll-popup hide-scrollbar px-3"
        style={{ height: "25rem" }}
      >
        <div className="col">
          <div className="">
            <HeaderName
              text="Informasi Booking"
              fontSize="f14-vw fw-600"
              color2="purple"
            />
          </div>
          <div className="my-1">
            {detailInformation("Nama OTA", detail?.ota_name)}
            {detailInformation("Nama Pemesan", showMore(detail?.booked_by))}
            {detailInformation("Nomor Ponsel", parsePhone(detail?.phone))}
            {detailInformation(
              "Email",
              detail?.email ? showMore(detail?.email) : "-"
            )}
            {detailInformation("Tipe Kamar", detail?.room_type_name)}
            {detailInformation(
              "Tamu & Kamar",
              detail?.guest_qty + " Tamu, " + detail?.room_booked + " Kamar"
            )}
            {detailInformation(
              "Check In",
              getServerTimeWithoutDays(detail?.checkin_date)
            )}
            {detailInformation(
              "Check Out",
              getServerTimeWithoutDays(detail?.checkout_date)
            )}
            {detailInformation(
              "Durasi",
              getDayDuration(detail?.checkin_date, detail?.checkout_date) +
                " Malam"
            )}
            {detailInformation(
              "Purpose",
              filterPurpose(detail?.purpose_id)?.label
            )}
            {detailInformation(
              "Guest Type",
              filterGuestType(detail?.guest_type_id)?.label
            )}
          </div>
        </div>
        <div className="col">
          <div className="mt-2">
            <HeaderName
              text="Informasi Checkin"
              fontSize="f14-vw fw-600"
              color2="purple"
            />
          </div>
          <div className="my-1">
            {detailInformation("Nomor Kamar", roomNumber?.join(","))}
            {detailInformation("Nama Tamu", showMore(guestName))}
          </div>
          <div className="my-2">
            <div className="mt-2">
              <HeaderName
                text="Informasi Pembayaran"
                fontSize="f14-vw fw-600"
                color2="purple"
              />
            </div>
            <div className="my-1">
              {detailPayment("Total Harga Kamar", roomPrice)}
              {detailPayment("Early Check In", parseInt(earlyCheckin))}

              <div className="">
                <hr className="separator-purple my-2" />
              </div>
              {detailPayment(
                "Total Tagihan",
                parseInt(totalCharge),
                "fw-700",
                true
              )}
              <div className="mt-3">
                {detailPayment(
                  "Deposit (Cash)",
                  parseInt(deposit),
                  "fw-700",
                  false
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="px-4">
        <hr className="separator-grey my-2 mx-1" />
        <div className="d-flex align-items-center flex-column my-2 px-3">
          <span className="f10-vw">Periksa kembali detail Pemesanan anda,</span>
          <span className="f10-vw">Klik confirm untuk melanjutkan</span>
        </div>
      </div>
      <div className="d-flex justify-content-between mt-3 px-4 mx-1">
        <div className="col-6 pl-0">
          <button
            className="btn btn-sm btn-block btn-outline-purple f12-vw"
            onClick={() => close()}
          >
            Back
          </button>
        </div>
        <div className="col-6 pr-0">
          <button
            className={`btn btn-sm btn-block text-white f12-vw ${
              loading ? "btn-secondary" : "btn-gradient"
            }`}
            onClick={() => confirm()}
            disabled={loading ? true : false}
          >
            Confirm
          </button>
        </div>
      </div>
    </div>
  );
};
