import { Icon } from "@iconify/react";
import React, { useEffect, useState } from "react";
import { HeaderName } from "../../../common/HeaderName";
import { IS_MOBILE } from "../../../common/Responsive";
import SelectOpt from "../../../common/SelectOpt";
import { iconUrl } from "../../../config";
import { priceSplit } from "../../../utils";
import { isEmpty } from "../../../utils/index";

export const PaymentPopup = ({
  close,
  paymentMethod,
  paymentSelected,
  bankList,
  bankSelected,
  roomCharge,
  earlyCheckin,
  deposit,
  setBankSelected,
  setpaymentSelected,
  totalCharge,
  payment,
  charge,
  onClick,
  setPayment,
  setCharge,
  totalPaid,
  setTotalPaid,
  voucher_no,
  voucher_type,
  realCharge,
  discount,
  additionalCharge,
  processType,
}) => {
  const [tempMethod, setTempMethod] = useState([]);
  const handlePaymentMethod = (val) => {
    setpaymentSelected(val);
    setBankSelected([]);
    setPayment(0);
  };
  const handlePayment = (val) => {
    setPayment(Number(val.target.value.replace(/\D/g, "")));
  };
  const handleBankSelected = (val) => {
    setBankSelected(val);
  };
  const detailPayment = (title, value, color, tax) => {
    return (
      <div className="d-flex flex-row f8-vw my-1 py-1">
        <div className="col-6 px-0">
          <span className={` ${color ? color : "text-black"}`}>{title}</span>
        </div>
        <div className="col px-0">
          <div className="d-flex flex-row justify-content-between">
            <div className={`${color ? color : "text-black"} text-black`}>
              Rp
            </div>
            <div
              className={`col px-0 text-right  ${color ? color : "text-black"}`}
            >
              <span className="px-2">{value}</span>
            </div>
          </div>
          {tax && value > 0 && (
            <div className="px-2" style={{ position: "relative" }}>
              <small
                className="f4-vw grey"
                style={{ position: "absolute", top: -3, right: 5 }}
              >
                Sudah termasuk pajak
              </small>
            </div>
          )}
        </div>
      </div>
    );
  };
  const mobileDetailPayment = (title, value, color, tax) => {
    return (
      <div className="d-flex flex-row f12-vw my-1 py-1">
        <div className="col-6 px-0">
          <span className={` ${color ? color : "text-black"}`}>{title}</span>
        </div>
        <div className="col px-0">
          <div className="d-flex flex-row justify-content-between">
            <div className={`${color ? color : "text-black"} text-black`}>
              Rp
            </div>
            <div
              className={`col px-0 text-right  ${color ? color : "text-black"}`}
            >
              <span className="px-2">{value}</span>
            </div>
          </div>
          {tax && value > 0 && (
            <div className="px-2" style={{ position: "relative" }}>
              <small
                className="f7-vw grey"
                style={{ position: "absolute", top: -3, right: 5 }}
              >
                Sudah termasuk pajak
              </small>
            </div>
          )}
        </div>
      </div>
    );
  };
  const paymentInput = (title, value, onChange, autoFocus) => {
    return (
      <div className="d-flex flex-row align-items-center f8-vw">
        <div className="col-6 px-0">
          <span className={`font-weight-bold text-black`}>{title}</span>
        </div>
        <div className="col px-0">
          <div className="d-flex flex-row align-items-center justify-content-between">
            <div className={`font-weight-bold text-black`}>Rp</div>
            <div
              className={`col px-0 font-weight-bold text-black ml-2`}
              style={{ border: "1px solid lightgray", borderRadius: 5 }}
            >
              <input
                type="text"
                className="form-control text-right border-0 form-control-sm f8-vw font-weight-bold"
                value={priceSplit(value)}
                style={{ textAlign: "right" }}
                autoFocus={autoFocus}
                onChange={onChange}
                onFocus={
                  value > 0
                    ? (e) => (e.target.value = priceSplit(value))
                    : (e) => (e.target.value = "")
                }
                onBlur={(e) => (e.target.value = priceSplit(value))}
                maxLength={12}
              />
            </div>
          </div>
        </div>
      </div>
    );
  };
  const mobilePaymentInput = (title, value, onChange, autoFocus) => {
    return (
      <div className="d-flex flex-row align-items-center f12-vw">
        <div className="col-6 px-0">
          <span className={`font-weight-bold text-black`}>{title}</span>
        </div>
        <div className="col px-0">
          <div className="d-flex flex-row align-items-center justify-content-between">
            <div className={`font-weight-bold text-black`}>Rp</div>
            <div
              className={`col px-0 font-weight-bold text-black ml-2`}
              style={{ border: "1px solid lightgray", borderRadius: 5 }}
            >
              <input
                type="text"
                className="form-control text-right border-0 form-control-sm f12-vw font-weight-bold"
                value={priceSplit(value)}
                style={{ textAlign: "right" }}
                autoFocus={autoFocus}
                onChange={onChange}
                onFocus={(e) => (e.target.value = "")}
                maxLength={12}
                inputMode="numeric"
              />
            </div>
          </div>
        </div>
      </div>
    );
  };

  useEffect(() => {
    if (roomCharge === 0 && earlyCheckin === 0 && additionalCharge === 0) {
      let d = paymentMethod.filter((item) => item.value === 3);
      setTempMethod(d);
    } else {
      setTempMethod(paymentMethod);
    }
  }, [earlyCheckin, paymentMethod, roomCharge, additionalCharge]);

  useEffect(() => {
    if (isEmpty(paymentSelected) || paymentSelected?.value === 3) {
      let d = totalCharge + deposit;
      setTotalPaid(d);
    } else {
      setTotalPaid(totalCharge);
    }
  }, [
    deposit,
    paymentSelected,
    paymentSelected?.value,
    setTotalPaid,
    totalCharge,
  ]);

  useEffect(() => {
    if (paymentSelected?.value === 3 && payment >= totalPaid) {
      setCharge(Math.abs(parseInt(totalPaid) - parseInt(payment)));
    } else {
      setCharge(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentSelected, totalPaid, payment]);

  return (
    <>
      {IS_MOBILE ? (
        <div
          className="d-flex flex-column px-2 pb-3 pt-2"
          style={{ width: "21rem" }}
        >
          <div className="col px-0 py-1">
            <div className="d-flex align-items-center justify-content-between">
              <div className="col">
                <div className="d-flex align-items-center justify-content-start">
                  <Icon
                    icon={iconUrl.walletCreditCard20Filled}
                    color="orange"
                    width={25}
                  />
                  <div className="fw-600 mx-2">
                    <HeaderName
                      text="Pembayaran"
                      fontSize="f14-vw fw-700"
                      color1="blue-title"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="col px-0"
            style={{
              backgroundColor: "white",
              borderRadius: "5px",
            }}
          >
            <div className="d-flex flex-column my-1">
              <div className="col">
                <div className="">
                  <label
                    htmlFor="payment-method"
                    className="f12-vw mb-2 fw-600"
                  >
                    Metode Pembayaran
                  </label>
                  <SelectOpt
                    placeholder="Pilih metode pembayaran"
                    optionsValue={tempMethod}
                    onChange={handlePaymentMethod}
                    value={paymentSelected}
                    fontSize="calc(8pt + 0.472vw)"
                  />
                </div>
              </div>
              {paymentSelected.value === 2 && (
                <div className="col">
                  <div className="mt-2">
                    <label
                      htmlFor="payment-method"
                      className="f12-vw mb-2 fw-600"
                    >
                      Pilih Bank
                    </label>
                    <SelectOpt
                      placeholder="Pilih bank"
                      optionsValue={bankList}
                      onChange={handleBankSelected}
                      value={bankSelected}
                      fontSize="calc(8pt + 0.472vw)"
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
          <div
            className="col px-0 my-1"
            style={{
              backgroundColor: "white",
              borderRadius: "5px",
            }}
          >
            <div className="d-flex flex-column my-1">
              <div className="col">
                <HeaderName
                  text="Informasi Pembayaran"
                  fontSize="f14-vw fw-600"
                  color2="purple"
                />
              </div>
              <div className="col">
                {!!voucher_no && (
                  <div>
                    <div className="f12-vw">Kode Voucher</div>
                    <div className="f12-vw blue">{voucher_no}</div>
                  </div>
                )}
                {mobileDetailPayment(
                  "Total Harga Kamar",
                  voucher_type === 1 && processType === 12
                    ? priceSplit(roomCharge)
                    : priceSplit(realCharge)
                )}
                {processType === 12 &&
                  mobileDetailPayment(
                    "Additional Charge",
                    priceSplit(additionalCharge)
                  )}
                {processType !== 12 &&
                  mobileDetailPayment(
                    "Early Check In",
                    priceSplit(earlyCheckin)
                  )}
                {processType !== 12 &&
                (isEmpty(paymentSelected) || paymentSelected.value === 3)
                  ? mobileDetailPayment("Deposit", priceSplit(deposit))
                  : null}

                {discount > 0 &&
                  mobileDetailPayment("Diskon", "-" + priceSplit(discount))}
                <hr className="separator-blue my-2" />
                {mobileDetailPayment(
                  "Total Tagihan",
                  priceSplit(totalPaid),
                  "fw-700",
                  true,
                  true
                )}
                {paymentSelected.value === 3 && (
                  <hr className="separator-blue my-2" />
                )}
                {paymentSelected.value === 2 &&
                  processType !== 12 &&
                  mobileDetailPayment(
                    "Deposit (Cash)",
                    priceSplit(deposit),
                    "fw-700"
                  )}
                {paymentSelected.value === 3 && (
                  <>
                    {mobilePaymentInput("Pembayaran Tunai", payment, (e) =>
                      handlePayment(e)
                    )}
                    {mobileDetailPayment(
                      "Kembalian",
                      charge,
                      "text-black fw-700",
                      false
                    )}
                  </>
                )}
              </div>
              <div className="col">
                <hr className="separator-blue my-1" />
              </div>
            </div>
          </div>
          <div className="col">
            <div className="text-center mb-2">
              <small className="f10-vw fw-500">
                Apakah anda yakin ingin melanjutkan?
              </small>
            </div>
          </div>
          <div className="col px-0">
            <div className="d-flex justify-content-between">
              <div className="col">
                <button
                  className={`btn btn-block f12-vw font-weight-bold btn-outline-purple`}
                  onClick={close}
                >
                  Back
                </button>
              </div>
              <div className="col">
                <button
                  className={`btn btn-block f12-vw font-weight-bold text-white ${
                    (paymentSelected?.value === 2 && bankSelected?.value) ||
                    (paymentSelected?.value === 3 && payment >= totalPaid)
                      ? "btn-gradient"
                      : "btn-secondary"
                  }`}
                  onClick={onClick}
                  disabled={
                    (paymentSelected?.value === 2 && bankSelected?.value) ||
                    (paymentSelected?.value === 3 && payment >= totalPaid)
                      ? false
                      : true
                  }
                >
                  Confirm
                </button>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div
          className="d-flex flex-column px-3 py-3"
          style={{ width: "22rem" }}
        >
          <div className="col px-0 py-1">
            <div className="d-flex align-items-center justify-content-between">
              <div className="col">
                <div className="d-flex align-items-center justify-content-start">
                  <Icon
                    icon={iconUrl.walletCreditCard20Filled}
                    color="orange"
                    width={25}
                  />
                  <div className="fw-600 mx-2">
                    <HeaderName
                      text="Pembayaran"
                      fontSize="f12-vw"
                      color1="blue-title"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="col px-0"
            style={{
              backgroundColor: "white",
              borderRadius: "5px",
            }}
          >
            <div className="d-flex flex-column mt-2">
              <div className="col">
                <div className="">
                  <label htmlFor="payment-method" className="f8-vw mb-2 fw-600">
                    Metode Pembayaran
                  </label>
                  <SelectOpt
                    placeholder="Pilih metode pembayaran"
                    optionsValue={tempMethod}
                    onChange={handlePaymentMethod}
                    value={paymentSelected}
                  />
                </div>
              </div>
              {paymentSelected.value === 2 && (
                <div className="col">
                  <div className="mt-2">
                    <label
                      htmlFor="payment-method"
                      className="f8-vw mb-2 fw-600"
                    >
                      Pilih Bank
                    </label>
                    <SelectOpt
                      placeholder="Pilih bank"
                      optionsValue={bankList}
                      onChange={handleBankSelected}
                      value={bankSelected}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
          <div
            className="col px-0 mt-2"
            style={{
              backgroundColor: "white",
              borderRadius: "5px",
            }}
          >
            <div className="d-flex flex-column my-2">
              <div className="col">
                <HeaderName
                  text="Informasi Pembayaran"
                  fontSize="f10-vw fw-600"
                  color2="purple"
                />
              </div>
              <div className="col">
                {!!voucher_no && (
                  <div>
                    <div className="f8-vw">Kode Voucher</div>
                    <div className="f8-vw blue">{voucher_no}</div>
                  </div>
                )}
                {detailPayment(
                  "Total Harga Kamar",
                  voucher_type === 1 || processType === 12
                    ? priceSplit(roomCharge)
                    : priceSplit(realCharge)
                )}
                {processType === 12 &&
                  detailPayment(
                    "Additional Charge",
                    priceSplit(additionalCharge)
                  )}
                {processType !== 12 &&
                  detailPayment("Early Check In", priceSplit(earlyCheckin))}
                {processType !== 12 &&
                (isEmpty(paymentSelected) || paymentSelected.value === 3)
                  ? detailPayment("Deposit", priceSplit(deposit))
                  : null}
                {discount > 0 &&
                  detailPayment("Diskon", "-" + priceSplit(discount))}
                <hr className="separator-blue my-2" />
                {detailPayment(
                  "Total Tagihan",
                  priceSplit(totalPaid),
                  "fw-700",
                  true,
                  true
                )}
                {paymentSelected.value === 3 && (
                  <hr className="separator-blue my-2" />
                )}
                {paymentSelected.value === 2 &&
                  processType !== 12 &&
                  detailPayment(
                    "Deposit (Cash)",
                    priceSplit(deposit),
                    "fw-700"
                  )}
                {paymentSelected.value === 3 && (
                  <>
                    {paymentInput("Pembayaran Tunai", payment, (e) =>
                      handlePayment(e)
                    )}
                    {detailPayment(
                      "Kembalian",
                      priceSplit(charge),
                      "text-black fw-700",
                      false
                    )}
                  </>
                )}
              </div>
              <div className="col">
                <hr className="separator-blue my-1" />
              </div>
            </div>
          </div>
          <div className="col">
            <div className="text-center mb-3">
              <small className="f8-vw fw-500">
                Apakah anda yakin ingin melanjutkan?
              </small>
            </div>
          </div>
          <div className="col px-0">
            <div className="d-flex justify-content-between">
              <div className="col pl-1">
                <button
                  className={`btn btn-block f8-vw font-weight-bold btn-outline-purple`}
                  onClick={close}
                >
                  Back
                </button>
              </div>
              <div className="col pr-1">
                <button
                  className={`btn btn-block f8-vw font-weight-bold text-white ${
                    (paymentSelected?.value === 2 && bankSelected?.value) ||
                    (paymentSelected?.value === 3 && payment >= totalPaid)
                      ? "btn-gradient"
                      : "btn-secondary"
                  }`}
                  onClick={onClick}
                  disabled={
                    (paymentSelected?.value === 2 && bankSelected?.value) ||
                    (paymentSelected?.value === 3 && payment >= totalPaid)
                      ? false
                      : true
                  }
                >
                  Confirm
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
