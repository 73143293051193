import { Icon } from "@iconify/react";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { BottomSheet } from "react-spring-bottom-sheet";
import { BreadcrumbContext } from "../../../common/BreadcrumbContext";
import { MobileCardList } from "../../../common/CardList";
import { t } from "../../../common/ErrorMessage";
import NotifMessage, {
  ButtonClose,
  ButtonConfirm,
} from "../../../common/Notif";
import PopupModal from "../../../common/PopupModal";
import { IS_MOBILE } from "../../../common/Responsive";
import { iconUrl } from "../../../config";
import {
  createLineDash,
  createLineSolid,
  genBookingId,
  genContent1,
  genContent2,
  genPaymentID,
} from "../../../print/common/printer-helper";
import { Print } from "../../../print/Print";
import {
  getClientTimeWithoutSecond,
  getDayDuration,
  getServerTime,
  getServerTimeWithoutDays,
  getTimezoneOffsetClient,
  isEmpty,
  priceSplit,
} from "../../../utils";
import {
  addData,
  getData,
  getErr,
  safeErrCode,
} from "../../../utils/api-request";
import history from "../../../utils/history";
import { isValidAccess, is_sub_menu_action } from "../../../utils/uac";
import { MobileEmptyData } from "../../Empty/EmptyData";
import { mobileSearchInput } from "../bookingHelper";
import { PaymentPopup } from "./PaymentPopup";
import { PendingBookingList } from "./PendingBookingList";

const PendingBooking = () => {
  const {
    setpathLocation,
    isOpeningShift,
    userProfile,
    openButtonSheet,
    setOpenButtonSheet,
    setShowFilter,
  } = useContext(BreadcrumbContext);
  const [pendingBookingList, setPendingBookingList] = useState([]);
  const [bookedBy, setBookedBy] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [errorSubmit, setErrorSubmit] = useState("");
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [mode, setMode] = useState("");
  const [headerPopup, setHeaderPopup] = useState("");
  const [imgPopup, setImgPopup] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState([]);
  const [paymentSelected, setPaymentSelected] = useState([]);
  const [bankList, setBankList] = useState([]);
  const [bankSelected, setBankSelected] = useState([]);
  const [detailBooking, setDetailBooking] = useState({});
  const [change, setChange] = useState(0);
  const [totalPaid, setTotalPaid] = useState(0);
  const [payment, setPayment] = useState(0);
  const [totalCharge, setTotalCharge] = useState(0);
  const [isPrint, setIsPrint] = useState(false);
  const [paymentId, setPaymentId] = useState("");
  const [dueDate, setDueDate] = useState("");
  const timezone = localStorage.getItem("timezone");
  const hotel_info = JSON.parse(localStorage.getItem("info_hotel"));

  const tableHeader = [
    "Kode Booking",
    "Pemesan",
    "Check In",
    "Check Out",
    "Tipe Kamar",
    "Nomor Kamar",
    "Action",
  ];

  const openModal = () => {
    setIsOpen(!isOpen);
  };

  const resetPopupPayment = () => {
    setIsOpen(false);
    setBankSelected([]);
    setPaymentSelected([]);
    setPayment(0);
    setTotalPaid(0);
    setChange(0);
    setDetailBooking({});
    setTotalCharge(0);
    setMode("");
    setHeaderPopup("");
    setImgPopup("");
    setPendingBookingList([]);
    setIsPrint(false);
    getPendingList();
  };

  const resetFilter = () => {
    setBookedBy("");
  };

  const handleEnter = (e) => {
    if (e.key === "Enter") {
      getPendingList();
    }
  };

  const getPendingList = useCallback(() => {
    if (!isOpeningShift) {
      const params = {
        ...(!!bookedBy && { guest_name: bookedBy }),
      };
      let paramsFormatted = null;

      if (!isEmpty(params)) {
        paramsFormatted = new URLSearchParams(params);
      }
      const endpoint = `/api/v1/list_pending_booking${
        paramsFormatted !== null ? `?${paramsFormatted}` : ""
      }`;
      setPendingBookingList([]);
      setLoading(true);
      getData(endpoint)
        .then((resp) => {
          setLoading(false);
          if (resp.message === "Success") {
            setPendingBookingList(resp.data);
          } else {
            resp.json().then((err) => {
              setError(t(safeErrCode(err)));
            });
          }
        })
        .catch((error) => {
          let err = getErr(error);
          setError(t(err));
          setLoading(false);
          console.error(t(err));
        });
    }
  }, [bookedBy, isOpeningShift]);

  const makePayment = () => {
    const formData = new FormData();
    const payload = {
      billing_ids: detailBooking?.billing_id,
      booking_id: detailBooking?.booking_id,
      paymenttype: paymentSelected?.value,
      payment_receive: paymentSelected?.value === 2 ? totalCharge : payment,
      real_room_charge: detailBooking.real_room_charge,
      ...(!!bankSelected?.bank_name && { bank_name: bankSelected.bank_name }),
      ...(!!bankSelected?.bank_accno && {
        bankaccno: bankSelected.bank_accno,
      }),
      ...(!!detailBooking?.voucher_no && {
        voucher_no: detailBooking?.voucher_no,
      }),
    };
    for (const key in payload) {
      formData.append(key, payload[key]);
    }
    // for (const key in payload) {
    //   console.log(key, payload[key]);
    // }
    addData("/api/v1/makepayment_pending", formData)
      .then((resp) => {
        if (resp.message === "Success" && resp.payment_id) {
          setMode("confirm-success");
          setHeaderPopup("Pembayaran Berhasil");
          setImgPopup("/img/icon-shift/confirm-close.svg");
          setPaymentId(resp?.payment_id);
          setDueDate(resp.due_date);
          setIsPrint(true);
        } else {
          resp.json().then((err) => {
            setError(t(safeErrCode(err)));
          });
          setHeaderPopup("Pembayaran Gagal");
          setMode("payment-error");
          setImgPopup("/img/error-icon/booking-failed.svg");
        }
      })
      .catch((error) => {
        let err = getErr(error);
        setLoading(false);
        setError(t(err));
        console.log(error);
        setMode("payment-error");
        setHeaderPopup("Pembayaran Gagal");
        setImgPopup("/img/error-icon/booking-failed.svg");
      });
  };

  const cancelBooking = (id) => {
    const payload = {
      booking_uid: id,
    };
    setLoadingSubmit(true);
    addData("/api/v1/order/cancel_bookthencheckin", payload)
      .then((resp) => {
        setLoadingSubmit(false);
        if (resp.message) {
          setMode("cancel-success");
          setHeaderPopup("Cancel Booking Berhasil");
          setImgPopup("/img/icon-shift/confirm-close.svg");
        } else {
          resp.json().then((err) => {
            setErrorSubmit(t(safeErrCode(err)));
            setMode("error");
            setHeaderPopup("Cancel Gagal");
            setImgPopup("/img/error-icon/booking-failed.svg");
          });
        }
      })
      .catch((error) => {
        setLoadingSubmit(false);
        let err = getErr(error);
        setErrorSubmit(t(err));
        console.log(t(err));
        setMode("error");
        setHeaderPopup("Cancel Gagal");
        setImgPopup("/img/error-icon/booking-failed.svg");
      });
  };

  const getPaymentMethod = useCallback(() => {
    if (isOpen && mode === "payment-process") {
      getData("/api/v1/listpaymentmethod")
        .then((resp) => {
          if (resp.data) {
            let d = resp.data.map((item) => {
              return {
                value: item.payment_type,
                label: item.payment_name,
              };
            });
            setPaymentMethod(d);
          } else {
            resp.json().then((err) => {
              setError(t(safeErrCode(err)));
            });
            setPaymentMethod([]);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [isOpen, mode]);

  const getPaymentType = useCallback(() => {
    if (paymentSelected?.value === 2) {
      getData(`/api/v1/paymentype?payment_type=${paymentSelected.value}`)
        .then((resp) => {
          if (resp.data) {
            let d = resp.data.map((item) => {
              return {
                value: item.bank_id,
                label: `${item.bank_name} ( ${item.bank_accno} )`,
                bank_name: item.bank_name,
                bank_accno: item.bank_accno,
                bank_accholder: item.bank_accholder,
              };
            });
            setBankList(d);
          } else {
            resp.json().then((err) => {
              setError(t(safeErrCode(err)));
            });
            setBankList([]);
          }
        })
        .catch((error) => {
          let err = getErr(error);
          setError(t(err));
        });
    }
  }, [paymentSelected]);

  useEffect(() => {
    getPaymentMethod();
    return () => {
      setPaymentMethod([]);
    };
  }, [getPaymentMethod]);

  useEffect(() => {
    getPaymentType();
    return () => {
      setBankList([]);
    };
  }, [getPaymentType]);

  useEffect(() => {
    if (bookedBy === "") {
      getPendingList();
    }
  }, [getPendingList, bookedBy]);

  useEffect(() => {
    if (isPrint) {
      setTimeout(() => {
        setIsPrint(false);
      }, 6000);
    }
  }, [isPrint]);

  useEffect(() => {
    setpathLocation(history.location.pathname);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history.location.pathname]);

  useEffect(() => {
    setShowFilter(true);
    return () => {
      setShowFilter(false);
    };
  }, [setShowFilter]);

  return (
    <>
      {IS_MOBILE ? (
        <div className="d-flex flex-column p-1 mb-5">
          <div className="col">
            {pendingBookingList?.length > 0 && !loading ? (
              pendingBookingList.map((item) => {
                return (
                  <MobileCardList
                    title={
                      <>
                        <div
                          className="f10-vw fw-500"
                          style={{ color: "#959595" }}
                        >
                          Kode Booking
                        </div>
                        <div>{item?.book_id}</div>
                      </>
                    }
                    key={item?.booking_id}
                    fontSizeHeader="f12-vw"
                    action_button={
                      isValidAccess([
                        "rv_room_pb_process",
                        "rv_room_pb_cancel",
                      ]) && (
                        <div className="dropdown combo-box-mobile col px-0">
                          <button
                            className="btn btn-sm btn-gradient text-white dropdown-toggle border-0 btn-block f12-vw fw-600"
                            type="button"
                            data-toggle="dropdown"
                            aria-expanded="false"
                          >
                            Action
                            <span className="ml-2">
                              <Icon icon={iconUrl.arrowDownAlt2} width={15} />
                            </span>
                          </button>
                          <div className="dropdown-menu dropdown-menu-center">
                            {is_sub_menu_action("rv_room_pb_process") && (
                              <button
                                className="menu border-0 purple bg-transparent f12-vw font-weight-bold btn-block m-0"
                                onClick={() => {
                                  setMode("payment-process");
                                  openModal();
                                  setDetailBooking(item);
                                }}
                              >
                                Process
                              </button>
                            )}

                            {is_sub_menu_action("rv_room_pb_cancel") && (
                              <button
                                className="menu border-0 purple bg-transparent f12-vw font-weight-bold btn-block m-0"
                                onClick={() => {
                                  setMode("cancel-booking");
                                  openModal();
                                  setDetailBooking(item);
                                }}
                              >
                                Cancel
                              </button>
                            )}
                          </div>
                        </div>
                      )
                    }
                  >
                    <div className="card-hms-list">
                      <div className="col-4 px-0 grey-text">Nama Tamu</div>
                      <div className="col pr-0 f12-vw black-value">
                        {item?.guest_name}
                      </div>
                    </div>
                    <div className="card-hms-list">
                      <div className="col-4 px-0 grey-text">Nomor Kamar</div>
                      <div className="col pr-0 f12-vw black-value">
                        {item?.room_number}
                      </div>
                    </div>
                    <div className="card-hms-list">
                      <div className="col-4 px-0 grey-text">Tipe Kamar</div>
                      <div className="col pr-0 f12-vw black-value">
                        {item?.room_type_name}
                      </div>
                    </div>
                    <div className="card-hms-list">
                      <div className="col-4 px-0 grey-text">Check In</div>
                      <div className="col pr-0 f12-vw black-value">
                        {getServerTimeWithoutDays(item?.checkin_date, timezone)}
                      </div>
                    </div>
                    <div className="card-hms-list">
                      <div className="col-4 px-0 grey-text">Check Out</div>
                      <div className="col pr-0 f12-vw black-value">
                        {getServerTimeWithoutDays(
                          item?.checkout_date,
                          timezone
                        )}
                      </div>
                    </div>
                  </MobileCardList>
                );
              })
            ) : (
              <div style={{ position: "relative" }}>
                <MobileEmptyData
                  errMessage={error}
                  loading={loading}
                  height="40vh"
                />
              </div>
            )}
          </div>
        </div>
      ) : (
        <div
          className={`check-out-page bg-white my-1 ${
            !isOpeningShift ? "" : "not-opening"
          }`}
        >
          <PendingBookingList
            guestName={bookedBy}
            setGuestName={setBookedBy}
            loadingList={loading}
            errorList={error}
            list={pendingBookingList}
            tableHeader={tableHeader}
            setMode={setMode}
            openModal={openModal}
            setDetail={setDetailBooking}
            onKeyPress={(e) => handleEnter(e)}
          />
        </div>
      )}

      {IS_MOBILE && (
        <div>
          <BottomSheet
            open={openButtonSheet}
            onDismiss={() => setOpenButtonSheet(false)}
            snapPoints={({ minHeight }) => minHeight}
            header={
              <div className="d-flex justify-content-between">
                <span className="purple f16-vw fw-600 px-2">Filter</span>
                <span
                  className="px-2"
                  onClick={() => setOpenButtonSheet(false)}
                >
                  <Icon icon={iconUrl.closeBold} color={"grey"} width={20} />
                </span>
              </div>
            }
            footer={
              <div className="d-flex justify-content-between">
                <div className="col-6 pl-2">
                  <button
                    className="btn btn-block btn-outline-purple f12-vw"
                    onClick={() => {
                      resetFilter();
                      setOpenButtonSheet(false);
                    }}
                  >
                    Reset
                  </button>
                </div>
                <div className="col-6 pr-2">
                  <button
                    className="btn btn-block btn-gradient f12-vw text-white"
                    onClick={() => {
                      setPendingBookingList([]);
                      setOpenButtonSheet(false);
                      getPendingList();
                    }}
                  >
                    Search
                  </button>
                </div>
              </div>
            }
          >
            <div className="mt-3" style={{ height: "17rem" }}>
              <div className="px-4 mt-2">
                <label htmlFor="" className="f12-vw m-0 p-0 fw-600">
                  Nama Tamu
                </label>
                {mobileSearchInput("Cari Nama Tamu", bookedBy, (e) =>
                  setBookedBy(e.target.value)
                )}
              </div>
            </div>
          </BottomSheet>
        </div>
      )}

      <PopupModal modalIsOpen={isOpen}>
        {mode === "payment-process" ? (
          <PaymentPopup
            paymentMethod={paymentMethod}
            paymentSelected={paymentSelected}
            setpaymentSelected={setPaymentSelected}
            bankList={bankList}
            bankSelected={bankSelected}
            setBankSelected={setBankSelected}
            detail={detailBooking}
            setCharge={setChange}
            charge={change}
            setTotalPaid={setTotalPaid}
            totalPaid={totalPaid}
            setPayment={setPayment}
            payment={payment}
            close={() => resetPopupPayment()}
            setTotalCharge={setTotalCharge}
            totalCharge={totalCharge}
            onClick={() => makePayment()}
          />
        ) : mode === "confirm-success" ? (
          <>
            <NotifMessage
              closeModal={openModal}
              img={imgPopup}
              messageHeader={
                paymentSelected.value === 3
                  ? "Checkin & Pembayaran Berhasil"
                  : "Silahkan Konfirmasi Pembayaran"
              }
              color="black"
              messageBody={
                paymentSelected.value === 3
                  ? "Pembayaran Berhasil"
                  : "Klik OK untuk menuju ke menu Konfirmasi"
              }
            />
            <ButtonClose
              close={
                paymentSelected.value === 2
                  ? () => {
                      openModal();
                      history.push("/reservasi/payment-confirm");
                    }
                  : () => {
                      resetPopupPayment();
                    }
              }
              color="bg-green"
              text="OK"
            />
          </>
        ) : mode === "cancel-booking" ? (
          <>
            <NotifMessage
              closeModal={openModal}
              img={"/img/error-icon/booking-failed.svg"}
              messageHeader={"Cancel Booking"}
              color="red"
              messageBody={"Apakah anda yakin ingin Cancel Booking?"}
            />
            <ButtonConfirm
              text={"Confirm"}
              secondButton={"Kembali"}
              close={() => resetPopupPayment()}
              confirm={() => cancelBooking(detailBooking?.booking_id)}
              disabled={loadingSubmit}
              loading={loadingSubmit}
            />
          </>
        ) : mode === "cancel-success" ? (
          <>
            <NotifMessage
              closeModal={openModal}
              img={imgPopup}
              messageHeader={headerPopup}
              color="black"
            />
            <ButtonClose
              close={() => {
                resetPopupPayment();
              }}
              text="OK"
            />
          </>
        ) : (
          <>
            <NotifMessage
              closeModal={openModal}
              img={imgPopup}
              messageHeader={headerPopup}
              color="red"
              messageBody={errorSubmit}
            />
            <ButtonClose
              close={() => {
                resetPopupPayment();
              }}
              text="OK"
            />
          </>
        )}
      </PopupModal>

      <div style={{ display: "none" }}>
        <Print launch_print={isPrint}>
          <>
            {paymentSelected?.value === 3 ? (
              <p className="p-print txt-print-h1">SLIP Pembayaran</p>
            ) : (
              <p className="p-print txt-print-h1">Billing</p>
            )}

            {genPaymentID(paymentId)}
            {createLineDash()}
            <p className="p-print txt-print-h3">{`${getServerTime(
              new Date(),
              timezone
            )} - ${getClientTimeWithoutSecond(
              new Date(),
              timezone
            )} ${getTimezoneOffsetClient(timezone)}`}</p>
            <p className="p-print txt-print-h3">{hotel_info?.hotel_name}</p>
            {createLineDash()}
            <table className="table-print">
              <tbody>
                {genContent1("Kasir", userProfile?.nama)}
                {/* {genContent1("Kode Booking", limitText(data?.booking_id))} */}
              </tbody>
            </table>
            {genBookingId("Booking ID", detailBooking?.book_id)}
            {createLineDash()}
            <p className="p-print txt-print-section-h2">Informasi Booking</p>
            <table className="table-print">
              <tbody>
                {genContent1(
                  "Check In",
                  getServerTimeWithoutDays(
                    detailBooking?.checkin_date,
                    timezone
                  )
                )}
                {genContent1(
                  "Check Out",
                  getServerTimeWithoutDays(
                    detailBooking?.checkout_date,
                    timezone
                  )
                )}
                {genContent1(
                  "Durasi",
                  getDayDuration(
                    detailBooking?.checkin_date,
                    detailBooking?.checkout_date
                  ) + " Malam"
                )}
                {genContent1("Nomor Kamar", detailBooking?.room_number)}
              </tbody>
            </table>
            {createLineDash()}
            <table className="table-print">
              <tbody>
                {genContent1("Metode Pembayaran", paymentSelected?.label)}
                {paymentSelected?.value === 2 && (
                  <>
                    {genContent1("Nama Bank", bankSelected?.label)}
                    {genContent1(
                      "Batas Pembayaran",
                      `${getClientTimeWithoutSecond(
                        dueDate,
                        timezone
                      )} ${getTimezoneOffsetClient(timezone)}`
                    )}
                  </>
                )}
              </tbody>
            </table>
            {createLineDash()}
            {paymentSelected?.value === 3 ? (
              <p className="p-print txt-print-section-h2">
                Informasi Pembayaran
              </p>
            ) : (
              <p className="p-print txt-print-section-h2">Informasi Tagihan</p>
            )}
            {detailBooking?.voucher_no !== ""
              ? genBookingId("Kode Voucher", detailBooking.voucher_no)
              : null}
            <table className="table-print mt-2">
              <tbody>
                {genContent2(
                  "Harga Kamar",
                  detailBooking?.voucher_type === 1
                    ? detailBooking.room_charge
                    : detailBooking.real_room_charge
                )}
                {genContent2("Early Checkin", detailBooking?.early_checkin)}
                {paymentSelected?.value === 3 &&
                  genContent2("Deposit", detailBooking?.deposit)}
                {detailBooking?.diskon > 0 &&
                  detailBooking?.voucher_type !== 1 && (
                    <tr className="txt-print-section">
                      <td width={100}>Diskon</td>
                      <td width={90} style={{ textAlign: "center" }}>
                        {"IDR"}
                      </td>
                      <td className={"txt-price"} width={60}>
                        -{priceSplit(detailBooking?.diskon)}
                      </td>
                    </tr>
                  )}
              </tbody>
            </table>
            {createLineSolid()}
            <table className="table-print">
              <tbody>
                {genContent2("Total Tagihan", totalPaid)}
                {paymentSelected?.value === 2 &&
                  genContent2("Deposit (Cash)", detailBooking?.deposit)}
                {paymentSelected?.value === 3 &&
                  genContent2("Pembayaran Tunai", payment)}
              </tbody>
            </table>
            {paymentSelected?.value === 3 && (
              <>
                {createLineSolid()}
                <table className="table-print">
                  <tbody>{genContent2("Kembalian", change)}</tbody>
                </table>
              </>
            )}
          </>
        </Print>
      </div>
    </>
  );
};

export default PendingBooking;
