import React from "react";
import { assignFacility, populateFacilites } from "../utils/facilityHelper";

export default function Facilities() {
  const hotel_facilities = JSON.parse(localStorage.getItem("hotel_facilities"));
  const facilityList = populateFacilites(hotel_facilities);
  const facilityShow = assignFacility(facilityList);

  return (
    <>
      {facilityShow.map((item, i) => {
        return (
          <div className="mr-2" key={i}>
            {item.iconName}
          </div>
        );
      })}
    </>
  );
}
