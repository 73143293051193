import { Icon } from "@iconify/react";
import React, { useEffect } from "react";
import ReactDatePicker from "react-datepicker";
import Count from "../../../common/Count";
import { DateCheckin } from "../../../common/DateCheckin";
import { HeaderName } from "../../../common/HeaderName";
import InputPhone from "../../../common/InputPhone";
import { MobileUploadFile } from "../../../common/MobileUploadFile";
import { IS_MOBILE } from "../../../common/Responsive";
import SelectOpt from "../../../common/SelectOpt";
import { iconUrl } from "../../../config";
import { addDays, priceSplit } from "../../../utils";
import { mobileInputContainer } from "../bookingHelper";

export const MobileOtherForm = ({
  otherForm,
  setotherForm,
  formError,
  setFormError,
  otherPlatform,
  otherSelected,
  setOtherSelected,
  typeRoom,
  roomSelected,
  setRoomSelected,
  purpose,
  purposeSelected,
  setPurposeSelected,
  guesttype,
  guestTypeSelected,
  setGuestTypeSelected,
  setfileUpload,
  fileUpload,
  setShowImg,
  showImg,
}) => {
  // UTILS
  const handleOtherOTA = (val) => {
    setOtherSelected(val);
  };

  const handleSelect = (value) => {
    setotherForm({ ...otherForm, roomType: value.value, roomNumber: [] });
    setRoomSelected(value);
  };

  const handleGuestName = (val) => {
    if (val.target.value) {
      let d = val?.target?.value.replace(
        /(^\w{1})|(\s+\w{1})|(,+\w{1})/g,
        (letter) => letter.toUpperCase()
      );
      let text = d.replace(", ", ",");
      let guest = text?.split(",");
      setotherForm({ ...otherForm, guestName: guest });
    } else {
      setotherForm({ ...otherForm, guestName: [] });
    }
  };

  const handleCheckIn = (value) => {
    setotherForm({ ...otherForm, checkin: value, checkout: addDays(value, 1) });
  };
  const handleCheckOut = (value) => {
    setotherForm({ ...otherForm, checkout: value });
  };
  const handleBookingDate = (value) => {
    setotherForm({ ...otherForm, booking_date: value });
  };

  const handleGuest = (val) => {
    setotherForm({ ...otherForm, guest: val });
  };
  const handleRoom = (val) => {
    setotherForm({ ...otherForm, room: val });
  };

  const handlePurpose = (val) => {
    setPurposeSelected(val);
  };
  const handleGuestType = (val) => {
    setGuestTypeSelected(val);
  };
  const handleCalendarOpen = () => {
    document.addEventListener(
      "touchstart",
      (event) => {
        event.stopPropagation();
      },
      true
    );
  };

  const mobilePaymentInput = (title, value, onChange, autoFocus) => {
    return (
      <div className="d-flex flex-column py-2">
        <div className="col px-0">
          <span className={`text-black f12-vw font-weight-bold`}>{title}</span>
        </div>
        <div className="col px-0">
          <div className="d-flex flex-row align-items-center">
            <div
              className={`col px-0 text-black `}
              style={{
                border: "1px solid #5f0593",
                borderRadius: 5,
                backgroundColor: "white",
                display: "flex",
                alignItems: "center",
              }}
            >
              <span className={`px-3 f12-vw fw-700`}>Rp</span>
              <input
                type="text"
                className="form-control text-right border-0 form-control-sm f12-vw font-weight-bold"
                value={priceSplit(value)}
                style={{ textAlign: "right", boxShadow: "none" }}
                onFocus={(e) => (e.target.value = "")}
                autoFocus={autoFocus}
                onChange={onChange}
                maxLength={12}
                inputMode="numeric"
              />
            </div>
          </div>
        </div>
      </div>
    );
  };

  const handleRoomCharge = (val) => {
    setotherForm({
      ...otherForm,
      totalcharge: Number(val.target.value.replace(/\D/g, "")),
    });
  };

  const validEmailInput = (val) => {
    const validEmail = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (val?.length < 1) {
      setFormError(null);
    } else if (!validEmail.test(val)) {
      setFormError("Format email salah");
    } else {
      return setFormError(null);
    }
  };
  const handlePhone = (value) => {
    setotherForm({ ...otherForm, phoneNumber: value });
  };
  const handleEmail = (val) => {
    setotherForm({ ...otherForm, email: val.target.value });
    validEmailInput(val.target.value);
  };

  useEffect(() => {
    setPurposeSelected(purpose[1]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    setGuestTypeSelected(guesttype[0]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <div className="d-flex flex-column">
        <div className="col px-0">
          <HeaderName text="Informasi Tamu" fontSize="f14-vw fw-700" />
        </div>
        <div className="col px-0">
          <div className="form-group mb-2">
            <label htmlFor="pemesan" className="f12-vw fw-700 m-0">
              <span className="red">* </span>Nama OTA
            </label>
            <SelectOpt
              optionsValue={otherPlatform}
              onChange={handleOtherOTA}
              placeholder={"Pilih OTA"}
              value={otherSelected}
              fontSize={"calc(8pt + 0.472vw)"}
            />
          </div>
        </div>
        <div className="col px-0">
          <div className="form-group mb-2">
            <label htmlFor="type-room" className="f12-vw fw-700 m-0">
              <span className="red">* </span>Kode Booking
            </label>
            <input
              type="text"
              placeholder={"Kode Booking"}
              className="form-control f12-vw rounded-input "
              value={otherForm.bookingCode}
              onChange={(e) =>
                setotherForm({
                  ...otherForm,
                  bookingCode: e.target.value.toUpperCase(),
                })
              }
            />
          </div>
        </div>
        <div className="col px-0">
          <label htmlFor="" className="f12-vw fw-700 m-0">
            <span className="red">* </span>Tanggal Booking
          </label>
          <div
            className="form-group mb-2"
            style={{
              border: "1px solid #ced4da",
              borderRadius: 5,
              padding: "4px 0",
            }}
          >
            <div className="d-flex px-2 f8-vw align-items-center ">
              <Icon icon={iconUrl.calendarIcon} color="#ef9403" width={15} />
              <div className="w-100">
                <ReactDatePicker
                  selected={otherForm?.booking_date}
                  onChange={(e) => handleBookingDate(e)}
                  className={"date-checkin-new f12-vw text-left"}
                  dateFormat="dd MMM yyyy"
                  placeholderText="Pilih Tanggal Booking"
                  maxDate={new Date()}
                  withPortal={IS_MOBILE ? true : false}
                  onFocus={(e) => e.target.blur()}
                  onCalendarOpen={handleCalendarOpen}
                  onKeyDown={(e) => {
                    e.preventDefault();
                  }}
                  onChangeRaw={(e) => {
                    e.preventDefault();
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="col px-0">
          <div className="form-group mb-2">
            <div className="d-flex flex-row">
              <div className="col-6 pl-0 pr-2">
                <label className="f12-vw fw-700 my-1">Purpose</label>
                <SelectOpt
                  optionsValue={purpose}
                  onChange={handlePurpose}
                  placeholder={"Pilih Tujuan Menginap"}
                  value={purposeSelected}
                  placement={true}
                  fontSize={"calc(8pt + 0.472vw)"}
                />
              </div>
              <div className="col-6 pr-0 pl-2">
                <label className="f12-vw fw-700 my-1">Guest Type</label>
                <SelectOpt
                  optionsValue={guesttype}
                  onChange={handleGuestType}
                  placeholder={"Pilih Guest Type"}
                  value={guestTypeSelected}
                  placement={true}
                  fontSize={"calc(8pt + 0.472vw)"}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="col px-0">
          {mobileInputContainer(
            "Nama Tamu",
            "Nama Lengkap",
            otherForm?.guestName,
            (e) => handleGuestName(e),
            true
          )}
        </div>
        <div className="col px-0">
          <div className="form-group mb-2">
            <label htmlFor="pemesan" className="f12-vw fw-700 m-0">
              <span className="red">* </span>Nomor Ponsel
            </label>
            <div className="group-input f8-vw grey">
              <InputPhone
                value={otherForm?.phoneNumber}
                setValue={handlePhone}
              />
            </div>
          </div>
        </div>
        <div className="col px-0">
          {mobileInputContainer(
            "Email",
            "Masukkan email",
            otherForm?.email,
            (e) => handleEmail(e),
            false,
            "email",
            formError,
            false
          )}
          <div className="invalid-feedback f6-vw">{formError}</div>
        </div>
        <div className="col px-0 mt-2">
          <HeaderName text="Informasi Pemesanan" fontSize="f14-vw fw-700" />
        </div>
        <div className="col px-0">
          <div className="date-header form-group mb-2">
            <div className="f12-vw fw-700 mt-1 d-flex flex-row">
              <span className="col-6 pl-0 pr-2">Check in</span>
              <span className="col-6 pr-0 pl-2">Check out</span>
            </div>
            <DateCheckin
              start={otherForm?.checkin}
              handleStart={handleCheckIn}
              disable={false}
              end={otherForm?.checkout}
              handleEnd={handleCheckOut}
              minDate={true}
              fontSize={"f12-vw"}
            />
          </div>
        </div>
        <div className="col px-0" style={{ marginTop: 2 }}>
          <div className="form-group mb-2">
            <label htmlFor="type-room" className="f12-vw fw-700 m-0">
              <span className="red">* </span>Tipe Kamar
            </label>
            <SelectOpt
              optionsValue={typeRoom}
              onChange={handleSelect}
              placeholder={"Pilih Tipe Kamar"}
              value={roomSelected}
              fontSize={"calc(8pt + 0.472vw)"}
            />
          </div>
        </div>
        <div className="col px-0">
          <div className="form-group mb-2">
            <label htmlFor="pemesan" className="f12-vw my-0 fw-700">
              <span className="red">* </span>Tamu dan Kamar
            </label>
            <div className="d-flex">
              <div className="col px-0 dropdown">
                <div
                  className="form-control form-control-sm dropdown-toggle rounded-input py-0 d-flex align-items-center"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <div className="d-flex justify-content-between">
                    <div className="">
                      <span className="f12-vw fw-500 ml-1">
                        {otherForm?.guest} Tamu,
                      </span>
                      <span className="f12-vw fw-500 mx-1">
                        {otherForm?.room} Kamar
                      </span>
                    </div>
                  </div>
                  <span style={{ position: "absolute", right: 10 }}>
                    <Icon
                      icon={iconUrl.arrowDownAlt2}
                      width={18}
                      color="#62148f"
                    />
                  </span>
                </div>
                <div
                  className="dropdown-menu py-0"
                  aria-labelledby="dropdownMenuButton"
                  style={{ width: "100%" }}
                >
                  <div className="d-flex flex-column">
                    <div className="col d-flex justify-content-between align-items-center">
                      <div className="f12-vw fw-600">Tamu</div>
                      <div className="d-flex align-items-center">
                        <Count
                          setValue={handleGuest}
                          count={otherForm?.guest}
                          fontSize={"f12-vw"}
                        />
                      </div>
                    </div>
                    <div className="col py-0 d-flex justify-content-between align-items-center">
                      <div className="f12-vw fw-600">Kamar</div>
                      <div className="d-flex align-items-center">
                        <Count
                          setValue={handleRoom}
                          count={otherForm?.room}
                          fontSize={"f12-vw"}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col px-0 mt-2">
          <HeaderName text="Bukti Pemesanan" fontSize="f14-vw fw-700" />
          <div className="mt-1">
            <MobileUploadFile
              setfileUpload={setfileUpload}
              title={"Upload Bukti Pemesanan"}
              height="150px"
              fileUpload={fileUpload}
              setShowImg={setShowImg}
              showImg={showImg}
              fontSize={"f12-vw"}
            />
          </div>
        </div>
        {mobilePaymentInput("Total Harga Kamar", otherForm.totalcharge, (e) =>
          handleRoomCharge(e)
        )}
      </div>
    </>
  );
};
