import i18n from "./i18n";

export function t(error) {
  let output = "Terdapat masalah pada sistem";

  if (i18n.exists(error)) {
    output = i18n.t(error);
  } else {
    output = i18n.t("DEFAULT_CODE", { err: error });
  }

  return output;
}
