import React, { useCallback, useRef, useState } from "react";
import Webcam from "react-webcam";
import Resizer from "react-image-file-resizer";

export const CameraWebcam = ({
  setfileUpload,
  title,
  height,
  showImg,
  setShowImg,
  fontSize = "f8-vw",
}) => {
  // const [showImg, setShowImg] = useState(null);
  const [isRetake, setIsRetake] = useState(false);
  const [isWebcam, setIsWebcam] = useState(false);
  const webcamRef = useRef(null);
  const inputRef = useRef(null);

  const handleOpenFileInput = useCallback(() => {
    setIsWebcam(false);
    setfileUpload([]);
    setShowImg(null);
    setTimeout(() => {
      inputRef.current.click();
    }, 500);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputRef]);

  const capture = useCallback(() => {
    const imageSrc = webcamRef.current.getScreenshot();
    setShowImg(imageSrc);
    setIsRetake(true);
    let indexData = new Date().getTime();
    const [, type] = imageSrc.split(";")[0].split("/");
    let mimeType = imageSrc.match(/[^:]\w+\/[\w-+\d.]+(?=;|,)/)[0];
    fetch(imageSrc)
      .then((res) => res.blob())
      .then((blob) => {
        let file = new File([blob], `image_${indexData}.${type}`, {
          type: mimeType,
        });
        setfileUpload([file]);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [webcamRef]);

  const videoConstraints = {
    width: 640,
    height: 480,
    facingMode: "user",
  };

  const resizeFile = (file) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        640,
        480,
        "png",
        50,
        0,
        (uri) => {
          resolve(uri);
        },
        "file"
      );
    });

  const handleFile = async (val) => {
    let file = val.target.files;
    if (file.length > 0) {
      let resize = await resizeFile(file[0]);
      setfileUpload([resize]);
      setShowImg(URL.createObjectURL(resize));
    }
  };

  const WebcamCapture = () => {
    return (
      <>
        <Webcam
          audio={false}
          ref={webcamRef}
          screenshotFormat="image/jpeg"
          style={{ width: "100%", height: height, objectFit: "contain" }}
          videoConstraints={videoConstraints}
        />
      </>
    );
  };

  const CustomUpload = ({ fileUpload, onChange, title }) => {
    return (
      <div>
        <div
          className=""
          style={{
            padding: 0,
          }}
          onClick={() => handleOpenFileInput()}
        >
          {fileUpload !== undefined &&
          fileUpload !== null &&
          fileUpload !== "" ? (
            <img
              src={fileUpload}
              alt="KTP"
              style={{ objectFit: "contain", height: height, width: "100%" }}
            />
          ) : (
            <div
              className="d-flex flex-column justify-content-center align-items-center px-0"
              style={{
                border: "2px dashed lightgray",
                height: height,
                borderRadius: 5,
                width: "100%",
                backgroundColor: "#ececec",
              }}
            >
              <div className="">
                <img src="/img/icon/icon-upload.svg" alt="" width={30} />
              </div>
              <div className={"blue fw-600 " + fontSize}>
                {title ? title : "Upload Foto"}
              </div>
            </div>
          )}
        </div>
        <input
          type="file"
          ref={inputRef}
          accept="image/*"
          onChange={(e) => onChange(e)}
          style={{
            display: "none",
          }}
        />
      </div>
    );
  };
  return (
    <div>
      {!isWebcam ? (
        <>
          <CustomUpload
            fileUpload={showImg}
            onChange={handleFile}
            title={title}
          />
        </>
      ) : (
        <>
          {isRetake && showImg !== null && showImg !== "" ? (
            <>
              <img
                src={showImg}
                alt="KTP"
                style={{ width: "100%", height: height, objectFit: "contain" }}
              />
            </>
          ) : (
            <>
              <WebcamCapture />
            </>
          )}
        </>
      )}
      <div className="d-flex flex-row justify-content-between py-2">
        <div className="col pl-0">
          {isWebcam ? (
            <>
              {isRetake ? (
                <button
                  className={"btn btn-block purple " + fontSize}
                  style={{
                    backgroundColor: "rgb(95,5,147,0.2)",
                    border: "none",
                  }}
                  onClick={() => {
                    setIsRetake(false);
                    setfileUpload([]);
                    setShowImg(null);
                  }}
                >
                  Retake
                </button>
              ) : (
                <button
                  className={"btn btn-block purple " + fontSize}
                  style={{
                    backgroundColor: "rgb(95,5,147,0.2)",
                    border: "none",
                  }}
                  onClick={() => capture()}
                >
                  Capture Photo
                </button>
              )}
            </>
          ) : (
            <button
              className={"btn btn-block purple " + fontSize}
              style={{ backgroundColor: "rgb(95,5,147,0.2)", border: "none" }}
              onClick={() => {
                setIsWebcam(true);
                setfileUpload([]);
                setShowImg(null);
                setIsRetake(false);
              }}
            >
              Take Photo
            </button>
          )}
        </div>
        <div className="col pr-0">
          <button
            className={"btn btn-block purple " + fontSize}
            style={{ backgroundColor: "rgb(95,5,147,0.2)", border: "none" }}
            onClick={handleOpenFileInput}
          >
            Browse File
          </button>
        </div>
      </div>
    </div>
  );
};

export default React.memo(CameraWebcam);
