import { Icon } from "@iconify/react";
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { BottomSheet } from "react-spring-bottom-sheet";
import { BreadcrumbContext } from "../../common/BreadcrumbContext";
import { MobileCardList } from "../../common/CardList";
import { IS_MOBILE } from "../../common/Responsive";
import SelectOpt from "../../common/SelectOpt";
import { filterStatusRoomCustom } from "../../utils";
import { iconUrl } from "../../config";
import { getData, getErr, safeErrCode } from "../../utils/api-request";
import history from "../../utils/history";
import { MobileEmptyData } from "../Empty/EmptyData";
import { RoomStockTable } from "./RoomStockTable";
import { t } from "../../common/ErrorMessage";

const RoomStock = () => {
  const mounted = useRef(false);
  const {
    setpathLocation,
    isOpeningShift,
    openButtonSheet,
    setOpenButtonSheet,
    setShowFilter,
  } = useContext(BreadcrumbContext);
  const [roomStockList, setRoomStockList] = useState([]);
  const [loadingList, setLoadingList] = useState(false);
  const [errorList, setErrorList] = useState("");
  const [floorList, setFloorList] = useState([]);
  const [floorSelected, setFloorSelected] = useState([]);
  const [loadingFilter, setloadingFilter] = useState(false);
  const [roomTypeSelected, setRoomTypeSelected] = useState([]);
  const roomType = JSON.parse(localStorage?.getItem("room_type"));
  const [filterKeyword, setFilterKeyword] = useState({
    room_type: "",
    bed_type: "",
    floor: "",
  });

  const getRoomStockList = useCallback(() => {
    setLoadingList(true);
    getData(
      `/api/v1/roomstock?room_type=${filterKeyword?.room_type}&floor=${filterKeyword.floor}&process_type=1`
    )
      .then((resp) => {
        if (mounted.current) {
          setLoadingList(false);
          if (resp.data) {
            setRoomStockList(resp.data);
          } else {
            resp.json().then((err) => {
              setErrorList(t(safeErrCode(err)));
            });
          }
        }
      })
      .catch((error) => {
        let err = getErr(error);
        setLoadingList(false);
        setErrorList(t(err));
      });
  }, [filterKeyword]);

  const getFloorStockList = useCallback(() => {
    setloadingFilter(true);
    getData("/api/v1/roomstock/floorlist")
      .then((resp) => {
        if (mounted.current) {
          setloadingFilter(false);
          if (resp.data) {
            let d = resp.data.map((item) => {
              return {
                value: item,
                label: `Lt. ${item}`,
              };
            });
            setFloorList(d);
          } else {
            resp.json().then((err) => {
              console.error(t(safeErrCode(err)));
            });
          }
        }
      })
      .catch((error) => {
        let err = getErr(error);
        setloadingFilter(false);
        console.error(t(err));
      });
  }, []);

  const handleFloor = (val) => {
    if (!val) {
      setFloorSelected([]);
      setFilterKeyword({ ...filterKeyword, floor: "" });
    } else {
      setFloorSelected(val);
      setFilterKeyword({ ...filterKeyword, floor: val.value });
    }
  };

  const handleRoomType = (val) => {
    if (!val) {
      setRoomTypeSelected([]);
      setFilterKeyword({ ...filterKeyword, room_type: "" });
    } else {
      setRoomTypeSelected(val);
      setFilterKeyword({ ...filterKeyword, room_type: val.value });
    }
  };
  const selectFilter = (label, list, onChange, selected, placeholder) => {
    return (
      <div className="col px-1">
        <label htmlFor="" className="f8-vw m-0 p-0">
          {label}
        </label>
        <SelectOpt
          optionsValue={list}
          onChange={(e) => onChange(e)}
          placeholder={placeholder}
          clear={true}
          value={selected}
          loading={loadingFilter}
        />
      </div>
    );
  };
  const resetFilter = () => {
    setRoomStockList([]);
    setFloorSelected([]);
    setFilterKeyword({ ...filterKeyword, room_type: "", floor: "" });
    setRoomTypeSelected([]);
    getRoomStockList();
  };

  useEffect(() => {
    setpathLocation(history.location.pathname);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history.location.pathname]);

  useEffect(() => {
    if (
      filterKeyword.bed_type === "" &&
      filterKeyword.room_type === "" &&
      filterKeyword.floor === "" &&
      !isOpeningShift
    ) {
      getRoomStockList();
    }
  }, [getRoomStockList, filterKeyword, isOpeningShift]);

  useEffect(() => {
    getFloorStockList();
  }, [getFloorStockList]);

  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);
  useEffect(() => {
    setShowFilter(true);
    return () => {
      setShowFilter(false);
    };
  }, [setShowFilter]);

  return (
    <>
      {IS_MOBILE ? (
        <div className="d-flex flex-column p-1 mb-5">
          <div className="col">
            {roomStockList?.length > 0 ? (
              roomStockList.map((item) => {
                return (
                  <MobileCardList
                    title={`Kamar ${item?.room_number}`}
                    key={item?.room_number}
                    action_button={
                      <div
                        className={`text-center fw-700 ${
                          filterStatusRoomCustom(item?.status)?.className
                        }`}
                        style={{
                          borderRadius: 2,
                          width: 40,
                        }}
                      >
                        {filterStatusRoomCustom(item?.status)?.label}
                      </div>
                    }
                  >
                    <div className="card-hms-list">
                      <div className="col-4 px-0 grey-text">Floor</div>
                      <div className="col pr-0 f12-vw black-value">
                        {item?.floor}
                      </div>
                    </div>
                    <div className="card-hms-list">
                      <div className="col-4 px-0 grey-text">Room Type</div>
                      <div className="col pr-0 f12-vw black-value">
                        {item?.room_type_name}
                      </div>
                    </div>
                    <div className="card-hms-list">
                      <div className="col-4 px-0 grey-text">Bed Type</div>
                      <div className="col pr-0 f12-vw black-value">
                        {item?.bed_type_name}
                      </div>
                    </div>
                    <div className="card-hms-list">
                      <div className="col-4 px-0 grey-text">Bed Size</div>
                      <div className="col pr-0 f12-vw black-value">
                        {item?.bed_size}
                      </div>
                    </div>
                  </MobileCardList>
                );
              })
            ) : (
              <div style={{ position: "relative" }}>
                <MobileEmptyData
                  errMessage={errorList}
                  loading={loadingList}
                  height="40vh"
                />
              </div>
            )}
          </div>
        </div>
      ) : (
        <div
          className={`d-flex flex-column mt-1 ${
            !isOpeningShift ? "" : "not-opening"
          }`}
        >
          <div className="px-4">
            <div className="d-flex flex-row justify-content-between px-1 mt-1">
              <div className="col-md-9 px-0">
                <div className="d-flex flex-row">
                  <div className="col-4 px-0 mr-1">
                    {selectFilter(
                      "Pilih Room Type",
                      roomType,
                      handleRoomType,
                      roomTypeSelected,
                      "Pilih Room Type"
                    )}
                  </div>
                  <div className="col-4 px-0 mr-1">
                    {selectFilter(
                      "Pilih Floor",
                      floorList,
                      handleFloor,
                      floorSelected,
                      "Pilih Floor"
                    )}
                  </div>
                </div>
              </div>
              <div className="col-md-2 pr-0 pl-5 mt-auto">
                <div className="d-flex flex-column">
                  <button
                    className="btn btn-sm search-new rounded-input btn-block mt-auto f8-vw fw-500 text-white"
                    onClick={() => {
                      getRoomStockList();
                    }}
                    style={{
                      paddingTop: 7,
                      paddingBottom: 7,
                      backgroundColor: "#742B9E",
                    }}
                  >
                    Search
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="px-4 mt-3">
            <RoomStockTable
              list={roomStockList}
              loading={loadingList}
              errorMessage={errorList}
            />
          </div>
          {/* <div className="mt-auto p-3">
      <FootNote data={statusRoomCustom} />
    </div> */}
        </div>
      )}
      {IS_MOBILE && (
        <div>
          <BottomSheet
            open={openButtonSheet}
            onDismiss={() => setOpenButtonSheet(false)}
            snapPoints={({ minHeight }) => minHeight}
            header={
              <div className="d-flex justify-content-between">
                <span className="purple f16-vw fw-600 px-2">Filter</span>
                <span
                  className="px-2"
                  onClick={() => setOpenButtonSheet(false)}
                >
                  <Icon icon={iconUrl.closeBold} color={"grey"} width={20} />
                </span>
              </div>
            }
            footer={
              <div className="d-flex justify-content-between">
                <div className="col-6 pl-2">
                  <button
                    className="btn btn-block btn-outline-purple f12-vw"
                    onClick={() => {
                      resetFilter();
                      setOpenButtonSheet(false);
                    }}
                  >
                    Reset
                  </button>
                </div>
                <div className="col-6 pr-2">
                  <button
                    className="btn btn-block btn-gradient f12-vw text-white"
                    onClick={() => {
                      setRoomStockList([]);
                      setOpenButtonSheet(false);
                      getRoomStockList();
                    }}
                  >
                    Search
                  </button>
                </div>
              </div>
            }
          >
            <div className="mt-3" style={{ height: "17rem" }}>
              <div className="px-4 mt-2">
                <label htmlFor="" className="f12-vw m-0 p-0 fw-600">
                  Pilih Room Type
                </label>
                <SelectOpt
                  placeholder="Pilih Room Type"
                  optionsValue={roomType}
                  onChange={(e) => handleRoomType(e)}
                  clear={true}
                  value={roomTypeSelected}
                  fontSize="calc(8pt + 0.472vw)"
                  isSearchable={true}
                />
              </div>
              <div className="px-4 mt-2">
                <label htmlFor="" className="f12-vw m-0 p-0 fw-600">
                  Pilih Lantai
                </label>
                <SelectOpt
                  placeholder="Pilih Lantai"
                  optionsValue={floorList}
                  onChange={(e) => handleFloor(e)}
                  clear={true}
                  value={floorSelected}
                  fontSize="calc(8pt + 0.472vw)"
                  menuHeight="8rem"
                  isSearchable={true}
                />
              </div>
            </div>
          </BottomSheet>
        </div>
      )}
    </>
  );
};

export default RoomStock;
