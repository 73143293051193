import React, {
  Fragment,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { Link, NavLink } from "react-router-dom";
import { checkMenuPath, isLogout } from "../../utils";
import {
  checkoutMenu,
  houseKeepingMenu,
  lossFoundMenu,
  masterDataMenu,
  reportDataMenu,
  rePrintCheckinMenu,
  rePrintCheckoutMenu,
  reservasiMenu,
  weeklyTransfer,
} from "./sidebarData";
import "./new-styles/Styles.scss";
import { Icon } from "@iconify/react";
import { useHistory, useLocation } from "react-router";
import PopupModal from "../../common/PopupModal";
import { getData, getErr, safeErrCode } from "../../utils/api-request";
import NotifMessage, { ButtonClose } from "../../common/Notif";
import { OpenShift } from "../../pages/shift/OpenShift";
import { ClosingShift } from "../../pages/shift/ClosingShift";
import { ClosingConfirm } from "../../pages/shift/ClosingConfirm";
import { config, iconUrl } from "../../config";
import {
  createStructACL,
  is_sub_menu_action,
  isValidAccessMultiple,
  isValidAccess,
  mappingMenu,
} from "../../utils/uac";
import { isCurrentPath } from "../../utils/mobile";
import { AccordionMenu } from "./AccordionMenu";
import { Menu } from "./Menu";
import { BreadcrumbContext } from "../../common/BreadcrumbContext";
import { IS_MOBILE } from "../../common/Responsive";
import UserInfo from "../../common/UserInfo";
import { OpenShiftSuccess } from "../../pages/shift/OpenShiftSuccess";
import { priorityPage } from "../../common/FilterAcl";
import { t } from "../../common/ErrorMessage";

const FONT_SIZE_MENU = IS_MOBILE ? "f12-vw" : "f10-vw";

const Sidebar = ({ close = () => null, closeMenu = null }) => {
  const history = useHistory();
  const [openModal, setopenModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [modalError, setModalError] = useState(false);
  const [mode, setMode] = useState("");
  const [closingShift, setclosingShift] = useState({});
  const [shiftInfo, setShiftInfo] = useState([]);
  const [error, setError] = useState(null);
  const { userProfile } = useContext(BreadcrumbContext);
  const [show0, setShow0] = useState(true);
  const [show1, setShow1] = useState(false);
  const [show2, setShow2] = useState(false);
  const [show3, setShow3] = useState(false);
  const [show4, setShow4] = useState(false);
  const [show5, setShow5] = useState(false);
  const [show6, setShow6] = useState(false);
  const [show7, setShow7] = useState(false);
  const [loadingPre, setLoadingPre] = useState(false);
  const [imgPopup, setImgPopup] = useState("");
  const [revenue, setRevenue] = useState(0);
  const [change, setChange] = useState(0);
  const [lastBalance, setLastBalance] = useState(0);
  const [realCash, setRealCash] = useState(0);
  const [noteBalance, setNoteBalance] = useState("");

  const [currentPath, setCurrentPath] = useState("");
  const location = useLocation();
  useEffect(() => {
    let path = location.pathname;
    setCurrentPath(path);
  }, [location]);

  useEffect(() => {
    let x = currentPath.split("/");
    initCollapsedMenu(x[1]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPath]);

  const initCollapsedMenu = (prefix_path) => {
    switch (prefix_path) {
      case "reservasi":
        setShow0(true);
        setShow1(false);
        setShow2(false);
        setShow3(false);
        setShow4(false);
        setShow5(false);
        setShow6(false);
        setShow7(false);
        break;
      case "shift":
        setShow0(false);
        setShow1(true);
        setShow2(false);
        setShow3(false);
        setShow4(false);
        setShow5(false);
        setShow6(false);
        setShow7(false);
        break;
      case "master-data":
        setShow0(false);
        setShow1(false);
        setShow2(true);
        setShow3(false);
        setShow4(false);
        setShow5(false);
        setShow6(false);
        setShow7(false);
        break;
      case "report":
        setShow0(false);
        setShow3(true);
        setShow1(false);
        setShow2(false);
        setShow4(false);
        setShow5(false);
        setShow6(false);
        setShow7(false);
        break;
      case "housekeeping":
        setShow0(false);
        setShow1(false);
        setShow2(false);
        setShow3(false);
        setShow4(true);
        setShow5(false);
        setShow6(false);
        setShow7(false);
        break;
      case "marketing":
        setShow0(false);
        setShow1(false);
        setShow2(false);
        setShow3(false);
        setShow4(false);
        setShow5(true);
        setShow6(false);
        setShow7(false);
        break;
      case "checkout":
        setShow0(false);
        setShow1(false);
        setShow2(false);
        setShow3(false);
        setShow4(false);
        setShow5(false);
        setShow6(true);
        setShow7(false);
        break;
      case "loss-n-found":
        // showActive7();
        setShow0(false);
        setShow1(false);
        setShow2(false);
        setShow3(false);
        setShow4(false);
        setShow5(false);
        setShow6(false);
        setShow7(true);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    createStructACL();
    let defaultRoute = priorityPage();
    history.push(defaultRoute?.path);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sessionStorage.getItem("token")]);

  const handleError = (val) => {
    setError(val);
  };
  const isOpen = () => {
    setopenModal(!openModal);
  };
  const goToMain = () => {
    setopenModal(false);
    history.push("/reservasi/room-stock");
  };

  const isOpenModalError = () => {
    setModalError(!modalError);
  };

  const getShiftName = useCallback(() => {
    setLoading(true);
    getData("/api/v1/shift/current")
      .then((resp) => {
        setLoading(false);
        if (resp?.shift) {
          let shift_info = {
            end_hour: resp.shift.end_hour,
            shift_id: resp.shift.shift_id,
            shift_name: resp.shift.shift_name,
            start_hour: resp.shift.start_hour,
          };
          setShiftInfo(shift_info);
          isOpen();
          close();
          setMode("open-shift");
        } else {
          resp.json().then((err) => {
            if (err.code === "E2005") {
              setError(
                "Shift Tidak Ditemukan. Silahkan hubungi Manager untuk menambahkan shift Anda"
              );
            } else {
              setError(t(safeErrCode(err)));
            }
          });
          setImgPopup("/img/error-icon/cant-close-shift.png");
          isOpenModalError();
          close();
        }
      })
      .catch((error) => {
        let err = getErr(error);
        setImgPopup("/img/error-icon/cant-close-shift.png");
        setLoading(false);
        setError(t(err));
        isOpenModalError();
        console.log("run catch popup error");
        close();
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const showActive0 = () => {
    setShow0(!show0);
  };
  const showActive1 = () => {
    setShow1(!show1);
  };
  const showActive2 = () => {
    setShow2(!show2);
  };
  const showActive3 = () => {
    setShow3(!show3);
  };
  const showActive4 = () => {
    setShow4(!show4);
  };
  const showActive5 = () => {
    setShow5(!show5);
  };
  const showActive6 = () => {
    setShow6(!show6);
  };
  const showActive7 = () => {
    setShow7(!show7);
  };

  const openCloseMenu = (param) => {
    switch (param) {
      case "reservasi":
        showActive0();
        setShow1(false);
        setShow2(false);
        setShow3(false);
        setShow4(false);
        setShow5(false);
        setShow6(false);
        setShow7(false);
        break;
      case "shift":
        setShow0(false);
        showActive1();
        setShow2(false);
        setShow3(false);
        setShow4(false);
        setShow5(false);
        setShow6(false);
        setShow7(false);
        break;
      case "master":
        setShow0(false);
        setShow1(false);
        showActive2();
        setShow3(false);
        setShow4(false);
        setShow5(false);
        setShow6(false);
        setShow7(false);
        break;
      case "report":
        setShow0(false);
        showActive3();
        setShow1(false);
        setShow2(false);
        setShow4(false);
        setShow5(false);
        setShow6(false);
        setShow7(false);
        break;
      case "housekeeping":
        setShow0(false);
        setShow1(false);
        setShow2(false);
        setShow3(false);
        showActive4();
        setShow5(false);
        setShow6(false);
        setShow7(false);
        break;
      case "marketing":
        showActive5();
        setShow0(false);
        setShow1(false);
        setShow2(false);
        setShow3(false);
        setShow4(false);
        setShow6(false);
        setShow7(false);
        break;
      case "checkout":
        showActive6();
        setShow0(false);
        setShow1(false);
        setShow2(false);
        setShow3(false);
        setShow4(false);
        setShow5(false);
        setShow7(false);
        break;
      case "lossfound":
        showActive7();
        setShow0(false);
        setShow1(false);
        setShow2(false);
        setShow3(false);
        setShow4(false);
        setShow5(false);
        setShow6(false);
        break;
      default:
        break;
    }
  };

  const getRevenue = () => {
    setLoadingPre(true);
    getData("/api/v1/shift/closed")
      .then((resp) => {
        setLoadingPre(false);
        if (resp.status === "OK") {
          setRevenue(resp);
          isOpen();
          close();
          setMode("close-shift");
        } else {
          resp.json().then((err) => {
            if (err.code === "E1101") {
              setError(
                "Anda tidak dapat melakukan Closing Shift, hubungi staff yang melakukan Opening Shift"
              );
            } else {
              setError(t(safeErrCode(err)));
            }
          });
          setImgPopup("/img/error-icon/cant-close-shift.png");
          isOpenModalError();
          close();
        }
      })
      .catch((error) => {
        setLoadingPre(false);
        console.log(error);
        setImgPopup("/img/error-icon/cant-close-shift.png");
        close();
        isOpenModalError();
      });
  };

  useEffect(() => {
    if (openModal || modalError) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
    return () => {
      document.body.style.overflow = "unset";
    };
  }, [modalError, openModal]);

  const isOpeningShift = JSON.parse(localStorage.getItem("shift_open"));
  return (
    <div className="sidebar" ref={closeMenu}>
      {IS_MOBILE ? (
        <UserInfo />
      ) : (
        <div className="icon-logo">
          <img
            src={process.env.REACT_APP_SIDE_LOGO}
            alt="hmslogo"
            loading="lazy"
          />
        </div>
      )}

      <div className="px-4">
        <hr className="separator-grey my-0" />
      </div>
      <div className="">
        <div className="menu my-1 d-flex flex-column">
          <ul
            className="relative scroll-page"
            style={{ height: "76vh", scrollBehavior: "smooth" }}
          >
            <div className="accordion" id="accordionMenu">
              {isValidAccessMultiple(reservasiMenu, rePrintCheckinMenu) && (
                <AccordionMenu
                  show={show0}
                  openCloseMenu={() => openCloseMenu("reservasi")}
                  prefix_path={"reservasi"}
                  menuName="Reservasi"
                  iconMenu={iconUrl.reservasiIcon}
                  menuColor="reservasi-color"
                >
                  <Menu
                    menuData={reservasiMenu}
                    collapseClass={`collapse ${isCurrentPath(
                      currentPath,
                      "reservasi"
                    )}`}
                    id={"reservasi"}
                    close={close}
                  >
                    {isValidAccess(mappingMenu(rePrintCheckinMenu)) && (
                      <div className="accordion" id="accordionRePrint">
                        <div className="item-list pl-3 py-1 ml-3 menu-hover">
                          <button
                            className="border-0 nav-item px-0 btn-transparent text-menu 10-vw fw-500 "
                            data-toggle="collapse"
                            data-target="#reprint-checkin"
                            aria-expanded={"false"}
                            aria-controls="reprint-checkin"
                          >
                            Re-Printing
                          </button>
                        </div>
                        <Menu
                          collapseClass={"collapse"}
                          menuData={rePrintCheckinMenu}
                          id="reprint-checkin"
                          dataParent="#accordionRePrint"
                          close={close}
                        />
                      </div>
                    )}
                  </Menu>
                </AccordionMenu>
              )}
              {isValidAccessMultiple(checkoutMenu, rePrintCheckoutMenu) && (
                <AccordionMenu
                  show={show6}
                  openCloseMenu={(e) => {
                    openCloseMenu("checkout");
                  }}
                  prefix_path={"checkout"}
                  menuName="Checkout"
                  iconMenu={iconUrl.checkoutIcon}
                  menuColor="checkout-color"
                >
                  <Menu
                    menuData={checkoutMenu}
                    id={"checkout"}
                    collapseClass={`collapse ${isCurrentPath(
                      currentPath,
                      "checkout"
                    )}`}
                    close={close}
                  >
                    {isValidAccess(mappingMenu(rePrintCheckoutMenu)) && (
                      <div className="accordion" id="accordionRePrintCheckout">
                        <div className="item-list pl-3 py-1 ml-3 menu-hover">
                          <button
                            className="border-0 nav-item px-0 btn-transparent text-menu f10-vw fw-500"
                            data-toggle="collapse"
                            data-target="#reprint-checkout"
                            aria-expanded={"false"}
                            aria-controls="reprint-checkout"
                          >
                            Re-Printing
                          </button>
                        </div>
                        <Menu
                          collapseClass={"collapse"}
                          menuData={rePrintCheckoutMenu}
                          id="reprint-checkout"
                          dataParent="#accordionRePrintCheckout"
                          close={close}
                        />
                      </div>
                    )}
                  </Menu>
                </AccordionMenu>
              )}
              {isValidAccess(mappingMenu(lossFoundMenu)) && (
                <AccordionMenu
                  show={show7}
                  openCloseMenu={() => openCloseMenu("lossfound")}
                  prefix_path={"lossfound"}
                  menuName="Lost &amp; Found"
                  iconMenu={iconUrl.lostnfoundIcon}
                  menuColor="lossfound-color"
                >
                  <Menu
                    menuData={lossFoundMenu}
                    id={"lossfound"}
                    collapseClass={`collapse ${isCurrentPath(
                      currentPath,
                      "loss-n-found"
                    )}`}
                    close={close}
                  />
                </AccordionMenu>
              )}
              {isValidAccessMultiple(
                ["sf_open_process", "sf_close_process"],
                weeklyTransfer
              ) && (
                <AccordionMenu
                  show={show1}
                  openCloseMenu={() => {
                    openCloseMenu("shift");
                  }}
                  prefix_path={"shiftOpen"}
                  menuName="Shift"
                  iconMenu={iconUrl.shiftIcon}
                  menuColor="shift-color"
                >
                  <div
                    id="shiftOpen"
                    className={`collapse ${isCurrentPath(
                      currentPath,
                      "shift"
                    )}`}
                    aria-labelledby="shiftOpen"
                    data-parent="#accordionMenu"
                  >
                    <div className=" px-3 d-flex flex-column">
                      {is_sub_menu_action("sf_open_process") && (
                        <li
                          className={`item-list pl-3 ml-3 py-1 w-100 ${
                            !isOpeningShift ? "" : "menu-hover"
                          }`}
                          style={{ order: 1 }}
                        >
                          <button
                            className={
                              !isOpeningShift
                                ? "nav-item border-0 btn-transparent grey px-0 " +
                                  FONT_SIZE_MENU
                                : "nav-item border-0 btn-transparent text-menu px-0"
                            }
                            onClick={
                              isOpeningShift
                                ? () => {
                                    getShiftName();
                                  }
                                : null
                            }
                            disabled={!isOpeningShift}
                          >
                            Opening Shift
                          </button>
                        </li>
                      )}
                      {is_sub_menu_action("sf_close_process") && (
                        <li
                          className={`item-list pl-3 ml-3 py-1 w-100 ${
                            !isOpeningShift ? "menu-hover" : ""
                          }`}
                          style={{ order: 2 }}
                        >
                          <button
                            className={
                              isOpeningShift
                                ? "nav-item border-0 btn-transparent grey px-0 " +
                                  FONT_SIZE_MENU
                                : "nav-item border-0 btn-transparent px-0 text-menu"
                            }
                            disabled={isOpeningShift}
                            onClick={
                              !isOpeningShift
                                ? () => {
                                    getRevenue();
                                  }
                                : null
                            }
                          >
                            Closing Shift
                          </button>
                        </li>
                      )}
                      {isValidAccess(mappingMenu(weeklyTransfer)) && (
                        <div
                          className="accordion"
                          id="accordionWeekly"
                          style={{ order: 3 }}
                        >
                          <div className="item-list pl-3 ml-3 py-1 w-100 menu-hover">
                            <button
                              className="border-0 nav-item px-0 btn-transparent text-menu f10-vw fw-500"
                              data-toggle="collapse"
                              data-target="#weekly-transfer"
                              aria-expanded={"false"}
                              aria-controls="weekly-transfer"
                            >
                              Setoran Cash
                            </button>
                          </div>
                          <div
                            className={`pl-3 collapse`}
                            id="weekly-transfer"
                            aria-labelledby="weekly-transfer"
                            data-parent="#accordionWeekly"
                          >
                            <div>
                              {weeklyTransfer.map((item, i) => {
                                return (
                                  <Fragment key={i}>
                                    {is_sub_menu_action(item?.acl) && (
                                      <li
                                        className={`item-list pl-3 ml-3 py-1 menu-hover w-100 ${checkMenuPath(
                                          item?.linkName
                                        )}`}
                                        onClick={close}
                                      >
                                        <NavLink
                                          to={item?.linkName}
                                          className={item?.cName}
                                        >
                                          <span className="text-menu">
                                            {item?.menuName}
                                          </span>
                                        </NavLink>
                                      </li>
                                    )}
                                  </Fragment>
                                );
                              })}
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </AccordionMenu>
              )}
              {isValidAccess(mappingMenu(houseKeepingMenu)) && (
                <AccordionMenu
                  show={show4}
                  openCloseMenu={() => {
                    openCloseMenu("housekeeping");
                  }}
                  prefix_path={"housekeeping"}
                  menuName="Housekeeping"
                  iconMenu={iconUrl.housekeepingIcon}
                  menuColor="housekeeping-color"
                >
                  <Menu
                    menuData={houseKeepingMenu}
                    id={"housekeeping"}
                    collapseClass={`collapse ${isCurrentPath(
                      currentPath,
                      "housekeeping"
                    )}`}
                    close={close}
                  />
                </AccordionMenu>
              )}
              {isValidAccess(mappingMenu(masterDataMenu)) && (
                <AccordionMenu
                  show={show2}
                  openCloseMenu={() => {
                    openCloseMenu("master");
                  }}
                  prefix_path={"master"}
                  menuName="Master Data"
                  iconMenu={iconUrl.masterdataIcon}
                  menuColor="master-color"
                >
                  <Menu
                    menuData={masterDataMenu}
                    id={"master"}
                    collapseClass={`collapse ${isCurrentPath(
                      currentPath,
                      "master"
                    )}`}
                    close={close}
                  />
                </AccordionMenu>
              )}
              {isValidAccess(mappingMenu(reportDataMenu)) && (
                <AccordionMenu
                  show={show3}
                  openCloseMenu={() => {
                    openCloseMenu("report");
                  }}
                  prefix_path={"report"}
                  menuName="Report"
                  iconMenu={iconUrl.reportIcon}
                  menuColor="report-color"
                >
                  <Menu
                    menuData={reportDataMenu}
                    id={"report"}
                    collapseClass={`collapse ${isCurrentPath(
                      currentPath,
                      "report"
                    )}`}
                    close={close}
                  />
                </AccordionMenu>
              )}
            </div>
          </ul>
          <li
            className="item-list px-3 ml-2"
            style={{ position: "absolute", bottom: 25 }}
          >
            <Link
              className="nav-item"
              onClick={() => isLogout(history)}
              to="/login"
            >
              <i className="icon-img mr-1">
                <Icon icon={iconUrl.logoutIcon} color="white" />
              </i>
              <span className="text-menu fw-700">Log Out</span>
            </Link>
          </li>

          <div
            className=" px-4"
            style={{
              // transform: "translateY(-100%)",
              // display: "inline-block",
              position: "absolute",
              bottom: 5,
            }}
          >
            <div className="f7-vw text-white">{config?.version}</div>
          </div>
        </div>
      </div>
      <PopupModal
        modalIsOpen={openModal}
        closeModal={isOpen}
        width={mode === "close-shift" ? "43.5rem" : "380px"}
        maxWidth={mode === "close-shift" ? "43.5rem" : "380px"}
        mode={mode === "success" ? true : false}
        top={mode === "close-shift" ? "58%" : "50%"}
      >
        {mode === "open-shift" ? (
          <OpenShift
            close={() => isOpen()}
            goTo={() => {
              setMode("open-success");
            }}
            error={error}
            handleError={handleError}
            shiftInfo={shiftInfo}
            modal={openModal}
            loading={loading}
            setLastBalance={setLastBalance}
            setRealCash={setRealCash}
            setNoteBalance={setNoteBalance}
          />
        ) : mode === "open-success" ? (
          <OpenShiftSuccess
            shiftInfo={shiftInfo}
            userProfile={userProfile}
            lastBalance={lastBalance}
            realCash={realCash}
            noteBalance={noteBalance}
            close={goToMain}
          />
        ) : mode === "close-shift" ? (
          <>
            <ClosingShift
              closeModal={isOpen}
              mode={() => setMode("confirm-close")}
              setclosingShift={(val) =>
                setclosingShift({ ...closingShift, ...val })
              }
              error={error}
              handleError={handleError}
              revenue={revenue}
              loadingPre={loadingPre}
              change={change}
              setChange={setChange}
            />
          </>
        ) : (
          mode === "confirm-close" && (
            <ClosingConfirm
              closeModal={isOpen}
              closingShift={closingShift}
              userProfile={userProfile}
              change={change}
            />
          )
        )}
      </PopupModal>
      <PopupModal
        modalIsOpen={modalError}
        closeModal={() => isOpenModalError()}
        width="300px"
        maxWidth="350px"
        top="65%"
      >
        <>
          <NotifMessage
            img={imgPopup}
            messageHeader={""}
            color="red"
            messageBody={error}
          />
          <ButtonClose
            close={() => {
              isOpenModalError();
              setError("");
            }}
            text="OK"
          />
        </>
      </PopupModal>
    </div>
  );
};

export default Sidebar;
