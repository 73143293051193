import React from "react";
import { IS_MOBILE } from "../../common/Responsive";
import { filterForecast, splitDateForecast, tableCol } from "../../utils";
import { EmptyData } from "../Empty/EmptyData";

const FONT_SIZE = IS_MOBILE ? "f12-vw" : "f8-vw";
export const TableBookingForecast = ({
  list,
  dateList,
  roomType,
  totalBooked,
  error,
}) => {
  return (
    <div
      className={`table-list scroll-table px-0 mt-2 table-border-line ${
        IS_MOBILE ? "hide-scrollbar" : ""
      }`}
      style={{ height: "40vh" }}
    >
      {dateList?.length > 0 ? (
        <table
          className="table table-sm"
          style={{ borderCollapse: "collapse", borderSpacing: 0 }}
        >
          <thead className={"sticky-table " + FONT_SIZE}>
            <tr
              className="text-center text-white"
              style={{ backgroundColor: "#0071A4" }}
            >
              <th className="align-middle py-2">Room Type</th>
              {dateList.map((date_item, i) => (
                <th key={i} className={`align-middle py-2`}>
                  {splitDateForecast(date_item)}
                </th>
              ))}
            </tr>
          </thead>
          <tbody className={FONT_SIZE}>
            {roomType?.map((item, i) => {
              return (
                <tr key={i} className={tableCol(i)}>
                  <td
                    className="text-center align-middle py-2"
                    style={{ width: 100 }}
                  >
                    {item.room_type_name}
                  </td>
                  {dateList.map((date, index) => (
                    <td key={index} className="text-center align-middle">
                      {filterForecast(list, item.room_type_id, date)}
                    </td>
                  ))}
                </tr>
              );
            })}
          </tbody>

          <tfoot
            className={FONT_SIZE}
            style={{
              position: "sticky",
              backgroundColor: "white",
              insetBlockEnd: 0,
              boxShadow: "0 2px 0 #0071A4 inset, 0 -2px 0 #0071A4 inset",
            }}
          >
            <tr
              className="font-weight-bold"
              style={{
                backgroundColor: "#7FC3FF40",
              }}
            >
              <td className="text-center align-middle py-2">Total</td>
              {totalBooked.map((total, index) => (
                <td
                  key={index}
                  className={`text-center align-middle ${
                    total?.total_booked > 0 ? "blue" : ""
                  }`}
                >
                  {total?.total_booked}
                </td>
              ))}
            </tr>
          </tfoot>
        </table>
      ) : (
        <table>
          <tbody className={"p-0 " + FONT_SIZE}>
            <tr>
              <td colSpan={31} className="p-0">
                <EmptyData errMessage={error} />
              </td>
            </tr>
          </tbody>
        </table>
      )}
    </div>
  );
};
