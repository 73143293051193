import { Icon } from "@iconify/react";
import { Fragment, useEffect, useState, useContext } from "react";
import { BreadcrumbContext } from "../../../common/BreadcrumbContext";
import history from "../../../utils/history";
import NotifMessage, { ButtonClose } from "../../../common/Notif";
import PopupModal from "../../../common/PopupModal";
import {
  changeCol,
  formatDate,
  generateBookingCode,
  getServerTimeWithoutDays,
  paymentLabel,
  priceSplit,
  isEmpty,
  getClientTimeWithoutSecond,
  getTimezoneOffsetClient,
  getServerTime,
  getDayDuration,
  getClientTime,
  filterStringValue,
} from "../../../utils";
import {
  addData,
  addDataPict,
  getData,
  getErr,
  safeErrCode,
} from "../../../utils/api-request";
import { EmptyData, MobileEmptyData } from "../../Empty/EmptyData";
import { PaymentConfirmPopup } from "./PaymentConfirmPopup";
import { PaymentConfirmSuccess } from "./PaymentConfirmSuccess";
import { isValidAccess, is_sub_menu_action } from "../../../utils/uac";
import { Print } from "../../../print/Print";
import {
  createLineDash,
  createLineSolid,
  genBookingId,
  genContent1,
  genContent2,
  genPaymentID,
} from "../../../print/common/printer-helper";
import { process_type } from "../../re-printing/utils/helper";
import { CancelPaymentCheckout } from "./CancelPayment";
import { iconUrl } from "../../../config";
import { mobileSearchInput, searchInput } from "../../booking/bookingHelper";
import { IS_MOBILE } from "../../../common/Responsive";
import { MobileCardList } from "../../../common/CardList";
import { BottomSheet } from "react-spring-bottom-sheet";
import { t } from "../../../common/ErrorMessage";

const List = ({
  data,
  setItem,
  loading,
  errMessage,
  onClick,
  setMode,
  openModal,
  timezone,
  search,
  setSearch,
  onKeyPress,
}) => {
  return (
    <div className="d-flex flex-column px-3">
      <div className="col px-0">
        <div className="d-flex flex-row justify-content-between align-items-center">
          <div className="col-md-8 px-0">
            <div className="d-flex flex-row">
              <div className="col px-0 mr-1">
                <label htmlFor="" className="f8-vw m-0 p-0">
                  Kode Booking
                </label>
                {searchInput(
                  "Cari Kode Booking",
                  search.booking_id,
                  (e) =>
                    setSearch({
                      ...search,
                      booking_id: generateBookingCode(e.target.value),
                    }),

                  onKeyPress
                )}
              </div>
              <div className="col px-0 mr-1">
                <label htmlFor="" className="f8-vw m-0 p-0">
                  Payment ID
                </label>
                {searchInput(
                  "Cari Payment ID",
                  search.payment_id,
                  (e) =>
                    setSearch({
                      ...search,
                      payment_id: generateBookingCode(e.target.value),
                    }),
                  onKeyPress
                )}
              </div>
              <div className="col px-0 mr-1">
                <label htmlFor="" className="f8-vw m-0 p-0">
                  Nomor Kamar
                </label>
                {searchInput(
                  "Cari Nomor Kamar",
                  search.room_number,
                  (e) =>
                    setSearch({
                      ...search,
                      room_number: e.target.value,
                    }),
                  onKeyPress
                )}
              </div>
            </div>
          </div>
          <div className="col-md-2 pr-0 pl-5 mt-auto">
            <div className="d-flex flex-column">
              <button
                className="btn btn-sm search-new rounded-input btn-block mt-auto f8-vw fw-500 text-white"
                onClick={() => {
                  onClick();
                }}
                style={{
                  paddingTop: 7,
                  paddingBottom: 7,
                  backgroundColor: "#742B9E",
                }}
              >
                Search
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="col px-0 mt-3">
        <div
          className="table-list table-responsive-sm scroll-table"
          style={{
            height: "22rem",
          }}
        >
          <table className="table table-sm">
            <thead className="f8-vw sticky-table">
              <tr
                className="text-center text-white"
                style={{
                  backgroundColor: "#0071A4",
                }}
              >
                <th className="align-middle py-2">Booking ID</th>
                <th className="align-middle py-2">Payment ID</th>
                <th className="align-middle py-2">
                  <div>Room</div>
                  <div>Number</div>
                </th>
                <th className="align-middle py-2">Total Bayar (Rp)</th>
                <th className="align-middle py-2">Nomor Rekening</th>
                <th className="align-middle py-2">
                  <div>Batas</div>
                  <div>Pembayaran</div>
                </th>
                <th className="align-middle py-2">
                  <div>Process</div>
                  <div>Type</div>
                </th>
                <th className="align-middle py-2">Status</th>
                <th className="align-middle py-2">Action</th>
              </tr>
            </thead>
            <tbody className="f8-vw">
              {data?.length > 0 ? (
                <>
                  {data?.map((item, index) => (
                    <Fragment key={index}>
                      <tr
                        style={{
                          backgroundColor: index & 1 ? "#ECECEC" : "#FFFFFF",
                        }}
                        className="f8-vw"
                      >
                        <td className="align-middle text-left">
                          {item?.book_id}
                        </td>
                        <td className="align-middle text-center">
                          {item?.payment_id}
                        </td>
                        <td className="align-middle text-center">
                          {item?.room_number}
                        </td>
                        <td className="align-middle text-right">
                          {priceSplit(item?.total_charge)}
                        </td>
                        <td className="align-middle text-center">
                          {item?.bank_accno
                            ? item?.to_bank_name +
                              " ( " +
                              item?.bank_accno +
                              " )"
                            : "-"}
                        </td>
                        <td className="align-middle text-center">
                          {item?.due_date ? (
                            <>
                              <div>
                                {getServerTimeWithoutDays(
                                  item?.due_date,
                                  timezone
                                )}
                              </div>
                              <div className="" style={{ fontWeight: "bold" }}>
                                {getClientTimeWithoutSecond(
                                  item?.due_date,
                                  timezone
                                ) +
                                  " " +
                                  getTimezoneOffsetClient(timezone)}
                              </div>
                            </>
                          ) : (
                            "-"
                          )}
                        </td>
                        <td className="align-middle text-center">
                          {
                            filterStringValue(process_type, item?.process_type)
                              ?.label
                          }
                        </td>
                        <td
                          className={`text-center align-middle ${changeCol(
                            item?.payment_status
                          )}`}
                        >
                          {paymentLabel(item?.payment_status)}
                        </td>
                        <td
                          className="align-middle text-center"
                          style={{ backgroundColor: "white" }}
                        >
                          {isValidAccess([
                            "ch_payment_confirm_process",
                            "ch_payment_confirm_cancel",
                          ]) &&
                            (item?.payment_status === 0 ||
                              item?.payment_status === 2) && (
                              <div className="combo-box">
                                <div
                                  className="dropdown"
                                  style={{
                                    color: "#79319F",
                                  }}
                                >
                                  <button
                                    className="bg-transparent dropdown-toggle border-0 blue btn-block f8-vw fw-700"
                                    type="button"
                                    id="dropdownMenu2"
                                    data-toggle="dropdown"
                                    aria-expanded="false"
                                  >
                                    Action
                                  </button>
                                  <div
                                    className="dropdown-menu dropdown-menu-right"
                                    aria-labelledby="dropdownMenu2"
                                  >
                                    <div className="d-flex flex-column">
                                      {is_sub_menu_action(
                                        "ch_payment_confirm_process"
                                      ) && (
                                        <button
                                          className="menu btn border-0 btn-sm blue f8-vw fw-600"
                                          onClick={() => {
                                            openModal();
                                            setMode("confirm-payment");
                                            setItem(item);
                                          }}
                                        >
                                          Confirm Payment
                                        </button>
                                      )}
                                      {is_sub_menu_action(
                                        "ch_payment_confirm_cancel"
                                      ) && (
                                        <button
                                          className="menu btn border-0 btn-sm blue f8-vw fw-600"
                                          onClick={() => {
                                            openModal();
                                            setMode("cancel-payment");
                                            setItem(item);
                                          }}
                                        >
                                          Cancel Payment
                                        </button>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}
                        </td>
                      </tr>
                    </Fragment>
                  ))}
                </>
              ) : (
                <tr>
                  <td colSpan={10} style={{ padding: 0 }}>
                    <EmptyData
                      loading={loading}
                      errMessage={errMessage}
                      text="Data tidak ditemukan"
                      sourceImg={"/img/booking/payment-confirmation.svg"}
                      height="17rem"
                    />
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

const PaymentConfirm = () => {
  const {
    setpathLocation,
    isOpeningShift,
    userProfile,
    openButtonSheet,
    setOpenButtonSheet,
    setShowFilter,
  } = useContext(BreadcrumbContext);
  const [mode, setMode] = useState("");
  const [isOpen, setIsOpen] = useState(false);

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [error, setError] = useState("");
  const [item, setItem] = useState({});

  const [imgPopup, setImgPopup] = useState("");
  const [headerPopup, setHeaderPopup] = useState("");
  const [fileUpload, setfileUpload] = useState([]);
  const [showImg, setShowImg] = useState("");
  const [search, setSearch] = useState({
    booking_id: "",
    room_number: "",
    payment_id: "",
  });

  const [dateTransfer, setDateTransfer] = useState(new Date());
  const [isPrint, setIsPrint] = useState(false);
  const hotel_info = JSON.parse(localStorage.getItem("info_hotel"));

  const timezone =
    localStorage?.getItem("timezone") && localStorage?.getItem("timezone");

  const openModal = () => {
    setIsOpen(!isOpen);
  };

  const reset = () => {
    setMode("");
    setIsOpen(false);
    setSearch({ booking_id: "", room_number: "", payment_id: "" });
    setLoading(false);
    setError("");
    setHeaderPopup("");
    setImgPopup("");
    setfileUpload([]);
    setItem({});
    setData([]);
    setIsPrint(false);
    setShowImg("");
  };

  const redirectCancel = (item) => {
    if (item?.process_type === 2) {
      history.push("/checkout/checkout-process");
    } else {
      history.push("/checkout/payment-process");
    }
  };

  const cancelPayment = (payment_id, process_type) => {
    setLoadingSubmit(true);
    const url = "/api/v1/listpayment/checkouts/cancel";
    const payload = {
      payment_id,
      process_type,
    };
    addData(url, payload)
      .then((resp) => {
        setLoadingSubmit(false);
        if (resp.message) {
          setMode("success-cancel");
          setHeaderPopup("Cancel Pembayaran Berhasil!");
          setImgPopup("/img/error-icon/booking-failed.svg");
        } else {
          resp.json().then((err) => {
            setError(t(safeErrCode(err)));
            setMode("error");
            setHeaderPopup("Cancel Pembayaran Gagal");
            setImgPopup("/img/error-icon/booking-failed.svg");
          });
        }
      })
      .catch((error) => {
        setLoadingSubmit(false);
        console.log(error);
        let err = getErr(error);
        setError(t(err));
        setMode("error");
        setHeaderPopup("Cancel Pembayaran Gagal");
        setImgPopup("/img/error-icon/booking-failed.svg");
      });
  };

  const getListPaymentConfirm = () => {
    const params = {
      ...(!!search?.booking_id && {
        booking_id: search?.booking_id.replace(" ", ""),
      }),
      ...(!!search?.payment_id && { payment_id: search?.payment_id }),
      ...(!!search?.room_number && { room_number: search?.room_number }),
    };

    let paramsFormatted = null;

    if (!isEmpty(params)) {
      paramsFormatted = new URLSearchParams(params);
    }

    const endpoint = `/api/v1/listpayment/checkouts?${
      paramsFormatted !== null ? `&${paramsFormatted}` : ""
    }`;

    setLoading(true);
    getData(endpoint)
      .then((resp) => {
        if (resp?.data?.length > 0) {
          setError(null);
          setData(resp.data);
        } else if (resp?.data?.length < 1) {
          setError("Data Billing tidak ditemukan");
          setData([]);
        } else {
          resp.json().then((err) => {
            setError(t(safeErrCode(err)));
          });
          setData([]);
        }
      })
      .catch((err) => setError(t(getErr(err))))
      .finally(() => setLoading(false));
  };

  const handlePaymentConfirm = () => {
    const payload = {
      process_type: item?.process_type === 13 ? item.process_type : 2,
      ...(item?.process_type !== 13 && { room_number: item?.room_number }),
      tgl_transfer: `${formatDate(dateTransfer)} ${getClientTime(
        dateTransfer
      )}`,
      payment_id: item?.payment_id,
      billing_ids: item?.billing_id,
      idem_id:
        Math.random()
          .toString()
          .slice(2, 15) +
        Math.random()
          .toString()
          .slice(2, 15),
      ...(item?.activity_id && { activity_id: item?.activity_id }),
    };

    const formData = new FormData();
    for (const key in payload) {
      formData.append(key, payload[key]);
    }
    if (fileUpload.length > 0) {
      for (const key of Object.keys(fileUpload)) {
        if (fileUpload[key]) {
          formData.append("file_bukti", fileUpload[key]);
        }
      }
    }
    const url =
      item?.process_type !== 13
        ? "/api/v1/confirmpayment"
        : "/api/v1/confirmpayment_extend";

    setLoadingSubmit(true);
    addDataPict(url, formData)
      .then((resp) => {
        setLoadingSubmit(false);
        if (resp.message) {
          setMode("confirm-success");
          setIsPrint(true);
        } else {
          resp.json().then((err) => {
            setError(t(safeErrCode(err)));
            setMode("error");
            setHeaderPopup("Konfirmasi Gagal");
            setImgPopup("/img/error-icon/booking-failed.svg");
          });
        }
      })
      .catch((err) => {
        setLoadingSubmit(false);
        setError(t(getErr(err)));
        setMode("error");
        setHeaderPopup("Konfirmasi Gagal");
        setImgPopup("/img/error-icon/booking-failed.svg");
      })
      .finally(() => setLoading(false));
  };

  const handleEnter = (e) => {
    if (e?.key === "Enter") {
      setData([]);
      getListPaymentConfirm();
    }
  };

  const resetFilter = () => {
    setData([]);
    setSearch({ booking_id: "", room_number: "", payment_id: "" });
    getListPaymentConfirm();
  };

  useEffect(() => {
    if (isPrint) {
      setTimeout(() => {
        setIsPrint(false);
      }, 6000);
    }
  }, [isPrint]);

  useEffect(() => {
    setpathLocation(history.location.pathname);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      mode === "" &&
      !isOpeningShift &&
      search?.booking_id === "" &&
      search?.payment_id === "" &&
      search?.room_number === ""
    ) {
      getListPaymentConfirm();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    mode,
    isOpeningShift,
    search,
    search?.booking_id,
    search?.payment_id,
    search?.room_number,
  ]);

  useEffect(() => {
    setShowFilter(true);
    return () => {
      setShowFilter(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
    return () => {
      document.body.style.overflow = "unset";
    };
  }, [isOpen]);

  // console.log("test : ", getClientTime(new Date()));

  return (
    <>
      {IS_MOBILE ? (
        <div className={` my-1 ${!isOpeningShift ? "" : "not-opening"}`}>
          <div className="d-flex flex-column px-3 py-1 mb-5">
            <div className="col px-1">
              {data?.length > 0 ? (
                data.map((item) => {
                  const timezone = localStorage.getItem("timezone");
                  return (
                    <MobileCardList
                      title={
                        <>
                          <div
                            className="f10-vw fw-500"
                            style={{ color: "#959595" }}
                          >
                            Kode Booking
                          </div>
                          <div>{item?.book_id}</div>
                        </>
                      }
                      key={item?.payment_id}
                      fontSizeHeader="f10-vw"
                      action_button={
                        isValidAccess([
                          "ch_payment_confirm_process",
                          "ch_payment_confirm_cancel",
                        ]) &&
                        (item?.payment_status === 0 ||
                          item?.payment_status === 2) && (
                          <div className="dropdown combo-box-mobile col px-0">
                            <button
                              className="btn btn-sm btn-gradient text-white dropdown-toggle border-0 btn-block f12-vw fw-600"
                              type="button"
                              data-toggle="dropdown"
                              aria-expanded="false"
                            >
                              Action
                              <span className="ml-2">
                                <Icon icon={iconUrl.arrowDownAlt2} width={15} />
                              </span>
                            </button>
                            <div
                              className="dropdown-menu dropdown-menu-center"
                              aria-labelledby="dropdownMenu2"
                            >
                              {is_sub_menu_action(
                                "ch_payment_confirm_process"
                              ) && (
                                <button
                                  className="menu border-0 purple bg-transparent f12-vw font-weight-bold btn-block m-0"
                                  onClick={() => {
                                    openModal();
                                    setMode("confirm-payment");
                                    setItem(item);
                                  }}
                                >
                                  Confirm Payment
                                </button>
                              )}
                              {is_sub_menu_action(
                                "ch_payment_confirm_cancel"
                              ) && (
                                <button
                                  className="menu border-0 purple bg-transparent f12-vw font-weight-bold btn-block m-0"
                                  onClick={() => {
                                    openModal();
                                    setMode("cancel-payment");
                                    setItem(item);
                                  }}
                                >
                                  Cancel Payment
                                </button>
                              )}
                            </div>
                          </div>
                        )
                      }
                    >
                      <div className="card-hms-list">
                        <div className="col-5 px-0 grey-text">Nomor Kamar</div>
                        <div className="col pr-0 f12-vw black-value">
                          {item?.room_number}
                        </div>
                      </div>
                      <div className="card-hms-list">
                        <div className="col-5 px-0 grey-text">Payment ID</div>
                        <div className="col pr-0 f12-vw black-value">
                          {item?.payment_id}
                        </div>
                      </div>
                      <div className="card-hms-list">
                        <div className="col-5 px-0 grey-text">Total Bayar</div>
                        <div className="col pr-0 f12-vw black-value">
                          Rp {priceSplit(item?.total_charge)}
                        </div>
                      </div>
                      <div className="card-hms-list">
                        <div className="col-5 px-0 grey-text">
                          Nomor Rekening
                        </div>
                        <div className="col pr-0 f12-vw black-value">
                          {item?.bank_accno ? (
                            <>
                              <div>{item?.to_bank_name}</div>
                              <div>{item?.bank_accno}</div>
                            </>
                          ) : (
                            "-"
                          )}
                        </div>
                      </div>
                      <div className="card-hms-list">
                        <div className="col-5 px-0 grey-text">
                          Batas Pembayaran
                        </div>
                        <div className="col pr-0 f12-vw black-value">
                          {item?.due_date ? (
                            <>
                              <div>
                                {getServerTimeWithoutDays(
                                  item?.due_date,
                                  timezone
                                )}
                              </div>
                              <div style={{ fontWeight: "bold" }}>
                                {getClientTime(item?.due_date, timezone) +
                                  " " +
                                  getTimezoneOffsetClient(timezone)}
                              </div>
                            </>
                          ) : (
                            "-"
                          )}
                        </div>
                      </div>
                      <div className="card-hms-list">
                        <div className="col-5 px-0 grey-text">Status</div>
                        <div className="col pr-0 f12-vw black-value">
                          <span
                            className={`text-center align-middle fw-600 ${changeCol(
                              item?.payment_status
                            )}`}
                          >
                            {paymentLabel(item?.payment_status)}
                          </span>
                        </div>
                      </div>
                    </MobileCardList>
                  );
                })
              ) : (
                <div style={{ position: "relative" }}>
                  <MobileEmptyData
                    errMessage={error}
                    loading={loading}
                    height="40vh"
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      ) : (
        <div
          className={`check-out-page bg-white px-3 py-1 my-1  ${
            !isOpeningShift ? "" : "not-opening"
          }`}
        >
          <List
            data={data}
            setItem={setItem}
            loading={loading}
            errMessage={error}
            onClick={() => getListPaymentConfirm()}
            setMode={setMode}
            openModal={openModal}
            timezone={timezone}
            search={search}
            setSearch={setSearch}
            onKeyPress={handleEnter}
          />
        </div>
      )}

      {IS_MOBILE && (
        <div>
          <BottomSheet
            open={openButtonSheet}
            onDismiss={() => setOpenButtonSheet(false)}
            snapPoints={({ minHeight }) => minHeight}
            header={
              <div className="d-flex justify-content-between">
                <span className="purple f16-vw fw-600 px-2">Filter</span>
                <span
                  className="px-2"
                  onClick={() => setOpenButtonSheet(false)}
                >
                  <Icon icon={iconUrl.closeBold} color={"grey"} width={20} />
                </span>
              </div>
            }
            footer={
              <div className="d-flex justify-content-between">
                <div className="col-6 pl-2">
                  <button
                    className="btn btn-block btn-outline-purple f12-vw"
                    onClick={() => {
                      resetFilter();
                      setOpenButtonSheet(false);
                    }}
                  >
                    Reset
                  </button>
                </div>

                <div className="col-6 pr-2">
                  <button
                    className="btn btn-block btn-gradient f12-vw text-white"
                    onClick={() => {
                      setData([]);
                      getListPaymentConfirm();
                      setOpenButtonSheet(false);
                    }}
                  >
                    Search
                  </button>
                </div>
              </div>
            }
          >
            <div className="mt-3" style={{ height: "17rem" }}>
              <div className="px-4 mt-3">
                <label htmlFor="" className="f12-vw m-0 p-0 fw-600">
                  Kode Booking
                </label>
                {mobileSearchInput(
                  "Masukkan Kode Booking",
                  search.booking_id,
                  (e) =>
                    setSearch({
                      ...search,
                      booking_id: generateBookingCode(e.target.value),
                    }),
                  handleEnter
                )}
              </div>
              <div className="px-4 mt-3">
                <label htmlFor="" className="f12-vw m-0 p-0 fw-600">
                  Payment ID
                </label>
                {mobileSearchInput(
                  "Cari Payment ID",
                  search.payment_id,
                  (e) =>
                    setSearch({
                      ...search,
                      payment_id: e.target.value,
                    }),
                  handleEnter
                )}
              </div>
              <div className="px-4 mt-3">
                <label htmlFor="" className="f12-vw m-0 p-0 fw-600">
                  Nomor Kamar
                </label>
                {mobileSearchInput(
                  "Cari Nomor Kamar",
                  search.room_number,
                  (e) =>
                    setSearch({
                      ...search,
                      room_number: e.target.value,
                    }),
                  handleEnter
                )}
              </div>
            </div>
          </BottomSheet>
        </div>
      )}

      <PopupModal modalIsOpen={isOpen}>
        {mode === "confirm-payment" ? (
          <PaymentConfirmPopup
            fileUpload={fileUpload}
            setfileUpload={setfileUpload}
            dateTransfer={dateTransfer}
            setDateTransfer={setDateTransfer}
            close={reset}
            loading={loadingSubmit}
            confirm={handlePaymentConfirm}
            setShowImg={setShowImg}
            showImg={showImg}
          />
        ) : mode === "confirm-success" ? (
          <PaymentConfirmSuccess
            close={reset}
            detail={item}
            fileUpload={fileUpload}
          />
        ) : mode === "cancel-payment" || mode === "success-cancel" ? (
          <CancelPaymentCheckout
            items={item}
            confirm={cancelPayment}
            back={reset}
            loading={loadingSubmit}
            mode={mode}
            redirect={() => redirectCancel(item)}
          />
        ) : (
          <>
            <NotifMessage
              closeModal={openModal}
              img={imgPopup}
              messageHeader={headerPopup}
              color="red"
              messageBody={error}
            />
            <ButtonClose close={openModal} text="OK" />
          </>
        )}
      </PopupModal>
      <div style={{ display: "none" }}>
        {!isEmpty(item) && (
          <Print launch_print={isPrint}>
            <p className="p-print txt-print-h1">SLIP Pembayaran</p>
            {genPaymentID(item?.payment_id)}
            {item?.process_type === 2 ? (
              <>
                {createLineDash()}
                <p className="p-print txt-print-h3">{`${getServerTime(
                  dateTransfer,
                  timezone
                )} - ${getClientTimeWithoutSecond(
                  dateTransfer,
                  timezone
                )} ${getTimezoneOffsetClient(timezone)}`}</p>
                <p className="p-print txt-print-h3">{hotel_info?.hotel_name}</p>
                {createLineDash()}
                <table className="table-print">
                  <tbody>
                    {genContent1("Kasir", userProfile?.nama)}
                    {/* {genContent1("Kode Booking", item?.booking_id)} */}
                  </tbody>
                </table>
                {genBookingId("Booking ID", item?.book_id)}
                {createLineDash()}
                <p className="p-print txt-print-section-h2">
                  Informasi Booking
                </p>
                <table className="table-print">
                  <tbody>
                    {genContent1("Tipe Kamar", item?.room_type_name)}
                    {genContent1(
                      "Durasi",
                      getDayDuration(item?.checkin_date, item?.checkout_date) +
                        " Malam"
                    )}
                  </tbody>
                </table>
                {createLineDash()}
                <table className="table-print">
                  <tbody>
                    {genContent1("Metode Pembayaran", "Transfer Bank")}
                    {genContent1(
                      "Nama Bank",
                      `${item?.to_bank_name} ( ${item?.bank_accno} )`
                    )}
                  </tbody>
                </table>
                {createLineDash()}
                <p className="p-print txt-print-section-h2">
                  Informasi Pembayaran
                </p>

                <table className="table-print">
                  <tbody>
                    {genContent2("Late Check Out", item?.late_checkout)}
                    {genContent2("Ganti Rugi", item?.ganti_rugi)}
                    {genContent2("Denda", item?.denda)}
                    {genContent2(
                      "Deposit",
                      item?.use_deposit
                        ? "-" + priceSplit(item?.total_deposit)
                        : 0
                    )}
                  </tbody>
                </table>
                {createLineSolid()}
                <table className="table-print">
                  <tbody>{genContent2("Total", item?.total_charge)}</tbody>
                </table>
                {createLineSolid()}
                <table className="table-print">
                  <tbody>
                    {genContent2("Total Pembayaran", item?.total_charge)}
                  </tbody>
                </table>
                {createLineSolid()}
                <table className="table-print">
                  <tbody>
                    {genContent2("Sisa Deposit", item?.sisa_deposit)}
                  </tbody>
                </table>
              </>
            ) : item?.process_type === 22 ? (
              <>
                <p className="p-print txt-print-h2">Change Room</p>
                {createLineDash()}
                <p className="p-print txt-print-h3">{`${getServerTime(
                  dateTransfer,
                  timezone
                )} - ${getClientTimeWithoutSecond(
                  dateTransfer,
                  timezone
                )} ${getTimezoneOffsetClient(timezone)}`}</p>
                <p className="p-print txt-print-h3">{hotel_info?.hotel_name}</p>
                {createLineDash()}
                <table className="table-print">
                  <tbody>
                    {genContent1("Kasir", userProfile?.nama)}
                    {/* {genContent1("Kode Booking", item?.booking_id)} */}
                  </tbody>
                </table>
                {genBookingId("Booking ID", item?.book_id)}
                {createLineDash()}
                <p className="p-print txt-print-section-h2">New Room Info</p>
                <table className="table-print">
                  <tbody>
                    {genContent1("Tipe Kamar", item?.to_room_type)}
                    {genContent1("Nomor Kamar", item?.to_room_number)}
                  </tbody>
                </table>
                {createLineDash()}
                <table className="table-print">
                  <tbody>
                    {genContent1("Metode Pembayaran", "Transfer Bank")}
                    {genContent1(
                      "Nama Bank",
                      `${item?.to_bank_name} ( ${item?.bank_accno} )`
                    )}
                  </tbody>
                </table>
                {createLineDash()}

                <p className="p-print txt-print-section-h2">
                  Informasi Pembayaran
                </p>
                <table className="table-print">
                  <tbody>
                    {genContent2("Total Charge", item?.total_charge)}
                  </tbody>
                </table>
              </>
            ) : item?.process_type === 13 ? (
              <>
                <p className="p-print txt-print-h2">Extend Booking</p>
                {createLineDash()}
                <p className="p-print txt-print-h3">{`${getServerTime(
                  dateTransfer,
                  timezone
                )} - ${getClientTimeWithoutSecond(
                  dateTransfer,
                  timezone
                )} ${getTimezoneOffsetClient(timezone)}`}</p>
                <p className="p-print txt-print-h3">{hotel_info?.hotel_name}</p>
                {createLineDash()}
                <table className="table-print">
                  <tbody>
                    {genContent1("Kasir", userProfile?.nama)}
                    {/* {genContent1("Kode Booking", item?.booking_id)} */}
                  </tbody>
                </table>
                {genBookingId("Booking ID", item?.book_id)}
                {createLineDash()}
                <table className="table-print">
                  <tbody>
                    {genContent1("Metode Pembayaran", "Transfer Bank")}
                    {genContent1(
                      "Nama Bank",
                      `${item?.to_bank_name} ( ${item?.bank_accno} )`
                    )}
                  </tbody>
                </table>
                {createLineDash()}

                <p className="p-print txt-print-section-h2">
                  Informasi Pembayaran
                </p>
                <table className="table-print">
                  <tbody>
                    {genContent2("Total Charge", item?.total_charge)}
                  </tbody>
                </table>
              </>
            ) : (
              <>
                <p className="p-print txt-print-h2">Breakage</p>
                {createLineDash()}
                <p className="p-print txt-print-h3">{`${getServerTime(
                  dateTransfer,
                  timezone
                )} - ${getClientTimeWithoutSecond(
                  dateTransfer,
                  timezone
                )} ${getTimezoneOffsetClient(timezone)}`}</p>
                <p className="p-print txt-print-h3">{hotel_info?.hotel_name}</p>
                {createLineDash()}
                <table className="table-print">
                  <tbody>
                    {genContent1("Kasir", userProfile?.nama)}
                    {/* {genContent1("Kode Booking", item?.booking_id)} */}
                  </tbody>
                </table>
                {genBookingId("Booking ID", item?.book_id)}
                {createLineDash()}
                <p className="p-print txt-print-section-h2">
                  Informasi Booking
                </p>
                <table className="table-print">
                  <tbody>
                    {genContent1("Tipe Kamar", item?.room_type_name)}
                    {genContent1("Nomor Kamar", item?.room_number)}
                    {genContent1(
                      "Durasi",
                      getDayDuration(item?.checkin_date, item?.checkout_date) +
                        " Malam"
                    )}
                  </tbody>
                </table>
                {createLineDash()}
                <table className="table-print">
                  <tbody>
                    {genContent1("Metode Pembayaran", "Transfer Bank")}
                    {genContent1(
                      "Nama Bank",
                      `${item?.to_bank_name} ( ${item?.bank_accno} )`
                    )}
                  </tbody>
                </table>
                {createLineDash()}
                <p className="p-print txt-print-section-h2">
                  Informasi Pembayaran
                </p>
                <table className="table-print">
                  <tbody>
                    {genContent2("Total Charge", item?.total_charge)}
                  </tbody>
                </table>
              </>
            )}
          </Print>
        )}
      </div>
    </>
  );
};
export default PaymentConfirm;
