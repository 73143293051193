import React from "react";

export const MobileCardList = ({
  title,
  children,
  action_button,
  fontSizeHeader = "f14-vw",
}) => {
  return (
    <div className={"card-hms mb-3 "}>
      <div className={"card-hms-header " + fontSizeHeader}>
        <div className="col-7 px-0">{title}</div>
        {action_button}
      </div>
      <div className="px-1">
        <hr className="separator-blue mx-2 my-1" />
      </div>
      <div className="card-hms-body">{children}</div>
    </div>
  );
};
