import { Icon } from "@iconify/react";
import ReactDatePicker from "react-datepicker";
import CameraWebcam from "../../../common/CameraWebcam";
import { MobileUploadFile } from "../../../common/MobileUploadFile";
import { IS_MOBILE } from "../../../common/Responsive";
import { iconUrl } from "../../../config";

export const PaymentConfirmPopup = ({
  setfileUpload,
  fileUpload,
  dateTransfer,
  setDateTransfer,
  close,
  loading,
  confirm,
  setShowImg,
  showImg,
}) => {
  const handleDate = (val) => {
    setDateTransfer(val);
  };
  const handleCalendarOpen = () => {
    document.addEventListener(
      "touchstart",
      (event) => {
        event.stopPropagation();
      },
      true
    );
  };
  return (
    <div
      className="d-flex flex-column f8-vw py-2"
      style={{ position: "relative", width: "21rem" }}
    >
      <div className="col">
        <div className="d-flex justify-content-between">
          <div
            className={`blue-title fw-700 ${IS_MOBILE ? "f14-vw" : "f12-vw"}`}
          >
            <span className="mr-1">
              <Icon
                icon={iconUrl.formMultiple20Filled}
                width="25"
                height="25"
                color="orange"
              />
            </span>
            Konfirmasi Pembayaran
          </div>
        </div>
      </div>
      <div className="col mt-1">
        <div className="bg-white rounded-page p-2">
          <div className="">
            <label htmlFor="" className={`${IS_MOBILE ? "f12-vw" : "f8-vw"}`}>
              Upload Bukti Pembayaran
            </label>
            {IS_MOBILE ? (
              <MobileUploadFile
                setfileUpload={setfileUpload}
                title={"Add Attachment"}
                height="150px"
                fileUpload={fileUpload}
                setShowImg={setShowImg}
                showImg={showImg}
                fontSize={"f12-vw"}
              />
            ) : (
              <CameraWebcam
                setfileUpload={setfileUpload}
                title={"Upload Foto"}
                height="150px"
                fileUpload={fileUpload}
                setShowImg={setShowImg}
                showImg={showImg}
                fontSize={"f8-vw"}
              />
            )}
          </div>
          <div className="mt-2">
            <label htmlFor="" className={`${IS_MOBILE ? "f12-vw" : "f8-vw"}`}>
              Tanggal Transfer
            </label>
            <div
              className=""
              style={{
                border: "1px solid #ced4da",
                borderRadius: 5,
                padding: "4px 0",
              }}
            >
              <div className="d-flex px-2 f8-vw align-items-center">
                <Icon icon={iconUrl.calendarIcon} color="#ef9403" width={20} />
                <div className="w-100 date-wrapper">
                  <ReactDatePicker
                    selected={dateTransfer}
                    startDate={dateTransfer}
                    onChange={handleDate}
                    showTimeSelect
                    timeFormat="HH:mm"
                    className={`date-checkin-new text-left w-100 ${
                      IS_MOBILE ? "f12-vw" : "f8-vw"
                    }`}
                    dateFormat="dd MMM yyyy - HH:mm"
                    maxDate={new Date()}
                    withPortal={IS_MOBILE ? true : false}
                    onFocus={(e) => e.target.blur()}
                    onCalendarOpen={handleCalendarOpen}
                    onKeyDown={(e) => {
                      e.preventDefault();
                    }}
                    onChangeRaw={(e) => {
                      e.preventDefault();
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col px-4">
        <hr className="separator-blue my-2" />
      </div>
      <div className="col">
        <div className="text-center">
          <span className={IS_MOBILE ? "f10-vw" : "f8-vw"}>
            Apakah anda yakin ingin melanjutkan?
          </span>
        </div>
      </div>
      <div className="col my-3">
        <div className="d-flex justify-content-between">
          <div className="col-6 pl-2 pr-3">
            <button
              className={`btn btn-sm btn-block btn-outline-purple ${
                IS_MOBILE ? "f12-vw" : "f8-vw"
              }`}
              onClick={() => close()}
            >
              Back
            </button>
          </div>
          <div className="col-6 pl-3 pr-2">
            <button
              className={`btn btn-sm btn-block text-white ${
                IS_MOBILE ? "f12-vw" : "f8-vw"
              } ${
                fileUpload?.length > 0 && !loading
                  ? "btn-gradient"
                  : "btn-secondary"
              }`}
              disabled={fileUpload.length > 0 && !loading ? false : true}
              onClick={() => confirm()}
            >
              {loading && (
                <span
                  className="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
              )}
              Confirm
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
