export const searchInput = (placeholder, value, onchange, onKeyPress) => {
  return (
    <div className="">
      <input
        type="text"
        className="form-control f8-vw"
        placeholder={placeholder}
        value={value}
        onChange={onchange}
        onKeyUp={onKeyPress ? (e) => onKeyPress(e) : null}
      />
    </div>
  );
};
export const mobileSearchInput = (placeholder, value, onchange, onKeyPress) => {
  return (
    <div className="">
      <input
        type="text"
        className="form-control f12-vw"
        placeholder={placeholder}
        value={value}
        onChange={onchange}
        onKeyUp={onKeyPress ? (e) => onKeyPress(e) : null}
      />
    </div>
  );
};

export const inputContainer = (
  inpValue,
  inpPlaceholder,
  value,
  onChange,
  autoFocus = false,
  type,
  isInvalid,
  required = true,
  maxLength = 250
) => {
  return (
    <div className="form-group mb-2">
      <label htmlFor="pemesan" className="f8-vw m-0 fw-700">
        {required && <span className="red">* </span>}
        {inpValue}
      </label>
      <input
        type={type ? type : "text"}
        placeholder={inpPlaceholder}
        className={`form-control f8-vw ${isInvalid ? "is-invalid" : ""}`}
        value={value}
        onChange={onChange}
        autoFocus={autoFocus}
        maxLength={maxLength}
        style={{ boxShadow: "none" }}
      />
    </div>
  );
};
export const mobileInputContainer = (
  inpValue,
  inpPlaceholder,
  value,
  onChange,
  autoFocus,
  type,
  isInvalid,
  required = true,
  maxLength = 250
) => {
  return (
    <div className="form-group mb-2">
      <label htmlFor="pemesan" className="f12-vw m-0 fw-700">
        {required && <span className="red">* </span>}
        {inpValue}
      </label>
      <input
        type={type ? type : "text"}
        placeholder={inpPlaceholder}
        className={`form-control f12-vw ${isInvalid ? "is-invalid" : ""}`}
        value={value}
        onChange={onChange}
        autoFocus={
          autoFocus && localStorage.getItem("shift_open") ? true : false
        }
        maxLength={maxLength}
        style={{ boxShadow: "none" }}
      />
    </div>
  );
};
