import { Icon } from "@iconify/react";
import React from "react";
import { Link } from "react-router-dom";
import { iconUrl } from "../config";

const Breadcrumb = ({ branchs }) => {
  return (
    <div className="w-100">
      <ul className="d-flex ">
        {branchs?.map((branch, i, arr) => {
          return (
            <li key={i} className="">
              <Link
                to={branch.path}
                className={
                  i === arr.length - 1
                    ? "breadcrumb-data-active fw-700"
                    : "breadcrumb-data fw-600"
                }
                style={{
                  cursor: i === arr.length - 1 ? "pointer" : "default",
                }}
              >
                {branch.name}
              </Link>
              {i !== arr.length - 1 && (
                <span className="mx-2">
                  <Icon icon={iconUrl.rightArrow} width={10} color="#959595" />
                </span>
              )}
            </li>
          );
        })}
      </ul>
    </div>
  );
};
export default React.memo(Breadcrumb);
