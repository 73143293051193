import { Icon } from "@iconify/react";
import React, { useRef, useState } from "react";
import { iconUrl } from "../../../../config";

export const CustomCameraGeoTaging = ({
  fileUpload,
  onChange,
  placeholder = "Take Photo",
  height = "20rem",
  fontSize = "f12-vw",
  iconUpload = false,
  canvas_component,
}) => {
  const inputRef = useRef(null);
  const handleOpenFileInput = () => {
    inputRef.current.click();
  };
  return (
    <div>
      <div
        className=""
        style={{
          padding: 0,
        }}
        onClick={() => handleOpenFileInput()}
      >
        {canvas_component}
        {fileUpload?.length < 1 && (
          <div
            id="photo-geotagging"
            className="d-flex flex-column justify-content-center align-items-center px-0"
            style={{
              border: "2px dashed lightgray",
              height: height,
              borderRadius: 5,
              width: "100%",
              backgroundColor: "#ececec",
            }}
          >
            <div className="">
              {iconUpload ? (
                <img src="/img/icon/icon-upload.svg" alt="" width={30} />
              ) : (
                <Icon
                  icon={iconUrl.cameraAdd48Filled}
                  color="grey"
                  width={50}
                />
              )}
            </div>
            <div className={"blue fw-600 " + fontSize}>{placeholder}</div>
          </div>
        )}
      </div>
      <input
        type="file"
        ref={inputRef}
        accept="image/*"
        onChange={(e) => onChange(e)}
        capture="camera"
        style={{
          display: "none",
        }}
      />
    </div>
  );
};

export const CustomFileUpload = ({
  setfileUpload,
  height = 170,
  title = "Upload Photo",
}) => {
  const inputRef = useRef(null);
  const [imgPreview, setImgPreview] = useState(null);
  const handleOpenFileInput = () => {
    inputRef.current.click();
  };
  const handleChange = (val) => {
    let file = val.target.files;
    if (file.length > 0) {
      setImgPreview(URL.createObjectURL(file[0]));
      setfileUpload(file);
    }
  };
  return (
    <div>
      <div
        className=""
        style={{
          padding: 0,
        }}
        onClick={() => handleOpenFileInput()}
      >
        {imgPreview !== undefined &&
        imgPreview !== null &&
        imgPreview !== "" ? (
          <img
            src={imgPreview}
            alt=""
            style={{ objectFit: "cover", height: height, borderRadius: 5 }}
            width="100%"
          />
        ) : (
          <div
            className="d-flex flex-column justify-content-center align-items-center px-0"
            style={{
              border: "1px dashed #5F0593",
              height,
              borderRadius: 10,
              width: "100%",
              backgroundColor: "#EFEFEF",
            }}
          >
            <div className="">
              <Icon icon={iconUrl.cameraAdd48Filled} color="grey" width={35} />
            </div>
            <div className="f8-vw">{title}</div>
          </div>
        )}
      </div>
      <input
        type="file"
        ref={inputRef}
        accept="image/*"
        onChange={(e) => handleChange(e)}
        style={{
          display: "none",
        }}
      />
    </div>
  );
};
