import React, { useEffect, useState } from "react";
import { Icon } from "@iconify/react";
import { iconUrl } from "../config";

function Count(props) {
  const [count, setCount] = useState(0);
  const operationCount = (param) => {
    switch (param) {
      case "minus":
        setCount(count - 1);
        props.setValue(count - 1);
        break;
      case "plus":
        setCount(count + 1);
        props.setValue(count + 1);
        break;

      default:
        break;
    }
  };
  const validation = () => {
    let d = count > 1 ? true : false;
    return d;
  };

  useEffect(() => {
    setCount(props.count);
  }, [props.count]);
  return (
    <>
      <div className="count-number border-0">
        <div className="d-flex">
          <span
            className="mx-1"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              return validation() ? operationCount("minus") : null;
            }}
          >
            <Icon icon={iconUrl.minusCircleOutlined} color="#62148f" />
          </span>
          <span
            className={`mx-1 py-1  ${
              props.fontSize ? props.fontSize : "f9-vw"
            }`}
          >
            {count}
          </span>
          <span
            className="mx-1"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              operationCount("plus");
            }}
          >
            <Icon icon={iconUrl.plusCircleOutlined} color="#62148f" />
          </span>
        </div>
      </div>
    </>
  );
}
export default Count;
