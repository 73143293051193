import { Icon } from "@iconify/react";
import React, { useContext, useEffect, useRef, useState } from "react";
import ReactDatePicker from "react-datepicker";
import { iconUrl } from "../../config";
import { comboBoxDownloadReport } from "../../utils/helper";
import { is_sub_menu_action } from "../../utils/uac";
import {
  getCsvFile,
  getData,
  getErr,
  safeErrCode,
} from "../../utils/api-request";
import { formatDate, isEmpty } from "../../utils";
import { useCallback } from "react";
import { TableRoomingList } from "./TableRoomingList";
import Skeleton from "react-loading-skeleton";
import { BreadcrumbContext } from "../../common/BreadcrumbContext";
import history from "../../utils/history";
import { IS_MOBILE } from "../../common/Responsive";
import { BottomSheet } from "react-spring-bottom-sheet";
import "react-loading-skeleton/dist/skeleton.css";
import { t } from "../../common/ErrorMessage";

const RoomingList = () => {
  const {
    setpathLocation,
    openButtonSheet,
    setOpenButtonSheet,
    setShowFilter,
  } = useContext(BreadcrumbContext);
  const [startDate, setStartDate] = useState(new Date());
  const [loadingList, setLoadingList] = useState(false);
  const [error, setError] = useState("");
  const [dateList, setDateList] = useState([]);
  const [roomListData, setRoomListData] = useState([]);
  const [roomNumber, setRoomNumber] = useState([]);
  const link = useRef(null);

  const getRoomingList = useCallback((startDate) => {
    setLoadingList(true);
    const params = {
      ...(!!startDate && { start_date: formatDate(startDate) }),
    };

    let paramsFormatted = null;

    if (!isEmpty(params)) {
      paramsFormatted = new URLSearchParams(params);
    }
    const endpoint = `/api/v1/rooming_list?${
      paramsFormatted !== null ? `&${paramsFormatted}` : ""
    }`;
    getData(endpoint)
      .then((resp) => {
        setLoadingList(false);
        if (resp.message === "Success") {
          let date_range = resp.dates.split(",");
          setDateList(date_range);
          setRoomListData(resp.data);
          setRoomNumber(resp.rooms);
        } else {
          resp.json().then((err) => {
            setError(t(safeErrCode(err)));
          });
          setRoomListData([]);
          setRoomNumber([]);
          setDateList([]);
        }
      })
      .catch((error) => {
        let err = getErr(error);
        setLoadingList(false);
        setError(t(err));
        console.error(err);
        setRoomListData([]);
        setRoomNumber([]);
      });
  }, []);

  const exportCSVFile = (e) => {
    if (
      link.current.href &&
      link.current.href.substr(link.current.href.length - 6) !== "no_use"
    ) {
      return;
    }
    if (link.current.href.substr(link.current.href.length - 6) === "no_use") {
      e.preventDefault();
    }
    const params = {
      ...(!!startDate && { start_date: formatDate(startDate) }),
    };

    let paramsFormatted = null;

    if (!isEmpty(params)) {
      paramsFormatted = new URLSearchParams(params);
    }
    const endpoint = `/api/v1/rooming_list?xlsoutput=1${
      paramsFormatted !== null ? `&${paramsFormatted}` : ""
    }`;
    getCsvFile(endpoint)
      .then((resp) => {
        let now = new Date();
        let filenameServ = "rooming-list-" + formatDate(now) + ".xlsx";
        if (
          resp.headers !== undefined &&
          resp.headers !== null &&
          resp.headers.length > 1
        ) {
          filenameServ = resp.headers
            .get("Content-Disposition")
            .split("filename=")[1];
        }
        let filename = filenameServ;
        // console.log("filename-serv-download", filenameServ);
        resp.blob().then((blob) => {
          let dx = new Blob([blob]);
          const href = window.URL.createObjectURL(dx);
          link.current.download = filename;
          link.current.href = href;
          link.current.click();
          link.current.download = "no_use";
          link.current.href = "no_use";
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleCalendarOpen = () => {
    document.addEventListener(
      "touchstart",
      (event) => {
        event.stopPropagation();
      },
      true
    );
  };

  const resetFilter = () => {
    setStartDate(new Date());
  };

  useEffect(() => {
    setpathLocation(history.location.pathname);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getRoomingList(startDate);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setShowFilter(true);
    return () => {
      setShowFilter(false);
    };
  }, [setShowFilter]);

  return (
    <div className={`d-flex flex-column ${IS_MOBILE ? "px-3" : "px-4 py-1"} `}>
      {!IS_MOBILE && (
        <div className="col px-2">
          <div className="d-flex flex-row justify-content-between">
            <div className="col-md-10 px-0">
              <div className="d-flex flex-row">
                <div className="col-2 px-0 mr-2">
                  <label htmlFor="" className="f8-vw m-0 p-0">
                    Dari Tanggal
                  </label>

                  <div
                    className="date-single-input"
                    style={{
                      border: "1px solid #ced4da",
                      borderRadius: 5,
                      padding: "4px 0",
                    }}
                  >
                    <div className="d-flex px-2 f8-vw align-items-center justify-content-center">
                      <Icon
                        icon={iconUrl.calendarIcon}
                        color="#ef9403"
                        width={"1.5rem"}
                      />
                      <div className="">
                        <ReactDatePicker
                          selected={startDate}
                          onChange={(e) => setStartDate(e)}
                          className={"date-checkin-new f8-vw"}
                          dateFormat="dd MMM yyyy"
                          placeholderText="Pilih Tanggal"
                          withPortal={IS_MOBILE ? true : false}
                          onCalendarOpen={() => handleCalendarOpen()}
                          onChangeRaw={(e) => {
                            e.preventDefault();
                          }}
                          onKeyDown={(e) => {
                            e.preventDefault();
                          }}
                          onFocus={(e) => e.target.blur()}
                          preventOpenOnFocus={IS_MOBILE ? true : false}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-2 pr-0 pl-5 mt-auto">
              <div className="d-flex flex-column">
                <button
                  className="btn btn-sm search-new rounded-input btn-block mt-auto f8-vw fw-500 text-white"
                  onClick={() => {
                    getRoomingList(startDate);
                  }}
                  style={{
                    paddingTop: 7,
                    paddingBottom: 7,
                    backgroundColor: "#742B9E",
                  }}
                >
                  Search
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="d-flex flex-row align-items-center">
        {is_sub_menu_action("rv_roominglist_export") && (
          <div className={IS_MOBILE ? "" : "mt-2"}>
            <div className="d-flex">
              {comboBoxDownloadReport(link, exportCSVFile)}
            </div>
          </div>
        )}
      </div>
      <div className="col px-2 mt-0" style={{ position: "relative" }}>
        {!loadingList ? (
          <TableRoomingList
            roomNumber={roomNumber}
            dateList={dateList}
            error={error}
            list={roomListData}
          />
        ) : (
          <Skeleton count={10} height={25} />
        )}
      </div>
      {IS_MOBILE && (
        <div>
          <BottomSheet
            open={openButtonSheet}
            onDismiss={() => setOpenButtonSheet(false)}
            snapPoints={({ maxHeight }) => maxHeight}
            header={
              <div className="d-flex justify-content-between">
                <span className="purple f16-vw fw-600 px-2">Filter</span>
                <span
                  className="px-2"
                  onClick={() => setOpenButtonSheet(false)}
                >
                  <Icon icon={iconUrl.closeBold} color={"grey"} width={20} />
                </span>
              </div>
            }
            footer={
              <div className="d-flex justify-content-between">
                <div className="col-6 pl-2">
                  <button
                    className="btn btn-block btn-outline-purple f12-vw"
                    onClick={() => {
                      resetFilter();
                      setOpenButtonSheet(false);
                      getRoomingList(new Date());
                    }}
                  >
                    Reset
                  </button>
                </div>
                <div className="col-6 pr-2">
                  <button
                    className="btn btn-block btn-gradient f12-vw text-white"
                    onClick={() => {
                      setOpenButtonSheet(false);
                      getRoomingList(startDate);
                    }}
                  >
                    Search
                  </button>
                </div>
              </div>
            }
          >
            <div className="mt-3" style={{ height: "30rem" }}>
              <div className="px-4 mt-2">
                <label htmlFor="" className="f12-vw m-0 p-0">
                  Dari Tanggal
                </label>

                <div
                  className="date-single-input"
                  style={{
                    border: "1px solid #ced4da",
                    borderRadius: 5,
                    padding: "5px 0",
                  }}
                >
                  <div className="d-flex px-2 f12-vw align-items-center justify-content-center">
                    <Icon
                      icon={iconUrl.calendarIcon}
                      color="#ef9403"
                      width={"1.5rem"}
                    />
                    <div className="">
                      <ReactDatePicker
                        selected={startDate}
                        onChange={(e) => setStartDate(e)}
                        className={"date-checkin-new f12-vw"}
                        dateFormat="dd MMM yyyy"
                        placeholderText="Pilih Tanggal"
                        withPortal={IS_MOBILE ? true : false}
                        onCalendarOpen={() => handleCalendarOpen()}
                        onChangeRaw={(e) => {
                          e.preventDefault();
                        }}
                        onKeyDown={(e) => {
                          e.preventDefault();
                        }}
                        onFocus={(e) => e.target.blur()}
                        preventOpenOnFocus={IS_MOBILE ? true : false}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </BottomSheet>
        </div>
      )}
    </div>
  );
};

export default RoomingList;
