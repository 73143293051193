import React, { useContext } from "react";
import { useLocation } from "react-router";
import Breadcrumb from "../../common/Breadcrumb";
import { BreadcrumbContext } from "../../common/BreadcrumbContext";
// import HotelRules from "../../common/HotelRules";
import UserInfo from "../../common/UserInfo";
// import { iconUrl } from "../../config";
// import {
//   getClientTimeWithoutSecond,
//   getTimezoneOffsetClient,
// } from "../../utils";
// import history from "../../utils/history";

function Info() {
  const location = useLocation();
  const { pathLocation } = useContext(BreadcrumbContext);
  const getBreadcrumbLocation = (param) => {
    if (param !== undefined) {
      let d = param?.replace("-", " ");
      const textBread = d.replace(/(^\w{1})|(\s+\w{1})/g, (letter) =>
        letter.toUpperCase()
      );
      return textBread;
    }
  };

  const logData = pathLocation?.split("/");
  return (
    <div className="info-status align-items-center px-5 shadow-sm">
      <div className="col px-0">
        <Breadcrumb
          branchs={
            logData?.length <= 3
              ? [
                  {
                    name: getBreadcrumbLocation(logData[1]),
                    path: location.pathname,
                  },
                  {
                    name: getBreadcrumbLocation(logData[2]),
                    path: location.pathname,
                  },
                ]
              : [
                  {
                    name: getBreadcrumbLocation(logData[1]),
                    path: location.pathname,
                  },
                  {
                    name: getBreadcrumbLocation(logData[2]),
                    path: location.pathname,
                  },
                  {
                    name: getBreadcrumbLocation(logData[3]),
                    path: location.pathname,
                  },
                ]
          }
        />
      </div>

      {/* <div className="col-6 px-0">
        <div className="d-flex align-items-center justify-content-end">
          <div className="col-4 px-0">
            <HotelRules
              iconName={iconUrl.clockIcon}
              title="Check In Time"
              info={`${getClientTimeWithoutSecond(
                hotelDetail?.check_in_time,
                timezone
              )} ${getTimezoneOffsetClient(timezone)}`}
              iconColor={"#51b7ab"}
              fontColor="dark-blue"
            />
          </div>
          <div className="col-4 px-0">
            <HotelRules
              iconName={iconUrl.clockCircleFilled}
              title="Check Out Time"
              info={`${getClientTimeWithoutSecond(
                hotelDetail?.check_out_time,
                timezone
              )} ${getTimezoneOffsetClient(timezone)}`}
              iconColor={"#51b7ab"}
              fontColor="dark-blue"
            />
          </div>
        </div>
      </div> */}
      <div className="col-3 px-0">
        <UserInfo />
      </div>
    </div>
  );
}

export default Info;
