import React from "react";
import { IS_MOBILE } from "../../common/Responsive";
import {
  filterRoomingList,
  filterStatusRoomCustom,
  splitDateForecast,
  tableCol,
} from "../../utils";
import { EmptyData } from "../Empty/EmptyData";

const FONT_SIZE = IS_MOBILE ? "f12-vw" : "f8-vw";

export const TableRoomingList = ({ dateList, roomNumber, error, list }) => {
  return (
    <div
      className={`table-list scroll-table px-0 mt-2 table-border-line ${
        IS_MOBILE ? "hide-scrollbar" : ""
      }`}
      style={{ height: "40vh" }}
    >
      {dateList?.length > 0 ? (
        <table
          className="table table-sm table-borderless"
          style={{ borderCollapse: "separate", borderSpacing: 0 }}
        >
          <thead
            className={"sticky-table " + FONT_SIZE}
            style={{ zIndex: 100 }}
          >
            <tr
              className="text-center text-white"
              style={{ backgroundColor: "#0071A4" }}
            >
              <th
                className="align-middle py-2"
                style={{
                  position: "sticky",
                  left: 0,
                  zIndex: 50,
                  backgroundColor: "#0071A4",
                  minWidth: 100,
                  maxWidth: 100,
                }}
              >
                Room No
              </th>
              <th
                className="align-middle py-2"
                style={{
                  position: "sticky",
                  left: 100,
                  zIndex: 50,
                  backgroundColor: "#0071A4",
                }}
              >
                Status
              </th>
              {dateList.map((date_item, i) => (
                <th
                  key={i}
                  className={`align-middle py-2`}
                  style={{
                    minWidth: 42,
                    maxWidth: 42,
                    left: "-42px",
                    position: "relative",
                    zIndex: i === 0 ? -1 : 0,
                    backgroundColor: "#0071A4",
                  }}
                >
                  {splitDateForecast(date_item)}
                </th>
              ))}
            </tr>
          </thead>
          <tbody className={FONT_SIZE} style={{ border: "1px solid #ececec" }}>
            {roomNumber?.map((item, i) => {
              return (
                <tr key={i} className={tableCol(i)}>
                  <td
                    className="text-center align-middle p-0"
                    style={{
                      position: "sticky",
                      left: 0,
                      backgroundColor: "white",
                      zIndex: 55,
                    }}
                    colSpan={2}
                  >
                    <div className="d-flex justify-content-between">
                      <div
                        className="py-2"
                        style={{
                          borderRight: "1px solid #ececec",
                          minWidth: 100,
                        }}
                      >
                        {item.room_number}
                      </div>
                      <div className="py-2" style={{ width: "100%" }}>
                        <span
                          className={`px-2 ${
                            filterStatusRoomCustom(item?.status)?.className
                          }`}
                          style={{
                            borderRadius: 3,
                            fontWeight: "bold",
                            padding: 3,
                          }}
                        >
                          {filterStatusRoomCustom(item?.status)?.label}
                        </span>
                      </div>
                    </div>
                  </td>
                  {dateList?.map((date, index) => (
                    <td
                      className={`align-middle p-0`}
                      key={index}
                      style={{
                        minWidth: 42,
                        maxWidth: 42,
                        borderLeft: index === 0 ? "none" : "1px solid #ececec",
                        borderRight: index === 0 ? "none" : "1px solid #ececec",
                        borderBottom:
                          roomNumber?.length === i + 1
                            ? "1px solid #ececec"
                            : "none",
                        position: "relative",
                        left: "-42px",
                      }}
                    >
                      {filterRoomingList(
                        list,
                        item.room_number,
                        date,
                        index,
                        dateList.length
                      )}
                    </td>
                  ))}
                </tr>
              );
            })}
          </tbody>
        </table>
      ) : (
        <EmptyData errMessage={error} height="15rem" />
      )}
    </div>
  );
};
