import React from "react";

export const Loading = (props) => {
  return (
    <div className="d-flex">
      <img
        src={"/img/loading.svg"}
        width={props.width ? props.width : 64}
        height={props.width ? props.width : 64}
        className="img-fluid mx-auto"
        alt="..."
      />
    </div>
  );
};

export const LoadingOverlay = () => {
  return (
    <div className="loading-overlay">
      <div className="overlay">
        <div className="overlay__inner">
          <div className="overlay__content">
            <span className="spinner"></span>
          </div>
        </div>
      </div>
    </div>
  );
};
