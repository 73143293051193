import React from "react";
import Select from "react-select";

export const OptionHotel = (props) => {
  const customStyles = {
    valueContainer: (base) => ({
      ...base,
      position: "relative",
      bottom: 5,
      borderColor: "#742b9e",
      padding: 0,
    }),
    dropdownIndicator: (base) => ({
      ...base,
      color: "#177DAC",
      position: "relative",
      backgroundColor: "#177dac40",
      bottom: 6,
      padding: 0,
      marginLeft: 8,
      borderRadius: 3,
      width: "100%",
      ":hover": {
        color: "#177DAC",
      },
    }),

    clearIndicator: (base) => ({
      ...base,
      position: "relative",
      bottom: 4,
      left: 5,
      padding: 0,
      color: "#742b9e",
      ":hover": {
        color: "#742b9e",
      },
    }),
    indicatorSeparator: (base) => ({
      ...base,
      display: "none",
    }),
    control: (base) => ({
      ...base,
      borderRadius: "10px",
      boxShadow: "none",
      fontSize: props.fontSize ? props.fontSize : "calc(6pt + 0.312vw)",
      height: props.height ? props.height : 28.7,
      minHeight: props.height ? props.height : 28.7,
      borderColor: "#ffffff",
      ":hover": { borderColor: "#ffffff" },
    }),
    placeholder: (base) => ({
      ...base,
      position: "relative",
      bottom: props.position ? props.position : 0,
      color: "#b7b7b7",
    }),
    input: (base) => ({
      ...base,
      position: "relative",
      bottom: 0,
      display: "flex",
      alignItems: "center",
      fontSize: props.fontSize ? props.fontSize : "calc(6pt + 0.312vw)",
      color: "grey",
    }),
    singleValue: (base) => ({
      ...base,
      position: "relative",
      bottom: 0,
      display: "flex",
      alignItems: "center",
      fontSize: props.fontSize ? props.fontSize : "calc(6pt + 0.312vw)",
      color: "#0071a4",
      fontWeight: 700,
      margin: 0,
    }),
    menu: (base) => ({
      ...base,
      fontSize: props.fontSize ? props.fontSize : "calc(6pt + 0.312vw)",
      color: "grey",
      zIndex: 11,
    }),
    option: (base, { isSelected }) => ({
      ...base,
      backgroundColor: isSelected ? "#0071a4" : "ffffff",
      color: isSelected ? "#ffffff" : "#000000",
      fontWeight: isSelected ? "bold" : "normal",
      fontSize: props.fontSelect ? props.fontSelect : "calc(6pt + 0.312vw)",
      "&:hover": {
        color: isSelected ? "#ffffff" : "#000000",
        backgroundColor: isSelected ? "#0071a4" : "#ececec",
      },
    }),
  };

  return (
    <>
      <Select
        options={props.optionsValue}
        menuPlacement={props.placement ? "top" : "auto"}
        value={props.value && props.value}
        defaultValue={props.defaultValue && props.defaultValue}
        isSearchable={props.isSearchable ? props.isSearchable : false}
        styles={customStyles}
        isOptionSelected={true}
        classNamePrefix={"react-select " + props.className}
        placeholder={props.placeholder}
        onChange={props.onChange}
        autoFocus={props.focus ? props.focus : false}
        isClearable={props.clear && props.clear}
        isDisabled={props.disabled ? props.disabled : false}
        isLoading={props.loading ? props.loading : false}
        formatOptionLabel={
          !!props?.formatOptionLabel ? props.formatOptionLabel : null
        }
      />
    </>
  );
};
