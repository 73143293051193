import { Icon } from "@iconify/react";
import React, { Fragment, useEffect, useState } from "react";
import { IS_MOBILE } from "../../../common/Responsive";
import { iconUrl } from "../../../config";
import {
  editLocalQty,
  editLocalRoomCompensation,
  getCheckedItemIDS,
  getCompensationQty,
  getLocalRoomCompensation,
  saveLocalQtyRoom,
  saveLocalRoomCompensation,
} from "../../../utils";
import { grouping_compensation } from "../helper";
const FONT_SIZE = IS_MOBILE ? "f12-vw" : "f8-vw";
const FONT_SIZE_HEADER = IS_MOBILE ? "f16-vw" : "f14-vw";

const BoxList = ({
  item_name,
  onChange,
  value,
  checked,
  qty,
  operationCount,
  activities,
  activities_index,
  index_categ,
}) => {
  return (
    <div className="">
      <div
        className="d-flex flex-row align-items-center pb-1"
        style={{ borderBottom: "1px solid #e9e9e9" }}
      >
        <div className="col px-0">
          <div className="" onClick={onChange} style={{ cursor: "pointer" }}>
            <input type="checkbox" value={value} readOnly checked={checked} />
            <label
              className={"form-check-label px-2 " + FONT_SIZE}
              style={{ cursor: "pointer" }}
              htmlFor="defaultCheck1"
            >
              {item_name}
            </label>
          </div>
        </div>
        <div className="ml-auto">
          {countQty(
            qty,
            checked,
            operationCount,
            activities,
            activities_index,
            index_categ
          )}
        </div>
      </div>
    </div>
  );
};

const validation = (counter) => {
  let d = counter > 1 ? true : false;
  return d;
};

const countQty = (
  count,
  checked,
  operationCount,
  activities,
  index,
  index_categ
) => {
  return (
    <div
      className="d-flex align-items-center justify-content-between"
      style={{
        width: 75,
        borderRadius: "5px",
        border: "1px solid lightgrey",
      }}
    >
      <div
        className={`col px-0 ${
          checked && count > 1 ? "btn-count-active" : "btn-count-disable"
        }`}
        style={{ borderRadius: "4px 0 0 4px", cursor: "pointer" }}
      >
        <div
          className="text-center"
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            return validation(count)
              ? operationCount("minus", activities, index, index_categ)
              : null;
          }}
        >
          <Icon icon={iconUrl.minusIcon} color="white" width={15} />
        </div>
      </div>
      <div className="col px-0 text-center mx-1">
        <span className={FONT_SIZE}>{count}</span>
      </div>
      <div
        className={`col px-0 ${
          checked ? "btn-count-active" : "btn-count-disable"
        }`}
        style={{ borderRadius: "0 4px 4px 0", cursor: "pointer" }}
      >
        <div
          className="text-center"
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            return checked
              ? operationCount("plus", activities, index, index_categ)
              : null;
          }}
        >
          <Icon icon={iconUrl.plusIcon} color="white" width={15} />
        </div>
      </div>
    </div>
  );
};

export const CompensationPopup = ({
  compensation,
  room,
  close,
  confirm,
  mode,
  setBreakageDetail,
}) => {
  const [compensationList, setCompensationList] = useState(
    grouping_compensation(compensation)
  );
  const arrLocal = getLocalRoomCompensation();
  const [isValid, setIsValid] = useState(false);

  const [roomSelect, setRoomSelect] = useState([]);

  //   Update Check box
  const updateCheckBox = (item, index, value, index_categ) => {
    let x = [...compensationList];
    let d = [...item];
    d[index].checked = value;
    d[index].qty = value === true ? 1 : 0;
    x[index_categ].activities = d;
    setCompensationList(x);
  };
  // Operation Quantity Compensation
  const operationCount = (param, item, index, index_categ) => {
    let x = [...compensationList];
    let d = [...item];
    switch (param) {
      case "minus":
        d[index].qty = d[index].qty - 1;
        break;
      case "plus":
        d[index].qty = d[index].qty + 1;
        break;
      default:
        break;
    }
    x[index_categ].activities = d;
    return setCompensationList(x);
  };

  useEffect(() => {
    let dx = [];
    let dataTrue = [];
    for (let index = 0; index < compensationList.length; index++) {
      let x = compensationList[index].activities.filter(
        (item) => item.checked === true
      );
      dx.push(x);
    }
    for (let index = 0; index < dx.length; index++) {
      dataTrue.push.apply(dataTrue, dx[index]);
    }
    if (dataTrue?.length > 0) {
      setIsValid(true);
      setBreakageDetail(dataTrue);
    }
    return () => {
      setIsValid(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [compensationList]);

  //   Save on local storage
  const localMemo = () => {
    let data_equipment = JSON.parse(localStorage?.getItem("data_equipment"));
    let item_id = [];
    let arr = [];
    let dx = [];
    let dataTrue = [];
    for (let index = 0; index < compensationList.length; index++) {
      let x = compensationList[index].activities.filter(
        (item) => item.checked === true
      );
      dx.push(x);
      let d = x.map((data) => {
        return data.item_id;
      });
      arr.push(d);
    }
    for (let index = 0; index < arr.length; index++) {
      item_id.push.apply(item_id, arr[index]);
    }
    for (let index = 0; index < dx.length; index++) {
      dataTrue.push.apply(dataTrue, dx[index]);
    }
    let qty = dataTrue.map((item) => {
      return item.qty;
    });
    let price = dataTrue.reduce((a, v) => (a = a + v.value * v.qty), 0);
    let dxx = arrLocal?.filter((item) => item.room === roomSelect);

    let roomX = data_equipment?.filter((item) => item.room === roomSelect);
    let temp_data = roomX[0];
    temp_data.compensation = dataTrue;
    let arx = editLocalQty(data_equipment, roomSelect, temp_data);

    let temp = dxx[0];
    temp.itemids = item_id;
    temp.price = price;
    temp.qty = qty;

    let r = editLocalRoomCompensation(arrLocal, roomSelect, temp);
    setTimeout(() => {
      saveLocalRoomCompensation(r);
      saveLocalQtyRoom(arx);
      clearChecked();
      setRoomSelect([]);
      mode === "compensation" ? close() : confirm();
    }, [500]);
  };

  const clearChecked = () => {
    if (compensation) {
      setCompensationList(grouping_compensation(compensation));
    }
  };

  useEffect(() => {
    let dBackup = [...compensationList];

    for (let i = 0; i < dBackup.length; i++) {
      dBackup[i].activities.length > 0 &&
        dBackup[i].activities.map((item) => {
          return (
            (item.checked = getCheckedItemIDS(roomSelect, item.item_id)),
            (item.qty =
              getCompensationQty(roomSelect, item.item_id) !== undefined
                ? getCompensationQty(roomSelect, item.item_id)
                : 0)
          );
        });
    }
    setCompensationList(dBackup);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [roomSelect]);

  useEffect(() => {
    if (room?.length === 1) {
      setRoomSelect(room[0]);
    }
  }, [room]);

  return (
    <>
      <div
        className="d-flex flex-column px-4 py-3"
        style={{ width: IS_MOBILE ? "21rem" : "40rem" }}
      >
        <div className="col px-0">
          <div className={"blue-title fw-700 " + FONT_SIZE_HEADER}>
            <span className="mr-2">
              <Icon
                icon={iconUrl.clipboardTextSearch}
                color="orange"
                width={25}
              />
            </span>
            Loss and Breakage List
          </div>
        </div>
        <div className="col px-0">
          <hr className="separator-blue mb-0 mt-2" />
          <div className={`d-flex py-2 ${IS_MOBILE ? "f14-vw" : "f10-vw"}`}>
            <div className="mr-2 black-text">Room Number</div>
            <div className="black-text fw-700">: {room[0]}</div>
          </div>
          <hr className="separator-blue mt-0 mb-2" />
        </div>

        <div
          className={IS_MOBILE ? "scroll-popup hide-scrollbar" : "scroll-popup"}
          style={{ minHeight: "15rem", maxHeight: "28rem" }}
        >
          {compensationList?.length > 0 &&
            compensationList?.map((item, i) => {
              return (
                <Fragment key={i}>
                  <div
                    className={`col black-text px-0 fw-700 ${
                      IS_MOBILE ? "f12-vw" : "f8-vw"
                    } ${i !== 0 ? "mt-3" : ""}`}
                  >
                    {item?.category_name}
                  </div>
                  {IS_MOBILE ? (
                    <hr className="separator-grey my-0" />
                  ) : (
                    <hr className="separator-purple my-0" />
                  )}
                  <div
                    className={`col d-flex my-2 px-0 ${
                      IS_MOBILE ? "flex-column" : "flex-wrap"
                    }`}
                  >
                    {item?.activities?.length > 0 &&
                      item?.activities?.map((data, index) => {
                        return (
                          <Fragment key={index}>
                            <div
                              className={`${IS_MOBILE ? "col" : "col-6"} ${
                                IS_MOBILE
                                  ? "px-0"
                                  : index % 2 === 0
                                  ? "pl-0 pr-2"
                                  : "pr-0 pl-2"
                              } pt-1`}
                            >
                              <BoxList
                                item_name={data?.item_name}
                                value={data?.value}
                                checked={data?.checked}
                                onChange={() =>
                                  updateCheckBox(
                                    item.activities,
                                    index,
                                    data.checked ? false : true,
                                    i
                                  )
                                }
                                qty={data?.qty}
                                operationCount={operationCount}
                                activities={item?.activities}
                                activities_index={index}
                                index_categ={i}
                              />
                            </div>
                          </Fragment>
                        );
                      })}
                  </div>
                </Fragment>
              );
            })}
        </div>

        <div className="d-flex mt-3">
          <div
            className={`col-2 pl-0 pr-1 purple my-auto ${FONT_SIZE} ${
              IS_MOBILE ? "col-2" : "col-4"
            }`}
            style={{ cursor: "pointer" }}
            onClick={() => clearChecked()}
          >
            {IS_MOBILE ? (
              <button className="btn btn-block bg-primaryCol-4 f12-vw">
                <span>
                  <Icon
                    icon={iconUrl.clearOutlined}
                    width={19}
                    color="purple"
                  />
                </span>
              </button>
            ) : (
              <u>Clear selected item</u>
            )}
          </div>
          <div className="col px-0">
            <div className="d-flex justify-content-between">
              <div className="col-6 pr-1 pl-0">
                <button
                  className={
                    "btn btn-block btn-outline-purple fw-700 " + FONT_SIZE
                  }
                  onClick={() => close()}
                >
                  Cancel
                </button>
              </div>
              <div className="col-6 pr-0 pl-1">
                <button
                  className={`btn btn-block text-white ${
                    (mode === "compensation" && roomSelect?.length > 0) ||
                    (mode === "breakage" && isValid)
                      ? "btn-gradient"
                      : "btn-secondary"
                  } fw-700 ${FONT_SIZE}`}
                  disabled={
                    (mode === "compensation" && roomSelect?.length > 0) ||
                    (mode === "breakage" && isValid)
                      ? false
                      : true
                  }
                  onClick={() => {
                    localMemo();
                  }}
                >
                  Confirm
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
