import { Icon } from "@iconify/react";
import React, { useEffect, useState } from "react";
import CameraWebcam from "../../../common/CameraWebcam";
import { HeaderName } from "../../../common/HeaderName";
import { MobileUploadFile } from "../../../common/MobileUploadFile";
import { IS_MOBILE } from "../../../common/Responsive";
import { iconUrl } from "../../../config";
// import MultipleWebcam from "../../../common/MultipleWebcam";
import {
  filterGuestType,
  filterPurpose,
  getDayDuration,
  getServerTimeWithoutDays,
  parsePhone,
  priceSplit,
  showMore,
  showMoreGuest,
} from "../../../utils";
import PopupModal from "../../../common/PopupModal";
import RoomingListCheckin from "./RoomingListCheckin";

export const CheckinDetail = ({
  detailCheckin,
  setcurrentPage,
  roomList,
  roomNumber,
  guestName,
  setGuestName,
  earlyCheckin,
  setfileUpload,
  deposit,
  setDeposit,
  totalCharge,
  setTotalCharge,
  roomPrice,
  openModal,
  setMode,
  setError,
  setImgPopup,
  fileUpload,
  showImg,
  setShowImg,
  reset,
  setpathLocation,
  roomingList,
  checkNextPage,
  hasMore,
  loadingRoomingList,
  errorRoomingList,
  handleSelect,
  errorEarlyCheckin,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const detailInformation = (title, value) => {
    return (
      <div className="d-flex flex-row ">
        <div className="col px-0">
          <span className="grey f8-vw">{title}</span>
        </div>
        <div className="col px-0">
          <span className="text-black fw-600 f8-vw">: {value}</span>
        </div>
      </div>
    );
  };
  const mobileDetailInformation = (title, value) => {
    return (
      <div className="d-flex flex-row ">
        <div className="col-4 px-0">
          <span className="grey f12-vw">{title}</span>
        </div>
        <div className="col px-1">
          <span className="text-black fw-600 f12-vw">: {value}</span>
        </div>
      </div>
    );
  };
  const inputContainer = (
    inpValue,
    inpPlaceholder,
    value,
    onChange,
    autoFocus,
    type,
    isInvalid
  ) => {
    return (
      <div className="form-group my-1">
        <label htmlFor="pemesan" className="f8-vw m-0 fw-600">
          {inpValue}
        </label>
        <input
          type={type ? type : "text"}
          placeholder={inpPlaceholder}
          className={`form-control f8-vw ${isInvalid ? "is-invalid" : ""}`}
          value={value}
          onChange={onChange}
          autoFocus={
            autoFocus && localStorage.getItem("shift_open") ? true : false
          }
        />
      </div>
    );
  };
  const mobileInputContainer = (
    inpValue,
    inpPlaceholder,
    value,
    onChange,
    autoFocus,
    type,
    isInvalid
  ) => {
    return (
      <div className="form-group my-1">
        <label htmlFor="pemesan" className="f12-vw m-0 fw-600">
          {inpValue}
        </label>
        <input
          type={type ? type : "text"}
          placeholder={inpPlaceholder}
          className={`form-control f12-vw ${isInvalid ? "is-invalid" : ""}`}
          value={value}
          onChange={onChange}
          autoFocus={
            autoFocus && localStorage.getItem("shift_open") ? true : false
          }
        />
      </div>
    );
  };

  const detailPayment = (title, value, color, onClick) => {
    return (
      <div className="d-flex flex-row f8-vw">
        <div className="col px-0">
          <span className={` ${color ? color : "text-black"}`}>{title}</span>
        </div>
        <div className="col px-0">
          <div className="d-flex flex-row justify-content-between">
            <div className={` ${color ? color : "text-black"}`}>Rp</div>
            {onClick ? (
              <div
                className="col ml-1 px-0 text-right text-black "
                style={{
                  cursor: "pointer",
                  backgroundColor: "#EFE5F4",
                  borderRadius: "5px",
                  fontWeight: "bold",
                }}
                onClick={onClick}
              >
                <div className="d-flex justify-content-between">
                  <span className="px-2">
                    <Icon icon={iconUrl?.infoCircle} />
                  </span>
                  <span className="pr-2 f8-vw">{priceSplit(value)}</span>
                </div>
              </div>
            ) : (
              <>
                <div
                  className={`col px-0 text-right  ${
                    color ? "f8-vw fw-600 " + color : "text-black"
                  }`}
                >
                  <span className="pr-2">{priceSplit(value)}</span>
                </div>
              </>
            )}
          </div>
          {color && value > 0 && (
            <div className="px-2" style={{ position: "relative" }}>
              <small
                className="f4-vw grey"
                style={{ position: "absolute", top: 0, right: 5 }}
              >
                Sudah termasuk pajak
              </small>
            </div>
          )}
        </div>
      </div>
    );
  };
  const mobileDetailPayment = (title, value, color, onClick) => {
    return (
      <div className="d-flex flex-row f12-vw">
        <div className="col px-0">
          <span className={` ${color ? color : "text-black"}`}>{title}</span>
        </div>
        <div className="col px-0">
          <div className="d-flex flex-row justify-content-between">
            <div className={` ${color ? color : "text-black"}`}>Rp</div>
            {onClick ? (
              <div
                className="col ml-1 px-0 text-right text-black "
                style={{
                  cursor: "pointer",
                  backgroundColor: "#EFE5F4",
                  borderRadius: "5px",
                  fontWeight: "bold",
                }}
                onClick={onClick}
              >
                <div className="d-flex justify-content-between">
                  <span className="px-2">
                    <Icon icon={iconUrl?.infoCircle} />
                  </span>
                  <span className="pr-2 f12-vw">{priceSplit(value)}</span>
                </div>
              </div>
            ) : (
              <>
                <div
                  className={`col px-0 text-right  ${
                    color ? "f12-vw fw-600 " + color : "text-black"
                  }`}
                >
                  <span className="pr-2">{priceSplit(value)}</span>
                </div>
              </>
            )}
          </div>
          {color && value > 0 && (
            <div className="px-2" style={{ position: "relative" }}>
              <small
                className="f4-vw grey"
                style={{ position: "absolute", top: 0, right: 5 }}
              >
                Sudah termasuk pajak
              </small>
            </div>
          )}
        </div>
      </div>
    );
  };

  const depositInput = (title, value, onChange) => {
    return (
      <div className="d-flex flex-row align-items-center">
        <div className="col px-0 f8-vw">{title}</div>
        <div className="col px-0">
          <div className="d-flex flex-row align-items-center">
            <span className="pr-2 f8-vw">Rp</span>
            <input
              type="text"
              className="form-control f8-vw rounded-input text-right"
              style={{ paddingRight: 7 }}
              onChange={onChange}
              value={priceSplit(value)}
              onFocus={(e) => (e.target.value = "")}
              maxLength={12}
            />
          </div>
        </div>
      </div>
    );
  };
  const mobileDepositInput = (title, value, onChange) => {
    return (
      <div className="d-flex flex-row align-items-center">
        <div className="col px-0 f12-vw">{title}</div>
        <div className="col px-0">
          <div className="d-flex flex-row align-items-center">
            <span className="pr-2 f12-vw">Rp</span>
            <input
              type="text"
              className="form-control f12-vw rounded-input text-right"
              style={{ paddingRight: 7 }}
              onChange={onChange}
              value={priceSplit(value)}
              onFocus={(e) => (e.target.value = "")}
              inputMode="numeric"
              maxLength={12}
            />
          </div>
        </div>
      </div>
    );
  };

  const handleDeposit = (e) => {
    setDeposit(Number(e.target.value.replace(/\D/g, "")));
  };

  const handleGuestName = (val) => {
    if (val.target.value) {
      let d = val?.target?.value.replace(
        /(^\w{1})|(\s+\w{1})|(,+\w{1})/g,
        (letter) => letter.toUpperCase()
      );
      let text = d.replace(", ", ",");
      let guest = text?.split(",");
      setGuestName(guest);
    } else {
      setGuestName([]);
    }
  };

  useEffect(() => {
    setTotalCharge(earlyCheckin + roomPrice);
  }, [roomPrice, earlyCheckin, setTotalCharge]);

  useEffect(() => {
    setpathLocation("/reservasi/proses-check in/konfirmasi-check in");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setIsOpen(true);
    return () => {
      setIsOpen(false);
    };
  }, []);

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
    return () => {
      document.body.style.overflow = "unset";
    };
  }, [isOpen]);

  return (
    <>
      {IS_MOBILE ? (
        <>
          <div className="d-flex flex-column">
            <div className="col px-2">
              <div className="d-flex ">
                <div className="col-4 px-0 f12-vw grey">Kode Booking</div>
                <div className="col pr-0 pl-1 f12-vw fw-700">
                  : {detailCheckin?.ota_booking_id}
                </div>
              </div>
            </div>
            <div className="col px-2">
              <div className="d-flex">
                <div className="col-4 grey f12-vw px-0">Remaining</div>
                <div className="col pr-0 pl-1 f12-vw fw-700">
                  : {detailCheckin?.checkin_remaining}
                </div>
              </div>
            </div>
            <div className="col px-2 mt-2">
              <HeaderName
                text="Informasi Tamu"
                fontSize="f14-vw fw-600"
                color2="purple"
              />
              <div className="my-1">
                {mobileDetailInformation(
                  "Nama OTA",
                  detailCheckin?.ota_name ? detailCheckin?.ota_name : "-"
                )}
                {mobileDetailInformation(
                  "Nama Pemesan",
                  detailCheckin?.booked_by
                    ? showMore(detailCheckin.booked_by)
                    : "-"
                )}
                {mobileDetailInformation(
                  "Nomor Ponsel",
                  detailCheckin?.phone ? parsePhone(detailCheckin?.phone) : "-"
                )}
                {mobileDetailInformation(
                  "Email",
                  detailCheckin?.email
                    ? showMore(detailCheckin?.email, 25)
                    : "-"
                )}
                {mobileDetailInformation(
                  "Purpose",
                  detailCheckin?.purpose_id
                    ? filterPurpose(detailCheckin.purpose_id)?.label
                    : "-"
                )}
                {mobileDetailInformation(
                  "Guest Type",
                  detailCheckin?.guest_type_id
                    ? filterGuestType(detailCheckin.guest_type_id)?.label
                    : "-"
                )}
              </div>
            </div>
            <div className="col mt-2 px-2">
              <HeaderName
                text="Informasi Pemesanan"
                fontSize="f14-vw fw-600"
                color2="purple"
              />
              <div className="my-1">
                {mobileDetailInformation(
                  "Tipe Kamar",
                  detailCheckin?.room_type_name
                    ? detailCheckin?.room_type_name
                    : "-"
                )}
                {mobileDetailInformation(
                  "Check In",
                  detailCheckin?.checkin_date
                    ? getServerTimeWithoutDays(detailCheckin?.checkin_date)
                    : "-"
                )}
                {mobileDetailInformation(
                  "Check Out",
                  detailCheckin?.checkout_date
                    ? getServerTimeWithoutDays(detailCheckin?.checkout_date)
                    : "-"
                )}
                {mobileDetailInformation(
                  "Durasi",
                  detailCheckin
                    ? getDayDuration(
                        detailCheckin?.checkin_date,
                        detailCheckin?.checkout_date
                      ) + " Malam"
                    : "-"
                )}
                {mobileDetailInformation(
                  "Tamu & Kamar",
                  detailCheckin
                    ? detailCheckin.guest_qty +
                        " Tamu, " +
                        detailCheckin?.room_booked +
                        " Kamar"
                    : "-"
                )}
              </div>
            </div>

            <div className="col px-2 mt-2">
              <HeaderName
                text="Informasi Check In"
                fontSize="f14-vw fw-600"
                color2="purple"
              />
              <div className="">
                <label className="f12-vw my-0 fw-600">Nomor Kamar</label>
                <div
                  className="f12-vw px-2"
                  style={{
                    cursor: "pointer",
                    backgroundColor: "rgb(95,5,147,0.2)",
                    borderRadius: "5px",
                    padding: "8px 0",
                  }}
                  onClick={
                    roomList?.length > 0
                      ? () => {
                          openModal();
                          setMode("room");
                        }
                      : () => {
                          openModal();
                          setMode("error");
                          setError("Tidak ada Nomor Kamar yang tersedia");
                          setImgPopup("/img/error-icon/room-not-available.svg");
                        }
                  }
                >
                  <span className="purple">
                    {roomNumber?.length > 0
                      ? roomNumber.join(", ")
                      : "Pilih Nomor Kamar"}
                  </span>
                </div>
              </div>
            </div>
            <div className="col mt-1 px-2 mb-2">
              {mobileInputContainer(
                "Nama Tamu",
                "Nama Tamu di setiap Kamar",
                guestName,
                (e) => handleGuestName(e),
                false
              )}
            </div>
            <div className="col mt-2 px-2">
              <HeaderName
                text="Informasi Tagihan"
                fontSize="f14-vw fw-600"
                color2="purple"
              />
              <div className="my-1">
                {mobileDetailPayment("Total Harga Kamar", roomPrice)}
                <div className="mt-1">
                  {mobileDetailPayment(
                    "Early Check In",
                    parseInt(earlyCheckin)
                  )}
                </div>
                {mobileDepositInput("Deposit", deposit, (e) =>
                  handleDeposit(e)
                )}
                <div className="">
                  <hr className="separator-grey my-2" />
                </div>
                {mobileDetailPayment(
                  "Total Tagihan",
                  parseInt(totalCharge),
                  "f12-vw fw-700"
                )}
              </div>
            </div>

            <div className="col px-2 mt-2">
              <HeaderName
                text="Upload KTP"
                fontSize="f14-vw fw-600"
                color2="purple"
              />
              <div className="">
                <MobileUploadFile
                  setfileUpload={setfileUpload}
                  title={"Upload KTP"}
                  height="200px"
                  fileUpload={fileUpload}
                  setShowImg={setShowImg}
                  showImg={showImg}
                  fontSize="f12-vw"
                />
              </div>
            </div>
          </div>
          <div className="px-2">
            <hr className="separator-grey" />
          </div>
          <div className="d-flex flex-row justify-content-between pb-3 px-2">
            <div className="col-6 pl-0">
              <button
                className="btn btn-outline-purple text-black f12-vw rounded-input btn-block font-weight-bold"
                onClick={() => {
                  setcurrentPage("list-checkin");
                  reset();
                }}
              >
                Back
              </button>
            </div>
            <div className="col-6 pr-0">
              <button
                className={`btn text-white f12-vw rounded-input btn-block font-weight-bold ${
                  roomNumber?.length > 0 &&
                  guestName?.length > 0 &&
                  fileUpload?.length > 0 &&
                  errorEarlyCheckin === ""
                    ? "btn-gradient"
                    : "btn-secondary"
                }`}
                disabled={
                  roomNumber?.length > 0 &&
                  guestName?.length > 0 &&
                  fileUpload?.length > 0 &&
                  errorEarlyCheckin === ""
                    ? false
                    : true
                }
                onClick={() => {
                  openModal();
                  setMode("checkin-reconfirm");
                }}
              >
                Next
              </button>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="d-flex flex-row">
            <div className="col-4 pr-0">
              <div className="d-flex flex-column">
                <div className="col px-0">
                  <div className="d-flex ">
                    <div className="col-4 px-0 f8-vw grey">Kode Booking</div>
                    <div className="col pr-0 pl-1 f8-vw fw-700">
                      : {detailCheckin?.ota_booking_id}
                    </div>
                  </div>
                </div>
                <div className="col px-0">
                  <div className="d-flex">
                    <div className="col-4 grey f8-vw px-0">Remaining</div>
                    <div className="col pr-0 pl-1 f8-vw fw-700">
                      : {detailCheckin?.checkin_remaining}
                    </div>
                  </div>
                </div>
                <div className="col mt-2 px-0">
                  <HeaderName
                    text="Informasi Tamu"
                    fontSize="f12-vw fw-600"
                    color2="purple"
                  />
                  <div className="my-1">
                    {detailInformation(
                      "Nama OTA",
                      detailCheckin?.ota_name ? detailCheckin?.ota_name : "-"
                    )}
                    {detailInformation(
                      "Nama Pemesan",
                      detailCheckin?.booked_by
                        ? showMoreGuest(detailCheckin.booked_by)
                        : "-"
                    )}
                    {detailInformation(
                      "Nomor Ponsel",
                      detailCheckin?.phone
                        ? parsePhone(detailCheckin?.phone)
                        : "-"
                    )}
                    {detailInformation(
                      "Email",
                      detailCheckin?.email
                        ? showMoreGuest(detailCheckin?.email)
                        : "-"
                    )}
                    {detailInformation(
                      "Purpose",
                      detailCheckin?.purpose_id
                        ? filterPurpose(detailCheckin.purpose_id)?.label
                        : "-"
                    )}
                    {detailInformation(
                      "Guest Type",
                      detailCheckin?.guest_type_id
                        ? filterGuestType(detailCheckin.guest_type_id)?.label
                        : "-"
                    )}
                  </div>
                </div>
                <div className="col mt-2 px-0">
                  <HeaderName
                    text="Informasi Pemesanan"
                    fontSize="f12-vw fw-600"
                    color2="purple"
                  />
                  <div className="my-1">
                    {detailInformation(
                      "Tipe Kamar",
                      detailCheckin?.room_type_name
                        ? detailCheckin?.room_type_name
                        : "-"
                    )}
                    {detailInformation(
                      "Check In",
                      detailCheckin?.checkin_date
                        ? getServerTimeWithoutDays(detailCheckin?.checkin_date)
                        : "-"
                    )}
                    {detailInformation(
                      "Check Out",
                      detailCheckin?.checkout_date
                        ? getServerTimeWithoutDays(detailCheckin?.checkout_date)
                        : "-"
                    )}
                    {detailInformation(
                      "Durasi",
                      detailCheckin
                        ? getDayDuration(
                            detailCheckin?.checkin_date,
                            detailCheckin?.checkout_date
                          ) + " Malam"
                        : "-"
                    )}
                    {detailInformation(
                      "Tamu & Kamar",
                      detailCheckin
                        ? detailCheckin.guest_qty +
                            " Tamu, " +
                            detailCheckin?.room_booked +
                            " Kamar"
                        : "-"
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-4">
              <div className="d-flex flex-column">
                <div className="col">
                  <HeaderName
                    text="Informasi Check In"
                    fontSize="f12-vw fw-600"
                    color2="purple"
                  />
                  <div className="">
                    <label className="f8-vw my-0 fw-600">Nomor Kamar</label>
                    <div
                      className="f8-vw px-2"
                      style={{
                        cursor: "pointer",
                        backgroundColor: "rgb(95,5,147,0.2)",
                        borderRadius: "5px",
                        padding: "8px 0",
                      }}
                      onClick={
                        roomList?.length > 0
                          ? () => {
                              openModal();
                              setMode("room");
                            }
                          : () => {
                              openModal();
                              setMode("error");
                              setError("Tidak ada Nomor Kamar yang tersedia");
                              setImgPopup(
                                "/img/error-icon/room-not-available.svg"
                              );
                            }
                      }
                    >
                      <span className="purple">
                        {roomNumber?.length > 0
                          ? roomNumber.join(", ")
                          : "Pilih Nomor Kamar"}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="col mt-2">
                  {inputContainer(
                    "Nama Tamu",
                    "Nama Tamu di setiap Kamar",
                    guestName,
                    (e) => handleGuestName(e),
                    false
                  )}
                </div>
                <div className="col mt-2">
                  <HeaderName
                    text="Informasi Tagihan"
                    fontSize="f12-vw fw-600"
                    color2="purple"
                  />
                  <div className="my-1">
                    {detailPayment("Total Harga Kamar", roomPrice)}
                    <div className="mt-1">
                      {detailPayment("Early Check In", parseInt(earlyCheckin))}
                    </div>
                    {depositInput("Deposit", deposit, (e) => handleDeposit(e))}
                    <div className="">
                      <hr className="separator-grey my-2" />
                    </div>
                    {detailPayment(
                      "Total Tagihan",
                      parseInt(totalCharge),
                      "f8-vw fw-700"
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-4">
              <HeaderName
                text="Upload KTP"
                fontSize="f12-vw fw-600"
                color2="purple"
              />
              <div className="mt-2">
                <CameraWebcam
                  setfileUpload={setfileUpload}
                  title={"Upload KTP"}
                  height="200px"
                  fileUpload={fileUpload}
                  setShowImg={setShowImg}
                  showImg={showImg}
                />
              </div>
            </div>
          </div>
          <div className="px-3">
            <hr className="separator-grey" />
          </div>
          <div className="d-flex flex-row justify-content-between">
            <div className="col-2">
              <button
                className="btn btn-outline-purple text-black f8-vw rounded-input btn-block font-weight-bold"
                onClick={() => {
                  setcurrentPage("list-checkin");
                  reset();
                }}
              >
                Back
              </button>
            </div>
            <div className="col-2">
              <button
                className={`btn text-white f8-vw rounded-input btn-block font-weight-bold ${
                  roomNumber?.length > 0 &&
                  guestName?.length > 0 &&
                  fileUpload?.length > 0 &&
                  errorEarlyCheckin === ""
                    ? "btn-gradient"
                    : "btn-secondary"
                }`}
                disabled={
                  roomNumber?.length > 0 &&
                  guestName?.length > 0 &&
                  fileUpload?.length > 0 &&
                  errorEarlyCheckin === ""
                    ? false
                    : true
                }
                onClick={() => {
                  openModal();
                  setMode("checkin-reconfirm");
                }}
              >
                Next
              </button>
            </div>
          </div>
        </>
      )}
      <PopupModal modalIsOpen={isOpen}>
        <RoomingListCheckin
          close={() => {
            setIsOpen(false);
          }}
          list={roomingList}
          checkNextPage={checkNextPage}
          hasMore={hasMore}
          loadingRoomingList={loadingRoomingList}
          errorRoomingList={errorRoomingList}
          handleSelect={handleSelect}
        />
      </PopupModal>
    </>
  );
};
