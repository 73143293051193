import React from "react";
import { EmptyData } from "../../Empty/EmptyData";
import { tableCol } from "../../../utils";
import InfiniteScroll from "react-infinite-scroll-component";
import { Loading } from "../../../common/Loading";
import { IS_MOBILE } from "../../../common/Responsive";

const FONT_SIZE = IS_MOBILE ? "f12-vw" : "f8-vw";
const RoomingListCheckin = ({
  list,
  close,
  checkNextPage,
  loadingRoomingList,
  hasMore,
  errorRoomingList,
  handleSelect,
}) => {
  const tableHeader = [
    "Kode Booking",
    "Room Number",
    "Room Type",
    "Guest Name",
  ];
  return (
    <div className="p-4" style={{ width: IS_MOBILE ? "21rem" : "50rem" }}>
      <div className="d-flex flex-column">
        <InfiniteScroll
          dataLength={list}
          next={checkNextPage}
          hasMore={hasMore}
          loader={<Loading />}
          endMessage={
            list?.length < 1 && (
              <EmptyData
                loading={loadingRoomingList}
                errMessage={
                  errorRoomingList
                    ? errorRoomingList
                    : "Tidak ada data Rooming List"
                }
                height={IS_MOBILE ? "10rem" : "15rem"}
              />
            )
          }
          height={"15rem"}
        >
          <div className="table-list table-responsive-sm scroll-table table-report">
            <table className="table table-sm scroll-table">
              <thead
                className={FONT_SIZE}
                style={{ position: "sticky", top: 0, zIndex: 1 }}
              >
                <tr
                  className="text-black text-center text-white"
                  style={{ backgroundColor: "#0071A4" }}
                >
                  {tableHeader.map((item, i) => {
                    return (
                      <th
                        className={"align-middle py-3"}
                        style={{ padding: 8 }}
                        key={i}
                      >
                        {item}
                      </th>
                    );
                  })}
                </tr>
              </thead>
              <tbody className={FONT_SIZE}>
                {list?.length > 0 &&
                  list?.map((item, index) => {
                    return (
                      <tr
                        className={` ${tableCol(index)} click-table`}
                        style={{
                          zIndex: 0,
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          handleSelect(item);
                          close();
                        }}
                        key={index}
                      >
                        <td>{item?.booking_id}</td>
                        <td className="text-center">{item?.room_number}</td>
                        <td>{item?.room_type_name}</td>
                        <td>{item?.guest_name}</td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
        </InfiniteScroll>
        <div className="col-md-2 col-4 px-0">
          <button
            className={`btn btn-sm btn-block btn-outline-purple ${FONT_SIZE}`}
            onClick={() => close()}
          >
            Back
          </button>
        </div>
      </div>
    </div>
  );
};

export default RoomingListCheckin;
