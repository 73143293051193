import React, { useEffect, useState } from "react";
import NotifMessage, { ButtonClose } from "../../../common/Notif";
import PopupModal from "../../../common/PopupModal";
import { addDays, formatDate, slicePhoneNumber } from "../../../utils";
import {
  addDataPict,
  getData,
  getErr,
  safeErrCode,
} from "../../../utils/api-request";
import history from "../../../utils/history";
import { OtherDetailPopup } from "./OtherDetailPopup";
import { OtherForm } from "./OtherForm";
import { IS_MOBILE } from "../../../common/Responsive";
import { MobileOtherForm } from "./MobileOtherForm";
import { t } from "../../../common/ErrorMessage";

export const OtherCheckin = ({ typeRoom }) => {
  const [otherSelected, setOtherSelected] = useState([]);
  const [otherPlatform, setOtherPlatform] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [fileUpload, setfileUpload] = useState([]);
  const [mode, setMode] = useState("");
  const [roomSelected, setRoomSelected] = useState([]);
  const [headerPopup, setHeaderPopup] = useState("");
  const [otherForm, setotherForm] = useState({
    bookingCode: "",
    orderName: "",
    phoneNumber: "",
    guest: 2,
    room: 1,
    guestName: [],
    email: "",
    roomType: "",
    checkin: new Date(),
    checkout: addDays(new Date(), 1),
    deposit: 0,
    roomNumber: [],
    totalcharge: 0,
    payment: 0,
    booking_date: new Date(),
  });
  const [formError, setFormError] = useState("");
  const [roomNumber, setRoomNumber] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [showImg, setShowImg] = useState("");
  // const [modeSuccess, setmodeSuccess] = useState("");
  // const [totalPaid, settotalPaid] = useState(0);
  // const [charge, setCharge] = useState(0);
  // const [paymentSelected, setpaymentSelected] = useState({});
  // const [paymentMethod, setpaymentMethod] = useState([]);
  // const [infoPayment, setinfoPayment] = useState([]);
  // const [bankList, setBankList] = useState([]);
  // const [bankSelected, setBankSelected] = useState({});
  // const [bankDetail, setBankDetail] = useState([]);
  const [imgPopup, setImgPopup] = useState("");
  // const [loadingList, setLoadingList] = useState(false);
  // const [errorList, setErrorList] = useState("");
  const [purposeSelected, setPurposeSelected] = useState([]);
  const [guestTypeSelected, setGuestTypeSelected] = useState([]);
  // const [detailCheckin, setDetailCheckin] = useState([]);
  // const [guestName, setGuestName] = useState([]);
  // const [otaName, setOtaName] = useState("");
  const guesttype = [
    { value: 1, label: "Personal" },
    { value: 2, label: "Group" },
  ];
  const purpose = [
    { value: 1, label: "Business Trip" },
    { value: 2, label: "Leisure/Vacation" },
    { value: 3, label: "Family Trip" },
  ];

  //   API request ==>
  const getOther = () => {
    getData("/api/v1/listotherotaname")
      .then((resp) => {
        if (resp.data) {
          let d = resp.data.map((item) => {
            return {
              value: item.ota_id,
              label: item.ota_name,
            };
          });
          setOtherPlatform(d);
        } else {
          resp.json().then((err) => {
            setError(t(safeErrCode(err)));
          });
        }
      })
      .catch((error) => {
        let err = getErr(error);
        setError(t(err));
      });
  };
  const confirmBooking = () => {
    const formData = new FormData();
    const payload = {
      room_type: roomSelected?.value,
      booked_by: otherForm?.guestName?.join(","),
      phone: slicePhoneNumber(otherForm.phoneNumber),
      email: otherForm.email,
      guest_qty: otherForm.guest,
      checkin_date: formatDate(otherForm.checkin),
      checkout_date: formatDate(otherForm.checkout),
      room_booked: otherForm.room,
      purpose_id: purposeSelected?.value,
      guest_type_id: guestTypeSelected?.value,
      booking_id: otherForm?.bookingCode,
      ota_name: otherSelected?.label,
      room_charge_ota: otherForm?.totalcharge,
      ota_booking_evidence: fileUpload[0],
      booking_date: formatDate(otherForm.booking_date),
    };

    for (const key in payload) {
      formData.append(key, payload[key]);
    }
    for (const pair of formData.entries()) {
      console.log(pair[0] + ", " + pair[1]);
    }
    addDataPict("/api/v1/order/addtolistota", formData)
      .then((resp) => {
        if (resp.message) {
          setMode("detail-success");
          setHeaderPopup("Booking Berhasil");
          setImgPopup("/img/icon-shift/confirm-close.svg");
        } else {
          resp.json().then((err) => {
            setError(t(safeErrCode(err)));
            setMode("error");
            setHeaderPopup("Booking Gagal");
            setImgPopup("/img/error-icon/booking-failed.svg");
          });
        }
      })
      .catch((error) => {
        let err = getErr(error);
        setLoading(false);
        setError(t(err));
        console.log(error);
        setMode("error");
        setHeaderPopup("Booking Gagal");
        setImgPopup("/img/error-icon/booking-failed.svg");
      });
  };
  const openModal = () => {
    setIsOpen(!isOpen);
  };
  const reset = () => {
    setfileUpload([]);
    setotherForm({ ...otherForm, totalcharge: 0 });
    setShowImg("");
  };

  //   <== UTILS

  //   useEffect
  useEffect(() => {
    getOther();
    return () => {
      setOtherPlatform([]);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
    return () => {
      document.body.style.overflow = "unset";
    };
  }, [isOpen]);

  //   useEffect
  return (
    <div
      className="tab-pane show p-3"
      id="other-ota"
      role="tabpanel"
      aria-labelledby="other-ota-tab"
    >
      {IS_MOBILE ? (
        <div className="d-flex flex-column pb-4">
          <div className="col">
            <MobileOtherForm
              otherForm={otherForm}
              typeRoom={typeRoom}
              setotherForm={setotherForm}
              formError={formError}
              setFormError={setFormError}
              otherPlatform={otherPlatform}
              otherSelected={otherSelected}
              setOtherSelected={setOtherSelected}
              roomSelected={roomSelected}
              setRoomSelected={setRoomSelected}
              roomNumber={roomNumber}
              setRoomNumber={setRoomNumber}
              mode={mode}
              setMode={setMode}
              errMessage={error}
              headerPopup={headerPopup}
              imgPopup={imgPopup}
              setImgPopup={setImgPopup}
              purpose={purpose}
              purposeSelected={purposeSelected}
              setPurposeSelected={setPurposeSelected}
              guesttype={guesttype}
              guestTypeSelected={guestTypeSelected}
              setGuestTypeSelected={setGuestTypeSelected}
              setfileUpload={setfileUpload}
              fileUpload={fileUpload}
              setShowImg={setShowImg}
              showImg={showImg}
            />
          </div>
          <div className="col">
            <hr className="separator-grey" />
          </div>
          <div className="col mt-auto">
            <div className="d-flex flex-row justify-content-between align-items-end">
              <div className="col-6 ml-auto pr-0">
                <button
                  className={`btn text-white f12-vw btn-block font-weight-bold ${
                    otherForm.guestName.length > 0 &&
                    otherForm.bookingCode !== "" &&
                    otherForm.roomType !== "" &&
                    otherForm.phoneNumber?.length >= 10 &&
                    otherForm.phoneNumber?.length <= 16 &&
                    otherSelected !== undefined &&
                    otherSelected?.length !== 0 &&
                    fileUpload.length > 0 &&
                    otherForm.totalcharge > 0
                      ? "btn-gradient"
                      : "btn-secondary"
                  }`}
                  disabled={
                    otherForm.guestName.length > 0 &&
                    otherForm.bookingCode !== "" &&
                    otherForm.roomType !== "" &&
                    otherForm.phoneNumber?.length >= 10 &&
                    otherForm.phoneNumber?.length <= 16 &&
                    otherSelected !== undefined &&
                    otherSelected?.length !== 0 &&
                    fileUpload.length > 0 &&
                    otherForm.totalcharge > 0
                      ? false
                      : true
                  }
                  onClick={() => {
                    openModal();
                    setMode("detail-ota");
                  }}
                >
                  Next
                </button>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="d-flex flex-column">
          <div className="col">
            <OtherForm
              otherForm={otherForm}
              typeRoom={typeRoom}
              setotherForm={setotherForm}
              formError={formError}
              setFormError={setFormError}
              otherPlatform={otherPlatform}
              otherSelected={otherSelected}
              setOtherSelected={setOtherSelected}
              roomSelected={roomSelected}
              setRoomSelected={setRoomSelected}
              roomNumber={roomNumber}
              setRoomNumber={setRoomNumber}
              mode={mode}
              setMode={setMode}
              errMessage={error}
              headerPopup={headerPopup}
              imgPopup={imgPopup}
              setImgPopup={setImgPopup}
              purpose={purpose}
              purposeSelected={purposeSelected}
              setPurposeSelected={setPurposeSelected}
              guesttype={guesttype}
              guestTypeSelected={guestTypeSelected}
              setGuestTypeSelected={setGuestTypeSelected}
            />
          </div>
          <div className="col" style={{ marginTop: 50, marginBottom: 15 }}>
            <hr className="separator-grey" />
          </div>
          <div className="col mt-auto">
            <div className="d-flex flex-row justify-content-between align-items-end">
              <div className="col-2 ml-auto px-0">
                <button
                  className={`btn text-white f8-vw btn-block font-weight-bold ${
                    otherForm.guestName.length > 0 &&
                    otherForm.bookingCode !== "" &&
                    otherForm.roomType !== "" &&
                    otherForm.phoneNumber?.length >= 10 &&
                    otherForm.phoneNumber?.length <= 16 &&
                    otherSelected !== undefined &&
                    otherSelected?.length !== 0
                      ? "btn-gradient"
                      : "btn-secondary"
                  }`}
                  disabled={
                    otherForm.guestName.length > 0 &&
                    otherForm.bookingCode !== "" &&
                    otherForm.roomType !== "" &&
                    otherForm.phoneNumber?.length >= 10 &&
                    otherForm.phoneNumber?.length <= 16 &&
                    otherSelected !== undefined &&
                    otherSelected?.length !== 0
                      ? false
                      : true
                  }
                  onClick={() => {
                    openModal();
                    setMode("detail-ota");
                  }}
                >
                  Next
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      {IS_MOBILE ? (
        <PopupModal modalIsOpen={isOpen}>
          {mode === "detail-ota" ? (
            <OtherDetailPopup
              detail={otherForm}
              purposeSelected={purposeSelected}
              guestTypeSelected={guestTypeSelected}
              close={() => {
                openModal();
                reset();
              }}
              setotherForm={setotherForm}
              otherSelected={otherSelected}
              setfileUpload={setfileUpload}
              confirm={confirmBooking}
              loading={loading}
              fileUpload={fileUpload}
              roomSelected={roomSelected}
              setShowImg={setShowImg}
              showImg={showImg}
            />
          ) : mode === "detail-success" ? (
            <>
              <NotifMessage
                closeModal={openModal}
                img={imgPopup}
                messageHeader={headerPopup}
                color="black"
                messageBody={"Silahkan lakukan check-in pada Proses Check-In"}
              />
              <ButtonClose
                close={() => {
                  openModal();
                  history.push("/reservasi/checkin-process");
                }}
                color="bg-green"
                text="OK"
              />
            </>
          ) : (
            <>
              <NotifMessage
                closeModal={openModal}
                img={imgPopup}
                messageHeader={headerPopup}
                color="red"
                messageBody={error}
              />
              <ButtonClose
                close={() => {
                  openModal();
                }}
                text="OK"
              />
            </>
          )}
        </PopupModal>
      ) : (
        <PopupModal modalIsOpen={isOpen}>
          {mode === "detail-ota" ? (
            <OtherDetailPopup
              detail={otherForm}
              purposeSelected={purposeSelected}
              guestTypeSelected={guestTypeSelected}
              close={() => {
                openModal();
                reset();
              }}
              setotherForm={setotherForm}
              otherSelected={otherSelected}
              setfileUpload={setfileUpload}
              confirm={confirmBooking}
              loading={loading}
              fileUpload={fileUpload}
              roomSelected={roomSelected}
              setShowImg={setShowImg}
              showImg={showImg}
            />
          ) : mode === "detail-success" ? (
            <>
              <NotifMessage
                closeModal={openModal}
                img={imgPopup}
                messageHeader={headerPopup}
                color="black"
                messageBody={"Silahkan lakukan check-in pada Proses Check-In"}
              />
              <ButtonClose
                close={() => {
                  openModal();
                  history.push("/reservasi/checkin-process");
                }}
                color="bg-green"
                text="OK"
              />
            </>
          ) : (
            <>
              <NotifMessage
                closeModal={openModal}
                img={imgPopup}
                messageHeader={headerPopup}
                color="red"
                messageBody={error}
              />
              <ButtonClose
                close={() => {
                  openModal();
                }}
                text="OK"
              />
            </>
          )}
        </PopupModal>
      )}
    </div>
  );
};
