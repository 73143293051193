import { Icon } from "@iconify/react";
import React, { useEffect, useState } from "react";
import { iconUrl } from "../../config";
import { filterStatusRoomCustom, roomLabel, tableCol } from "../../utils";
import { IS_MOBILE } from "../../common/Responsive";
import { MobileCardList } from "../../common/CardList";

export const PopupRoomNumber = (props) => {
  const [roomNumber, setRoomNumber] = useState(props.roomNumber);
  const updateChecked = (index, value) => {
    let d = [...roomNumber];
    d[index].checked = value;
    setRoomNumber(d);
  };
  const buttonToggle = (item, checked, onClick) => {
    return (
      <div className="day-box">
        <div
          className={
            !props.isPicked || checked
              ? `f8-vw day-border ${checked ? "active-indicator" : ""}`
              : !checked && `f8-vw day-disable`
          }
          onClick={!props.isPicked || checked ? onClick : null}
        >
          {item}
        </div>
      </div>
    );
  };

  const filterNumber = () => {
    let d = roomNumber.filter((item) => item.checked === true);
    let dx = d.map((item) => {
      return item.room_number;
    });
    // setChoose(d);
    props.onChange(dx);
  };
  useEffect(() => {
    filterNumber();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [roomNumber]);
  return (
    <div className="row no-gutters py-2">
      {IS_MOBILE ? (
        <div className="col" style={{ width: "21rem" }}>
          <div className="px-3 d-flex align-items-center">
            <Icon icon={iconUrl?.doorOpen} color="orange" width={20} />
            <span className="blue-title f14-vw fw-700 mx-1">Room Number</span>
          </div>
          <div
            className="px-3 scroll-table my-3 hide-scrollbar"
            style={{ maxHeight: "23rem" }}
          >
            {roomNumber?.map((item, i) => {
              return (
                <MobileCardList
                  title={`Kamar ${item?.room_number}`}
                  key={item?.room_number}
                  action_button={
                    <button
                      className={`btn btn-sm btn-block text-center ${
                        item?.checked
                          ? "btn-gradient text-white"
                          : !props.isPicked && !item?.checked
                          ? "btn-outline-purple purple"
                          : "btn-default-selected text-white"
                      }`}
                      onClick={
                        !props.isPicked || item.checked
                          ? () => updateChecked(i, item.checked ? false : true)
                          : null
                      }
                      disabled={props.isPicked && !item?.checked ? true : false}
                    >
                      <div className="">
                        <span className="mx-1 f12-vw fw-600">Pilih</span>
                        <span>
                          <Icon
                            icon={
                              props.isPicked && !item?.checked
                                ? iconUrl.disabledIcon
                                : iconUrl.checkIcon
                            }
                            width={props.isPicked && !item?.checked ? 15 : 20}
                          />
                        </span>
                      </div>
                    </button>
                  }
                >
                  <div className="card-hms-list">
                    <div className="col-4 px-0 grey-text">Tipe Kamar</div>
                    <div className="col pr-0 f12-vw black-value">
                      {roomLabel(item?.room_type)}
                    </div>
                  </div>
                  <div className="card-hms-list">
                    <div className="col-4 px-0 grey-text">Tipe Bed</div>
                    <div className="col pr-0 f12-vw black-value">
                      {item?.bed_type_name}
                    </div>
                  </div>
                  <div className="card-hms-list">
                    <div className="col-4 px-0 grey-text">Bed Size</div>
                    <div className="col pr-0 f12-vw black-value">
                      {item?.bed_size}
                    </div>
                  </div>
                  <div className="card-hms-list">
                    <div className="col-4 px-0 grey-text">Lantai</div>
                    <div className="col pr-0 f12-vw black-value">
                      {item?.floor}
                    </div>
                  </div>
                  <div className="card-hms-list">
                    <div className="col-4 px-0 grey-text">Status</div>
                    <div className="col pr-0 f12-vw black-value">
                      <div
                        className={`text-center ${
                          filterStatusRoomCustom(item?.status)?.className
                        }`}
                        style={{
                          borderRadius: 3,
                          width: "3rem",
                          fontWeight: "bold",
                        }}
                      >
                        {filterStatusRoomCustom(item?.status)?.label}
                      </div>
                    </div>
                  </div>
                </MobileCardList>
              );
            })}
          </div>
          <div className="px-3">
            <hr className="separator-blue my-2" />
          </div>
          <div className="d-flex justify-content-between py-2">
            <div className="col-5">
              <button
                className="btn btn-block btn-outline-purple f12-vw fw-600 purple"
                onClick={() => {
                  props.close();
                  props.reset();
                }}
              >
                Cancel
              </button>
            </div>
            <div className="col-5">
              <button
                className={`btn btn-block f12-vw fw-600 text-white ${
                  props.isPicked ? "btn-gradient" : "btn-secondary"
                }`}
                onClick={() => {
                  props.setRoomNumber(roomNumber);
                  props.close();
                  filterNumber();
                }}
                disabled={props.isPicked ? false : true}
              >
                Confirm
              </button>
            </div>
          </div>
        </div>
      ) : (
        <div className="col" style={{ width: "35rem" }}>
          <div className="px-3 d-flex align-items-center">
            <Icon icon={iconUrl?.doorOpen} color="orange" width={20} />
            <span className="purple f12-vw fw-700 mx-1">Room Number</span>
          </div>
          <div
            className="table-list scroll-page px-3 mt-3"
            style={{ maxHeight: "18rem", borderRadius: 10 }}
          >
            <table
              className="table table-sm text-center"
              style={{ borderRadius: 5 }}
            >
              <thead
                className="sticky-table"
                style={{ backgroundColor: "#177DAC" }}
              >
                <tr className="text-white fw-500 f8-vw">
                  <th className="py-3">No</th>
                  <th className="py-3">Tipe Kamar</th>
                  <th className="py-3">Tipe Bed (Size)</th>
                  <th className="py-3">Lantai</th>
                  <th className="py-3">Status</th>
                </tr>
              </thead>
              <tbody className="f8-vw">
                {roomNumber?.map((item, i) => {
                  return (
                    <tr key={i} className={tableCol(i)}>
                      <td className=" align-middle text-center">
                        {buttonToggle(item.room_number, item.checked, () =>
                          updateChecked(i, item.checked ? false : true)
                        )}
                      </td>
                      <td className=" align-middle">
                        {roomLabel(item?.room_type)}
                      </td>
                      <td className=" align-middle">{`${item?.bed_type_name} ( ${item?.bed_size} )`}</td>
                      <td className=" align-middle">{item?.floor}</td>
                      <td className=" align-middle">
                        <div className="d-flex justify-content-center">
                          <div
                            className={` ${
                              filterStatusRoomCustom(item?.status)?.className
                            }`}
                            style={{
                              borderRadius: 5,
                              width: 35,
                              fontWeight: "bold",
                            }}
                          >
                            {filterStatusRoomCustom(item?.status)?.label}
                          </div>
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          <div className="d-flex justify-content-end mt-4">
            <div className="col-4">
              <button
                className="btn btn-block btn-outline-purple f8-vw fw-600 purple"
                onClick={() => {
                  props.close();
                  props.reset();
                }}
              >
                Cancel
              </button>
            </div>
            <div className="col-4">
              <button
                className={`btn btn-block f8-vw fw-600 text-white ${
                  props.isPicked ? "btn-gradient" : "btn-secondary"
                }`}
                onClick={() => {
                  props.setRoomNumber(roomNumber);
                  props.close();
                  filterNumber();
                }}
                disabled={props.isPicked ? false : true}
              >
                Confirm
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
