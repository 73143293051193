import React, { useContext, useEffect } from "react";
import SelectOpt from "../../../common/SelectOpt";
import {
  generateBookingCode,
  getServerTimeWithoutDays,
  showMore,
  showMoreGuest,
  tableCol,
} from "../../../utils";
import { isValidAccess, is_sub_menu_action } from "../../../utils/uac";
import { EmptyData, MobileEmptyData } from "../../Empty/EmptyData";
import { searchInput } from "../bookingHelper";
import { BreadcrumbContext } from "../../../common/BreadcrumbContext";
import { IS_MOBILE } from "../../../common/Responsive";
import { MobileCardList } from "../../../common/CardList";
import { Icon } from "@iconify/react";
import { iconUrl } from "../../../config";
import { lessThanTime, lessThanToday } from "../../loss-found/common/utils";

export const CheckinList = ({
  search,
  setSearch,
  list,
  setDetailCheckin,
  setcurrentPage,
  otaList,
  otaSelected,
  setOtaSelected,
  searchFilter,
  setMode,
  openModal,
  loadingList,
  errorList,
  onKeyPress,
}) => {
  const { setpathLocation } = useContext(BreadcrumbContext);
  const tableHeader = [
    "Kode Booking",
    "Nama OTA",
    "Pemesan",
    "Check In",
    "Check Out",
    "Tipe Kamar",
    "Room Qty",
    "Remaining",
    "Action",
  ];

  const handleOtaSelected = (val) => {
    if (!val) {
      setOtaSelected([]);
    } else {
      setOtaSelected(val);
    }
  };
  useEffect(() => {
    setpathLocation("/reservasi/proses-check in");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      {IS_MOBILE ? (
        <div className="d-flex flex-column p-1 mb-5">
          <div className="col px-0">
            {list?.length > 0 ? (
              list.map((item) => {
                const timezone = localStorage.getItem("timezone");
                return (
                  <MobileCardList
                    title={
                      <>
                        <div
                          className="f10-vw fw-500"
                          style={{ color: "#959595" }}
                        >
                          Kode Booking
                        </div>
                        <div>{item?.ota_booking_id}</div>
                      </>
                    }
                    key={item?.ota_booking_id}
                    action_button={
                      isValidAccess([
                        "rv_check_in_process",
                        "rv_check_in_cancel_booking",
                        "rv_check_in_edit_booking",
                      ]) && (
                        <div className="dropdown combo-box-mobile col px-0">
                          <button
                            className="btn btn-sm btn-gradient text-white dropdown-toggle border-0 btn-block f12-vw fw-600"
                            type="button"
                            data-toggle="dropdown"
                            aria-expanded="false"
                          >
                            Action
                            <span className="ml-2">
                              <Icon icon={iconUrl.arrowDownAlt2} width={15} />
                            </span>
                          </button>
                          <div className="dropdown-menu dropdown-menu-center">
                            {is_sub_menu_action("rv_check_in_process") && (
                              <button
                                className="menu border-0 purple bg-transparent f12-vw font-weight-bold btn-block m-0"
                                onClick={() => {
                                  setDetailCheckin(item);
                                  setcurrentPage("detail-checkin");
                                }}
                              >
                                Check In
                              </button>
                            )}

                            {item?.checkin_remaining === item?.room_booked &&
                              is_sub_menu_action(
                                "rv_check_in_cancel_booking"
                              ) &&
                              item?.ota_name !== "RNA" &&
                              item?.ota_name !== "HMS" && (
                                <button
                                  className="menu border-0 purple bg-transparent f12-vw font-weight-bold btn-block m-0"
                                  onClick={() => {
                                    setMode("cancel-booking");
                                    openModal();
                                    setDetailCheckin(item);
                                  }}
                                >
                                  Cancel Booking
                                </button>
                              )}

                            {item?.checkin_remaining === item?.room_booked &&
                              is_sub_menu_action("rv_check_in_edit_booking") &&
                              lessThanTime(item?.checkout_date) &&
                              // item?.ota_name !== "RNA" &&
                              item?.ota_name !== "HMS" && (
                                <button
                                  className="menu border-0 purple bg-transparent f12-vw font-weight-bold btn-block m-0"
                                  onClick={() => {
                                    setDetailCheckin(item);
                                    setMode("edit-booking");
                                    openModal();
                                  }}
                                >
                                  Edit
                                </button>
                              )}
                            {item?.checkin_remaining === item?.room_booked &&
                              item?.ota_name !== "RNA" &&
                              lessThanToday(item?.checkin_date) &&
                              is_sub_menu_action("rv_check_in_reschedule") && (
                                <button
                                  className="menu border-0 purple bg-transparent f12-vw font-weight-bold btn-block m-0"
                                  onClick={() => {
                                    setDetailCheckin(item);
                                    setMode("reschedule");
                                    openModal();
                                  }}
                                >
                                  Reschedule
                                </button>
                              )}
                          </div>
                        </div>
                      )
                    }
                    fontSizeHeader="f10-vw"
                  >
                    <div className="card-hms-list">
                      <div className="col-4 px-0 grey-text">Nama OTA</div>
                      <div className="col pr-0 f12-vw black-value">
                        {item?.ota_name}
                      </div>
                    </div>
                    <div className="card-hms-list">
                      <div className="col-4 px-0 grey-text">Nama Pemesan</div>
                      <div className="col pr-0 f12-vw black-value">
                        {showMore(item?.booked_by, 25)}
                      </div>
                    </div>
                    <div className="card-hms-list">
                      <div className="col-4 px-0 grey-text">Check In</div>
                      <div className="col pr-0 f12-vw black-value">
                        {getServerTimeWithoutDays(item?.checkin_date, timezone)}
                      </div>
                    </div>
                    <div className="card-hms-list">
                      <div className="col-4 px-0 grey-text">Check Out</div>
                      <div className="col pr-0 f12-vw black-value">
                        {getServerTimeWithoutDays(
                          item?.checkout_date,
                          timezone
                        )}
                      </div>
                    </div>
                    <div className="card-hms-list">
                      <div className="col-4 px-0 grey-text">Tipe Kamar</div>
                      <div className="col pr-0 f12-vw black-value">
                        {item?.room_type_name}
                      </div>
                    </div>
                    <div className="card-hms-list">
                      <div className="col-4 px-0 grey-text">Remaining</div>
                      <div className="col pr-0 f12-vw black-value">
                        {item?.checkin_remaining}
                      </div>
                    </div>
                  </MobileCardList>
                );
              })
            ) : (
              <div style={{ position: "relative" }}>
                <MobileEmptyData
                  errMessage={errorList}
                  loading={loadingList}
                  height="40vh"
                />
              </div>
            )}
          </div>
        </div>
      ) : (
        <div className="d-flex flex-column px-3 py-1">
          <div className="col px-0">
            <div className="d-flex flex-row justify-content-between">
              <div className="col-md-9 px-0">
                <div className="d-flex flex-row">
                  <div className="col px-0 mr-1">
                    <label htmlFor="" className="f8-vw m-0 p-0 fw-600">
                      Nama OTA
                    </label>
                    <SelectOpt
                      placeholder="Pilih OTA"
                      optionsValue={otaList}
                      onChange={(e) => handleOtaSelected(e)}
                      clear={true}
                      value={otaSelected}
                    />
                  </div>
                  <div className="col px-0 mr-1">
                    <label htmlFor="" className="f8-vw m-0 p-0 fw-600">
                      Kode Booking
                    </label>
                    {searchInput(
                      "Masukkan Kode Booking",
                      search.booking_id,
                      (e) =>
                        setSearch({
                          ...search,
                          booking_id: generateBookingCode(e.target.value),
                        }),
                      onKeyPress
                    )}
                  </div>
                  <div className="col px-0 mr-1">
                    <label htmlFor="" className="f8-vw m-0 p-0 fw-600">
                      Nama Tamu
                    </label>
                    {searchInput(
                      "Cari Tamu",
                      search.guestname,
                      (e) =>
                        setSearch({
                          ...search,
                          guestname: e.target.value,
                        }),
                      onKeyPress
                    )}
                  </div>
                </div>
              </div>
              <div className="col-md-2 pr-0 pl-5 mt-auto">
                <div className="d-flex flex-column">
                  <button
                    className="btn btn-sm search-new rounded-input btn-block mt-auto f8-vw fw-500 text-white"
                    onClick={searchFilter}
                    style={{
                      paddingTop: 7,
                      paddingBottom: 7,
                      backgroundColor: "#742B9E",
                    }}
                  >
                    Search
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="col px-0 mt-3">
            <div
              className="table-list table-responsive-sm scroll-table table-report"
              style={{
                height: "24rem",
              }}
            >
              <table className="table table-sm scroll-table">
                <thead
                  className="f8-vw"
                  style={{ position: "sticky", top: 0, zIndex: 1 }}
                >
                  <tr
                    className="text-black text-center text-white"
                    style={{ backgroundColor: "#0071A4" }}
                  >
                    {tableHeader.map((item, i) => {
                      return (
                        <th
                          className={"align-middle py-3"}
                          style={{ padding: 8 }}
                          key={i}
                        >
                          {item}
                        </th>
                      );
                    })}
                  </tr>
                </thead>
                <tbody className="f8-vw">
                  {list?.length > 0 ? (
                    list.map((item, index) => {
                      const timezone = localStorage.getItem("timezone");
                      return (
                        <tr
                          className={` ${tableCol(index)}`}
                          style={{
                            zIndex: 0,
                          }}
                          key={index}
                        >
                          <td className="text-left align-middle font-weight-bold">
                            <div>{item?.ota_booking_id}</div>
                          </td>
                          <td className="text-left align-middle">
                            {item?.ota_name}
                          </td>
                          <td className=" text-left align-middle">
                            {showMoreGuest(item?.booked_by)}
                          </td>
                          <td className="text-left align-middle">
                            <div className="" style={{ width: 80 }}>
                              {getServerTimeWithoutDays(
                                item?.checkin_date,
                                timezone
                              )}
                            </div>
                          </td>
                          <td className="text-left align-middle">
                            <div className="" style={{ width: 80 }}>
                              {getServerTimeWithoutDays(
                                item?.checkout_date,
                                timezone
                              )}
                            </div>
                          </td>
                          <td className="text-wrap text-left align-middle">
                            <div className="" style={{ width: 110 }}>
                              {item?.room_type_name}
                            </div>
                          </td>
                          <td className="text-center align-middle">
                            {item?.room_booked}
                          </td>
                          <td className="text-center align-middle">
                            {item?.checkin_remaining}
                          </td>
                          <td
                            className="text-wrap text-center align-middle"
                            style={{ backgroundColor: "white" }}
                          >
                            {isValidAccess([
                              "rv_check_in_process",
                              "rv_check_in_cancel_booking",
                              "rv_check_in_edit_booking",
                              "rv_check_in_reschedule",
                            ]) && (
                              <div className="combo-box">
                                <div className="dropdown">
                                  <button
                                    className="bg-transparent dropdown-toggle border-0 blue btn-block f8-vw fw-700"
                                    type="button"
                                    id="dropdownMenu2"
                                    data-toggle="dropdown"
                                    aria-expanded="false"
                                  >
                                    Action
                                  </button>
                                  <div
                                    className="dropdown-menu dropdown-menu-right"
                                    aria-labelledby="dropdownMenu2"
                                  >
                                    <div className="d-flex flex-column">
                                      {is_sub_menu_action(
                                        "rv_check_in_process"
                                      ) && (
                                        <button
                                          className="menu border-0 dark-blue bg-transparent f8-vw font-weight-bold "
                                          onClick={() => {
                                            setDetailCheckin(item);
                                            setcurrentPage("detail-checkin");
                                          }}
                                        >
                                          Check In
                                        </button>
                                      )}

                                      {item?.checkin_remaining ===
                                        item?.room_booked &&
                                        item?.ota_name !== "RNA" &&
                                        item?.ota_name !== "HMS" &&
                                        is_sub_menu_action(
                                          "rv_check_in_cancel_booking"
                                        ) && (
                                          <button
                                            className="menu border-0 dark-blue bg-transparent f8-vw font-weight-bold "
                                            onClick={() => {
                                              setMode("cancel-booking");
                                              openModal();
                                              setDetailCheckin(item);
                                            }}
                                          >
                                            Cancel Booking
                                          </button>
                                        )}

                                      {item?.checkin_remaining ===
                                        item?.room_booked &&
                                        is_sub_menu_action(
                                          "rv_check_in_edit_booking"
                                        ) &&
                                        lessThanTime(item?.checkout_date) &&
                                        // item?.ota_name !== "RNA" &&
                                        item?.ota_name !== "HMS" && (
                                          <button
                                            className="menu border-0 dark-blue bg-transparent f8-vw font-weight-bold "
                                            onClick={() => {
                                              setDetailCheckin(item);
                                              setMode("edit-booking");
                                              openModal();
                                            }}
                                          >
                                            Edit
                                          </button>
                                        )}

                                      {item?.checkin_remaining ===
                                        item?.room_booked &&
                                        item?.ota_name !== "RNA" &&
                                        lessThanToday(item?.checkin_date) &&
                                        is_sub_menu_action(
                                          "rv_check_in_reschedule"
                                        ) && (
                                          <button
                                            className="menu border-0 dark-blue bg-transparent f8-vw font-weight-bold "
                                            onClick={() => {
                                              setDetailCheckin(item);
                                              setMode("reschedule");
                                              openModal();
                                            }}
                                          >
                                            Reschedule
                                          </button>
                                        )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}
                          </td>
                        </tr>
                      );
                    })
                  ) : (
                    <tr>
                      <td
                        colSpan={10}
                        style={{
                          padding: 0,
                        }}
                      >
                        <EmptyData
                          text="Semua booking sudah dibayar"
                          loading={loadingList}
                          errMessage={errorList}
                          sourceImg={"/img/booking/checkin-process.svg"}
                          height="18rem"
                        />
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
