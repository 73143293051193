import { Icon } from "@iconify/react";
import React, {
  Fragment,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { BreadcrumbContext } from "../../common/BreadcrumbContext";
import {
  DateRangeFilterMobile,
  DateRangeFilterNew,
} from "../../common/DateRange";
import {
  addDays,
  changeCol,
  filterStringValue,
  formatDate,
  generateBookingCode,
  getServerTime,
  getTimezoneOffsetClient,
  isEmpty,
  paymentLabel,
  priceSplit,
  tableCol,
} from "../../utils";
import { getData, getErr, safeErrCode } from "../../utils/api-request";
import { mobileSearchInput, searchInput } from "../booking/bookingHelper";
import { Loading } from "../../common/Loading";
import { EmptyData, MobileEmptyData } from "../Empty/EmptyData";
import { Print } from "../../print/Print";
import {
  createLineDash,
  createLineSolid,
  genBookingId,
  genContent1,
  genContent2,
  genPaymentID,
} from "../../print/common/printer-helper";
import { getClientTimeWithoutSecond, getDayDuration } from "../../utils/index";
import { process_type } from "./utils/helper";
import { is_sub_menu_action } from "../../utils/uac";
import { iconUrl } from "../../config";
import { IS_MOBILE } from "../../common/Responsive";
import { MobileCardList } from "../../common/CardList";
import { BottomSheet } from "react-spring-bottom-sheet";
import { t } from "../../common/ErrorMessage";

const RePrintCheckoutBilling = () => {
  const timezone = localStorage.getItem("timezone");
  const hotel_info = JSON.parse(localStorage.getItem("info_hotel"));
  const {
    setpathLocation,
    isOpeningShift,
    openButtonSheet,
    setOpenButtonSheet,
    setShowFilter,
  } = useContext(BreadcrumbContext);
  const [listPrintBilling, setListPrintBilling] = useState([]);
  const mounted = useRef(false);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [range, setRange] = useState({
    start: new Date(),
    end: new Date(),
    page: 1,
  });
  const [roomNumber, setroomNumber] = useState("");
  const [bookingId, setBookingId] = useState("");
  const [billingId, setBillingId] = useState("");
  const [hasMore, sethasMore] = useState(false);
  const [item, setItem] = useState({});
  const [isPrint, setIsPrint] = useState(false);

  const checkNextPage = () => {
    if (hasMore) {
      let page = range.page;
      let pagi = page + 1;
      setRange({ ...range, page: pagi });
      getlistPrintBilling(pagi, false, range.start, range.end);
    }
  };

  const getlistPrintBilling = (pagi, is_init, start, end) => {
    let datas = [];
    setLoading(true);
    const params = {
      ...(!!start && { start_date: formatDate(start) }),
      ...(!!end && { end_date: formatDate(end) }),
      ...(!!roomNumber && { room_number: roomNumber }),
      ...(!!bookingId && { booking_id: bookingId }),
      ...(!!billingId && { billing_id: billingId }),
      ...{ page: pagi },
    };
    let paramsFormatted = null;

    if (!isEmpty(params)) {
      paramsFormatted = new URLSearchParams(params);
    }
    let url = `/api/v1/receipt/checkout/billing?${
      paramsFormatted !== null ? `&${paramsFormatted}` : ""
    }`;
    getData(url)
      .then((resp) => {
        if (mounted.current) {
          setLoading(false);
          if (resp.data) {
            setLoading(false);
            setError("");
            if (is_init) {
              datas = resp.data.data;
            } else {
              let x = [];
              x = listPrintBilling;
              datas = [...x, ...resp.data.data];
            }
            sethasMore(resp.data.next_page);
            setListPrintBilling(datas);
          } else {
            resp.json().then((err) => {
              setError(t(safeErrCode(err)));
            });
          }
        }
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
        let err = getErr(error);
        setError(t(err));
      });
  };

  const handleCreateStart = (val) => {
    setRange({ ...range, start: val, end: addDays(val, 1) });
  };
  const handleCreateEnd = (val) => {
    setRange({ ...range, end: val });
  };

  const handleEnter = (e) => {
    if (e.key === "Enter") {
      getlistPrintBilling(1, true, range.start, range.end);
      setListPrintBilling([]);
      setRange({ ...range, page: 1 });
    }
  };

  const resetFilter = () => {
    setRange({ ...range, start: new Date(), end: new Date(), page: 1 });
    setBillingId("");
    setBookingId("");
    setroomNumber("");
    setListPrintBilling([]);
  };

  useEffect(() => {
    if (isPrint) {
      setTimeout(() => {
        setIsPrint(false);
      }, 6000);
    }
  }, [isPrint]);
  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);
  useEffect(() => {
    if (
      mounted.current &&
      roomNumber === "" &&
      bookingId === "" &&
      billingId === ""
    ) {
      getlistPrintBilling(1, true, range.start, range.end);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [billingId, bookingId, roomNumber]);

  useEffect(() => {
    setpathLocation("/checkout/re-printing/billing");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    setShowFilter(true);
    return () => {
      setShowFilter(false);
    };
  }, [setShowFilter]);
  return (
    <div
      className={`check-out-page bg-white my-1 ${
        !isOpeningShift ? "" : "not-opening"
      }`}
    >
      {IS_MOBILE ? (
        <div className="d-flex flex-column px-3 py-1 mb-5">
          <div className="col px-1">
            <InfiniteScroll
              dataLength={listPrintBilling.length}
              next={checkNextPage}
              hasMore={hasMore}
              loader={<Loading />}
              endMessage={
                listPrintBilling?.length < 1 && (
                  <div style={{ position: "relative" }}>
                    <MobileEmptyData
                      errMessage={error}
                      loading={loading}
                      height="40vh"
                    />
                  </div>
                )
              }
            >
              {listPrintBilling.map((item, i) => {
                return (
                  <MobileCardList
                    title={
                      <>
                        <div
                          className="f10-vw fw-500"
                          style={{ color: "#959595" }}
                        >
                          Kode Booking
                        </div>
                        <div>{item?.book_id}</div>
                      </>
                    }
                    key={i}
                    fontSizeHeader="f10-vw"
                    action_button={
                      is_sub_menu_action("ch_checkout_billing_print") && (
                        <>
                          {item?.payment_status === 0 ||
                          item?.payment_status === 2 ? (
                            <button
                              className="btn border-0 btn-sm btn-gradient text-white btn-block f12-vw fw-700 "
                              onClick={() => {
                                setItem(item);
                                setIsPrint(true);
                              }}
                            >
                              <span className="mr-1">
                                <Icon icon={iconUrl.print20Filled} width={18} />
                              </span>
                              <span>Print</span>
                            </button>
                          ) : null}
                        </>
                      )
                    }
                  >
                    <div className="card-hms-list">
                      <div className="col-4 px-0 grey-text">Nomor Kamar</div>
                      <div className="col pr-0 f12-vw black-value">
                        {item?.room_number}
                      </div>
                    </div>
                    <div className="card-hms-list">
                      <div className="col-4 px-0 grey-text">Billing ID</div>
                      <div className="col pr-0 f12-vw black-value">
                        {item?.billing_id}
                      </div>
                    </div>

                    <div className="card-hms-list">
                      <div className="col-4 px-0 purple fw-700">Tagihan</div>
                    </div>
                    <div className="card-hms-list">
                      <div className="col-4 px-0 grey-text">Room Charge</div>
                      <div className="col pr-0">
                        <div className="d-flex justify-content-between">
                          <span className="f12-vw black-value">Rp</span>
                          <span className="f12-vw black-value">
                            {priceSplit(item?.room_charge)}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="card-hms-list">
                      <div className="col-4 px-0 grey-text">Late Checkout</div>
                      <div className="col pr-0">
                        <div className="d-flex justify-content-between">
                          <span className="f12-vw black-value">Rp</span>
                          <span className="f12-vw black-value">
                            {priceSplit(item?.late_checkout)}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="card-hms-list">
                      <div className="col-4 px-0 grey-text">Ganti Rugi</div>
                      <div className="col pr-0">
                        <div className="d-flex justify-content-between">
                          <span className="f12-vw black-value">Rp</span>
                          <span className="f12-vw black-value">
                            {priceSplit(item?.ganti_rugi)}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="card-hms-list">
                      <div className="col-4 px-0 grey-text">Denda</div>
                      <div className="col pr-0">
                        <div className="d-flex justify-content-between">
                          <span className="f12-vw black-value">Rp</span>
                          <span className="f12-vw black-value">
                            {priceSplit(item?.denda)}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="card-hms-list">
                      <div className="col-4 px-0 grey-text fw-700">Total</div>
                      <div className="col pr-0">
                        <div className="d-flex justify-content-between">
                          <span className="fw-700 f12-vw black-value">Rp</span>
                          <span className="fw-700 f12-vw black-value">
                            {priceSplit(item?.total_charge)}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="card-hms-list">
                      <div className="col-4 px-0 grey-text fw-700">
                        Deposit (Cash)
                      </div>
                      <div className="col pr-0">
                        <div className="d-flex justify-content-between">
                          <span className="fw-700 f12-vw black-value">Rp</span>
                          <span className="fw-700 f12-vw black-value">
                            {priceSplit(item?.deposit)}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="card-hms-list">
                      <div className="col-4 px-0 grey-text">Process Type</div>
                      <div className="col pr-0 f12-vw black-value">
                        {
                          filterStringValue(process_type, item?.process_type)
                            ?.label
                        }
                      </div>
                    </div>
                    <div className="card-hms-list">
                      <div className="col-4 px-0 grey-text">Status</div>
                      <div className="col pr-0 f12-vw black-value fw-700">
                        <span className={changeCol(item?.payment_status)}>
                          {paymentLabel(item?.payment_status)}
                        </span>
                      </div>
                    </div>
                  </MobileCardList>
                );
              })}
            </InfiniteScroll>
          </div>
        </div>
      ) : (
        <div className="d-flex flex-column px-4 py-1">
          <div className="col px-2">
            <div className="d-flex flex-row justify-content-between">
              <div className="col-md-10 px-0">
                <div className="d-flex flex-row">
                  <div className="col px-0 mr-2">
                    <label htmlFor="" className="f8-vw m-0 p-0">
                      Created at
                    </label>
                    <DateRangeFilterNew
                      start={range.start}
                      end={range.end}
                      handleStart={handleCreateStart}
                      handleEnd={handleCreateEnd}
                      minDate={true}
                    />
                  </div>
                  <div className="col px-0 mr-2">
                    <label htmlFor="" className="f8-vw m-0 p-0">
                      Kode Booking
                    </label>
                    {searchInput(
                      "Cari Kode Booking",
                      bookingId,
                      (e) => setBookingId(generateBookingCode(e.target.value)),
                      handleEnter
                    )}
                  </div>
                  <div className="col px-0 mr-2">
                    <label htmlFor="" className="f8-vw m-0 p-0">
                      Billing ID
                    </label>
                    {searchInput(
                      "Cari Billing ID",
                      billingId,
                      (e) => setBillingId(generateBookingCode(e.target.value)),
                      handleEnter
                    )}
                  </div>
                  <div className="col px-0 mr-2">
                    <label htmlFor="" className="f8-vw m-0 p-0">
                      Nomor Kamar
                    </label>
                    {searchInput(
                      "Cari Nomor Kamar",
                      roomNumber,
                      (e) => setroomNumber(e.target.value),
                      handleEnter
                    )}
                  </div>
                </div>
              </div>
              <div className="col-md-2 pr-0 pl-5 mt-auto">
                <div className="d-flex flex-column">
                  <button
                    className="btn btn-sm search-new rounded-input btn-block mt-auto f8-vw fw-500 text-white"
                    onClick={() => {
                      getlistPrintBilling(1, true, range.start, range.end);
                      setListPrintBilling([]);
                      setRange({ ...range, page: 1 });
                    }}
                    style={{
                      paddingTop: 7,
                      paddingBottom: 7,
                      backgroundColor: "#742B9E",
                    }}
                  >
                    Search
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div className="col px-2 mt-2">
            <InfiniteScroll
              dataLength={listPrintBilling.length}
              next={checkNextPage}
              hasMore={hasMore}
              loader={<Loading />}
              endMessage={
                listPrintBilling?.length < 1 && (
                  <EmptyData
                    loading={loading}
                    errMessage={error}
                    height="15rem"
                  />
                )
              }
              className="px-0 mt-2 scroll-table table-border-line"
              height={"47vh"}
            >
              <div className="table-list table-responsive-sm ">
                <table className="table table-sm">
                  <thead
                    className="f8-vw sticky-table"
                    style={{
                      backgroundColor: "#0071A4",
                    }}
                  >
                    <tr className="text-center text-white">
                      <th rowSpan={2} className="align-middle ">
                        Booking ID
                      </th>
                      <th rowSpan={2} className="align-middle">
                        Billing ID
                      </th>
                      <th rowSpan={2} className="align-middle">
                        <div>Room</div>
                        <div>Number</div>
                      </th>
                      <th rowSpan={2} className="align-middle">
                        Deposit
                      </th>
                      <th rowSpan={1} colSpan={5}>
                        Tagihan
                      </th>
                      <th rowSpan={2} className="align-middle">
                        <div>Process</div>
                        <div>Type</div>
                      </th>
                      <th rowSpan={2} className="align-middle">
                        Status
                      </th>
                      <th rowSpan={2} className="align-middle">
                        Action
                      </th>
                    </tr>
                    <tr className="text-black text-center text-white">
                      <th style={{ width: 100 }}>Room Charge</th>
                      <th style={{ width: 100 }}>Late Checkout</th>
                      <th style={{ width: 100 }}>Ganti Rugi</th>
                      <th style={{ width: 100 }}>Denda</th>
                      <th style={{ width: 100 }}>Total</th>
                    </tr>
                  </thead>
                  <tbody className="f8-vw">
                    {listPrintBilling?.map((item, index) => (
                      <Fragment key={index}>
                        <tr className={` ${tableCol(index)}`}>
                          <td className="align-middle text-left font-weight-bold">
                            {item?.book_id}
                          </td>
                          <td className="align-middle text-center font-weight-bold">
                            {item?.billing_id}
                          </td>
                          <td className="align-middle text-center font-weight-bold">
                            {item?.room_number}
                          </td>
                          <td className="align-middle text-right">
                            {priceSplit(item?.deposit)}
                          </td>
                          <td className="align-middle text-right">
                            {priceSplit(item?.room_charge)}
                          </td>
                          <td className="align-middle text-right">
                            {priceSplit(item?.late_checkout)}
                          </td>
                          <td className="align-middle text-right">
                            {priceSplit(item?.ganti_rugi)}
                          </td>
                          <td className="align-middle text-right">
                            {priceSplit(item?.denda)}
                          </td>
                          <td className="align-middle text-right">
                            {priceSplit(item?.total_charge)}
                          </td>
                          <td className="align-middle text-center">
                            {
                              filterStringValue(
                                process_type,
                                item?.process_type
                              )?.label
                            }
                          </td>
                          <td
                            className={
                              "align-middle text-center " +
                              changeCol(item?.payment_status)
                            }
                          >
                            {paymentLabel(item?.payment_status)}
                          </td>
                          <td className="align-middle text-center">
                            {is_sub_menu_action(
                              "ch_checkout_billing_print"
                            ) && (
                              <>
                                {item?.payment_status === 0 ||
                                item?.payment_status === 2 ? (
                                  <button
                                    className="btn border-0 btn-sm blue fw-700 f8-vw"
                                    onClick={() => {
                                      setItem(item);
                                      setIsPrint(true);
                                    }}
                                  >
                                    Print
                                  </button>
                                ) : null}
                              </>
                            )}
                          </td>
                        </tr>
                      </Fragment>
                    ))}
                  </tbody>
                </table>
              </div>
            </InfiniteScroll>
          </div>
        </div>
      )}

      {IS_MOBILE && (
        <div>
          <BottomSheet
            open={openButtonSheet}
            onDismiss={() => setOpenButtonSheet(false)}
            snapPoints={({ maxHeight }) => maxHeight}
            header={
              <div className="d-flex justify-content-between">
                <span className="purple f16-vw fw-600 px-2">Filter</span>
                <span
                  className="px-2"
                  onClick={() => setOpenButtonSheet(false)}
                >
                  <Icon icon={iconUrl.closeBold} color={"grey"} width={20} />
                </span>
              </div>
            }
            footer={
              <div className="d-flex justify-content-between">
                <div className="col-6 pl-2">
                  <button
                    className="btn btn-block btn-outline-purple f12-vw"
                    onClick={() => {
                      resetFilter();
                      setOpenButtonSheet(false);
                      getlistPrintBilling(1, true, new Date(), new Date());
                    }}
                  >
                    Reset
                  </button>
                </div>
                <div className="col-6 pr-2">
                  <button
                    className="btn btn-block btn-gradient f12-vw text-white"
                    onClick={() => {
                      setListPrintBilling([]);
                      getlistPrintBilling(1, true, range.start, range.end);
                      setOpenButtonSheet(false);
                    }}
                  >
                    Search
                  </button>
                </div>
              </div>
            }
          >
            <div className="mt-3" style={{ height: "20rem" }}>
              <div className="px-4 mt-2 date-wrapper">
                <label htmlFor="" className="f12-vw m-0 p-0">
                  Created at
                </label>
                <DateRangeFilterMobile
                  start={range.start}
                  end={range.end}
                  handleStart={handleCreateStart}
                  handleEnd={handleCreateEnd}
                  minDate={true}
                  fontSize="f12-vw"
                />
              </div>
              <div className="px-4 mt-3">
                <label htmlFor="" className="f12-vw m-0 p-0">
                  Kode Booking
                </label>
                {mobileSearchInput("Cari Kode Booking", bookingId, (e) =>
                  setBookingId(generateBookingCode(e.target.value))
                )}
              </div>
              <div className="px-4 mt-3">
                <label htmlFor="" className="f12-vw m-0 p-0">
                  Billing ID
                </label>
                {mobileSearchInput("Cari Billing ID", billingId, (e) =>
                  setBillingId(generateBookingCode(e.target.value))
                )}
              </div>
              <div className="px-4 mt-3">
                <label htmlFor="" className="f12-vw m-0 p-0">
                  Nomor Kamar
                </label>
                {mobileSearchInput("Cari Nomor Kamar", roomNumber, (e) =>
                  setroomNumber(e.target.value)
                )}
              </div>
            </div>
          </BottomSheet>
        </div>
      )}
      <div style={{ display: "none" }}>
        {!isEmpty(item) && (
          <Print launch_print={isPrint}>
            <p className="p-print txt-print-h1">Billing</p>
            {genPaymentID(item?.payment_id)}
            {item?.process_type === 22 ? (
              <>
                <p className="p-print txt-print-h2">Change Room</p>
                {createLineDash()}
                <p className="p-print txt-print-h3">{`${getServerTime(
                  item?.ts,
                  timezone
                )} - ${getClientTimeWithoutSecond(
                  item?.ts,
                  timezone
                )} ${getTimezoneOffsetClient(timezone)}`}</p>
                <p className="p-print txt-print-h3">{`${hotel_info?.hotel_name}`}</p>
                {createLineDash()}
                <table className="table-print">
                  <tbody>
                    {genContent1("Kasir", item?.kasir)}
                    {/* {genContent1("Kode Booking", limitText(item?.booking_id))} */}
                  </tbody>
                </table>
                {genBookingId("Booking ID", item?.book_id)}
                {createLineDash()}
                <p className="p-print txt-print-section-h2">
                  Informasi Booking
                </p>
                <table className="table-print">
                  <tbody>
                    {genContent1("Tipe Kamar", item?.room_type_name)}
                    {genContent1("Nomor Kamar", item?.room_number)}
                  </tbody>
                </table>
                <p className="p-print txt-print-section-h2">New Room Info</p>
                <table className="table-print">
                  <tbody>
                    {genContent1("Tipe Kamar", item?.to_room_type)}
                    {genContent1("Nomor Kamar", item?.to_room_number)}
                  </tbody>
                </table>
                {createLineDash()}
                <table className="table-print">
                  <tbody>
                    {genContent1("Metode Pembayaran", "Transfer Bank")}
                    {genContent1(
                      "Nama Bank",
                      `${item?.to_bank_name} (${item?.bank_accno})`
                    )}
                    {genContent1(
                      "Batas Pembayaran",
                      `${getClientTimeWithoutSecond(
                        item?.due_date,
                        timezone
                      )} ${getTimezoneOffsetClient(timezone)}`
                    )}
                  </tbody>
                </table>
                {createLineDash()}
                <p className="p-print txt-print-section-h2">
                  Informasi Tagihan
                </p>
                <table className="table-print">
                  <tbody>
                    {genContent2("Total Charge", item?.total_charge)}
                  </tbody>
                </table>
              </>
            ) : item?.process_type === 21 ? (
              <>
                <p className="p-print txt-print-h2">Breakage</p>
                {createLineDash()}
                <p className="p-print txt-print-h3">{`${getServerTime(
                  item?.ts,
                  timezone
                )} - ${getClientTimeWithoutSecond(
                  item?.ts,
                  timezone
                )} ${getTimezoneOffsetClient(timezone)}`}</p>
                <p className="p-print txt-print-h3">{`${hotel_info?.hotel_name}`}</p>
                {createLineDash()}
                <table className="table-print">
                  <tbody>
                    {genContent1("Kasir", item?.kasir)}
                    {/* {genContent1("Kode Booking", limitText(item?.booking_id))} */}
                  </tbody>
                </table>
                {genBookingId("Booking ID", item?.book_id)}
                {createLineDash()}
                <p className="p-print txt-print-section-h2">
                  Informasi Booking
                </p>
                <table className="table-print">
                  <tbody>
                    {genContent1("Tipe Kamar", item?.room_type_name)}
                    {genContent1("Nomor Kamar", item?.room_number)}
                    {genContent1(
                      "Durasi",
                      getDayDuration(item?.checkin_date, item?.checkout_date) +
                        " Malam"
                    )}
                  </tbody>
                </table>
                {createLineDash()}
                <table className="table-print">
                  <tbody>
                    {genContent1("Metode Pembayaran", "Transfer Bank")}
                    {genContent1(
                      "Nama Bank",
                      `${item?.to_bank_name} (${item?.bank_accno})`
                    )}
                    {genContent1(
                      "Batas Pembayaran",
                      `${getClientTimeWithoutSecond(
                        item?.due_date,
                        timezone
                      )} ${getTimezoneOffsetClient(timezone)}`
                    )}
                  </tbody>
                </table>
                {createLineDash()}
                <p className="p-print txt-print-section-h2">
                  Informasi Tagihan
                </p>
                <table className="table-print">
                  <tbody>
                    {genContent2("Total Charge", item?.total_charge)}
                  </tbody>
                </table>
              </>
            ) : (
              <>
                <p className="p-print txt-print-h3">{`${getServerTime(
                  item?.ts,
                  timezone
                )} - ${getClientTimeWithoutSecond(
                  item?.ts,
                  timezone
                )} ${getTimezoneOffsetClient(timezone)}`}</p>
                <p className="p-print txt-print-h3">{`${hotel_info?.hotel_name}`}</p>
                {createLineDash()}
                <table className="table-print">
                  <tbody>
                    {genContent1("Kasir", item?.kasir)}
                    {/* {genContent1("Kode Booking", limitText(item?.booking_id))} */}
                  </tbody>
                </table>
                {genBookingId("Booking ID", item?.book_id)}
                {createLineDash()}
                <p className="p-print txt-print-section-h2">
                  Informasi Booking
                </p>
                <table className="table-print">
                  <tbody>
                    {genContent1("Tipe Kamar", item?.room_type_name)}
                    {genContent1(
                      "Durasi",
                      getDayDuration(item?.checkin_date, item?.checkout_date) +
                        " Malam"
                    )}
                  </tbody>
                </table>
                {createLineDash()}
                <table className="table-print">
                  <tbody>
                    {genContent1("Metode Pembayaran", "Transfer Bank")}
                    {genContent1(
                      "Nama Bank",
                      `${item?.to_bank_name} (${item?.bank_accno})`
                    )}
                    {genContent1(
                      "Batas Pembayaran",
                      `${getClientTimeWithoutSecond(
                        item?.due_date,
                        timezone
                      )} ${getTimezoneOffsetClient(timezone)}`
                    )}
                  </tbody>
                </table>
                {createLineDash()}
                <p className="p-print txt-print-section-h2">
                  Informasi Pembayaran
                </p>
                <table className="table-print">
                  <tbody>
                    {genContent2("Late Check Out", item?.late_checkout)}
                    {genContent2("Ganti Rugi", item?.ganti_rugi)}
                    {genContent2("Denda", item?.denda)}
                    {genContent2(
                      "Deposit",
                      item?.use_deposit && item?.deposit > 0
                        ? "-" + item?.deposit
                        : 0
                    )}
                  </tbody>
                </table>
                {createLineSolid()}
                <table className="table-print">
                  <tbody>
                    {genContent2("Total", item?.total_charge)}
                    {genContent2(
                      "Sisa Deposit",
                      item?.use_deposit ? 0 : item?.deposit
                    )}
                  </tbody>
                </table>
              </>
            )}
          </Print>
        )}
      </div>
    </div>
  );
};
export default RePrintCheckoutBilling;
