import { Icon } from "@iconify/react";
import React from "react";
import icon24HoursLine from "@iconify/icons-ri/24-hours-line";
import parkingIcon from "@iconify/icons-fa-solid/parking";
import airConditioner from "@iconify/icons-iconoir/air-conditioner";
import smokingIcon from "@iconify/icons-fa-solid/smoking";
import baselineAirportShuttle from "@iconify/icons-ic/baseline-airport-shuttle";
import countertopIcon from "@iconify/icons-mdi/countertop";
import laundryIcon from "@iconify/icons-map/laundry";
import groupDiscussionMeetingx3 from "@iconify/icons-healthicons/group-discussion-meetingx3";
import baselineAccessible from "@iconify/icons-ic/baseline-accessible";
import swimmingPool from "@iconify/icons-fa-solid/swimming-pool";
import roundBedroomBaby from "@iconify/icons-ic/round-bedroom-baby";
import roundRestaurant from "@iconify/icons-ic/round-restaurant";
import dumbbellIcon from "@iconify/icons-bx/dumbbell";
import spaIcon from "@iconify/icons-map/spa";
import roundWifi from "@iconify/icons-ic/round-wifi";

const setIcon24 = (
  <Icon icon={icon24HoursLine} color="#51b7ab" width={"1rem"} />
);
const setParking = <Icon icon={parkingIcon} color="#51b7ab" width={"1rem"} />;
const setAirCond = (
  <Icon icon={airConditioner} color="#51b7ab" width={"1rem"} />
);
const setWifi = <Icon icon={roundWifi} color="#51b7ab" width={"1rem"} />;
const setReceptionist = (
  <Icon icon="si-glyph:customer-support" color="#51b7ab" width={"1rem"} />
);
const setSmokingArea = (
  <Icon icon={smokingIcon} color="#51b7ab" width={"1rem"} />
);
const setSpa = <Icon icon={spaIcon} color="#51b7ab" width={"1rem"} />;
const setAirport = (
  <Icon icon={baselineAirportShuttle} color="#51b7ab" width={"1rem"} />
);
const setKitchen = (
  <Icon icon={countertopIcon} color="#51b7ab" width={"1rem"} />
);
const setLaundry = <Icon icon={laundryIcon} color="#51b7ab" width={"1rem"} />;
const setMeetingRoom = (
  <Icon icon={groupDiscussionMeetingx3} color="#51b7ab" width={"1rem"} />
);
const setWheelchair = (
  <Icon icon={baselineAccessible} color="#51b7ab" width={"1rem"} />
);
const setSwimmingpool = (
  <Icon icon={swimmingPool} color="#51b7ab" width={"1rem"} />
);
const setChild = (
  <Icon icon={roundBedroomBaby} color="#51b7ab" width={"1rem"} />
);
const setRestaurant = (
  <Icon icon={roundRestaurant} color="#51b7ab" width={"1rem"} />
);
const setGym = <Icon icon={dumbbellIcon} color="#51b7ab" width={"1rem"} />;

export const populateFacilites = (resp) => {
  let data = [
    {
      label: "a24h_checkin",
      value: resp?.a24h_checkin,
      iconName: setIcon24,
    },
    {
      label: "parking",
      value: resp?.parking,
      iconName: setParking,
    },
    {
      label: "ac",
      value: resp?.ac,
      iconName: setAirCond,
    },
    {
      label: "public wifi",
      value: resp?.public_wifi,
      iconName: setWifi,
    },
    {
      label: "airport transfer",
      value: resp?.airport_transfer,
      iconName: setAirport,
    },
    {
      label: "child_facility",
      value: resp?.child_facility,
      iconName: setChild,
    },
    {
      label: "gym",
      value: resp?.gym,
      iconName: setGym,
    },
    {
      label: "kitchen",
      value: resp?.kitchen,
      iconName: setKitchen,
    },
    {
      label: "laundry",
      value: resp?.laundry,
      iconName: setLaundry,
    },
    {
      label: "meeting_room",
      value: resp?.meeting_room,
      iconName: setMeetingRoom,
    },
    {
      label: "receptionist",
      value: resp?.receptionist,
      iconName: setReceptionist,
    },
    {
      label: "restaurant",
      value: resp?.restaurant,
      iconName: setRestaurant,
    },
    {
      label: "smoking_area",
      value: resp?.smoking_area,
      iconName: setSmokingArea,
    },
    {
      label: "spa",
      value: resp?.spa,
      iconName: setSpa,
    },
    {
      label: "swimmingpool",
      value: resp?.swimmingpool,
      iconName: setSwimmingpool,
    },
    {
      label: "wheelchair",
      value: resp?.wheelchair,
      iconName: setWheelchair,
    },
  ];
  return data;
};

export const assignFacility = (resp) => {
  return resp.filter((item) => parseInt(item.value) === 1);
};
