import React from "react";
// import PopupModal from "../../../common/PopupModal";
import {
  getClientTimeWithoutSecond,
  getServerTimeWithoutDays,
  getTimezoneOffsetClient,
  showMoreGuest,
  tableCol,
} from "../../../utils";
// import { DetailGuestCheckin } from "../../report-booking/DetailGuestCheckin";

function ReportTable({ reportData }) {
  return (
    <>
      <div className="table-list table-responsive-sm">
        <table
          className="table table-sm"
          style={{
            borderCollapse: "separate",
            borderSpacing: 0,
            position: "sticky",
          }}
        >
          <thead
            className="f8-vw"
            style={{ position: "sticky", top: 0, zIndex: 1 }}
          >
            <tr
              className="text-black text-center text-white"
              style={{
                backgroundColor: "#0071A4",
                zIndex: 1,
              }}
            >
              <th rowSpan={2} className="align-middle">
                Booking ID
              </th>
              <th rowSpan={2} className="align-middle">
                Tanggal Booking
              </th>
              <th rowSpan={2} className="align-middle">
                Tipe Kamar
              </th>
              <th rowSpan={2} className="align-middle">
                Room No
              </th>
              <th rowSpan={2} className="align-middle ">
                Tamu
              </th>
              <th colSpan={2} className="align-middle">
                Booking
              </th>
              <th colSpan={4} className="align-middle">
                Actual
              </th>
              <th rowSpan={2} className="text-wrap align-middle">
                Channel Booking
              </th>
              <th rowSpan={2} className="text-wrap align-middle">
                Status
              </th>
            </tr>
            <tr
              className="text-white text-center"
              style={{
                backgroundColor: "#0071A4",
              }}
            >
              <th className="align-middle ">Check In</th>
              <th className="align-middle ">Check Out</th>
              <th className="align-middle ">Check In</th>
              <th className="align-middle ">Check In By</th>
              <th className="align-middle ">Check Out</th>
              <th className="align-middle ">Check Out By</th>
            </tr>
          </thead>

          <tbody className="f8-vw">
            {reportData.map((item, index) => {
              const timezone = localStorage?.getItem("timezone");
              return (
                <tr
                  className={` ${tableCol(index)}`}
                  style={{
                    zIndex: 0,
                  }}
                  key={index}
                >
                  <td className="text-left align-middle">
                    <div className="font-weight-bold" style={{ width: 200 }}>
                      {item?.book_id}
                    </div>
                  </td>
                  <td className=" text-left align-middle">
                    <div className="" style={{ width: 80 }}>
                      {getServerTimeWithoutDays(item?.created_at, timezone)}
                    </div>
                  </td>
                  <td className="text-wrap text-left align-middle">
                    <div className="" style={{ width: 110 }}>
                      {item?.room_type_name}
                    </div>
                  </td>
                  <td
                    className={`text-wrap align-middle font-weight-bold ${
                      item?.room_number ? "text-left" : "text-center"
                    }`}
                  >
                    <div className="" style={{ width: 80 }}>
                      {item?.room_number ? item?.room_number : "-"}
                    </div>
                  </td>
                  <td className=" text-left align-middle px-0 text-wrap">
                    <div className="text-wrap" style={{ width: 100 }}>
                      {showMoreGuest(item?.guest_name)}
                    </div>
                  </td>
                  <td className="text-left align-middle">
                    <div className="" style={{ width: 80 }}>
                      {getServerTimeWithoutDays(item?.checkin_date, timezone)}
                    </div>
                  </td>
                  <td className="text-left align-middle">
                    <div className="" style={{ width: 80 }}>
                      {getServerTimeWithoutDays(item?.checkout_date, timezone)}
                    </div>
                  </td>
                  <td className="align-middle text-wrap text-left">
                    <div
                      className="text-center d-flex flex-column"
                      style={{ width: 100 }}
                    >
                      <span>
                        {getServerTimeWithoutDays(
                          item?.actual_checkin_date,
                          timezone
                        )}
                      </span>
                      <span className="font-weight-bold">
                        {getClientTimeWithoutSecond(
                          item?.actual_checkin_date,
                          timezone
                        ) +
                          " " +
                          getTimezoneOffsetClient(timezone)}
                      </span>
                    </div>
                  </td>
                  <td className="text-center align-middle">
                    <div className="" style={{ width: 80 }}>
                      {item?.check_in_by}
                    </div>
                  </td>
                  <td
                    className={`align-middle text-wrap  ${
                      item?.actual_checkout_date ? "text-left" : "text-center"
                    }`}
                  >
                    <div
                      className="text-center d-flex flex-column"
                      style={{ width: 100 }}
                    >
                      {item?.actual_checkout_date ? (
                        <>
                          <span>
                            {getServerTimeWithoutDays(
                              item?.actual_checkout_date,
                              timezone
                            )}
                          </span>
                          <span className="font-weight-bold">
                            {getClientTimeWithoutSecond(
                              item?.actual_checkout_date,
                              timezone
                            ) +
                              " " +
                              getTimezoneOffsetClient(timezone)}
                          </span>
                        </>
                      ) : (
                        "-"
                      )}
                    </div>
                  </td>
                  <td className="text-center align-middle">
                    <div className="" style={{ width: 80 }}>
                      {item?.check_out_by ? item?.check_out_by : "-"}
                    </div>
                  </td>
                  <td className="text-center align-middle">
                    <div className="" style={{ width: 80 }}>
                      {item?.channel_booking}
                    </div>
                  </td>
                  <td className="text-center align-middle">
                    <div className="" style={{ width: 80 }}>
                      {item?.guest_status}
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </>
  );
}

export default ReportTable;
