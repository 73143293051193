import { Icon } from "@iconify/react";
import React, {
  Fragment,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { BreadcrumbContext } from "../../../common/BreadcrumbContext";
import NotifMessage, { ButtonClose } from "../../../common/Notif";
import PopupModal from "../../../common/PopupModal";
import {
  generateBookingCode,
  priceSplit,
  showMore,
  showMoreGuest,
  tableCol,
} from "../../../utils";
import {
  addData,
  getData,
  getErr,
  safeErrCode,
} from "../../../utils/api-request";
import history from "../../../utils/history";
import { is_sub_menu_action } from "../../../utils/uac";
import { EmptyData, MobileEmptyData } from "../../Empty/EmptyData";
import { mobileSearchInput, searchInput } from "../bookingHelper";
import { PaymentPopup } from "./PaymentPopup";
import { PaymentPopupSuccess } from "./PaymentPopupSuccess";
import PrintReceipt from "../../../print/PrintReceipt";
import { grouping_print } from "../../../utils/grouping-print";
import { iconUrl } from "../../../config";
import { IS_MOBILE } from "../../../common/Responsive";
import { MobileCardList } from "../../../common/CardList";
import { BottomSheet } from "react-spring-bottom-sheet";
import { t } from "../../../common/ErrorMessage";

const PaymentReservasi = () => {
  const {
    setpathLocation,
    isOpeningShift,
    openButtonSheet,
    setOpenButtonSheet,
    setShowFilter,
  } = useContext(BreadcrumbContext);
  const [listPayment, setListPayment] = useState([]);
  const mounted = useRef(false);
  const [isOpen, setIsOpen] = useState(false);
  const [mode, setMode] = useState("");
  const [paymentMethod, setPaymentMethod] = useState([]);
  const [paymentSelected, setpaymentSelected] = useState([]);
  const [bankList, setBankList] = useState([]);
  const [bankSelected, setBankSelected] = useState([]);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [deposit, setDeposit] = useState(0);
  const [earlyCheckin, setEarlyCheckin] = useState(0);
  const [roomPrice, setRoomPrice] = useState(0);
  const [totalCharge, settotalCharge] = useState(0);
  const [payment, setPayment] = useState(0);
  const [change, setChange] = useState(0);
  const [validation, setValidation] = useState(false);
  const [bookingSelected, setBookingSelected] = useState({});
  const [detailPrint, setDetailPrint] = useState([]);
  const [bookingId, setBookingId] = useState("");
  const [imgPopup, setImgPopup] = useState("");
  const [headerPopup, setHeaderPopup] = useState("");
  const [isPrint, setIsPrint] = useState(false);
  const [paymentId, setPaymentId] = useState("");
  const [totalPaid, setTotalPaid] = useState(0);
  const [additionalCharge, setAdditionalCharge] = useState(0);
  const [search, setSearch] = useState({
    booking_id: "",
    room_number: "",
    guest_name: "",
  });
  const [dueDate, setDueDate] = useState("");
  const [realCharge, setRealCharge] = useState(0);
  const [discount, setDiscount] = useState(0);

  const openModal = () => {
    setIsOpen(!isOpen);
  };

  const getListPayment = useCallback(() => {
    setLoading(true);
    getData(
      `/api/v1/listbilling?booking_id=${search.booking_id}&room_number=${search.room_number}&guest_name=${search.guest_name}`
    )
      .then((resp) => {
        setLoading(false);
        if (resp.data) {
          if (resp.data.length > 0) {
            let d = resp.data.map((item) => {
              return {
                checked: false,
                disabled: false,
                ...item,
              };
            });
            setListPayment(d);
          } else {
            setError("Semua billing sudah di proses");
            setListPayment([]);
          }
        } else {
          resp.json().then((err) => {
            setError(t(safeErrCode(err)));
          });
        }
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
  }, [search.booking_id, search.guest_name, search.room_number]);

  const getPaymentMethod = useCallback(() => {
    getData("/api/v1/listpaymentmethod")
      .then((resp) => {
        if (mounted.current) {
          if (resp.data) {
            let d = resp.data.map((item) => {
              return {
                value: item.payment_type,
                label: item.payment_name,
              };
            });
            setPaymentMethod(d);
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const getPaymentType = useCallback(() => {
    if (paymentSelected?.value === 2) {
      getData(`/api/v1/paymentype?payment_type=${paymentSelected.value}`)
        .then((resp) => {
          if (mounted.current) {
            if (resp.data) {
              let d = resp.data.map((item) => {
                return {
                  value: item.bank_id,
                  label: `${item.bank_name} ( ${item.bank_accno} )`,
                  bank_name: item.bank_name,
                  bank_accno: item.bank_accno,
                  bank_accholder: item.bank_accholder,
                };
              });
              setBankList(d);
            } else {
              resp.json().then((err) => {
                setError(t(safeErrCode(err)));
              });
              setBankList([]);
            }
          }
        })
        .catch((error) => {
          let err = getErr(error);
          setError(t(err));
        });
    }
  }, [paymentSelected]);

  const makePayment = () => {
    const formData = new FormData();
    const payload = {
      room_number: bookingSelected?.room_number?.join(","),
      billing_ids: bookingSelected?.billing_id?.join(","),
      booking_id: bookingId,
      paymenttype: paymentSelected?.value,
      payment_receive: paymentSelected?.value === 2 ? totalPaid : payment,
      real_room_charge: realCharge,
      ...(!!bankSelected?.bank_name && { bank_name: bankSelected.bank_name }),
      ...(!!bankSelected?.bank_accno && {
        bankaccno: bankSelected.bank_accno,
      }),
      ...(!!bookingSelected?.voucher_no[0] && {
        voucher_no: bookingSelected?.voucher_no[0],
      }),
      process_type: bookingSelected?.process_type,
    };
    for (const key in payload) {
      formData.append(key, payload[key]);
    }
    addData("/api/v1/makepayment", formData)
      .then((resp) => {
        if (resp.message === "Success" && resp.payment_id) {
          setMode("payment-success");
          setPaymentId(resp?.payment_id);
          setDueDate(resp.due_date);
          setIsPrint(true);
        } else {
          resp.json().then((err) => {
            setError(t(safeErrCode(err)));
          });
          setHeaderPopup("Pembayaran Gagal");
          setMode("error");
          setImgPopup("/img/error-icon/booking-failed.svg");
        }
      })
      .catch((error) => {
        let err = getErr(error);
        setError(t(err));
        setMode("error");
        setHeaderPopup("Pembayaran Gagal");
        setImgPopup("/img/error-icon/booking-failed.svg");
      });
  };

  const checkedPayment = (index, value, booking_id) => {
    let x = [...listPayment];
    x[index].checked = value;
    setBookingId(booking_id);
    setListPayment(x);
  };

  const handleEnter = (e) => {
    if (e.key === "Enter") {
      getListPayment();
    }
  };

  const reset = () => {
    setChange(0);
    setPayment(0);
    setpaymentSelected([]);
    setBankSelected([]);
    setImgPopup("");
    setHeaderPopup("");
    setIsPrint(false);
    setTotalPaid(0);
    setDiscount(0);
    setAdditionalCharge(0);
  };
  const resetFilter = () => {
    setSearch({ ...search, booking_id: "", guest_name: "", room_number: "" });
    setListPayment([]);
  };

  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);

  useEffect(() => {
    setpathLocation("/reservasi/proses-pembayaran");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getPaymentMethod();
  }, [getPaymentMethod]);

  useEffect(() => {
    getPaymentType();
  }, [getPaymentType]);

  useEffect(() => {
    if (isOpen) {
      let d = [...listPayment];
      let x = d.filter((item) => item.checked);
      let depo = x.reduce((a, v) => (a = a + v.deposit), 0);
      let early_checkin = x.reduce((a, v) => (a = a + v.early_checkin), 0);
      let room_charge = x.reduce((a, v) => (a = a + v.room_charge), 0);
      let total_charge = x.reduce((a, v) => (a = a + v.total_charge), 0);
      let real_room_charge = x.reduce(
        (a, v) => (a = a + v.real_room_charge),
        0
      );
      let additional_charge = x.reduce(
        (a, v) => (a = a + v.additional_charge),
        0
      );
      let totalDiscount = x.reduce((a, v) => (a = a + v.diskon), 0);
      setDeposit(depo);
      setEarlyCheckin(early_checkin);
      setRoomPrice(room_charge);
      settotalCharge(total_charge);
      setRealCharge(real_room_charge);
      setDiscount(totalDiscount);
      setAdditionalCharge(additional_charge);
    }
  }, [isOpen, listPayment]);

  useEffect(() => {
    if (
      search.booking_id === "" &&
      search.guest_name === "" &&
      search.room_number === "" &&
      !isOpeningShift
    ) {
      getListPayment();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search, isOpeningShift, getListPayment]);

  useEffect(() => {
    let d = [...listPayment];
    let x = d.filter((item) => item.checked === true);
    let valid = x.length > 0 ? true : false;
    setValidation(valid);
    setDetailPrint(grouping_print(x));
  }, [listPayment]);

  useEffect(() => {
    if (listPayment.length > 0) {
      let x = [...listPayment];
      let d = [];
      if (bookingId !== "") {
        d = x.map((data) => {
          return {
            ...data,
            disabled: data.booking_id !== bookingId ? true : false,
          };
        });
      } else {
        d = x.map((data) => {
          return {
            ...data,
            disabled: false,
          };
        });
      }
      setListPayment(d);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bookingId]);

  useEffect(() => {
    const tes =
      listPayment?.length > 0 && listPayment.filter((item) => item.checked);
    if (tes.length === 0) {
      setBookingId("");
    }
  }, [listPayment]);

  useEffect(() => {
    let x = [...listPayment];
    let data_select = {};
    data_select.room_number = x
      .filter((item) => item.checked)
      .map((data) => data.room_number);
    data_select.billing_id = x
      .filter((item) => item.checked)
      .map((data) => data.billing_id);
    data_select.voucher_no = x
      .filter((item) => item.checked && !!item.voucher_no)
      .map((data) => data.voucher_no);
    data_select.voucher_type = x
      .filter((item) => item.checked)
      .map((data) => data.voucher_type);
    data_select.process_type = x.find((item) => item.checked)?.process_type;

    setBookingSelected(data_select);
  }, [listPayment]);

  useEffect(() => {
    if (isPrint) {
      setTimeout(() => {
        setIsPrint(false);
      }, 6000);
    }
  }, [isPrint]);

  useEffect(() => {
    setShowFilter(true);
    return () => {
      setShowFilter(false);
    };
  }, [setShowFilter]);

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
    return () => {
      document.body.style.overflow = "unset";
    };
  }, [isOpen]);

  return (
    <>
      {IS_MOBILE ? (
        <div className={`my-1 ${!isOpeningShift ? "" : "not-opening"}`}>
          <div className="d-flex flex-column px-3 py-1 mb-5">
            <div className="col px-1">
              {listPayment?.length > 0 ? (
                listPayment?.map((item, index) => (
                  <MobileCardList
                    title={
                      <>
                        <div
                          className="f10-vw fw-500"
                          style={{ color: "#959595" }}
                        >
                          Kode Booking
                        </div>
                        <div>{item?.book_id}</div>
                      </>
                    }
                    fontSizeHeader="f10-vw"
                    key={item?.billing_id}
                    action_button={
                      <button
                        className={`btn btn-sm btn-block text-center ${
                          item?.checked
                            ? "btn-gradient text-white"
                            : item?.disabled
                            ? "btn-default-selected"
                            : "btn-outline-purple purple"
                        }`}
                        onClick={() =>
                          checkedPayment(
                            index,
                            item?.checked ? false : true,
                            item.booking_id
                          )
                        }
                        disabled={item?.disabled}
                      >
                        <div className="">
                          <span className="mx-1 f12-vw fw-600">Pilih</span>
                          <span>
                            <Icon
                              icon={
                                item?.disabled
                                  ? iconUrl.disabledIcon
                                  : iconUrl.checkIcon
                              }
                              width={item?.disabled ? 15 : 20}
                            />
                          </span>
                        </div>
                      </button>
                    }
                  >
                    <div className="card-hms-list">
                      <div className="col-4 px-0 grey-text">Nomor Kamar</div>
                      <div className="col pr-0 f12-vw black-value">
                        {item?.room_number}
                      </div>
                    </div>
                    <div className="card-hms-list">
                      <div className="col-4 px-0 grey-text">Billing ID</div>
                      <div className="col pr-0 f12-vw black-value">
                        {item?.billing_id}
                      </div>
                    </div>
                    <div className="card-hms-list">
                      <div className="col-4 px-0 grey-text">Tamu</div>
                      <div className="col pr-0 f12-vw black-value">
                        {showMore(item?.guest_name, 25)}
                      </div>
                    </div>
                    <div className="card-hms-list">
                      <div className="col-4 px-0 purple fw-700">Tagihan</div>
                    </div>
                    <div className="card-hms-list">
                      <div className="col-4 px-0 grey-text">Room Charge</div>
                      <div className="col pr-0">
                        <div className="d-flex justify-content-between">
                          <span className="f12-vw black-value">Rp</span>
                          <span className="f12-vw black-value">
                            {priceSplit(item?.room_charge)}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="card-hms-list">
                      <div className="col-4 px-0 grey-text">Early Check In</div>
                      <div className="col pr-0">
                        <div className="d-flex justify-content-between">
                          <span className="f12-vw black-value">Rp</span>
                          <span className="f12-vw black-value">
                            {priceSplit(item?.early_checkin)}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="card-hms-list">
                      <div className="col-4 px-0 grey-text">
                        Additional Charge
                      </div>
                      <div className="col pr-0">
                        <div className="d-flex justify-content-between">
                          <span className="f12-vw black-value">Rp</span>
                          <span className="f12-vw black-value">
                            {priceSplit(item?.additional_charge)}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="card-hms-list">
                      <div className="col-4 px-0 grey-text fw-700">Total</div>
                      <div className="col pr-0">
                        <div className="d-flex justify-content-between">
                          <span className="fw-700 f12-vw black-value">Rp</span>
                          <span className="fw-700 f12-vw black-value">
                            {priceSplit(item?.total_charge)}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="card-hms-list">
                      <div className="col-4 px-0 grey-text fw-700">
                        Deposit (Cash)
                      </div>
                      <div className="col pr-0">
                        <div className="d-flex justify-content-between">
                          <span className="fw-700 f12-vw black-value">Rp</span>
                          <span className="fw-700 f12-vw black-value">
                            {priceSplit(item?.deposit)}
                          </span>
                        </div>
                      </div>
                    </div>
                  </MobileCardList>
                ))
              ) : (
                <div style={{ position: "relative" }}>
                  <MobileEmptyData
                    errMessage={error}
                    loading={loading}
                    height="40vh"
                  />
                </div>
              )}
            </div>

            {listPayment?.length > 0 && (
              <div className="col px-1 my-2">
                {is_sub_menu_action("rv_process_payment_process") && (
                  <button
                    className={`btn text-white f12-vw rounded-input btn-block font-weight-bold ${
                      validation ? "btn-gradient" : "btn-secondary"
                    }`}
                    disabled={validation ? false : true}
                    onClick={() => {
                      openModal();
                      setMode("payment-popup");
                    }}
                  >
                    Payment Process
                  </button>
                )}
              </div>
            )}
          </div>
        </div>
      ) : (
        <div
          className={`check-out-page my-1 px-3 ${
            !isOpeningShift ? "" : "not-opening"
          }`}
        >
          <div className="d-flex flex-column px-3 py-1">
            <div className="col px-0">
              <div className="d-flex flex-row justify-content-between">
                <div className="col-md-9 px-0">
                  <div className="d-flex flex-row">
                    <div className="col px-0 mr-3">
                      <label htmlFor="" className="f8-vw m-0 p-0">
                        Kode Booking
                      </label>
                      {searchInput(
                        "Cari Kode Booking",
                        search.booking_id,
                        (e) =>
                          setSearch({
                            ...search,
                            booking_id: generateBookingCode(e.target.value),
                          }),
                        handleEnter
                      )}
                    </div>
                    <div className="col px-0 mr-3">
                      <label htmlFor="" className="f8-vw m-0 p-0">
                        Nama Tamu
                      </label>
                      {searchInput(
                        "Cari Nama Tamu",
                        search.guest_name,
                        (e) =>
                          setSearch({
                            ...search,
                            guest_name: e.target.value,
                          }),
                        handleEnter
                      )}
                    </div>
                    <div className="col px-0 mr-3">
                      <label htmlFor="" className="f8-vw m-0 p-0">
                        Nomor Kamar
                      </label>
                      {searchInput(
                        "Cari Nomor Kamar",
                        search.room_number,
                        (e) =>
                          setSearch({
                            ...search,
                            room_number: e.target.value,
                          }),
                        handleEnter
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-md-2 pr-0 pl-5 mt-auto">
                  <div className="d-flex flex-column">
                    <button
                      className="btn btn-sm search-new rounded-input btn-block mt-auto f8-vw fw-500 text-white"
                      onClick={() => {
                        setListPayment([]);
                        getListPayment();
                      }}
                      style={{
                        paddingTop: 7,
                        paddingBottom: 7,
                        backgroundColor: "#742B9E",
                      }}
                    >
                      Search
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="col px-0 mt-3">
              <div
                className="table-list table-responsive-sm scroll-table table-report"
                style={{
                  height: "23rem",
                }}
              >
                <table className="table table-sm">
                  <thead
                    className="f8-vw sticky-table"
                    style={{
                      backgroundColor: "#0071A4",
                    }}
                  >
                    <tr className="text-black text-center text-white">
                      <th rowSpan={2} className="align-middle">
                        Booking ID
                      </th>
                      <th rowSpan={2} className="align-middle">
                        Billing ID
                      </th>
                      <th rowSpan={2} className="align-middle">
                        Room No
                      </th>
                      <th
                        rowSpan={2}
                        className="align-middle"
                        style={{ width: 150 }}
                      >
                        Tamu
                      </th>
                      <th rowSpan={2} className="align-middle">
                        Deposit
                      </th>
                      <th rowSpan={1} colSpan={4}>
                        Tagihan
                      </th>
                      <th rowSpan={2} className="align-middle "></th>
                    </tr>
                    <tr className="text-black text-center text-white">
                      <th style={{ width: 100 }}>Room Charge</th>
                      <th style={{ width: 100 }}>Early Check-In</th>
                      <th style={{ width: 100 }}>Additional Charge</th>
                      <th style={{ width: 100 }}>Total</th>
                    </tr>
                  </thead>
                  <tbody className="f8-vw">
                    {listPayment?.length > 0 ? (
                      <>
                        {listPayment?.map((item, index) => (
                          <Fragment key={item?.booking_id}>
                            <tr className={` ${tableCol(index)}`}>
                              <td className="align-middle text-left font-weight-bold">
                                {item?.book_id}
                              </td>
                              <td className="align-middle text-center font-weight-bold">
                                {item?.billing_id}
                              </td>
                              <td className="align-middle text-center font-weight-bold">
                                {item?.room_number}
                              </td>
                              <td className="align-middle text-left">
                                {showMoreGuest(item?.guest_name)}
                              </td>
                              <td className="align-middle text-right">
                                {priceSplit(item?.deposit)}
                              </td>
                              <td className="align-middle text-right">
                                {priceSplit(item?.room_charge)}
                              </td>
                              <td className="align-middle text-right">
                                {priceSplit(item?.early_checkin)}
                              </td>
                              <td className="align-middle text-right">
                                {priceSplit(item?.additional_charge)}
                              </td>
                              <td className="align-middle text-right">
                                {priceSplit(item?.total_charge)}
                              </td>

                              <td className="align-middle text-center">
                                <input
                                  type="checkbox"
                                  checked={item.checked}
                                  onChange={() =>
                                    checkedPayment(
                                      index,
                                      item?.checked ? false : true,
                                      item.booking_id
                                    )
                                  }
                                  disabled={item?.disabled}
                                />
                              </td>
                            </tr>
                          </Fragment>
                        ))}
                      </>
                    ) : (
                      <tr>
                        <td colSpan={10} style={{ padding: 0 }}>
                          <EmptyData
                            loading={loading}
                            errMessage={error}
                            text="Semua booking sudah dibayar"
                            sourceImg={"/img/booking/payment-process.svg"}
                            height={"18rem"}
                          />
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>

            <div className="col-2 px-0 my-2 ml-auto">
              {is_sub_menu_action("rv_process_payment_process") && (
                <button
                  className={`btn text-white f8-vw rounded-input btn-block font-weight-bold ${
                    validation ? "btn-gradient" : "btn-secondary"
                  }`}
                  disabled={validation ? false : true}
                  onClick={() => {
                    openModal();
                    setMode("payment-popup");
                  }}
                >
                  Payment Process
                </button>
              )}
            </div>
          </div>
        </div>
      )}
      {IS_MOBILE && (
        <div>
          <BottomSheet
            open={openButtonSheet}
            onDismiss={() => setOpenButtonSheet(false)}
            snapPoints={({ minHeight }) => minHeight}
            header={
              <div className="d-flex justify-content-between">
                <span className="purple f16-vw fw-600 px-2">Filter</span>
                <span
                  className="px-2"
                  onClick={() => setOpenButtonSheet(false)}
                >
                  <Icon icon={iconUrl.closeBold} color={"grey"} width={20} />
                </span>
              </div>
            }
            footer={
              <div className="d-flex justify-content-between">
                <div className="col-6 pl-2">
                  <button
                    className="btn btn-block btn-outline-purple f12-vw"
                    onClick={() => {
                      resetFilter();
                      setOpenButtonSheet(false);
                    }}
                  >
                    Reset
                  </button>
                </div>
                <div className="col-6 pr-2">
                  <button
                    className="btn btn-block btn-gradient f12-vw text-white"
                    onClick={() => {
                      setListPayment([]);
                      getListPayment();
                      setOpenButtonSheet(false);
                    }}
                  >
                    Search
                  </button>
                </div>
              </div>
            }
          >
            <div className="mt-3" style={{ height: "16rem" }}>
              <div className="px-4 mt-2">
                <label htmlFor="" className="f12-vw m-0 p-0 fw-600">
                  Kode Booking
                </label>
                {mobileSearchInput(
                  "Masukkan Kode Booking",
                  search.booking_id,
                  (e) =>
                    setSearch({
                      ...search,
                      booking_id: generateBookingCode(e.target.value),
                    })
                )}
              </div>
              <div className="px-4 mt-3">
                <label htmlFor="" className="f12-vw m-0 p-0 fw-600">
                  Nama Tamu
                </label>
                {mobileSearchInput("Cari Tamu", search.guest_name, (e) =>
                  setSearch({
                    ...search,
                    guest_name: e.target.value,
                  })
                )}
              </div>
              <div className="px-4 mt-3">
                <label htmlFor="" className="f12-vw m-0 p-0 fw-600">
                  Nomor Kamar
                </label>
                {mobileSearchInput(
                  "Cari Nomor Kamar",
                  search.room_number,
                  (e) =>
                    setSearch({
                      ...search,
                      room_number: e.target.value,
                    })
                )}
              </div>
            </div>
          </BottomSheet>
        </div>
      )}
      <div style={{ display: "none" }}>
        <PrintReceipt
          data={detailPrint[0]}
          launch_print={isPrint}
          roomCharge={roomPrice}
          earlyCheckin={earlyCheckin}
          deposit={deposit}
          totalCharge={totalCharge}
          paymentSelected={paymentSelected}
          bankSelected={bankSelected}
          payment={payment}
          charge={change}
          bookingSelected={bookingSelected}
          paymentId={paymentId}
          realCharge={realCharge}
          due_date={dueDate}
          discount={discount}
          additionalCharge={additionalCharge}
          processType={bookingSelected?.process_type}
        />
      </div>
      <PopupModal modalIsOpen={isOpen}>
        {mode === "payment-popup" ? (
          <PaymentPopup
            close={() => {
              openModal();
              reset();
            }}
            paymentMethod={paymentMethod}
            paymentSelected={paymentSelected}
            bankList={bankList}
            bankSelected={bankSelected}
            roomCharge={roomPrice}
            earlyCheckin={earlyCheckin}
            deposit={deposit}
            totalCharge={totalCharge}
            setBankSelected={setBankSelected}
            setpaymentSelected={setpaymentSelected}
            payment={payment}
            charge={change}
            onClick={() => {
              makePayment();
            }}
            setPayment={setPayment}
            setCharge={setChange}
            totalPaid={totalPaid}
            setTotalPaid={setTotalPaid}
            voucher_no={bookingSelected?.voucher_no[0]}
            voucher_type={bookingSelected?.voucher_type[0]}
            discount={discount}
            realCharge={realCharge}
            additionalCharge={additionalCharge}
            processType={bookingSelected?.process_type}
          />
        ) : mode === "payment-success" ? (
          <PaymentPopupSuccess
            totalCharge={totalPaid}
            payment={payment}
            charge={change}
            deposit={deposit}
            earlyCheckin={earlyCheckin}
            roomCharge={roomPrice}
            paymentSelected={paymentSelected}
            bankSelected={bankSelected}
            confirm={() => history.push("/reservasi/payment-confirm")}
            close={() => {
              openModal();
              getListPayment();
              reset();
            }}
            realCharge={realCharge}
            voucher_no={bookingSelected?.voucher_no[0]}
            voucher_type={bookingSelected?.voucher_type[0]}
            discount={discount}
            additionalCharge={additionalCharge}
            processType={bookingSelected?.process_type}
          />
        ) : (
          <>
            <NotifMessage
              closeModal={openModal}
              img={imgPopup}
              messageHeader={headerPopup}
              color="red"
              messageBody={error}
            />
            <ButtonClose
              close={() => {
                openModal();
              }}
              text="OK"
            />
          </>
        )}
      </PopupModal>
    </>
  );
};

export default PaymentReservasi;
