import { Icon } from "@iconify/react";
import React, { useCallback, useEffect, useState } from "react";
import { t } from "../../common/ErrorMessage";
import { IS_MOBILE } from "../../common/Responsive";
import { iconUrl } from "../../config";
import { priceSplit } from "../../utils";
import { addData, getData, getErr, safeErrCode } from "../../utils/api-request";

const FONT_SIZE = IS_MOBILE ? "f12-vw" : "f8-vw";
const FONT_SIZE_HEADER = IS_MOBILE ? "f16-vw" : "f12-vw";

export const OpenShift = ({
  error,
  shiftInfo,
  modal,
  goTo,
  handleError,
  close,
  setLastBalance,
  setRealCash,
  setNoteBalance,
}) => {
  const [loading, setLoading] = useState(false);
  const [lastShift, setLastShift] = useState(0);
  const [balance, setBalance] = useState(0);
  const [note, setNote] = useState("");
  const [errorInfo, setErrorInfo] = useState("");

  const additionalOnly = (e) => {
    setBalance(Number(e.target.value.replace(/\D/g, "")));
  };
  const handleCashLastShift = (e) => {
    setLastShift(Number(e.target.value.replace(/\D/g, "")));
  };

  const getOpenShiftInfo = useCallback(() => {
    if (modal) {
      getData("/api/v1/shift/open")
        .then((resp) => {
          if (resp.status === "OK") {
            setLastShift(resp.lastcashonhand);
            setLastBalance(resp.lastcashonhand);
            setBalance(resp.lastcashonhand);
          } else {
            resp.json().then((response) => {
              if (response.code === "E2008") {
                setLastShift(0);
                setBalance(0);
              } else {
                setErrorInfo(t(safeErrCode(response)));
              }
            });
          }
        })
        .catch((error) => {
          let errMessage = getErr(error);
          setErrorInfo(t(errMessage));
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modal]);

  const submitOpenShift = () => {
    setLoading(true);
    const payload = {
      cash_from_last_shift: parseInt(lastShift),
      cash_on_hand: parseInt(balance),
      note: note,
    };
    addData(`/api/v1/shift/open`, payload)
      .then((resp) => {
        setLoading(false);
        if (resp.status === "OK") {
          let shift_data = false;
          localStorage.setItem("shift_open", shift_data);
          goTo();
        } else {
          resp.json().then((response) => {
            console.log(response);
            handleError(t(safeErrCode(response)));
          });
        }
      })
      .catch((error) => {
        let errMessage = getErr(error);
        setLoading(false);
        handleError(t(errMessage));
      });
  };

  const inputAppend = (title, onChange, val, disabled, autoFocus) => {
    return (
      <div className="form-group my-1">
        <label htmlFor="last-shift" className={"fw-700 my-0 " + FONT_SIZE}>
          {title}
        </label>
        <div className="input-group input-group-modal">
          <div className="input-group-prepend left-radius">
            <span
              className={"input-group-text px-3 grey-text " + FONT_SIZE}
              style={{
                backgroundColor: "white",
                borderRight: 0,
                borderLeftColor: "#9E9E9E",
                borderTopColor: "#9E9E9E",
                borderBottomColor: "#9E9E9E",
                paddingTop: 6,
              }}
              id="basic-addon1"
            >
              IDR
            </span>
          </div>
          <input
            type="text"
            className={"form-control fw-700 " + FONT_SIZE}
            style={{
              borderLeft: 0,
              textAlign: "right",
              backgroundColor: "white",
              borderRightColor: "#9E9E9E",
              borderTopColor: "#9E9E9E",
              borderBottomColor: "#9E9E9E",
            }}
            aria-describedby="basic-addon1"
            onChange={onChange}
            value={priceSplit(val)}
            autoFocus={autoFocus ? autoFocus : false}
            disabled={disabled}
            inputMode="numeric"
          />
        </div>
      </div>
    );
  };

  useEffect(() => {
    if (balance > lastShift) {
      setBalance(lastShift);
    }
  }, [balance, lastShift]);

  useEffect(() => {
    setRealCash(balance);
    setNoteBalance(note);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [balance, note]);

  useEffect(() => {
    getOpenShiftInfo();
  }, [getOpenShiftInfo]);
  return (
    <div className="d-flex flex-column p-2" style={{ width: "21rem" }}>
      <div className="col px-3">
        <div className="d-flex align-items-center">
          <span className="mr-2">
            <Icon
              icon={iconUrl.timerLockOpen}
              width={"1.5rem"}
              color="orange"
            />
          </span>
          <span className={"fw-700 blue-title " + FONT_SIZE_HEADER}>
            Opening Shift
          </span>
        </div>
      </div>
      <div className="col mt-2">
        <div className="form-group mb-0">
          <div className="shift">
            <label
              htmlFor="shift"
              className={"shift-label fw-700 my-1 " + FONT_SIZE}
            >
              Nama Shift
            </label>
            <input
              type="text"
              value={
                shiftInfo?.shift_name
                  ? shiftInfo?.shift_name
                  : "Belum ada shift"
              }
              className={"form-control text-black " + FONT_SIZE}
              readOnly
              disabled
            />
          </div>
        </div>
      </div>
      <div className="col mt-2">
        {inputAppend(
          "Saldo Awal",
          (e) => handleCashLastShift(e),
          errorInfo ? errorInfo : lastShift,
          true
        )}
      </div>
      <div className="col mt-2">
        {inputAppend(
          "Real Cash From Last Shift",
          (e) => additionalOnly(e),
          balance,
          false,
          true
        )}
      </div>
      <div className="col mt-2">
        <label htmlFor="account-no" className={"fw-700 my-0 mb-1 " + FONT_SIZE}>
          Catatan
        </label>
        <textarea
          name="note"
          id="note"
          rows="5"
          className={"form-control form-control-sm " + FONT_SIZE}
          style={{ borderColor: "#9E9E9E", resize: "none" }}
          onChange={(e) => setNote(e.target.value)}
          value={note}
        />
      </div>
      <div className="col">
        <hr className="separator-blue " />
      </div>
      <small className="d-flex justify-content-center f8-vw text-center">
        Apakah anda yakin untuk melanjutkan?
      </small>
      <div className="col mb-4 mt-3 d-flex flex-row ">
        <div className="col pl-0">
          <button
            onClick={close}
            className={"btn btn-block btn-outline-purple fw-600 " + FONT_SIZE}
          >
            Back
          </button>
        </div>
        <div className="col pr-0">
          <button
            disabled={(balance < lastShift && note === "") || loading}
            onClick={() => {
              submitOpenShift();
            }}
            className={`btn text-white fw-600 ${FONT_SIZE} btn-block  ${
              (balance < lastShift && note === "") || loading
                ? "btn-secondary"
                : "btn-gradient"
            }`}
          >
            {loading ? (
              <span
                className="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              ></span>
            ) : (
              "Confirm"
            )}
          </button>
        </div>
      </div>
    </div>
  );
};
