import { Icon } from "@iconify/react";
import React, { useEffect, useState } from "react";
import { useGeolocated } from "react-geolocated";
import { iconUrl } from "../../config";
import {
  getClientTimeWithoutSecond,
  getServerTimeWithoutDays,
  getTimezoneOffsetClient,
} from "../../utils";
import { getData } from "../../utils/api-request";
import { dateCompilation } from "../../utils/mobile";
import { CustomCameraGeoTaging } from "./mobile-view/common/CustomCameraGeotaging";
import Resizer from "react-image-file-resizer";

export const ActionEndPopup = ({
  sameUser = false,
  roomActionSelected,
  reset,
  note,
  setNote,
  loadingSubmit,
  onSubmit,
  reasonNote,
  setReasonNote,
  listFile,
  validate,
  stepperForm,
  mode,
  setFileBathroom,
  setFileRoom,
  setFileRoomShow,
  setFileBathroomShow,
  setFileMakingRoom,
  setFileMakingRoomShow,
}) => {
  const [latitude, setLatitude] = useState("");
  const [longitude, setLongitude] = useState("");
  const [infoHotel, setInfoHotel] = useState("");
  const [isOnArea, setIsOnArea] = useState(false);
  const timezone =
    localStorage?.getItem("timezone") && localStorage?.getItem("timezone");
  const { coords, isGeolocationEnabled } = useGeolocated({
    positionOptions: {
      enableHighAccuracy: false,
    },
    userDecisionTimeout: 5000,
  });

  const resizeFile = (file) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        640,
        480,
        "png",
        50,
        0,
        (uri) => {
          resolve(uri);
        },
        "file"
      );
    });

  const renderImageCanvas = async (elID, file) => {
    const blob = new Blob(
      [file],
      { type: file.type } // If the type is unknown, default is empty string.
    );
    let canvas = document.getElementById(elID);
    const ctx = canvas.getContext("2d");

    const img = new Image();
    let img_file = null;
    let dateNow = new Date().getTime();
    let filenamed = "file-" + dateNow + ".png";
    img.onload = (event) => {
      canvas.width = document.getElementById("reason-note").clientWidth;
      canvas.height = window.innerHeight;
      canvas.height = 180;

      let infoHotelName =
        isOnArea && infoHotel?.length > 0 ? infoHotel : "Unknown Location";
      ctx.font = "10pt Open Sans";

      var textWidth = ctx.measureText(infoHotelName).width;

      //enable first

      ctx.drawImage(
        img,
        0,
        0,
        img.width,
        img.height, // source rectangle
        0,
        0,
        canvas.width,
        canvas.height
      ); // destination rectangle

      ctx.fillStyle = "rgba(255,255,255,0.5)";
      ctx.fillRect(0, 5, textWidth + 80, 50);
      let dateNow = new Date();
      let formatDate = dateCompilation(dateNow.toISOString().toString());
      ctx.fillStyle = "black";
      ctx.fillText(formatDate, 25, 25);
      ctx.fillText(infoHotelName, 25, 45);

      let imgSrc = canvas.toDataURL("image/png");

      canvas.toBlob((blobFile) => {
        img_file = new File([blobFile], filenamed, {
          lastModified: new Date().getTime(),
          type: blobFile.type,
        });
        let filex = {
          img_file: [img_file],
          img_src: URL.createObjectURL(blob),
        };
        switch (elID) {
          case "end_room_file_path":
            setFileRoom(filex?.img_file);
            setFileRoomShow(imgSrc);
            break;
          case "end_bathroom_file_path":
            setFileBathroom(filex?.img_file);
            setFileBathroomShow(imgSrc);
            break;
          case "hasil_making_bed":
            setFileMakingRoom(filex?.img_file);
            setFileMakingRoomShow(imgSrc);
            break;
          default:
            break;
        }
      });
      URL.revokeObjectURL(event.target.src); // 👈 This is important. If you are not using the blob, you should release it if you don't want to reuse it. It's good for memory.
    };
    img.src = URL.createObjectURL(blob);
  };

  const handleFileCustom = async (val, label) => {
    let file = val.target.files;
    let file_resize = await resizeFile(file[0]);
    renderImageCanvas(label, file_resize);
  };

  const listUploadFile = (title, show, label, i) => {
    return (
      <div className={`mt-2`} key={i}>
        <label htmlFor="" className="f12-vw fw-700 mb-0">
          {title}
        </label>
        <div className="mt-2">
          <CustomCameraGeoTaging
            fileUpload={show}
            onChange={(e) => handleFileCustom(e, label)}
            placeholder={"Take Photo"}
            iconUpload={true}
            canvas_component={
              <>
                <canvas
                  id={label}
                  style={{
                    borderRadius: 3,
                    display: "none",
                  }}
                  height={"5px"}
                />
                <img
                  src={show}
                  alt=""
                  style={{
                    objectFit: "contain",
                    width: "100%",
                    height: "12rem",
                    display: show ? "block" : "none",
                  }}
                  loading="lazy"
                />
              </>
            }
            height={"12rem"}
          />
        </div>
      </div>
    );
  };
  const listShowFile = (title, label, i) => {
    return (
      <div className={`mt-2`} key={i}>
        <label htmlFor="" className="f12-vw fw-700 mb-0">
          {title}
        </label>
        <div className="" style={{ height: "12rem" }}>
          <img
            src={label}
            alt=""
            style={{ objectFit: "contain", width: "100%", height: "12rem" }}
            loading="lazy"
          />
        </div>
      </div>
    );
  };

  useEffect(() => {
    if (isGeolocationEnabled) {
      setLatitude(coords?.latitude);
      setLongitude(coords?.longitude);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [coords, isGeolocationEnabled]);

  useEffect(() => {
    let info_hotel = localStorage.getItem("info_hotel");
    if (info_hotel?.length > 0) {
      let infoHotelData = JSON.parse(info_hotel);
      setInfoHotel(infoHotelData?.hotel_name);
    }
  }, []);

  useEffect(() => {
    // getArea(latitude, longitude);
    if (isGeolocationEnabled) {
      let url = "/api/v1/is_inside_area?lat=" + latitude + "&lng=" + longitude;
      getData(url)
        .then((resp) => {
          if (resp?.on_area) {
            setIsOnArea(true);
          }
        })
        .catch((errX) => console.log(errX));
    }
  }, [latitude, longitude, isGeolocationEnabled]);

  return (
    <div className="d-flex flex-column py-3 px-2" style={{ width: "21rem" }}>
      <div className="col">
        <div className="d-flex align-items-center">
          <span className="mr-1">
            <Icon
              icon={
                mode === "end-confirm"
                  ? iconUrl.questionMarkCircle20Solid
                  : iconUrl.informationIcon
              }
              color="orange"
              width={"1.7rem"}
            />
          </span>
          <span className="dark-blue f14-vw mr-1">
            {mode === "end-confirm" ? "Yakin selesai bersihkan" : "End Clean"}
          </span>
          <span className="dark-blue f14-vw font-weight-bold">
            {` Kamar ${roomActionSelected?.room_number}`}
          </span>
        </div>
        <hr className="my-2 separator-blue" />
      </div>
      <div className="col">
        <div
          className="d-flex flex-column scroll-popup hide-scrollbar"
          style={{ maxHeight: "25rem" }}
        >
          <div className="col px-0">
            <label htmlFor="" className="f12-vw fw-700">
              Room Action Detail
            </label>
            <div className="f12-vw">
              <div className="d-flex flex-row">
                <div className="col-4 px-0" style={{ color: "#5e5e5e" }}>
                  Action Start
                </div>
                <div className="">
                  :{" "}
                  {getServerTimeWithoutDays(
                    roomActionSelected?.start_cleaning,
                    timezone
                  )}
                  <span className="font-weight-bold">{`, ${getClientTimeWithoutSecond(
                    roomActionSelected?.start_cleaning,
                    timezone
                  )} ${getTimezoneOffsetClient(timezone)}`}</span>
                </div>
              </div>
            </div>
            <div className="f12-vw">
              <div className="d-flex flex-row">
                <div className="col-4 px-0 " style={{ color: "#5e5e5e" }}>
                  Action End
                </div>
                <div className="">
                  : {getServerTimeWithoutDays(new Date(), timezone)}
                  <span className="font-weight-bold">{`, ${getClientTimeWithoutSecond(
                    new Date(),
                    timezone
                  )} ${getTimezoneOffsetClient(timezone)}`}</span>
                </div>
              </div>
            </div>
          </div>
          <div className="col px-0 mt-2">
            <label htmlFor="" className="f12-vw fw-700">
              Catatan
            </label>
            {mode === "end-confirm" ? (
              <div className="f12-vw">{note ? note : "-"}</div>
            ) : (
              <textarea
                name="note"
                id="note"
                rows="5"
                className="form-control f12-vw form-control-sm rounded-input"
                style={{
                  borderColor: "lightgray",
                  resize: "none",
                  backgroundColor: stepperForm > 1 ? "#ededed" : "white",
                }}
                onChange={(e) => setNote(e.target.value)}
                value={note}
                placeholder="Tulis catatan..."
              />
            )}
          </div>
          <div className="col px-0 mt-2">
            <label htmlFor="" className="f12-vw fw-700">
              Alasan Housekeeper berbeda
            </label>
            {mode === "end-confirm" ? (
              <div className="f12-vw">{reasonNote ? reasonNote : "-"}</div>
            ) : (
              <div className="">
                <textarea
                  name="note"
                  id="reason-note"
                  rows="5"
                  className="form-control f12-vw form-control-sm rounded-input"
                  style={{
                    borderColor: "lightgray",
                    resize: "none",
                    backgroundColor: sameUser ? "lightgray" : "white",
                    cursor: sameUser ? "not-allowed" : "text",
                  }}
                  onChange={(e) => setReasonNote(e.target.value)}
                  value={reasonNote}
                  placeholder="Alasan housekeeper berbeda..."
                  disabled={!sameUser ? false : true}
                />
              </div>
            )}
          </div>
          <div className="col px-0 mt-2">
            {mode === "end-confirm" ? (
              <div className="d-flex flex-column">
                {listFile?.length > 0 &&
                  listFile?.map((item, i) => {
                    return (
                      <div className="col px-0" key={item?.id}>
                        {listShowFile(item?.title, item.show, item.id)}
                      </div>
                    );
                  })}
              </div>
            ) : (
              <div className="d-flex flex-column">
                {listFile?.length > 0 &&
                  listFile?.map((item, i) => {
                    return (
                      <div className="col px-0" key={item?.id}>
                        {listUploadFile(
                          item.title,
                          item.show,
                          item.label,
                          item.id
                        )}
                      </div>
                    );
                  })}
              </div>
            )}
          </div>
        </div>
      </div>

      <div className="col">
        <hr className="separator-blue my-2" />
        <div className="text-center">
          <small className="f10-vw">Apakah anda yakin untuk melanjutkan?</small>
        </div>
      </div>
      <div className="col">
        <div className={`d-flex mt-2 justify-content-between`}>
          <div className="col-6 pl-0 pr-4">
            <button
              className="btn btn-sm btn-block btn-outline-purple"
              onClick={() => reset()}
            >
              Back
            </button>
          </div>

          <div className="col-6 pr-0 pl-4">
            <button
              className={`btn btn-sm btn-block text-white ${
                validate() ? "btn-gradient" : "btn-secondary"
              }`}
              onClick={() =>
                onSubmit(`endcleanextra`, roomActionSelected?.id, stepperForm)
              }
              disabled={validate() ? false : true}
            >
              {loadingSubmit && (
                <span className="spinner-border spinner-border-sm mr-1"></span>
              )}
              Next
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
