import React from "react";
import { HeaderName } from "../../common/HeaderName";
import { IS_MOBILE } from "../../common/Responsive";
import { dateFormat, getTimezoneOffsetClient, priceSplit } from "../../utils";
import history from "../../utils/history";

const FONT_SIZE = IS_MOBILE ? "f12-vw" : "f8-vw";

export const ClosingConfirm = ({
  userProfile,
  closingShift,
  closeModal,
  change,
}) => {
  const shiftInfo =
    localStorage.getItem("shift_info") &&
    JSON.parse(localStorage.getItem("shift_info"));
  const detailShift = (title, detailValue) => {
    return (
      <div className={"d-flex flex-row " + FONT_SIZE}>
        <div className="col-4 px-0 grey">{title}</div>
        <div className="col">: {detailValue}</div>
      </div>
    );
  };
  const showDetailCash = (title, value, bold) => {
    return (
      <div className={`d-flex mt-1 ${FONT_SIZE} ${bold ? "fw-700" : ""}`}>
        <div className="col px-0 grey">{title}</div>
        <div className="col px-0">
          <div className={`d-flex justify-content-between `}>
            <span>: Rp</span>
            <span>{priceSplit(value ? value : 0)}</span>
          </div>
        </div>
      </div>
    );
  };

  const timezone =
    localStorage.getItem("timezone") && localStorage.getItem("timezone");
  return (
    <div
      className="d-flex flex-column py-2"
      style={{ width: IS_MOBILE ? "21rem" : "23rem" }}
    >
      <div className="col text-center">
        <img
          src="/img/icon-shift/close-success.svg"
          alt=""
          width={80}
          loading="lazy"
        />
      </div>
      <div className="fw-700 text-center">
        <HeaderName
          text="Closing Shift Berhasil"
          color1="black"
          color2="black"
          fontSize={IS_MOBILE ? "f16-vw" : "f12-vw"}
        />
      </div>
      <div className={`text-center ${IS_MOBILE ? "f14-vw" : "f9-vw"}`}>
        Data{" "}
        <span className="fw-700">{`Closing ${shiftInfo?.shift_name}`}</span>{" "}
        berhasil disimpan
      </div>
      <div className="col">
        <hr className="separator-blue p-0 my-2 mx-3" />
      </div>
      <div
        className={
          IS_MOBILE
            ? "px-3 py-2 scroll-popup hide-scrollbar"
            : "px-3 scroll-popup"
        }
        style={{ maxHeight: IS_MOBILE ? "23rem" : "25rem" }}
      >
        <div className="form-group mb-0 d-flex flex-column mx-3">
          <div className="shift ">
            <div className="fw-700">
              <HeaderName
                text="Shift Detail"
                fontSize={IS_MOBILE ? "f14-vw" : "f10-vw"}
                color1="purple"
                color2="purple"
              />
            </div>
            <div className="">
              {detailShift("Nama", userProfile?.nama)}
              {detailShift("Shift", shiftInfo?.shift_name)}
              {detailShift(
                "Start",
                <>
                  <span>{dateFormat(new Date())}, </span>
                  <span className="fw-700">
                    {`${shiftInfo?.start_hour} ${getTimezoneOffsetClient(
                      timezone
                    )}`}
                  </span>
                </>
              )}
              {detailShift(
                "End",
                <>
                  <span>{dateFormat(new Date())}, </span>
                  <span className="fw-700">
                    {`${shiftInfo?.end_hour} ${getTimezoneOffsetClient(
                      timezone
                    )}`}
                  </span>
                </>
              )}
            </div>
          </div>
          <div className="my-1">
            <div
              className={`fw-700 my-0 purple ${
                IS_MOBILE ? "f14-vw" : "f10-vw"
              }`}
            >
              Closing Summary
            </div>
            <div className={FONT_SIZE}>
              {showDetailCash("Total Cash", closingShift?.cash)}
              {showDetailCash("Total Transfer", closingShift?.transfer)}
              <hr className="separator-blue my-2" />
              {showDetailCash("Total", closingShift?.total, true)}
              {showDetailCash("Deposit", closingShift?.nextCash, true)}
              <hr className="separator-blue my-2" />
            </div>

            <div className={FONT_SIZE}>
              {showDetailCash(
                "Real Cash On Hand",
                closingShift?.realCash,
                false
              )}
              {showDetailCash("Pengembalian", change, false, true)}
              {showDetailCash(
                "Jumlah yang diserahkan ke shift selanjutnya",
                closingShift?.nextCash,
                false
              )}

              <div className="mt-2">
                <hr className="separator-blue my-2" />
                <div className={"grey " + FONT_SIZE}>Catatan</div>
                <div className={FONT_SIZE}>
                  {closingShift?.note ? closingShift?.note : "-"}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col">
        <hr className="separator-blue my-2 mx-3" />
      </div>
      <div className="d-flex justify-content-center">
        <div className="col-5">
          <button
            className="btn btn-sm btn-block bg-green text-white"
            onClick={() => {
              closeModal();
              localStorage.setItem("shift_open", true);
              history.go("/reservasi/room-stock");
            }}
          >
            OK
          </button>
        </div>
      </div>
    </div>
  );
};
