import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { BreadcrumbContext } from "../../../common/BreadcrumbContext";
import NotifMessage, {
  ButtonClose,
  ButtonConfirm,
} from "../../../common/Notif";
import PopupModal from "../../../common/PopupModal";
import { PopupRoomNumber } from "../../../components/Popup/PopupRoomNumber";
import {
  addDays,
  bucketRnaWeb,
  dateFormat,
  filterGuestType,
  filterPurpose,
  filterStringLabel,
  filterStringValue,
  formatDate,
  generateBookingCode,
  getClientTimeWithoutSecond,
  getDayDuration,
  getServerTime,
  getServerTimeWithoutDays,
  getTimezoneOffsetClient,
  isEmpty,
  oss,
  parsePhone,
  priceSplit,
  showMore,
  showMoreGuest,
} from "../../../utils";
import {
  addDataPict,
  getData,
  getErr,
  safeErrCode,
  updateData,
  updateDataPict,
} from "../../../utils/api-request";
import history from "../../../utils/history";
import { CancelPopup } from "./CancelPopup";
import { CheckinDetail } from "./CheckinDetail";
import { CheckinList } from "./CheckinList";
import { ConfirmEditBooking } from "./ConfirmEditBooking";
import {
  DetailCheckinPopup,
  MobileDetailCheckinPopup,
} from "./DetailCheckinPopup";
import { PopupEditBooking } from "./EditBooking";
import { HeaderName } from "../../../common/HeaderName";
import { IS_MOBILE } from "../../../common/Responsive";
import { BottomSheet } from "react-spring-bottom-sheet";
import "react-spring-bottom-sheet/dist/style.css";
import SelectOpt from "../../../common/SelectOpt";
import { mobileSearchInput } from "../bookingHelper";
import { Icon } from "@iconify/react";
import { iconUrl } from "../../../config";
import { t } from "../../../common/ErrorMessage";
import { Reschedule } from "./Reschedule";
import { ConfirmReschedule } from "./ConfirmReschedule";
import { ReschedulePayment } from "./ReschedulePayment";
import { Print } from "../../../print/Print";
import {
  createLineDash,
  createLineSolid,
  genBookingId,
  genContent1,
  genContent2,
  genPaymentID,
} from "../../../print/common/printer-helper";
import { filterRoom } from "../../loss-found/common/utils";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { WarningModal } from "../../../common/WarningModal";

const Checkin = () => {
  const timezone = localStorage?.getItem("timezone");
  const [search, setSearch] = useState({ booking_id: "", guestname: "" });
  const {
    setpathLocation,
    isOpeningShift,
    roomType,
    openButtonSheet,
    setOpenButtonSheet,
    setShowFilter,
    userProfile,
  } = useContext(BreadcrumbContext);
  const mounted = useRef(false);
  const [currentPage, setcurrentPage] = useState("list-checkin");
  const [loadingList, setLoadingList] = useState(false);
  const [error, setError] = useState("");
  const [errorEarlyCheckin, setErrorEarlyCheckin] = useState("");
  const [errorCharge, setErrorCharge] = useState("");
  const [errorList, setErrorList] = useState("");
  const [earlyCheckin, setEarlyCheckin] = useState(0);
  const [detailCheckin, setDetailCheckin] = useState({});
  const [checkinListTable, setCheckinListTable] = useState([]);
  const [roomList, setRoomList] = useState([]);
  const [roomNumber, setRoomNumber] = useState([]);
  const [guestName, setGuestName] = useState([]);
  const [fileUpload, setFileUpload] = useState([]);
  const [deposit, setDeposit] = useState(0);
  const [totalCharge, setTotalCharge] = useState(0);
  const [roomPrice, setRoomPrice] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const [mode, setMode] = useState("");
  const [imgPopup, setImgPopup] = useState("");
  const [headerPopup, setHeaderPopup] = useState("");
  const [otaList, setOtaList] = useState([]);
  const [otaSelected, setOtaSelected] = useState([]);
  const [showImg, setShowImg] = useState(null);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [formError, setFormError] = useState("");
  const [roomTypeSelected, setRoomTypeSelected] = useState([]);
  const [otherSelected, setOtherSelected] = useState([]);
  const guesttype = [
    { value: 1, label: "Personal" },
    { value: 2, label: "Group" },
  ];
  const purpose = [
    { value: 1, label: "Business Trip" },
    { value: 2, label: "Leisure/Vacation" },
    { value: 3, label: "Family Trip" },
  ];
  const [fileUploadEdit, setFileUploadEdit] = useState([]);
  const [purposeSelected, setPurposeSelected] = useState([]);
  const [guestTypeSelected, setGuestTypeSelected] = useState([]);
  const [formEdit, setFormEdit] = useState({
    bookingCode: "",
    orderName: "",
    phoneNumber: "",
    guest: 2,
    room: 1,
    guestName: [],
    email: "",
    roomType: "",
    checkin: new Date(),
    checkout: addDays(new Date(), 1),
    deposit: 0,
    roomNumber: [],
    totalcharge: 0,
    payment: 0,
    booking_date: new Date(),
  });
  const [otherPlatform, setOtherPlatform] = useState([]);
  const [showImgEdit, setShowImgEdit] = useState("");
  const [cancelNote, setCancelNote] = useState("");
  const [cancelFile, setCancelFile] = useState([]);
  const [showImgCancel, setShowImgCancel] = useState(null);
  const [charge, setCharge] = useState({});
  const [loadingCharge, setLoadingCharge] = useState(false);
  const [payment, setPayment] = useState(0);
  const [change, setChange] = useState(0);
  const [paymentSelected, setPaymentSelected] = useState([]);
  const [bankSelected, setBankSelected] = useState([]);
  const [paymentId, setPaymentId] = useState("");
  const [isPrint, setIsPrint] = useState(false);
  const [roomingList, setRoomingList] = useState([]);
  const [hasMore, sethasMore] = useState(false);
  const [page, setPage] = useState(1);
  const [errorRoomingList, setErrorRoomingList] = useState("");
  const [loadingRoomingList, setLoadingRoomingList] = useState(false);

  const date_now = new Date();
  const hotel_info = JSON.parse(localStorage.getItem("info_hotel"));

  const getOther = () => {
    getData("/api/v1/listotherotaname")
      .then((resp) => {
        if (resp.data) {
          let d = resp.data.map((item) => {
            return {
              value: item.ota_id,
              label: item.ota_name,
            };
          });
          const data = [...d, { value: "RNA", label: "RNA" }];
          // const data = ;
          setOtherPlatform(data);
        } else {
          resp.json().then((err) => {
            console.error(t(safeErrCode(err)));
          });
        }
      })
      .catch((error) => {
        let err = getErr(error);
        console.error(t(err));
      });
  };

  const getEarlyCheckin = useCallback(() => {
    if (currentPage === "detail-checkin") {
      getData(
        `/api/v1/getearlycheckin?room_type_id=${detailCheckin?.room_type_id}&room_qty=1&booking_id=${detailCheckin?.booking_id}`
      )
        .then((resp) => {
          if (resp.charge !== undefined && resp.charge !== null) {
            setEarlyCheckin(resp?.charge);
            setErrorEarlyCheckin("");
          } else {
            resp.json().then((err) => {
              toast.error(t(safeErrCode(err)));
              setEarlyCheckin(0);
              setErrorEarlyCheckin(t(safeErrCode(err)));
            });
          }
        })
        .catch((error) => {
          let err = getErr(error);
          setEarlyCheckin(0);
          toast.error(t(err));
          setErrorEarlyCheckin(t(err));
        });
    }
  }, [detailCheckin, currentPage]);

  const getPriceHMS = useCallback(() => {
    if (!!detailCheckin && detailCheckin?.ota_name === "HMS") {
      getData(
        `/api/v1/getpriceplatform?checkin_date=${formatDate(
          new Date(detailCheckin?.checkin_date)
        )}&checkout_date=${formatDate(
          new Date(detailCheckin?.checkout_date)
        )}&room_type_id=${detailCheckin?.room_type_id}&room_qty=1`
      )
        .then((resp) => {
          if (resp?.charge) {
            setRoomPrice(resp.charge);
          } else {
            resp.json().then((err) => {
              setError(t(safeErrCode(err)));
            });
          }
        })
        .catch((error) => {
          let err = getErr(error);
          setError(t(err));
        });
    }
  }, [detailCheckin]);

  const getCheckinList = useCallback(() => {
    if (currentPage === "list-checkin") {
      setLoadingList(true);
      getData(
        `/api/v1/order/listbooking?date_start=${formatDate(
          new Date()
        )}&date_end=${formatDate(addDays(new Date(), 1))}&ota_name=${
          otaSelected?.value ? otaSelected?.value : ""
        }&guestname=${search.guestname}&booking_id=${search.booking_id}`
      )
        .then((resp) => {
          setLoadingList(false);
          if (resp.data) {
            setCheckinListTable(resp.data);
          } else {
            resp.json().then((err) => {
              setErrorList(t(safeErrCode(err)));
            });
          }
        })
        .catch((error) => {
          let err = getErr(error);
          setLoadingList(false);
          setErrorList(t(err));
        });
    }
  }, [currentPage, search, otaSelected]);

  const getListOtaName = () => {
    getData("/api/v1/listotanameonbooking")
      .then((resp) => {
        if (mounted.current) {
          if (resp.data) {
            let d = resp.data.map((item) => {
              return {
                value: item.ota_id,
                label: item.ota_name,
              };
            });
            setOtaList(d);
          } else {
            resp.json().then((err) => {
              console.error(t(safeErrCode(err)));
            });
          }
        }
      })
      .catch((error) => {
        let err = getErr(error);
        console.log(t(err));
      });
  };

  const getRoomNumber = useCallback(() => {
    if (
      detailCheckin !== undefined &&
      detailCheckin !== null &&
      detailCheckin !== "" &&
      currentPage === "detail-checkin"
    ) {
      getData(`/api/v1/detailroom?room_type=${detailCheckin?.room_type_id}`)
        .then((resp) => {
          if (resp.data) {
            if (resp.data.length > 0) {
              let d = resp.data.map((item) => {
                return {
                  room_number: item.room_number,
                  floor: item.floor,
                  bed_type_name: item.bed_type_name,
                  room_type: item.room_type,
                  bed_size: item.bed_size,
                  status: item.status,
                  checked: false,
                };
              });
              setRoomList(d);
            } else {
              setHeaderPopup("");
              setRoomList([]);
              setError(
                "Nomor Kamar tidak tersedia, silahkan pilih tipe kamar lain"
              );
              setImgPopup("/img/error-icon/empty-room-number.svg");
            }
          } else {
            resp.json().then((err) => {
              setError(t(safeErrCode(err)));
            });
            setImgPopup("/img/error-icon/empty-room-number.svg");
          }
        })
        .catch((error) => {
          let err = getErr(error);
          setError(t(err));
          setImgPopup("/img/error-icon/empty-room-number.svg");
        });
    }
  }, [detailCheckin, currentPage]);

  const getCharge = useCallback(() => {
    if (!!detailCheckin && mode === "confirm-reschedule") {
      setLoadingCharge(true);
      getData(
        `/api/v1/order/reschedule?booking_id_uuid=${
          detailCheckin?.booking_id
        }&checkin_date=${formatDate(
          formEdit?.checkin
        )}&checkout_date=${formatDate(formEdit?.checkout)}`
      )
        .then((resp) => {
          if (mounted.current) {
            setLoadingCharge(false);
            if (resp.message === "OK") {
              let data = {
                reschedule_fee: resp?.reschedule_fee,
                room_charge: resp?.room_charge,
                total_charge: resp?.total_charge,
                ...(resp?.rate_key && { rate_key: resp?.rate_key }),
              };
              setCharge(data);
              setErrorCharge("");
            } else {
              resp.json().then((err) => {
                setErrorCharge(t(safeErrCode(err)));
              });
            }
          }
        })
        .catch((error) => {
          let err = getErr(error);
          setErrorCharge(t(err));
        });
    }
  }, [detailCheckin, mode, formEdit]);

  const confirmCheckin = () => {
    const payload = {
      room_number: roomNumber,
      guest: guestName?.join(","),
      earlycheckin: earlyCheckin,
      booking_id: detailCheckin?.booking_id,
      deposit: deposit,
      room_type: detailCheckin?.room_type_id,
      room_charge: roomPrice,
    };
    const formData = new FormData();
    for (const key in payload) {
      formData.append(key, payload[key]);
    }
    if (fileUpload.length > 0) {
      for (const key of Object.keys(fileUpload)) {
        if (fileUpload[key]) {
          formData.append("file_ktp", fileUpload[key]);
        }
      }
    }
    setLoadingSubmit(true);
    addDataPict("/api/v1/order/postcheckin", formData)
      .then((resp) => {
        setLoadingSubmit(false);
        if (resp.message) {
          setMode("confirm-success");
        } else {
          resp.json().then((err) => {
            setError(t(safeErrCode(err)));
            setMode("error");
            setHeaderPopup("Checkin Gagal");
            setImgPopup("/img/error-icon/booking-failed.svg");
          });
        }
      })
      .catch((error) => {
        let err = getErr(error);
        setLoadingSubmit(false);
        setError(t(err));
        console.log(error);
        setMode("error");
        setHeaderPopup("Checkin Gagal");
        setImgPopup("/img/error-icon/booking-failed.svg");
      });
  };

  const cancelBooking = (id) => {
    const payload = {
      booking_id: id,
      reason: cancelNote,
    };
    const formData = new FormData();
    for (const key in payload) {
      formData.append(key, payload[key]);
    }
    if (cancelFile.length > 0) {
      for (const key of Object.keys(cancelFile)) {
        if (cancelFile[key]) {
          formData.append("file_bukti", cancelFile[key]);
        }
      }
    }
    setLoadingSubmit(true);
    addDataPict("/api/v1/order/cancelbooking", formData)
      .then((resp) => {
        setLoadingSubmit(false);
        if (resp.message) {
          setMode("cancel-success");
        } else {
          resp.json().then((err) => {
            setError(t(safeErrCode(err)));
            setMode("error");
            setHeaderPopup("Cancel Gagal");
            setImgPopup("/img/error-icon/booking-failed.svg");
          });
        }
      })
      .catch((error) => {
        setLoadingSubmit(false);
        let err = getErr(error);
        setError(t(err));
        console.log(error);
        setMode("error");
        setHeaderPopup("Cancel Gagal");
        setImgPopup("/img/error-icon/booking-failed.svg");
      });
  };

  const editBooking = () => {
    const payload = {
      booking_id: detailCheckin.booking_id,
      room_type: roomTypeSelected?.value,
      booked_by: formEdit.guestName,
      phone: formEdit.phoneNumber,
      email: formEdit.email,
      guest_qty: formEdit.guest,
      checkin_date: formatDate(formEdit.checkin),
      checkout_date: formatDate(formEdit.checkout),
      room_booked: formEdit?.room,
      purpose_id: purposeSelected.value,
      guest_type_id: guestTypeSelected.value,
      ota_booking_id: formEdit.bookingCode,
      ota_name: otherSelected?.label,
      room_charge_ota: formEdit.totalcharge,
      booking_date: formatDate(formEdit.booking_date),
    };
    const formData = new FormData();
    for (const key in payload) {
      formData.append(key, payload[key]);
    }
    if (fileUploadEdit.length > 0) {
      for (const key of Object.keys(fileUploadEdit)) {
        if (fileUploadEdit[key]) {
          formData.append("ota_booking_evidence", fileUploadEdit[key]);
        }
      }
    }
    // for (const pair of formData.entries()) {
    //   console.log(pair[0] + ", " + pair[1]);
    // }
    const url = "/api/v1/order/edit_otacheckin";
    setLoadingSubmit(true);
    updateDataPict(url, formData)
      .then((resp) => {
        setLoadingSubmit(false);
        if (resp.message === "Ok") {
          setMode("edit-success");
        } else {
          resp.json().then((err) => {
            setError(t(safeErrCode(err)));
            setMode("error-edit");
            setHeaderPopup("Edit Booking Gagal");
            setImgPopup("/img/error-icon/booking-failed.svg");
          });
        }
      })
      .catch((error) => {
        let err = getErr(error);
        setLoadingSubmit(false);
        setError(t(err));
        console.error(error);
        setMode("error-edit");
        setHeaderPopup("Edit Booking Gagal");
        setImgPopup("/img/error-icon/booking-failed.svg");
      });
  };

  const rescheduleBooking = () => {
    setLoadingSubmit(true);
    const url = "/api/v1/order/reschedule ";
    const payload = {
      booking_id_uuid: detailCheckin?.booking_id,
      checkin_date: formatDate(formEdit?.checkin),
      checkout_date: formatDate(formEdit?.checkout),
      ...(charge?.rate_key && { rate_key: charge?.rate_key }),
      paymenttype: paymentSelected?.value,
      ...(paymentSelected?.value === 3 && { payment_receive: payment }),
      ...(paymentSelected?.value === 2 &&
        !!bankSelected && {
          bank_name: bankSelected?.bank_name,
        }),
      ...(paymentSelected?.value === 2 &&
        !!bankSelected && {
          bankaccno: bankSelected?.bank_accno,
        }),
    };
    const formData = new FormData();
    for (const key in payload) {
      formData.append(key, payload[key]);
    }
    updateData(url, formData)
      .then((resp) => {
        setLoadingSubmit(false);
        if (resp.message === "Ok") {
          setPaymentId(resp?.payment_id);
          setMode("reschedule-success");
          setIsPrint(true);
        } else {
          resp.json().then((err) => {
            setError(t(safeErrCode(err)));
            setMode("error-edit");
            setHeaderPopup("Reschedule Booking Gagal");
            setImgPopup("/img/error-icon/booking-failed.svg");
          });
        }
      })
      .catch((error) => {
        let err = getErr(error);
        setLoadingSubmit(false);
        setError(t(err));
        console.error(error);
        setMode("error-edit");
        setHeaderPopup("Reschedule Booking Gagal");
        setImgPopup("/img/error-icon/booking-failed.svg");
      });
  };

  const checkNextPage = () => {
    if (hasMore) {
      let pagi = page + 1;
      setPage(pagi);
      getRoomingList(pagi, false);
    }
  };

  const getRoomingList = useCallback(
    (page, isInit) => {
      setLoadingRoomingList(true);
      const url = `/api/v1/get_rooming_forcheckin?booking_id_uuid=${detailCheckin.booking_id}&page=${page}`;
      let datas = [];
      if (!!detailCheckin && currentPage === "detail-checkin") {
        getData(url)
          .then((resp) => {
            if (resp.data) {
              setLoadingRoomingList(false);
              setErrorRoomingList("");
              if (isInit) {
                datas = resp.data.data;
              } else {
                let x = [];
                x = roomingList;
                datas = [...x, ...resp.data.data];
              }
              sethasMore(resp.data.next_page);
              setRoomingList(datas);
            } else {
              resp.json().then((err) => {
                setErrorRoomingList(t(safeErrCode(err)));
              });
            }
          })
          .catch((error) => {
            console.log(error);
            setLoadingRoomingList(false);
            let err = getErr(error);
            setErrorRoomingList(t(err));
          });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [detailCheckin, currentPage]
  );

  const handleSelect = (item) => {
    // checked room number list
    const list = [...roomList];
    for (let i = 0; i < list.length; i++) {
      if (list[i].room_number === item.room_number) {
        list[i].checked = true;
      }
    }

    // show room number on detail
    let arr = [];
    const roomSelected = filterRoom(roomList, item?.room_number)?.room_number;
    if (!!roomSelected) {
      arr.push(roomSelected);
    }

    let guest_name = [];
    guest_name.push(item?.guest_name);

    setGuestName(guest_name);
    setRoomNumber(arr);
    setRoomList(list);
  };

  const handleEnter = (e) => {
    if (e.key === "Enter") {
      setCheckinListTable([]);
      getCheckinList();
    }
  };

  const openModal = () => {
    setIsOpen(!isOpen);
  };

  const detailInformation = (title, value) => {
    return (
      <div className="d-flex flex-row mt-1">
        <div className="col px-0">
          <span className="grey f8-vw">{title}</span>
        </div>
        <div className="col px-0">
          <span className="text-black f8-vw">: {value ? value : "-"}</span>
        </div>
      </div>
    );
  };
  const mobileDetailInformation = (title, value) => {
    return (
      <div className="d-flex flex-row mt-1">
        <div className="col-6 px-0">
          <span className="grey f12-vw">{title}</span>
        </div>
        <div className="col px-0">
          <span className="text-black fw-600 f12-vw">
            : {value ? value : "-"}
          </span>
        </div>
      </div>
    );
  };

  const isPicked = roomNumber?.length >= 1 ? true : false;

  const isChanging = () => {
    return detailCheckin?.ota_booking_id !== formEdit?.bookingCode ||
      detailCheckin.booked_by !== formEdit.orderName ||
      detailCheckin.phone !== formEdit.phoneNumber ||
      detailCheckin?.guest_qty !== formEdit.guest ||
      detailCheckin?.room_booked !== formEdit.room ||
      detailCheckin.booked_by !== formEdit.guestName ||
      detailCheckin.email !== formEdit.email ||
      formatDate(new Date(detailCheckin.checkin_date)) !==
        formatDate(formEdit.checkin) ||
      formatDate(new Date(detailCheckin.checkout_date)) !==
        formatDate(formEdit.checkout) ||
      detailCheckin.room_charge_ota !== formEdit.totalcharge ||
      formatDate(new Date(detailCheckin.ota_booking_date)) !==
        formatDate(formEdit.booking_date) ||
      roomTypeSelected.value !== detailCheckin.room_type_id ||
      otherSelected.label !== detailCheckin.ota_name ||
      purposeSelected.value !== detailCheckin.purpose_id ||
      guestTypeSelected.value !== detailCheckin.guest_type_id ||
      showImgEdit !==
        `${bucketRnaWeb + detailCheckin.ota_booking_evidence}?${oss}`
      ? true
      : false;
  };

  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);

  useEffect(() => {
    if (
      search.booking_id === "" &&
      search.guestname === "" &&
      otaSelected?.value === undefined &&
      !isOpeningShift
    ) {
      getCheckinList();
    }
  }, [getCheckinList, search, otaSelected, isOpeningShift]);

  useEffect(() => {
    getEarlyCheckin();
  }, [getEarlyCheckin]);

  useEffect(() => {
    getRoomingList(1, true);
  }, [getRoomingList]);

  useEffect(() => {
    let interval;
    if (currentPage === "detail-checkin") {
      interval = setInterval(() => {
        getEarlyCheckin();
      }, 60000);
    }
    return () => {
      clearInterval(interval);
    };
  }, [currentPage, getEarlyCheckin]);

  useEffect(() => {
    if (!isEmpty(detailCheckin)) {
      setFormEdit({
        ...formEdit,
        bookingCode: detailCheckin.ota_booking_id,
        orderName: detailCheckin.booked_by,
        phoneNumber: detailCheckin.phone,
        guest: detailCheckin?.guest_qty,
        room: detailCheckin?.room_booked,
        guestName: detailCheckin.booked_by,
        email: detailCheckin.email,
        checkin: new Date(detailCheckin.checkin_date),
        checkout: new Date(detailCheckin.checkout_date),
        totalcharge: detailCheckin.room_charge_ota,
        booking_date: new Date(detailCheckin.ota_booking_date),
      });
      setRoomTypeSelected(
        filterStringValue(roomType, detailCheckin.room_type_id)
      );
      setOtherSelected(
        filterStringLabel(otherPlatform, detailCheckin?.ota_name)
      );
      setPurposeSelected(filterPurpose(detailCheckin.purpose_id));
      setGuestTypeSelected(filterGuestType(detailCheckin.guest_type_id));

      !!detailCheckin?.ota_booking_evidence
        ? setShowImgEdit(
            `${bucketRnaWeb + detailCheckin.ota_booking_evidence}?${oss}`
          )
        : setShowImgEdit("");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [detailCheckin]);

  useEffect(() => {
    getCharge();
  }, [getCharge]);

  useEffect(() => {
    getListOtaName();
    getOther();

    return () => {
      setOtherPlatform([]);
      setOtaList([]);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (currentPage === "list-checkin") {
      setShowFilter(true);
    }
    return () => {
      setShowFilter(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);

  useEffect(() => {
    if (isPrint) {
      setTimeout(() => {
        setIsPrint(false);
      }, 6000);
    }
  }, [isPrint]);

  const switchPage = (page) => {
    switch (page) {
      case "list-checkin":
        return (
          <CheckinList
            list={checkinListTable}
            search={search}
            setSearch={setSearch}
            setcurrentPage={setcurrentPage}
            setDetailCheckin={setDetailCheckin}
            loadingList={loadingList}
            errorList={errorList}
            otaList={otaList}
            otaSelected={otaSelected}
            setOtaSelected={setOtaSelected}
            searchFilter={() => {
              setCheckinListTable([]);
              getCheckinList();
            }}
            setMode={setMode}
            openModal={openModal}
            onKeyPress={handleEnter}
            setpathLocation={setpathLocation}
          />
        );
      // break;
      case "detail-checkin":
        return (
          <CheckinDetail
            detailCheckin={detailCheckin}
            setcurrentPage={setcurrentPage}
            roomList={roomList}
            roomNumber={roomNumber}
            guestName={guestName}
            setGuestName={setGuestName}
            earlyCheckin={earlyCheckin}
            setfileUpload={setFileUpload}
            deposit={deposit}
            setDeposit={setDeposit}
            totalCharge={totalCharge}
            setTotalCharge={setTotalCharge}
            roomPrice={roomPrice}
            setRoomList={setRoomList}
            setRoomNumber={setRoomNumber}
            openModal={openModal}
            setMode={setMode}
            setError={setError}
            setImgPopup={setImgPopup}
            fileUpload={fileUpload}
            showImg={showImg}
            setShowImg={setShowImg}
            reset={reset}
            setpathLocation={setpathLocation}
            roomingList={roomingList}
            checkNextPage={checkNextPage}
            hasMore={hasMore}
            loadingRoomingList={loadingRoomingList}
            errorRoomingList={errorRoomingList}
            handleSelect={handleSelect}
            errorEarlyCheckin={errorEarlyCheckin}
          />
        );

      default:
        break;
    }
  };

  const reset = () => {
    setFileUpload([]);
    setTotalCharge(0);
    setDeposit(0);
    setRoomPrice(0);
    setGuestName([]);
    setRoomNumber([]);
    setDetailCheckin({});
    setFileUploadEdit([]);
    setImgPopup("");
    setHeaderPopup("");
    setMode("");
    setShowImg(null);
    setShowImgEdit(null);
    setCancelNote("");
    setCancelFile([]);
    setShowImgCancel("");
    setPayment(0);
    setBankSelected([]);
    setPaymentSelected([]);
    setChange(0);
    setCharge({});
    setErrorCharge("");
    setIsPrint(false);
    getCheckinList();
    setErrorEarlyCheckin("");
  };

  const confirmButton = () => {
    setcurrentPage("list-checkin");
    openModal();
    reset();
  };

  const validationRedirect = () => {
    return totalCharge > 0 || deposit > 0
      ? history.push("/reservasi/payment-process")
      : confirmButton();
  };
  const handleOtaSelected = (val) => {
    if (!val) {
      setOtaSelected([]);
    } else {
      setOtaSelected(val);
    }
  };

  const resetFilter = () => {
    setOtaSelected([]);
    setSearch({ ...search, booking_id: "", guestname: "" });
    getCheckinList();
  };

  useEffect(() => {
    getRoomNumber();
  }, [getRoomNumber]);

  useEffect(() => {
    getPriceHMS();
  }, [getPriceHMS]);

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
    return () => {
      document.body.style.overflow = "unset";
    };
  }, [isOpen]);

  return (
    <div
      className={`check-out-page my-1 ${!isOpeningShift ? "" : "not-opening"}`}
      style={{ position: "relative" }}
    >
      <ToastContainer />
      <div className="px-3">{switchPage(currentPage)}</div>
      {IS_MOBILE && (
        <div>
          <BottomSheet
            open={openButtonSheet}
            onDismiss={() => setOpenButtonSheet(false)}
            snapPoints={({ minHeight }) => minHeight}
            header={
              <div className="d-flex justify-content-between">
                <span className="purple f16-vw fw-600 px-2">Filter</span>
                <span
                  className="px-2"
                  onClick={() => setOpenButtonSheet(false)}
                >
                  <Icon icon={iconUrl.closeBold} color={"grey"} width={20} />
                </span>
              </div>
            }
            footer={
              <div className="d-flex justify-content-between">
                <div className="col-6 pl-2">
                  <button
                    className="btn btn-block btn-outline-purple f12-vw"
                    onClick={() => {
                      resetFilter();
                      setOpenButtonSheet(false);
                    }}
                  >
                    Reset
                  </button>
                </div>
                <div className="col-6 pr-2">
                  <button
                    className="btn btn-block btn-gradient f12-vw text-white"
                    onClick={() => {
                      setCheckinListTable([]);
                      getCheckinList();
                      setOpenButtonSheet(false);
                    }}
                  >
                    Search
                  </button>
                </div>
              </div>
            }
          >
            <div className="mt-3" style={{ height: "17rem" }}>
              <div className="px-4 mt-2">
                <label htmlFor="" className="f12-vw m-0 p-0 fw-600">
                  Nama OTA
                </label>
                <SelectOpt
                  placeholder="Pilih OTA"
                  optionsValue={otaList}
                  onChange={(e) => handleOtaSelected(e)}
                  clear={true}
                  value={otaSelected}
                  fontSize="calc(8pt + 0.472vw)"
                  menuHeight="12rem"
                  isSearchable={true}
                />
              </div>

              <div className="px-4 mt-3">
                <label htmlFor="" className="f12-vw m-0 p-0 fw-600">
                  Kode Booking
                </label>
                {mobileSearchInput(
                  "Masukkan Kode Booking",
                  search.booking_id,
                  (e) =>
                    setSearch({
                      ...search,
                      booking_id: generateBookingCode(e.target.value),
                    }),
                  handleEnter
                )}
              </div>
              <div className="px-4 mt-3">
                <label htmlFor="" className="f12-vw m-0 p-0 fw-600">
                  Nama Tamu
                </label>
                {mobileSearchInput(
                  "Cari Tamu",
                  search.guestname,
                  (e) =>
                    setSearch({
                      ...search,
                      guestname: e.target.value,
                    }),
                  handleEnter
                )}
              </div>
            </div>
          </BottomSheet>
        </div>
      )}
      {IS_MOBILE ? (
        <PopupModal modalIsOpen={isOpen}>
          {mode === "room" ? (
            <>
              <PopupRoomNumber
                close={() => {
                  openModal();
                }}
                reset={() => {
                  setRoomNumber([]);
                  getRoomNumber();
                }}
                title="Room Number"
                roomNumber={roomList}
                onChange={(val) => setRoomNumber(val)}
                setRoomNumber={(val) => setRoomList(val)}
                isPicked={isPicked}
              />
            </>
          ) : mode === "confirm-checkin" ? (
            <MobileDetailCheckinPopup
              detail={detailCheckin}
              roomNumber={roomNumber}
              guestName={guestName}
              deposit={deposit}
              earlyCheckin={earlyCheckin}
              loading={loadingSubmit}
              totalCharge={totalCharge}
              roomPrice={roomPrice}
              close={openModal}
              confirm={confirmCheckin}
              isOpen={isOpen}
              setIsOpen={setIsOpen}
            />
          ) : mode === "confirm-success" ? (
            <>
              <NotifMessage
                closeModal={() => {
                  openModal();
                  reset();
                }}
                img={"/img/booking/checkin-success.svg"}
                messageHeader={"Check In Berhasil"}
                color=""
              />
              <div className="text-center">
                <span className="f12-vw fw-600">
                  Ingin melakukan Proses Check-In lagi?
                </span>
              </div>
              <ButtonConfirm
                close={() => {
                  validationRedirect();
                }}
                text={"Yes"}
                secondButton="No"
                secondButtonColor={"#19AAEB"}
                color="bg-green"
                confirm={() => {
                  setcurrentPage("list-checkin");
                  openModal();
                  reset();
                }}
              />
            </>
          ) : mode === "cancel-booking" ? (
            <CancelPopup
              img={"/img/popup/cancel-booking.svg"}
              title={"Apakah anda yakin ingin membatalkan Booking ini?"}
              detail={detailCheckin}
              close={() => {
                openModal();
                reset();
              }}
              cancel={cancelBooking}
              mode={mode}
              note={cancelNote}
              setNote={setCancelNote}
              setShowImg={setShowImgCancel}
              showImg={showImgCancel}
              fileUpload={cancelFile}
              setfileUpload={setCancelFile}
            />
          ) : mode === "cancel-success" ? (
            <CancelPopup
              img={"/img/popup/cancel-booking-success.svg"}
              title={"Cancel Booking Berhasil"}
              detail={detailCheckin}
              close={() => {
                openModal();
                reset();
                getCheckinList();
              }}
              mode={mode}
              note={cancelNote}
              setShowImg={setShowImgCancel}
              showImg={showImgCancel}
              fileUpload={cancelFile}
              setfileUpload={setCancelFile}
            />
          ) : mode === "edit-booking" ? (
            <PopupEditBooking
              formEdit={formEdit}
              setFormEdit={setFormEdit}
              backButton={() => {
                openModal();
                reset();
              }}
              nextButton={() => setMode("confirm-edit")}
              typeRoom={roomType}
              formError={formError}
              guestTypeSelected={guestTypeSelected}
              guesttype={guesttype}
              purpose={purpose}
              purposeSelected={purposeSelected}
              setGuestTypeSelected={setGuestTypeSelected}
              setPurposeSelected={setPurposeSelected}
              otherPlatform={otherPlatform}
              otherSelected={otherSelected}
              setOtherSelected={setOtherSelected}
              roomTypeSelected={roomTypeSelected}
              setRoomTypeSelected={setRoomTypeSelected}
              setFormError={setFormError}
              detailCheckin={detailCheckin}
            />
          ) : mode === "confirm-edit" ? (
            <ConfirmEditBooking
              formEdit={formEdit}
              setFormEdit={setFormEdit}
              purposeSelected={purposeSelected}
              guestTypeSelected={guestTypeSelected}
              close={() => {
                setMode("edit-booking");
              }}
              otherSelected={otherSelected}
              setfileUpload={setFileUploadEdit}
              confirm={editBooking}
              loading={loadingSubmit}
              setShowImg={setShowImgEdit}
              showImg={showImgEdit}
              fileUpload={fileUploadEdit}
              roomTypeSelected={roomTypeSelected}
              isChanging={isChanging}
            />
          ) : mode === "edit-success" ? (
            <>
              <NotifMessage
                closeModal={() => {
                  openModal();
                  reset();
                }}
                img={"/img/booking/edit-booking-success.svg"}
                // messageHeader={"Edit Booking Berhasil!"}
                width="21rem"
              />
              <div className="f14-vw text-black fw-700 text-center">
                Edit Booking Berhasil!
              </div>
              <div className="px-4">
                <div className="px-1">
                  <hr className="separator-blue my-1" />
                </div>
              </div>
              <div
                className="d-flex flex-column hide-scrollbar px-3"
                style={{ height: "20rem", overflowY: "scroll" }}
              >
                <div className="col">
                  <div className="" style={{ fontWeight: 600 }}>
                    <HeaderName
                      text="Informasi Tamu"
                      fontSize="f14-vw"
                      color2="purple"
                    />
                  </div>
                  <div className="my-1">
                    {mobileDetailInformation("Nama OTA", otherSelected?.label)}
                    {mobileDetailInformation(
                      "Kode Booking",
                      formEdit?.bookingCode
                    )}
                    {mobileDetailInformation(
                      "Nama Pemesan",
                      formEdit?.guestName
                    )}
                    {mobileDetailInformation(
                      "Nomor Ponsel",
                      parsePhone(formEdit?.phoneNumber)
                    )}
                    {mobileDetailInformation(
                      "Email",
                      showMore(formEdit?.email)
                    )}
                    {mobileDetailInformation("Purpose", purposeSelected?.label)}
                    {mobileDetailInformation(
                      "Guest Type",
                      guestTypeSelected?.label
                    )}
                  </div>
                </div>
                <div className="col">
                  <div className="mt-2">
                    <HeaderName
                      text="Informasi Pemesanan"
                      fontSize="f14-vw"
                      color2="purple"
                    />
                  </div>
                  <div className="my-1">
                    {mobileDetailInformation(
                      "Check In",
                      dateFormat(formEdit?.checkin)
                    )}
                    {mobileDetailInformation(
                      "Check Out",
                      dateFormat(formEdit?.checkout)
                    )}
                    {mobileDetailInformation(
                      "Tanggal Booking",
                      dateFormat(formEdit?.booking_date)
                    )}
                    {mobileDetailInformation(
                      "Durasi",
                      getDayDuration(formEdit?.checkin, formEdit?.checkout) +
                        " Malam"
                    )}
                    {mobileDetailInformation(
                      "Tipe Kamar",
                      roomTypeSelected?.label
                    )}
                    {mobileDetailInformation(
                      "Tamu & Kamar",
                      formEdit?.guest + " Tamu, " + formEdit?.room + " Kamar"
                    )}
                  </div>
                </div>
              </div>
              <div className="px-4">
                <div className="px-1">
                  <hr className="separator-grey px-3" />
                </div>
              </div>
              <div className="d-flex justify-content-center">
                <div className="col">
                  <ButtonClose
                    close={() => {
                      openModal();
                      setCheckinListTable([]);
                      getCheckinList();
                      reset();
                    }}
                    text="Close"
                    color="bg-green"
                  />
                </div>
              </div>
            </>
          ) : mode === "error-edit" ? (
            <>
              <NotifMessage
                closeModal={openModal}
                img={imgPopup}
                messageHeader={headerPopup}
                color="red"
                messageBody={error}
              />
              <ButtonClose
                close={() => {
                  openModal();
                  reset();
                  setCheckinListTable([]);
                  getCheckinList();
                }}
                text="OK"
              />
            </>
          ) : mode === "reschedule" ? (
            <Reschedule
              item={detailCheckin}
              backButton={() => {
                openModal();
                reset();
              }}
              formEdit={formEdit}
              setFormEdit={setFormEdit}
              nextButton={() => setMode("confirm-reschedule")}
            />
          ) : mode === "confirm-reschedule" ? (
            <ConfirmReschedule
              item={detailCheckin}
              formEdit={formEdit}
              close={() => {
                setMode("reschedule");
              }}
              confirm={() => setMode("reschedule-payment")}
              charge={charge}
              errorCharge={errorCharge}
            />
          ) : mode === "reschedule-payment" ? (
            <ReschedulePayment
              id={detailCheckin.booking_id}
              close={() => {
                setMode("confirm-reschedule");
              }}
              charge={charge}
              loading={loadingCharge}
              payment={payment}
              setPayment={setPayment}
              change={change}
              setChange={setChange}
              errorCharge={errorCharge}
              setPaymentSelected={setPaymentSelected}
              setBankSelected={setBankSelected}
              bankSelected={bankSelected}
              paymentSelected={paymentSelected}
              confirm={rescheduleBooking}
            />
          ) : mode === "reschedule-success" ? (
            <>
              <NotifMessage
                closeModal={() => {
                  openModal();
                  reset();
                }}
                img={"/img/booking/edit-booking-success.svg"}
                messageHeader={"Reschedule Booking Berhasil!"}
                width="21rem"
              />
              <div className="px-4">
                <hr className="separator-grey px-3" />
              </div>
              <div
                className="d-flex flex-column hide-scrollbar px-3"
                style={{ height: "20rem", overflowY: "scroll" }}
              >
                <div className="col">
                  <div className="">
                    <HeaderName
                      text="Kode Booking"
                      fontSize="f14-vw fw-600"
                      color2="purple"
                    />
                    <div className="f12-vw">
                      {detailCheckin?.ota_booking_id}
                    </div>
                  </div>
                  <div className="" style={{ fontWeight: 600 }}>
                    <HeaderName
                      text="Informasi Tamu"
                      fontSize="f14-vw"
                      color2="purple"
                    />
                  </div>
                  <div className="my-1">
                    {mobileDetailInformation(
                      "Nama OTA",
                      detailCheckin?.ota_name
                    )}
                    {mobileDetailInformation(
                      "Tanggal Booking",
                      getServerTimeWithoutDays(detailCheckin?.ota_booking_date)
                    )}
                    {mobileDetailInformation(
                      "Nama Pemesan",
                      showMoreGuest(detailCheckin?.booked_by)
                    )}
                    {mobileDetailInformation(
                      "Nomor Ponsel",
                      parsePhone(detailCheckin?.phone)
                    )}
                    {mobileDetailInformation(
                      "Email",
                      showMoreGuest(detailCheckin?.email)
                    )}
                  </div>
                </div>
                <div className="col">
                  <div className="" style={{ fontWeight: 600 }}>
                    <HeaderName
                      text="Informasi Pemesanan"
                      fontSize="f14-vw"
                      color2="purple"
                    />
                  </div>
                  <div className="my-1">
                    {mobileDetailInformation(
                      "Check In",
                      dateFormat(formEdit?.checkin)
                    )}
                    {mobileDetailInformation(
                      "Check Out",
                      dateFormat(formEdit?.checkout)
                    )}
                    {mobileDetailInformation(
                      "Durasi",
                      getDayDuration(
                        detailCheckin?.checkin_date,
                        detailCheckin?.checkout_date
                      ) + " Malam"
                    )}
                    {mobileDetailInformation(
                      "Tipe Kamar",
                      detailCheckin?.room_type_name
                    )}
                    {mobileDetailInformation(
                      "Tamu & Kamar",
                      detailCheckin?.guest_qty +
                        " Tamu, " +
                        detailCheckin?.room_booked +
                        " Kamar"
                    )}
                    {mobileDetailInformation(
                      "Purpose",
                      filterPurpose(detailCheckin?.purpose_id)?.label
                    )}
                    {mobileDetailInformation(
                      "Guest Type",
                      filterGuestType(detailCheckin?.guest_type_id)?.label
                    )}
                  </div>
                  <div className="mt-3">
                    <HeaderName
                      text="Total Charge"
                      fontSize="f14-vw fw-600"
                      color2="purple"
                    />
                    <div
                      className="d-flex justify-content-between align-items-center border px-3 py-2 rounded f12-vw fw-700"
                      style={{ backgroundColor: "#ececec" }}
                    >
                      <span>Rp</span>
                      <span>{priceSplit(charge?.total_charge)}</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="px-4">
                <hr className="separator-grey px-3" />
              </div>
              <div className="d-flex justify-content-center">
                <div className="col">
                  <ButtonClose
                    close={() => {
                      openModal();
                      setCheckinListTable([]);
                      getCheckinList();
                      reset();
                    }}
                    text="Close"
                    color="bg-green"
                  />
                </div>
              </div>
            </>
          ) : mode === "checkin-reconfirm" ? (
            <WarningModal
              close={openModal}
              confirm={() => setMode("confirm-checkin")}
              messageHeader={"Anda Yakin Proses Check-in?"}
              type="Check-in"
              book_id={detailCheckin?.ota_booking_id}
              date={detailCheckin?.checkin_date}
              guestName={guestName?.join(",")}
            />
          ) : (
            <>
              <NotifMessage
                closeModal={openModal}
                img={imgPopup}
                messageHeader={headerPopup}
                color="red"
                messageBody={error}
              />
              <ButtonClose close={openModal} text="OK" />
            </>
          )}
        </PopupModal>
      ) : (
        <PopupModal modalIsOpen={isOpen}>
          {mode === "room" ? (
            <>
              <PopupRoomNumber
                close={() => {
                  openModal();
                }}
                reset={() => {
                  setRoomNumber([]);
                  getRoomNumber();
                }}
                title="Room Number"
                roomNumber={roomList}
                onChange={(val) => setRoomNumber(val)}
                setRoomNumber={(val) => setRoomList(val)}
                isPicked={isPicked}
              />
            </>
          ) : mode === "confirm-checkin" ? (
            <DetailCheckinPopup
              detail={detailCheckin}
              roomNumber={roomNumber}
              guestName={guestName}
              deposit={deposit}
              earlyCheckin={earlyCheckin}
              loading={loadingSubmit}
              totalCharge={totalCharge}
              roomPrice={roomPrice}
              close={openModal}
              confirm={confirmCheckin}
              isOpen={isOpen}
              setIsOpen={setIsOpen}
            />
          ) : mode === "confirm-success" ? (
            <>
              <NotifMessage
                closeModal={() => {
                  openModal();
                  reset();
                }}
                img={"/img/booking/checkin-success.svg"}
                messageHeader={"Check In Berhasil"}
                color=""
              />
              <div className="text-center">
                <span className="f8-vw fw-600">
                  Ingin melakukan Proses Check-In lagi?
                </span>
              </div>
              <ButtonConfirm
                close={() => {
                  validationRedirect();
                }}
                text={"Yes"}
                secondButton="No"
                secondButtonColor={"#19AAEB"}
                color="bg-green"
                confirm={() => {
                  setcurrentPage("list-checkin");
                  openModal();
                  reset();
                }}
              />
            </>
          ) : mode === "cancel-booking" ? (
            <CancelPopup
              img={"/img/popup/cancel-booking.svg"}
              title={"Apakah anda yakin ingin membatalkan Booking ini?"}
              detail={detailCheckin}
              close={() => {
                openModal();
                reset();
              }}
              cancel={cancelBooking}
              mode={mode}
              note={cancelNote}
              setNote={setCancelNote}
              setShowImg={setShowImgCancel}
              showImg={showImgCancel}
              fileUpload={cancelFile}
              setfileUpload={setCancelFile}
            />
          ) : mode === "cancel-success" ? (
            <CancelPopup
              img={"/img/popup/cancel-booking-success.svg"}
              title={"Cancel Booking Berhasil"}
              detail={detailCheckin}
              close={() => {
                openModal();
                reset();
                getCheckinList();
              }}
              mode={mode}
              note={cancelNote}
              setShowImg={setShowImgCancel}
              showImg={showImgCancel}
              fileUpload={cancelFile}
              setfileUpload={setCancelFile}
            />
          ) : mode === "edit-booking" ? (
            <PopupEditBooking
              formEdit={formEdit}
              setFormEdit={setFormEdit}
              backButton={() => {
                openModal();
                reset();
              }}
              nextButton={() => setMode("confirm-edit")}
              typeRoom={roomType}
              formError={formError}
              guestTypeSelected={guestTypeSelected}
              guesttype={guesttype}
              purpose={purpose}
              purposeSelected={purposeSelected}
              setGuestTypeSelected={setGuestTypeSelected}
              setPurposeSelected={setPurposeSelected}
              otherPlatform={otherPlatform}
              otherSelected={otherSelected}
              setOtherSelected={setOtherSelected}
              roomTypeSelected={roomTypeSelected}
              setRoomTypeSelected={setRoomTypeSelected}
              setFormError={setFormError}
              detailCheckin={detailCheckin}
            />
          ) : mode === "confirm-edit" ? (
            <ConfirmEditBooking
              formEdit={formEdit}
              setFormEdit={setFormEdit}
              purposeSelected={purposeSelected}
              guestTypeSelected={guestTypeSelected}
              close={() => {
                setMode("edit-booking");
              }}
              otherSelected={otherSelected}
              setfileUpload={setFileUploadEdit}
              confirm={editBooking}
              loading={loadingSubmit}
              setShowImg={setShowImgEdit}
              showImg={showImgEdit}
              fileUpload={fileUploadEdit}
              roomTypeSelected={roomTypeSelected}
              isChanging={isChanging}
            />
          ) : mode === "edit-success" ? (
            <>
              <NotifMessage
                closeModal={() => {
                  openModal();
                  reset();
                }}
                img={"/img/booking/edit-booking-success.svg"}
                messageHeader={"Edit Booking Berhasil!"}
                width="35rem"
              />
              <div className="px-4">
                <hr className="separator-grey px-3" />
              </div>
              <div className="d-flex flex-row">
                <div className="col px-4">
                  <div className="" style={{ fontWeight: 600 }}>
                    <HeaderName
                      text="Informasi Tamu"
                      fontSize="f10-vw"
                      color2="purple"
                    />
                  </div>

                  <div className="my-1">
                    {detailInformation("Nama OTA", otherSelected?.label)}
                    {detailInformation("Kode Booking", formEdit?.bookingCode)}
                    {detailInformation("Nama Pemesan", formEdit?.guestName)}
                    {detailInformation(
                      "Nomor Ponsel",
                      parsePhone(formEdit?.phoneNumber)
                    )}
                    {detailInformation("Email", showMoreGuest(formEdit?.email))}
                    {detailInformation("Purpose", purposeSelected?.label)}
                    {detailInformation("Guest Type", guestTypeSelected?.label)}
                  </div>
                </div>
                <div className="col">
                  <div className="" style={{ fontWeight: 600 }}>
                    <HeaderName
                      text="Informasi Pemesanan"
                      fontSize="f10-vw"
                      color2="purple"
                    />
                  </div>
                  <div className="my-1">
                    {detailInformation(
                      "Check In",
                      dateFormat(formEdit?.checkin)
                    )}
                    {detailInformation(
                      "Check Out",
                      dateFormat(formEdit?.checkout)
                    )}
                    {detailInformation(
                      "Tanggal Booking",
                      dateFormat(formEdit?.booking_date)
                    )}
                    {detailInformation(
                      "Durasi",
                      getDayDuration(formEdit?.checkin, formEdit?.checkout) +
                        " Malam"
                    )}
                    {detailInformation("Tipe Kamar", roomTypeSelected?.label)}
                    {detailInformation(
                      "Tamu & Kamar",
                      formEdit?.guest + " Tamu, " + formEdit?.room + " Kamar"
                    )}
                  </div>
                </div>
              </div>
              <div className="px-4">
                <hr className="separator-grey px-3" />
              </div>
              <div className="d-flex justify-content-center">
                <div className="col-8">
                  <ButtonClose
                    close={() => {
                      openModal();
                      setCheckinListTable([]);
                      getCheckinList();
                      reset();
                    }}
                    text="Close"
                    color="bg-green"
                  />
                </div>
              </div>
            </>
          ) : mode === "error-edit" ? (
            <>
              <NotifMessage
                closeModal={openModal}
                img={imgPopup}
                messageHeader={headerPopup}
                color="red"
                messageBody={error}
              />
              <ButtonClose
                close={() => {
                  openModal();
                  reset();
                  setCheckinListTable([]);
                  getCheckinList();
                }}
                text="OK"
              />
            </>
          ) : mode === "reschedule" ? (
            <Reschedule
              item={detailCheckin}
              backButton={() => {
                openModal();
                reset();
              }}
              formEdit={formEdit}
              setFormEdit={setFormEdit}
              nextButton={() => setMode("confirm-reschedule")}
            />
          ) : mode === "confirm-reschedule" ? (
            <ConfirmReschedule
              item={detailCheckin}
              formEdit={formEdit}
              close={() => {
                setMode("reschedule");
              }}
              confirm={() => setMode("reschedule-payment")}
              charge={charge}
              errorCharge={errorCharge}
            />
          ) : mode === "reschedule-payment" ? (
            <ReschedulePayment
              id={detailCheckin.booking_id}
              close={() => {
                setMode("confirm-reschedule");
              }}
              charge={charge}
              loading={loadingCharge}
              payment={payment}
              setPayment={setPayment}
              change={change}
              setChange={setChange}
              errorCharge={errorCharge}
              setPaymentSelected={setPaymentSelected}
              setBankSelected={setBankSelected}
              bankSelected={bankSelected}
              paymentSelected={paymentSelected}
              confirm={rescheduleBooking}
            />
          ) : mode === "reschedule-success" ? (
            <>
              <NotifMessage
                closeModal={() => {
                  openModal();
                  reset();
                }}
                img={"/img/booking/edit-booking-success.svg"}
                messageHeader={"Reschedule Booking Berhasil!"}
                width="38rem"
              />
              <div className="px-4">
                <hr className="separator-grey px-3" />
              </div>
              <div className="d-flex flex-row">
                <div className="col px-4">
                  <div className="">
                    <HeaderName
                      text="Kode Booking"
                      fontSize="f10-vw fw-600"
                      color2="purple"
                    />
                    <div className="f8-vw">{detailCheckin?.ota_booking_id}</div>
                  </div>
                  <div className="" style={{ fontWeight: 600 }}>
                    <HeaderName
                      text="Informasi Tamu"
                      fontSize="f10-vw"
                      color2="purple"
                    />
                  </div>
                  <div className="my-1">
                    {detailInformation("Nama OTA", detailCheckin?.ota_name)}
                    {detailInformation(
                      "Tanggal Booking",
                      getServerTimeWithoutDays(detailCheckin?.ota_booking_date)
                    )}
                    {detailInformation(
                      "Nama Pemesan",
                      showMoreGuest(detailCheckin?.booked_by)
                    )}
                    {detailInformation(
                      "Nomor Ponsel",
                      parsePhone(detailCheckin?.phone)
                    )}
                    {detailInformation(
                      "Email",
                      showMoreGuest(detailCheckin?.email)
                    )}
                  </div>
                </div>
                <div className="col">
                  <div className="" style={{ fontWeight: 600 }}>
                    <HeaderName
                      text="Informasi Pemesanan"
                      fontSize="f10-vw"
                      color2="purple"
                    />
                  </div>
                  <div className="my-1">
                    {detailInformation(
                      "Check In",
                      dateFormat(formEdit?.checkin)
                    )}
                    {detailInformation(
                      "Check Out",
                      dateFormat(formEdit?.checkout)
                    )}
                    {detailInformation(
                      "Durasi",
                      getDayDuration(
                        detailCheckin?.checkin_date,
                        detailCheckin?.checkout_date
                      ) + " Malam"
                    )}
                    {detailInformation(
                      "Tipe Kamar",
                      detailCheckin?.room_type_name
                    )}
                    {detailInformation(
                      "Tamu & Kamar",
                      detailCheckin?.guest_qty +
                        " Tamu, " +
                        detailCheckin?.room_booked +
                        " Kamar"
                    )}
                    {detailInformation(
                      "Purpose",
                      filterPurpose(detailCheckin?.purpose_id)?.label
                    )}
                    {detailInformation(
                      "Guest Type",
                      filterGuestType(detailCheckin?.guest_type_id)?.label
                    )}
                  </div>
                  <div className="mt-4">
                    <HeaderName
                      text="Total Charge"
                      fontSize="f10-vw fw-600"
                      color2="purple"
                    />
                    <div
                      className="d-flex justify-content-between align-items-center border px-3 py-2 rounded f8-vw fw-700"
                      style={{ backgroundColor: "#ececec" }}
                    >
                      <span>Rp</span>
                      <span>{priceSplit(charge?.total_charge)}</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="px-4">
                <hr className="separator-grey px-3" />
              </div>
              <div className="d-flex justify-content-center">
                <div className="col-8">
                  <ButtonClose
                    close={() => {
                      openModal();
                      setCheckinListTable([]);
                      getCheckinList();
                      reset();
                    }}
                    text="Close"
                    color="bg-green"
                  />
                </div>
              </div>
            </>
          ) : mode === "checkin-reconfirm" ? (
            <WarningModal
              close={openModal}
              confirm={() => setMode("confirm-checkin")}
              messageHeader={"Anda Yakin Proses Check-in?"}
              type="Check-in"
              book_id={detailCheckin?.ota_booking_id}
              date={detailCheckin?.checkin_date}
              guestName={guestName?.join(",")}
            />
          ) : (
            <>
              <NotifMessage
                closeModal={openModal}
                img={imgPopup}
                messageHeader={headerPopup}
                color="red"
                messageBody={error}
              />
              <ButtonClose close={openModal} text="OK" />
            </>
          )}
        </PopupModal>
      )}

      <div style={{ display: "none" }}>
        <Print launch_print={isPrint}>
          {paymentSelected?.value === 3 ? (
            <p className="p-print txt-print-h1">SLIP Pembayaran</p>
          ) : (
            <p className="p-print txt-print-h1">Billing</p>
          )}
          <>
            {genPaymentID(paymentId)}
            <p className="p-print txt-print-h2">Reschedule</p>
            {createLineDash()}
            <p className="p-print txt-print-h3">{`${getServerTime(
              date_now,
              timezone
            )} - ${getClientTimeWithoutSecond(
              date_now,
              timezone
            )} ${getTimezoneOffsetClient(timezone)}`}</p>
            <p className="p-print txt-print-h3">{hotel_info?.hotel_name}</p>
            {createLineDash()}
            <table className="table-print">
              <tbody>{genContent1("Kasir", userProfile?.nama)}</tbody>
            </table>
            {genBookingId("Booking ID", detailCheckin?.ota_booking_id)}
            {createLineDash()}
            <p className="p-print txt-print-section-h2">Informasi Booking</p>
            <table className="table-print">
              <tbody>
                {genContent1("Tipe Kamar", detailCheckin?.room_type_name)}
              </tbody>
            </table>
            {createLineDash()}
            <table className="table-print">
              <tbody>
                {!isEmpty(paymentSelected) &&
                  genContent1("Metode Pembayaran", paymentSelected?.label)}
                {paymentSelected?.value === 2 &&
                  genContent1("Nama Bank", bankSelected?.label)}
              </tbody>
            </table>
            {createLineDash()}
            {paymentSelected?.value === 3 ? (
              <p className="p-print txt-print-section-h2">
                Informasi Pembayaran
              </p>
            ) : (
              <p className="p-print txt-print-section-h2">Informasi Tagihan</p>
            )}
            <table className="table-print">
              <tbody>
                {genContent2("Reschedule Fee", charge?.reschedule_fee)}
                {genContent2("Room Charge", charge?.room_charge)}
                {genContent2("Total Charge", charge?.total_charge)}
              </tbody>
            </table>
            {paymentSelected?.value === 3 && (
              <>
                {createLineSolid()}
                <table className="table-print">
                  <tbody>
                    {genContent2("Pembayaran Tunai", payment)}
                    {genContent2("Kembalian", change)}
                  </tbody>
                </table>
              </>
            )}
          </>
        </Print>
      </div>
    </div>
  );
};

export default Checkin;
