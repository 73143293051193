import { Icon } from "@iconify/react";
import React, { useEffect } from "react";
import ReactTooltip from "react-tooltip";
import { HeaderName } from "../../../common/HeaderName";
import { IS_MOBILE } from "../../../common/Responsive";
import SelectOpt from "../../../common/SelectOpt";
import { iconUrl } from "../../../config";
import { priceSplit } from "../../../utils";
// import { filterSelected } from "../administrator/utils";

export const PaymentProcessPopup = ({
  loading,
  detail,
  paymentMethod,
  paymentSelected,
  setpaymentSelected,
  bankList,
  bankSelected,
  setBankSelected,
  checkedDepo,
  setCheckedDepo,
  totalPaid,
  // setTotalPaid,
  payment,
  setPayment,
  charge,
  setCharge,
  close,
  confirm,
}) => {
  const handlePaymentMethod = (val) => {
    setpaymentSelected(val);
    setBankSelected([]);
    setPayment(0);
  };

  const handleBankSelected = (val) => {
    setBankSelected(val);
  };

  const handlePayment = (val) => {
    setPayment(Number(val.target.value.replace(/\D/g, "")));
  };

  const detailPayment = (title, value, color, tax) => {
    return (
      <div
        className="d-flex flex-row f8-vw py-1"
        style={{ borderBottom: color ? "none" : "1px solid #ececec" }}
      >
        <div className="col-6 px-0">
          <span className={` ${color ? color : "text-black"}`}>{title}</span>
        </div>
        <div className="col px-0">
          <div className="d-flex flex-row justify-content-between">
            <div className={` text-black`}>Rp</div>
            <div
              className={`col px-0 text-right  ${color ? color : "text-black"}`}
            >
              <span className="px-2">{priceSplit(value)}</span>
            </div>
          </div>
          {tax && value > 0 && (
            <div className="px-2" style={{ position: "relative" }}>
              <small
                className="f6-vw grey"
                style={{ position: "absolute", top: -3, right: 5 }}
              >
                Sudah termasuk pajak
              </small>
            </div>
          )}
        </div>
      </div>
    );
  };

  const mobileDetailPayment = (title, value, color, tax) => {
    return (
      <div className="d-flex flex-row f12-vw my-1 py-1">
        <div className="col-6 px-0">
          <span className={` ${color ? color : "text-black"}`}>{title}</span>
        </div>
        <div className="col px-0">
          <div className="d-flex flex-row justify-content-between">
            <div className={`${color ? color : "text-black"} text-black`}>
              Rp
            </div>
            <div
              className={`col px-0 text-right  ${color ? color : "text-black"}`}
            >
              <span className="px-2">{priceSplit(value)}</span>
            </div>
          </div>
          {tax && value > 0 && (
            <div className="px-2" style={{ position: "relative" }}>
              <small
                className="f7-vw grey"
                style={{ position: "absolute", top: -3, right: 5 }}
              >
                Sudah termasuk pajak
              </small>
            </div>
          )}
        </div>
      </div>
    );
  };

  const paymentInput = (title, value, onChange, autoFocus) => {
    return (
      <div className="d-flex flex-row align-items-center f8-vw">
        <div className="col-6 px-0">
          <span className={`font-weight-bold text-black`}>{title}</span>
        </div>
        <div className="col px-0">
          <div className="d-flex flex-row align-items-center justify-content-between">
            <div className={`font-weight-bold text-black`}>Rp</div>
            <div
              className={`col px-0 font-weight-bold text-black ml-2`}
              style={{ border: "1px solid lightgray", borderRadius: 5 }}
            >
              <input
                type="text"
                className="form-control text-right border-0 form-control-sm f8-vw font-weight-bold"
                value={priceSplit(value)}
                style={{ textAlign: "right" }}
                autoFocus={autoFocus}
                onChange={onChange}
                onFocus={(e) => (e.target.value = "")}
              />
            </div>
          </div>
        </div>
      </div>
    );
  };

  const mobilePaymentInput = (title, value, onChange, autoFocus) => {
    return (
      <div className="d-flex flex-row align-items-center f12-vw">
        <div className="col-6 px-0">
          <span className={`font-weight-bold text-black`}>{title}</span>
        </div>
        <div className="col px-0">
          <div className="d-flex flex-row align-items-center justify-content-between">
            <div className={`font-weight-bold text-black`}>Rp</div>
            <div
              className={`col px-0 font-weight-bold text-black ml-2`}
              style={{ border: "1px solid lightgray", borderRadius: 5 }}
            >
              <input
                type="text"
                className="form-control text-right border-0 form-control-sm f12-vw font-weight-bold"
                value={priceSplit(value)}
                style={{ textAlign: "right" }}
                autoFocus={autoFocus}
                onChange={onChange}
                onFocus={(e) => (e.target.value = "")}
                maxLength={12}
                inputMode="numeric"
              />
            </div>
          </div>
        </div>
      </div>
    );
  };

  const checkBoxDeposit = (title, value, isChecked, isCheckedBox, disabled) => {
    return (
      <div
        className={`d-flex flex-row align-items-center mt-1 pb-1 ${
          IS_MOBILE ? "f12-vw" : "f8-vw"
        }`}
      >
        <div className="col px-0 d-flex align-items-center">
          <input
            type="checkbox"
            readOnly
            checked={isChecked}
            onClick={isCheckedBox}
            data-tip="Include Deposit"
            disabled={disabled ? disabled : false}
          />
          <ReactTooltip
            backgroundColor="grey"
            textColor="white"
            className="data-class"
            border
          />
          <label className={`form-check-label mx-1 `} htmlFor="defaultCheck1">
            {title}
          </label>
        </div>
        <div
          className={`col-6 px-0 d-flex align-items-center justify-content-between`}
        >
          <div className="mr-auto">Rp.</div>
          <div className="mr-2">{priceSplit(value)}</div>
        </div>
      </div>
    );
  };

  useEffect(() => {
    if (checkedDepo && detail?.deposit >= detail?.total_charge) {
      setpaymentSelected([]);
    }
  }, [checkedDepo, detail?.deposit, detail?.total_charge, setpaymentSelected]);

  useEffect(() => {
    if (detail?.process_type === 2) {
      if (
        paymentSelected?.value === 3 &&
        payment >= totalPaid &&
        !checkedDepo
      ) {
        setCharge(
          Math.abs(
            parseInt(payment) + parseInt(detail?.deposit) - parseInt(totalPaid)
          )
        );
      } else if (
        paymentSelected?.value === 3 &&
        payment >= totalPaid &&
        checkedDepo
      ) {
        setCharge(Math.abs(parseInt(payment) - parseInt(totalPaid)));
      } else {
        setCharge(0);
      }
    } else {
      if (payment >= totalPaid) {
        setCharge(Math.abs(parseInt(payment) - parseInt(totalPaid)));
      } else {
        setCharge(0);
      }
    }
  }, [paymentSelected, totalPaid, payment, checkedDepo, detail, setCharge]);

  // useEffect(() => {
  //   if (totalPaid === 0) {
  //     setpaymentSelected(filterSelected(paymentMethod, 3));
  //   } else {
  //     // setpaymentSelected([]);
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [totalPaid]);

  const isEnabled = () => {
    return (
      (paymentSelected?.value === 2 && bankSelected?.value && !loading) ||
      (paymentSelected?.value === 3 && payment >= totalPaid && !loading) ||
      payment >= totalPaid ||
      (totalPaid === 0 && paymentSelected?.value === 3)
    );
  };

  // const isEnabled2 = () => {
  //   return (
  //     (paymentSelected?.value === 2 && bankSelected?.value && !loading) ||
  //     (paymentSelected?.value === 3 && !loading)
  //   );
  // };

  return (
    <>
      {IS_MOBILE ? (
        <div
          className="d-flex flex-column px-2 pb-3 pt-2"
          style={{ width: "21rem" }}
        >
          <div className="col px-0 py-1">
            <div className="d-flex align-items-center justify-content-between">
              <div className="col">
                <div className="d-flex align-items-center justify-content-start">
                  <Icon
                    icon={iconUrl.walletCreditCard20Filled}
                    color="orange"
                    width={25}
                  />
                  <div className="fw-600 mx-2">
                    <HeaderName
                      text="Pembayaran"
                      fontSize="f14-vw fw-700"
                      color1="blue-title"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="col px-0"
            style={{
              backgroundColor: "white",
              borderRadius: "5px",
            }}
          >
            <div className="d-flex flex-column my-1">
              <div className="col">
                <div className="">
                  <label
                    htmlFor="payment-method"
                    className="f12-vw mb-2 fw-600"
                  >
                    Metode Pembayaran
                  </label>
                  <SelectOpt
                    placeholder="Pilih metode pembayaran"
                    optionsValue={paymentMethod}
                    onChange={handlePaymentMethod}
                    fontSize="calc(8pt + 0.472vw)"
                    value={paymentSelected}
                    disabled={totalPaid === 0 ? true : false}
                  />
                </div>
              </div>
              {paymentSelected.value === 2 && (
                <div className="col">
                  <div className="mt-2">
                    <label
                      htmlFor="payment-method"
                      className="f12-vw mb-2 fw-600"
                    >
                      Pilih Bank
                    </label>
                    <SelectOpt
                      placeholder="Pilih bank"
                      optionsValue={bankList}
                      onChange={handleBankSelected}
                      fontSize="calc(8pt + 0.472vw)"
                      value={bankSelected}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="col px-0 my-1">
            <div className="d-flex flex-column my-1">
              <div className="col">
                <HeaderName
                  text="Informasi Pembayaran"
                  fontSize="f14-vw fw-600"
                  color2="purple"
                />
              </div>
              <div className="col">
                {detail?.process_type === 2 && (
                  <>
                    {mobileDetailPayment(
                      "Total Harga Kamar",
                      detail?.room_charge || 0
                    )}
                    {mobileDetailPayment(
                      "Late Check Out",
                      detail?.late_checkout || 0
                    )}
                    {mobileDetailPayment(
                      "Ganti Rugi",
                      detail?.ganti_rugi || 0,
                      "text-black",
                      false
                    )}
                    {mobileDetailPayment(
                      "Denda",
                      detail?.denda || 0,
                      "text-black",
                      false
                    )}
                    <hr className="separator-blue my-1" />
                    {mobileDetailPayment(
                      "Total",
                      detail?.total_charge || 0,
                      "text-black",
                      false
                    )}
                  </>
                )}

                {detail?.process_type === 2 &&
                  checkBoxDeposit(
                    "Deposit",
                    detail?.deposit || 0,
                    checkedDepo,
                    () => setCheckedDepo(!checkedDepo),
                    false
                  )}
                {detail?.process_type === 2 && (
                  <hr className="separator-blue my-2" />
                )}
                {mobileDetailPayment(
                  detail?.process_type === 2
                    ? "Total Tagihan"
                    : "Additional Charge",
                  totalPaid || 0,
                  "text-black fw-700",
                  false
                )}
                {detail?.process_type !== 2 && paymentSelected.value === 2 && (
                  <hr className="separator-blue mt-2 mb-0" />
                )}

                {detail?.process_type === 2 &&
                  checkedDepo &&
                  detail?.deposit > detail?.total_charge &&
                  mobileDetailPayment(
                    "Pengembalian Deposit",
                    parseInt(detail?.deposit - detail?.total_charge) || 0,
                    "purple",
                    false
                  )}
                {paymentSelected.value === 3 && totalPaid > 0 && (
                  <>
                    <hr className="separator-blue my-2" />
                    {mobilePaymentInput("Pembayaran Tunai", payment, (e) =>
                      handlePayment(e)
                    )}
                    {mobileDetailPayment(
                      "Kembalian",
                      charge,
                      "text-black fw-700",
                      false
                    )}
                    <hr className="separator-blue my-2" />
                  </>
                )}
              </div>
            </div>
          </div>
          <div className="col">
            <div className="text-center mb-2">
              <small className="f10-vw fw-500">
                Apakah anda yakin ingin melanjutkan?
              </small>
            </div>
          </div>
          <div className="col px-0 py-2">
            <div className="d-flex justify-content-between">
              <div className="col">
                <button
                  className={`btn btn-block f12-vw font-weight-bold btn-outline-purple`}
                  onClick={close}
                >
                  Back
                </button>
              </div>
              <div className="col">
                <button
                  className={`btn btn-block f12-vw font-weight-bold text-white ${
                    isEnabled() ? "btn-gradient" : "btn-secondary"
                  }`}
                  onClick={confirm}
                  disabled={isEnabled() ? false : true}
                >
                  {loading && (
                    <span
                      className="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                  )}
                  Confirm
                </button>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="d-flex flex-column p-2" style={{ width: "22rem" }}>
          <div className="col px-0 py-1">
            <div className="d-flex align-items-center justify-content-between">
              <div className="col">
                <div className="d-flex align-items-center justify-content-start">
                  <Icon
                    icon={iconUrl.walletCreditCard20Filled}
                    color="orange"
                    width={25}
                  />
                  <div className="fw-600 mx-2">
                    <HeaderName
                      text="Pembayaran"
                      fontSize="f12-vw"
                      color1="blue-title"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="col px-0"
            style={{
              backgroundColor: "white",
              borderRadius: "5px",
            }}
          >
            <div className="d-flex flex-column mt-2">
              <div className="col">
                <div className="">
                  <label htmlFor="payment-method" className="f8-vw mb-2 fw-600">
                    Metode Pembayaran
                  </label>
                  <SelectOpt
                    placeholder="Pilih metode pembayaran"
                    optionsValue={paymentMethod}
                    onChange={handlePaymentMethod}
                    value={paymentSelected}
                    disabled={totalPaid === 0 ? true : false}
                  />
                </div>
              </div>
              {paymentSelected.value === 2 && (
                <div className="col">
                  <div className="mt-2">
                    <label
                      htmlFor="payment-method"
                      className="f8-vw mb-2 fw-600"
                    >
                      Pilih Bank
                    </label>
                    <SelectOpt
                      placeholder="Pilih bank"
                      optionsValue={bankList}
                      onChange={handleBankSelected}
                      value={bankSelected}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="col px-0 my-1">
            <div className="d-flex flex-column my-1">
              <div className="col">
                <HeaderName
                  text="Informasi Pembayaran"
                  fontSize="f10-vw fw-600"
                  color2="purple"
                />
              </div>
              <div className="col">
                {detail?.process_type === 2 && (
                  <>
                    {detailPayment(
                      "Total Harga Kamar",
                      detail?.room_charge || 0
                    )}
                    {detailPayment(
                      "Late Check Out",
                      detail?.late_checkout || 0
                    )}
                    {detailPayment(
                      "Ganti Rugi",
                      detail?.ganti_rugi || 0,
                      "text-black",
                      false
                    )}
                    {detailPayment(
                      "Denda",
                      detail?.denda || 0,
                      "text-black",
                      false
                    )}
                    <hr className="separator-blue my-1" />
                    {detailPayment(
                      "Total",
                      detail?.total_charge || 0,
                      "text-black",
                      false
                    )}
                  </>
                )}

                {detail?.process_type === 2 &&
                  checkBoxDeposit(
                    "Deposit",
                    detail?.deposit || 0,
                    checkedDepo,
                    () => setCheckedDepo(!checkedDepo),
                    false
                  )}
                {(detail?.process_type === 2 ||
                  detail?.process_type === 13) && (
                  <hr className="separator-blue my-2" />
                )}
                {detailPayment(
                  detail?.process_type === 2 || detail?.process_type === 13
                    ? "Total Tagihan"
                    : "Additional Charge",
                  totalPaid || 0,
                  "text-black fw-700",
                  false
                )}
                {detail?.process_type !== 2 && paymentSelected.value === 2 && (
                  <hr className="separator-blue mt-2 mb-0" />
                )}

                {detail?.process_type === 2 &&
                  checkedDepo &&
                  detail?.deposit > detail?.total_charge &&
                  detailPayment(
                    "Pengembalian Deposit",
                    parseInt(detail?.deposit - detail?.total_charge) || 0,
                    "purple",
                    false
                  )}
                {paymentSelected.value === 3 && totalPaid > 0 && (
                  <>
                    <hr className="separator-blue my-2" />
                    {paymentInput("Pembayaran Tunai", payment, (e) =>
                      handlePayment(e)
                    )}
                    {detailPayment(
                      "Kembalian",
                      charge,
                      "text-black fw-700",
                      false
                    )}
                    <hr className="separator-blue my-2" />
                  </>
                )}
              </div>
            </div>
          </div>
          <div className="col">
            <div className="text-center mb-2">
              <small className="f8-vw fw-500">
                Apakah anda yakin ingin melanjutkan?
              </small>
            </div>
          </div>
          <div className="col px-0 py-2">
            <div className="d-flex justify-content-between">
              <div className="col">
                <button
                  className={`btn btn-block f8-vw font-weight-bold btn-outline-purple`}
                  onClick={close}
                >
                  Back
                </button>
              </div>
              <div className="col">
                <button
                  className={`btn btn-block f8-vw font-weight-bold text-white ${
                    isEnabled() ? "btn-gradient" : "btn-secondary"
                  }`}
                  onClick={confirm}
                  disabled={isEnabled() ? false : true}
                >
                  {loading && (
                    <span
                      className="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                  )}
                  Confirm
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
