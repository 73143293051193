import { Icon } from "@iconify/react";
import React, { useEffect, useState } from "react";
import { HeaderName } from "../../common/HeaderName";
import MobileMultipleUpload from "../../common/MobileMultipleUpload";
import MultipleWebcam from "../../common/MultipleWebcam";
import { IS_MOBILE } from "../../common/Responsive";
import { iconUrl } from "../../config";
import { priceSplit } from "../../utils";
import { addDataPict, getErr, safeErrCode } from "../../utils/api-request";
import { t } from "../../common/ErrorMessage";

const FONT_SIZE = IS_MOBILE ? "f12-vw" : "f8-vw";
const FONT_SIZE_HEADER = IS_MOBILE ? "f16-vw" : "f12-vw";

export const ClosingShift = ({
  handleError,
  mode,
  setclosingShift,
  closeModal,
  revenue,
  change,
  setChange,
}) => {
  const [loading, setLoading] = useState(false);
  const [realCash, setrealCash] = useState(0);
  const [note, setNote] = useState("");
  const [fileUpload, setfileUpload] = useState([]);

  const totalCashOnHand = (e) => {
    setrealCash(Number(e.target.value.replace(/\D/g, "")));
  };

  const submitClosing = () => {
    setLoading(true);
    handleError(null);
    const formData = new FormData();
    const payload = {
      total_revenue: parseInt(revenue.cash),
      cash_on_hand: realCash,
      note: note,
      cash_to_next_shift: revenue?.deposit_return,
    };

    for (const key in payload) {
      formData.append(key, payload[key]);
    }
    if (fileUpload.length > 0) {
      for (const key of Object.keys(fileUpload)) {
        if (fileUpload[key]) {
          formData.append("files", fileUpload[key]);
        }
      }
    }
    // for (const pair of formData.entries()) {
    //   console.log(pair[0] + " - " + pair[1]);
    // }
    addDataPict("/api/v1/shift/closed", formData)
      .then((resp) => {
        setLoading(false);
        if (resp.status === "OK") {
          mode();
          setclosingShift({
            cash: parseInt(revenue.cash),
            transfer: parseInt(revenue.transfer),
            realCash: realCash,
            total: parseInt(revenue.cash) + parseInt(revenue.transfer),
            changeCash: change,
            nextCash: revenue.deposit_return,
            note: note,
          });
        } else {
          resp.json().then((err) => {
            handleError(t(safeErrCode(err)));
          });
        }
      })
      .catch((error) => {
        let errMessage = getErr(error);
        handleError(t(errMessage));
      });
  };

  const inputAppend = (title, onChange, val, disabled, autoFocus) => {
    return (
      <div className="form-group my-0">
        <div
          className={"col px-0 fw-600 " + FONT_SIZE}
          style={{ color: "#5E5E5E" }}
        >
          {title}
        </div>
        <div className="col px-0 input-group input-group-modal">
          <div className="input-group-prepend ">
            <span
              className={
                "input-group-text text-center align-middle text-black " +
                FONT_SIZE
              }
              style={{
                backgroundColor: "transparent",
                borderRight: 0,
                borderLeftColor: "#9E9E9E",
                borderTopColor: "#9E9E9E",
                borderBottomColor: "#9E9E9E",
                color: "#959595",
              }}
              id="basic-addon1"
            >
              Rp
            </span>
          </div>
          <input
            type="text"
            className={"form-control fw-700 " + FONT_SIZE}
            style={{
              borderLeft: 0,
              textAlign: "right",
              backgroundColor: "",
              borderRightColor: "#9E9E9E",
              borderTopColor: "#9E9E9E",
              borderBottomColor: "#9E9E9E",
              fontWeight: 500,
            }}
            aria-describedby="basic-addon1"
            onChange={onChange ? onChange : null}
            value={priceSplit(val)}
            autoFocus={autoFocus ? autoFocus : false}
            disabled={disabled}
            inputMode="numeric"
          />
        </div>
      </div>
    );
  };
  const inputAppendNoBorder = (title, val, color, bold) => {
    return (
      <div className={`form-group my-0`}>
        <div
          className={"col px-0 fw-600 " + FONT_SIZE}
          style={{ color: "#5E5E5E" }}
        >
          {title}
        </div>
        <div className="col px-0 input-group input-group-modal" style={{}}>
          <div className="input-group-prepend ">
            <span
              className={`input-group-text  ${FONT_SIZE}`}
              style={{
                borderRight: 0,
                border: "none",
                color: "#959595",
                backgroundColor: color,
              }}
            >
              Rp
            </span>
          </div>
          <input
            type="text"
            className={`form-control ${FONT_SIZE} fw-700 `}
            style={{
              border: "none",
              textAlign: "right",
              backgroundColor: color,
            }}
            aria-describedby="basic-addon1"
            value={priceSplit(val)}
            readOnly={true}
          />
        </div>
      </div>
    );
  };

  const showDetailCash = (title, value, bold, color) => {
    return (
      <div
        className={`d-flex ${FONT_SIZE} my-1 align-items-center justify-content-between`}
      >
        <div className="col-6 px-0">{title}</div>
        <div className="col px-0">Rp</div>
        <div className="col-5 px-0 text-right">
          <span>{priceSplit(value)}</span>
        </div>
      </div>
    );
  };

  useEffect(() => {
    setrealCash(revenue?.cash);
  }, [revenue]);

  useEffect(() => {
    if (realCash > revenue?.cash) {
      setrealCash(revenue?.cash);
    }
  }, [revenue, realCash]);

  useEffect(() => {
    if (revenue.cash >= realCash) {
      let d = parseInt(revenue.cash) - parseInt(realCash);
      setChange(d);
    }
  }, [realCash, revenue, setChange]);

  useEffect(() => {
    if (change > 0) {
      setNote("");
    }
  }, [change]);

  return (
    <div
      className="d-flex flex-column p-3"
      style={{ width: IS_MOBILE ? "21rem" : "38rem" }}
    >
      <div className="px-1">
        <div className="d-flex justify-content-between align-items-center">
          <div className="d-flex align-items-center">
            <span className="mr-2">
              <Icon icon={iconUrl.timerLock} width={"1.5rem"} color="orange" />
            </span>
            <span className={"fw-700 blue-title " + FONT_SIZE_HEADER}>
              Closing Shift
            </span>
          </div>
        </div>
      </div>

      <div className="col px-0 mt-2">
        <div
          className={
            IS_MOBILE
              ? "d-flex flex-column scroll-popup hide-scrollbar"
              : "d-flex flex-row"
          }
          style={{ maxHeight: IS_MOBILE ? "25rem" : "auto" }}
        >
          <div className="col px-1">
            <div className="d-flex flex-column px-1">
              <div className="col px-0">
                <HeaderName
                  text={"Closing Summary"}
                  fontSize={FONT_SIZE + " fw-700"}
                  color2="purple"
                />
                <div className="px-2 my-2">
                  {showDetailCash("Total Cash", revenue?.cash)}
                  {showDetailCash("Total Transfer", revenue?.transfer)}
                </div>
                <div
                  className={`d-flex ${FONT_SIZE} my-1 fw-700 p-2 align-items-center justify-content-between`}
                  style={{
                    backgroundColor: "#E8FEEA",
                    borderTop: "1px solid #CAD0EF",
                  }}
                >
                  <div className="col-6 px-0">Total</div>
                  <div className="col px-0">Rp</div>
                  <div className="col-5 px-0 text-right">
                    <span>
                      {priceSplit(
                        parseInt(revenue?.cash) + parseInt(revenue?.transfer)
                      )}
                    </span>
                  </div>
                </div>
                <div
                  className={`d-flex ${FONT_SIZE} my-1 fw-700 p-2 align-items-center justify-content-between`}
                  style={{
                    backgroundColor: "#E8FEEA",
                    borderBottom: "1px solid #CAD0EF",
                  }}
                >
                  <div className="col-6 px-0">Deposit</div>
                  <div className="col px-0">Rp</div>
                  <div className="col-5 px-0 text-right">
                    <span>{priceSplit(revenue?.deposit_return)}</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="d-flex flex-column">
              <div className="col my-2 pl-1 pr-0">
                {inputAppend(
                  "Real cash on hand",
                  (e) => totalCashOnHand(e),
                  realCash,
                  false,
                  true
                )}
              </div>
              <div className="col my-2 pl-1 pr-0">
                {inputAppendNoBorder("Pengembalian", change, "#EFEFEF")}
              </div>
              <div className="col my-2 pl-1 pr-0">
                {inputAppendNoBorder(
                  "Jumlah yang diserahkan ke shift selanjutnya",
                  revenue?.deposit_return,
                  "#E8FEEA"
                )}
              </div>
            </div>
          </div>
          <div className="col px-1">
            <div className="d-flex flex-column">
              <div className="col px-1">
                <label htmlFor="note" className={FONT_SIZE}>
                  Catatan
                </label>
                <textarea
                  name="note"
                  id="note"
                  rows="5"
                  className={"form-control " + FONT_SIZE}
                  style={{ borderColor: "lightgray", resize: "none" }}
                  onChange={(e) => setNote(e.target.value)}
                  value={note}
                  disabled={change > 0 ? false : true}
                  placeholder="Catatan..."
                />
              </div>
              <div className="col px-1 mt-2">
                <label htmlFor="upload-pict" className={FONT_SIZE}>
                  Add Attachment
                </label>
                {IS_MOBILE ? (
                  <MobileMultipleUpload
                    fileUpload={fileUpload}
                    setfileUpload={setfileUpload}
                    height={140}
                    title="Add Attachment"
                    fontSize={IS_MOBILE ? "f12-vw" : "f8-vw"}
                  />
                ) : (
                  <MultipleWebcam
                    fileUpload={fileUpload}
                    setfileUpload={setfileUpload}
                    height={140}
                    title="Add Attachment"
                    fontSize={IS_MOBILE ? "f12-vw" : "f8-vw"}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="px-2">
        <hr className="separator-blue my-2" />
      </div>
      <div className="px-2 text-center">
        <small className={"f8-vw"}>Apakah anda yakin ingin melanjutkan?</small>
      </div>
      <div className="popup-footer d-flex justify-content-between px-2 mt-2">
        <div className={IS_MOBILE ? "col-5 px-0" : "col-3 px-0"}>
          <button
            onClick={closeModal}
            className={"btn btn-outline-purple btn-block " + FONT_SIZE}
          >
            Back
          </button>
        </div>
        <div className={IS_MOBILE ? "col-5 px-0" : "col-3 px-0"}>
          <button
            disabled={
              realCash === "" ||
              (change > 0 && note === "") ||
              // (change > 0 && fileUpload.length === 0) ||
              // isFileUploadInvalid(fileUpload) ||
              loading
            }
            onClick={() => submitClosing()}
            className={
              realCash === "" ||
              (note === "" && change > 0) ||
              // (change > 0 && fileUpload.length === 0) ||
              // isFileUploadInvalid(fileUpload) ||
              loading
                ? "btn bg-grey text-white btn-block " + FONT_SIZE
                : "btn btn-gradient text-white btn-block " + FONT_SIZE
            }
          >
            {loading ? (
              <span
                className="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              ></span>
            ) : (
              "Confirm"
            )}
          </button>
        </div>
      </div>
    </div>
  );
};
