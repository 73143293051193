import { Icon } from "@iconify/react";
import { HeaderName } from "../../../common/HeaderName";
import { IS_MOBILE } from "../../../common/Responsive";
import { iconUrl } from "../../../config";
import {
  getDayDuration,
  getServerTimeWithoutDays,
  parsePhone,
  showMoreGuest,
  splitText,
  showMore,
} from "../../../utils";
import {
  priceContainer,
  getDataContainer,
  mobileGetDataContainer,
  mobilePriceContainer,
} from "../helper";

export const CheckoutConfirm = ({
  timezone,
  detailCheckout,
  lateCheckout,
  compensation,
  totalPaid,
  close,
  confirm,
  penalty,
  loading,
}) => {
  return (
    <>
      {IS_MOBILE ? (
        <div
          className="d-flex flex-column px-4 py-3"
          style={{ width: "21rem" }}
        >
          <div className="col px-0">
            <div className="purple fw-600 f14-vw">
              <span className="mr-2">
                <Icon
                  icon={iconUrl.formMultiple20Filled}
                  color="orange"
                  width={25}
                />
              </span>
              Konfirmasi Check Out
            </div>
            <hr className="separator-blue my-2" />
          </div>
          <div className="col px-0">
            <div className="">
              <span className="f12-vw grey">Kode Booking</span>
              <span className="ml-2 f12-vw fw-700">
                : {detailCheckout?.book_id || "-"}
              </span>
            </div>
          </div>
          <div className="col px-0 mt-1">
            <span className="grey f12-vw">Remaining Check Out</span>
            <span className="ml-2 f12-vw fw-700">
              : {detailCheckout?.checkout_remaining || "-"}
            </span>
          </div>
          <div className="col px-0">
            <hr className="separator-blue my-2" />
          </div>

          <div
            className="scroll-popup hide-scrollbar my-2"
            style={{ height: "20rem" }}
          >
            <HeaderName
              text="Informasi Booking"
              fontSize="f14-vw fw-600"
              color2="purple"
            />

            {mobileGetDataContainer(
              "Nama Pemesan",
              showMore(detailCheckout?.booked_by, 25) || "-"
            )}
            {mobileGetDataContainer(
              "Email",
              detailCheckout?.email ? showMore(detailCheckout?.email, 25) : "-"
            )}
            {mobileGetDataContainer(
              "Nomor Ponsel",
              detailCheckout?.phone ? parsePhone(detailCheckout?.phone) : "-"
            )}
            {mobileGetDataContainer(
              "Check In",
              detailCheckout?.checkin_date
                ? getServerTimeWithoutDays(
                    detailCheckout?.checkin_date,
                    timezone
                  )
                : "-"
            )}
            {mobileGetDataContainer(
              "Check Out",
              detailCheckout?.checkout_date
                ? getServerTimeWithoutDays(
                    detailCheckout?.checkout_date,
                    timezone
                  )
                : "-"
            )}
            {mobileGetDataContainer(
              "Durasi",
              detailCheckout?.checkout_date && detailCheckout?.checkin_date
                ? getDayDuration(
                    detailCheckout?.checkout_date,
                    detailCheckout?.checkin_date
                  ) + " Malam"
                : "-"
            )}
            {mobileGetDataContainer(
              "Tipe Kamar",
              detailCheckout?.room_type_name || "-"
            )}
            <div className="mt-2">
              <HeaderName
                text="Informasi Check In"
                fontSize="f14-vw fw-600"
                color2="purple"
              />

              <>
                {mobileGetDataContainer(
                  "Nomor Kamar",
                  detailCheckout?.room_number || "-"
                )}
                {mobileGetDataContainer(
                  "Nama Tamu",
                  detailCheckout?.guest_name
                    ? showMore(detailCheckout?.guest_name, 25)
                    : "-"
                )}
              </>
            </div>
            <div className="mt-2">
              <HeaderName
                text="Informasi Tagihan"
                fontSize="f14-vw fw-600"
                color2="purple"
              />
              <div className="mt-2">
                <div className="row no-gutters">
                  <div className="col-4">
                    <div className="f12-vw text-black">Late Checkout</div>
                  </div>
                  <div className="col f12-vw text-black font-weight-bold">
                    {mobilePriceContainer(lateCheckout)}
                  </div>
                </div>
                <div className="row no-gutters mt-1">
                  <div className="col-4">
                    <div className="f12-vw text-black">Ganti Rugi</div>
                  </div>
                  <div className="col f12-vw text-black font-weight-bold">
                    {mobilePriceContainer(compensation)}
                  </div>
                </div>
                <div className="row no-gutters mt-1">
                  <div className="col-4">
                    <div className="f12-vw text-black">Denda</div>
                  </div>
                  <div className="col f12-vw text-black font-weight-bold">
                    {mobilePriceContainer(penalty)}
                  </div>
                </div>
              </div>

              <hr className="separator-blue my-2" />
              <div className="detail-information d-flex flex-row">
                <div className="col-4 px-0">
                  <div className="f12-vw font-weight-bold">Total Tagihan</div>
                </div>
                <div className="col px-0 f12-vw font-weight-bold">
                  {mobilePriceContainer(totalPaid)}
                </div>
              </div>

              <div className="detail-information d-flex flex-row mt-2">
                <div className="col-4 px-0">
                  <div className="f12-vw font-weight-bold">Deposit</div>
                </div>
                <div className="col px-0 f12-vw font-weight-bold">
                  {mobilePriceContainer(detailCheckout?.deposit)}
                </div>
              </div>
            </div>
          </div>

          <div className="col px-0">
            <hr className="separator-blue my-3" />
            <div className="text-center mb-3">
              <div className="f10-vw">Periksa kembali detail informasi</div>
              <div className="f10-vw">Klik confirm untuk melanjutkan</div>
            </div>
          </div>
          <div className="col px-0">
            <div className="d-flex justify-content-between">
              <div className="col-5 px-0">
                <button
                  className="btn btn-block btn-outline-purple f12-vw"
                  onClick={close}
                >
                  Close
                </button>
              </div>
              <div className="col-5 px-0">
                <button
                  className="btn btn-gradient btn-block text-white f12-vw"
                  onClick={confirm}
                  disabled={loading ? true : false}
                >
                  {loading && (
                    <span
                      className="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                  )}
                  Confirm
                </button>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div
          className="d-flex flex-column px-4 py-3"
          style={{ width: "35rem" }}
        >
          <div className="col px-0">
            <div className="purple fw-600 f12-vw">
              <span className="mr-2">
                <Icon
                  icon={iconUrl.formMultiple20Filled}
                  color="orange"
                  width={25}
                />
              </span>
              Konfirmasi Check Out
            </div>
            <hr className="separator-blue my-2" />
          </div>
          <div className="col-9 px-0">
            {getDataContainer("Kode Booking", detailCheckout?.book_id || "-")}
            {getDataContainer(
              "Remaining Check Out",
              detailCheckout?.checkout_remaining || "-"
            )}
          </div>
          <div className="col px-0">
            <hr className="separator-blue mt-3 mb-2" />
            <div className="d-flex justify-content-between">
              <div className="col-6 px-0">
                <div className="d-flex flex-column">
                  <HeaderName
                    text="Informasi Booking"
                    fontSize="f10-vw fw-600"
                    color2="purple"
                  />
                  <>
                    {getDataContainer(
                      "Nama Pemesan",
                      detailCheckout?.booked_by || "-"
                    )}
                    {getDataContainer(
                      "Email",
                      detailCheckout?.email
                        ? showMoreGuest(detailCheckout?.email)
                        : "-"
                    )}
                    {getDataContainer(
                      "Nomor Ponsel",
                      detailCheckout?.phone
                        ? parsePhone(detailCheckout?.phone)
                        : "-"
                    )}
                    {getDataContainer(
                      "Check In",
                      detailCheckout?.checkin_date
                        ? getServerTimeWithoutDays(
                            detailCheckout?.checkin_date,
                            timezone
                          )
                        : "-"
                    )}
                    {getDataContainer(
                      "Check Out",
                      detailCheckout?.checkout_date
                        ? getServerTimeWithoutDays(
                            detailCheckout?.checkout_date,
                            timezone
                          )
                        : "-"
                    )}
                    {getDataContainer(
                      "Durasi",
                      detailCheckout?.checkout_date &&
                        detailCheckout?.checkin_date
                        ? getDayDuration(
                            detailCheckout?.checkout_date,
                            detailCheckout?.checkin_date
                          ) + " Malam"
                        : "-"
                    )}
                    {getDataContainer(
                      "Tipe Kamar",
                      detailCheckout?.room_type_name || "-"
                    )}
                  </>
                </div>
              </div>
              <div className="col-6 px-0">
                <div className="d-flex flex-column">
                  <HeaderName
                    text="Informasi Check In"
                    fontSize="f10-vw fw-600"
                    color2="purple"
                  />

                  <>
                    {getDataContainer(
                      "Nomor Kamar",
                      detailCheckout?.room_number || "-"
                    )}
                    {getDataContainer(
                      "Nama Tamu",
                      detailCheckout?.guest_name
                        ? showMoreGuest(splitText(detailCheckout?.guest_name))
                        : "-"
                    )}
                  </>
                </div>
                <div className="d-flex flex-column mt-2">
                  <HeaderName
                    text="Informasi Tagihan"
                    fontSize="f10-vw fw-600"
                    color2="purple"
                  />
                  <div className="mt-2">
                    <div className="row no-gutters">
                      <div className="col-5">
                        <div className="f8-vw text-black">Late Checkout</div>
                      </div>
                      <div className="col f8-vw text-black font-weight-bold">
                        {priceContainer(lateCheckout)}
                      </div>
                    </div>
                    <div className="row no-gutters mt-1">
                      <div className="col-5">
                        <div className="f8-vw text-black">Ganti Rugi</div>
                      </div>
                      <div className="col f8-vw text-black font-weight-bold">
                        {priceContainer(compensation)}
                      </div>
                    </div>
                    <div className="row no-gutters mt-1">
                      <div className="col-5">
                        <div className="f8-vw text-black">Denda</div>
                      </div>
                      <div className="col f8-vw text-black font-weight-bold">
                        {priceContainer(penalty)}
                      </div>
                    </div>

                    <hr className="separator-blue my-2" />
                    <div className="detail-information d-flex flex-row">
                      <div className="col-5 px-0">
                        <div className="f8-vw font-weight-bold">
                          Total Tagihan
                        </div>
                      </div>
                      <div className="col px-0 f8-vw font-weight-bold">
                        {priceContainer(totalPaid)}
                      </div>
                    </div>

                    <div className="detail-information d-flex flex-row mt-2">
                      <div className="col-5 px-0">
                        <div className="f8-vw font-weight-bold">Deposit</div>
                      </div>
                      <div className="col px-0 f8-vw font-weight-bold">
                        {priceContainer(detailCheckout?.deposit)}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col px-0">
            <hr className="separator-blue my-3" />
            <div className="text-center mb-3">
              <small className="f8-vw">
                Periksa kembali detail informasi, klik confirm untuk melanjutkan
              </small>
            </div>
          </div>
          <div className="col px-0">
            <div className="d-flex justify-content-between">
              <div className="col-4 px-0">
                <button
                  className="btn btn-block btn-outline-purple f8-vw"
                  onClick={close}
                >
                  Close
                </button>
              </div>
              <div className="col-4 px-0">
                <button
                  className="btn btn-gradient btn-block text-white f8-vw"
                  onClick={confirm}
                  disabled={loading ? true : false}
                >
                  {loading && (
                    <span
                      className="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                  )}
                  Confirm
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
