import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

export const useCheckedLoggedIn = () => {
  const [show, setShow] = useState(false);
  const location = useLocation();
  useEffect(() => {
    if (location.pathname === "/login") {
      setShow(true);
    }
    return () => {
      setShow(false);
    };
  }, [location.pathname]);
  return show;
};
