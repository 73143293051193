import { Icon } from "@iconify/react";
import React, { useRef } from "react";
import { useContext } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useCallback } from "react";
import { useCountdownTimer } from "use-countdown-timer";
import { BreadcrumbContext } from "../../common/BreadcrumbContext";
import { t } from "../../common/ErrorMessage";
import Facilities from "../../common/Facilities";
import HotelRules from "../../common/HotelRules";
import NotifMessage, { ButtonClose } from "../../common/Notif";
import { OptionHotel } from "../../common/OptionHotel";
import PopupModal from "../../common/PopupModal";
import { iconUrl } from "../../config";
import {
  counterFormat,
  filterStringValue,
  getClientTimeWithoutSecond,
  getEndHourShift,
  getTimezoneOffsetClient,
  isEmpty,
  isLogout,
  review,
  showMore,
  timerString,
} from "../../utils";
import { addData, getData, getErr, safeErrCode } from "../../utils/api-request";
import history from "../../utils/history";
import { is_sub_menu_action } from "../../utils/uac";

export const MobileHeader = ({ setLoading }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [errorSwitch, setErrorSwitch] = useState("");
  const [timeRemaining, setTimeRemaining] = useState(0);
  const { userProfile, shiftInfo, token } = useContext(BreadcrumbContext);
  const { countdown, start, reset, isRunning } = useCountdownTimer({
    timer: timeRemaining * 1000,
  });
  const [showDetail, setShowDetail] = useState(false);
  const [errorShift, setErrorShift] = useState("");
  const [startCounter, setStartCounter] = useState(false);
  const [hotelDetail, sethotelDetail] = useState({});
  const [hotelList, sethotelList] = useState([]);
  const [hotelSelected, setHotelSelected] = useState([]);
  const isOpeningShift = JSON.parse(localStorage.getItem("shift_open"));
  const openDetail = useRef("collapse");

  const timezone = localStorage?.getItem("timezone");

  const getShiftName = useCallback(
    (init) => {
      if (!isOpeningShift) {
        getData("/api/v1/shift/current")
          .then((resp) => {
            if (resp) {
              if (init) {
                let opening_shift = {
                  end_hour: resp.opening_shift.end_hour,
                };
                setTimeRemaining(getEndHourShift(opening_shift?.end_hour));
                setStartCounter(true);
              }
            } else {
              resp.json().then((err) => {
                setErrorShift(t(safeErrCode(err)));
              });
            }
          })
          .catch((error) => {
            let err = getErr(error);
            setErrorShift(t(err));
          });
      }
    },
    [isOpeningShift]
  );

  const getTimeLeft = useCallback(() => {
    if (!isOpeningShift && token) {
      getData("/api/v1/getshifttimeleft")
        .then((resp) => {
          if (resp?.over === false) {
            let d = resp.data.split(":");
            let dx = timerString(d[0], d[1], d[2]);
            setTimeRemaining(parseInt(dx));
          } else if (resp.over === true) {
            getShiftName(true);
          } else {
            console.log(resp);
          }
          setStartCounter(resp.over);
        })
        .catch((error) => {
          console.error(error);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpeningShift, token]);

  const submitSwitchHotel = (id) => {
    setLoading(true);
    let url = `/api/v1/switch_hotel`;
    let payload = {
      hotel: id?.value,
    };
    addData(url, payload)
      .then((resp) => {
        setLoading(false);
        if (resp.message === "Login Successful") {
          localStorage.setItem("com_acl_2", JSON.stringify(resp.com_acl));
          if (resp.message === "Login Successful") {
            let auth = JSON.stringify({
              user_id: resp.user_id,
              user_role: resp.user_role,
            });
            let hotels_info = JSON.stringify({
              hotel_id: resp.hotel_info.hotel_id,
              hotel_name: resp.hotel_info.hotel_name,
              main_picture: resp.hotel_info.main_picture,
              area_loc: resp.hotel_info.area,
              stars: resp.hotel_info.stars,
              review: resp.hotel_info.review,
              road_address: resp.hotel_info.road_address,
              guest_max: resp.hotel_info.guest_max,
              refund: resp.hotel_info.refund,
              refund_charge: resp.hotel_info.refund_charge,
              check_in_time: resp.hotel_info.check_in_timez,
              check_out_time: resp.hotel_info.check_out_timez,
            });
            let hotel_facilities = JSON.stringify({
              parking: resp.hotel_info.parking,
              public_wifi: resp.hotel_info.public_wifi,
              a24h_checkin: resp.hotel_info["24h_checkin"],
              airport_transfer: resp.hotel_info.airport_transfer,
              ac: resp.hotel_info.ac,
              child_facility: resp.hotel_info.child_facility,
              gym: resp.hotel_info.gym,
              kitchen: resp.hotel_info.kitchen,
              laundry: resp.hotel_info.laundry,
              meeting_room: resp.hotel_info.meeting_room,
              receptionist: resp.hotel_info.receptionist,
              restaurant: resp.hotel_info.restaurant,
              smoking_area: resp.hotel_info.smoking_area,
              spa: resp.hotel_info.spa,
              swimmingpool: resp.hotel_info.swimmingpool,
              wheelchair: resp.hotel_info.wheelchair_access,
            });
            localStorage.setItem(
              "sub_menu_action_2",
              JSON.stringify(resp.acl_list)
            );
            localStorage.setItem("timezone", resp.hotel_info.timezone);
            localStorage.setItem("shift_open", resp.shift_open);
            localStorage.setItem("expired", resp.expired);
            localStorage.setItem("user_info", auth);
            localStorage.setItem("info_hotel", hotels_info);
            localStorage.setItem("hotel_facilities", hotel_facilities);
            sessionStorage.setItem("token", resp.token);
          }
          setHotelSelected(id);
          getShiftName();
          setTimeout(() => {
            goToPage(resp?.token);
          }, 150);
        } else {
          resp.json().then((err) => {
            setErrorSwitch(t(safeErrCode(err)));
          });
          setIsOpen(true);
        }
      })
      .catch((error) => {
        setLoading(false);
        setErrorSwitch(t(getErr(error)));
        console.error(error);
        setIsOpen(true);
      });
  };
  const goToPage = (token) => {
    if (token === sessionStorage?.getItem("token")) {
      history.push("/reservasi/room-stock");
    }
  };
  const handleSwitchHotel = (val) => {
    if (val.value !== hotelSelected.value) {
      submitSwitchHotel(val);
    }
  };
  const getHotelList = useCallback(() => {
    if (
      userProfile?.level_id === 3 &&
      is_sub_menu_action("mg_change_hotel_process")
    ) {
      getData("/api/v1/hotel_member")
        .then((resp) => {
          if (resp.data) {
            let d = resp.data.map((item) => {
              return {
                value: item.hotel_id,
                label: item.hotel_name,
              };
            });
            sethotelList(d);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [userProfile?.level_id]);

  useEffect(() => {
    getHotelList();
  }, [getHotelList]);

  useEffect(() => {
    if (hotelDetail?.hotel_id) {
      setHotelSelected(filterStringValue(hotelList, hotelDetail?.hotel_id));
    }
  }, [hotelDetail, hotelList]);

  useEffect(() => {
    if (localStorage.getItem("info_hotel")) {
      const hotelInfo =
        localStorage?.getItem("info_hotel") &&
        JSON.parse(localStorage.getItem("info_hotel"));
      sethotelDetail(hotelInfo);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localStorage.getItem("info_hotel")]);

  useEffect(() => {
    if (timeRemaining > 0 && !isOpeningShift && !startCounter) {
      reset();
      start();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timeRemaining, isOpeningShift, startCounter]);

  useEffect(() => {
    let d;
    if (!isOpeningShift && startCounter) {
      d = setInterval(() => {
        setTimeRemaining((count) => count + 1);
      }, 1000);
    }
    return () => {
      clearInterval(d);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpeningShift, startCounter, isRunning]);

  useEffect(() => {
    getTimeLeft();
  }, [getTimeLeft]);

  return (
    <div className="mobile-header" style={{ marginTop: "-90px" }}>
      <div
        className="d-flex flex-row align-items-center"
        style={{ paddingTop: 10 }}
      >
        <div className="col-8" style={{ borderRight: "1px solid #e9e9e9" }}>
          <div className="hotel-details">
            {userProfile?.level_id === 3 &&
            is_sub_menu_action("mg_change_hotel_process") ? (
              <div className="hotel-name d-flex">
                <OptionHotel
                  optionsValue={hotelList}
                  onChange={handleSwitchHotel}
                  fontSize={"calc(9pt + 0.548vw)"}
                  value={hotelSelected}
                  height={20}
                  fontSelect={"calc(8pt + 0.472vw)"}
                />
              </div>
            ) : (
              <div className="hotel-name">{hotelDetail?.hotel_name}</div>
            )}
            <div className="hotel-location">{hotelDetail.area_loc}</div>
            <div className="rating mt-1">
              <span className="rate-number mr-2">
                {parseFloat(hotelDetail?.review).toFixed(1)}
              </span>
              <span className="rate-word">{review(hotelDetail?.review)}</span>
            </div>
            <div className="feature flex-wrap mt-1">
              <Facilities />
            </div>
          </div>
        </div>
        <div className="col px-2">
          {!isEmpty(hotelDetail) && !!timezone && (
            <div className="d-flex flex-column">
              <div className="col px-0">
                <HotelRules
                  iconName={iconUrl.clockIcon}
                  title="Check In"
                  info={`${getClientTimeWithoutSecond(
                    hotelDetail?.check_in_time,
                    timezone
                  )} ${getTimezoneOffsetClient(timezone)}`}
                  iconColor={"#51b7ab"}
                  fontColor="dark-blue"
                  iconSize={15}
                />
              </div>
              <div className="col px-0 mt-2">
                <HotelRules
                  iconName={iconUrl.clockCircleFilled}
                  title="Check Out"
                  info={`${getClientTimeWithoutSecond(
                    hotelDetail?.check_out_time,
                    timezone
                  )} ${getTimezoneOffsetClient(timezone)}`}
                  iconColor={"#51b7ab"}
                  fontColor="dark-blue"
                  iconSize={15}
                />
              </div>
            </div>
          )}
        </div>
      </div>

      <div className="mt-1">
        <div className="collapse" id="collapseHeader" ref={openDetail}>
          <div className="d-flex flex-column justify-content-between">
            <div className="col">
              <hr className="separator-grey my-2" />
              <span>
                <Icon icon={iconUrl.dotMark} width={13} color={"#0071a4"} />
              </span>
              <span className="dark-blue f12-vw fw-800">Closing Shift : </span>
              <span className="f12-vw fw-700">
                {isOpeningShift
                  ? "-"
                  : isRunning && !startCounter
                  ? counterFormat(countdown / 1000)
                  : counterFormat(timeRemaining)}
              </span>
            </div>
            <div className="col">
              <span>
                <Icon icon={iconUrl.dotMark} width={13} color={"#0071a4"} />
              </span>
              <span className="dark-blue f12-vw fw-800">Active Shift : </span>
              <span className="f12-vw fw-700">
                {!isOpeningShift &&
                errorShift === "Kategori shift tidak ditemukan" ? (
                  "-"
                ) : !isOpeningShift ? (
                  <>
                    <span>{shiftInfo?.open_shift_name}</span> -{" "}
                    <span className="dark-blue">
                      {showMore(shiftInfo.full_name, 5)}
                    </span>
                  </>
                ) : (
                  "No Active Shift"
                )}
              </span>
            </div>
          </div>
        </div>
        <div className="d-flex">
          <button
            className="btn btn-transparent btn-block btn-sm f8-vw p-0 mr-2 text-right mb-2"
            type="button"
            data-toggle="collapse"
            data-target="#collapseHeader"
            aria-expanded="false"
            aria-controls="collapseHeader"
            onClick={() => setShowDetail((showDetail) => !showDetail)}
            aria-label="button collapse"
          >
            <Icon
              icon={showDetail ? iconUrl.fastArrowTop : iconUrl.fastArrowBottom}
              width={13}
              color={"#0071a4"}
            />
          </button>
        </div>
      </div>

      <PopupModal modalIsOpen={isOpen} width="300px" mode={false} top={"65%"}>
        <>
          <NotifMessage
            closeModal={isOpen}
            img={"/img/error-icon/check-out-gagal.png"}
            color="red"
            messageBody={errorSwitch}
            messageHeader={"Gagal Switch Hotel"}
          />
          <ButtonClose
            close={() => {
              setIsOpen(false);
              setErrorSwitch("");
              isLogout();
            }}
            text="OK"
            color="bg-green"
          />
        </>
      </PopupModal>
    </div>
  );
};
