import { iconUrl } from "../../config";

// Primary menu
export const reservasiMenu = [
  {
    separator: false,
    linkName: "/reservasi/booking",
    menuName: "Booking",
    iconUrl: iconUrl.dotMark,
    cName: "nav-item ",
    acl: "rv_booking_show",
  },
  {
    separator: false,
    linkName: "/reservasi/pending-booking",
    menuName: "Pending Booking",
    iconUrl: iconUrl.dotMark,
    cName: "nav-item ",
    acl: "rv_room_pb_show",
  },
  {
    separator: false,
    linkName: "/reservasi/checkin-process",
    menuName: "Proses Check In",
    iconUrl: iconUrl.dotMark,
    cName: "nav-item ",
    acl: "rv_check_in_show",
  },
  {
    separator: false,
    linkName: "/reservasi/payment-process",
    menuName: "Proses Pembayaran",
    iconUrl: iconUrl.dotMark,
    cName: "nav-item ",
    acl: "rv_process_payment_show",
  },
  {
    separator: false,
    linkName: "/reservasi/payment-confirm",
    menuName: "Konfirmasi Pembayaran",
    iconUrl: iconUrl.dotMark,
    cName: "nav-item ",
    acl: "rv_payment_confirm_show",
  },
  {
    separator: false,
    linkName: "/reservasi/room-stock",
    menuName: "Room Stock",
    iconUrl: iconUrl.dotMark,
    cName: "nav-item ",
    acl: "rv_room_st_show",
  },
  {
    separator: false,
    linkName: "/reservasi/rooming-list",
    menuName: "Rooming List",
    iconUrl: iconUrl.dotMark,
    cName: "nav-item ",
    acl: "rv_roominglist_show",
  },
];

export const checkoutMenu = [
  {
    separator: false,
    linkName: "/checkout/checkout-process",
    menuName: "Proses Check Out",
    iconUrl: iconUrl.dotMark,
    cName: "nav-item ",
    acl: "ch_process_check_out_show",
  },
  {
    separator: false,
    linkName: "/checkout/payment-process",
    menuName: "Proses Pembayaran",
    iconUrl: iconUrl.dotMark,
    cName: "nav-item ",
    acl: "ch_process_payment_show",
  },
  {
    separator: false,
    linkName: "/checkout/payment-confirm",
    menuName: "Konfirmasi Pembayaran",
    iconUrl: iconUrl.dotMark,
    cName: "nav-item ",
    acl: "ch_payment_confirm_show",
  },
];

export const houseKeepingMenu = [
  {
    separator: false,
    linkName: "/housekeeping/room-action",
    menuName: "Room Action",
    iconUrl: iconUrl.dotMark,
    cName: "nav-item ",
    acl: "hk_room_act_show",
  },
  {
    separator: false,
    linkName: "/housekeeping/room-inspection",
    menuName: "Room Inspection",
    iconUrl: iconUrl.dotMark,
    cName: "nav-item ",
    acl: "hk_inspection_show",
  },
  {
    separator: false,
    linkName: "/housekeeping/room-management",
    menuName: "Room Management",
    iconUrl: iconUrl.dotMark,
    cName: "nav-item ",
    acl: "hk_room_list_show",
  },
];

export const masterDataMenu = [
  {
    separator: false,
    linkName: "/master-data/room-allocation",
    menuName: "Room Allocation",
    iconUrl: iconUrl.dotMark,
    cName: "nav-item ",
    acl: "mt_room_mgmt_show",
  },
  {
    separator: false,
    linkName: "/master-data/bed-type",
    menuName: "Bed Type",
    iconUrl: iconUrl.dotMark,
    cName: "nav-item ",
    acl: "mt_bed_type_show",
  },
  {
    separator: false,
    linkName: "/master-data/loss-and-breakage",
    menuName: "Loss and Breakage",
    iconUrl: iconUrl.dotMark,
    cName: "nav-item ",
    acl: "mt_loss_show",
  },
  {
    separator: false,
    linkName: "/master-data/shift-management",
    menuName: "Shift Management",
    iconUrl: iconUrl.dotMark,
    cName: "nav-item ",
    acl: "mt_shift_mgmt_show",
  },
];

export const reportDataMenu = [
  {
    separator: false,
    linkName: "/report/guest-checkin",
    menuName: "Guest Activity",
    iconUrl: iconUrl.dotMark,
    cName: "nav-item ",
    acl: "rp_guest_activity_show",
  },
  {
    separator: false,
    linkName: "/report/shift-report",
    menuName: "Shift",
    iconUrl: iconUrl.dotMark,
    cName: "nav-item ",
    acl: "rp_the_shift_show",
  },
  {
    separator: false,
    linkName: "/report/housekeeping-report",
    menuName: "Housekeeping",
    iconUrl: iconUrl.dotMark,
    cName: "nav-item ",
    acl: "rp_house_keeping_show",
  },
  {
    separator: false,
    linkName: "/report/history-setoran",
    menuName: "Setoran Cash",
    iconUrl: iconUrl.dotMark,
    cName: "nav-item ",
    acl: "rp_setoran_cash_show",
  },
  {
    separator: false,
    linkName: "/report/breakage-report",
    menuName: "Breakage Report",
    iconUrl: iconUrl.dotMark,
    cName: "nav-item ",
    acl: "rp_breakage_report_show",
  },
  {
    separator: false,
    linkName: "/report/booking-forecast",
    menuName: "Booking Forecast",
    iconUrl: iconUrl.dotMark,
    cName: "nav-item ",
    acl: "rp_booking_forecast_show",
  },
  // {
  //   separator: false,
  //   linkName: "/report/rooming-list",
  //   menuName: "Rooming List",
  //   iconUrl: iconUrl.dotMark,
  //   cName: "nav-item ",
  //   acl: "rp_rooming_list_show",
  // },
];

export const weeklyTransfer = [
  {
    separator: false,
    linkName: "/shift/proses-setoran",
    menuName: "Proses Setoran",
    iconUrl: iconUrl.dotMark,
    cName: "nav-item ",
    acl: "sf_setoran_process_setoran_show",
  },
  {
    separator: false,
    linkName: "/shift/konfirmasi-setoran",
    menuName: "Konfirmasi Setoran",
    iconUrl: iconUrl.dotMark,
    cName: "nav-item ",
    acl: "sf_setoran_confirm_setoran_show",
  },
  {
    separator: false,
    linkName: "/shift/approve-setoran",
    menuName: "Approve Setoran",
    iconUrl: iconUrl.dotMark,
    cName: "nav-item ",
    acl: "sf_setoran_approve_setoran_show",
  },
];

export const lossFoundMenu = [
  {
    separator: false,
    linkName: "/loss-n-found/loss-found-form",
    menuName: "Lost & Found Form",
    iconUrl: iconUrl.dotMark,
    cName: "nav-item ",
    acl: "lf_lost_found_show",
  },
  {
    separator: false,
    linkName: "/loss-n-found/loss-found-list",
    menuName: "Lost & Found List",
    iconUrl: iconUrl.dotMark,
    cName: "nav-item ",
    acl: "lf_lost_found_show",
  },
];

export const rePrintCheckinMenu = [
  {
    separator: false,
    linkName: "/reservasi/re-print-billing",
    menuName: "Billing",
    iconUrl: iconUrl.dotMark,
    cName: "nav-item ",
    acl: "rv_checkin_billing_show",
  },
  {
    separator: false,
    linkName: "/reservasi/re-print-payment",
    menuName: "Payment Slip",
    iconUrl: iconUrl.dotMark,
    cName: "nav-item ",
    acl: "rv_checkin_receipt_show",
  },
];

export const rePrintCheckoutMenu = [
  {
    separator: false,
    linkName: "/checkout/re-print-billing",
    menuName: "Billing",
    iconUrl: iconUrl.dotMark,
    cName: "nav-item ",
    acl: "ch_checkout_billing_show",
  },
  {
    separator: false,
    linkName: "/checkout/re-print-payment",
    menuName: "Payment Slip",
    iconUrl: iconUrl.dotMark,
    cName: "nav-item ",
    acl: "ch_checkout_receipt_show",
  },
];
