import React from "react";
import Modal from "react-modal";

function PopupModal({
  mode = false,
  modalIsOpen = false,
  reqClose,
  color = "white",
  children,
}) {
  const customStyles = {
    content: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      inset: 20,
      borderRadius: 5,
      backgroundSize: "cover",
      border: 0,
      borderWidth: 0,
      padding: 0,
      overflow: "hidden",
      backgroundColor: "transparent",
    },
    overlay: {
      backgroundColor: "rgba(0 , 0, 0, 0.75)",
      zIndex: 10,
    },
  };
  return (
    <>
      <Modal
        isOpen={modalIsOpen}
        onAfterClose={reqClose}
        style={customStyles}
        contentLabel="PopUp"
        ariaHideApp={false}
      >
        <div
          className="popup py-1"
          style={{
            backgroundColor: color,
            borderRadius: 5,
          }}
        >
          {children}
        </div>
      </Modal>
    </>
  );
}

export default PopupModal;
