import {
  getServerTimeWithoutDays,
  getClientTimeWithoutSecond,
  getTimezoneOffsetClient,
} from ".";
import { routerComponent } from "../common/routerPage";
import { config } from "../config";

export const nav_mobile_bg = (value) => {
  const d = [
    { value: "sub_menu", color: "#64BBB1" },
    { value: "content", color: "#A87CD5" },
  ];
  return d.filter((vx) => vx.value === value);
};

export const get_hk_status = (hk_status) => {
  let d = {};
  if (hk_status === 0 || hk_status === 3) {
    d.hk_flag = "hk_start";
    d.hk_label = "Start Cleaning";
    d.hk_className = "btn-action-hk-secondary";
  } else if (hk_status === 1) {
    d.hk_flag = "hk_end";
    d.hk_label = "End Cleaning";
    d.hk_className = "btn-action-hk-primary";
  }
  return d;
};

export const dateCompilation = (data_time) => {
  const timezone =
    localStorage?.getItem("timezone") && localStorage?.getItem("timezone");
  if (data_time) {
    return (
      getServerTimeWithoutDays(data_time, timezone) +
      ", " +
      getClientTimeWithoutSecond(data_time, timezone) +
      " " +
      getTimezoneOffsetClient(timezone)
    );
  } else {
    return "-";
  }
};

export const safeNull = (resp) => {
  return resp ? resp : "-";
};

export const getUserInfo = () => {
  let d = {
    user_id: "",
    user_role: "",
  };
  let userInfo = localStorage.getItem("user_info");
  if (userInfo) {
    d = JSON.parse(userInfo);
  }
  return d;
};

export const getRoomTypeLocal = () => {
  let dataParsed = [
    {
      value: "",
      label: "",
    },
  ];
  let data = localStorage.getItem("room_type");
  dataParsed = JSON.parse(data);
  return dataParsed;
};

export async function getFileFromUrl(url, name, defaultType = "image/jpeg") {
  const response = await fetch(url);
  const data = await response.blob();
  return new File([data], name, {
    type: data.type || defaultType,
  });
}

export const genImgHost = (url) => {
  let urlFile = "";
  if (url?.length > 0) {
    urlFile = config.img_host + url;
  }

  return urlFile;
};

export const safeNullSend = (data) => {
  return data ? data : "";
};

export const list_default_routes = {
  rv: "/reservasi/booking",
  ch: "/checkout/checkout-process",
  sf: "/shift/proses-setoran",
  hk: "/housekeeping/room-action",
  mt: "/master-data/room-allocation",
  rp: "/report/guest-checkin",
  lf: "/loss-n-found/loss-found-form",
};
// export const list_default_routes = {
//   rv_booking: "/reservasi/booking",
//   rv_check_in: "/reservasi/checkin-process",
//   rv_process_payment: "/reservasi/payment-process",
//   rv_payment_confirm: "/reservasi/payment-confirm",
//   rv_room_st: "/reservasi/room-stock",
//   ch_process_check_out: "/checkout/checkout-process",
//   ch_process_payment: "/checkout/payment-process",
//   ch_payment_confirm: "/checkout/payment-confirm",
//   ch_payment_confirm: "/checkout/payment-confirm",
//   sf: "/shift/proses-setoran",
//   hk: "/housekeeping/room-action",
//   mt: "/master-data/room-allocation",
//   rp: "/report/guest-checkin",
//   lf: "/loss-n-found/loss-found-form",
// };

export const priorityPage = (arr_data) => {
  let data = localStorage.getItem("sub_menu_action");
  let arr_original = data.split(",");

  let x = [];
  arr_original.map((value) => {
    let d = routerComponent.filter((v, i) => v.acl === value);
    return x.push(d[0]);
  });
  let z = x.sort((a, b) => a.execution_type - b.execution_type);
  return z[0];
};

export const isCurrentPath = (url_current, prefix_group) => {
  let url_part = url_current.split("/");
  return url_part[1] === prefix_group ? "show" : "";
};

export const isShowCollapse = (url_target) => {
  //url current is based on active on the addressbar
  //url target is based on target want to detect show bar
  let url_current = window.location.pathname;
  return url_current === url_target ? "show" : "";
};

export const dataURLtoBlob = (dataURI) => {
  // convert base64/URLEncoded data component to raw binary data held in a string
  var byteString;
  if (dataURI.split(",")[0].indexOf("base64") >= 0)
    byteString = atob(dataURI.split(",")[1]);
  else byteString = unescape(dataURI.split(",")[1]);

  // separate out the mime component
  var mimeString = dataURI
    .split(",")[0]
    .split(":")[1]
    .split(";")[0];

  // write the bytes of the string to a typed array
  var ia = new Uint8Array(byteString.length);
  for (var i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }

  return new Blob([ia], { type: mimeString });
};

export const convertTimeDuration = (hour_resp) => {
  let x = parseFloat(hour_resp);
  if (x > 0) {
    let hours = Math.round(Math.floor(x / 1));
    let minutes = Math.round((x - hours) * 60);
    if (minutes < 1) {
      return hours + " Hours";
    } else {
      return hours + " Hours, " + minutes + " Minutes";
    }
  } else {
    return "-";
  }
};
