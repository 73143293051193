export const IS_MOBILE = window.matchMedia("(max-width: 1024px)").matches
  ? true
  : false;

export const IS_MEDIUM = window.matchMedia("(max-width: 1024px)").matches
  ? true
  : false;

export const IS_LARGE = window.matchMedia("(max-width: 1280px)").matches
  ? true
  : false;
