import { HeaderName } from "../../../common/HeaderName";
import { IS_MOBILE } from "../../../common/Responsive";
import { priceSplit } from "../../../utils";

const FONT_SIZE_HEADER = IS_MOBILE ? "16-vw" : "f12-vw";
const FONT_SIZE = IS_MOBILE ? "f12-vw" : "f8-vw";
const FONT_SIZE_TITLE = IS_MOBILE ? "14-vw" : "f10-vw";

export const PaymentProcessSuccess = ({
  detail,
  paymentSelected,
  bankSelected,
  checkedDepo,
  totalPaid,
  payment,
  charge,
  close,
}) => {
  const detailInformation = (title, value) => {
    return (
      <div className={`d-flex flex-row mt-1 ${FONT_SIZE}`}>
        <div className="col px-0">
          <span className="grey">{title}</span>
        </div>
        <div className="col px-0 fw-600">: {value}</div>
      </div>
    );
  };

  const detailPayment = (title, value, color = "") => {
    return (
      <div className={`d-flex flex-row mt-1 ${color} ${FONT_SIZE}`}>
        <div className="col px-0">
          <span className="text-black">{title}</span>
        </div>
        <div className="col px-0">
          <div className="d-flex justify-content-between">
            <div className="">Rp</div>
            <div className="">
              <div className="text-right ">{priceSplit(value)}</div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div
      className="d-flex flex-column p-2"
      style={{ width: IS_MOBILE ? "21rem" : "23rem" }}
    >
      <div className="col">
        <div className="d-flex justify-content-center">
          <img
            src="/img/success-payment/success-icon.svg"
            alt=""
            loading="lazy"
          />
        </div>
      </div>
      <div className="col">
        {paymentSelected?.value === 2 ? (
          <div className={"fw-700 text-center " + FONT_SIZE_HEADER}>
            Pemesanan Berhasil
          </div>
        ) : (
          <div className={"fw-700 text-center " + FONT_SIZE_HEADER}>
            Pembayaran Berhasil
          </div>
        )}

        {paymentSelected?.value === 2 && (
          <>
            <div className={"text-center " + FONT_SIZE}>
              Lakukan Konfirmasi setelah melakukan pembayaran
            </div>
            <div className={"text-center " + FONT_SIZE}>
              pada menu "Konfirmasi Pembayaran"
            </div>
          </>
        )}

        <hr className="separator-blue" />
      </div>
      <div className="col">
        <div className="d-flex flex-column">
          <div className="col pl-0">
            <div className="">
              <HeaderName
                text="Pembayaran"
                fontSize={"fw-700 " + FONT_SIZE_TITLE}
              />
            </div>
            {detailInformation("Metode Pembayaran", paymentSelected?.label)}
            {paymentSelected?.value === 2 && (
              <>
                {detailInformation("Nama Bank", bankSelected?.bank_name)}
                {detailInformation("Nomor Rekening", bankSelected?.bank_accno)}
              </>
            )}
          </div>
          <div className="col px-0">
            <div className="mt-2">
              <HeaderName
                text="Informasi Tagihan"
                fontSize={"fw-700 " + FONT_SIZE_TITLE}
                color2="purple"
              />
              <div className="">
                {detail?.process_type === 2 || detail?.process_type === 13 ? (
                  <>
                    {detailPayment(
                      "Total Harga Kamar",
                      detail?.room_charge || 0
                    )}
                    {detail?.process_type === 2 &&
                      detailPayment(
                        "Late Check Out",
                        detail?.late_checkout || 0
                      )}
                    {detail?.process_type === 2 &&
                      detailPayment("Ganti Rugi", detail?.ganti_rugi || 0)}
                    {detail?.process_type === 2 &&
                      detailPayment("Denda", detail?.denda || 0)}
                    {detail?.process_type === 2 && (
                      <hr className="separator-blue my-2" />
                    )}
                    {detail?.process_type === 2 &&
                      detailPayment("Total", detail?.total_charge || 0)}
                    {detail?.process_type === 2 &&
                      detailPayment(
                        "Deposit",
                        checkedDepo ? detail?.deposit || 0 : 0
                      )}
                    <hr className="separator-blue my-2" />
                    {detailPayment("Total Tagihan", totalPaid || 0, "fw-700")}
                    {checkedDepo &&
                      detail?.deposit >= detail?.total_charge &&
                      detailPayment(
                        "Pengembalian Deposit",
                        parseInt(detail?.deposit - detail?.total_charge) || 0
                      )}
                    {paymentSelected.value === 3 && (
                      <>
                        <hr className="separator-blue my-2" />
                        <div className="mt-2">
                          {detailPayment(
                            "Pembayaran Tunai",
                            payment || 0,
                            "fw-700"
                          )}
                          {detailPayment("Kembalian", charge || 0, "fw-700")}
                        </div>
                      </>
                    )}
                  </>
                ) : (
                  <>
                    {detailPayment(
                      "Additional Charge",
                      totalPaid || 0,
                      "fw-700"
                    )}
                    {paymentSelected.value === 3 && (
                      <>
                        <hr className="separator-blue my-2" />
                        <div className="mt-2">
                          {detailPayment(
                            "Pembayaran Tunai",
                            payment || 0,
                            "fw-700"
                          )}
                          {detailPayment("Kembalian", charge || 0, "fw-700")}
                        </div>
                      </>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
        <hr className="separator-blue" />
      </div>

      <div className="d-flex justify-content-center mt-2">
        <div className="col-6">
          <button
            className={
              "btn bg-green btn-block font-weight-bold text-white " + FONT_SIZE
            }
            onClick={close}
          >
            OK
          </button>
        </div>
      </div>
    </div>
  );
};
