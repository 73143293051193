import React from "react";
import { img_fallback } from "../../utils/ui";
import { getServerTimeWithoutDays, parsePhone } from "../../utils/index";
import { Icon } from "@iconify/react";
import { iconUrl } from "../../config";
import { IS_MOBILE } from "../../common/Responsive";

const FONT_SIZE = IS_MOBILE ? "f12-vw" : "f8-vw";
const FONT_SIZE_HEADER = IS_MOBILE ? "f16-vw" : "f12-vw";

export const ConfirmFormLossFound = ({
  detail,
  reportType,
  reportBy,
  qty,
  location,
  fileSrc,
  loadingSubmit,
  submit,
  close,
  roomNumber,
  founderName,
}) => {
  const detailInformation = (title, value, bold = false) => {
    return (
      <div className={"d-flex flex-row mt-1 " + FONT_SIZE}>
        <div className="col-6 grey px-0">{title}</div>
        <div className={`col-6 px-0 ${bold ? "fw-700" : ""}`}>
          : {value ? value : "-"}
        </div>
      </div>
    );
  };
  return (
    <div
      className="d-flex flex-column p-3"
      style={{ width: IS_MOBILE ? "21rem" : "40rem" }}
    >
      <div className="col">
        <div className={"fw-600 " + FONT_SIZE_HEADER}>
          <span className="mr-1">
            <Icon
              icon={iconUrl.formMultiple20Filled}
              color="orange"
              width={30}
            />
          </span>
          <span className="purple">Lost & Found Confirmation</span>
        </div>
        <hr className="separator-blue" />
      </div>
      <div className="col">
        {IS_MOBILE ? (
          <div
            className="scroll-popup hide-scrollbar"
            style={{ maxHeight: "21rem" }}
          >
            {detailInformation("Tipe Laporan", reportType?.label, true)}
            {detailInformation("Dilaporkan oleh", reportBy?.label)}
            {detailInformation("Nama Pelapor", detail?.founderName)}
            {detailInformation(
              "Kontak Pelapor",
              parsePhone(detail?.phoneNumber)
            )}
            {detailInformation("Nama Barang", detail?.itemName)}
            {detailInformation("Jumlah", qty)}
            {detailInformation(
              "Tanggal Hilang /Ditemukan",
              getServerTimeWithoutDays(detail?.dateLostFound)
            )}
            {detailInformation("Lokasi Hilang /Ditemukan", location?.label)}
            {detailInformation("Diserahkan Kepada", founderName?.label)}
            {detailInformation(
              "Tanggal Check In",
              reportBy?.value === 2 || reportType?.value === 1
                ? getServerTimeWithoutDays(detail?.dateCheckin)
                : "-"
            )}
            {detailInformation(
              "Tanggal Check Out",
              reportBy?.value === 2 || reportType?.value === 1
                ? getServerTimeWithoutDays(detail?.dateCheckout)
                : "-"
            )}
            {detailInformation("Nomor Kamar", roomNumber?.label)}

            <div className="col px-0 mt-2">
              <div className={"grey mb-1 " + FONT_SIZE}>Foto Barang</div>
              <div className="d-flex justify-content-center">
                {fileSrc?.length > 0 ? (
                  <img
                    src={
                      fileSrc?.length > 0
                        ? URL.createObjectURL(fileSrc[0])
                        : img_fallback
                    }
                    alt=""
                    onError={({ currentTarget }) => {
                      currentTarget.onerror = null; // prevents looping
                      currentTarget.src = img_fallback;
                    }}
                    loading="lazy"
                    style={{
                      objectFit: "contain",
                      width: "100%",
                      height: "8rem",
                    }}
                  />
                ) : (
                  <span
                    className={
                      "d-flex justify-content-center align-items-center " +
                      FONT_SIZE
                    }
                    style={{
                      width: "100%",
                      height: "8rem",
                      border: "1px solid black",
                      borderRadius: 5,
                    }}
                  >
                    Tidak ada Photo
                  </span>
                )}
              </div>
            </div>
            <div className="col px-0 mt-2">
              <div className={"grey mt-3 mb-2 " + FONT_SIZE}>Detail Barang</div>
              <div className={FONT_SIZE}>
                {detail?.itemDetail ? detail?.itemDetail : "-"}
              </div>
            </div>
          </div>
        ) : (
          <div className={`d-flex flex-row`}>
            <div className="col-7 px-0">
              {detailInformation("Tipe Laporan", reportType?.label, true)}
              {detailInformation("Dilaporkan oleh", reportBy?.label)}
              {detailInformation("Nama Pelapor", detail?.founderName)}
              {detailInformation(
                "Kontak Pelapor",
                parsePhone(detail?.phoneNumber)
              )}
              {detailInformation("Nama Barang", detail?.itemName)}
              {detailInformation("Jumlah", qty)}
              {detailInformation(
                "Tanggal Hilang/Ditemukan",
                getServerTimeWithoutDays(detail?.dateLostFound)
              )}
              {detailInformation("Lokasi Hilang/Ditemukan", location?.label)}
              {detailInformation("Diserahkan Kepada", founderName?.label)}
              {detailInformation(
                "Tanggal Check In",
                reportBy?.value === 2 || reportType?.value === 1
                  ? getServerTimeWithoutDays(detail?.dateCheckin)
                  : "-"
              )}
              {detailInformation(
                "Tanggal Check Out",
                reportBy?.value === 2 || reportType?.value === 1
                  ? getServerTimeWithoutDays(detail?.dateCheckout)
                  : "-"
              )}
              {detailInformation("Nomor Kamar", roomNumber?.label)}
            </div>
            <div className="col px-0">
              <div className="d-flex flex-column">
                <div className="col pr-0">
                  <div className="f8-vw grey mb-1">Foto Barang</div>
                  <div className="d-flex justify-content-center">
                    {fileSrc?.length > 0 ? (
                      <img
                        src={
                          fileSrc?.length > 0
                            ? URL.createObjectURL(fileSrc[0])
                            : img_fallback
                        }
                        alt=""
                        onError={({ currentTarget }) => {
                          currentTarget.onerror = null; // prevents looping
                          currentTarget.src = img_fallback;
                        }}
                        loading="lazy"
                        style={{
                          objectFit: "contain",
                          width: "100%",
                          height: "8rem",
                        }}
                      />
                    ) : (
                      <span
                        className="d-flex justify-content-center align-items-center f8-vw"
                        style={{
                          width: "100%",
                          height: "8rem",
                          border: "1px solid black",
                          borderRadius: 5,
                        }}
                      >
                        Tidak ada Photo
                      </span>
                    )}
                  </div>
                </div>
                <div className="col pr-0">
                  <div className="f8-vw grey mt-3 mb-2">Detail Barang</div>
                  <div className="f8-vw">
                    {detail?.itemDetail ? detail?.itemDetail : "-"}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      <div className="col">
        <hr className="separator-blue " />
        <div className="text-center">
          <small className={IS_MOBILE ? "f10-vw" : "f8-vw"}>
            Periksa kembali detail Lost and Found, klik Confirm untuk
            melanjutkan
          </small>
        </div>
        <div className="d-flex justify-content-between mt-3">
          <div className={`${IS_MOBILE ? "col-5" : "col-3"} px-0`}>
            <button
              className={`btn btn-block btn-outline-purple ${FONT_SIZE}`}
              onClick={() => {
                close();
              }}
            >
              Back
            </button>
          </div>
          <div className={`${IS_MOBILE ? "col-5" : "col-3"} px-0`}>
            <button
              className={`btn btn-block text-white ${FONT_SIZE} ${
                loadingSubmit ? "btn-secondary" : "btn-gradient"
              }`}
              onClick={() => {
                submit();
              }}
              disabled={loadingSubmit}
            >
              {loadingSubmit && (
                <span className="spinner-border spinner-border-sm mr-1"></span>
              )}
              Confirm
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
