import { Icon } from "@iconify/react";
import React from "react";
import CameraWebcam from "../../../common/CameraWebcam";
import { HeaderName } from "../../../common/HeaderName";
import { MobileUploadFile } from "../../../common/MobileUploadFile";
import { IS_MOBILE } from "../../../common/Responsive";
import { iconUrl } from "../../../config";
import {
  dateFormat,
  getDayDuration,
  parsePhone,
  priceSplit,
  showMore,
  showMoreGuest,
} from "../../../utils";

export const ConfirmEditBooking = ({
  formEdit,
  setFormEdit,
  purposeSelected,
  guestTypeSelected,
  confirm,
  close,
  otherSelected,
  setfileUpload,
  fileUpload,
  roomTypeSelected,
  setShowImg,
  showImg,
  isChanging,
  loading,
}) => {
  const handleRoomCharge = (val) => {
    setFormEdit({
      ...formEdit,
      totalcharge: Number(val.target.value.replace(/\D/g, "")),
    });
  };

  const paymentInput = (title, value, onChange, autoFocus) => {
    return (
      <div className="d-flex flex-column py-2">
        <div className="col px-0">
          <span className={`text-black f8-vw font-weight-bold`}>{title}</span>
        </div>
        <div className="col px-0">
          <div className="d-flex flex-row align-items-center">
            <div
              className={`col px-0 text-black `}
              style={{
                border: "1px solid #5f0593",
                borderRadius: 5,
                backgroundColor:
                  otherSelected?.value === "RNA" ? "#e9ecef" : "white",
                display: "flex",
                alignItems: "center",
                ...(otherSelected?.value === "RNA" && {
                  pointerEvents: "none",
                }),
              }}
            >
              <span className={`px-3 f8-vw fw-700`}>Rp</span>
              <input
                type="text"
                className="form-control text-right border-0 form-control-sm f8-vw font-weight-bold"
                value={priceSplit(value)}
                style={{ textAlign: "right", boxShadow: "none" }}
                onFocus={(e) => (e.target.value = "")}
                autoFocus={autoFocus}
                onChange={onChange}
                maxLength={12}
                disabled={otherSelected?.value === "RNA"}
              />
            </div>
          </div>
        </div>
      </div>
    );
  };
  const mobilePaymentInput = (title, value, onChange, autoFocus) => {
    return (
      <div className="d-flex flex-column py-2">
        <div className="col px-0">
          <span className={`text-black f12-vw font-weight-bold`}>{title}</span>
        </div>
        <div className="col px-0">
          <div className="d-flex flex-row align-items-center">
            <div
              className={`col px-0 text-black `}
              style={{
                border: "1px solid #5f0593",
                borderRadius: 5,
                backgroundColor:
                  otherSelected?.value === "RNA" ? "#e9ecef" : "white",
                display: "flex",
                alignItems: "center",
                ...(otherSelected?.value === "RNA" && {
                  pointerEvents: "none",
                }),
              }}
            >
              <span className={`px-3 f12-vw fw-700`}>Rp</span>
              <input
                type="text"
                className="form-control text-right border-0 form-control-sm f12-vw font-weight-bold"
                value={priceSplit(value)}
                style={{ textAlign: "right", boxShadow: "none" }}
                onFocus={(e) => (e.target.value = "")}
                autoFocus={autoFocus}
                onChange={onChange}
                maxLength={12}
                inputMode="numeric"
                disabled={otherSelected?.value === "RNA"}
              />
            </div>
          </div>
        </div>
      </div>
    );
  };
  const detailInformation = (title, value) => {
    return (
      <div className="d-flex flex-row f8-vw mt-1">
        <div className="col-5 px-0">
          <span className="grey">{title}</span>
        </div>
        <div className="col px-0">
          <span className="text-black ">: {value ? value : "-"}</span>
        </div>
      </div>
    );
  };
  const mobileDetailInformation = (title, value) => {
    return (
      <div className="d-flex flex-row f12-vw mt-1">
        <div className="col-6 px-0">
          <span className="grey">{title}</span>
        </div>
        <div className="col px-0">
          <span className="text-black f12-vw">: {value ? value : "-"}</span>
        </div>
      </div>
    );
  };
  return (
    <>
      {IS_MOBILE ? (
        <div className="p-3">
          <div className="px-2">
            <div className="d-flex align-items-center">
              <span>
                <Icon
                  icon={iconUrl.formMultiple20Filled}
                  width={25}
                  color="orange"
                />
              </span>
              <span className="blue-title fw-700 f14-vw">
                Konfirmasi Pemesanan
              </span>
            </div>
            <div className="px-1">
              <hr className="separator-grey my-2" />
            </div>
          </div>
          <div
            className="d-flex flex-column hide-scrollbar"
            style={{ height: "25rem", overflowY: "scroll" }}
          >
            <div className="col">
              <div className="">
                <HeaderName
                  text="Informasi Tamu"
                  fontSize="f14-vw"
                  color2="purple"
                />
              </div>
              <div className="my-1">
                {mobileDetailInformation("Nama OTA", otherSelected?.label)}
                {mobileDetailInformation(
                  "Kode Booking",
                  showMore(formEdit?.bookingCode)
                )}
                {mobileDetailInformation("Nama Pemesan", formEdit?.guestName)}
                {mobileDetailInformation(
                  "Nomor Ponsel",
                  parsePhone(formEdit?.phoneNumber)
                )}
                {mobileDetailInformation("Email", showMore(formEdit?.email))}
              </div>
              <div className="mt-3">
                <HeaderName
                  text="Informasi Pemesanan"
                  fontSize="f14-vw"
                  color2="purple"
                />
              </div>
              <div className="my-1">
                {mobileDetailInformation(
                  "Check In",
                  dateFormat(formEdit?.checkin)
                )}
                {mobileDetailInformation(
                  "Check Out",
                  dateFormat(formEdit?.checkout)
                )}
                {mobileDetailInformation(
                  "Tanggal Booking",
                  dateFormat(formEdit?.booking_date)
                )}
                {mobileDetailInformation(
                  "Durasi",
                  getDayDuration(formEdit?.checkin, formEdit?.checkout) +
                    " Malam"
                )}
                {mobileDetailInformation("Tipe Kamar", roomTypeSelected?.label)}
                {mobileDetailInformation(
                  "Tamu & Kamar",
                  formEdit?.guest + " Tamu, " + formEdit?.room + " Kamar"
                )}
                {mobileDetailInformation("Purpose", purposeSelected?.label)}
                {mobileDetailInformation(
                  "Guest Type",
                  guestTypeSelected?.label
                )}
              </div>
            </div>
            <div className="col">
              {otherSelected?.value !== "RNA" && (
                <div className="mt-3">
                  <HeaderName
                    text="Bukti Pemesanan"
                    fontSize="f14-vw"
                    color2="purple"
                  />
                  <div className="mt-2">
                    <MobileUploadFile
                      setfileUpload={setfileUpload}
                      title={"Upload Bukti Pemesanan"}
                      height="150px"
                      fileUpload={fileUpload}
                      setShowImg={setShowImg}
                      showImg={showImg}
                      fontSize="f12-vw"
                    />
                  </div>
                </div>
              )}
              {mobilePaymentInput(
                "Total Harga Kamar",
                formEdit.totalcharge,
                (e) => handleRoomCharge(e)
              )}
            </div>
          </div>
          <div className="px-3">
            <hr className="separator-grey" />
            <div className="my-3">
              <div className="text-center f10-vw my-2">
                Periksa kembali detail Pemesanan anda, klik confirm untuk
                melanjutkan
              </div>
            </div>
          </div>
          <div className="px-3">
            <div className="d-flex justify-content-between ">
              <div className="col-6 pl-0">
                <button
                  className="btn btn-sm btn-block btn-outline-purple f12-vw"
                  onClick={() => close()}
                >
                  Back
                </button>
              </div>
              <div className="col-6 pr-0">
                <button
                  className={`btn btn-sm btn-block text-white  f12-vw ${
                    !loading &&
                    showImg !== null &&
                    formEdit?.totalcharge > 0 &&
                    isChanging()
                      ? "btn-gradient"
                      : "btn-secondary"
                  }`}
                  onClick={() => confirm()}
                  disabled={
                    !loading &&
                    showImg !== null &&
                    formEdit?.totalcharge > 0 &&
                    isChanging()
                      ? false
                      : true
                  }
                >
                  Confirm
                </button>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div
          className="d-flex flex-column f8-vw p-3"
          style={{ width: "35rem" }}
        >
          <div className="col">
            <div className="d-flex align-items-center">
              <span>
                <Icon
                  icon={iconUrl.formMultiple20Filled}
                  width={25}
                  color="orange"
                />
              </span>
              <span className="blue-title font-weight-bold f10-vw">
                Konfirmasi Pemesanan
              </span>
            </div>
            <hr className="separator-grey my-2" />
          </div>
          <div className="col">
            <div className="d-flex flex-row">
              <div className="col px-0">
                <div className="">
                  <HeaderName
                    text="Informasi Tamu"
                    fontSize="f10-vw"
                    color2="purple"
                  />
                </div>
                <div className="my-1">
                  {detailInformation("Nama OTA", otherSelected?.label)}
                  {detailInformation("Kode Booking", formEdit?.bookingCode)}
                  {detailInformation("Nama Pemesan", formEdit?.guestName)}
                  {detailInformation(
                    "Nomor Ponsel",
                    parsePhone(formEdit?.phoneNumber)
                  )}
                  {detailInformation("Email", showMoreGuest(formEdit?.email))}
                </div>
                <div className="mt-2">
                  <HeaderName
                    text="Informasi Pemesanan"
                    fontSize="f10-vw"
                    color2="purple"
                  />
                </div>
                <div className="my-1">
                  {detailInformation("Check In", dateFormat(formEdit?.checkin))}
                  {detailInformation(
                    "Check Out",
                    dateFormat(formEdit?.checkout)
                  )}
                  {detailInformation(
                    "Tanggal Booking",
                    dateFormat(formEdit?.booking_date)
                  )}
                  {detailInformation(
                    "Durasi",
                    getDayDuration(formEdit?.checkin, formEdit?.checkout) +
                      " Malam"
                  )}
                  {detailInformation("Tipe Kamar", roomTypeSelected?.label)}
                  {detailInformation(
                    "Tamu & Kamar",
                    formEdit?.guest + " Tamu, " + formEdit?.room + " Kamar"
                  )}
                  {detailInformation("Purpose", purposeSelected?.label)}
                  {detailInformation("Guest Type", guestTypeSelected?.label)}
                </div>
              </div>
              <div className="col px-0">
                {otherSelected?.value !== "RNA" && (
                  <div className="my-1">
                    <HeaderName
                      text="Bukti Pemesanan"
                      fontSize="f10-vw"
                      color2="purple"
                    />
                    <CameraWebcam
                      setfileUpload={setfileUpload}
                      title={"Upload Bukti Pemesanan"}
                      height="150px"
                      fileUpload={fileUpload}
                      setShowImg={setShowImg}
                      showImg={showImg}
                    />
                  </div>
                )}
                {paymentInput("Total Harga Kamar", formEdit.totalcharge, (e) =>
                  handleRoomCharge(e)
                )}
              </div>
            </div>
            <div className="">
              <hr className="separator-grey" />
            </div>

            <div className="my-3">
              <div className="text-center f8-vw my-2">
                Periksa kembali detail Pemesanan anda , klik confirm untuk
                melanjutkan
              </div>
            </div>
          </div>
          <div className="col">
            <div className="d-flex justify-content-between ">
              <div className="col-3 px-0">
                <button
                  className="btn btn-sm btn-block btn-outline-purple f8-vw"
                  onClick={() => close()}
                >
                  Back
                </button>
              </div>
              <div className="col-3 px-0">
                <button
                  className={`btn btn-sm btn-block text-white  f8-vw ${
                    !loading &&
                    showImg !== null &&
                    formEdit?.totalcharge > 0 &&
                    isChanging()
                      ? "btn-gradient"
                      : "btn-secondary"
                  }`}
                  onClick={() => confirm()}
                  disabled={
                    !loading &&
                    showImg !== null &&
                    formEdit?.totalcharge > 0 &&
                    isChanging()
                      ? false
                      : true
                  }
                >
                  Confirm
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
