import { Icon } from "@iconify/react";
import React, { useContext } from "react";
import { useState } from "react";
import useComponentVisible from "../../common/RefUtils";
import { iconUrl } from "../../config";
import { MobileHeader } from "../Header/MobileHeader";
import Sidebar from "./Sidebar";
import { useScroll } from "../../hook/useScroll";
import { BreadcrumbContext } from "../../common/BreadcrumbContext";

export const MobileSideBar = () => {
  const [showMenu, setShowMenu] = useState(false);
  const { ref } = useComponentVisible(setShowMenu);
  const { setLoadingOverlay, openButtonSheet } = useContext(BreadcrumbContext);
  const scrollPosition = useScroll();

  return (
    <>
      <div>
        <div
          className="d-flex justify-content-between py-3"
          style={{
            backgroundColor: "#0071A4",
            height: 160,
          }}
        >
          <div className="col pr-0">
            <div className="d-flex flex-row align-items-center">
              <button
                className="btn ml-1"
                onClick={() => setShowMenu(!showMenu)}
                aria-label="Hamburger Menu"
              >
                <Icon icon={iconUrl.menuHamburger} color="white" width={30} />
              </button>
              <div className="text-white">
                <div className="d-flex flex-column">
                  <h4 className="f16-vw fw-700 m-0">Hotel Management System</h4>
                  <h4 className="f14-vw fw-600 m-0">by ruangnyaman</h4>
                </div>
              </div>
            </div>

            <div
              className="overlay-sidebar"
              style={{ left: showMenu ? 0 : "-200%" }}
            >
              <Sidebar close={() => setShowMenu(false)} closeMenu={ref} />
            </div>
          </div>
        </div>
        <MobileHeader setLoading={setLoadingOverlay} />
      </div>

      <div
        className="d-flex justify-content-between py-3"
        style={{
          backgroundColor: "#0071A4",
          height: 80,
          position: "fixed",
          right: 0,
          left: 0,
          top: scrollPosition && !openButtonSheet ? 0 : "-100%",
          zIndex: 10,
          transition: "0.5s",
        }}
      >
        <div className="col pr-0">
          <div className="d-flex flex-row align-items-center">
            <button
              className="btn ml-1"
              aria-label="Hamburger Menu"
              onClick={() => setShowMenu(!showMenu)}
            >
              <Icon icon={iconUrl.menuHamburger} color="white" width={30} />
            </button>
            <div className="text-white">
              <div className="d-flex flex-column">
                <h4 className="f16-vw fw-700 m-0">Hotel Management System</h4>
                <h4 className="f14-vw fw-600 m-0">by ruangnyaman</h4>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
