import React, {
  Fragment,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import {
  addDataPict,
  getData,
  getErr,
  safeErrCode,
} from "../../utils/api-request";
import { EmptyData, MobileEmptyData } from "../Empty/EmptyData";
import {
  bucketRnaWeb,
  formatDate,
  getServerTimeWithoutDays,
  isEmpty,
  oss,
  parsePhone,
  tableCol,
} from "../../utils/index";
import { filterStatusLostFound, reportType } from "./common/utils";
import PopupModal from "../../common/PopupModal";
import NotifMessage, { ButtonClose } from "../../common/Notif";
import { img_fallback } from "../../utils/ui";
import { Icon } from "@iconify/react";
import ReactDatePicker from "react-datepicker";
import SelectOpt from "../../common/SelectOpt";
import { mobileSearchInput, searchInput } from "../booking/bookingHelper";
import { BreadcrumbContext } from "../../common/BreadcrumbContext";
import { is_sub_menu_action } from "../../utils/uac";
import { iconUrl } from "../../config";
import { CameraWebcam } from "../../common/CameraWebcam";
import { IS_MOBILE } from "../../common/Responsive";
import { MobileCardList } from "../../common/CardList";
import { BottomSheet } from "react-spring-bottom-sheet";
import { MobileUploadFile } from "../../common/MobileUploadFile";
import {
  DateRangeFilterMobile,
  DateRangeFilterNew,
} from "../../common/DateRange";
import { t } from "../../common/ErrorMessage";

const FONT_SIZE = IS_MOBILE ? "f12-vw" : "f8-vw";

const TableLostFound = ({
  list,
  loading,
  errorMessage,
  getDetailList,
  returned,
  setListSelected,
}) => {
  const timezone =
    localStorage?.getItem("timezone") && localStorage?.getItem("timezone");
  return (
    <div
      className="table-list table-responsive-sm scroll-table"
      style={{
        height: "22rem",
      }}
    >
      <table className="table table-sm">
        <thead
          className="f8-vw"
          style={{ position: "sticky", top: 0, zIndex: 1 }}
        >
          <tr
            className="text-center text-white"
            style={{ backgroundColor: "#0071A4" }}
          >
            <th className="py-2 align-middle">ID</th>
            <th className="py-2 align-middle">
              <div>Tanggal</div>
              <div>Laporan</div>
            </th>
            <th className="py-2 align-middle">
              <div>Tipe</div>
              <div>Laporan</div>
            </th>
            <th className="py-2 align-middle">
              <div>Nama</div>
              <div>Pelapor</div>
            </th>
            <th className="py-2 align-middle">Nama Barang</th>
            <th className="py-2 align-middle">Jumlah</th>
            <th className="py-2 align-middle">Status</th>
            <th className="py-2 align-middle">Action</th>
          </tr>
        </thead>
        <tbody className="f8-vw text-center">
          {list.length > 0 && !loading ? (
            <>
              {list?.map((item, index) => (
                <Fragment key={item?.id}>
                  <tr
                    style={{
                      cursor: "pointer",
                    }}
                    className={`click-table ${tableCol(index)}`}
                    onClick={() => {
                      getDetailList(item);
                    }}
                  >
                    <td className="align-middle text-center fw-700">
                      {item?.id}
                    </td>
                    <td className="align-middle text-center">
                      {getServerTimeWithoutDays(item?.ts, timezone)}
                    </td>
                    <td className="align-middle text-center fw-700">
                      {item?.report_type_name}
                    </td>
                    <td className="align-middle text-center">
                      {item?.reported_by}
                    </td>
                    <td className="align-middle text-center">
                      {item?.nama_barang}
                    </td>
                    <td className="align-middle text-center">{item?.qty}</td>
                    <td
                      className={`align-middle text-center ${
                        filterStatusLostFound(item?.status)?.className
                      }`}
                    >
                      {filterStatusLostFound(item?.status)?.label}
                    </td>
                    <td
                      className="align-middle text-center"
                      style={{ backgroundColor: "white" }}
                    >
                      {item?.status === 0 &&
                        is_sub_menu_action("lf_lost_found_process") && (
                          <button
                            className="btn btn-sm py-0 blue fw-600 f8-vw"
                            onClick={(e) => {
                              e.preventDefault();
                              e.stopPropagation();
                              returned();
                              setListSelected(item);
                            }}
                          >
                            <span className="mr-1">
                              <Icon
                                icon={iconUrl.archiveArrowBack24Regular}
                                width={15}
                              />
                            </span>
                            <span>Return</span>
                          </button>
                        )}
                      {item?.status === 1 &&
                        is_sub_menu_action("lf_lost_found_process") && (
                          <button
                            className="btn btn-sm py-0 blue fw-600 f8-vw"
                            onClick={(e) => {
                              getDetailList(item);
                            }}
                          >
                            Detail
                          </button>
                        )}
                    </td>
                  </tr>
                </Fragment>
              ))}
            </>
          ) : (
            <tr>
              <td className="p-0" colSpan={12}>
                <EmptyData
                  loading={loading}
                  errMessage={errorMessage}
                  text="Tidak ada data"
                  height="13rem"
                />
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

const DetailPopup = ({ detail, close }) => {
  const detailInformation = (title, value, bold = false) => {
    return (
      <div className={"d-flex flex-row mt-1 " + FONT_SIZE}>
        <div className="col-6 grey px-0">{title}</div>
        <div className={`col-6 px-0 ${bold ? "fw-700" : ""}`}>
          : {value ? value : "-"}
        </div>
      </div>
    );
  };
  const showImageConfirm = (file, title) => {
    return (
      <div className="">
        <label htmlFor="pemesan" className={"fw-600 m-0 " + FONT_SIZE}>
          {title}
        </label>
        <img
          src={bucketRnaWeb + file + oss}
          alt=""
          style={{ width: "100%", objectFit: "contain", height: "10rem" }}
          loading="lazy"
        />
      </div>
    );
  };
  const timezone =
    localStorage?.getItem("timezone") && localStorage?.getItem("timezone");
  return (
    <>
      {IS_MOBILE ? (
        <div className="p-3" style={{ width: "21rem" }}>
          <div className="col">
            <div className={`fw-600 f16-vw`}>
              <span className="mr-1">
                <Icon
                  icon={iconUrl.formMultiple20Filled}
                  width={25}
                  color="orange"
                />
              </span>
              <span className="purple">Loss & Found Detail</span>
            </div>
            <hr className="separator-blue" />
          </div>
          <div
            className="d-flex flex-column scroll-popup hide-scrollbar"
            style={{ maxHeight: "25rem" }}
          >
            <div className="col">
              <div className="d-flex flex-column">
                <div className="col px-0">
                  {detailInformation("ID", detail?.id, true)}
                  {detailInformation(
                    "Tanggal Laporan",
                    getServerTimeWithoutDays(detail?.ts, timezone)
                  )}
                  {detailInformation("Diterima oleh", detail?.entry_by_name)}
                  {detailInformation(
                    "Status",
                    <span
                      className={`font-weight-bold ${
                        filterStatusLostFound(detail?.status)?.className
                      }`}
                    >
                      {filterStatusLostFound(detail?.status)?.label}
                    </span>
                  )}
                </div>
                <div className="col px-0">
                  {detailInformation(
                    "Tipe Laporan",
                    detail?.report_type_name,
                    true
                  )}
                  {detailInformation(
                    "Dilaporkan oleh",
                    detail?.reported_by_type_name
                  )}
                  {detailInformation("Nama Pelapor", detail?.reported_by)}
                  {detailInformation(
                    "Kontak Pelapor",
                    parsePhone(detail?.reported_by_contact)
                  )}
                  {detailInformation("Nama Barang", detail?.nama_barang)}
                  {detailInformation("Jumlah", detail?.qty)}
                  {detailInformation(
                    "Tanggal Hilang/Ditemukan",
                    getServerTimeWithoutDays(detail?.ts_lost_found, timezone)
                  )}
                  {detailInformation(
                    "Lokasi Hilang/Ditemukan",
                    detail?.lost_found_location_name
                  )}
                  {detailInformation(
                    "Diserahkan Kepada",
                    detail?.hand_over_to_name
                  )}
                  {detailInformation(
                    "Tanggal Check In",
                    detail?.check_in_date
                      ? getServerTimeWithoutDays(
                          detail?.check_in_date,
                          timezone
                        )
                      : "-"
                  )}
                  {detailInformation(
                    "Tanggal Check Out",
                    detail?.check_out_date
                      ? getServerTimeWithoutDays(
                          detail?.check_out_date,
                          timezone
                        )
                      : "-"
                  )}
                  {detailInformation("Nomor Kamar", detail?.room_number)}
                </div>
                <div className="col px-0">
                  <div className="d-flex flex-column mt-2">
                    <div className="col px-0">
                      <div className="f12-vw grey mb-1">Photo Barang</div>
                      <div className="">
                        <img
                          src={
                            detail?.photo_barang?.length > 0
                              ? bucketRnaWeb + detail?.photo_barang + oss
                              : img_fallback
                          }
                          alt=""
                          onError={({ currentTarget }) => {
                            currentTarget.onerror = null; // prevents looping
                            currentTarget.src = img_fallback;
                          }}
                          style={{
                            objectFit: "contain",
                            width: "100%",
                            height: "8rem",
                          }}
                          loading="lazy"
                        />
                      </div>
                    </div>
                    <div className="col px-0">
                      <div className="f12-vw grey mt-3 mb-2">Detail Barang</div>
                      <div className="f12-vw">
                        {detail?.detail_barang ? detail?.detail_barang : "-"}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {detail?.ts_returned && (
              <div className="col">
                <hr className="separator-blue" />
                <div className="fw-600 f16-vw">
                  <span className="mr-2">
                    <Icon
                      icon={iconUrl.formMultiple20Filled}
                      width={25}
                      color="orange"
                    />
                  </span>
                  <span className="purple">Returned Detail</span>
                </div>

                <div className="d-flex flex-column">
                  <div className="col px-0">
                    {detailInformation(
                      "Tanggal Pengembalian",
                      getServerTimeWithoutDays(detail?.ts_returned)
                    )}
                    {detailInformation("Penerima", detail?.returned_to)}
                  </div>

                  <div className="col px-0 mt-2">
                    {showImageConfirm(
                      detail?.photo_ktp_penerima,
                      "Foto KTP Penerima"
                    )}
                  </div>
                  <div className="col px-0 mt-2">
                    {showImageConfirm(
                      detail?.handed_over_photo,
                      "Foto Bukti Penerimaan"
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="col">
            <hr className="separator-blue" />
            <div className="d-flex justify-content-end mt-3">
              <div className="col-6 px-0">
                <button
                  className={`btn btn-block text-white btn-gradient`}
                  onClick={() => {
                    close();
                  }}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="p-3" style={{ width: "62rem" }}>
          <div className="col">
            <div className="fw-600 f12-vw">
              <span className="mr-2">
                <Icon
                  icon={iconUrl.formMultiple20Filled}
                  width={25}
                  color="orange"
                />
              </span>
              <span className="purple">Loss & Found Detail</span>
            </div>
            <hr className="separator-blue" />
          </div>
          <div
            className="d-flex flex-column scroll-popup"
            style={{ maxHeight: "25rem" }}
          >
            <div className="col">
              <div className="d-flex flex-row">
                <div className="col px-0">
                  {detailInformation("ID", detail?.id, true)}
                  {detailInformation(
                    "Tanggal Laporan",
                    getServerTimeWithoutDays(detail?.ts, timezone)
                  )}
                  {detailInformation("Diterima oleh", detail?.entry_by_name)}
                  {detailInformation(
                    "Status",
                    <span
                      className={`font-weight-bold ${
                        filterStatusLostFound(detail?.status)?.className
                      }`}
                    >
                      {filterStatusLostFound(detail?.status)?.label}
                    </span>
                  )}
                </div>
                <div className="col px-0">
                  {detailInformation(
                    "Tipe Laporan",
                    detail?.report_type_name,
                    true
                  )}
                  {detailInformation(
                    "Dilaporkan oleh",
                    detail?.reported_by_type_name
                  )}
                  {detailInformation("Nama Pelapor", detail?.reported_by)}
                  {detailInformation(
                    "Kontak Pelapor",
                    parsePhone(detail?.reported_by_contact)
                  )}
                  {detailInformation("Nama Barang", detail?.nama_barang)}
                  {detailInformation("Jumlah", detail?.qty)}
                  {detailInformation(
                    "Tanggal Hilang/Ditemukan",
                    getServerTimeWithoutDays(detail?.ts_lost_found, timezone)
                  )}
                  {detailInformation(
                    "Lokasi Hilang/Ditemukan",
                    detail?.lost_found_location_name
                  )}
                  {detailInformation(
                    "Diserahkan Kepada",
                    detail?.hand_over_to_name
                  )}
                  {detailInformation(
                    "Tanggal Check In",
                    detail?.check_in_date
                      ? getServerTimeWithoutDays(
                          detail?.check_in_date,
                          timezone
                        )
                      : "-"
                  )}
                  {detailInformation(
                    "Tanggal Check Out",
                    detail?.check_out_date
                      ? getServerTimeWithoutDays(
                          detail?.check_out_date,
                          timezone
                        )
                      : "-"
                  )}
                  {detailInformation("Nomor Kamar", detail?.room_number)}
                </div>
                <div className="col px-0">
                  <div className="d-flex flex-column">
                    <div className="col">
                      <div className="f8-vw grey mb-1">Photo Barang</div>
                      <div className="d-flex justify-content-center">
                        <img
                          src={
                            detail?.photo_barang?.length > 0
                              ? bucketRnaWeb + detail?.photo_barang + oss
                              : img_fallback
                          }
                          alt=""
                          onError={({ currentTarget }) => {
                            currentTarget.onerror = null; // prevents looping
                            currentTarget.src = img_fallback;
                          }}
                          style={{
                            objectFit: "contain",
                            width: "100%",
                            height: "10rem",
                          }}
                          loading="lazy"
                        />
                      </div>
                    </div>
                    <div className="col">
                      <div className="f8-vw grey mt-3 mb-2">Detail Barang</div>
                      <div className="f8-vw">
                        {detail?.detail_barang ? detail?.detail_barang : "-"}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {detail?.ts_returned && (
              <div className="col">
                <hr className="separator-blue" />
                <div className="fw-600 f12-vw">
                  <span className="mr-2">
                    <Icon
                      icon={iconUrl.formMultiple20Filled}
                      width={25}
                      color="orange"
                    />
                  </span>
                  <span className="purple">Returned Detail</span>
                </div>

                <div className="d-flex flex-row">
                  <div className="col px-0">
                    {detailInformation(
                      "Tanggal Pengembalian",
                      getServerTimeWithoutDays(detail?.ts_returned)
                    )}
                    {detailInformation("Penerima", detail?.returned_to)}
                  </div>

                  <div className="col pl-0">
                    {showImageConfirm(
                      detail?.photo_ktp_penerima,
                      "Foto KTP Penerima"
                    )}
                  </div>
                  <div className="col pr-0">
                    {showImageConfirm(
                      detail?.handed_over_photo,
                      "Foto Bukti Penerimaan"
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="col">
            <hr className="separator-blue" />
            <div className="d-flex justify-content-end mt-3">
              <div className="col-2 px-0">
                <button
                  className={`btn btn-block text-white btn-gradient`}
                  onClick={() => {
                    close();
                  }}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

const ReturnedPopup = ({
  returnedForm,
  setReturnedForm,
  setFileKtp,
  setFileProofReceived,
  fileKtp,
  fileProofReceived,
  handleDate,
  close,
  mode,
  setMode,
  submit,
  loading,
}) => {
  const [file1, setFile1] = useState("");
  const [file2, setFile2] = useState("");
  const inputContainer = (
    inpValue,
    inpPlaceholder,
    value,
    onChange,
    type,
    required = true
  ) => {
    return (
      <div className="form-group mb-1">
        <label htmlFor="pemesan" className={"m-0 " + FONT_SIZE}>
          {required && <span className="red">* </span>}
          {inpValue}
        </label>
        <input
          type={type ? type : "text"}
          placeholder={inpPlaceholder}
          className={`form-control form-control-sm ${FONT_SIZE}`}
          value={value}
          onChange={onChange}
        />
      </div>
    );
  };
  const validate = () => {
    return fileKtp?.length > 0 &&
      fileProofReceived?.length > 0 &&
      returnedForm?.receiver !== ""
      ? true
      : false;
  };

  const detailInformation = (title, value, bold = false) => {
    return (
      <div className={"d-flex flex-row mt-1 " + FONT_SIZE}>
        <div className="col-6 grey px-0">{title}</div>
        <div className={`col-6 px-0 ${bold ? "font-weight-bold" : ""}`}>
          : {value ? value : "-"}
        </div>
      </div>
    );
  };
  const showImageConfirm = (file, title) => {
    return (
      <div className="">
        <label htmlFor="pemesan" className={FONT_SIZE}>
          {title}
        </label>
        <img
          src={file}
          alt=""
          style={{ width: "100%", height: "10rem", objectFit: "contain" }}
          loading="lazy"
        />
      </div>
    );
  };

  return (
    <div className="p-2" style={{ width: IS_MOBILE ? "21rem" : "23rem" }}>
      {mode === "returned" ? (
        <>
          <div className="pb-2 px-3">
            <span className="mr-2">
              <Icon
                icon={iconUrl.personArrowBack24Filled}
                color="orange"
                width={20}
              />
            </span>
            <span
              className={`purple fw-600 ${IS_MOBILE ? "f16-vw" : "f12-vw"}`}
            >
              Return
            </span>
          </div>
          <div
            className={`d-flex flex-column scroll-popup ${
              IS_MOBILE ? "hide-scrollbar" : ""
            }`}
            style={{ maxHeight: "30rem" }}
          >
            <div className="col">
              <div className="form-group mb-1">
                <label htmlFor="pemesan" className={"mb-0 m-0 " + FONT_SIZE}>
                  Tanggal Pengembalian
                </label>
                <div
                  className=""
                  style={{
                    border: "1px solid #ced4da",
                    borderRadius: 3,
                  }}
                >
                  <div
                    className={"d-flex px-2 align-items-center " + FONT_SIZE}
                  >
                    <Icon
                      icon={iconUrl.calendarIcon}
                      color="#ef9403"
                      width={15}
                    />
                    <div className="">
                      <ReactDatePicker
                        selected={returnedForm?.returnDate}
                        onChange={(e) => handleDate(e)}
                        startDate={returnedForm?.returnDate}
                        className={
                          "date-checkin-new form-control form-control-sm " +
                          FONT_SIZE
                        }
                        dateFormat="dd MMM yyyy"
                        maxDate={new Date()}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col">
              {inputContainer(
                "Penerima",
                "Penerima",
                returnedForm?.receiver,
                (e) =>
                  setReturnedForm({
                    ...returnedForm,
                    receiver: e.target.value,
                  }),
                "text",
                false
              )}
            </div>
            <div className="col">
              <div className="form-group mb-1">
                <label htmlFor="pemesan" className={"m-0 " + FONT_SIZE}>
                  Foto KTP Penerima
                </label>
                {IS_MOBILE ? (
                  <MobileUploadFile
                    setfileUpload={setFileKtp}
                    title={"Add attachment"}
                    height="200px"
                    fileUpload={fileKtp}
                    setShowImg={setFile1}
                    showImg={file1}
                    fontSize="f12-vw"
                  />
                ) : (
                  <CameraWebcam
                    setfileUpload={setFileKtp}
                    title={"Add attachment"}
                    height="10rem"
                    fileUpload={fileKtp}
                    setShowImg={setFile1}
                    showImg={file1}
                  />
                )}
              </div>
            </div>
            <div className="col">
              <div className="form-group mb-1">
                <label htmlFor="pemesan" className={"mb-0 m-0 " + FONT_SIZE}>
                  Foto Bukti Penerimaan
                </label>
                {IS_MOBILE ? (
                  <MobileUploadFile
                    setfileUpload={setFileProofReceived}
                    title={"Add attachment"}
                    height="200px"
                    fileUpload={fileProofReceived}
                    setShowImg={setFile2}
                    showImg={file2}
                    fontSize="f12-vw"
                  />
                ) : (
                  <CameraWebcam
                    setfileUpload={setFileProofReceived}
                    title={"Add attachment"}
                    height="10rem"
                    fileUpload={fileProofReceived}
                    setShowImg={setFile2}
                    showImg={file2}
                  />
                )}
              </div>
            </div>
          </div>
          <div className="col">
            <hr className="separator-blue my-1" />
            <div className="d-flex justify-content-between mt-2">
              <div className="col-5 px-0">
                <button
                  className={
                    "btn btn-block btn-outline-purple text-black " + FONT_SIZE
                  }
                  onClick={() => {
                    close();
                  }}
                >
                  Kembali
                </button>
              </div>
              <div className="col-5 px-0">
                <button
                  className={`btn btn-block text-white ${FONT_SIZE} ${
                    validate() && !loading ? "btn-gradient" : "btn-secondary"
                  }`}
                  onClick={() => {
                    setMode("return-confirmed");
                  }}
                  disabled={validate() && !loading ? false : true}
                >
                  Next
                </button>
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="col">
            <div className="d-flex align-items-center">
              <span className="mr-2">
                <Icon
                  icon={iconUrl.personArrowBack24Filled}
                  color="orange"
                  width={20}
                />
              </span>
              <span
                className={`purple fw-600 ${IS_MOBILE ? "f16-vw" : "f12-vw"}`}
              >
                Returned Confirmation
              </span>
            </div>
          </div>
          <div className="d-flex flex-column">
            <div className="col">
              {detailInformation(
                "Tanggal Pengembalian",
                getServerTimeWithoutDays(returnedForm?.returnDate)
              )}
              {detailInformation("Penerima", returnedForm?.receiver)}
            </div>
            <div className="col mt-2">
              {showImageConfirm(file1, "Foto KTP Penerima")}
            </div>
            <div className="col mt-2">
              {showImageConfirm(file2, "Foto Bukti Penerimaan")}
            </div>
          </div>
          <div className="col">
            <hr className="separator-blue " />
            <div className="d-flex justify-content-between mt-2">
              <div className="col-5 px-0">
                <button
                  className={"btn btn-block btn-outline-purple " + FONT_SIZE}
                  onClick={() => {
                    setMode("returned");
                  }}
                >
                  Kembali
                </button>
              </div>
              <div className="col-5 px-0">
                <button
                  className={`btn btn-block text-white ${FONT_SIZE} ${
                    validate() ? "btn-gradient" : "btn-secondary"
                  }`}
                  onClick={() => {
                    submit();
                  }}
                >
                  {loading ? (
                    <span className="spinner-border spinner-border-sm mr-1"></span>
                  ) : (
                    "Confirm"
                  )}
                </button>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

const LossFoundList = () => {
  const { openButtonSheet, setOpenButtonSheet, setShowFilter } = useContext(
    BreadcrumbContext
  );
  const mounted = useRef(false);
  const [isOpen, setisOpen] = useState(false);
  const [mode, setMode] = useState("");
  const [loadingList, setLoadingList] = useState(false);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [errorList, setErrorList] = useState("");
  const [errorSubmit, setErrorSubmit] = useState("");
  const [lostFoundList, setlostFoundList] = useState([]);
  const [detailList, setdetailList] = useState({});
  const [imgPopup, setImgPopup] = useState("");
  const [headerPopup, setHeaderPopup] = useState("");
  const { setpathLocation } = useContext(BreadcrumbContext);
  const [returnedForm, setReturnedForm] = useState({
    returnDate: new Date(),
    receiver: "",
  });
  const [filterList, setfilterList] = useState({
    reportDateStart: null,
    reportDateEnd: null,
    reportType: "",
    full_name: "",
    item_name: "",
    // lostFoundDate: null,
  });
  const [fileKtp, setFileKtp] = useState([]);
  const [fileProofReceived, setFileProofReceived] = useState([]);
  const [listSelected, setListSelected] = useState({});
  const [reportTypeSelected, setReportTypeSelected] = useState([]);

  const openModal = useCallback(() => {
    setisOpen(!isOpen);
  }, [isOpen]);

  const reset = () => {
    setisOpen(false);
    getLossFoundList();
    setReturnedForm({ ...returnedForm, returnDate: new Date(), receiver: "" });
    setHeaderPopup("");
    setErrorSubmit("");
    setImgPopup("");
    setFileKtp([]);
    setFileProofReceived([]);
    setListSelected({});
    setMode("");
    setfilterList({
      ...filterList,
      reportDateStart: null,
      reportDateEnd: null,
      reportType: "",
      full_name: "",
      item_name: "",
      // lostFoundDate: null,
    });
  };

  const getLossFoundList = (startDate, endDate) => {
    const params = {
      ...(!!startDate && {
        tanggal_laporan: formatDate(startDate),
      }),
      ...(!!endDate && {
        tanggal_laporan_end: formatDate(endDate),
      }),
      ...(!!filterList?.reportType && { tipe_laporan: filterList?.reportType }),
      ...(!!filterList?.full_name && { nama_pelapor: filterList?.full_name }),
      ...(!!filterList?.item_name && { nama_barang: filterList?.item_name }),
      // ...(!!filterList &&
      //   !!filterList?.lostFoundDate && {
      //     tanggal_hilang_ditemukan: formatDate(filterList?.lostFoundDate),
      //   }),
    };
    let paramsFormatted = null;

    if (!isEmpty(params)) {
      paramsFormatted = new URLSearchParams(params);
    }
    let url = `/api/v1/listforreturn?${
      paramsFormatted !== null ? `&${paramsFormatted}` : ""
    }`;
    setLoadingList(true);
    getData(url)
      .then((resp) => {
        if (mounted.current) {
          setLoadingList(false);
          if (resp.data) {
            setlostFoundList(resp.data);
          } else {
            resp.json().then((err) => {
              setErrorList(t(safeErrCode(err)));
            });
          }
        }
      })
      .catch((error) => {
        console.log(error);
        setErrorList(t(getErr(error)));
        setLoadingList(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  const handleEnter = (e) => {
    if (e.key === "Enter") {
      getLossFoundList(filterList.reportDateStart, filterList.reportDateEnd);
    }
  };

  const getDetailList = (id) => {
    let url = `/api/v1/detaillossnfound?id=${id}`;
    getData(url)
      .then((resp) => {
        if (mounted.current) {
          if (resp.data) {
            setdetailList(resp.data);
            openModal();
            setMode("detail");
          } else {
            resp.json().then((err) => {
              setErrorList(t(safeErrCode(err)));
            });
            openModal();
            setMode("error");
            setImgPopup("/img/error-icon/save-room-failed.svg");
            setHeaderPopup("Gagal Fetch Data");
          }
        }
      })
      .catch((error) => {
        console.log(error);
        setLoadingList(false);
        setErrorList(t(getErr(error)));
        setMode("error");
        setHeaderPopup("Gagal Fetch Data");
        setImgPopup("/img/error-icon/save-room-failed.svg");
      });
  };

  const submitReturned = () => {
    setLoadingSubmit(true);
    let url = "/api/v1/lossnfoundreturn";
    let payload = {
      returned_to: returnedForm?.receiver,
      photo_ktp_penerima: fileKtp[0],
      returned_photo: fileProofReceived[0],
      id: listSelected?.id,
      tsreturn: formatDate(returnedForm?.returnDate),
    };
    const formData = new FormData();
    for (const key in payload) {
      formData.append(key, payload[key]);
    }
    for (const pair of formData.entries()) {
      console.log(pair[0] + " - " + pair[1]);
    }
    addDataPict(url, formData)
      .then((resp) => {
        setLoadingSubmit(false);
        if (resp.message === "Success") {
          setMode("return-success");
          setHeaderPopup("Proses Returned Berhasil");
          setImgPopup("/img/lost-found/icon-return-success.svg");
        } else {
          resp.json().then((err) => {
            setErrorSubmit(t(safeErrCode(err)));
          });
          setHeaderPopup("Gagal Submit");
          setImgPopup("/img/error-icon/save-room-failed.svg");
          setMode("error-submit");
        }
      })
      .catch((error) => {
        console.log(error);
        setLoadingSubmit(false);
        setErrorSubmit(t(getErr(error)));
        setMode("error-submit");
        setHeaderPopup("Gagal Submit");
        setImgPopup("/img/error-icon/save-room-failed.svg");
      });
  };

  const buttonReturned = () => {
    setMode("returned");
    openModal();
  };
  const handleDate = (val) => {
    setReturnedForm({ ...returnedForm, returnDate: val });
  };

  const handleReportType = (val) => {
    if (!val) {
      setReportTypeSelected([]);
      setfilterList({ ...filterList, reportType: "" });
    } else {
      setfilterList({ ...filterList, reportType: val.value });
      setReportTypeSelected(val);
    }
  };
  const handleReportDateStart = (val) => {
    setfilterList({ ...filterList, reportDateStart: val });
  };
  const handleReportDateEnd = (val) => {
    setfilterList({ ...filterList, reportDateEnd: val });
  };
  const validateReturn = (item) => {
    switch (item.status) {
      case 0:
        getDetailList(item?.id);
        break;
      case 1:
        setMode("detail");
        openModal();
        setdetailList(item);
        break;

      default:
        break;
    }
  };

  const resetFilter = () => {
    setReportTypeSelected([]);
    setfilterList({
      reportDateStart: null,
      reportDateEnd: null,
      reportType: "",
      full_name: "",
      item_name: "",
      // lostFoundDate: null,
    });
  };

  const handleClear = () => {
    setfilterList({
      reportDateStart: null,
      reportDateEnd: null,
    });
    setlostFoundList([]);
    getLossFoundList();
  };

  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);

  useEffect(() => {
    if (
      filterList?.full_name === "" &&
      filterList?.item_name === "" &&
      filterList?.reportType === "" &&
      filterList?.reportDateEnd === null &&
      filterList.reportDateStart === null
    ) {
      getLossFoundList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    filterList?.full_name,
    filterList?.item_name,
    filterList?.reportDateEnd,
    filterList.reportDateStart,
    filterList?.reportType,
  ]);

  useEffect(() => {
    setpathLocation("/lost-& found/lost-& found list");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setShowFilter(true);
    return () => {
      setShowFilter(false);
    };
  }, [setShowFilter]);

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
    return () => {
      document.body.style.overflow = "unset";
    };
  }, [isOpen]);

  return (
    <>
      {IS_MOBILE ? (
        <div className="my-1 px-3">
          <div className="d-flex flex-column p-1 mb-5">
            <div className="col px-0">
              {lostFoundList?.length > 0 ? (
                lostFoundList.map((item, indexItem) => {
                  const timezone = localStorage.getItem("timezone");
                  return (
                    <div
                      key={item?.id}
                      onClick={(e) => {
                        validateReturn(item);
                      }}
                    >
                      <MobileCardList
                        title={item?.report_type_name}
                        action_button={
                          item?.status === 0 &&
                          is_sub_menu_action("lf_lost_found_process") && (
                            <button
                              className="btn border-0 btn-sm btn-gradient text-white btn-block f12-vw fw-700"
                              onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                buttonReturned();
                                setListSelected(item);
                              }}
                            >
                              <span className="mr-1">
                                <Icon
                                  icon={iconUrl.archiveArrowBack24Regular}
                                  width={15}
                                />
                              </span>
                              <span>Return</span>
                            </button>
                          )
                        }
                      >
                        <div className="card-hms-list">
                          <div className="col-6 px-0 grey-text">ID</div>
                          <div className="col pr-0 f12-vw black-value">
                            {item?.id}
                          </div>
                        </div>
                        <div className="card-hms-list">
                          <div className="col-6 px-0 grey-text">
                            Tanggal Laporan
                          </div>
                          <div className="col pr-0 f12-vw black-value">
                            {getServerTimeWithoutDays(item?.ts, timezone)}
                          </div>
                        </div>
                        <div className="card-hms-list">
                          <div className="col-6 px-0 grey-text">
                            Nama Pelapor
                          </div>
                          <div className="col pr-0 f12-vw black-value">
                            {item?.reported_by}
                          </div>
                        </div>
                        <div className="card-hms-list">
                          <div className="col-6 px-0 grey-text">
                            Nama Barang
                          </div>
                          <div className="col pr-0 f12-vw black-value">
                            {item?.nama_barang}
                          </div>
                        </div>
                        <div className="card-hms-list">
                          <div className="col-6 px-0 grey-text">
                            Jumlah Barang
                          </div>
                          <div className="col pr-0 f12-vw black-value">
                            {item?.qty}
                          </div>
                        </div>
                        <div className="card-hms-list">
                          <div className="col-6 px-0 grey-text">Status</div>
                          <div className="col pr-0 f12-vw black-value">
                            <span
                              className={`fw-600 ${
                                filterStatusLostFound(item?.status)?.className
                              }`}
                            >
                              {filterStatusLostFound(item?.status)?.label}
                            </span>
                          </div>
                        </div>
                      </MobileCardList>
                    </div>
                  );
                })
              ) : (
                <div style={{ position: "relative" }}>
                  <MobileEmptyData
                    errMessage={errorList}
                    loading={loadingList}
                    height="40vh"
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      ) : (
        <div className="my-1 px-3">
          <div className="px-3">
            <div className="d-flex flex-row justify-content-between">
              <div className="col-md-10 px-0">
                <div className="d-flex flex-row">
                  <div className="col px-0 mr-1">
                    <label htmlFor="" className="f8-vw m-0 p-0 fw-600">
                      Tanggal Laporan
                    </label>
                    <DateRangeFilterNew
                      start={filterList.reportDateStart}
                      end={filterList.reportDateEnd}
                      handleStart={handleReportDateStart}
                      handleEnd={handleReportDateEnd}
                      isClear={true}
                      handleClear={handleClear}
                      minDate={true}
                    />
                  </div>
                  <div className="col px-0 mr-1">
                    <label htmlFor="" className="f8-vw m-0 p-0 fw-600">
                      Tipe Laporan
                    </label>
                    <SelectOpt
                      optionsValue={reportType}
                      onChange={handleReportType}
                      placeholder={"Tipe Laporan"}
                      value={reportTypeSelected}
                      clear={true}
                    />
                  </div>
                  <div className="col px-0 mr-1">
                    <label htmlFor="" className="f8-vw m-0 p-0 fw-600">
                      Nama Pelapor
                    </label>
                    {searchInput(
                      "Nama Pelapor",
                      filterList?.full_name,
                      (e) =>
                        setfilterList({
                          ...filterList,
                          full_name: e.target.value,
                        }),
                      handleEnter
                    )}
                  </div>
                  <div className="col px-0 mr-1">
                    <label htmlFor="" className="f8-vw m-0 p-0 fw-600">
                      Nama Barang
                    </label>
                    {searchInput(
                      "Nama Barang",
                      filterList?.item_name,
                      (e) =>
                        setfilterList({
                          ...filterList,
                          item_name: e.target.value,
                        }),
                      handleEnter
                    )}
                  </div>
                </div>
              </div>
              <div className="col-md-2 pr-0 pl-5 mt-auto">
                <div className="d-flex flex-column">
                  <button
                    className="btn btn-sm search-new btn-block mt-auto f8-vw fw-500 text-white"
                    onClick={() => {
                      setlostFoundList([]);
                      getLossFoundList(
                        filterList.reportDateStart,
                        filterList.reportDateEnd
                      );
                    }}
                    style={{
                      paddingTop: 6,
                      paddingBottom: 6,
                      backgroundColor: "#742B9E",
                    }}
                  >
                    Search
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="px-3 mt-3">
            <TableLostFound
              list={lostFoundList}
              loading={loadingList}
              errorMessage={errorList}
              getDetailList={validateReturn}
              returned={buttonReturned}
              setListSelected={setListSelected}
            />
          </div>
        </div>
      )}
      {IS_MOBILE && (
        <div>
          <BottomSheet
            open={openButtonSheet}
            onDismiss={() => setOpenButtonSheet(false)}
            snapPoints={({ maxHeight }) => maxHeight}
            header={
              <div className="d-flex justify-content-between">
                <span className="purple f16-vw fw-600 px-2">Filter</span>
                <span
                  className="px-2"
                  onClick={() => setOpenButtonSheet(false)}
                >
                  <Icon icon={iconUrl.closeBold} color={"grey"} width={20} />
                </span>
              </div>
            }
            footer={
              <div className="d-flex justify-content-between">
                <div className="col-6 pl-2">
                  <button
                    className="btn btn-block btn-outline-purple f12-vw"
                    onClick={() => {
                      resetFilter();
                      setOpenButtonSheet(false);
                    }}
                  >
                    Reset
                  </button>
                </div>
                <div className="col-6 pr-2">
                  <button
                    className="btn btn-block btn-gradient f12-vw text-white"
                    onClick={() => {
                      setlostFoundList([]);
                      getLossFoundList(
                        filterList.reportDateStart,
                        filterList.reportDateEnd
                      );
                      setOpenButtonSheet(false);
                    }}
                  >
                    Search
                  </button>
                </div>
              </div>
            }
          >
            <div className="mt-3">
              <div className="px-4 mt-2">
                <label htmlFor="" className="f12-vw m-0 p-0 fw-600">
                  Tanggal Laporan
                </label>
                <DateRangeFilterMobile
                  start={filterList?.reportDateStart}
                  end={filterList?.reportDateEnd}
                  handleStart={(value) => handleReportDateStart(value)}
                  handleEnd={(value) => handleReportDateEnd(value)}
                  fontSize="f12-vw"
                  minDate={true}
                />
              </div>

              <div className="px-4 mt-3">
                <label htmlFor="" className="f12-vw m-0 p-0 fw-600">
                  Tipe Laporan
                </label>
                <SelectOpt
                  optionsValue={reportType}
                  onChange={handleReportType}
                  placeholder={"Tipe Laporan"}
                  value={reportTypeSelected}
                  fontSize="calc(8pt + 0.472vw)"
                  menuHeight="12rem"
                  clear={true}
                  isSearchable={true}
                />
              </div>
              <div className="px-4 mt-3">
                <label htmlFor="" className="f12-vw m-0 p-0 fw-600">
                  Nama Pelapor
                </label>
                {mobileSearchInput("Nama Pelapor", filterList?.full_name, (e) =>
                  setfilterList({
                    ...filterList,
                    full_name: e.target.value,
                  })
                )}
              </div>
              <div className="px-4 mt-3">
                <label htmlFor="" className="f12-vw m-0 p-0 fw-600">
                  Nama Barang
                </label>
                {mobileSearchInput("Nama Barang", filterList?.item_name, (e) =>
                  setfilterList({
                    ...filterList,
                    item_name: e.target.value,
                  })
                )}
              </div>
            </div>
          </BottomSheet>
        </div>
      )}
      <PopupModal
        modalIsOpen={isOpen}
        width={mode === "detail" ? "40rem" : "20rem"}
        maxWidth={mode === "detail" ? "40rem" : "20rem"}
      >
        {mode === "detail" ? (
          <DetailPopup detail={detailList} close={reset} />
        ) : mode === "returned" || mode === "return-confirmed" ? (
          <ReturnedPopup
            date={returnedForm?.returnDate}
            returnedForm={returnedForm}
            handleDate={handleDate}
            setReturnedForm={setReturnedForm}
            setFileKtp={setFileKtp}
            setFileProofReceived={setFileProofReceived}
            close={reset}
            fileKtp={fileKtp}
            fileProofReceived={fileProofReceived}
            mode={mode}
            setMode={setMode}
            submit={submitReturned}
            loading={loadingSubmit}
          />
        ) : mode === "return-success" ? (
          <>
            <NotifMessage
              closeModal={openModal}
              img={imgPopup}
              messageHeader={headerPopup}
              // color="red"
              // messageBody={errorSubmit}
            />
            <ButtonClose close={reset} text="OK" color="bg-green" />
          </>
        ) : mode === "error-submit" ? (
          <>
            <NotifMessage
              closeModal={openModal}
              img={imgPopup}
              messageHeader={headerPopup}
              color="red"
              messageBody={errorSubmit}
            />
            <ButtonClose close={reset} text="OK" />
          </>
        ) : (
          <>
            <NotifMessage
              closeModal={openModal}
              img={imgPopup}
              messageHeader={headerPopup}
              color="red"
              messageBody={errorList}
            />
            <ButtonClose close={reset} text="OK" />
          </>
        )}
      </PopupModal>
    </>
  );
};

export default LossFoundList;
