import React, { Fragment, useEffect, useState } from "react";
import { IS_MOBILE } from "../../../common/Responsive";
import { Icon } from "@iconify/react";
import { iconUrl } from "../../../config";
import { grouping_compensation } from "../helper";
import {
  editLocalQty,
  editLocalRoomCompensation,
  getCheckedItemPenalty,
  getLocalPenaltySubmit,
  getPenaltyQty,
  priceSplit,
  saveLocalPenaltyData,
  saveLocalPenaltySubmit,
} from "../../../utils";

const FONT_SIZE = IS_MOBILE ? "f12-vw" : "f8-vw";
const FONT_SIZE_HEADER = IS_MOBILE ? "f16-vw" : "f14-vw";

const BoxList = ({ item_name, onChange, value, checked }) => {
  return (
    <div className="">
      <div
        className="d-flex flex-row align-items-center pb-1"
        style={{ borderBottom: "1px solid #e9e9e9" }}
      >
        <div className="col px-0">
          <div
            className="d-flex align-items-center"
            onClick={onChange}
            style={{ cursor: "pointer" }}
          >
            <input type="checkbox" value={value} readOnly checked={checked} />
            <label
              className={"form-check-label px-2 " + FONT_SIZE}
              style={{ cursor: "pointer" }}
              htmlFor="defaultCheck1"
            >
              {item_name}
            </label>
          </div>
        </div>
        <div className="ml-auto">
          <span className={FONT_SIZE}>
            {value ? `Rp ${priceSplit(value)}` : "-"}
          </span>
        </div>
      </div>
    </div>
  );
};

const PenaltyChargePopup = ({ close, room, penaltyData = [] }) => {
  const [penaltyList, setPenaltyList] = useState(
    grouping_compensation(penaltyData)
  );
  const [roomSelect, setRoomSelect] = useState([]);

  const arrLocal = getLocalPenaltySubmit();

  const updateCheckBox = (item, index, value, index_categ) => {
    let arr = [...penaltyList];
    let data = [...item];
    data[index].checked = value;
    arr[index_categ].activities = data;
    setPenaltyList(arr);
  };

  const clearChecked = () => {
    if (penaltyData) {
      setPenaltyList(grouping_compensation(penaltyData));
    }
  };

  const submitPenalty = () => {
    let data_equipment = JSON.parse(localStorage?.getItem("penalty_data"));
    let item_id = [];
    let arr = [];
    let dx = [];
    let dataTrue = [];

    for (let index = 0; index < penaltyList.length; index++) {
      let x = penaltyList[index].activities.filter(
        (item) => item.checked === true
      );
      dx.push(x);
      let d = x.map((data) => {
        return data.item_id;
      });
      arr.push(d);
    }

    for (let index = 0; index < arr.length; index++) {
      item_id.push.apply(item_id, arr[index]);
    }
    for (let index = 0; index < dx.length; index++) {
      dataTrue.push.apply(dataTrue, dx[index]);
    }

    let qty = dataTrue.map((item) => {
      return item.value;
    });
    let price = dataTrue.reduce((a, v) => (a = a + v.value), 0);
    let dxx = arrLocal?.filter((item) => item.room === roomSelect);
    let roomX = data_equipment?.filter((item) => item.room === roomSelect);
    let temp_data = roomX[0];

    temp_data.penalty = dataTrue;
    let arx = editLocalQty(data_equipment, roomSelect, temp_data);
    let temp = dxx[0];
    temp.itemids = item_id;
    temp.price = price;
    temp.qty = qty;
    let r = editLocalRoomCompensation(arrLocal, roomSelect, temp);

    setTimeout(() => {
      saveLocalPenaltySubmit(r);
      saveLocalPenaltyData(arx);
      clearChecked();
      setRoomSelect([]);
      close();
    }, [500]);
  };

  useEffect(() => {
    if (room?.length === 1) {
      setRoomSelect(room[0]);
    }
  }, [room]);

  useEffect(() => {
    let dBackup = [...penaltyList];

    for (let i = 0; i < dBackup.length; i++) {
      dBackup[i].activities.length > 0 &&
        dBackup[i].activities.map((item) => {
          return (
            (item.checked = getCheckedItemPenalty(roomSelect, item.item_id)),
            (item.qty =
              getPenaltyQty(roomSelect, item.item_id) !== undefined
                ? getPenaltyQty(roomSelect, item.item_id)
                : 0)
          );
        });
    }
    setPenaltyList(dBackup);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [roomSelect]);

  return (
    <>
      <div
        className="d-flex flex-column px-4 py-3"
        style={{ width: IS_MOBILE ? "21rem" : "30rem" }}
      >
        <div className="col px-0">
          <div className={"blue-title fw-700 " + FONT_SIZE_HEADER}>
            <span className="mr-2">
              <Icon
                icon={iconUrl.clipboardTextSearch}
                color="orange"
                width={25}
              />
            </span>
            Denda
          </div>
        </div>
        <div className="col px-0">
          <hr className="separator-blue mb-0 mt-2" />
          <div className={`d-flex py-2 ${IS_MOBILE ? "f14-vw" : "f10-vw"}`}>
            <div className="mr-2 black-text">Room Number</div>
            <div className="black-text fw-700">: {room[0]}</div>
          </div>
          <hr className="separator-blue mt-0 mb-2" />
        </div>

        <div
          className={IS_MOBILE ? "scroll-popup hide-scrollbar" : "scroll-popup"}
          style={{ minHeight: "15rem", maxHeight: "28rem" }}
        >
          {penaltyList?.length > 0 &&
            penaltyList.map((item, i) => {
              return (
                <Fragment key={i}>
                  <div
                    className={`col black-text px-0 fw-700 ${
                      IS_MOBILE ? "f12-vw" : "f8-vw"
                    } ${i !== 0 ? "mt-3" : ""}`}
                  >
                    {item?.category_name}
                  </div>
                  {IS_MOBILE ? (
                    <hr className="separator-grey my-0" />
                  ) : (
                    <hr className="separator-purple my-0" />
                  )}
                  <div className={`col d-flex my-2 px-0 flex-column`}>
                    {item?.activities?.length > 0 &&
                      item?.activities?.map((data, index) => {
                        return (
                          <Fragment key={index}>
                            <div className={`col px-0 pt-1`}>
                              <BoxList
                                item_name={data?.item_name}
                                value={data?.value}
                                checked={data?.checked}
                                onChange={() =>
                                  updateCheckBox(
                                    item.activities,
                                    index,
                                    data.checked ? false : true,
                                    i
                                  )
                                }
                                qty={data?.qty}
                                activities={item?.activities}
                                activities_index={index}
                                index_categ={i}
                              />
                            </div>
                          </Fragment>
                        );
                      })}
                  </div>
                </Fragment>
              );
            })}
        </div>

        <div className="d-flex mt-3">
          <div
            className={`col-2 pl-0 pr-1 purple my-auto ${FONT_SIZE} ${
              IS_MOBILE ? "col-2" : "col-4"
            }`}
            style={{ cursor: "pointer" }}
            onClick={() => clearChecked()}
          >
            {IS_MOBILE ? (
              <button className="btn btn-block bg-primaryCol-4 f12-vw">
                <span>
                  <Icon
                    icon={iconUrl.clearOutlined}
                    width={19}
                    color="purple"
                  />
                </span>
              </button>
            ) : (
              <u>Clear selected item</u>
            )}
          </div>
          <div className="col px-0">
            <div className="d-flex justify-content-between">
              <div className="col-6 pr-1 pl-0">
                <button
                  className={
                    "btn btn-block btn-outline-purple fw-700 " + FONT_SIZE
                  }
                  onClick={() => close()}
                >
                  Cancel
                </button>
              </div>
              <div className="col-6 pr-0 pl-1">
                <button
                  className={`btn btn-block text-white ${
                    roomSelect?.length > 0 ? "btn-gradient" : "btn-secondary"
                  } fw-700 ${FONT_SIZE}`}
                  onClick={() => {
                    submitPenalty();
                  }}
                  disabled={roomSelect?.length === 0}
                >
                  Confirm
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PenaltyChargePopup;
