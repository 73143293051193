import React, { useCallback, useRef, useState } from "react";
import Webcam from "react-webcam";
import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";
import { Icon } from "@iconify/react";
import Resizer from "react-image-file-resizer";
import { iconUrl } from "../config";

const MultipleWebcam = ({
  height,
  setfileUpload,
  title,
  fileUpload,
  fontSize = "f8-vw",
}) => {
  const webcamRef = useRef(null);
  const inputRef = useRef(null);
  const [imgSrcs, setImgSrcs] = useState([]);
  const [isWebCam, setisWebCam] = useState(false);

  const videoConstraints = {
    width: 1280,
    height: 720,
    facingMode: "user",
  };

  const activeCamera = () => {
    setisWebCam(true);
  };

  const removePicture = (index) => {
    let data = imgSrcs.filter((item, i) => i !== index);
    let dataFile = fileUpload.filter((item, i) => i !== index);
    setImgSrcs(data);
    setfileUpload(dataFile);
  };

  const resizeFile = (file) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        640,
        480,
        "png",
        50,
        0,
        (uri) => {
          resolve(uri);
        },
        "file"
      );
    });

  const handleFile = async (val) => {
    let file = val.target.files;
    if (file.length > 0) {
      for (let index = 0; index < file.length; index++) {
        let resize = await resizeFile(file[index]);
        setImgSrcs((prev) => [...prev, URL.createObjectURL(resize)]);
        setfileUpload((prev) => [...prev, resize]);
      }
    }
  };

  const capture = useCallback(() => {
    const imageSrc = webcamRef.current.getScreenshot();
    setImgSrcs((prev) => [...prev, imageSrc]);
    let indexData = new Date().getTime();
    const [, type] = imageSrc.split(";")[0].split("/");
    let mimeType = imageSrc.match(/[^:]\w+\/[\w-+\d.]+(?=;|,)/)[0];
    fetch(imageSrc)
      .then((res) => res.blob())
      .then((blob) => {
        let file = new File([blob], `image_${indexData}.${type}`, {
          type: mimeType,
        });
        setfileUpload((prev) => [...prev, file]);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [webcamRef]);

  const handleOpenFileInput = useCallback(() => {
    setTimeout(() => {
      inputRef.current.click();
    }, 300);
    setisWebCam(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputRef]);

  const CustomUpload = ({ onChange, title }) => {
    return (
      <>
        <div
          className="d-flex flex-column justify-content-center align-items-center px-0"
          style={{
            border: "1px dashed lightgray",
            height: height,
            borderRadius: 5,
            width: "100%",
          }}
          onClick={handleOpenFileInput}
        >
          <div className="">
            <img src="/img/icon/icon-upload.svg" alt="" width={30} />
          </div>
          <div className={fontSize + " fw-600 blue"}>
            {title ? title : "Upload Foto"}
          </div>
        </div>
        <input
          type="file"
          ref={inputRef}
          accept="image/*"
          onChange={(e) => onChange(e)}
          style={{
            display: "none",
          }}
          multiple
        />
      </>
    );
  };

  const WebCamCapture = () => {
    return (
      <>
        <Webcam
          audio={false}
          ref={webcamRef}
          screenshotFormat="image/jpeg"
          videoConstraints={videoConstraints}
          style={{
            width: "100%",
            height: height ? height : "120px",
            objectFit: "contain",
          }}
        />
      </>
    );
  };

  return (
    <>
      {isWebCam ? (
        <WebCamCapture />
      ) : (
        <CustomUpload onChange={(e) => handleFile(e)} title={title} />
      )}
      <div className="d-flex flex-row justify-content-between py-2">
        <div className="col-6 pl-0">
          {isWebCam ? (
            <button
              className={"btn btn-block purple " + fontSize}
              style={{ backgroundColor: "#DCC9E7", border: "none" }}
              onClick={() => capture()}
            >
              Capture Photo
            </button>
          ) : (
            <button
              className={"btn btn-block purple " + fontSize}
              style={{ backgroundColor: "#DCC9E7", border: "none" }}
              onClick={() => activeCamera()}
            >
              Take Photo
            </button>
          )}
        </div>
        <div className="col-6 pr-0">
          <button
            className={"btn btn-block purple " + fontSize}
            style={{ backgroundColor: "#DCC9E7", border: "none" }}
            onClick={() => handleOpenFileInput()}
          >
            Browse File
          </button>
        </div>
      </div>
      <div
        style={{
          height: imgSrcs?.length > 0 ? "4.5rem" : 0,
          overflowY: "auto",
        }}
      >
        <div className="d-flex flex-wrap">
          {imgSrcs?.length > 0 &&
            imgSrcs?.map((imgSrc, i) => (
              <div
                style={{
                  width: "clamp(3rem,100%,5rem)",
                  padding: 4,
                  position: "relative",
                }}
                key={i}
              >
                <Zoom>
                  <img src={imgSrc} alt="capture" style={{ width: "100%" }} />
                </Zoom>
                <span
                  style={{
                    position: "absolute",
                    zIndex: 10,
                    top: "-10%",
                    right: "0",
                    cursor: "pointer",
                  }}
                  onClick={() => removePicture(i)}
                >
                  <Icon icon={iconUrl.circleX} />
                </span>
              </div>
            ))}
        </div>
      </div>
    </>
  );
};

export default React.memo(MultipleWebcam);
