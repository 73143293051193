import { IS_MOBILE } from "../../../common/Responsive";

const FONT_SIZE = IS_MOBILE ? "f12-vw" : "f8-vw";

const CustomOptionLabel = ({ label, title, className }) => (
  <div className="d-flex align-items-center">
    <div
      className={`text-center px-2 mr-2 ${FONT_SIZE} ${className}`}
      style={{
        borderRadius: 2,
        paddingTop: 3,
        paddingBottom: 3,
        width: "3rem",
      }}
    >
      {label}
    </div>
    <div className={FONT_SIZE}>{title}</div>
  </div>
);

export { CustomOptionLabel };

export const CustomOptionLabelMobile = ({ label, title, className }) => (
  <div className="d-flex align-items-center">
    <div
      className={"text-center f12-vw px-2 mr-2 " + className}
      style={{
        borderRadius: 2,
        paddingTop: 3,
        paddingBottom: 3,
        width: "3rem",
      }}
    >
      {label}
    </div>
    <div className="f12-vw">{title}</div>
  </div>
);
