import { Icon } from "@iconify/react";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { HeaderName } from "../../../common/HeaderName";
import SelectOpt from "../../../common/SelectOpt";
import { iconUrl } from "../../../config";
import { priceSplit } from "../../../utils";
import { getData, getErr, safeErrCode } from "../../../utils/api-request";
import { t } from "../../../common/ErrorMessage";
import { IS_MOBILE } from "../../../common/Responsive";

const FONT_SIZE = IS_MOBILE ? "f12-vw" : "f8-vw";
const FONT_SIZE_HEADER = IS_MOBILE ? "f16-vw" : "f12-vw";
const FONT_SIZE_TITLE = IS_MOBILE ? "f14-vw" : "f10-vw";

export const ReschedulePayment = ({
  close,
  charge,
  loading,
  errorCharge,
  payment,
  setPayment,
  change,
  setChange,
  setPaymentSelected,
  setBankSelected,
  bankSelected,
  paymentSelected,
  confirm,
}) => {
  const mounted = useRef(false);
  const [paymentMethod, setPaymentMethod] = useState([]);
  const [bankList, setBankList] = useState([]);

  const getPaymentMethod = useCallback(() => {
    getData("/api/v1/listpaymentmethod")
      .then((resp) => {
        if (mounted.current) {
          if (resp.data) {
            let d = resp.data.map((item) => {
              return {
                value: item.payment_type,
                label: item.payment_name,
              };
            });
            setPaymentMethod(d);
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const getPaymentType = useCallback(() => {
    if (paymentSelected?.value === 2) {
      getData(`/api/v1/paymentype?payment_type=${paymentSelected.value}`)
        .then((resp) => {
          if (mounted.current) {
            if (resp.data) {
              let d = resp.data.map((item) => {
                return {
                  value: item.bank_id,
                  label: `${item.bank_name} ( ${item.bank_accno} )`,
                  bank_name: item.bank_name,
                  bank_accno: item.bank_accno,
                  bank_accholder: item.bank_accholder,
                };
              });
              setBankList(d);
            } else {
              resp.json().then((err) => {
                console.error(t(safeErrCode(err)));
              });
              setBankList([]);
            }
          }
        })
        .catch((error) => {
          let err = getErr(error);
          console.error(t(err));
        });
    }
  }, [paymentSelected]);

  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);

  const handlePaymentMethod = (val) => {
    setPaymentSelected(val);
    setBankSelected([]);
  };
  const handlePayment = (val) => {
    setPayment(Number(val.target.value.replace(/\D/g, "")));
  };
  const handleBankSelected = (val) => {
    setBankSelected(val);
  };

  const detailPayment = (title, value, color, tax) => {
    return (
      <div className={`d-flex flex-row my-1 py-1 ${FONT_SIZE}`}>
        <div className="col-6 px-0">
          <span className={` ${color ? color : "text-black"}`}>{title}</span>
        </div>
        <div className="col px-0">
          <div className="d-flex flex-row justify-content-between">
            <div className={`${color ? color : "text-black"} text-black`}>
              Rp
            </div>
            <div
              className={`col px-0 text-right  ${color ? color : "text-black"}`}
            >
              <span className="px-2">{value}</span>
            </div>
          </div>
          {tax && value > 0 && (
            <div className="px-2" style={{ position: "relative" }}>
              <small
                className="f4-vw grey"
                style={{ position: "absolute", top: -3, right: 5 }}
              >
                Sudah termasuk pajak
              </small>
            </div>
          )}
        </div>
      </div>
    );
  };

  const paymentInput = (title, value, onChange, autoFocus) => {
    return (
      <div className={`d-flex flex-row align-items-center ${FONT_SIZE}`}>
        <div className="col-6 px-0">
          <span className={`font-weight-bold text-black`}>{title}</span>
        </div>
        <div className="col px-0">
          <div className="d-flex flex-row align-items-center justify-content-between">
            <div className={`font-weight-bold text-black`}>Rp</div>
            <div
              className={`col px-0 font-weight-bold text-black ml-2`}
              style={{ border: "1px solid lightgray", borderRadius: 5 }}
            >
              <input
                type="text"
                className={`form-control text-right border-0 form-control-sm ${FONT_SIZE} font-weight-bold`}
                value={priceSplit(value)}
                style={{ textAlign: "right" }}
                autoFocus={autoFocus}
                onChange={onChange}
                onFocus={
                  value > 0
                    ? (e) => (e.target.value = priceSplit(value))
                    : (e) => (e.target.value = "")
                }
                onBlur={(e) => (e.target.value = priceSplit(value))}
                maxLength={12}
                inputMode="numeric"
              />
            </div>
          </div>
        </div>
      </div>
    );
  };

  useEffect(() => {
    getPaymentMethod();
  }, [getPaymentMethod]);

  useEffect(() => {
    getPaymentType();
  }, [getPaymentType]);

  useEffect(() => {
    let changing = parseInt(payment) - parseInt(charge?.total_charge);
    if (changing > 0 && paymentSelected?.value === 3) {
      setChange(changing);
    } else {
      setChange(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [charge?.total_charge, payment, paymentSelected]);

  return (
    <div className="d-flex flex-column px-3 py-3" style={{ width: "21rem" }}>
      <div className="col px-0 py-1">
        <div className="d-flex align-items-center justify-content-between">
          <div className="col">
            <div className="d-flex align-items-center justify-content-start">
              <Icon
                icon={iconUrl.walletCreditCard20Filled}
                color="orange"
                width={25}
              />
              <div className="fw-600 mx-2">
                <HeaderName
                  text="Pembayaran"
                  fontSize={FONT_SIZE_HEADER}
                  color1="blue-title"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="col px-0"
        style={{
          backgroundColor: "white",
          borderRadius: "5px",
        }}
      >
        <div className="d-flex flex-column mt-2">
          <div className="col">
            <div className="">
              <label
                htmlFor="payment-method"
                className={`${FONT_SIZE} mb-2 fw-600`}
              >
                Metode Pembayaran
              </label>
              <SelectOpt
                placeholder="Pilih metode pembayaran"
                optionsValue={paymentMethod}
                onChange={handlePaymentMethod}
                value={paymentSelected}
                fontSize={IS_MOBILE ? "calc(8pt + 0.472vw)" : ""}
              />
            </div>
          </div>
          {paymentSelected.value === 2 && (
            <div className="col">
              <div className="mt-2">
                <label
                  htmlFor="payment-method"
                  className={`${FONT_SIZE} mb-2 fw-600`}
                >
                  Pilih Bank
                </label>
                <SelectOpt
                  placeholder="Pilih bank"
                  optionsValue={bankList}
                  onChange={handleBankSelected}
                  value={bankSelected}
                  fontSize={IS_MOBILE ? "calc(8pt + 0.472vw)" : ""}
                />
              </div>
            </div>
          )}
        </div>
      </div>
      <div
        className="col px-0 mt-2"
        style={{
          backgroundColor: "white",
          borderRadius: "5px",
        }}
      >
        <div className="d-flex flex-column my-2">
          <div className="col">
            <HeaderName
              text="Informasi Pembayaran"
              fontSize={`${FONT_SIZE_TITLE} fw-600`}
              color2="purple"
            />
          </div>
          <div className="col">
            {detailPayment(
              "Reschedule Fee",
              loading ? (
                <span
                  className="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
              ) : (
                priceSplit(charge?.reschedule_fee)
              ),
              "fw-700",
              true,
              true
            )}
            {detailPayment(
              "Room Charge",
              loading ? (
                <span
                  className="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
              ) : (
                priceSplit(charge?.room_charge)
              ),
              "fw-700",
              true,
              true
            )}
            <hr className="separator-blue my-2" />
            {detailPayment(
              "Total Charge",
              loading ? (
                <span
                  className="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
              ) : (
                priceSplit(charge?.total_charge)
              ),
              "fw-700",
              true,
              true
            )}
            <div className="text-center">
              {errorCharge && (
                <span className={`${FONT_SIZE} text-danger`}>
                  {errorCharge}
                </span>
              )}
            </div>
            {paymentSelected.value === 3 && (
              <hr className="separator-blue my-2" />
            )}
            {paymentSelected.value === 3 && (
              <>
                {paymentInput("Pembayaran Tunai", payment, (e) =>
                  handlePayment(e)
                )}
                {detailPayment(
                  "Kembalian",
                  priceSplit(change),
                  "text-black fw-700",
                  false
                )}
              </>
            )}
          </div>
          <div className="col">
            <hr className="separator-blue my-1" />
          </div>
        </div>
      </div>
      <div className="col">
        <div className="text-center mb-3">
          <small className={`${FONT_SIZE} fw-500`}>
            Apakah anda yakin ingin melanjutkan?
          </small>
        </div>
      </div>
      <div className="col px-0">
        <div className="d-flex justify-content-between">
          <div className="col pl-1">
            <button
              className={`btn btn-block ${FONT_SIZE} font-weight-bold btn-outline-purple`}
              onClick={close}
            >
              Back
            </button>
          </div>
          <div className="col pr-1">
            <button
              className={`btn btn-block f8-vw font-weight-bold text-white ${FONT_SIZE} ${
                errorCharge === "" &&
                ((paymentSelected?.value === 2 && bankSelected?.value) ||
                  (paymentSelected?.value === 3 &&
                    payment >= charge?.total_charge))
                  ? "btn-gradient"
                  : "btn-secondary"
              }`}
              onClick={() => confirm()}
              disabled={
                errorCharge === "" &&
                ((paymentSelected?.value === 2 && bankSelected?.value) ||
                  (paymentSelected?.value === 3 &&
                    payment >= charge?.total_charge))
                  ? false
                  : true
              }
            >
              Confirm
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
