import React, { useCallback, useContext, useEffect, useState } from "react";
import { BreadcrumbContext } from "../../../common/BreadcrumbContext";
import NotifMessage, { ButtonClose } from "../../../common/Notif";
import PopupModal from "../../../common/PopupModal";
import {
  addData,
  getData,
  getErr,
  safeErrCode,
} from "../../../utils/api-request";
import history from "../../../utils/history";

import { Icon } from "@iconify/react";
import { Fragment } from "react";
import { EmptyData, MobileEmptyData } from "../../Empty/EmptyData";
import {
  generateBookingCode,
  tableCol,
  showMoreGuest,
  splitText,
  priceSplit,
  isEmpty,
  getServerTime,
  getDayDuration,
  getClientTimeWithoutSecond,
  getTimezoneOffsetClient,
  showMore,
  filterStringValue,
} from "../../../utils";
import { PaymentProcessPopup } from "./PaymentProcessPopup";
import { PaymentProcessSuccess } from "./PaymentProcessSuccess";
import { isValidAccess, is_sub_menu_action } from "../../../utils/uac";
import { Print } from "../../../print/Print";
import {
  createLineDash,
  createLineSolid,
  genBookingId,
  genContent1,
  genContent2,
  genPaymentID,
} from "../../../print/common/printer-helper";
import { iconUrl } from "../../../config";
import { IS_MOBILE } from "../../../common/Responsive";
import { MobileCardList } from "../../../common/CardList";
import { BottomSheet } from "react-spring-bottom-sheet";
import { mobileSearchInput, searchInput } from "../../booking/bookingHelper";
import { t } from "../../../common/ErrorMessage";
import { process_type } from "../../re-printing/utils/helper";

const List = ({
  data,
  setItem,
  loading,
  errMessage,
  bookingId,
  setBookingId,
  guestFullname,
  setguestFullname,
  roomNumber,
  setRoomNumber,
  onClick,
  setMode,
  openModal,
  onKeyPress,
}) => {
  return (
    <div className="d-flex flex-column px-3 py-1">
      <div className="col px-0">
        <div className="d-flex flex-row justify-content-between align-items-center">
          <div className="col-md-8 px-0">
            <div className="d-flex flex-row">
              <div className="col px-0 mr-2">
                <label htmlFor="" className="f8-vw m-0 p-0">
                  Kode Booking
                </label>
                {searchInput(
                  "Cari Kode Booking",
                  bookingId,
                  (e) => setBookingId(generateBookingCode(e.target.value)),
                  onKeyPress
                )}
              </div>
              <div className="col px-0 mr-2">
                <label htmlFor="" className="f8-vw m-0 p-0">
                  Nama Tamu
                </label>
                {searchInput(
                  "Cari Nama Tamu",
                  guestFullname,
                  (e) => setguestFullname(e.target.value),
                  onKeyPress
                )}
              </div>
              <div className="col px-0 mr-2">
                <label htmlFor="" className="f8-vw m-0 p-0">
                  Nomor Kamar
                </label>
                {searchInput(
                  "Cari Nomor Kamar",
                  roomNumber,
                  (e) => setRoomNumber(e.target.value),
                  onKeyPress
                )}
              </div>
            </div>
          </div>
          <div className="col-md-2 pr-0 pl-5 mt-auto">
            <div className="d-flex flex-column">
              <button
                className="btn btn-sm search-new rounded-input btn-block mt-auto f8-vw fw-500 text-white"
                onClick={() => onClick()}
                style={{
                  paddingTop: 7,
                  paddingBottom: 7,
                  backgroundColor: "#742B9E",
                }}
              >
                Search
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="col px-0 mt-3">
        <div
          className="table-list table-responsive-sm scroll-table"
          style={{
            height: "25rem",
          }}
        >
          <table className="table table-sm">
            <thead className="f8-vw sticky-table">
              <tr
                className="text-center text-white"
                style={{
                  backgroundColor: "#0071A4",
                }}
              >
                <th rowSpan={2} className="align-middle">
                  Booking ID
                </th>
                <th rowSpan={2} className="align-middle">
                  <div>Room</div>
                  <div>Number</div>
                </th>
                <th rowSpan={2} className="align-middle">
                  Tamu
                </th>
                <th rowSpan={2} className="align-middle">
                  Deposit
                </th>
                <th rowSpan={1} colSpan={5} className="align-middle">
                  Tagihan
                </th>
                <th rowSpan={2} className="align-middle">
                  <div>Process</div>
                  <div>Type</div>
                </th>
                <th rowSpan={2} className="align-middle border-bottom">
                  Action
                </th>
              </tr>
              <tr
                className="text-center text-white"
                style={{ backgroundColor: "#0071A4" }}
              >
                <th>Ganti Rugi</th>
                <th>Denda</th>
                <th>Late Check Out</th>
                <th>Room Charge</th>
                <th>Total</th>
              </tr>
            </thead>
            <tbody className="f8-vw">
              {data?.length > 0 && !loading ? (
                <>
                  {data?.map((item, index) => (
                    <Fragment key={index}>
                      <tr className={`f8-vw ${tableCol(index)}`}>
                        <td className="align-middle text-center fw-700">
                          {item?.book_id}
                        </td>
                        <td className="align-middle text-center fw-700">
                          {item?.room_number}
                        </td>
                        <td className="align-middle text-center">
                          {showMoreGuest(splitText(item?.guest_name))}
                        </td>
                        <td className="align-middle text-right">
                          {priceSplit(item?.deposit)}
                        </td>
                        <td className="align-middle text-right">
                          {priceSplit(item?.ganti_rugi)}
                        </td>
                        <td className="align-middle text-right">
                          {priceSplit(item?.denda)}
                        </td>
                        <td className="align-middle text-right">
                          {priceSplit(item?.late_checkout)}
                        </td>
                        <td className="align-middle text-right">
                          {priceSplit(item?.room_charge)}
                        </td>
                        <td className="align-middle text-right">
                          {priceSplit(item?.total_charge)}
                        </td>
                        <td className="align-middle text-center">
                          {
                            filterStringValue(process_type, item?.process_type)
                              ?.label
                          }
                        </td>

                        <td className="align-middle text-center">
                          {(item?.process_type === 2 ||
                            item?.process_type === 13) &&
                            is_sub_menu_action(
                              "ch_process_payment_process"
                            ) && (
                              <button
                                className="btn btn-sm py-0 blue"
                                onClick={() => {
                                  openModal();
                                  setMode("payment-process");
                                  setItem(item);
                                }}
                              >
                                <span className="mr-1">
                                  <Icon
                                    icon={iconUrl.walletCreditCard20Filled}
                                    width={15}
                                  />
                                </span>
                                <span className="f8-vw font-weight-bold">
                                  Bayar
                                </span>
                              </button>
                            )}
                          {item?.process_type === 22 &&
                            isValidAccess([
                              "ch_process_payment_process",
                              "ch_process_payment_cancel_cr",
                            ]) && (
                              <div className="combo-box">
                                <div className="dropdown">
                                  <button
                                    className="bg-transparent dropdown-toggle border-0 blue btn-block f8-vw fw-700"
                                    type="button"
                                    id="dropdownMenu2"
                                    data-toggle="dropdown"
                                    aria-expanded="false"
                                  >
                                    Action
                                  </button>
                                  <div
                                    className="dropdown-menu dropdown-menu-right"
                                    aria-labelledby="dropdownMenu2"
                                    style={{ zIndex: 4 }}
                                  >
                                    <div className="d-flex flex-column">
                                      {is_sub_menu_action(
                                        "ch_process_payment_process"
                                      ) && (
                                        <button
                                          className="menu border-0 dark-blue bg-transparent f8-vw font-weight-bold "
                                          onClick={() => {
                                            openModal();
                                            setMode("payment-process");
                                            setItem(item);
                                          }}
                                        >
                                          <span className="mr-1">
                                            <Icon
                                              icon={
                                                iconUrl.walletCreditCard20Filled
                                              }
                                              width={15}
                                            />
                                          </span>
                                          <span className="f8-vw font-weight-bold">
                                            Bayar
                                          </span>
                                        </button>
                                      )}
                                      {is_sub_menu_action(
                                        "ch_process_payment_cancel_cr"
                                      ) && (
                                        <button
                                          className="menu border-0 dark-blue bg-transparent f8-vw font-weight-bold "
                                          onClick={() => {
                                            openModal();
                                            setMode("cancel-room");
                                            setItem(item);
                                          }}
                                        >
                                          Cancel
                                        </button>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}
                          {item?.process_type === 21 &&
                            isValidAccess([
                              "ch_process_payment_process",
                              "ch_process_payment_cancel_breakage",
                            ]) && (
                              <div className="combo-box">
                                <div className="dropdown">
                                  <button
                                    className="bg-transparent dropdown-toggle border-0 blue btn-block f8-vw fw-700"
                                    type="button"
                                    id="dropdownMenu2"
                                    data-toggle="dropdown"
                                    aria-expanded="false"
                                  >
                                    Action
                                  </button>
                                  <div
                                    className="dropdown-menu dropdown-menu-right"
                                    aria-labelledby="dropdownMenu2"
                                    style={{ zIndex: 4 }}
                                  >
                                    <div className="d-flex flex-column">
                                      {is_sub_menu_action(
                                        "ch_process_payment_process"
                                      ) && (
                                        <button
                                          className="menu border-0 dark-blue bg-transparent f8-vw font-weight-bold "
                                          onClick={() => {
                                            openModal();
                                            setMode("payment-process");
                                            setItem(item);
                                          }}
                                        >
                                          <span className="mr-1">
                                            <Icon
                                              icon={
                                                iconUrl.walletCreditCard20Filled
                                              }
                                              width={15}
                                            />
                                          </span>
                                          <span className="f8-vw font-weight-bold">
                                            Bayar
                                          </span>
                                        </button>
                                      )}
                                      {is_sub_menu_action(
                                        "ch_process_payment_cancel_breakage"
                                      ) && (
                                        <button
                                          className="menu border-0 dark-blue bg-transparent f8-vw font-weight-bold "
                                          onClick={() => {
                                            openModal();
                                            setMode("cancel-breakage");
                                            setItem(item);
                                          }}
                                        >
                                          Cancel
                                        </button>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}
                        </td>
                      </tr>
                    </Fragment>
                  ))}
                </>
              ) : (
                <tr>
                  <td colSpan={9} style={{ padding: 0 }}>
                    <EmptyData
                      loading={loading}
                      errMessage={errMessage}
                      text="Data tidak ditemukan"
                      sourceImg={"/img/booking/payment-process.svg"}
                      height="20rem"
                    />
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

const PaymentProcess = () => {
  const {
    setpathLocation,
    isOpeningShift,
    userProfile,
    openButtonSheet,
    setOpenButtonSheet,
    setShowFilter,
  } = useContext(BreadcrumbContext);
  const [mode, setMode] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [bookingId, setBookingId] = useState("");
  const [guestFullname, setguestFullname] = useState("");
  const [roomNumber, setRoomNumber] = useState("");
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [error, setError] = useState("");
  const [item, setItem] = useState({});
  const [paymentMethod, setpaymentMethod] = useState([]);
  const [bankList, setBankList] = useState([]);
  const [bankSelected, setBankSelected] = useState([]);
  const [checkedDepo, setCheckedDepo] = useState(false);
  const [payment, setPayment] = useState(0);
  const [paymentSelected, setpaymentSelected] = useState({});
  const [headerPopup, setHeaderPopup] = useState("");
  const [charge, setCharge] = useState(0);
  const [totalPaid, setTotalPaid] = useState(0);
  const [isPrint, setIsPrint] = useState(false);
  const date_now = new Date();
  const hotel_info = JSON.parse(localStorage.getItem("info_hotel"));
  const timezone = localStorage.getItem("timezone");
  const [paymentId, setpaymentId] = useState("");

  const getBillingList = () => {
    const params = {
      ...(!!bookingId && { booking_id: bookingId.replace(" ", "") }),
      ...(!!guestFullname && { guest_name: guestFullname }),
      ...(!!roomNumber && { room_number: roomNumber }),
    };

    let paramsFormatted = null;

    if (!isEmpty(params)) {
      paramsFormatted = new URLSearchParams(params);
    }

    const endpoint = `/api/v1/billing/checkouts?${
      paramsFormatted !== null ? `&${paramsFormatted}` : ""
    }`;

    setLoading(true);
    getData(endpoint)
      .then((resp) => {
        if (resp?.data?.length > 0) {
          setError(null);
          setData(resp.data);
        } else if (resp?.data?.length < 1) {
          setError("Semua billing sudah di proses");
          setData([]);
        } else {
          resp.json().then((err) => {
            setError(t(safeErrCode(err)));
          });
          setData([]);
        }
      })
      .catch((err) => setError(t(getErr(err))))
      .finally(() => setLoading(false));
  };

  const getPaymentMethod = () => {
    setLoading(true);
    getData("/api/v1/listpaymentmethod")
      .then((resp) => {
        if (resp.data) {
          let d = resp.data.map((item) => {
            return {
              value: item.payment_type,
              label: item.payment_name,
            };
          });
          setpaymentMethod(d);
        }
      })
      .catch((err) => setError(t(getErr(err))))
      .finally(() => setLoading(false));
  };

  const getPaymentType = useCallback(() => {
    if (paymentSelected?.value === 2) {
      setLoading(true);
      getData(`/api/v1/paymentype?payment_type=${paymentSelected?.value}`)
        .then((resp) => {
          if (resp.data) {
            let d = resp.data.map((item) => {
              return {
                value: item?.bank_id,
                label: `${item?.bank_name} ( ${item?.bank_accno} )`,
                bank_name: item?.bank_name,
                bank_accno: item?.bank_accno,
                bank_accholder: item?.bank_accholder,
              };
            });
            setBankList(d);
          } else {
            resp.json().then((err) => {
              setError(t(safeErrCode(err)));
            });
            setBankList([]);
          }
        })
        .catch((err) => setError(t(getErr(err))))
        .finally(() => setLoading(false));
    }
  }, [paymentSelected]);

  const cancelChangeRoom = (id) => {
    const url = "/api/v1/cancel_changeroom";
    const payload = {
      billing_id: id,
    };
    addData(url, payload)
      .then((resp) => {
        if (resp.message === "Success") {
          setMode("success-cancel");
        } else {
          resp.json().then((err) => {
            setError(t(safeErrCode(err)));
          });
          setMode("error");
          setHeaderPopup("Cancel Billing Gagal");
        }
      })
      .catch((error) => {
        setError(t(getErr(error)));
        setMode("error");
        setHeaderPopup("Cancel Billing Gagal");
      });
  };

  const cancelBreakage = (id) => {
    const url = "/api/v1/cancel_lnb";
    const payload = {
      billing_id: id,
    };
    addData(url, payload)
      .then((resp) => {
        if (resp.message === "Success") {
          setMode("success-cancel");
        } else {
          resp.json().then((err) => {
            setError(t(safeErrCode(err)));
          });
          setMode("error");
          setHeaderPopup("Cancel Billing Gagal");
        }
      })
      .catch((error) => {
        setError(t(getErr(error)));
        setMode("error");
        setHeaderPopup("Cancel Billing Gagal");
      });
  };

  const handleEnter = (e) => {
    if (e.key === "Enter") {
      getBillingList();
    }
  };

  const reset = () => {
    setMode("");
    setIsOpen(false);
    setBookingId("");
    setguestFullname("");
    setRoomNumber("");
    setLoading(false);
    setLoadingSubmit(false);
    setError("");
    setBankSelected([]);
    setCheckedDepo(false);
    setPayment(0);
    setpaymentSelected([]);
    setHeaderPopup("");
    setCharge(0);
    setTotalPaid(0);
    setItem({});
    setIsPrint(false);
    setData([]);
    setpaymentMethod([]);
    setBankList([]);
  };

  const openModal = () => {
    setIsOpen(!isOpen);
  };

  const handlePaymentProcess = () => {
    const { billing_id, booking_id, room_number, process_type } = item;

    const payload = {
      use_deposit: checkedDepo && process_type === 2 ? 1 : 0,
      room_number,
      ...(process_type === 13 && { billing_id }),
      ...(process_type !== 13 && { billing_ids: billing_id }),
      booking_id,
      paymenttype:
        checkedDepo &&
        item?.deposit >= item?.total_charge &&
        paymentSelected.value === undefined
          ? 3
          : paymentSelected.value,
      ...(paymentSelected.value === 2 && {
        bank_name: bankSelected.value ? bankSelected.bank_name : "",
        bankaccno: bankSelected.value ? bankSelected.bank_accno : "",
      }),
      payment_receive:
        paymentSelected?.value === 2 ? item?.total_charge : payment,
    };

    const url =
      process_type === 13
        ? "/api/v1/makepayment_extend"
        : "/api/v1/makepayment/checkouts";

    setLoadingSubmit(true);
    addData(url, payload)
      .then((resp) => {
        if (resp?.message === "Success") {
          setpaymentId(resp.payment_id);
          setMode("payment-process-success");
          if (resp.payment_id) {
            setIsPrint(true);
          }
        } else {
          resp.json().then((err) => {
            setError(t(safeErrCode(err)));
          });
          setMode("error");
          setHeaderPopup("Payment Process Gagal");
        }
      })
      .catch((err) => {
        setError(t(getErr(err)));
        setMode("error");
        setHeaderPopup("Payment Process Gagal");
      })
      .finally(() => setLoadingSubmit(false));
  };

  const validateDeposit = () => {
    let d = 0;
    if (checkedDepo) {
      if (item?.deposit >= item?.total_charge) {
        d = parseInt(item?.deposit) - parseInt(item?.total_charge);
        return d;
      } else {
        return d;
      }
    } else {
      return item?.deposit;
    }
  };

  const resetFilter = () => {
    setData([]);
    setBookingId("");
    setRoomNumber("");
    setguestFullname("");
    getBillingList();
  };

  useEffect(() => {
    setpathLocation("/check-out/proses-pembayaran");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getPaymentType();
  }, [getPaymentType]);

  useEffect(() => {
    if (checkedDepo) {
      if (item?.total_charge < item?.deposit) {
        setTotalPaid(0);
      } else {
        setTotalPaid(parseInt(item?.total_charge) - parseInt(item?.deposit));
      }
    } else {
      setTotalPaid(item?.total_charge);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item?.deposit, checkedDepo, item?.total_charge]);

  useEffect(() => {
    if (
      mode === "" &&
      !isOpeningShift &&
      bookingId === "" &&
      guestFullname === "" &&
      roomNumber === ""
    ) {
      getBillingList();
    } else if (mode === "payment-process" && !isOpeningShift) {
      getPaymentMethod();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mode, isOpeningShift, bookingId, guestFullname, roomNumber]);

  useEffect(() => {
    if (isPrint) {
      setTimeout(() => {
        setIsPrint(false);
      }, 6000);
    }
  }, [isPrint]);

  useEffect(() => {
    setShowFilter(true);
    return () => {
      setShowFilter(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {IS_MOBILE ? (
        <div className={`my-1 ${!isOpeningShift ? "" : "not-opening"}`}>
          <div className="d-flex flex-column px-3 py-1 mb-5">
            <div className="col px-1">
              {data?.length > 0 ? (
                data.map((item) => (
                  <MobileCardList
                    title={
                      <>
                        <div
                          className="f10-vw fw-500"
                          style={{ color: "#959595" }}
                        >
                          Kode Booking
                        </div>
                        <div>{item?.book_id}</div>
                      </>
                    }
                    // action_button={
                    //   is_sub_menu_action("ch_process_payment_process") && (
                    //     <button
                    //       className="btn btn-sm btn-gradient text-white border-0 btn-block f12-vw fw-600"
                    //       onClick={() => {
                    //         openModal();
                    //         setMode("payment-process");
                    //         setItem(item);
                    //       }}
                    //     >
                    //       <span className="mr-1">
                    //         <Icon
                    //           icon={iconUrl.walletCreditCard20Filled}
                    //           width={18}
                    //         />
                    //       </span>
                    //       <span>Bayar</span>
                    //     </button>
                    //   )
                    // }
                    action_button={
                      item.process_type === 2
                        ? is_sub_menu_action("ch_process_payment_process") && (
                            <button
                              className="btn btn-sm btn-gradient text-white border-0 btn-block f12-vw fw-600"
                              onClick={() => {
                                openModal();
                                setMode("payment-process");
                                setItem(item);
                              }}
                            >
                              <span className="mr-1">
                                <Icon
                                  icon={iconUrl.walletCreditCard20Filled}
                                  width={18}
                                />
                              </span>
                              <span>Bayar</span>
                            </button>
                          )
                        : item.process_type === 22
                        ? isValidAccess([
                            "ch_process_payment_process",
                            "ch_process_payment_cancel_cr",
                          ]) && (
                            <div className="dropdown combo-box-mobile col px-0">
                              <button
                                className="btn btn-sm btn-gradient text-white dropdown-toggle border-0 btn-block f12-vw fw-600"
                                type="button"
                                data-toggle="dropdown"
                                aria-expanded="false"
                              >
                                Action
                                <span className="ml-2">
                                  <Icon
                                    icon={iconUrl.arrowDownAlt2}
                                    width={15}
                                  />
                                </span>
                              </button>
                              <div
                                className="dropdown-menu dropdown-menu-center"
                                style={{ zIndex: 2 }}
                              >
                                {is_sub_menu_action(
                                  "ch_process_payment_process"
                                ) && (
                                  <button
                                    className="menu border-0 purple bg-transparent f12-vw font-weight-bold btn-block m-0"
                                    onClick={() => {
                                      openModal();
                                      setMode("payment-process");
                                      setItem(item);
                                    }}
                                  >
                                    <span className="mr-1">
                                      <Icon
                                        icon={iconUrl.walletCreditCard20Filled}
                                        width={18}
                                      />
                                    </span>
                                    <span>Bayar</span>
                                  </button>
                                )}
                                {is_sub_menu_action(
                                  "ch_process_payment_cancel_cr"
                                ) && (
                                  <button
                                    className="menu border-0 purple bg-transparent f12-vw font-weight-bold btn-block m-0"
                                    onClick={() => {
                                      openModal();
                                      setMode("cancel-room");
                                      setItem(item);
                                    }}
                                  >
                                    Cancel Change Room
                                  </button>
                                )}
                              </div>
                            </div>
                          )
                        : item.process_type === 13
                        ? is_sub_menu_action("ch_process_payment_process") && (
                            <button
                              className="btn btn-sm btn-gradient text-white border-0 btn-block f12-vw fw-600"
                              onClick={() => {
                                openModal();
                                setMode("payment-process");
                                setItem(item);
                              }}
                            >
                              <span className="mr-1">
                                <Icon
                                  icon={iconUrl.walletCreditCard20Filled}
                                  width={18}
                                />
                              </span>
                              <span>Bayar</span>
                            </button>
                          )
                        : isValidAccess([
                            "ch_process_payment_process",
                            "ch_process_payment_cancel_breakage",
                          ]) && (
                            <div className="dropdown combo-box-mobile col px-0">
                              <button
                                className="btn btn-sm btn-gradient text-white dropdown-toggle border-0 btn-block f12-vw fw-600"
                                type="button"
                                data-toggle="dropdown"
                                aria-expanded="false"
                              >
                                Action
                                <span className="ml-2">
                                  <Icon
                                    icon={iconUrl.arrowDownAlt2}
                                    width={15}
                                  />
                                </span>
                              </button>
                              <div
                                className="dropdown-menu dropdown-menu-center"
                                style={{ zIndex: 2 }}
                              >
                                {is_sub_menu_action(
                                  "ch_process_payment_process"
                                ) && (
                                  <button
                                    className="menu border-0 purple bg-transparent f12-vw font-weight-bold btn-block m-0"
                                    onClick={() => {
                                      openModal();
                                      setMode("payment-process");
                                      setItem(item);
                                    }}
                                  >
                                    <span className="mr-1">
                                      <Icon
                                        icon={iconUrl.walletCreditCard20Filled}
                                        width={18}
                                      />
                                    </span>
                                    <span>Bayar</span>
                                  </button>
                                )}
                                {is_sub_menu_action(
                                  "ch_process_payment_cancel_breakage"
                                ) && (
                                  <button
                                    className="menu border-0 purple bg-transparent f12-vw font-weight-bold btn-block m-0"
                                    onClick={() => {
                                      openModal();
                                      setMode("cancel-breakage");
                                      setItem(item);
                                    }}
                                  >
                                    Cancel Breakage
                                  </button>
                                )}
                              </div>
                            </div>
                          )
                    }
                    fontSizeHeader="f10-vw"
                    key={item?.billing_id}
                  >
                    <div className="card-hms-list">
                      <div className="col-4 px-0 grey-text">Nomor Kamar</div>
                      <div className="col pr-0 f12-vw black-value">
                        {item?.room_number}
                      </div>
                    </div>
                    <div className="card-hms-list">
                      <div className="col-4 px-0 grey-text">Tamu</div>
                      <div className="col pr-0 f12-vw black-value">
                        {showMore(item?.guest_name, 25)}
                      </div>
                    </div>
                    <div className="card-hms-list">
                      <div className="col-4 px-0 purple fw-700">Tagihan</div>
                    </div>
                    <div className="card-hms-list">
                      <div className="col-4 px-0 grey-text">Ganti Rugi</div>
                      <div className="col pr-0">
                        <div className="d-flex justify-content-between">
                          <span className="f12-vw black-value">Rp</span>
                          <span className="f12-vw black-value">
                            {priceSplit(item?.ganti_rugi)}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="card-hms-list">
                      <div className="col-4 px-0 grey-text">Denda</div>
                      <div className="col pr-0">
                        <div className="d-flex justify-content-between">
                          <span className="f12-vw black-value">Rp</span>
                          <span className="f12-vw black-value">
                            {priceSplit(item?.denda)}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="card-hms-list">
                      <div className="col-4 px-0 grey-text">Late Check Out</div>
                      <div className="col pr-0">
                        <div className="d-flex justify-content-between">
                          <span className="f12-vw black-value">Rp</span>
                          <span className="f12-vw black-value">
                            {priceSplit(item?.late_checkout)}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="card-hms-list">
                      <div className="col-4 px-0 grey-text">Room Charge</div>
                      <div className="col pr-0">
                        <div className="d-flex justify-content-between">
                          <span className="f12-vw black-value">Rp</span>
                          <span className="f12-vw black-value">
                            {priceSplit(item?.room_charge)}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="card-hms-list">
                      <div className="col-4 px-0 grey-text fw-700">Total</div>
                      <div className="col pr-0">
                        <div className="d-flex justify-content-between">
                          <span className="fw-700 f12-vw black-value">Rp</span>
                          <span className="fw-700 f12-vw black-value">
                            {priceSplit(item?.total_charge)}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="card-hms-list">
                      <div className="col-4 px-0 grey-text fw-700">
                        Deposit (Cash)
                      </div>
                      <div className="col pr-0">
                        <div className="d-flex justify-content-between">
                          <span className="fw-700 f12-vw black-value">Rp</span>
                          <span className="fw-700 f12-vw black-value">
                            {priceSplit(item?.deposit)}
                          </span>
                        </div>
                      </div>
                    </div>
                  </MobileCardList>
                ))
              ) : (
                <div style={{ position: "relative" }}>
                  <MobileEmptyData
                    errMessage={error}
                    loading={loading}
                    height="40vh"
                  />
                </div>
              )}
            </div>

            <div className="">
              <BottomSheet
                open={openButtonSheet}
                onDismiss={() => setOpenButtonSheet(false)}
                snapPoints={({ minHeight }) => minHeight}
                header={
                  <div className="d-flex justify-content-between">
                    <span className="purple f16-vw fw-600 px-2">Filter</span>
                    <span
                      className="px-2"
                      onClick={() => setOpenButtonSheet(false)}
                    >
                      <Icon
                        icon={iconUrl.closeBold}
                        color={"grey"}
                        width={20}
                      />
                    </span>
                  </div>
                }
                footer={
                  <div className="d-flex justify-content-between">
                    <div className="col-6 pl-2">
                      <button
                        className="btn btn-block btn-outline-purple f12-vw"
                        onClick={() => {
                          resetFilter();
                          setOpenButtonSheet(false);
                        }}
                      >
                        Reset
                      </button>
                    </div>

                    <div className="col-6 pr-2">
                      <button
                        className="btn btn-block btn-gradient f12-vw text-white"
                        onClick={() => {
                          setData([]);
                          getBillingList();
                          setOpenButtonSheet(false);
                        }}
                      >
                        Search
                      </button>
                    </div>
                  </div>
                }
              >
                <div className="mt-3" style={{ height: "17rem" }}>
                  <div className="px-4 mt-3">
                    <label htmlFor="" className="f12-vw m-0 p-0 fw-600">
                      Kode Booking
                    </label>
                    {mobileSearchInput(
                      "Masukkan Kode Booking",
                      bookingId,
                      (e) => setBookingId(generateBookingCode(e.target.value))
                    )}
                  </div>
                  <div className="px-4 mt-3">
                    <label htmlFor="" className="f12-vw m-0 p-0 fw-600">
                      Nama Tamu
                    </label>
                    {mobileSearchInput("Cari Nama Tamu", guestFullname, (e) =>
                      setguestFullname(e.target.value)
                    )}
                  </div>
                  <div className="px-4 mt-3">
                    <label htmlFor="" className="f12-vw m-0 p-0 fw-600">
                      Nomor Kamar
                    </label>
                    {mobileSearchInput("Cari Nomor Kamar", roomNumber, (e) =>
                      setRoomNumber(e.target.value)
                    )}
                  </div>
                </div>
              </BottomSheet>
            </div>
          </div>
        </div>
      ) : (
        <div className={`my-1 px-3 ${!isOpeningShift ? "" : "not-opening"}`}>
          <List
            data={data}
            setItem={setItem}
            loading={loading}
            errMessage={error}
            onClick={() => getBillingList()}
            bookingId={bookingId}
            setBookingId={setBookingId}
            guestFullname={guestFullname}
            setguestFullname={setguestFullname}
            roomNumber={roomNumber}
            setRoomNumber={setRoomNumber}
            setMode={setMode}
            openModal={openModal}
            onKeyPress={handleEnter}
          />
        </div>
      )}

      <PopupModal modalIsOpen={isOpen}>
        {mode === "payment-process" ? (
          <PaymentProcessPopup
            loading={loadingSubmit}
            detail={item}
            paymentMethod={paymentMethod}
            paymentSelected={paymentSelected}
            setpaymentSelected={setpaymentSelected}
            bankList={bankList}
            bankSelected={bankSelected}
            setBankSelected={setBankSelected}
            checkedDepo={checkedDepo}
            setCheckedDepo={setCheckedDepo}
            totalPaid={totalPaid}
            setTotalPaid={setTotalPaid}
            payment={payment}
            setPayment={setPayment}
            charge={charge}
            setCharge={setCharge}
            close={reset}
            confirm={handlePaymentProcess}
          />
        ) : mode === "payment-process-success" ? (
          <PaymentProcessSuccess
            detail={item}
            paymentSelected={paymentSelected}
            bankSelected={bankSelected}
            checkedDepo={checkedDepo}
            totalPaid={totalPaid}
            payment={payment}
            charge={charge}
            close={
              paymentSelected?.value === 2
                ? () => {
                    history.push("/checkout/payment-confirm");
                    reset();
                  }
                : () => {
                    reset();
                  }
            }
          />
        ) : mode === "cancel-room" ? (
          <>
            <NotifMessage
              closeModal={openModal}
              img={"/img/error-icon/check-out-gagal.png"}
              messageHeader={"Cancel Change Room"}
              color="black"
              messageBody={
                "Apakah anda yakin ingin cancel billing Change Room?"
              }
            />
            <div className="col px-0 py-2">
              <div className="d-flex justify-content-between">
                <div className="col">
                  <button
                    className={`btn btn-block ${
                      IS_MOBILE ? "f12-vw" : "f8-vw"
                    } font-weight-bold btn-outline-purple`}
                    onClick={openModal}
                  >
                    Back
                  </button>
                </div>
                <div className="col">
                  <button
                    className={`btn btn-block ${
                      IS_MOBILE ? "f12-vw" : "f8-vw"
                    } font-weight-bold text-white btn-gradient`}
                    onClick={() => cancelChangeRoom(item?.billing_id)}
                  >
                    {loadingSubmit && (
                      <span
                        className="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>
                    )}
                    Confirm
                  </button>
                </div>
              </div>
            </div>
          </>
        ) : mode === "success-cancel" ? (
          <>
            <NotifMessage
              closeModal={openModal}
              img={"/img/success-payment/success-icon.svg"}
              messageHeader={"Cancel Billing Success"}
              color="black"
            />
            <ButtonClose
              close={() => {
                openModal();
                setData([]);
                getBillingList();
              }}
              text="OK"
            />
          </>
        ) : mode === "cancel-breakage" ? (
          <>
            <NotifMessage
              closeModal={openModal}
              img={"/img/error-icon/check-out-gagal.png"}
              messageHeader={"Cancel Breakage"}
              color="black"
              messageBody={"Apakah anda yakin ingin cancel billing Breakage?"}
            />
            <div className="col px-0 py-2">
              <div className="d-flex justify-content-between">
                <div className="col">
                  <button
                    className={`btn btn-block ${
                      IS_MOBILE ? "f12-vw" : "f8-vw"
                    } font-weight-bold btn-outline-purple`}
                    onClick={openModal}
                  >
                    Back
                  </button>
                </div>
                <div className="col">
                  <button
                    className={`btn btn-block ${
                      IS_MOBILE ? "f12-vw" : "f8-vw"
                    } font-weight-bold text-white btn-gradient`}
                    onClick={() => cancelBreakage(item?.billing_id)}
                  >
                    {loadingSubmit && (
                      <span
                        className="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>
                    )}
                    Confirm
                  </button>
                </div>
              </div>
            </div>
          </>
        ) : (
          <>
            <NotifMessage
              closeModal={openModal}
              img={"/img/error-icon/check-out-gagal.png"}
              messageHeader={headerPopup}
              color="red"
              messageBody={error}
            />
            <ButtonClose
              close={() => {
                openModal();
                setData([]);
                getBillingList();
              }}
              text="OK"
            />
          </>
        )}
      </PopupModal>

      <div style={{ display: "none" }}>
        {!isEmpty(item) && (
          <Print launch_print={isPrint}>
            {paymentSelected?.value === 3 ||
            (checkedDepo && totalPaid === 0) ? (
              <p className="p-print txt-print-h1">SLIP Pembayaran</p>
            ) : (
              <p className="p-print txt-print-h1">Billing</p>
            )}
            {/* <p className="p-print txt-print-h2">
              Payment ID {"\t"}: {paymentId}
            </p> */}
            {genPaymentID(paymentId)}
            {item.process_type === 21 && (
              <p className="p-print txt-print-h2">Breakage</p>
            )}
            {item.process_type === 22 && (
              <p className="p-print txt-print-h2">Change Room</p>
            )}
            {createLineDash()}
            <p className="p-print txt-print-h3">{`${getServerTime(
              date_now,
              timezone
            )} - ${getClientTimeWithoutSecond(
              date_now,
              timezone
            )} ${getTimezoneOffsetClient(timezone)}`}</p>
            <p className="p-print txt-print-h3">{hotel_info?.hotel_name}</p>
            {createLineDash()}
            <table className="table-print">
              <tbody>{genContent1("Kasir", userProfile?.nama)}</tbody>
            </table>
            {genBookingId("Booking ID", item?.book_id)}
            {createLineDash()}

            <p className="p-print txt-print-section-h2">Informasi Booking</p>
            <table className="table-print">
              <tbody>
                {genContent1("Tipe Kamar", item?.room_type_name)}
                {genContent1(
                  "Durasi",
                  getDayDuration(item?.checkin_date, item?.checkout_date) +
                    " Malam"
                )}
              </tbody>
            </table>
            {createLineDash()}
            <table className="table-print">
              <tbody>
                {genContent1(
                  "Metode Pembayaran",
                  checkedDepo && totalPaid === 0
                    ? "Cash"
                    : paymentSelected?.label
                )}
                {paymentSelected?.value === 2 &&
                  genContent1("Nama Bank", bankSelected?.label)}
              </tbody>
            </table>
            {createLineDash()}
            {paymentSelected?.value === 3 ? (
              <p className="p-print txt-print-section-h2">
                Informasi Pembayaran
              </p>
            ) : (
              <p className="p-print txt-print-section-h2">Informasi Tagihan</p>
            )}
            {item?.process_type === 2 ? (
              <table className="table-print">
                <tbody>
                  {genContent2("Late Check Out", item?.late_checkout)}
                  {genContent2("Ganti Rugi", item?.ganti_rugi)}
                  {genContent2("Denda", item?.denda)}
                  {genContent2(
                    "Deposit",
                    checkedDepo && item?.deposit > 0
                      ? "-" + priceSplit(item?.deposit)
                      : 0
                  )}
                </tbody>
              </table>
            ) : (
              <table className="table-print">
                <tbody>
                  {genContent2("Additional Charge", item?.total_charge)}
                </tbody>
              </table>
            )}

            {createLineSolid()}
            <table className="table-print">
              <tbody>{genContent2("Total", totalPaid)}</tbody>
            </table>

            {paymentSelected?.value === 3 && (
              <>
                {createLineSolid()}
                <table className="table-print">
                  <tbody>
                    {genContent2("Pembayaran Tunai", payment)}

                    {item?.process_type === 2 ? (
                      <>
                        {genContent2(
                          "Kembalian",
                          checkedDepo ? charge : charge - item?.deposit
                        )}
                      </>
                    ) : (
                      <>{genContent2("Kembalian", charge)}</>
                    )}
                  </tbody>
                </table>
              </>
            )}
            {item?.process_type === 2 && (
              <>
                {createLineSolid()}
                <table className="table-print">
                  <tbody>
                    {genContent2("Sisa Deposit", validateDeposit())}
                  </tbody>
                </table>
              </>
            )}
          </Print>
        )}
      </div>
    </>
  );
};

export default PaymentProcess;
