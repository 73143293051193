import ReactTooltip from "react-tooltip";

const statusSetoran = [
  { value: 0, label: "New", className: "orange" },
  { value: 1, label: "Confirm", className: "blue" },
  { value: 2, label: "Approve", className: "green" },
  { value: 3, label: "Rejected", className: "red" },
];

export const filterStatusSetoran = (status) => {
  return statusSetoran?.find((item) => item.value === status);
};

export const showMore = (note, length = 50) => {
  let d = note;
  let text = d?.length > length ? d.substring(0, length) + "..." : d;
  if (d?.length > length) {
    return (
      <>
        <button
          type="button"
          className="text-black border-0 text-left p-0"
          style={{ background: "transparent" }}
          data-tip={d}
        >
          {text}
        </button>
        <ReactTooltip
          backgroundColor="white"
          textColor="black"
          className="note-more text-wrap text-justify"
          border
        />
      </>
    );
  } else {
    return d;
  }
};

export const filterPaymentMethod = (id) => {
  let payment_method =
    localStorage?.getItem("payment_method") &&
    JSON.parse(localStorage?.getItem("payment_method"));

  return payment_method?.find((item) => item.value === id);
};
export const filterBankList = (id) => {
  const bank_list =
    localStorage?.getItem("bank_list") &&
    JSON.parse(localStorage?.getItem("bank_list"));

  return bank_list?.find((item) => item.value === id);
};

export const filterStatusApproval = (id) => {
  let statusApproval = [
    { value: 1, label: "Need Confirmation" },
    { value: 2, label: "Approved" },
    { value: 3, label: "Rejected" },
  ];
  return statusApproval?.find((item) => item.value === id);
};
