import React from "react";
import EmptyResponse from "./EmptyRespons";
import { getErr, publicPost, safeErrCode } from "../utils/api-request";
import { config } from "../config";

export const ErrorFallback = ({ error, resetErrorBoundary }) => {
  console.error(error);
  return (
    <div className="container">
      <div className="row no-gutters mx-auto rn-padding-page">
        <div className="col-12 my-auto">
          <EmptyResponse
            src="/img/faq/assistance2.png"
            text={"Halaman ini sedang dalam perbaikan"}
            text2={"Silahkan coba beberapa saat lagi"}
            fontSize="f12-vw"
            fontSize2="f10-vw"
          />
        </div>
      </div>
    </div>
  );
};

export const myErrorHandler = async (error, info) => {
  const currentURL = window.location.href;
  const pathname = window.location.pathname;

  console.error("error : ", error?.message);
  console.log("info : ", info?.componentStack);
  console.log("currentURL :", currentURL);
  console.log("pathname :", pathname);

  const errorReport = (payload) => {
    const url = `${config.base_url}/public/v1/error/report`;
    fetch(url, publicPost(payload))
      .then((resp) => {
        if (resp.status >= 200 && resp.status <= 299) {
          resp.json().then((response) => {
            console.log(response);
          });
        } else {
          resp.json().then((err) => {
            console.error(safeErrCode(err));
          });
        }
      })
      .catch((error) => {
        let errMessage = getErr(error);
        console.error(errMessage);
      });
  };

  if (process.env.REACT_APP_BUILD === "production") {
    try {
      if (!!currentURL && !!error?.message) {
        const payload = {
          url_origin: currentURL,
          error_message: error?.message?.substring(0, 255),
        };

        const res = await errorReport(payload);
        console.log("res :", res);
      }
    } catch (error) {
      console.error(error?.message);
    }
  }
};
