import { Icon } from "@iconify/react";
import React from "react";
import { IS_MOBILE } from "../../../common/Responsive";
import { iconUrl } from "../../../config";

const FONT_SIZE = IS_MOBILE ? "f12-vw" : "f8-vw";

export const InputCounter = ({ counter = 1, setCount, onChange }) => {
  const operationCount = (param) => {
    switch (param) {
      case "minus":
        setCount(counter - 1);

        break;
      case "plus":
        setCount(counter + 1);

        break;

      default:
        break;
    }
  };
  const validation = () => {
    let d = counter > 1 ? true : false;
    return d;
  };
  return (
    <div className="d-flex justify-content-between">
      <div
        className={`col p-0 ${
          counter > 1 ? "btn-count-active" : "btn-count-disable"
        }`}
        style={{ borderRadius: "3px 0 0 3px", cursor: "pointer" }}
      >
        <button
          className="btn btn-sm btn-block"
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            return validation(counter) ? operationCount("minus") : null;
          }}
        >
          <Icon icon={iconUrl.minusIcon} color="white" width={15} />
        </button>
      </div>
      <div className="col-9 p-0">
        <input
          type="text"
          value={counter}
          className={"form-control text-center py-0 " + FONT_SIZE}
          style={{
            borderTop: "1px solid #9E9E9E",
            borderBottom: "1px solid #9E9E9E",
            borderLeft: 0,
            borderRight: 0,
            borderRadius: 0,
          }}
          onChange={(e) => onChange(e)}
        />
      </div>
      <div
        className={`col p-0 ${
          counter >= 0 ? "btn-count-active" : "btn-count-disable"
        }`}
        style={{ borderRadius: "0 3px 3px 0", cursor: "pointer" }}
      >
        <button
          className="btn btn-sm btn-block"
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            operationCount("plus");
          }}
        >
          <Icon icon={iconUrl.plusIcon} color="white" width={15} />
        </button>
      </div>
    </div>
  );
};
