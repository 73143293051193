export const housekeepingStatus = [
  {
    value: 0,
    label: "NFC",
    title: "Need for cleaning",
    className: "status-nfc",
  },
  {
    value: 1,
    label: "COP",
    title: "Cleaning on Progress",
    className: "status-cop",
  },
  {
    value: 3,
    label: "RJK",
    title: "Inspection Rejected",
    className: "status-rjk",
  },
  {
    value: 4,
    label: "CDN",
    title: "Inspection Approved",
    className: "status-cdn",
  },
  {
    value: 20,
    label: "NFI",
    title: "Need for Inspection",
    className: "status-nfi",
  },
];

export const filterHousekeepingStatus = (id) => {
  return housekeepingStatus.find((item) => item.value === id);
};

export const seeMore = (text) => {
  let subText = text?.length > 100 ? text.substring(0, 100) + "..." : text;
  return subText;
};
