import React from "react";
import { filterStatusRoomCustom, tableCol } from "../../utils";
import { EmptyData } from "../Empty/EmptyData";

export const RoomStockTable = ({ list, loading, errorMessage }) => {
  return (
    <div
      className="table-list table-responsive-sm scroll-table table-report table-border-line px-1"
      style={{
        height: "24rem",
      }}
    >
      <table className="table table-sm">
        <thead className="f8-vw sticky-table">
          <tr
            className="text-black text-center text-white"
            style={{
              backgroundColor: "#0071A4",
            }}
          >
            <th className="align-middle py-2">
              <div>Room</div>
              <div>Number</div>
            </th>
            <th className="align-middle py-2">Floor</th>
            <th className="align-middle py-2">Room Type</th>
            <th className="align-middle py-2">Bed Type ( Bed Size )</th>
            <th className="align-middle py-2">Status</th>
          </tr>
        </thead>

        <tbody className="f8-vw">
          {list?.length > 0 && !loading ? (
            <>
              {list?.map((item, index) => (
                <tr key={index} className={` ${tableCol(index)}`}>
                  <td className="text-center align-middle fw-700">
                    {item?.room_number}
                  </td>
                  <td className="text-center align-middle fw-700">
                    {item?.floor}
                  </td>
                  <td className="text-center align-middle">
                    {item?.room_type_name}
                  </td>
                  <td className="text-center align-middle">
                    <span className="fw-700">{item?.bed_type_name}</span>
                    <span className="mx-1 fw-500">({item?.bed_size})</span>
                  </td>
                  <td className="text-center align-middle">
                    <div
                      className={`mx-auto ${
                        filterStatusRoomCustom(item?.status)?.className
                      }`}
                      style={{
                        borderRadius: 3,
                        width: 35,
                        fontWeight: "bold",
                      }}
                    >
                      {filterStatusRoomCustom(item?.status)?.label}
                    </div>
                  </td>
                </tr>
              ))}
            </>
          ) : (
            <>
              <tr>
                <td className="p-0" colSpan={5}>
                  <EmptyData
                    loading={loading}
                    errMessage={errorMessage}
                    height="19rem"
                  />
                </td>
              </tr>
            </>
          )}
        </tbody>
      </table>
    </div>
  );
};
