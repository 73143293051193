import { Icon } from "@iconify/react";
import React from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { Loading } from "../../common/Loading";
import { IS_MOBILE } from "../../common/Responsive";
import { iconUrl } from "../../config";
import { priceSplit, tableCol } from "../../utils";
import { EmptyData } from "../Empty/EmptyData";

const FONT_SIZE = IS_MOBILE ? "f12-vw" : "f8-vw";

export const ModalDetail = ({
  itemList,
  close,
  total,
  checkNextPage,
  hasMore,
  loadingList,
  error,
}) => {
  return (
    <div className="p-2" style={{ width: IS_MOBILE ? "21rem" : "25rem" }}>
      <div className="d-flex align-items-center">
        <span className="mr-2">
          <Icon
            icon={iconUrl.informationIcon}
            color="orange"
            width={"1.5rem"}
          />
        </span>
        <span className={`purple fw-700 ${IS_MOBILE ? "f16-vw" : "f12-vw"}`}>
          Breakage Detail
        </span>
      </div>
      <div className="">
        <InfiniteScroll
          dataLength={itemList?.length}
          next={checkNextPage}
          hasMore={hasMore}
          loader={<Loading />}
          endMessage={
            itemList?.length < 1 && (
              <EmptyData loading={loadingList} errMessage={error} />
            )
          }
          height="15rem"
          className={`scroll-table px-0 my-3 ${
            IS_MOBILE ? "hide-scrollbar" : ""
          }`}
        >
          <div className="table-list">
            <table
              className="table table-sm m-0"
              style={{
                borderCollapse: "collapse",
                borderSpacing: 0,
              }}
            >
              <thead className={`sticky-table ${FONT_SIZE}`}>
                <tr
                  className="text-center text-white"
                  style={{ backgroundColor: "#0071A4" }}
                >
                  <th className="align-middle">Breakage Item</th>
                  <th className="align-middle">Jumlah</th>
                  <th className="align-middle">Harga Satuan</th>

                  <th className="align-middle">Charge</th>
                </tr>
              </thead>

              <tbody className={"border " + FONT_SIZE}>
                {itemList?.map((item, i) => {
                  return (
                    <tr className={tableCol(i)} key={i}>
                      <td className="align-middle">{item.item_name}</td>
                      <td className="align-middle text-center">
                        {item.com_qty}
                      </td>
                      <td className="align-middle text-right font-weight-bold">
                        {priceSplit(item.item_value)}
                      </td>
                      <td className="align-middle text-right font-weight-bold">
                        {priceSplit(item.total_compen)}
                      </td>
                    </tr>
                  );
                })}
              </tbody>

              <tfoot
                className={FONT_SIZE}
                style={{
                  position: "sticky",
                  backgroundColor: "white",
                  insetBlockEnd: 0,
                  boxShadow: "0 2px 0 #0071A4 inset, 0 -2px 0 #0071A4 inset",
                }}
              >
                <tr
                  className="text-black text-center"
                  style={{
                    backgroundColor: "#7FC3FF40",
                  }}
                >
                  <th className="align-middle text-left text-black" colSpan={3}>
                    Total Charge
                  </th>
                  <th className="align-middle text-right">
                    {priceSplit(total)}
                  </th>
                </tr>
              </tfoot>
            </table>
          </div>
        </InfiniteScroll>
      </div>
      <div className="d-flex justify-content-end mt-2">
        <div className="col-4 px-0">
          <button
            className={
              "btn btn-sm btn-block text-white btn-gradient " + FONT_SIZE
            }
            onClick={() => close()}
          >
            Close
          </button>
        </div>
      </div>
    </div>
  );
};
