import circleX from "@iconify/icons-akar-icons/circle-x";
import cameraAdd48Filled from "@iconify/icons-fluent/camera-add-48-filled";
import timesIcon from "@iconify/icons-uil/times";
import arrowRightAlt2 from "@iconify/icons-dashicons/arrow-right-alt2";
import arrowUpAlt2 from "@iconify/icons-dashicons/arrow-up-alt2";
import calendarIcon from "@iconify/icons-bx/calendar";
import userCheck from "@iconify/icons-fa-solid/user-check";
import plusCircleOutlined from "@iconify/icons-ant-design/plus-circle-outlined";
import minusCircleOutlined from "@iconify/icons-ant-design/minus-circle-outlined";
import minusIcon from "@iconify/icons-heroicons-solid/minus";
import plusIcon from "@iconify/icons-heroicons-solid/plus";
import searchIcon from "@iconify/icons-fa-solid/search";
import checkLg from "@iconify/icons-bi/check-lg";
import circleAlert from "@iconify/icons-akar-icons/circle-alert";
import eyeIcon from "@iconify/icons-bxs/show";
import eyeClosed from "@iconify/icons-bxs/hide";
import dotMark from "@iconify/icons-carbon/dot-mark";
import chevronBack from "@iconify/icons-ion/chevron-back";
import homeFilled from "@iconify/icons-ant-design/home-filled";
import logoutIcon from "@iconify/icons-mdi/logout";
import infoCircle from "@iconify/icons-bi/info-circle";
import starFill from "@iconify/icons-bi/star-fill";
import closeBold from "@iconify/icons-ep/close-bold";
import closeCircleFilled from "@iconify/icons-ant-design/close-circle-filled";
import whatsappIcon from "@iconify/icons-arcticons/whatsapp";
import envelopeIcon from "@iconify/icons-akar-icons/envelope";
import folderPlus from "@iconify/icons-bxs/folder-plus";
import reservasiIcon from "@iconify/icons-codicon/sign-in";
import checkoutIcon from "@iconify/icons-codicon/sign-out";
import lostnfoundIcon from "@iconify/icons-fluent/box-search-24-regular";
import shiftIcon from "@iconify/icons-mdi/calendar-clock";
import housekeepingIcon from "@iconify/icons-mdi/image-auto-adjust";
import masterdataIcon from "@iconify/icons-fluent/calendar-settings-16-regular";
import reportIcon from "@iconify/icons-fluent/clipboard-text-edit-32-regular";
import rightArrow from "@iconify/icons-bxs/right-arrow";
import clockIcon from "@iconify/icons-akar-icons/clock";
import clockCircleFilled from "@iconify/icons-ant-design/clock-circle-filled";
import houseUser from "@iconify/icons-fa6-solid/house-user";
import clockAlarm from "@iconify/icons-charm/clock-alarm";
import personWalkingLuggage from "@iconify/icons-fa6-solid/person-walking-luggage";
import touch1Filled from "@iconify/icons-carbon/touch-1-filled";
import receiptMoney20Filled from "@iconify/icons-fluent/receipt-money-20-filled";
import doorOpen from "@iconify/icons-bxs/door-open";
import walletCreditCard20Filled from "@iconify/icons-fluent/wallet-credit-card-20-filled";
import formMultiple20Filled from "@iconify/icons-fluent/form-multiple-20-filled";
import emailOutline from "@iconify/icons-eva/email-outline";
import lockAlt from "@iconify/icons-bx/lock-alt";
import editIcon from "@iconify/icons-bxs/edit";
import menuHamburger from "@iconify/icons-charm/menu-hamburger";
import fastArrowBottom from "@iconify/icons-iconoir/fast-arrow-bottom";
import fastArrowTop from "@iconify/icons-iconoir/fast-arrow-top";
import arrowDownAlt2 from "@iconify/icons-dashicons/arrow-down-alt2";
import filter20Filled from "@iconify/icons-fluent/filter-20-filled";
import checkIcon from "@iconify/icons-bi/check";
import disabledIcon from "@iconify/icons-fe/disabled";
import print20Filled from "@iconify/icons-fluent/print-20-filled";
import cleanIcon from "@iconify/icons-carbon/clean";
import shatteredGlass from "@iconify/icons-game-icons/shattered-glass";
import exchangeFour from "@iconify/icons-icon-park-solid/exchange-four";
import clipboardTextSearch from "@iconify/icons-mdi/clipboard-text-search";
import clearOutlined from "@iconify/icons-ant-design/clear-outlined";
import personArrowBack24Filled from "@iconify/icons-fluent/person-arrow-back-24-filled";
import archiveArrowBack24Regular from "@iconify/icons-fluent/archive-arrow-back-24-regular";
import timerLockOpen from "@iconify/icons-mdi/timer-lock-open";
import timerLock from "@iconify/icons-mdi/timer-lock";
import libraryAddCheck from "@iconify/icons-material-symbols/library-add-check";
import clockPlay from "@iconify/icons-tabler/clock-play";
import questionMarkCircle20Solid from "@iconify/icons-heroicons/question-mark-circle-20-solid";
import informationIcon from "@iconify/icons-pajamas/information";
import slideSearch24Filled from "@iconify/icons-fluent/slide-search-24-filled";
import textBulletListSquareEdit24Filled from "@iconify/icons-fluent/text-bullet-list-square-edit-24-filled";

export const config = {
  version: process.env.REACT_APP_APPS_VERSION,
  base_url: process.env.REACT_APP_API_URL,
  img_host: process.env.REACT_APP_IMG_HOST,
};

export const iconUrl = {
  circleX,
  cameraAdd48Filled,
  timesIcon,
  calendarIcon,
  arrowRightAlt2,
  arrowUpAlt2,
  userCheck,
  plusCircleOutlined,
  minusCircleOutlined,
  minusIcon,
  plusIcon,
  searchIcon,
  checkLg,
  circleAlert,
  eyeIcon,
  eyeClosed,
  dotMark,
  chevronBack,
  homeFilled,
  logoutIcon,
  infoCircle,
  starFill,
  closeBold,
  closeCircleFilled,
  whatsappIcon,
  envelopeIcon,
  folderPlus,
  reservasiIcon,
  checkoutIcon,
  lostnfoundIcon,
  shiftIcon,
  housekeepingIcon,
  masterdataIcon,
  reportIcon,
  rightArrow,
  clockIcon,
  clockCircleFilled,
  houseUser,
  clockAlarm,
  personWalkingLuggage,
  touch1Filled,
  receiptMoney20Filled,
  doorOpen,
  walletCreditCard20Filled,
  formMultiple20Filled,
  emailOutline,
  lockAlt,
  editIcon,
  menuHamburger,
  fastArrowBottom,
  fastArrowTop,
  arrowDownAlt2,
  filter20Filled,
  checkIcon,
  disabledIcon,
  print20Filled,
  cleanIcon,
  shatteredGlass,
  exchangeFour,
  clipboardTextSearch,
  clearOutlined,
  personArrowBack24Filled,
  archiveArrowBack24Regular,
  timerLockOpen,
  timerLock,
  libraryAddCheck,
  clockPlay,
  questionMarkCircle20Solid,
  informationIcon,
  slideSearch24Filled,
  textBulletListSquareEdit24Filled,
};
