import React, { useCallback, useContext, useEffect, useState } from "react";
import { useLocation } from "react-router";
import { BreadcrumbContext } from "../../common/BreadcrumbContext";
import { MobileCardList } from "../../common/CardList";
import { t } from "../../common/ErrorMessage";
import { IS_MOBILE } from "../../common/Responsive";
import { getTimezoneOffsetClient, tableCol } from "../../utils";
import { getData, getErr, safeErrCode } from "../../utils/api-request";
import { listTable } from "../../utils/helper";
import { MobileEmptyData } from "../Empty/EmptyData";

function ShiftManagement() {
  const [loading, setLoading] = useState(false);
  const [shiftInfo, setShiftInfo] = useState([]);
  const { setpathLocation } = useContext(BreadcrumbContext);
  const location = useLocation();
  const [error, setError] = useState(null);
  const tableHeader = ["No", "Nama Shift", "Jam Mulai", "Jam Berakhir"];
  const timezone =
    localStorage.getItem("timezone") && localStorage.getItem("timezone");

  const showData = useCallback(() => {
    setLoading(true);
    getData("/api/v1/shift_management")
      .then((resp) => {
        setLoading(false);
        if (resp.data) {
          setShiftInfo(resp.data);
          setError(null);
        } else {
          resp.json().then((err) => {
            setError(t(safeErrCode(err)));
          });
        }
      })
      .catch((error) => {
        console.error(error);
        let err = getErr(error);
        setLoading(false);
        setError(t(err));
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const trTable = (item, i) => {
    return (
      <tbody className="f8-vw" key={i}>
        <tr className={tableCol(i)}>
          <td className="text-center align-middle">{i + 1}</td>
          <td className="align-middle">{item?.shift_name}</td>
          <td className="text-center align-middle">
            {item?.start_hour + " " + getTimezoneOffsetClient(timezone)}
          </td>
          <td className="text-center align-middle">
            {item?.end_hour + " " + getTimezoneOffsetClient(timezone)}
          </td>
        </tr>
      </tbody>
    );
  };

  useEffect(() => {
    setpathLocation(location.pathname);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    showData();
    return () => {
      setShiftInfo({});
    };
  }, [showData]);

  return (
    <>
      {IS_MOBILE ? (
        <div className="d-flex flex-column px-3 py-1 mb-5">
          <div className="col px-1">
            {shiftInfo?.length > 0 ? (
              shiftInfo.map((item, i) => {
                return (
                  <MobileCardList title={item?.shift_name} key={i}>
                    <div className="card-hms-list">
                      <div className="col-4 px-0 grey-text">No</div>
                      <div className="col pr-0 f12-vw black-value">{i + 1}</div>
                    </div>
                    <div className="card-hms-list">
                      <div className="col-4 px-0 grey-text">Jam Mulai</div>
                      <div className="col pr-0 f12-vw black-value">
                        {item?.start_hour +
                          " " +
                          getTimezoneOffsetClient(timezone)}
                      </div>
                    </div>
                    <div className="card-hms-list">
                      <div className="col-4 px-0 grey-text">Jam Berakhir</div>
                      <div className="col pr-0 f12-vw black-value">
                        {item?.end_hour +
                          " " +
                          getTimezoneOffsetClient(timezone)}
                      </div>
                    </div>
                  </MobileCardList>
                );
              })
            ) : (
              <div style={{ position: "relative" }}>
                <MobileEmptyData
                  errMessage={error}
                  loading={loading}
                  height="40vh"
                  sourceImg={"/img/master-data/shift-management.svg"}
                />
              </div>
            )}
          </div>
        </div>
      ) : (
        <div className="d-flex flex-column px-4 py-1">
          <div className="col px-2">
            <div className="my-3">
              {listTable(
                tableHeader,
                shiftInfo,
                trTable,
                loading,
                error,
                "/img/master-data/shift-management.svg"
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default ShiftManagement;
