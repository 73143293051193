import { Icon } from "@iconify/react";
import React, {
  Fragment,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import ReactDatePicker from "react-datepicker";
import { BreadcrumbContext } from "../../common/BreadcrumbContext";
import CameraWebcam from "../../common/CameraWebcam";
import { MobileCardList } from "../../common/CardList";
import { t } from "../../common/ErrorMessage";
import { MobileUploadFile } from "../../common/MobileUploadFile";
import NotifMessage, { ButtonClose } from "../../common/Notif";
import PopupModal from "../../common/PopupModal";
import { IS_MOBILE } from "../../common/Responsive";
import SelectOpt from "../../common/SelectOpt";
import { iconUrl } from "../../config";
import {
  dateFormat,
  formatDate,
  getClientTimeWithoutSecond,
  getServerTimeWithoutDays,
  getTimezoneOffsetClient,
  priceSplit,
  tableCol,
} from "../../utils";
import {
  addDataPict,
  getData,
  getErr,
  safeErrCode,
} from "../../utils/api-request";
import { is_sub_menu_action } from "../../utils/uac";
import { EmptyData, MobileEmptyData } from "../Empty/EmptyData";
import { filterStatusSetoran, showMore } from "./common/utils";

const FONT_SIZE = IS_MOBILE ? "f12-vw" : "f8-vw";
const FONT_SIZE_HEADER = IS_MOBILE ? "f16-vw" : "f12-vw";
const TIMEZONE =
  localStorage?.getItem("timezone") && localStorage?.getItem("timezone");

const TableSetoranConfirm = ({
  height,
  list,
  loading,
  errorMessage,
  isOpen,
  setMode,
  setDetailSelected,
}) => {
  const timezone =
    localStorage?.getItem("timezone") && localStorage?.getItem("timezone");

  const renderItem = () => {
    return (
      <Fragment>
        {list.map((item, key) => {
          return (
            <tr key={key} className={tableCol(key)}>
              <td className="text-center align-middle fw-700">
                {item?.wsetoran_id}
              </td>
              <td className="text-center align-middle">
                {item?.created_at ? (
                  <>
                    <div>
                      {getServerTimeWithoutDays(item?.created_at, timezone)}
                    </div>
                    <div className="fw-700">
                      {getClientTimeWithoutSecond(item?.created_at, timezone) +
                        " " +
                        getTimezoneOffsetClient(timezone)}
                    </div>
                  </>
                ) : (
                  "-"
                )}
              </td>
              <td className="text-center align-middle">{item?.full_name}</td>
              <td className="text-right align-middle">
                {priceSplit(item?.total_setoran)}
              </td>
              <td
                className={`${
                  item?.note ? "text-left" : "text-center"
                } align-middle text-wrap`}
              >
                {item?.note ? (
                  <div style={{ width: "fit-content" }}>
                    {showMore(item?.note)}
                  </div>
                ) : (
                  "-"
                )}
              </td>
              <td
                className={
                  "text-center align-middle " +
                  filterStatusSetoran(item?.status)?.className
                }
              >
                {filterStatusSetoran(item?.status)?.label}
              </td>
              <td
                className="text-center align-middle"
                style={{ backgroundColor: "white" }}
              >
                {item?.status !== 2 &&
                  is_sub_menu_action("sf_setoran_confirm_setoran") && (
                    <button
                      className="btn btn-sm py-1 f8-vw blue fw-700"
                      onClick={() => {
                        setMode("confirm-setoran");
                        isOpen();
                        setDetailSelected(item);
                      }}
                    >
                      Confirm
                    </button>
                  )}
              </td>
            </tr>
          );
        })}
      </Fragment>
    );
  };

  return (
    <div className="d-flex flex-column">
      <div
        className="table-list table-responsive-sm scroll-table"
        style={{
          height: height ? height : "350px",
          overflowX: "hidden",
        }}
      >
        <table
          className="table table-sm m-0"
          style={{ borderCollapse: "collapse", borderSpacing: 0 }}
        >
          <thead className="f8-vw sticky-table">
            <tr
              className="text-center text-white"
              style={{
                backgroundColor: "#0071A4",
              }}
            >
              <th className="py-3 align-middle">ID Setoran</th>
              <th className="py-3 align-middle">Tanggal Setoran</th>
              <th className="py-3 align-middle">Disetorkan oleh</th>
              <th className="py-3 align-middle">Total Setoran</th>
              <th className="py-3 align-middle" style={{ width: "13rem" }}>
                Note
              </th>
              <th className="py-3 align-middle">Status</th>
              <th className="py-3 align-middle">Action</th>
            </tr>
          </thead>

          <tbody className="f8-vw">
            {list?.length > 0 && !loading ? (
              renderItem()
            ) : (
              <>
                <tr>
                  <td colSpan={12} className="p-0">
                    <EmptyData
                      loading={loading}
                      errMessage={errorMessage}
                      sourceImg={"/img/setoran/confirm-setoran.svg"}
                      text={"Semua setoran sudah dikonfirmasi"}
                      height={"21rem"}
                    />
                  </td>
                </tr>
              </>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

const PopupConfirmSetoran = ({
  detail,
  paymentMethod,
  paymentSelected = { value: 3 },
  bankList,
  bankSelected,
  receiver,
  setReceiver,
  setPaymentSelected,
  setBankSelected,
  setfileUpload,
  close,
  confirm,
  dateTransfer,
  setDateTransfer,
  fileUpload,
  setShowImg,
  showImg,
}) => {
  const handlePaymentMethod = (val) => {
    setPaymentSelected(val);
    setBankSelected([]);
    setReceiver("");
  };
  const handleBankSelected = (val) => {
    setBankSelected(val);
  };
  const handleDate = (val) => {
    setDateTransfer(val);
  };

  const validateButtonSubmit = () => {
    let d =
      (paymentSelected?.value === 3 &&
        fileUpload?.length > 0 &&
        receiver !== "") ||
      (paymentSelected?.value === 2 &&
        bankSelected?.value !== undefined &&
        fileUpload?.length > 0)
        ? true
        : false;
    return d;
  };

  const handleCalendarOpen = () => {
    document.addEventListener(
      "touchstart",
      (event) => {
        event.stopPropagation();
      },
      true
    );
  };
  return (
    <div className="d-flex flex-column py-3 px-2" style={{ width: "21rem" }}>
      <div className="col">
        <div className="d-flex align-items-center">
          <span className="mr-1">
            <Icon
              icon={iconUrl.walletCreditCard20Filled}
              color="orange"
              width={25}
            />
          </span>
          <span className={"dark-blue fw-700 " + FONT_SIZE_HEADER}>
            Konfirmasi Setoran
          </span>
        </div>
      </div>
      <div className="col">
        <div
          className="d-flex flex-column text-center p-3 my-2"
          style={{ backgroundColor: "#E8FEEA", borderRadius: 3 }}
        >
          <span className={FONT_SIZE}>Total Setoran</span>
          <span className={`${FONT_SIZE_HEADER} fw-700`}>
            Rp {priceSplit(detail?.total_setoran ? detail?.total_setoran : 0)}
          </span>
        </div>
      </div>

      <div
        className={`px-3 scroll-popup ${IS_MOBILE ? "hide-scrollbar" : ""}`}
        style={{ maxHeight: IS_MOBILE ? "25rem" : "" }}
      >
        <div className="form-group mb-1">
          <label htmlFor="payment-method" className={`${FONT_SIZE} mb-1`}>
            Metode Pembayaran
          </label>
          <SelectOpt
            placeholder="Pilih metode pembayaran"
            optionsValue={paymentMethod}
            onChange={handlePaymentMethod}
            value={paymentSelected}
            fontSize={IS_MOBILE ? "calc(8pt + 0.472vw)" : ""}
          />
        </div>
        {paymentSelected?.value === 2 && (
          <>
            <div className="form-group mb-1">
              <label htmlFor="payment-method" className={`${FONT_SIZE} mb-1`}>
                Pilih Bank
              </label>
              <SelectOpt
                placeholder="Nama bank"
                optionsValue={bankList}
                onChange={handleBankSelected}
                value={bankSelected}
                fontSize={IS_MOBILE ? "calc(8pt + 0.472vw)" : ""}
              />
            </div>
          </>
        )}
        {paymentSelected?.value === 3 && (
          <div className="form-group mb-1">
            <label htmlFor="payment-method" className={`${FONT_SIZE} mb-1`}>
              Penerima
            </label>
            <input
              type="text"
              placeholder="Nama Penerima"
              className={`form-control ${FONT_SIZE}`}
              value={receiver}
              onChange={(e) => setReceiver(e.target.value)}
            />
          </div>
        )}

        <div className="form-group mb-1">
          <label htmlFor="" className={`${FONT_SIZE} mb-1`}>
            {paymentSelected.value === 2
              ? "Tanggal Transfer"
              : "Tanggal Diserahkan"}
          </label>
          <div
            className=""
            style={{
              border: "1px solid #ced4da",
              borderRadius: 5,
              padding: "5px 0",
            }}
          >
            <div className="d-flex px-2 f8-vw align-items-center">
              <Icon icon={iconUrl.calendarIcon} color="#ef9403" width={18} />
              <div className="w-100 date-wrapper">
                <ReactDatePicker
                  selected={dateTransfer}
                  startDate={dateTransfer}
                  onChange={handleDate}
                  className={`date-checkin-new text-left w-100 ${
                    IS_MOBILE ? "f12-vw" : "f8-vw"
                  }`}
                  dateFormat="dd MMM yyyy"
                  maxDate={new Date()}
                  withPortal={IS_MOBILE ? true : false}
                  onFocus={(e) => e.target.blur()}
                  onCalendarOpen={handleCalendarOpen}
                  onKeyDown={(e) => {
                    e.preventDefault();
                  }}
                  onChangeRaw={(e) => {
                    e.preventDefault();
                  }}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="form-group mb-1">
          <label htmlFor="" className={`${FONT_SIZE} mb-1 fw-600`}>
            Bukti Transfer / Tanda Terima
          </label>
          {IS_MOBILE ? (
            <MobileUploadFile
              setfileUpload={setfileUpload}
              title={"Add attachment"}
              height="150px"
              fileUpload={fileUpload}
              setShowImg={setShowImg}
              showImg={showImg}
              fontSize="f12-vw"
            />
          ) : (
            <CameraWebcam
              setfileUpload={setfileUpload}
              title={"Add attachment"}
              height="150px"
              fileUpload={fileUpload}
              setShowImg={setShowImg}
              showImg={showImg}
            />
          )}
        </div>
      </div>
      <div className="col">
        <hr className="separator-blue my-2" />
      </div>
      <div className="text-center">
        <small className="f8-vw">Apakah anda yakin ingin melanjutkan?</small>
      </div>
      <div className="col">
        <div className="d-flex justify-content-between mt-2">
          <div className="col-6 pl-0">
            <button
              className={"btn btn-block btn-outline-purple " + FONT_SIZE}
              onClick={() => close()}
            >
              Close
            </button>
          </div>
          <div className="col-6 pr-0">
            <button
              className={`btn btn-block ${FONT_SIZE} text-white ${
                validateButtonSubmit() ? "btn-gradient" : "btn-secondary"
              }`}
              onClick={() => confirm()}
              disabled={validateButtonSubmit() ? false : true}
            >
              Confirm
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

const PopupConfirmSuccess = ({
  detail,
  paymentSelected,
  bankSelected,
  reveiver,
  close,
  dateTransfer,
}) => {
  const detailInput = (title, value) => {
    return (
      <div className={"d-flex flex-row " + FONT_SIZE}>
        <div className="col px-0 grey">{title}</div>
        <div className="col px-0 fw-600">: {value}</div>
      </div>
    );
  };
  return (
    <div className="d-flex flex-column py-3" style={{ width: "21rem" }}>
      <div className="col">
        <div className="text-center">
          <img
            src="/img/success-payment/success-icon-new.svg"
            alt=""
            width={100}
            loading="lazy"
          />
        </div>
      </div>
      <div className="col">
        <div className="text-center my-1">
          <span className={`${FONT_SIZE_HEADER} fw-700`}>
            Konfirmasi Setoran Berhasil
          </span>
        </div>
      </div>
      <div className="col">
        <div
          className="d-flex flex-column text-center p-3 my-2 mx-3"
          style={{ backgroundColor: "#E8FEEA", borderRadius: 3 }}
        >
          <span className={"text-black fw-600 " + FONT_SIZE}>
            Total Setoran
          </span>
          <span className={`${FONT_SIZE_HEADER} fw-700`}>
            Rp {priceSplit(detail?.total_setoran ? detail?.total_setoran : 0)}
          </span>
        </div>
        <div className="d-flex flex-column mt-2">
          <div className="col">
            <div
              className={`purple fw-700 my-2 ${
                IS_MOBILE ? "f14-vw" : "f10-vw"
              }`}
            >
              Informasi Pembayaran
            </div>
            {detailInput("Metode Pembayaran", paymentSelected?.label)}
            {paymentSelected?.value === 2 ? (
              <>
                {detailInput("Nama Bank", bankSelected?.bank_name)}
                {detailInput("Transfer Date", dateFormat(dateTransfer))}
              </>
            ) : (
              <>
                {detailInput("Penerima", reveiver)}
                {detailInput("Tanggal Diserahkan", dateFormat(dateTransfer))}
              </>
            )}
          </div>
        </div>
      </div>

      <div className="col">
        <hr className="separator-blue mx-3 my-2" />
      </div>
      <div className="col">
        <div className="d-flex justify-content-center mt-2">
          <div className="col-6 px-0">
            <button
              className={
                "btn btn-sm btn-block bg-green text-white " + FONT_SIZE
              }
              onClick={() => close()}
            >
              OK
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

const SetoranConfirmation = () => {
  const mounted = useRef(false);
  const { setpathLocation } = useContext(BreadcrumbContext);
  const [listConfirmSetoran, setListConfirmSetoran] = useState([]);
  const [loadingList, setLoadingList] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [mode, setMode] = useState("");
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [errorList, setErrorList] = useState("");
  const [errorSubmit, setErrorSubmit] = useState("");
  const [headerPopup, setHeaderPopup] = useState("");
  const [fileUpload, setFileUpload] = useState([]);
  const [paymentMethod, setPaymentMethod] = useState([]);
  const [bankList, setBankList] = useState([]);
  const [bankSelected, setBankSelected] = useState([]);
  const [paymentSelected, setPaymentSelected] = useState([]);
  const [receiver, setReceiver] = useState("");
  const [error, setError] = useState("");
  const [detailSelected, setDetailSelected] = useState({});
  const [dateTransfer, setDateTransfer] = useState(new Date());
  const [showImg, setShowImg] = useState("");

  const isOpen = () => {
    setOpenModal(!openModal);
  };

  const getListConfirmSetoran = () => {
    setLoadingList(true);
    getData("/api/v1/setoran/listforconfirm")
      .then((resp) => {
        if (mounted.current) {
          setLoadingList(false);
          if (resp.data) {
            setListConfirmSetoran(resp.data);
          } else {
            resp.json().then((err) => {
              setErrorList(t(safeErrCode(err)));
            });
          }
        }
      })
      .catch((error) => {
        console.error(error);
        setLoadingList(false);
        setErrorList(t(getErr(error)));
      });
  };

  const confirmSetoran = () => {
    setLoadingSubmit(true);
    const payload = {
      wsetoran_id: detailSelected?.wsetoran_id,
      acc_name: bankSelected?.value ? bankSelected?.bank_accholder : receiver,
      paymenttype: paymentSelected?.value,
      bank_name: bankSelected.value ? bankSelected.bank_name : "",
      bankaccno: bankSelected.value ? bankSelected.bank_accno : "",
      transfer_date: formatDate(dateTransfer),
      bank_id: bankSelected.value ? bankSelected.value : "",
      real_shiftids: detailSelected?.real_shiftids,
      bukti_setoran: fileUpload[0],
    };

    const formData = new FormData();
    for (const key in payload) {
      formData.append(key, payload[key]);
    }
    addDataPict("/api/v1/setoran/confirmsetoran", formData)
      .then((resp) => {
        setLoadingSubmit(false);
        console.log(resp);
        if (resp.message) {
          setMode("success-confirm");
        } else {
          resp.json().then((err) => {
            setErrorSubmit(t(safeErrCode(err)));
          });
          setMode("error");
          setHeaderPopup("Proses Setoran Gagal");
        }
      })
      .catch((error) => {
        console.log(error);
        setLoadingSubmit(false);
        setErrorSubmit(t(getErr(error)));
        setMode("error");
        setHeaderPopup("Konfirmasi Setoran Gagal");
      });
  };

  const getPaymentMethod = useCallback(() => {
    getData("/api/v1/listpaymentmethod")
      .then((resp) => {
        if (mounted.current) {
          if (resp.data) {
            let d = resp.data.map((item) => {
              return {
                value: item.payment_type,
                label: item.payment_name,
              };
            });
            setPaymentMethod(d);
            localStorage.setItem("payment_method", JSON.stringify(d));
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const getPaymentType = useCallback(() => {
    if (paymentSelected.value === 2) {
      getData(`/api/v1/paymentype?payment_type=${paymentSelected.value}`)
        .then((resp) => {
          if (mounted.current) {
            if (resp.data) {
              let d = resp.data.map((item) => {
                return {
                  value: item.bank_id,
                  label: `${item.bank_name} ( ${item.bank_accno} )`,
                  bank_name: item.bank_name,
                  bank_accno: item.bank_accno,
                  bank_accholder: item.bank_accholder,
                };
              });
              setBankList(d);
            } else {
              resp.json().then((err) => {
                setError(t(safeErrCode(err)));
              });
              setBankList([]);
            }
          }
        })
        .catch((error) => {
          let err = getErr(error);
          setError(t(err));
        });
    }
  }, [paymentSelected]);

  const reset = () => {
    setFileUpload([]);
    setBankSelected([]);
    setPaymentSelected([]);
    setReceiver("");
    getListConfirmSetoran();
    setDateTransfer(new Date());
    setError("");
    setHeaderPopup("");
    setMode("");
    setShowImg("");
  };

  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);

  useEffect(() => {
    setpathLocation("/shift/setoran-cash/konfirmasi-setoran");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getListConfirmSetoran();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getPaymentMethod();
  }, [getPaymentMethod]);

  useEffect(() => {
    getPaymentType();
  }, [getPaymentType]);

  return (
    <>
      {IS_MOBILE ? (
        <div className="d-flex flex-column px-3 py-1 mt-1 mb-5">
          <div className="col px-1">
            {listConfirmSetoran?.length > 0 ? (
              listConfirmSetoran?.map((item, index) => (
                <MobileCardList
                  key={item?.wsetoran_id}
                  title={
                    <>
                      <div
                        className="f10-vw fw-500"
                        style={{ color: "#959595" }}
                      >
                        ID Setoran
                      </div>
                      <div>{item?.wsetoran_id}</div>
                    </>
                  }
                  action_button={
                    item?.status !== 2 &&
                    is_sub_menu_action("sf_setoran_confirm_setoran") && (
                      <button
                        className="btn btn-block py-1 f8-vw btn-gradient text-white fw-700"
                        onClick={() => {
                          setMode("confirm-setoran");
                          isOpen();
                          setDetailSelected(item);
                        }}
                      >
                        <span className="mr-1">
                          <Icon icon={iconUrl.libraryAddCheck} width={18} />
                        </span>
                        <span className="f12-vw">Confirm</span>
                      </button>
                    )
                  }
                >
                  <div className="card-hms-list">
                    <div className="col-5 px-0 grey-text">Disetorkan oleh</div>
                    <div className="col pr-0 f12-vw black-value">
                      {item?.full_name}
                    </div>
                  </div>
                  <div className="card-hms-list">
                    <div className="col-5 px-0 grey-text">Tanggal setoran</div>
                    <div className="col pr-0 f12-vw black-value">
                      <span>
                        {getServerTimeWithoutDays(item?.created_at, TIMEZONE)},
                      </span>
                      <span className="fw-700">
                        {` ${getClientTimeWithoutSecond(
                          item?.created_at,
                          TIMEZONE
                        )}
                          ${getTimezoneOffsetClient(TIMEZONE)}`}
                      </span>
                    </div>
                  </div>
                  <div className="card-hms-list">
                    <div className="col-5 px-0 grey-text">Total setoran</div>
                    <div className="col pr-0 f12-vw black-value">
                      Rp {priceSplit(item?.total_setoran)}
                    </div>
                  </div>
                  <div className="card-hms-list">
                    <div className="col-5 px-0 grey-text">Catatan</div>
                    <div className="col pr-0 f12-vw black-value">
                      {item?.note ? showMore(item?.note, 25) : "-"}
                    </div>
                  </div>
                  <div className="card-hms-list">
                    <div className="col-5 px-0 grey-text">Status</div>
                    <div
                      className={
                        "col pr-0 f12-vw " +
                        filterStatusSetoran(item?.status)?.className
                      }
                    >
                      {filterStatusSetoran(item?.status)?.label}
                    </div>
                  </div>
                </MobileCardList>
              ))
            ) : (
              <div style={{ position: "relative" }}>
                <MobileEmptyData
                  errMessage={errorList}
                  loading={loadingList}
                  height="40vh"
                />
              </div>
            )}
          </div>
        </div>
      ) : (
        <div className="px-3 py-2">
          <div className="my-2 px-3">
            <TableSetoranConfirm
              list={listConfirmSetoran}
              loading={loadingList}
              errorMessage={errorList}
              isOpen={isOpen}
              setMode={setMode}
              setDetailSelected={setDetailSelected}
              height="25rem"
            />
          </div>
        </div>
      )}
      <PopupModal modalIsOpen={openModal}>
        {mode === "confirm-setoran" ? (
          <PopupConfirmSetoran
            paymentMethod={paymentMethod}
            paymentSelected={paymentSelected}
            bankList={bankList}
            bankSelected={bankSelected}
            detail={detailSelected}
            setBankSelected={setBankSelected}
            setPaymentSelected={setPaymentSelected}
            receiver={receiver}
            setReceiver={setReceiver}
            close={() => {
              isOpen();
              reset();
            }}
            setfileUpload={setFileUpload}
            confirm={() => {
              //   setMode("success-confirm");
              confirmSetoran();
            }}
            dateTransfer={dateTransfer}
            setDateTransfer={setDateTransfer}
            loadingSubmit={loadingSubmit}
            error={error}
            fileUpload={fileUpload}
            setShowImg={setShowImg}
            showImg={showImg}
          />
        ) : mode === "success-confirm" ? (
          <PopupConfirmSuccess
            paymentSelected={paymentSelected}
            detail={detailSelected}
            bankSelected={bankSelected}
            reveiver={receiver}
            close={() => {
              isOpen();
              reset();
            }}
            dateTransfer={dateTransfer}
          />
        ) : (
          <>
            <NotifMessage
              closeModal={openModal}
              img={"/img/error-icon/setoran-mingguan-gagal.png"}
              messageHeader={headerPopup}
              color="red"
              messageBody={errorSubmit}
            />
            <ButtonClose
              close={() => {
                reset();
                isOpen();
              }}
              text="OK"
            />
          </>
        )}
      </PopupModal>
    </>
  );
};
export default SetoranConfirmation;
