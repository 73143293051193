import { Icon } from "@iconify/react";
import React from "react";
import { iconUrl } from "../../config";

export const AccordionMenu = ({
  show,
  openCloseMenu,
  children,
  prefix_path,
  menuName,
  iconMenu,
  menuColor,
}) => {
  return (
    <div className={`${show ? "active-color" : ""} `}>
      <div className={`py-2 item-list pl-3 ${menuColor}`}>
        <button
          className={`nav-item border-0 btn-transparent btn-block d-flex justify-content-between align-items-center text-white`}
          data-toggle="collapse"
          data-target={`#${prefix_path}`}
          aria-expanded="false"
          aria-controls={prefix_path}
          onClick={openCloseMenu}
        >
          <div className="">
            <span className="mr-1">
              <Icon icon={iconMenu} />
            </span>
            <span className={`text-submenu `}>{menuName}</span>
          </div>
          <span className="">
            {show ? (
              <Icon icon={iconUrl.arrowUpAlt2} />
            ) : (
              <Icon icon={iconUrl.arrowRightAlt2} />
            )}
          </span>
        </button>
      </div>
      {children}
    </div>
  );
};
