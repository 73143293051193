//user access control

export const initACL = (data_acl) => {
  let local_acl = []; //must do to compatible with new side nav component
  if (data_acl?.length > 0) {
    let data_acls = JSON.parse(data_acl);
    data_acls.map((item, i) => {
      let d = item.split("_");
      return local_acl.push({
        menu: d[0],
        sub_menu: d[0] + "_" + d[1],
        sub_menu_action: item,
      });
    });
  }
};

export const get_com_acl = () => {
  let dataRaw = localStorage.getItem("com_acl_2");
  let data = JSON.parse(dataRaw);
  let sub_menu = [];
  let sub_menu_action = [];
  if (data?.length > 0) {
    data.map((item, i, arr) => {
      sub_menu.push(item?.sub_menu_id);
      sub_menu_action.push(item?.action);
      return true;
    });
    let uniqueSubMenu = sub_menu.filter((v, i, a) => a.indexOf(v) === i);
    let uniqueSubMenuAction = sub_menu_action.filter(
      (v, i, a) => a.indexOf(v) === i
    );

    localStorage.setItem("sub_menu_2", uniqueSubMenu);
    localStorage.setItem("sub_menu_action_2", uniqueSubMenuAction);
  }
  return [];
};

export const get_acl_list = () => {
  let data_acl = localStorage.getItem("acl_list");
  let local_acl = []; //must do to compatible with new side nav component
  if (data_acl?.length > 0) {
    let data_acls = JSON.parse(data_acl);
    data_acls.map((item, i) => {
      let d = item.split("_");
      return local_acl.push({
        menu: d[0],
        sub_menu: d[0] + "_" + d[1],
        sub_menu_action: item,
      });
    });
  }
  return local_acl;
};

export const groupByMenu = (group_name) => {
  //must take group name based on main_menu_id (reference with acl_list.csv)
  return get_acl_list().filter((x) => x.menu === group_name);
};

export const createStructACL = () => {
  let acl_list = get_acl_list();
  let menu = [];
  let submenu = [];
  let action = [];
  acl_list.map((data, x, arr) => {
    menu.push(data?.menu);
    submenu.push(data?.sub_menu);
    action.push(data?.sub_menu_action);
    return true;
  });

  let uniqueMenu = menu.filter((v, i, a) => a.indexOf(v) === i);
  // let uniqueSubMenu = submenu.filter((v, i, a) => a.indexOf(v) === i);
  // let uniqueAction = action.filter((v, i, a) => a.indexOf(v) === i);

  localStorage.setItem("menu", uniqueMenu);
  // localStorage.setItem("sub_menu", uniqueSubMenu);
  // localStorage.setItem("sub_menu_action", uniqueAction);
};

export const is_menu = (x) => {
  let menu = localStorage.getItem("menu");
  if (menu?.length > 0) {
    let menu_data = menu.split(",");
    return menu_data.includes(x);
  }
  return false;
};

export const is_sub_menu = (x) => {
  let sub_menu = localStorage.getItem("sub_menu_2");
  if (sub_menu?.length > 0) {
    let sub_menu_data = sub_menu.split(",");
    return sub_menu_data.includes(x);
  }
  return false;
};

export const is_sub_menu_action = (x) => {
  let sub_menu_action = JSON.parse(localStorage.getItem("sub_menu_action_2"));
  if (sub_menu_action?.length > 0) {
    return sub_menu_action.includes(x);
  }
  return false;
};

export const isValidAccess = (arr) => {
  //use arr as [.., ..]
  let sub_menu_action_data = [];
  let sub_menu_action = localStorage.getItem("sub_menu_action_2");
  if (sub_menu_action?.length > 0) {
    sub_menu_action_data = sub_menu_action;
  }
  const containsAll = arr.some((element) => {
    return sub_menu_action_data.includes(element);
  });
  return containsAll;
};
export const isValidAccessMenu = (arr) => {
  //use arr as [.., ..]
  let sub_menu_data = [];
  let sub_menu = localStorage.getItem("sub_menu_2");
  if (sub_menu?.length > 0) {
    sub_menu_data = sub_menu.split(",");
  }
  const containsAll = arr.some((element) => {
    return sub_menu_data.includes(element);
  });
  return containsAll;
};

export const mappingMenu = (menu) => {
  let newArr = menu.map((item) => item.acl);
  return newArr;
};

export const isValidAccessMultiple = (data1, data2) => {
  let dataMapping1 = mappingMenu(data1);
  let dataMapping2 = mappingMenu(data2);

  let concatArr = dataMapping1.concat(dataMapping2);

  let sub_menu_action_data = [];
  let sub_menu_action = JSON.parse(localStorage.getItem("sub_menu_action_2"));
  if (sub_menu_action?.length > 0) {
    sub_menu_action_data = sub_menu_action;
  }
  const containsAll = concatArr.some((element) => {
    return sub_menu_action_data.includes(element);
  });
  return containsAll;
};
