import React, { useEffect, useRef, useState } from "react";
import PopupModal from "../../common/PopupModal";
import SelectOpt from "../../common/SelectOpt";
import {
  addDays,
  bucketRnaWeb,
  formatDate,
  getClientTimeWithoutSecond,
  getServerTimeWithoutDays,
  getTimezoneOffsetClient,
  isEmpty,
  oss,
  priceSplit,
  showMoreGuest,
  splitID,
} from "../../utils";
import {
  getCsvFile,
  getData,
  getErr,
  safeErrCode,
} from "../../utils/api-request";
import { mobileSearchInput, searchInput } from "../booking/bookingHelper";
import { TableHistoryTransfer } from "./TableHistoryTransfer";
import { Loading } from "../../common/Loading";
import { EmptyData, MobileEmptyData } from "../Empty/EmptyData";
import InfiniteScroll from "react-infinite-scroll-component";
import {
  DateRangeFilterMobile,
  DateRangeFilterNew,
} from "../../common/DateRange";
import { img_fallback } from "../../utils/ui";
import { comboBoxDownloadReport } from "../../utils/helper";
import { useContext } from "react";
import { BreadcrumbContext } from "../../common/BreadcrumbContext";
import { is_sub_menu_action } from "../../utils/uac";
import { IS_MOBILE } from "../../common/Responsive";
import { Icon } from "@iconify/react";
import { iconUrl } from "../../config";
import { MobileCardList } from "../../common/CardList";
import { filterStatusSetoran } from "../setoran/common/utils";
import { BottomSheet } from "react-spring-bottom-sheet";
import { t } from "../../common/ErrorMessage";

const ShowImage = ({ sourceImg, close }) => {
  return (
    <div
      className="p-3 relative"
      style={{ width: IS_MOBILE ? "21rem" : "30rem" }}
    >
      <div className="d-flex align-items-center">
        <span className="mr-2">
          <Icon
            icon={iconUrl.informationIcon}
            color="orange"
            width={"1.5rem"}
          />
        </span>
        <span className={`purple fw-700 ${IS_MOBILE ? "f14-vw" : "f10-vw"}`}>
          Bukti Foto
        </span>
      </div>
      <div className="mt-2">
        <label
          htmlFor=""
          className={`fw-600 ${IS_MOBILE ? "f12-vw" : "f8-vw"}`}
        >
          Bukti Transfer/Tanda Terima
        </label>
        <img
          src={bucketRnaWeb + sourceImg + oss}
          alt="bukti setoran"
          style={{
            borderRadius: 5,
            width: "100%",
            objectFit: "contain",
            maxHeight: "25rem",
          }}
          onError={({ currentTarget }) => {
            currentTarget.onerror = null; // prevents looping
            currentTarget.src = img_fallback;
          }}
          loading="lazy"
        />
      </div>
      <hr className="separator-blue" />
      <div className="d-flex justify-content-center mt-3">
        <div className="col-5">
          <button
            className={`btn btn-sm btn-block btn-gradient text-white ${
              IS_MOBILE ? "f12-vw" : "f8-vw"
            }`}
            onClick={() => close()}
          >
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

const HistoryTransfer = () => {
  const {
    setpathLocation,
    openButtonSheet,
    setOpenButtonSheet,
    setShowFilter,
  } = useContext(BreadcrumbContext);
  const mounted = useRef(false);
  const [historyList, setHistoryList] = useState([]);
  const [detailHistory, setdetailHistory] = useState({});
  const [loadingList, setLoadingList] = useState(false);
  const [errorList, setErrorList] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const paymentMethod = JSON.parse(localStorage.getItem("payment_method"));
  const [paymentMethodSelected, setPaymentMethodSelected] = useState([]);
  const [hasMore, sethasMore] = useState(false);
  const link = useRef(null);
  const [filterState, setfilterState] = useState({
    start: addDays(new Date(), -7),
    end: new Date(),
    sent_to: "",
    page: 1,
  });

  const isOpen = () => {
    setOpenModal(!openModal);
  };
  const checkNextPage = () => {
    if (hasMore) {
      let page = filterState.page;
      let pagi = page + 1;
      setfilterState({ ...filterState, page: pagi });
      getSetoranReport(pagi, false, filterState.start, filterState.end);
    }
  };

  const handleStart = (value) => {
    setfilterState({ ...filterState, start: value, end: addDays(value, 7) });
  };
  const handleEnd = (value) => {
    setfilterState({ ...filterState, end: value });
  };

  const handlePaymentMethod = (val) => {
    if (!val) {
      setPaymentMethodSelected([]);
    } else {
      setPaymentMethodSelected(val);
    }
  };

  const getSetoranReport = (pagi, is_init, start, end) => {
    const params = {
      ...(!!start && { start_date: formatDate(start) }),
      ...(!!end && { end_date: formatDate(end) }),
      ...(!!filterState?.sent_to && { acc_name: filterState?.sent_to }),
      ...(!!paymentMethodSelected &&
        !!paymentMethodSelected?.value && {
          payment_type: paymentMethodSelected?.value,
        }),
    };
    let paramsFormatted = null;
    if (!isEmpty(params)) {
      paramsFormatted = new URLSearchParams(params);
    }
    let url = `/api/v1/report/setoran_report?${
      paramsFormatted !== null ? `&${paramsFormatted}&page=${pagi}` : ""
    }`;
    let datas = [];
    setLoadingList(true);
    getData(url)
      .then((resp) => {
        if (mounted.current) {
          if (resp.data) {
            setLoadingList(false);
            if (is_init) {
              datas = resp.data.data;
            } else {
              let x = [];
              x = historyList;
              datas = [...x, ...resp.data.data];
            }
            sethasMore(resp.data.next_page);
            setHistoryList(datas);
          } else {
            setLoadingList(false);
            resp.json().then((err) => {
              setErrorList(t(safeErrCode(err)));
            });
          }
        }
      })
      .catch((error) => {
        let err = getErr(error);
        setLoadingList(false);
        setErrorList(t(err));
        console.log(err);
      });
  };

  const handleEnter = (e) => {
    if (e.key === "Enter") {
      getSetoranReport(1, true, filterState.start, filterState.end);
      setHistoryList([]);
      setfilterState({ ...filterState, page: 1 });
    }
  };

  const handleDownloadCSV = (e) => {
    const params = {
      ...(!!filterState &&
        !!filterState.start && { start_date: formatDate(filterState?.start) }),
      ...(!!filterState &&
        !!filterState?.end && { end_date: formatDate(filterState.end) }),
      ...(!!filterState?.sent_to && { acc_name: filterState?.sent_to }),
      ...(!!paymentMethodSelected &&
        !!paymentMethodSelected?.value && {
          payment_type: paymentMethodSelected?.value,
        }),
    };
    let paramsFormatted = null;
    if (!isEmpty(params)) {
      paramsFormatted = new URLSearchParams(params);
    }
    let url = `/api/v1/report/setoran_report?&csvoutput=1${
      paramsFormatted !== null ? `&${paramsFormatted}` : ""
    }`;
    if (
      link.current.href &&
      link.current.href.substr(link.current.href.length - 6) !== "no_use"
    ) {
      return;
    }
    if (link.current.href.substr(link.current.href.length - 6) === "no_use") {
      e.preventDefault();
    }
    getCsvFile(url)
      .then((resp) => {
        let now = new Date();
        let filenameServ = "setoran-report-" + formatDate(now) + ".csv";
        if (
          resp.headers !== undefined &&
          resp.headers !== null &&
          resp.headers.length > 1
        ) {
          filenameServ = resp.headers
            .get("Content-Disposition")
            .split("filename=")[1];
        }
        let filename = filenameServ;
        // console.log("filename-serv-download", filenameServ);
        resp.blob().then((blob) => {
          let dx = new Blob([blob]);
          const href = window.URL.createObjectURL(dx);
          link.current.download = filename;
          link.current.href = href;
          link.current.click();
          link.current.download = "no_use";
          link.current.href = "no_use";
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const resetFilter = () => {
    setfilterState({
      ...filterState,
      start: addDays(new Date(), -7),
      end: new Date(),
      sent_to: "",
      page: 1,
    });
    setPaymentMethodSelected([]);
  };

  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);
  useEffect(() => {
    setpathLocation("/report/setoran-cash");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      paymentMethodSelected?.value === undefined &&
      filterState?.sent_to === ""
    ) {
      getSetoranReport(1, true, filterState.start, filterState.end);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentMethodSelected, filterState.sent_to]);

  useEffect(() => {
    setShowFilter(true);
    return () => {
      setShowFilter(false);
    };
  }, [setShowFilter]);

  useEffect(() => {
    if (openModal) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
    return () => {
      document.body.style.overflow = "unset";
    };
  }, [openModal]);

  return (
    <>
      {IS_MOBILE ? (
        <div className="d-flex flex-column px-3 py-1 mb-5">
          <div className="col px-1">
            {is_sub_menu_action("rp_setoran_cash_export") && (
              <div className="d-flex">
                {comboBoxDownloadReport(link, handleDownloadCSV)}
              </div>
            )}
          </div>
          <div className="col px-1">
            <InfiniteScroll
              dataLength={historyList?.length}
              next={checkNextPage}
              hasMore={hasMore}
              loader={<Loading />}
              endMessage={
                historyList?.length < 1 && (
                  <MobileEmptyData
                    loading={loadingList}
                    errMessage={errorList}
                    height="40vh"
                  />
                )
              }
            >
              {historyList?.map((item) => {
                const timezone = localStorage?.getItem("timezone");
                return (
                  <MobileCardList
                    title={
                      <>
                        <div
                          className="f12-vw fw-500"
                          style={{ color: "#959595" }}
                        >
                          Shift ID
                        </div>
                        <div>{item?.wsetoran_id}</div>
                      </>
                    }
                    fontSizeHeader="f14-vw"
                    key={item?.wsetoran_id}
                  >
                    <div className="card-hms-list">
                      <div className="col-5 px-0 grey-text">
                        Tanggal Setoran
                      </div>
                      <div className="col pr-0 f12-vw black-value">
                        <span>
                          {`${getServerTimeWithoutDays(
                            item?.created_at,
                            timezone
                          )}, `}
                        </span>
                        <span className="font-weight-bold">
                          {`${getClientTimeWithoutSecond(
                            item?.created_at,
                            timezone
                          )} ${getTimezoneOffsetClient(timezone)}`}
                        </span>
                      </div>
                    </div>
                    <div className="card-hms-list">
                      <div className="col-5 px-0 grey-text">
                        Disetorkan Oleh
                      </div>
                      <div className="col pr-0 f12-vw black-value">
                        {item?.disetorkan_oleh}
                      </div>
                    </div>
                    <div className="card-hms-list">
                      <div className="col-5 px-0 grey-text">Total Setoran</div>
                      <div className="col pr-0 f12-vw black-value">
                        Rp {priceSplit(item?.total_setoran)}
                      </div>
                    </div>
                    <div className="card-hms-list">
                      <div className="col-5 px-0 grey-text">
                        Metode Pembayaran
                      </div>
                      <div className="col pr-0 f12-vw black-value">
                        {item?.payment_name}
                      </div>
                    </div>
                    <div className="card-hms-list">
                      <div className="col-5 px-0 grey-text">
                        Tanggal Transfer
                      </div>
                      <div className="col pr-0 f12-vw black-value">
                        <span>
                          {`${getServerTimeWithoutDays(
                            item?.transfer_date,
                            timezone
                          )}, `}
                        </span>
                        <span className="font-weight-bold">
                          {`${getClientTimeWithoutSecond(
                            item?.transfer_date,
                            timezone
                          )} ${getTimezoneOffsetClient(timezone)}`}
                        </span>
                      </div>
                    </div>
                    {item?.bank_name && (
                      <div className="card-hms-list">
                        <div className="col-5 px-0 grey-text">Nama Bank</div>
                        <div className="col pr-0 f12-vw black-value">
                          {item?.bank_name ? (
                            <>
                              <div>{item?.bank_name}</div>
                              <div>{item?.to_account_no}</div>
                            </>
                          ) : (
                            "-"
                          )}
                        </div>
                      </div>
                    )}
                    <div className="card-hms-list">
                      <div className="col-5 px-0 grey-text">Penerima</div>
                      <div className="col pr-0 f12-vw black-value">
                        {item?.acc_name}
                      </div>
                    </div>
                    <div className="card-hms-list">
                      <div className="col-5 px-0 grey-text">Bukti Setoran</div>
                      <div className="col pr-0 f12-vw black-value">
                        <div
                          className="f12-vw fw-700 blue"
                          onClick={() => {
                            setdetailHistory(item?.bukti_setoran);
                            isOpen();
                          }}
                        >
                          <span className="mr-1">
                            <Icon
                              icon={iconUrl.formMultiple20Filled}
                              width={20}
                            />
                          </span>
                          Lihat bukti
                        </div>
                      </div>
                    </div>
                    <div className="card-hms-list">
                      <div className="col-5 px-0 grey-text">Note</div>
                      <div className="col pr-0 f12-vw black-value">
                        {item?.note ? showMoreGuest(item?.note, 25) : "-"}
                      </div>
                    </div>
                    <div className="card-hms-list">
                      <div className="col-4 px-0 purple fw-700">Approval</div>
                    </div>
                    <div className="card-hms-list">
                      <div className="col-5 px-0 grey-text">Approve by</div>
                      <div className="col pr-0 f12-vw black-value">
                        {item?.approved_by_name}
                      </div>
                    </div>
                    <div className="card-hms-list">
                      <div className="col-5 px-0 grey-text">Approved at</div>
                      <div className="col pr-0 f12-vw black-value">
                        <span>
                          {`${getServerTimeWithoutDays(
                            item?.ts_approve,
                            timezone
                          )}, `}
                        </span>
                        <span className="font-weight-bold">
                          {`${getClientTimeWithoutSecond(
                            item?.ts_approve,
                            timezone
                          )} ${getTimezoneOffsetClient(timezone)}`}
                        </span>
                      </div>
                    </div>
                    <div className="card-hms-list">
                      <div className="col-5 px-0 grey-text">Status</div>
                      <div
                        className={`col pr-0 fw-700 f12-vw ${
                          filterStatusSetoran(item?.status)?.className
                        }`}
                      >
                        {filterStatusSetoran(item?.status)?.label}
                      </div>
                    </div>
                    <div className="card-hms-list">
                      <div className="col-5 px-0 grey-text">Shift ID</div>
                      <div className="col pr-0 f12-vw black-value">
                        {splitID(item?.real_shiftids, 5)}
                      </div>
                    </div>
                    {item?.note_rejected && (
                      <div className="card-hms-list">
                        <div className="col-5 px-0 grey-text">
                          Note Rejected
                        </div>
                        <div className="col pr-0 f12-vw black-value">
                          {item?.note_rejected
                            ? showMoreGuest(item?.note_rejected, 25)
                            : "-"}
                        </div>
                      </div>
                    )}
                  </MobileCardList>
                );
              })}
            </InfiniteScroll>
          </div>
        </div>
      ) : (
        <div className="d-flex flex-column px-4 py-1">
          <div className="col px-2">
            <div className="d-flex flex-row justify-content-between">
              <div className="col-md-8 px-0">
                <div className="d-flex flex-row">
                  <div className="col px-0 mr-2">
                    <label htmlFor="" className="f8-vw m-0 p-0">
                      Rentang Waktu
                    </label>
                    <DateRangeFilterNew
                      start={filterState.start}
                      end={filterState.end}
                      handleStart={handleStart}
                      handleEnd={handleEnd}
                      minDate={true}
                    />
                  </div>
                  <div className="col px-0 mr-2">
                    <label htmlFor="" className="f8-vw m-0 p-0">
                      Metode Pembayaran
                    </label>
                    <SelectOpt
                      optionsValue={paymentMethod}
                      onChange={handlePaymentMethod}
                      placeholder="Pilih Metode Pembayaran"
                      clear={true}
                      value={paymentMethodSelected}
                    />
                  </div>
                  <div className="col px-0 mr-2">
                    <label htmlFor="" className="f8-vw m-0 p-0">
                      Nama Penerima
                    </label>
                    {searchInput(
                      "Cari Nama Penerima",
                      filterState.sent_to,
                      (e) =>
                        setfilterState({
                          ...filterState,
                          sent_to: e.target.value,
                        }),
                      handleEnter
                    )}
                  </div>
                </div>
              </div>
              <div className="col-md-2 pr-0 pl-5 mt-auto">
                <div className="d-flex flex-column">
                  <button
                    className="btn btn-sm search-new rounded-input btn-block mt-auto f8-vw fw-500 text-white"
                    onClick={() => {
                      setHistoryList([]);
                      setfilterState({ ...filterState, page: 1 });
                      getSetoranReport(
                        1,
                        true,
                        filterState.start,
                        filterState.end
                      );
                    }}
                    style={{
                      paddingTop: 7,
                      paddingBottom: 7,
                      backgroundColor: "#742B9E",
                    }}
                  >
                    Search
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="col px-0">
            <div className="d-flex flex-row align-items-center">
              {is_sub_menu_action("rp_setoran_cash_export") && (
                <div className="mt-2">
                  <div className="d-flex">
                    {comboBoxDownloadReport(link, handleDownloadCSV)}
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="col px-2 mt-0">
            <InfiniteScroll
              dataLength={historyList?.length}
              next={checkNextPage}
              hasMore={hasMore}
              loader={<Loading />}
              endMessage={
                historyList?.length < 1 && (
                  <EmptyData
                    loading={loadingList}
                    errMessage={errorList}
                    height="17rem"
                  />
                )
              }
              height={"50vh"}
              className="scroll-table px-0 mt-2 table-border-line"
            >
              <TableHistoryTransfer
                list={historyList}
                errorMessage={errorList}
                paymentMethod={paymentMethod}
                getDetail={setdetailHistory}
                isOpen={isOpen}
              />
            </InfiniteScroll>
          </div>
        </div>
      )}

      {IS_MOBILE && (
        <div>
          <BottomSheet
            open={openButtonSheet}
            onDismiss={() => setOpenButtonSheet(false)}
            snapPoints={({ maxHeight }) => maxHeight}
            header={
              <div className="d-flex justify-content-between">
                <span className="purple f16-vw fw-600 px-2">Filter</span>
                <span
                  className="px-2"
                  onClick={() => setOpenButtonSheet(false)}
                >
                  <Icon icon={iconUrl.closeBold} color={"grey"} width={20} />
                </span>
              </div>
            }
            footer={
              <div className="d-flex justify-content-between">
                <div className="col-6 pl-2">
                  <button
                    className="btn btn-block btn-outline-purple f12-vw"
                    onClick={() => {
                      resetFilter();
                      setOpenButtonSheet(false);
                      getSetoranReport(
                        1,
                        true,
                        addDays(new Date(), -7),
                        new Date()
                      );
                    }}
                  >
                    Reset
                  </button>
                </div>
                <div className="col-6 pr-2">
                  <button
                    className="btn btn-block btn-gradient f12-vw text-white"
                    onClick={() => {
                      setOpenButtonSheet(false);
                      getSetoranReport(
                        1,
                        true,
                        filterState.start,
                        filterState.end
                      );
                    }}
                  >
                    Search
                  </button>
                </div>
              </div>
            }
          >
            <div className="mt-3" style={{ height: "30rem" }}>
              <div className="px-4 mt-2 date-wrapper">
                <label htmlFor="" className="f12-vw m-0 p-0">
                  Rentang Waktu
                </label>
                <DateRangeFilterMobile
                  start={filterState.start}
                  end={filterState.end}
                  handleStart={handleStart}
                  handleEnd={handleEnd}
                  minDate={true}
                  fontSize="f12-vw"
                />
              </div>
              <div className="px-4 mt-3">
                <label htmlFor="" className="f12-vw m-0 p-0">
                  Metode Pembayaran
                </label>
                <SelectOpt
                  optionsValue={paymentMethod}
                  onChange={handlePaymentMethod}
                  placeholder={"Pilih Metode Pembayaran"}
                  value={paymentMethodSelected}
                  clear={true}
                  fontSize="calc(8pt + 0.472vw)"
                  menuHeight="12rem"
                />
              </div>
              <div className="px-4 mt-3">
                <label htmlFor="" className="f12-vw m-0 p-0">
                  Nama Penerima
                </label>
                {mobileSearchInput(
                  "Cari Nama Penerima",
                  filterState.sent_to,
                  (e) =>
                    setfilterState({ ...filterState, sent_to: e.target.value })
                )}
              </div>
            </div>
          </BottomSheet>
        </div>
      )}
      <PopupModal modalIsOpen={openModal}>
        <ShowImage close={isOpen} sourceImg={detailHistory} />
      </PopupModal>
    </>
  );
};

export default HistoryTransfer;
