import React from "react";
import { HeaderName } from "../../../common/HeaderName";
import { IS_MOBILE } from "../../../common/Responsive";
import { priceSplit } from "../../../utils";
const FONT_SIZE = IS_MOBILE ? "f12-vw" : "f8-vw";
const FONT_SIZE_HEADER = IS_MOBILE ? "f14-vw" : "f12-vw";

export const PaymentExtendSuccess = ({
  paymentSelected,
  bankSelected,
  detailPrice,
  payment,
  charge,
  close,
  confirm,
}) => {
  const { add_price, total_charge, room_price } = detailPrice;

  const detailPaymentMethod = (title, value) => {
    return (
      <div className={`d-flex flex-row mt-1 ${FONT_SIZE}`}>
        <div className="col px-0">
          <span className="grey">{title}</span>
        </div>
        <div className="col px-0 fw-600">: {value}</div>
      </div>
    );
  };

  const detaiTotalPaid = (title, value, tax) => {
    return (
      <div className={`d-flex flex-row mt-1 ${FONT_SIZE}`}>
        <div className="col px-0">
          <span className="text-black fw-700">{title}</span>
        </div>
        <div className="col px-0">
          <div className="d-flex justify-content-between">
            <div className="fw-700">Rp</div>
            <div className="">
              <div className="text-right fw-700">{priceSplit(value)}</div>
              {tax && (
                <div
                  className={`${IS_MOBILE ? "f8-vw" : "f6-vw"} grey`}
                  style={{
                    position: "relative",
                    right: 0,
                    top: 0,
                  }}
                >
                  Sudah termasuk pajak
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  };

  const redirectValidation = () => {
    return paymentSelected?.value === 2 ? confirm() : close();
  };

  return (
    <div
      className="d-flex flex-column py-3"
      style={{ width: IS_MOBILE ? "21rem" : "22rem" }}
    >
      <div className="col">
        <div className="d-flex justify-content-center">
          <img
            src="/img/success-payment/success-icon.svg"
            alt=""
            loading="lazy"
          />
        </div>
      </div>
      <div className="col">
        <div className={"font-weight-bold text-center " + FONT_SIZE_HEADER}>
          {paymentSelected?.value === 2
            ? "Pemesanan Berhasil"
            : "Pembayaran Berhasil"}
        </div>
        {paymentSelected?.value === 2 && (
          <>
            <div className={"text-center " + FONT_SIZE}>
              Lakukan Konfirmasi setelah melakukan pembayaran
            </div>
            <div className={"text-center " + FONT_SIZE}>
              pada menu "Konfirmasi Pembayaran"
            </div>
          </>
        )}
        <div className="px-3">
          <hr className="separator-blue" />
        </div>
      </div>
      <div className="col">
        <div className="d-flex flex-column">
          <div className="col">
            <div className="">
              <HeaderName
                text="Informasi Pembayaran"
                fontSize={IS_MOBILE ? "f14-vw fw-700" : "f10-vw fw-700"}
                color2="purple"
              />
            </div>
            {detailPaymentMethod("Metode Pembayaran", paymentSelected?.label)}
            {paymentSelected?.value === 2 && (
              <>
                {detailPaymentMethod("Nama Bank", bankSelected?.bank_name)}
                {detailPaymentMethod(
                  "Nomor Rekening",
                  bankSelected?.bank_accno
                )}
              </>
            )}
          </div>
          <div className="col">
            <div className="mt-3">
              <HeaderName
                text="Informasi Tagihan"
                fontSize={IS_MOBILE ? "f14-vw fw-700" : "f10-vw fw-700"}
                color2="purple"
              />
              <div className="">
                <hr className="separator-blue my-2" />
                {detaiTotalPaid("Additional Charge", add_price)}
                {detaiTotalPaid("Room Charge", room_price)}
                <hr className="separator-blue my-2" />
                {detaiTotalPaid("Total Charge", total_charge)}
              </div>
              {paymentSelected?.value === 3 && (
                <>
                  <hr className="separator-blue my-2" />
                  {detaiTotalPaid("Pembayaran Tunai", payment)}
                  {detaiTotalPaid("Kembalian", charge)}
                </>
              )}
              <hr className="separator-blue my-2" />
            </div>
          </div>
        </div>
      </div>
      <div className="col mt-2">
        <div className="d-flex justify-content-center">
          <div className="col-6">
            <button
              className={
                "btn fw-600 bg-green text-white btn-block " + FONT_SIZE
              }
              onClick={() => redirectValidation()}
            >
              OK
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
