import React, { lazy, Suspense } from "react";
import { Route } from "react-router-dom";
import { LoadingOverlay } from "../../common/Loading";
import { useCheckedLoggedIn } from "../../hook/useCheckedLoggedIn";
// import Login from "./Login";

export default function ProtectedPage({ children }) {
  const show = useCheckedLoggedIn();
  const Login = lazy(() => import("./Login"));
  const renderLoader = () => <LoadingOverlay />;
  return (
    <Suspense fallback={renderLoader()}>
      {!show ? children : <Route path="/login" component={Login} />}
    </Suspense>
  );
}
