import React from "react";
import { Redirect, Route } from "react-router-dom";
import { checkLoggedIn } from "../../utils";
import { is_sub_menu_action } from "../../utils/uac";
import { ErrorBoundary } from "react-error-boundary";
import { ErrorFallback, myErrorHandler } from "../../common/ErrorBoundaryHMS";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";

function PrivateComponent({ component: Component, ...rest }) {
  const { acl } = rest;
  const location = useLocation();
  return (
    <ErrorBoundary
      FallbackComponent={ErrorFallback}
      onError={myErrorHandler}
      onReset={() => {
        // reset the state of your app so the error doesn't happen again
      }}
      key={location.pathname}
    >
      <Route
        {...rest}
        render={(props) => {
          if (checkLoggedIn() && is_sub_menu_action(acl)) {
            return <Component />;
          } else {
            return (
              <Redirect
                to={{ pathname: "/login", state: { from: props.location } }}
              />
            );
          }
        }}
      />
    </ErrorBoundary>
  );
}

export default PrivateComponent;
