import { Icon } from "@iconify/react";
import React, { useEffect } from "react";
import { HeaderName } from "../../../common/HeaderName";
import SelectOpt from "../../../common/SelectOpt";
import { iconUrl } from "../../../config";
import { priceSplit } from "../../../utils";
import { IS_MOBILE } from "../../../common/Responsive";

const FONT_SIZE = IS_MOBILE ? "f12-vw" : "f8-vw";
const FONT_SIZE_HEADER = IS_MOBILE ? "f14-vw fw-600" : "f10-vw fw-600";

export const PaymentBreakage = ({
  loading,
  paymentMethod,
  paymentSelected,
  setpaymentSelected,
  bankList,
  bankSelected,
  setBankSelected,
  payment,
  setPayment,
  charge,
  setCharge,
  close,
  confirm,
  compensation,
  mode,
}) => {
  const handlePaymentMethod = (val) => {
    setpaymentSelected(val);
    setBankSelected([]);
    setPayment(0);
  };

  const handleBankSelected = (val) => {
    setBankSelected(val);
  };

  const handlePayment = (val) => {
    setPayment(Number(val.target.value.replace(/\D/g, "")));
  };

  const detailPayment = (title, value, color, tax) => {
    return (
      <div
        className={"d-flex flex-row py-1 " + FONT_SIZE}
        style={{ borderBottom: color ? "none" : "1px solid #ececec" }}
      >
        <div className="col-6 px-0">
          <span className={`font-weight-bold ${color ? color : "text-black"}`}>
            {title}
          </span>
        </div>
        <div className="col px-0">
          <div className="d-flex flex-row justify-content-between">
            <div className={`font-weight-bold text-black`}>Rp</div>
            <div
              className={`col px-0 text-right fw-700 ${
                color ? color : "text-black"
              }`}
            >
              <span className="px-2">{priceSplit(value)}</span>
            </div>
          </div>
          {tax && value > 0 && (
            <div className="px-2" style={{ position: "relative" }}>
              <small
                className={IS_MOBILE ? "f7-vw grey" : "f6-vw grey"}
                style={{ position: "absolute", top: -3, right: 5 }}
              >
                Sudah termasuk pajak
              </small>
            </div>
          )}
        </div>
      </div>
    );
  };

  const paymentInput = (title, value, onChange, autoFocus) => {
    return (
      <div className={"d-flex flex-row align-items-center " + FONT_SIZE}>
        <div className="col-6 px-0">
          <span className={`font-weight-bold text-black`}>{title}</span>
        </div>
        <div className="col px-0">
          <div className="d-flex flex-row align-items-center justify-content-between">
            <div className={`font-weight-bold text-black`}>Rp</div>
            <div
              className={`col px-0 font-weight-bold text-black ml-2`}
              style={{ border: "1px solid lightgray", borderRadius: 5 }}
            >
              <input
                type="text"
                className={
                  "form-control text-right border-0 form-control-sm font-weight-bold " +
                  FONT_SIZE
                }
                value={priceSplit(value)}
                style={{ textAlign: "right" }}
                autoFocus={autoFocus}
                onChange={onChange}
                onFocus={(e) => (e.target.value = "")}
                inputMode="numeric"
              />
            </div>
          </div>
        </div>
      </div>
    );
  };

  useEffect(() => {
    if (paymentSelected?.value === 3 && payment >= compensation) {
      setCharge(Math.abs(parseInt(compensation) - parseInt(payment)));
    } else {
      setCharge(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentSelected, compensation, payment]);

  const isEnabled = () => {
    return (
      (paymentSelected?.value === 2 && bankSelected?.value && !loading) ||
      (paymentSelected?.value === 3 && payment >= compensation && !loading) ||
      (payment >= compensation && !loading) ||
      (compensation === 0 && paymentSelected?.value === 3 && !loading)
    );
  };

  const isEnabled2 = () => {
    return (
      (paymentSelected?.value === 2 && bankSelected?.value && !loading) ||
      (paymentSelected?.value === 3 && !loading)
    );
  };

  return (
    <div
      className="d-flex flex-column px-3 py-2"
      style={{ width: IS_MOBILE ? "21rem" : "22rem" }}
    >
      <div className="col px-0 py-1">
        <div className="d-flex align-items-center justify-content-start">
          <Icon
            icon={iconUrl.walletCreditCard20Filled}
            color="orange"
            width={25}
          />
          <div className="fw-600 mx-2">
            <HeaderName text="Pembayaran" fontSize={"f14-vw"} />
          </div>
        </div>
      </div>
      <div className="col px-0">
        <div className="d-flex flex-column my-2">
          <div className="col px-1">
            <div className="">
              <label htmlFor="payment-method" className={"my-1 " + FONT_SIZE}>
                Metode Pembayaran
              </label>
              <SelectOpt
                placeholder="Pilih metode pembayaran"
                optionsValue={paymentMethod}
                onChange={handlePaymentMethod}
                fontSize={IS_MOBILE ? "calc(8pt + 0.472vw)" : ""}
                disabled={compensation === 0 ? true : false}
              />
            </div>
          </div>
          {paymentSelected.value === 2 && (
            <div className="col px-1 mt-2">
              <div className="">
                <label htmlFor="payment-method" className={"my-1 " + FONT_SIZE}>
                  Pilih Bank
                </label>
                <SelectOpt
                  placeholder="Pilih bank"
                  optionsValue={bankList}
                  onChange={handleBankSelected}
                  fontSize={IS_MOBILE ? "calc(8pt + 0.472vw)" : ""}
                />
              </div>
              {/* {bankSelected?.value && (
                <div className="">
                  <div className="">
                    <label htmlFor="payment-method" className="f7-vw my-0">
                      Account Name
                    </label>
                    <input
                      type="text"
                      placeholder="Nama Penerima"
                      className={`form-control f7-vw form-control-sm rounded-input`}
                      value={bankSelected?.bank_accholder}
                      readOnly
                    />
                  </div>
                </div>
              )} */}
            </div>
          )}
        </div>
      </div>
      <div
        className="col px-0"
        style={{
          backgroundColor: "white",
          borderRadius: "5px",
          pointerEvents: isEnabled2() ? "visible" : "none",
          opacity: isEnabled2() ? "1" : "0.5",
        }}
      >
        <div className="d-flex flex-column my-2">
          <div className="col px-1">
            <HeaderName
              text="Informasi Pembayaran"
              fontSize={FONT_SIZE_HEADER}
              color2="purple"
            />
          </div>
          <div className="col px-1 mt-1">
            <hr className="separator-blue my-1" />
            {mode === "payment-change-room"
              ? detailPayment(
                  "Additional Charge",
                  compensation || 0,
                  "text-black",
                  false
                )
              : detailPayment(
                  "Total Ganti Rugi",
                  compensation || 0,
                  "text-black",
                  false
                )}
            <hr className="separator-blue my-1" />
            {/* {detailPayment("Total Tagihan", compensation || 0, "purple", false)} */}

            {paymentSelected.value === 3 && compensation > 0 && (
              <div className="my-2">
                {paymentInput("Pembayaran Tunai", payment, (e) =>
                  handlePayment(e)
                )}
                <div className="mt-1">
                  {detailPayment("Kembalian", charge, "text-black", false)}
                </div>
                <hr className="separator-blue my-1" />
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="col">
        <div className="text-center">
          <small className={IS_MOBILE ? "f10-vw" : "f8-vw"}>
            Apakah Anda yakin ingin melanjutkan?
          </small>
        </div>
      </div>
      <div className="col px-1 py-2">
        <div className="d-flex justify-content-between">
          <div className="col-6 pl-0 pr-1">
            <button
              className={`btn btn-block ${FONT_SIZE} font-weight-bold btn-outline-purple`}
              onClick={close}
            >
              Back
            </button>
          </div>
          <div className="col pl-1 pr-0">
            <button
              className={`btn btn-block ${FONT_SIZE} font-weight-bold text-white ${
                isEnabled() ? "btn-gradient" : "btn-secondary"
              }`}
              onClick={confirm}
              disabled={isEnabled() ? false : true}
            >
              {loading && (
                <span
                  className="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
              )}
              Confirm
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
