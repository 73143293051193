import { Icon } from "@iconify/react";
import React, { Fragment } from "react";
import CameraWebcam from "../../../common/CameraWebcam";
import { HeaderName } from "../../../common/HeaderName";
import { IS_MOBILE } from "../../../common/Responsive";
import { iconUrl } from "../../../config";
import {
  dateFormat,
  getDayDuration,
  priceSplit,
  showMore,
} from "../../../utils";

export const OtherDetailPopup = ({
  detail,
  purposeSelected,
  guestTypeSelected,
  confirm,
  close,
  setotherForm,
  otherSelected,
  setfileUpload,
  fileUpload,
  roomSelected,
  setShowImg,
  showImg,
}) => {
  const handleRoomCharge = (val) => {
    setotherForm({
      ...detail,
      totalcharge: Number(val.target.value.replace(/\D/g, "")),
    });
  };
  const detailInformation = (title, value) => {
    return (
      <div className="d-flex flex-row f8-vw mt-2">
        <div className="col px-0">
          <span className="grey">{title}</span>
        </div>
        <div className="col px-0">
          <span className="text-black fw-600">: {value ? value : "-"}</span>
        </div>
      </div>
    );
  };
  const mobileDetailInformation = (title, value) => {
    return (
      <div className="d-flex flex-row f12-vw mt-2">
        <div className="col px-0">
          <span className="grey">{title}</span>
        </div>
        <div className="col px-0">
          <span className="text-black fw-600">: {value ? value : "-"}</span>
        </div>
      </div>
    );
  };

  const paymentInput = (title, value, onChange, autoFocus) => {
    return (
      <div className="d-flex flex-column py-2">
        <div className="col px-0">
          <span className={`text-black f8-vw font-weight-bold`}>{title}</span>
        </div>
        <div className="col px-0">
          <div className="d-flex flex-row align-items-center">
            <div
              className={`col px-0 text-black `}
              style={{
                border: "1px solid #5f0593",
                borderRadius: 5,
                backgroundColor: "white",
                display: "flex",
                alignItems: "center",
              }}
            >
              <span className={`px-3 f8-vw fw-700`}>Rp</span>
              <input
                type="text"
                className="form-control text-right border-0 form-control-sm f8-vw font-weight-bold"
                value={priceSplit(value)}
                style={{ textAlign: "right", boxShadow: "none" }}
                onFocus={(e) => (e.target.value = "")}
                autoFocus={autoFocus}
                onChange={onChange}
                maxLength={12}
              />
            </div>
          </div>
        </div>
      </div>
    );
  };
  return (
    <>
      {IS_MOBILE ? (
        <div className="py-2" style={{ width: "21rem" }}>
          <div className="px-4">
            <div className="blue-title fw-700 f14-vw">
              <span className="mr-2">
                <Icon
                  icon={iconUrl.formMultiple20Filled}
                  color="orange"
                  width={30}
                />
              </span>
              Konfirmasi Pemesanan
            </div>
            <div className="px-1">
              <hr className="separator-blue my-1" />
            </div>
          </div>
          <div
            className="d-flex flex-column px-3 scroll-popup hide-scrollbar"
            style={{ height: "22rem" }}
          >
            <div className="col">
              <div className="">
                <HeaderName
                  text="Informasi Tamu"
                  fontSize="f14-vw fw-600"
                  color2="purple"
                />
              </div>
              <div className="my-1">
                {mobileDetailInformation("Nama OTA", otherSelected?.label)}
                {mobileDetailInformation("Kode Booking", detail?.bookingCode)}
                {mobileDetailInformation(
                  "Tanggal Booking",
                  dateFormat(detail?.booking_date)
                )}
                {mobileDetailInformation(
                  "Nama Pemesan",
                  detail?.guestName.join(", ")
                )}
                {mobileDetailInformation(
                  "Nomor Ponsel",
                  "+" + detail?.phoneNumber
                )}
                {mobileDetailInformation("Email", showMore(detail?.email))}
                {mobileDetailInformation("Purpose", purposeSelected?.label)}
                {mobileDetailInformation(
                  "Guest Type",
                  guestTypeSelected?.label
                )}
              </div>
            </div>
            <div className="col">
              <div className="mt-2">
                <HeaderName
                  text="Informasi Pemesanan"
                  fontSize="f14-vw fw-600"
                  color2="purple"
                />
              </div>
              <div className="my-1">
                {mobileDetailInformation(
                  "Check In",
                  dateFormat(detail?.checkin)
                )}
                {mobileDetailInformation(
                  "Check Out",
                  dateFormat(detail?.checkout)
                )}
                {mobileDetailInformation(
                  "Durasi",
                  getDayDuration(detail?.checkin, detail?.checkout) + " Malam"
                )}
                {mobileDetailInformation("Tipe Kamar", roomSelected?.label)}
                {mobileDetailInformation(
                  "Tamu & Kamar",
                  detail?.guest + " Tamu, " + detail?.room + " Kamar"
                )}
              </div>
            </div>
            <div className="col">
              <div className="column no-gutters">
                {mobileDetailInformation(
                  "Total Harga Kamar",
                  `Rp ${priceSplit(detail.totalcharge)}`
                )}
                <div className="col mt-2">
                  <HeaderName
                    text="Bukti Pemesanan"
                    fontSize="f14-vw fw-600"
                    color2="purple"
                  />
                  <div className="mt-1">
                    <span>
                      <img
                        src={showImg}
                        alt=""
                        className="img-fluid"
                        style={{ width: "100%" }}
                        loading="lazy"
                      />
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="px-4">
            <div className="px-1">
              <hr className="separator-blue" />
            </div>
          </div>

          <div className="px-4 my-2">
            <div className="text-center f10-vw">
              Periksa kembali detail Pemesanan anda
            </div>
            <div className="text-center f10-vw">
              Klik Confirm untuk melanjutkan
            </div>
          </div>
          <div className="d-flex justify-content-between my-3 px-4">
            <div className="col-5 pl-0">
              <button
                className="btn btn-sm btn-block btn-outline-purple f12-vw"
                onClick={() => close()}
              >
                Back
              </button>
            </div>
            <div className="col-5 pr-0">
              <button
                className={`btn btn-sm btn-block text-white f12-vw btn-gradient`}
                onClick={() => confirm()}
              >
                Confirm
              </button>
            </div>
          </div>
        </div>
      ) : (
        <div className="d-flex flex-column p-3" style={{ width: "50rem" }}>
          <div className="col">
            <div className="purple fw-600 f12-vw">
              <span className="mr-2">
                <Icon
                  icon={iconUrl.formMultiple20Filled}
                  color="orange"
                  width={25}
                />
              </span>
              Konfirmasi Pemesanan
            </div>
            <hr className="separator-blue my-2" />
          </div>
          <div className="col">
            <div className="d-flex flex-row">
              <div className="col px-0">
                <div className="">
                  <HeaderName
                    text="Informasi Tamu"
                    fontSize="f10-vw fw-600"
                    color2="purple"
                  />
                </div>
                <div className="my-1">
                  {detailInformation("Nama OTA", otherSelected?.label)}
                  {detailInformation("Kode Booking", detail?.bookingCode)}
                  {detailInformation(
                    "Tanggal Booking",
                    dateFormat(detail?.booking_date)
                  )}
                  {detailInformation(
                    "Nama Pemesan",
                    detail?.guestName.join(", ")
                  )}
                  {detailInformation("Nomor Ponsel", "+" + detail?.phoneNumber)}
                  {detailInformation("Email", showMore(detail?.email))}
                  {detailInformation("Purpose", purposeSelected?.label)}
                  {detailInformation("Guest Type", guestTypeSelected?.label)}
                </div>
              </div>
              <div className="col pr-0">
                <div className="">
                  <HeaderName
                    text="Informasi Pemesanan"
                    fontSize="f10-vw fw-600"
                    color2="purple"
                  />
                </div>
                <div className="my-1">
                  {detailInformation("Check In", dateFormat(detail?.checkin))}
                  {detailInformation("Check Out", dateFormat(detail?.checkout))}
                  {detailInformation(
                    "Durasi",
                    getDayDuration(detail?.checkin, detail?.checkout) + " Malam"
                  )}
                  {detailInformation("Tipe Kamar", roomSelected?.label)}
                  {detailInformation(
                    "Tamu & Kamar",
                    detail?.guest + " Tamu, " + detail?.room + " Kamar"
                  )}
                </div>
              </div>
              <div className="col px-0">
                <div className="column no-gutters">
                  <div className="col my-1">
                    <HeaderName
                      text="Bukti Pemesanan"
                      fontSize="f10-vw fw-600"
                      color2="purple"
                    />
                    <div className="mt-1">
                      <CameraWebcam
                        setfileUpload={setfileUpload}
                        title={"Upload Bukti Pemesanan"}
                        height="110px"
                        fileUpload={fileUpload}
                        setShowImg={setShowImg}
                        showImg={showImg}
                      />
                    </div>
                  </div>
                  {paymentInput("Total Harga Kamar", detail.totalcharge, (e) =>
                    handleRoomCharge(e)
                  )}
                </div>
              </div>
            </div>
            <div className="">
              <hr className="separator-blue" />
            </div>

            <div className="my-2">
              <div className="text-center f8-vw">
                Periksa kembali detail Pemesanan anda
              </div>
              <div className="text-center f8-vw">
                Klik Confirm untuk melanjutkan
              </div>
            </div>
          </div>
          <div className="col">
            <div className="d-flex justify-content-between ">
              <div className="col-2 px-0">
                <button
                  className="btn btn-sm btn-block btn-outline-purple f8-vw"
                  onClick={() => close()}
                >
                  Back
                </button>
              </div>
              <div className="col-2 px-0">
                <button
                  className={`btn btn-sm btn-block text-white f8-vw ${
                    fileUpload?.length > 0 && detail?.totalcharge > 0
                      ? "btn-gradient"
                      : "btn-secondary"
                  }`}
                  onClick={() => confirm()}
                  disabled={
                    fileUpload.length > 0 && detail.totalcharge > 0
                      ? false
                      : true
                  }
                >
                  Confirm
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
