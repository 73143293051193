import { Fragment, useCallback, useContext, useEffect, useState } from "react";
import { BreadcrumbContext } from "../../../common/BreadcrumbContext";
import NotifMessage, {
  ButtonClose,
  ButtonConfirm,
} from "../../../common/Notif";
import PopupModal from "../../../common/PopupModal";
import {
  addDays,
  checkExtendDate,
  formatDate,
  generateBookingCode,
  getClientTimeWithoutSecond,
  getDayDuration,
  getLocalPenaltySubmit,
  getLocalRoomCompensation,
  getPriceCompensation,
  getPricePenalty,
  getServerTime,
  getTimezoneOffsetClient,
  isEmpty,
  priceSplit,
  showMoreGuest,
  splitText,
  totalCompensation,
} from "../../../utils";
import {
  addData,
  getData,
  getErr,
  safeErrCode,
} from "../../../utils/api-request";
import history from "../../../utils/history";
import { CheckoutDetail } from "./CheckoutDetail";
import { CheckoutList } from "./CheckoutList";
import { CheckoutConfirm } from "./CheckoutConfirm";
import { CompensationPopup } from "./CompensationPopup";
import { PaymentBreakage } from "./PaymentBreakage";
// import { PaymentBreakageSuccess } from "./PaymentBreakageSuccess";
import { RoomChangeNumber } from "./RoomChangeNumber";
import { Print } from "../../../print/Print";
import {
  createLineDash,
  createLineSolid,
  genBookingId,
  genContent1,
  genContent2,
  genPaymentID,
} from "../../../print/common/printer-helper";
import { IS_MOBILE } from "../../../common/Responsive";
import { BottomSheet } from "react-spring-bottom-sheet";
import { Icon } from "@iconify/react";
import { iconUrl } from "../../../config";
import { DateRangeFilterMobile } from "../../../common/DateRange";
import { mobileSearchInput } from "../../booking/bookingHelper";
import { PopupRoomNumber } from "../../../components/Popup/PopupRoomNumber";
import { t } from "../../../common/ErrorMessage";
import { ConfirmChangeRoom } from "./ConfirmChangeRoom";
import { lessThanToday } from "../../loss-found/common/utils";
import { WarningModal } from "../../../common/WarningModal";
import ExtendsModal from "./ExtendsModal";
import { PaymentExtendSuccess } from "./PaymentExtendSuccess";
import { PaymentExtends } from "./PaymentExtends";
import PenaltyChargePopup from "./PenaltyChargePopup";
const FONT_SIZE = IS_MOBILE ? "f12-vw" : "f8-vw";
const FONT_SIZE_HEADER = IS_MOBILE ? "f14-vw" : "f10-vw";

const PopupChangeRoomSuccess = ({
  old_room_number,
  new_room_number,
  close,
}) => {
  return (
    <div
      className="d-flex flex-column py-3"
      style={{ width: IS_MOBILE ? "19rem" : "22rem" }}
    >
      <div className="col">
        <div className="d-flex justify-content-center">
          <img src="/img/housekeeping/approve.svg" alt="" loading="lazy" />
        </div>
      </div>
      <div className="col">
        <div className="text-center">
          <span className={"fw-700 " + FONT_SIZE_HEADER}>
            Room Change Success
          </span>
        </div>
        <div className={"text-center mt-2 " + FONT_SIZE}>
          Change room from <span className="fw-700">{old_room_number}</span> to{" "}
          <span className="fw-700">{new_room_number} </span> success
        </div>
      </div>
      <div className="col">
        <div className="d-flex justify-content-center mt-3">
          <div className="col-6">
            <button
              className={
                "btn btn-sm bg-green rounded-input text-white btn-block " +
                FONT_SIZE
              }
              onClick={() => close()}
            >
              OK
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

const PopupDirect = ({ close, title }) => {
  return (
    <div
      className="d-flex flex-column py-3"
      style={{ width: IS_MOBILE ? "19rem" : "22rem" }}
    >
      <div className="col">
        <div className="d-flex justify-content-center">
          <img
            src="/img/popup/breakage-success-created.svg"
            alt=""
            loading="lazy"
          />
        </div>
      </div>
      <div className="col">
        <div className="text-center">
          <span className={"fw-700 " + FONT_SIZE_HEADER}>{title}</span>
        </div>
        <div className={"text-center mt-2 " + FONT_SIZE}>
          Silahkan lanjutkan di menu Proses Pembayaran
        </div>
      </div>
      <div className="col">
        <div className="d-flex justify-content-center mt-3">
          <div className="col-6">
            <button
              className={
                "btn btn-sm bg-green rounded-input text-white btn-block " +
                FONT_SIZE
              }
              onClick={() => close()}
            >
              OK
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

const PopupDetailConfirmBreakage = ({
  detail,
  room,
  close,
  confirm,
  loading,
}) => {
  const detailItem = (item) => {
    return (
      <div className={"d-flex " + FONT_SIZE}>
        <div className="col-4 px-0">{showMoreGuest(item?.item_name, 15)}</div>
        <div className="col-2 pr-0">{item?.qty}</div>
        <div className="col-3 px-0 text-right">{priceSplit(item?.value)}</div>
        <div className="col-3 px-0 text-right fw-700">
          {priceSplit(item?.value * item?.qty)}
        </div>
      </div>
    );
  };
  return (
    <div
      className="d-flex flex-column px-4 py-3"
      style={{ width: IS_MOBILE ? "21rem" : "25rem" }}
    >
      <div className="col px-0">
        <div className={`blue-title fw-700 ${IS_MOBILE ? "f16-vw" : "f12-vw"}`}>
          <span className="mr-2">
            <Icon
              icon={iconUrl.clipboardTextSearch}
              color="orange"
              width={25}
            />
          </span>
          Breakage Confirmation
        </div>
      </div>
      <div className="col px-0">
        <hr className="separator-blue mb-0 mt-2" />
        <div className={`d-flex py-2 ${IS_MOBILE ? "f14-vw" : "f10-vw"}`}>
          <div className="mr-2 black-text">Room Number</div>
          <div className="black-text fw-700">: {room[0]}</div>
        </div>
        <hr className="separator-blue mt-0 mb-2" />
      </div>
      <div className={"col px-0 py-2"}>
        <span className={`purple fw-700 ${IS_MOBILE ? "f14-vw" : "f12-vw"}`}>
          Informasi Item
        </span>
      </div>
      <div
        className={IS_MOBILE ? "scroll-popup hide-scrollbar" : "scroll-popup"}
        style={{ maxHeight: "15rem" }}
      >
        {detail?.length > 0 &&
          detail.map((item) => {
            return <Fragment key={item.item_id}>{detailItem(item)}</Fragment>;
          })}
      </div>
      <div className={"col px-0"}>
        <hr className="separator-blue mt-0 my-2" />
        <div className={"d-flex justify-content-between " + FONT_SIZE}>
          <div className="col px-0 fw-700">Total Ganti Rugi</div>
          <div className="col px-0 text-right fw-700">
            {priceSplit(totalCompensation(detail))}
          </div>
        </div>
        <hr className="separator-blue mt-0 my-2" />
      </div>
      <div className="col px-0 pb-2">
        <div className="text-center">
          <span
            className={IS_MOBILE ? "f10-vw text-center" : "f8-vw text-center"}
          >
            Apakah anda yakin ingin melanjutkan?
          </span>
        </div>
      </div>
      <div className="col px-0">
        <div className="d-flex justify-content-between">
          <div className="col-6 pl-0">
            <button
              className={"btn btn-block btn-outline-purple fw-700 " + FONT_SIZE}
              onClick={() => close()}
            >
              Back
            </button>
          </div>
          <div className="col-6 pr-0">
            <button
              className={`btn btn-block text-white ${
                !loading ? "btn-gradient" : "btn-secondary"
              } fw-700 ${FONT_SIZE}`}
              onClick={() => {
                confirm();
              }}
              disabled={loading}
            >
              Confirm
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

const PopupReturnDeposit = ({ detailCheckout, close }) => {
  return (
    <div className="d-flex flex-column px-2 py-3" style={{ width: "21rem" }}>
      <div className="col">
        <div className="d-flex justify-content-center">
          <img
            src="/img/popup/return-deposit-new.svg"
            alt="return-deposit"
            loading="lazy"
          />
        </div>
      </div>
      <div className="col">
        <div className="text-center">
          <span className={"fw-700 " + FONT_SIZE_HEADER}>
            Check Out Berhasil
          </span>
        </div>
      </div>
      <div className="col">
        <hr className="separator-blue my-2" />
        <div className="d-flex flex-column text-center py-2 my-2">
          <div className={FONT_SIZE}>Pengembalian Deposit</div>
          <div className={"font-weight-bold " + FONT_SIZE_HEADER}>
            Rp {priceSplit(detailCheckout?.deposit) || 0}
          </div>
        </div>
        <hr className="separator-blue my-2" />
      </div>
      <div className="col">
        <div className="d-flex justify-content-center mt-3">
          <div className="col-6">
            <button
              className={
                "btn btn-sm bg-green fw-600 text-white btn-block " + FONT_SIZE
              }
              onClick={() => close()}
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

const Checkout = () => {
  const timezone = localStorage?.getItem("timezone");
  const {
    setpathLocation,
    isOpeningShift,
    userProfile,
    openButtonSheet,
    setOpenButtonSheet,
    setShowFilter,
  } = useContext(BreadcrumbContext);
  const [isOpen, setIsOpen] = useState(false);
  const [openRoomNumber, setOpenRoomNumber] = useState(false);
  const [modeForm, setModeForm] = useState("checkout-list");
  const [modeSuccess, setmodeSuccess] = useState("");
  const [modeModal, setModeModal] = useState("");
  const [booking, setBooking] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [error, setError] = useState("");
  const [bookingCode, setBookingCode] = useState("");
  const [guestFullname, setguestFullname] = useState("");
  const [detailCheckout, setdetailCheckout] = useState({});
  const [lateCheckout, setLateCheckout] = useState(0);
  const [compensationList, setCompensationList] = useState([]);
  const [headerPopup, setHeaderPopup] = useState("");
  const [compensation, setCompensation] = useState(0);
  const [totalPaid, setTotalPaid] = useState(0);
  const [paymentCash, setPaymentCash] = useState(0);
  const [result, setResult] = useState([]);
  const [penaltyResult, setPenaltyResult] = useState([]);
  const [roomSelected, setRoomSelected] = useState([]);
  const typeRoom =
    localStorage?.getItem("room_type") &&
    JSON.parse(localStorage?.getItem("room_type"));
  const paymentMethod = localStorage?.getItem("payment_method")
    ? JSON.parse(localStorage?.getItem("payment_method"))
    : [];
  const bankList = localStorage?.getItem("bank_list")
    ? JSON.parse(localStorage?.getItem("bank_list"))
    : [];
  const [change, setChange] = useState(0);
  const [bankSelected, setBankSelected] = useState([]);
  const [paymentSelected, setpaymentSelected] = useState({});
  const [roomList, setRoomList] = useState([]);
  const [roomNumber, setRoomNumber] = useState([]);
  const [imgPopup, setImgPopup] = useState("");
  const isPicked = roomNumber?.length >= 1 ? true : false;
  const [reason, setReason] = useState("");
  const [roomPrice, setRoomPrice] = useState(0);
  const [requestSelected, setRequestSelected] = useState([]);
  const [isPrint, setIsPrint] = useState(false);
  const [modePrint, setModePrint] = useState("");
  const [paymentId, setPaymentId] = useState("");
  const [roomNumberFilter, setRoomNumberFilter] = useState("");
  const [redirected, setRedirected] = useState("");
  const [breakageDetail, setBreakageDetail] = useState([]);
  const [extendsPrice, setExtendsPrice] = useState({});
  const [billingExtend, setBillingExtend] = useState("");
  const [date, setDate] = useState(null);
  const [errorExtend, setErrorExtend] = useState("");
  const [loadingExtend, setLoadingExtend] = useState(false);
  const [penaltyCharge, setPenaltyCharge] = useState(0);
  const [penaltyList, setPenaltyList] = useState([]);
  const [rateKeyLateCheckout, setRateKeyLateCheckout] = useState("");
  const [rateKeyChangeRoom, setRateKeyChangeRoom] = useState("");

  const [range, setRange] = useState({
    start: null,
    end: null,
  });

  const getCheckoutList = (start, end) => {
    const params = {
      ...(!!bookingCode && { booking_id: bookingCode.replace(" ", "") }),
      ...(!!start && { date_start: formatDate(start) }),
      ...(!!end && { date_end: formatDate(end) }),
      ...(!!guestFullname && { guestname: guestFullname }),
      ...(!!roomNumberFilter && { room_number: roomNumberFilter }),
    };

    let paramsFormatted = null;

    if (!isEmpty(params)) {
      paramsFormatted = new URLSearchParams(params);
    }

    const endpoint = `/api/v1/checkouts?${
      paramsFormatted !== null ? `&${paramsFormatted}` : ""
    }`;

    setLoading(true);
    getData(endpoint)
      .then((resp) => {
        if (resp?.data?.length > 0) {
          setError(null);
          setBooking(resp.data);
        } else if (resp?.data?.length < 1) {
          setError("Data Check Out tidak ditemukan");
          setBooking([]);
        } else {
          resp.json().then((err) => {
            setError(t(safeErrCode(err)));
          });
          setBooking([]);
        }
      })
      .catch((err) => setError(t(getErr(err))))
      .finally(() => setLoading(false));
  };

  const getLateCheckout = (detail) => {
    const { booking_id, activity_id } = detail;
    setLoading(true);
    getData(
      `/api/v1/getlatecheckout?booking_id=${booking_id}&activity_id=${activity_id}`
    )
      .then((resp) => {
        if (resp.charge !== undefined && resp.charge !== null) {
          setLateCheckout(resp.charge);
          setRateKeyLateCheckout(resp?.rate_key);
        } else {
          resp.json().then((err) => {
            setError(t(safeErrCode(err)));
          });
        }
      })
      .catch((err) => setError(t(getErr(err))))
      .finally(() => setLoading(false));
  };

  const getCompensationList = () => {
    setLoading(true);
    getData("/api/v1/compensationlist")
      .then((resp) => {
        if (resp.data) {
          const d = resp.data.map((item) => {
            return {
              category_id: item.categ_id,
              category_name: item.categ_name,
              item_id: item.item_id,
              item_name: item.item_name,
              value: item.item_value,
              checked: false,
              qty: 0,
            };
          });
          setCompensationList(d);
          localStorage.setItem("compensation", JSON.stringify(d));
        }
      })
      .catch((err) => setError(t(getErr(err))))
      .finally(() => setLoading(false));
  };

  const getPriceHMS = useCallback(() => {
    if (roomSelected?.value !== undefined && roomNumber?.length > 0) {
      getData(
        `/api/v1/getpriceplatform2?checkout_date=${formatDate(
          new Date(detailCheckout?.checkout_date)
        )}&room_type_id=${roomSelected?.value}&room_qty=${
          roomNumber?.length
        }&oldroom_type_id=${detailCheckout?.room_type}&activity_id=${
          detailCheckout?.activity_id
        }`
      )
        .then((resp) => {
          if (resp?.charge >= 0) {
            if (requestSelected?.value === 1) {
              setRoomPrice(0);
            } else {
              setRoomPrice(resp.charge);
            }
            setRateKeyChangeRoom(resp?.rate_key);
          } else {
            resp.json().then((err) => {
              setError(t(safeErrCode(err)));
            });
          }
        })
        .catch((error) => {
          let err = getErr(error);
          setError(t(err));
        });
    }
  }, [
    roomSelected?.value,
    roomNumber?.length,
    detailCheckout?.checkout_date,
    detailCheckout?.room_type,
    detailCheckout?.activity_id,
    requestSelected?.value,
  ]);

  const getRoomNumber = useCallback(() => {
    if (roomSelected?.value !== undefined) {
      getData(`/api/v1/detailroom?room_type=${roomSelected?.value}`)
        .then((resp) => {
          if (resp.data) {
            if (resp.data.length > 0) {
              let d = resp.data.map((item) => {
                return {
                  room_number: item.room_number,
                  floor: item.floor,
                  bed_type_name: item.bed_type_name,
                  room_type: item.room_type,
                  bed_size: item.bed_size,
                  status: item.status,
                  checked: false,
                };
              });
              setRoomList(d);
            } else {
              setHeaderPopup("");
              setRoomList([]);
              setError(
                "Nomor Kamar tidak tersedia, silahkan pilih tipe kamar lain"
              );
              setImgPopup("/img/error-icon/empty-room-number.svg");
            }
          } else {
            resp.json().then((err) => {
              setError(t(safeErrCode(err)));
            });
            setImgPopup("/img/error-icon/empty-room-number.svg");
          }
        })
        .catch((error) => {
          let err = getErr(error);
          setError(t(err));
          setImgPopup("/img/error-icon/empty-room-number.svg");
        });
    }
  }, [roomSelected]);

  const getPenaltyList = () => {
    setLoading(true);
    getData("/public/v1/penalty_list")
      .then((resp) => {
        if (resp.data) {
          const d = resp?.data.map((item) => {
            return {
              category_id: item.fine_cat_id,
              category_name: item.fine_cat_name,
              item_id: item.fine_id,
              item_name: item.fine_name,
              value: item.fine_value,
              checked: false,
            };
          });
          setPenaltyList(d);
          localStorage.setItem("penalty", JSON.stringify(d));
        }
      })
      .catch((err) => setError(t(getErr(err))))
      .finally(() => setLoading(false));
  };

  const handleCheckout = () => {
    const { activity_id, booking_id, room_type, room_number } = detailCheckout;

    const payload = {
      activity_id,
      booking_id,
      room_type_id: room_type,
      compen_items: result.length > 0 ? result : JSON.stringify([]),
      compensation: parseInt(compensation),
      latecheckout: lateCheckout,
      payment: totalPaid,
      pen_items: penaltyResult.length > 0 ? penaltyResult : JSON.stringify([]),
      pinalty: parseInt(penaltyCharge),
      room_number,
      idempoten:
        Math.random()
          .toString()
          .slice(2, 15) +
        Math.random()
          .toString()
          .slice(2, 15),
      ...(!!rateKeyLateCheckout && { rate_key: rateKeyLateCheckout }),
    };

    const formData = new FormData();
    for (const key in payload) {
      formData.append(key, payload[key]);
    }

    setLoadingSubmit(true);

    addData("/api/v1/checkouts", formData)
      .then((resp) => {
        if (resp.data) {
          if (totalPaid === 0 && detailCheckout?.deposit > 0) {
            setmodeSuccess("return-deposit");
            setIsPrint(true);
            setModePrint("return-deposit");
          } else {
            setmodeSuccess("checkout-confirm-success");
          }
        } else {
          resp.json().then((err) => {
            if (err.code === "E2011") {
              setRedirected("process");
            } else if (err.code === "E2012") {
              setRedirected("confirm");
            }
            setError(t(safeErrCode(err)));
          });
          setmodeSuccess("error");
          setHeaderPopup("Check Out Gagal");
        }
      })
      .catch((err) => {
        setError(t(getErr(err)));
        setmodeSuccess("error");
        setHeaderPopup("Check Out Gagal");
      })
      .finally(() => setLoadingSubmit(false));
  };

  const submitBreakage = () => {
    const payload = {
      activity_id: detailCheckout?.activity_id,
      booking_id: detailCheckout?.booking_id,
      compen_items: !!result ? result : JSON.stringify([]),
      compensation: parseInt(getPriceCompensation()),
      room_number: detailCheckout?.room_number,
      // paymenttype: paymentSelected?.value,
      // bank_name: bankSelected.value ? bankSelected.bank_name : "",
      // bankaccno: bankSelected.value ? bankSelected.bank_accno : "",
    };

    const formData = new FormData();
    for (const key in payload) {
      formData.append(key, payload[key]);
    }
    // for (const key in payload) {
    //   console.log(key, payload[key]);
    // }
    setLoadingSubmit(true);
    addData("/api/v1/lnb", formData)
      .then((resp) => {
        setLoadingSubmit(false);
        if (resp.message) {
          setPaymentId(resp.data.payment_id);
          setmodeSuccess("breakage-success");
          // setModePrint("print-breakage");
          // setIsPrint(true);
        } else {
          resp.json().then((err) => {
            setError(t(safeErrCode(err)));
          });
          setmodeSuccess("error");
          setHeaderPopup("Billing Breakage Gagal");
        }
      })
      .catch((error) => {
        setLoadingSubmit(false);
        setError(t(getErr(error)));
        setmodeSuccess("error");
        setHeaderPopup("Billing Breakage Gagal");
      });
  };

  const submitChangeRoom = () => {
    const payload = {
      activity_id: detailCheckout?.activity_id,
      booking_id: detailCheckout?.booking_id,
      room_number: detailCheckout?.room_number,
      newroom_number: roomNumber?.join(","),
      newroom_type_id: roomSelected?.value,
      reasons: reason,
      on_behalf: requestSelected?.value,
      ...(!!rateKeyChangeRoom && { rate_key: rateKeyChangeRoom }),
      // paymenttype:
      //   paymentSelected?.value !== undefined ? paymentSelected?.value : "",
      // bank_name: bankSelected.value ? bankSelected.bank_name : "",
      // bankaccno: bankSelected.value ? bankSelected.bank_accno : "",
    };

    const formData = new FormData();
    for (const key in payload) {
      formData.append(key, payload[key]);
    }
    setLoadingSubmit(true);
    addData("/api/v1/changeroom", formData)
      .then((resp) => {
        setLoadingSubmit(false);
        if (resp.message) {
          setPaymentId(resp?.data.payment_id);
          if (requestSelected?.value === 2 && roomPrice > 0) {
            // setModePrint("change-room");
            // setIsPrint(true);
            setmodeSuccess("change-room-success");
          } else {
            setmodeSuccess("change-room-direct");
          }
        } else {
          resp.json().then((err) => {
            setError(t(safeErrCode(err)));
            if (err.code === "E2011") {
              setRedirected("process");
            } else if (err.code === "E2012") {
              setRedirected("confirm");
            }
          });
          setmodeSuccess("error");
          setHeaderPopup("Change Room Gagal");
        }
      })
      .catch((error) => {
        setLoadingSubmit(false);
        setError(t(getErr(error)));
        setmodeSuccess("error");
        setHeaderPopup("Change Room Gagal");
      });
  };

  const handleEnter = (e) => {
    if (e.key === "Enter") {
      getCheckoutList(range.start, range.end);
    }
  };

  const handleConfirm = () => {
    if (!lessThanToday(detailCheckout?.checkout_date)) {
      openModal();
      setmodeSuccess("checkout-confirm");
    } else {
      openModal();
      setmodeSuccess("checkout-reconfirm");
    }
  };

  const reset = () => {
    setIsOpen(false);
    setModeForm("checkout-list");
    setmodeSuccess("");
    setHeaderPopup("");
    setBooking([]);
    setLoading(false);
    setError("");
    setBookingCode("");
    setguestFullname("");
    setdetailCheckout({});
    setLateCheckout(0);
    setCompensationList([]);
    setHeaderPopup("");
    setCompensation(0);
    setTotalPaid(0);
    setChange(0);
    setPaymentCash(0);
    setBankSelected([]);
    setpaymentSelected([]);
    setResult([]);
    setRange({
      start: null,
      end: null,
    });
    setReason("");
    setRoomNumber([]);
    setRoomPrice(0);
    setRequestSelected([]);
    setIsPrint(false);
    setModePrint("");
    setRedirected("");
    setExtendsPrice({});
    setBillingExtend("");
    setDate(null);
    setErrorExtend("");
    setLoadingExtend(false);
    setPenaltyCharge(0);
    setPenaltyList([]);
    setRateKeyLateCheckout("");
    setRateKeyChangeRoom("");
  };

  const arrRoomNumber = () => {
    let d = [detailCheckout?.room_number];

    let room = d.map((item) => {
      return {
        room: item,
        itemids: [],
        qty: [],
        price: 0,
      };
    });

    let data_equipment = d.map((item) => {
      return {
        room: item,
        compensation: [],
      };
    });

    let penalty_data = d.map((item) => {
      return {
        room: item,
        penalty: [],
      };
    });

    let penalty_submit = d.map((item) => {
      return {
        room: item,
        itemids: [],
        qty: [],
        price: 0,
      };
    });

    localStorage.setItem("room", JSON.stringify(room));
    localStorage.setItem("data_equipment", JSON.stringify(data_equipment));
    localStorage.setItem("penalty_data", JSON.stringify(penalty_data));
    localStorage.setItem("penalty_submit", JSON.stringify(penalty_submit));
  };

  const date_now = new Date();
  const hotel_info = JSON.parse(localStorage.getItem("info_hotel"));

  // const validateChangeRoom = () => {
  //   if (requestSelected?.value === 2 && roomPrice > 0) {
  //     setmodeSuccess("payment-change-room");
  //   } else {
  //     submitChangeRoom();
  //   }
  // };

  const openModal = () => {
    setIsOpen(!isOpen);
  };
  const modalRoomNumber = () => {
    setOpenRoomNumber(!openRoomNumber);
  };

  const resetFilter = () => {
    setRange({ ...range, start: null, end: null });
    setBookingCode("");
    setBooking([]);
    setguestFullname("");
    setRoomNumberFilter("");
    getCheckoutList();
  };

  const handleClear = () => {
    setRange({ ...range, start: null, end: null });
  };

  const checkPriceExtend = useCallback(() => {
    if (date !== null) {
      const { booking_id, activity_id } = detailCheckout;
      setLoadingExtend(true);
      const payload = {
        booking_id,
        activity_id,
        extend_todate: formatDate(date),
      };
      let paramsFormatted = null;

      if (!isEmpty(payload)) {
        paramsFormatted = new URLSearchParams(payload);
      }

      const endpoint = `/api/v1/get_extend_price?${
        paramsFormatted !== null ? `&${paramsFormatted}` : ""
      }`;
      getData(endpoint)
        .then((resp) => {
          if (resp?.message === "Success") {
            setExtendsPrice(resp?.data);
            setmodeSuccess("extends-booking");
            setErrorExtend("");
            setIsOpen(true);
          } else {
            resp.json().then((err) => {
              setIsOpen(true);
              setErrorExtend(t(safeErrCode(err)));
              if (err.code === "E2011") {
                setRedirected("process");
                setHeaderPopup("Extend Booking Gagal");
                setmodeSuccess("error");
              } else if (err.code === "E2020") {
                setRedirected("confirm");
                setHeaderPopup("Extend Booking Gagal");
                setmodeSuccess("error");
              } else if (err.code === "E2000") {
                setmodeSuccess("extends-booking");
              }
            });
          }
        })
        .catch((err) => {
          console.log(err);
          setErrorExtend(t(getErr(err)));
          setHeaderPopup("Extend Booking Gagal");
          setmodeSuccess("error");
          setIsOpen(true);
        })
        .finally(() => setLoadingExtend(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date]);

  useEffect(() => {
    setpathLocation("/check-out/proses-check out");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    checkPriceExtend();
  }, [checkPriceExtend]);

  useEffect(() => {
    getPriceHMS();
  }, [getPriceHMS]);

  useEffect(() => {
    if (
      modeForm === "checkout-list" &&
      bookingCode === "" &&
      guestFullname === "" &&
      roomNumberFilter === "" &&
      range.start === null &&
      range.end === null &&
      !isOpeningShift
    ) {
      getCheckoutList();
      getCompensationList();
      getPenaltyList();
    }

    if (modeForm === "checkout-detail" && !!detailCheckout?.booking_id) {
      getLateCheckout(detailCheckout);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    modeForm,
    detailCheckout,
    isOpeningShift,
    bookingCode,
    guestFullname,
    range,
    roomNumberFilter,
  ]);

  useEffect(() => {
    let interval;
    if (
      modeForm === "checkout-detail" &&
      detailCheckout?.booking_id &&
      modeSuccess === ""
    ) {
      interval = setInterval(() => {
        getLateCheckout(detailCheckout);
      }, 60000);
    }

    return () => {
      clearInterval(interval);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [detailCheckout?.booking_id, modeForm, modeSuccess]);

  useEffect(() => {
    if (modeForm === "checkout-confirm" && isOpen) {
      setTimeout(() => {
        setIsOpen(false);
      }, 60000);
    }
  }, [isOpen, modeForm]);

  useEffect(() => {
    if (detailCheckout?.room_number) {
      arrRoomNumber();
    } else {
      localStorage.setItem("room", []);
      localStorage.setItem("penalty_submit", []);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [detailCheckout]);

  useEffect(() => {
    if (compensation > 0) {
      let x = getLocalRoomCompensation();
      let result = x.map(({ price, ...rest }) => ({ ...rest }));
      setResult(JSON.stringify(result));
    } else {
      setResult([]);
    }
  }, [compensation]);

  useEffect(() => {
    if (penaltyCharge > 0) {
      let x = getLocalPenaltySubmit();
      let result = x.map(({ price, ...rest }) => ({ ...rest }));
      setPenaltyResult(JSON.stringify(result));
    } else {
      setPenaltyResult([]);
    }
  }, [penaltyCharge]);

  useEffect(() => {
    if (localStorage?.getItem("room")) {
      setCompensation(getPriceCompensation());
    } else {
      setCompensation(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localStorage.getItem("room")]);

  useEffect(() => {
    if (localStorage?.getItem("penalty_submit")) {
      setPenaltyCharge(getPricePenalty());
    } else {
      setPenaltyCharge(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localStorage.getItem("penalty_submit")]);

  useEffect(() => {
    setTotalPaid(
      parseInt(compensation) + parseInt(lateCheckout) + parseInt(penaltyCharge)
    );
  }, [compensation, lateCheckout, penaltyCharge]);

  useEffect(() => {
    getRoomNumber();
  }, [getRoomNumber]);

  useEffect(() => {
    if (isPrint) {
      setTimeout(() => {
        setIsPrint(false);
      }, 6000);
    }
  }, [isPrint]);

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
    return () => {
      document.body.style.overflow = "unset";
    };
  }, [isOpen]);

  useEffect(() => {
    if (modeForm === "checkout-list") {
      setShowFilter(true);
    }
    return () => {
      setShowFilter(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modeForm]);

  return (
    <div className={`my-1 px-3 py-1 ${!isOpeningShift ? "" : "not-opening"}`}>
      {modeForm === "checkout-list" ? (
        <CheckoutList
          booking={booking}
          bookingCode={bookingCode}
          setBookingCode={setBookingCode}
          loading={loading || loadingExtend}
          errMessage={error}
          timezone={timezone}
          onKeyPress={handleEnter}
          range={range}
          setRange={setRange}
          setdetailCheckout={setdetailCheckout}
          guestFullname={guestFullname}
          setguestFullname={setguestFullname}
          setModeForm={setModeForm}
          filterClicked={
            !isOpeningShift
              ? () => getCheckoutList(range.start, range.end)
              : () => null
          }
          checkoutClicked={(item) => {
            setModeForm("checkout-detail");
            setdetailCheckout(item);
          }}
          breakageClicked={(item) => {
            setmodeSuccess("breakage");
            setdetailCheckout(item);
            openModal();
          }}
          changeRoomClicked={(item) => {
            setmodeSuccess("change-room");
            setdetailCheckout(item);
            openModal();
          }}
          extendClicked={(item) => {
            setdetailCheckout(item);
            setDate(checkExtendDate(item?.checkout_date));
          }}
          roomNumberFilter={roomNumberFilter}
          setRoomNumberFilter={setRoomNumberFilter}
          handleClear={handleClear}
        />
      ) : modeForm === "checkout-detail" ? (
        <CheckoutDetail
          detailCheckout={detailCheckout}
          compensation={compensation}
          lateCheckout={lateCheckout}
          totalPaid={totalPaid}
          timezone={timezone}
          compensationClicked={() => {
            openModal();
            setmodeSuccess("compensation");
          }}
          backClicked={() => {
            reset();
          }}
          confirmClicked={() => handleConfirm()}
          penaltyCharge={penaltyCharge}
          penaltyClicked={() => {
            openModal();
            setmodeSuccess("penalty");
          }}
        />
      ) : null}

      {IS_MOBILE && (
        <div>
          <BottomSheet
            open={openButtonSheet}
            onDismiss={() => setOpenButtonSheet(false)}
            snapPoints={({ maxHeight }) => maxHeight}
            header={
              <div className="d-flex justify-content-between">
                <span className="purple f16-vw fw-600 px-2">Filter</span>
                <span
                  className="px-2"
                  onClick={() => setOpenButtonSheet(false)}
                >
                  <Icon icon={iconUrl.closeBold} color={"grey"} width={20} />
                </span>
              </div>
            }
            footer={
              <div className="d-flex justify-content-between">
                <div className="col-6 pl-2">
                  <button
                    className="btn btn-block btn-outline-purple f12-vw"
                    onClick={() => {
                      resetFilter();
                      setOpenButtonSheet(false);
                    }}
                  >
                    Reset
                  </button>
                </div>
                <div className="col-6 pr-2">
                  <button
                    className="btn btn-block btn-gradient f12-vw text-white"
                    onClick={() => {
                      setBooking([]);
                      getCheckoutList(range.start, range.end);
                      setOpenButtonSheet(false);
                    }}
                  >
                    Search
                  </button>
                </div>
              </div>
            }
          >
            <div className="mt-3">
              <div className="px-4 mt-2">
                <label className="mb-0 f12-vw fw-600">
                  Pilih Tanggal Check Out
                </label>
                <DateRangeFilterMobile
                  start={range?.start}
                  end={range?.end}
                  handleStart={(value) =>
                    setRange({ ...range, start: value, end: addDays(value, 2) })
                  }
                  handleEnd={(value) => setRange({ ...range, end: value })}
                  minDate={true}
                  fontSize="f12-vw"
                />
              </div>

              <div className="px-4 mt-3">
                <label htmlFor="" className="f12-vw m-0 p-0 fw-600">
                  Kode Booking
                </label>
                {mobileSearchInput("Cari Kode Booking", bookingCode, (e) =>
                  setBookingCode(generateBookingCode(e.target.value))
                )}
              </div>
              <div className="px-4 mt-3">
                <label htmlFor="" className="f12-vw m-0 p-0 fw-600">
                  Nama Tamu
                </label>
                {mobileSearchInput("Cari Tamu", guestFullname, (e) =>
                  setguestFullname(e.target.value)
                )}
              </div>
              <div className="px-4 mt-3">
                <label htmlFor="" className="f12-vw m-0 p-0 fw-600">
                  Nomor Kamar
                </label>
                {mobileSearchInput("Cari Nomor Kamar", roomNumberFilter, (e) =>
                  setRoomNumberFilter(e.target.value)
                )}
              </div>
            </div>
          </BottomSheet>
        </div>
      )}

      <PopupModal modalIsOpen={isOpen}>
        {modeSuccess === "compensation" ? (
          <CompensationPopup
            compensation={compensationList}
            room={[detailCheckout?.room_number]}
            close={openModal}
            mode={modeSuccess}
            setBreakageDetail={setBreakageDetail}
          />
        ) : modeSuccess === "breakage" ? (
          <CompensationPopup
            compensation={compensationList}
            room={[detailCheckout?.room_number]}
            close={openModal}
            mode={modeSuccess}
            confirm={() => setmodeSuccess("breakage-confirm")}
            setBreakageDetail={setBreakageDetail}
          />
        ) : modeSuccess === "breakage-confirm" ? (
          <PopupDetailConfirmBreakage
            room={[detailCheckout?.room_number]}
            detail={breakageDetail}
            close={() => setmodeSuccess("breakage")}
            compensation={compensation}
            confirm={submitBreakage}
          />
        ) : modeSuccess === "payment-breakage" ? (
          <PaymentBreakage
            bankList={bankList}
            bankSelected={bankSelected}
            paymentMethod={paymentMethod}
            paymentSelected={paymentSelected}
            setBankSelected={setBankSelected}
            setpaymentSelected={setpaymentSelected}
            totalPaid={totalPaid}
            charge={change}
            setCharge={setChange}
            compensation={compensation}
            payment={paymentCash}
            setPayment={setPaymentCash}
            loading={loadingSubmit}
            mode={modeSuccess}
            close={() => {
              setmodeSuccess("breakage");
            }}
            confirm={() => submitBreakage()}
          />
        ) : modeSuccess === "breakage-success" ? (
          // <PaymentBreakageSuccess
          //   bankSelected={bankSelected}
          //   charge={change}
          //   compesation={compensation}
          //   paymentSelected={paymentSelected}
          //   payment={paymentCash}
          //   close={() => {
          //     openModal();
          //     reset();
          //   }}
          //   confirm={() => history?.push("/checkout/payment-confirm")}
          // />
          <PopupDirect
            close={() => {
              openModal();
              history?.push("/checkout/payment-process");
            }}
            title={"Breakage Created!"}
          />
        ) : modeSuccess === "change-room" ? (
          <RoomChangeNumber
            detail={detailCheckout}
            roomSelected={roomSelected}
            setRoomSelected={setRoomSelected}
            typeRoom={typeRoom}
            roomList={roomList}
            openModal={modalRoomNumber}
            setModeModal={setModeModal}
            setImgPopup={setImgPopup}
            roomNumber={roomNumber}
            reason={reason}
            setRoomNumber={setRoomNumber}
            setReason={setReason}
            setError={setError}
            setRequestSelected={setRequestSelected}
            requestSelected={requestSelected}
            loading={loadingSubmit}
            close={() => {
              openModal();
              reset();
            }}
            confirm={() => setmodeSuccess("confirm-change-room")}
          />
        ) : modeSuccess === "confirm-change-room" ? (
          <ConfirmChangeRoom
            detail={detailCheckout}
            roomSelected={roomSelected}
            setRoomSelected={setRoomSelected}
            typeRoom={typeRoom}
            roomList={roomList}
            openModal={modalRoomNumber}
            setModeModal={setModeModal}
            setImgPopup={setImgPopup}
            roomNumber={roomNumber}
            reason={reason}
            setRoomNumber={setRoomNumber}
            setReason={setReason}
            setError={setError}
            setRequestSelected={setRequestSelected}
            requestSelected={requestSelected}
            loading={loadingSubmit}
            close={() => setmodeSuccess("change-room")}
            confirm={submitChangeRoom}
          />
        ) : modeSuccess === "change-room-success" ? (
          <PopupDirect
            close={() => {
              openModal();
              history?.push("/checkout/payment-process");
            }}
            title={"Change Room Created!"}
          />
        ) : modeSuccess === "change-room-direct" ? (
          <PopupChangeRoomSuccess
            old_room_number={detailCheckout?.room_number}
            new_room_number={roomNumber?.join(",")}
            close={() => {
              openModal();
              reset();
            }}
          />
        ) : modeSuccess === "checkout-confirm" ? (
          <CheckoutConfirm
            timezone={timezone}
            detailCheckout={detailCheckout}
            lateCheckout={lateCheckout}
            compensation={compensation}
            loading={loadingSubmit}
            totalPaid={totalPaid}
            penalty={penaltyCharge}
            close={() => {
              openModal();
              setmodeSuccess("");
            }}
            confirm={handleCheckout}
          />
        ) : modeSuccess === "return-deposit" ? (
          <PopupReturnDeposit
            detailCheckout={detailCheckout}
            close={() => reset()}
          />
        ) : modeSuccess === "checkout-confirm-success" ? (
          <>
            <NotifMessage
              closeModal={() => {
                openModal();
                reset();
              }}
              img={"/img/icon-notif/icon-checkout-success.png"}
              messageHeader={"Check Out Berhasil"}
              color="black"
              messageBody={"Ingin melakukan proses check-out lagi?"}
            />
            <ButtonConfirm
              close={() => {
                openModal();
                totalPaid > 0
                  ? history.push("/checkout/payment-process")
                  : reset();
              }}
              text={"Yes"}
              secondButton="No"
              secondButtonColor={"#19AAEB"}
              color="bg-green"
              confirm={() => {
                openModal();
                reset();
              }}
            />
          </>
        ) : modeSuccess === "checkout-reconfirm" ? (
          <WarningModal
            close={() => {
              openModal();
              setmodeSuccess("");
            }}
            confirm={() => setmodeSuccess("checkout-confirm")}
            messageHeader={"Anda Yakin Proses Check-out?"}
            type="Check-out"
            book_id={detailCheckout?.book_id}
            date={detailCheckout?.checkout_date}
            guestName={splitText(detailCheckout?.guest_name)}
          />
        ) : modeSuccess === "extends-booking" ? (
          <ExtendsModal
            close={() => {
              openModal();
              reset();
            }}
            detail={detailCheckout}
            setPrice={setExtendsPrice}
            price={extendsPrice}
            setMode={setmodeSuccess}
            setBillingExtend={setBillingExtend}
            setError={setError}
            timezone={timezone}
            setRedirected={setRedirected}
            setHeaderPopup={setHeaderPopup}
            date={date}
            setDate={setDate}
            loading={loadingExtend}
            errorExtend={errorExtend}
          />
        ) : modeSuccess === "extends-payment" ? (
          <PaymentExtends
            bankList={bankList}
            bankSelected={bankSelected}
            charge={change}
            close={() => {
              openModal();
              reset();
            }}
            detailPrice={extendsPrice}
            mode={modeSuccess}
            payment={paymentCash}
            paymentMethod={paymentMethod}
            paymentSelected={paymentSelected}
            setBankSelected={setBankSelected}
            setCharge={setChange}
            setpaymentSelected={setpaymentSelected}
            setPayment={setPaymentCash}
            billingExtends={billingExtend}
            detail={detailCheckout}
            setIsPrint={setIsPrint}
            setError={setError}
            setHeaderPopup={setHeaderPopup}
            setMode={setmodeSuccess}
            setpaymentId={setPaymentId}
            setModePrint={setModePrint}
          />
        ) : modeSuccess === "extends-payment-success" ? (
          <PaymentExtendSuccess
            paymentSelected={paymentSelected}
            bankSelected={bankSelected}
            detailPrice={extendsPrice}
            payment={paymentCash}
            charge={change}
            close={() => {
              openModal();
              reset();
            }}
            confirm={() => {
              openModal();
              history?.push("/checkout/payment-confirm");
            }}
          />
        ) : modeSuccess === "penalty" ? (
          <PenaltyChargePopup
            close={() => {
              openModal();
            }}
            room={[detailCheckout?.room_number]}
            setPenaltyCharge={setPenaltyCharge}
            penaltyData={penaltyList}
          />
        ) : (
          <>
            <NotifMessage
              closeModal={() => {
                openModal();
                reset();
              }}
              img={"/img/error-icon/check-out-gagal.png"}
              messageHeader={headerPopup}
              color="red"
              messageBody={error || errorExtend}
            />
            <ButtonClose
              close={
                redirected === "process"
                  ? () => {
                      history.push("/checkout/payment-process");
                      openModal();
                      reset();
                    }
                  : redirected === "confirm"
                  ? () => {
                      history.push("/checkout/payment-confirm");
                      openModal();
                      reset();
                    }
                  : () => {
                      openModal();
                      reset();
                    }
              }
              text="OK"
            />
          </>
        )}
      </PopupModal>
      <PopupModal modalIsOpen={openRoomNumber}>
        {modeModal === "room" ? (
          <PopupRoomNumber
            close={() => {
              modalRoomNumber();
            }}
            reset={() => setRoomNumber()}
            title="Room Number"
            roomNumber={roomList}
            onChange={(val) => setRoomNumber(val)}
            setRoomNumber={(val) => setRoomList(val)}
            isPicked={isPicked}
          />
        ) : (
          modeModal === "error" && (
            <>
              <NotifMessage
                closeModal={openModal}
                img={imgPopup}
                messageHeader={headerPopup}
                color="red"
                messageBody={error}
              />
              <ButtonClose close={modalRoomNumber} text="OK" />
            </>
          )
        )}
      </PopupModal>

      <div style={{ display: "none" }}>
        <Print launch_print={isPrint}>
          {paymentSelected?.value === 3 ? (
            <p className="p-print txt-print-h1">SLIP Pembayaran</p>
          ) : (
            <p className="p-print txt-print-h1">Billing</p>
          )}
          {modePrint === "change-room" ? (
            <>
              {genPaymentID(paymentId)}
              <p className="p-print txt-print-h2">Change Room</p>
              {createLineDash()}
              <p className="p-print txt-print-h3">{`${getServerTime(
                date_now,
                timezone
              )} - ${getClientTimeWithoutSecond(
                date_now,
                timezone
              )} ${getTimezoneOffsetClient(timezone)}`}</p>
              <p className="p-print txt-print-h3">{hotel_info?.hotel_name}</p>
              {createLineDash()}
              <table className="table-print">
                <tbody>
                  {genContent1("Kasir", userProfile?.nama)}
                  {/* {genContent1(
                    "Kode Booking",
                    limitText(detailCheckout?.booking_id)
                  )} */}
                </tbody>
              </table>
              {genBookingId("Booking ID", detailCheckout?.book_id)}
              {createLineDash()}
              <p className="p-print txt-print-section-h2">Informasi Booking</p>
              <table className="table-print">
                <tbody>
                  {genContent1("Tipe Kamar", detailCheckout?.room_type_name)}
                  {genContent1("Nomor Kamar", detailCheckout?.room_number)}
                </tbody>
              </table>
              <p className="p-print txt-print-section-h2">New Room Info</p>
              <table className="table-print">
                <tbody>
                  {genContent1("Tipe Kamar", roomSelected?.label)}
                  {genContent1("Nomor Kamar", roomNumber)}
                </tbody>
              </table>
              {createLineDash()}
              <table className="table-print">
                <tbody>
                  {!isEmpty(paymentSelected) &&
                    genContent1("Metode Pembayaran", paymentSelected?.label)}
                  {paymentSelected?.value === 2 &&
                    genContent1("Nama Bank", bankSelected?.label)}
                </tbody>
              </table>
              {createLineDash()}
              {paymentSelected?.value === 3 ? (
                <p className="p-print txt-print-section-h2">
                  Informasi Pembayaran
                </p>
              ) : (
                <p className="p-print txt-print-section-h2">
                  Informasi Tagihan
                </p>
              )}
              <table className="table-print">
                <tbody>{genContent2("Total Charge", roomPrice)}</tbody>
              </table>
              {paymentSelected?.value === 3 && (
                <>
                  {createLineSolid()}
                  <table className="table-print">
                    <tbody>
                      {genContent2("Pembayaran Tunai", paymentCash)}
                      {genContent2("Kembalian", change)}
                    </tbody>
                  </table>
                </>
              )}
            </>
          ) : modePrint === "print-breakage" ? (
            <>
              {genPaymentID(paymentId)}
              <p className="p-print txt-print-h2">Breakage</p>
              {createLineDash()}
              <p className="p-print txt-print-h3">{`${getServerTime(
                date_now,
                timezone
              )} - ${getClientTimeWithoutSecond(
                date_now,
                timezone
              )} ${getTimezoneOffsetClient(timezone)}`}</p>
              <p className="p-print txt-print-h3">{hotel_info?.hotel_name}</p>
              {createLineDash()}
              <table className="table-print">
                <tbody>
                  {genContent1("Kasir", userProfile?.nama)}
                  {/* {genContent1(
                    "Kode Booking",
                    limitText(detailCheckout?.booking_id)
                  )} */}
                </tbody>
              </table>
              {genBookingId("Booking ID", detailCheckout?.book_id)}
              {createLineDash()}
              <p className="p-print txt-print-section-h2">Informasi Booking</p>
              <table className="table-print">
                <tbody>
                  {genContent1("Tipe Kamar", detailCheckout?.room_type_name)}
                  {genContent1("Nomor Kamar", detailCheckout?.room_number)}
                  {genContent1(
                    "Durasi",
                    getDayDuration(
                      detailCheckout?.checkin_date,
                      detailCheckout?.checkout_date
                    ) + " Malam"
                  )}
                </tbody>
              </table>
              {createLineDash()}
              <table className="table-print">
                <tbody>
                  {genContent1("Metode Pembayaran", paymentSelected?.label)}
                  {paymentSelected?.value === 2 &&
                    genContent1("Nama Bank", bankSelected?.label)}
                </tbody>
              </table>
              {createLineDash()}
              {paymentSelected?.value === 3 ? (
                <p className="p-print txt-print-section-h2">
                  Informasi Pembayaran
                </p>
              ) : (
                <p className="p-print txt-print-section-h2">
                  Informasi Tagihan
                </p>
              )}
              <table className="table-print">
                <tbody>{genContent2("Total Charge", totalPaid)}</tbody>
              </table>
              {paymentSelected?.value === 3 && (
                <>
                  {createLineSolid()}
                  <table className="table-print">
                    <tbody>
                      {genContent2("Pembayaran Tunai", paymentCash)}
                      {genContent2("Kembalian", change)}
                    </tbody>
                  </table>
                </>
              )}
            </>
          ) : modePrint === "print-extend" ? (
            <>
              {genPaymentID(paymentId)}
              <p className="p-print txt-print-h2">Extends</p>
              {createLineDash()}
              <p className="p-print txt-print-h3">{`${getServerTime(
                date_now,
                timezone
              )} - ${getClientTimeWithoutSecond(
                date_now,
                timezone
              )} ${getTimezoneOffsetClient(timezone)}`}</p>
              <p className="p-print txt-print-h3">{hotel_info?.hotel_name}</p>
              {createLineDash()}
              <table className="table-print">
                <tbody>
                  {genContent1("Kasir", userProfile?.nama)}
                  {/* {genContent1(
                    "Kode Booking",
                    limitText(detailCheckout?.booking_id)
                  )} */}
                </tbody>
              </table>
              {genBookingId("Booking ID", detailCheckout?.book_id)}
              {createLineDash()}
              <p className="p-print txt-print-section-h2">Informasi Booking</p>
              <table className="table-print">
                <tbody>
                  {genContent1("Tipe Kamar", detailCheckout?.room_type_name)}
                  {genContent1("Nomor Kamar", detailCheckout?.room_number)}
                </tbody>
              </table>
              {createLineDash()}
              <table className="table-print">
                <tbody>
                  {genContent1("Metode Pembayaran", paymentSelected?.label)}
                  {paymentSelected?.value === 2 &&
                    genContent1("Nama Bank", bankSelected?.label)}
                </tbody>
              </table>
              {createLineDash()}
              {paymentSelected?.value === 3 ? (
                <p className="p-print txt-print-section-h2">
                  Informasi Pembayaran
                </p>
              ) : (
                <p className="p-print txt-print-section-h2">
                  Informasi Tagihan
                </p>
              )}
              <table className="table-print">
                <tbody>
                  {genContent2("Additional Charge", extendsPrice?.add_price)}
                  {genContent2("Room Charge", extendsPrice?.room_price)}
                </tbody>
              </table>
              {createLineDash()}
              <table className="table-print">
                <tbody>
                  {genContent2("Total Charge", extendsPrice?.total_charge)}
                </tbody>
              </table>
              {paymentSelected?.value === 3 && (
                <>
                  {createLineSolid()}
                  <table className="table-print">
                    <tbody>
                      {genContent2("Pembayaran Tunai", paymentCash)}
                      {genContent2("Kembalian", change)}
                    </tbody>
                  </table>
                </>
              )}
            </>
          ) : (
            <>
              <p className="p-print txt-print-h3">{`${getServerTime(
                date_now,
                timezone
              )} - ${getClientTimeWithoutSecond(
                date_now,
                timezone
              )} ${getTimezoneOffsetClient(timezone)}`}</p>
              <p className="p-print txt-print-h3">{hotel_info?.hotel_name}</p>
              {createLineDash()}
              <table className="table-print">
                <tbody>
                  {genContent1("Kasir", userProfile?.nama)}
                  {/* {genContent1(
                    "Kode Booking",
                    limitText(detailCheckout?.booking_id)
                  )} */}
                </tbody>
              </table>
              {genBookingId("Booking ID", detailCheckout?.book_id)}
              {createLineDash()}
              <p className="p-print txt-print-section-h2">Informasi Booking</p>
              <table className="table-print">
                <tbody>
                  {genContent1("Tipe Kamar", detailCheckout?.room_type_name)}
                  {genContent1(
                    "Durasi",
                    getDayDuration(
                      detailCheckout?.checkin_date,
                      detailCheckout?.checkout_date
                    ) + " Malam"
                  )}
                </tbody>
              </table>
              {createLineDash()}
              <table className="table-print">
                <tbody>{genContent1("Metode Pembayaran", "Cash")}</tbody>
              </table>
              {createLineDash()}
              <p className="p-print txt-print-section-h2">
                Informasi Pembayaran
              </p>

              <table className="table-print">
                <tbody>
                  {genContent2("Late Check Out", lateCheckout)}
                  {genContent2("Ganti Rugi", compensation)}
                  {genContent2("Deposit", 0)}
                </tbody>
              </table>
              {createLineSolid()}
              <table className="table-print">
                <tbody>
                  {genContent2("Total", totalPaid)}
                  {genContent2(
                    "Sisa Deposit",
                    priceSplit(detailCheckout?.deposit)
                  )}
                </tbody>
              </table>
            </>
          )}
        </Print>
      </div>
    </div>
  );
};
export default Checkout;
